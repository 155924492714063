import React, { useCallback, useMemo, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  IconChevronRight,
  IconCommand,
  IconCornerDownLeft,
  IconFileInvoice,
  IconFolder,
  IconGridDots,
  IconId,
  IconList,
  IconListSearch,
  IconPlus,
  IconSearch,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { Color, Tooltip, getColorShade } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { SINGLE_RECORD } from '../constants/collectionLayouts';
import { FOLDER, PAGE, VIEW } from '../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { ESCAPE, KEY_K } from '../constants/shortcuts';
import { Element, StringPropValue } from '../models/Element';
import { IconValue } from '../models/IconValue';
import { projectDataSelector } from '../selectors/projectSelectors';
import { lookupOfArray } from '../utils/arrays';
import { useAuthWrapper } from '../utils/hooks/useAuthWrapper';
import useCurrentSpace from '../utils/hooks/useCurrentSpace';
import useDarkMode from '../utils/hooks/useDarkMode';
import useDarkModeSurface from '../utils/hooks/useDarkModeSurface';
import useIsMacOs from '../utils/hooks/useIsMacOs';
import useMergedScope from '../utils/hooks/useMergedScope';
import useOnKeyPress from '../utils/hooks/useOnKeyPress';
import useRouter from '../utils/hooks/useRouter';
import useSpaces from '../utils/hooks/useSpaces';
import { getText } from '../utils/lang';
import { PageRoute, getVisibleRoutes } from '../utils/navigation';
import { replaceDoubleSlashes } from '../utils/pages';
import Icon from './Icon';

const LANG_KEY = 'commandPalette';

interface CommandPaletteProps {
  className?: string;
  primaryColor: Color;
  pages: Element[];
  isNavExpanded: boolean;
}

const EMPTY_SCOPE = {};

type RouteType = 'VIEW' | 'NEW' | 'RECORD' | 'FOLDER' | 'PAGE' | 'SPACE';

const isSingleRecordLayout = (element: Element) =>
  element.props?.layout === SINGLE_RECORD;

const RouteTypes: Record<string, RouteType> = {
  VIEW: 'VIEW',
  NEW: 'NEW',
  RECORD: 'RECORD',
  FOLDER: 'FOLDER',
  PAGE: 'PAGE',
  SPACE: 'SPACE',
};

const getRouteType = (element: Element): RouteType => {
  if (element.type === FOLDER) {
    return RouteTypes.FOLDER;
  }

  if (element.type === PAGE) {
    return RouteTypes.PAGE;
  }

  if (element.id.endsWith('NEW')) {
    return RouteTypes.NEW;
  }

  if (element.type === VIEW) {
    if (isSingleRecordLayout(element)) {
      return RouteTypes.RECORD;
    }

    return RouteTypes.VIEW;
  }

  return RouteTypes.PAGE;
};

const RouteIcons = {
  [RouteTypes.FOLDER]: IconFolder,
  [RouteTypes.NEW]: IconPlus,
  [RouteTypes.PAGE]: IconFileInvoice,
  [RouteTypes.RECORD]: IconId,
  [RouteTypes.SPACE]: IconGridDots,
  [RouteTypes.VIEW]: IconList,
};

const RouteIcon = ({ type }: { type: RouteType }) => {
  const Icon = RouteIcons[type];

  return (
    <div className="flex items-center justify-center rounded-lg bg-gray-100 p-2 dark:bg-gray-500 dark:text-white">
      <Icon size={16} className="opacity-50" />
    </div>
  );
};

const sanitizeFilterString = (string: string) =>
  string.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();

const stringifyDynamicText = (stringParts: StringPropValue) =>
  stringParts.map((part) => part.text ?? '').join(' ');

interface RouteOption {
  id: string;
  path: string;
  type: RouteType;
  label: JSX.Element;
  plainLabel: string;
  icon: IconValue;
}

const getRouteOption = (
  routeId: string,
  routePath: string,
  routeName: string,
  icon: IconValue,
  parents: PageRoute[],
  type: RouteType,
): RouteOption => ({
  id: `${parents.map((parent) => parent.id).join('-')}${
    parents.length > 0 ? '-' : ''
  }${routeId}`,
  path: replaceDoubleSlashes(routePath),
  type,
  label: (
    <span className="flex w-full items-center space-x-2">
      {parents.map((parent) => (
        <React.Fragment key={parent.id}>
          <span>{parent.element.props?.name}</span>
          <IconChevronRight size={16} className="opacity-50" />
        </React.Fragment>
      ))}
      <span>{routeName}</span>
    </span>
  ),
  plainLabel: sanitizeFilterString(
    `${parents
      .map((parent) => parent.element.props.name ?? '')
      .join(' ')}${routeName}`,
  ),
  icon,
});

const getRouteOptions = (
  route: PageRoute,
  parents: PageRoute[] = [],
): RouteOption[] => {
  const props = route.element.props ?? {};

  const routeOptions = [
    getRouteOption(
      route.id,
      route.path,
      props.name,
      props.icon,
      parents,
      getRouteType(route.element),
    ),
  ];
  const elementType = route.element.type;

  if (
    elementType === VIEW &&
    (parents.length === 0 || parents[0].element.type === FOLDER) &&
    !isSingleRecordLayout(route.element) &&
    !route.element.props?.hideNewButton
  ) {
    const newRecordFormPath = route.path ? `${route.path}/new` : 'new';
    routeOptions.push(
      getRouteOption(
        'NEW',
        newRecordFormPath,
        stringifyDynamicText(props.new.title ?? []),
        props.icon,
        [...parents, route],
        RouteTypes.NEW,
      ),
    );
  }

  return routeOptions;
};

const CommandPalette = ({
  className,
  isNavExpanded,
  pages,
  primaryColor,
}: CommandPaletteProps) => {
  const { push } = useRouter();
  const isMacOs = useIsMacOs();
  const project = useSelector(projectDataSelector);
  const { fetchedUser, user } = useAuthWrapper();
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const scope = useMergedScope(EMPTY_SCOPE);
  const surface = useDarkModeSurface();
  const [isDarkModeEnabled] = useDarkMode();
  const spaces = useSpaces();
  const [_, setCurrentSpaceId] = useCurrentSpace();

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  useOnKeyPress(KEY_K, onOpen, { ctrlKey: true, enabled: !isOpen });
  useOnKeyPress(ESCAPE, onClose, { enabled: isOpen });

  const elementMap = useMemo(
    () => lookupOfArray<Element, string>(project.elements, 'id'),
    [project.elements],
  );

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const flatRoutes = useMemo(
    () =>
      getVisibleRoutes(
        pages
          .filter((page) => page && page.id && !page?.props.hide)
          .map((page) => ({ props: { element: page } })),
        elementMap,
        fetchedUser,
        project,
        user,
        scope,
        false,
        customRulesEnabled,
      ),
    [customRulesEnabled, elementMap, fetchedUser, pages, project, scope, user],
  );

  const pageOptions = useMemo(
    () =>
      flatRoutes
        .filter((route) => !route.parentPageId)
        .reduce((routeOptions, route) => {
          routeOptions.push(...getRouteOptions(route));

          flatRoutes
            .filter((childRoute) => childRoute.parentPageId === route.id)
            .forEach((childRoute) => {
              routeOptions.push(...getRouteOptions(childRoute, [route]));
            });

          return routeOptions;
        }, [] as RouteOption[]),
    [flatRoutes],
  );

  const spaceOptions = useMemo(
    () =>
      spaces.map(({ id, space }) => ({
        icon: space.icon,
        id,
        label: space.name,
        plainLabel: sanitizeFilterString(space.name),
        type: RouteTypes.SPACE,
      })),
    [spaces],
  );

  const allOptions = useMemo(
    () => [...pageOptions, ...spaceOptions],
    [pageOptions, spaceOptions],
  );

  const filteredOptions = useMemo(
    () =>
      allOptions.filter(
        (routeOption) => !query || routeOption.plainLabel.includes(query),
      ),
    [allOptions, query],
  );

  const onSelect = useCallback(
    ({ id, path, type }: RouteOption) => {
      if (type === RouteTypes.SPACE) {
        setCurrentSpaceId(id);
      } else {
        push(path);
      }

      setIsOpen(false);
    },
    [push, setCurrentSpaceId],
  );

  return (
    <div className={classNames(className, 'w-full px-3 sm:hidden')}>
      <Tooltip
        disabled={isNavExpanded}
        content={
          <div
            className={classNames('flex items-center space-x-2', {
              'text-slate-200': surface === DARK,
            })}
          >
            {getText(LANG_KEY, 'button')}
          </div>
        }
        placement="right"
        popoverClassName="sm:hidden"
        surface={surface}
      >
        <button
          className={classNames(
            'group flex w-full select-none items-center truncate rounded-lg px-3 py-2',
            `text-${getColorShade(primaryColor, 300)} hover:text-white hover:bg-${getColorShade(primaryColor, 800)} bg-${getColorShade(primaryColor, 600)} dark:bg-${getColorShade(primaryColor, 700)}`,
            { 'justify-center': !isNavExpanded },
          )}
          onClick={() => setIsOpen(true)}
        >
          <IconSearch className="flex-shrink-0" size={15} />
          {isNavExpanded && (
            <span className="ml-3">{getText(LANG_KEY, 'button')}</span>
          )}
          {isNavExpanded && (
            <div className="ml-auto hidden items-center opacity-75 group-hover:flex">
              {isMacOs ? (
                <IconCommand size={14} className="flex-shrink-0" />
              ) : (
                <span className="text-xs">Ctrl + </span>
              )}
              <span className="text-xs">k</span>
            </div>
          )}
        </button>
      </Tooltip>
      <Transition.Root
        show={isOpen}
        as={React.Fragment}
        afterLeave={() => setQuery('')}
        appear
      >
        <Dialog
          as="div"
          className={classNames('relative z-10', {
            dark: isDarkModeEnabled,
          })}
          onClose={setIsOpen}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-16 sm:p-6 md:p-20">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:bg-gray-900">
                <Combobox<any, RouteOption | null>
                  value={null}
                  onChange={onSelect}
                >
                  <div className="flex flex-col p-2">
                    <Combobox.Input
                      className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm dark:bg-gray-700 dark:text-white"
                      placeholder={getText(LANG_KEY, 'placeholder')}
                      onChange={(event) => setQuery(event.target.value)}
                    />

                    {filteredOptions.length > 0 && (
                      <Combobox.Options
                        static
                        className="-mb-2 overflow-hidden py-2 text-sm text-gray-800 dark:text-gray-200"
                      >
                        {/* @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component. */}
                        <SimpleBar className="max-h-72 scroll-py-2 overflow-y-auto">
                          {filteredOptions.map((option) => (
                            <Combobox.Option
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-4 py-2',
                                  {
                                    [`bg-${getColorShade(
                                      primaryColor,
                                      isDarkModeEnabled ? 800 : 200,
                                    )}`]: active,
                                  },
                                )
                              }
                              key={option.id}
                              value={option}
                            >
                              <Icon
                                className="mr-2 h-5 w-5 text-gray-700 opacity-75 dark:text-white"
                                icon={
                                  option.icon?.name
                                    ? option.icon
                                    : { name: 'File' }
                                }
                              />
                              <span className="mr-auto truncate">
                                {option.label}
                              </span>
                              <RouteIcon type={option.type} />
                            </Combobox.Option>
                          ))}
                        </SimpleBar>
                      </Combobox.Options>
                    )}
                  </div>
                  {filteredOptions.length > 0 && (
                    <div className="flex items-center justify-end bg-gray-100 px-6 py-1 text-gray-600 dark:bg-gray-800 dark:text-gray-500">
                      <span className="text-xs">
                        {getText(LANG_KEY, 'actionHelp')}
                      </span>
                      <span className="ml-2 rounded bg-gray-300 p-1 text-gray-600 dark:bg-gray-700 dark:text-gray-900">
                        <IconCornerDownLeft
                          size={12}
                          className="flex-shrink-0 opacity-75"
                        />
                      </span>
                    </div>
                  )}
                  {query !== '' && filteredOptions.length === 0 && (
                    <div className="px-4 py-14 text-center sm:px-14">
                      <IconListSearch
                        className="mx-auto flex-shrink-0 text-gray-400"
                        size={32}
                      />
                      <p className="mt-4 text-sm text-gray-900 dark:text-gray-100">
                        {getText(LANG_KEY, 'emptyState')}
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default CommandPalette;
