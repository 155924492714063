export const extractVideoId = (
  potentialVideoId: string,
  urlPatterns: string[],
): string => {
  for (const pattern of urlPatterns) {
    const match = potentialVideoId.toString().match(pattern);

    if (match) {
      return match[2];
    }
  }

  return potentialVideoId;
};
