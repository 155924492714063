import React, { useCallback, useMemo } from 'react';
import { SelectInput } from '@noloco/components';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getRootFieldDataType } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import DataFieldIcon from '../../DataFieldIcon';

interface SectionRootFieldEditorProps {
  dataType: DataType;
  onChange: (rootField: string | null, dataType: DataType) => void;
  project: Project;
  value: string | undefined;
}

const SectionRootFieldEditor = ({
  dataType,
  onChange,
  project,
  value,
}: SectionRootFieldEditorProps) => {
  const parentFieldOptions = useMemo(
    () => [
      {
        value: null,
        label: getText('elements.VIEW.fields.parent.record'),
      },
      ...dataType.fields
        .filter(
          (field: any) =>
            (field.relationship || field.relatedField) &&
            !isMultiField(field) &&
            field.type !== FILE,
        )
        .map((field: any) => ({
          value: field.name,
          label: (
            <div className="flex items-center">
              <DataFieldIcon
                size={16}
                field={field}
                className="mr-2 opacity-75"
              />
              <span>{field.display}</span>
            </div>
          ),
        })),
    ],
    [dataType.fields],
  );

  const onRootFieldChange = useCallback(
    (rootFieldName: string | undefined) => {
      if (rootFieldName) {
        const relatedDataType = getRootFieldDataType(
          rootFieldName,
          dataType,
          project.dataTypes,
        );

        onChange(rootFieldName, relatedDataType);
      } else {
        onChange(null, dataType);
      }
    },
    [dataType, onChange, project.dataTypes],
  );

  return (
    <SelectInput
      className="w-full text-black"
      onChange={onRootFieldChange}
      options={parentFieldOptions}
      usePortal={true}
      value={value || null}
    />
  );
};

export default SectionRootFieldEditor;
