import React, { useMemo } from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getColorShade } from '@noloco/components';
import Avatar from '../../components/Avatar';
import { SIDE_MENU, TOP_MENU } from '../../constants/menuStyles';
import { ghostUserIdSelector } from '../../selectors/dataSelectors';
import { useAuth } from '../../utils/hooks/useAuth';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { isBuilder } from '../../utils/user';
import { getFullName } from '../../utils/user';
import Notifications from './Notifications';
import ProfilePopover from './ProfilePopover';

const SidebarNavFooter = ({
  editorMode,
  isNavExpanded,
  isNavOpen,
  primaryColor,
  setGhostUserId,
  setIsNavOpen,
  showProfile,
  menuStyle,
}: any) => {
  const user = useScopeUser();
  const [isDarkModeEnabled] = useDarkMode();

  const { user: baseUser } = useAuth();
  const userIsBuilder = baseUser && isBuilder(baseUser);

  const ghostUserId = useSelector(ghostUserIdSelector);

  const fullName = useMemo(() => getFullName(user), [user]);

  return (
    <>
      {userIsBuilder && ghostUserId && menuStyle === SIDE_MENU && (
        <div
          className={classNames(
            'flex w-full flex-col space-y-2 border-b px-3 py-2 sm:hidden',
            `bg-${getColorShade(primaryColor, isDarkModeEnabled ? 700 : 600)}`,
            `border-${getColorShade(primaryColor, 900)}`,
            `text-${getColorShade(primaryColor, 100)}`,
          )}
        >
          <div className="flex items-center overflow-hidden">
            <span className="mr-auto truncate font-medium tracking-wider">
              {getText({ name: user.firstName }, 'leftSidebar.auth.viewingAs')}
            </span>
            <button onClick={() => setGhostUserId(null)} className="p-1">
              <IconX size={14} />
            </button>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex items-center',
          {
            'w-full p-3': menuStyle === SIDE_MENU,
            [`bg-${getColorShade(
              primaryColor,
              isDarkModeEnabled ? 700 : 600,
            )}`]: menuStyle === SIDE_MENU,
            'flex-row-reverse sm:w-full sm:flex-row sm:p-3':
              menuStyle === TOP_MENU,
          },
          { 'sm:hidden': !isNavOpen, 'flex-col space-y-2': !isNavExpanded },
        )}
        onClick={() => setIsNavOpen(false)}
        data-testid="sidebar-nav-footer"
      >
        <ProfilePopover
          placement={menuStyle === SIDE_MENU ? 'top-start' : 'bottom-end'}
          disabled={editorMode}
          showProfile={showProfile}
        >
          <div>
            <Avatar
              className={classNames({ 'cursor-pointer': !editorMode })}
              primaryColor={primaryColor}
              size={menuStyle === SIDE_MENU ? 12 : 8}
              user={user}
            />
          </div>
        </ProfilePopover>
        <div
          className={classNames('ml-4 flex-col', {
            flex: isNavExpanded,
            'hidden sm:flex': !isNavExpanded || menuStyle === TOP_MENU,
          })}
        >
          <ProfilePopover disabled={editorMode} showProfile={showProfile}>
            <div>
              <p
                className={classNames('text-sm text-white', {
                  'cursor-pointer hover:underline': !editorMode,
                })}
              >
                {fullName}
              </p>
            </div>
          </ProfilePopover>
        </div>
        <div
          className={classNames({
            'ml-auto': isNavExpanded && menuStyle === SIDE_MENU,
            'mr-4 flex-shrink-0 sm:ml-auto': menuStyle === TOP_MENU,
          })}
        >
          <Notifications primaryColor={primaryColor} />
        </div>
      </div>
    </>
  );
};

export default SidebarNavFooter;
