import { memo, useCallback, useMemo, useState } from 'react';
import { IconArrowLeft, IconArrowRight, IconX } from '@tabler/icons-react';
import { BaseModal } from '@noloco/components';
import ConfirmDeleteButton from '@noloco/ui/src/components/dataTable/ConfirmDeleteButton';
import {
  ESCAPE,
  KEY_ARROW_LEFT,
  KEY_ARROW_RIGHT,
} from '../../../constants/shortcuts';
import { File } from '../../../models/File';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRouter from '../../../utils/hooks/useRouter';
import FilePreviewElement from '../view/FilePreviewElement';
import FileDownloadButton from './FileDownloadButton';

interface FilePreviewModalProps {
  files: File[];
  id?: string;
  initialIndex?: number;
  onClose: () => void;
  onRemove?: (fileId: number) => void;
  queryFileId?: string;
}

const stopPropagation = (event: any) => event.stopPropagation();

const FilePreviewModal = memo(
  ({
    files,
    id,
    initialIndex,
    onClose,
    onRemove,
    queryFileId,
  }: FilePreviewModalProps) => {
    useOnKeyPress(ESCAPE, onClose);
    const { replaceQueryParams } = useRouter();
    const [localFileIndex, setFileIndex] = useState(initialIndex || 0);

    const fileIndex = useMemo(
      () =>
        !id || !queryFileId
          ? localFileIndex
          : files.findIndex((f: any) => f.id === queryFileId),
      [files, id, localFileIndex, queryFileId],
    );

    const hasPreviousFile = useMemo(
      () => !!(fileIndex > 0 && files[fileIndex - 1]),
      [fileIndex, files],
    );

    const hasNextFile = useMemo(
      () => !!(fileIndex < files.length - 1),
      [fileIndex, files],
    );

    const previousFile = useCallback(() => {
      if (hasPreviousFile) {
        if (!id) {
          setFileIndex(fileIndex - 1);
        } else {
          const previousFile = files[fileIndex - 1];
          replaceQueryParams({ _fileId: String(previousFile.id) });
        }
      }
    }, [fileIndex, files, hasPreviousFile, id, replaceQueryParams]);

    const nextFile = useCallback(() => {
      if (hasNextFile) {
        if (!id) {
          setFileIndex(fileIndex + 1);
        } else {
          const nextFile = files[fileIndex + 1];
          replaceQueryParams({ _fileId: String(nextFile.id) });
        }
      }
    }, [fileIndex, files, hasNextFile, id, replaceQueryParams]);

    useOnKeyPress(KEY_ARROW_LEFT, previousFile, {
      enabled: hasPreviousFile,
    });

    useOnKeyPress(KEY_ARROW_RIGHT, nextFile, {
      enabled: hasNextFile,
    });

    const file = useMemo(() => files[fileIndex], [fileIndex, files]);

    const handleRemove = useCallback(() => {
      if (onRemove && file && file.id) {
        onRemove(file.id);
      }
    }, [file, onRemove]);

    if (!file) {
      return null;
    }

    return (
      <BaseModal size="full" onClose={onClose}>
        <div
          className="flex h-screen w-full flex-col overflow-hidden bg-white p-4"
          onClick={stopPropagation}
        >
          <div className="mb-2 flex w-full items-center bg-white py-4">
            <button
              onClick={onClose}
              className="mr-4 p-1 opacity-75 hover:opacity-100"
            >
              <IconX size={14} />
            </button>
            <div className="truncate pr-4 text-lg font-medium tracking-wider">
              {file.name || ''}
            </div>
            <div className="ml-auto flex flex-shrink-0">
              {onRemove && (
                <ConfirmDeleteButton className="mr-2" onDelete={handleRemove} />
              )}
              <FileDownloadButton className="mr-4 p-1" file={file} />
              {(hasPreviousFile || hasNextFile) && (
                <>
                  <button
                    onClick={previousFile}
                    disabled={!hasPreviousFile}
                    className="p-1 opacity-75 hover:opacity-100 disabled:opacity-50"
                  >
                    <IconArrowLeft size={20} />
                  </button>
                  <button
                    className="ml-2 p-1 opacity-75 hover:opacity-100 disabled:opacity-50"
                    onClick={nextFile}
                    disabled={!hasNextFile}
                  >
                    <IconArrowRight size={20} />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="relative flex h-full w-full items-center justify-center overflow-hidden">
            <FilePreviewElement file={file} />
          </div>
        </div>
      </BaseModal>
    );
  },
);

export default FilePreviewModal;
