import { useMemo } from 'react';
import QRCode from 'react-qr-code';
import { CopyButton, TextInput } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { SetupDomainLink } from './SetupDomainLink';

const AppSharingDetails = ({
  nolocoDomain,
  setIsOpen,
  domains,
}: {
  nolocoDomain: string;
  setIsOpen: (value: boolean) => void;
  domains: any[];
}) => {
  const activeDomains = useMemo(
    () => domains.filter((domain) => domain.active === true),
    [domains],
  );

  const domain = useMemo(
    () => (activeDomains.length === 0 ? nolocoDomain : activeDomains[0].name),
    [activeDomains, nolocoDomain],
  );

  return (
    <div className="flex flex-row justify-start">
      <div className="mr-3 flex h-fit w-fit justify-center rounded-lg bg-gray-100 px-1.5 py-1.5 align-middle">
        <QRCode size={60} value={domain} />
      </div>
      <div
        aria-label="Domain and Custom Link"
        className="flex w-full flex-col justify-between overflow-hidden text-ellipsis whitespace-nowrap"
      >
        <div className="flex flex-row justify-between">
          <TextInput
            value={domain}
            disabled={true}
            autoFocus={false}
            readOnly={true}
            className="text-xs"
            border="none"
            surface={LIGHT}
          />
          <div className="ml-1 flex h-8 w-9 border-spacing-1 justify-center rounded-lg border-2 border-gray-200 align-middle">
            <CopyButton
              tooltipContent={'App URL Copied to Clipboard'}
              value={nolocoDomain}
              className="color-gray-200"
            />
          </div>
        </div>
        {activeDomains.length === 0 && (
          <SetupDomainLink onClick={() => setIsOpen(false)} />
        )}
      </div>
    </div>
  );
};

export default AppSharingDetails;
