import React, { forwardRef, memo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import * as loom from '@loomhq/loom-embed';
import { extractVideoId } from './utils/videoUtils';

const urlPatterns = [
  '(https:\\/\\/www\\.)?loom\\.com\\/share\\/([a-zA-Z0-9_-]+)',
  '(https:\\/\\/www\\.)?loom\\.com\\/embed\\/([a-zA-Z0-9_-]+)',
];

const LoomVideoElement = memo(({ videoId }: { videoId: string }) => {
  const handleRef = async (video: HTMLDivElement | null) => {
    if (!video) {
      return;
    }

    const cleanVideoId = extractVideoId(videoId, urlPatterns);
    const videoUrl = `https://www.loom.com/share/${cleanVideoId}`;

    try {
      const options = {
        width: window.innerWidth,
        height: Math.floor(window.innerWidth * 0.5625),
      };

      const embed = await loom.oembed(videoUrl, options);
      video.innerHTML = embed.html;

      const iframe = video.querySelector('iframe');

      if (iframe) {
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
      }
    } catch (error) {
      console.error('Error embedding Loom video:', error);
      video.innerHTML = `<div class="text-red-500">Error loading Loom video</div>`;
    }
  };

  return (
    <div ref={handleRef} className="absolute left-0 top-0 h-full w-full" />
  );
});

interface LoomVideoProps {
  className?: string;
  videoId: string;
  style?: any;
  onClick?: (...args: any[]) => any;
}

const LoomVideo = forwardRef<HTMLDivElement, LoomVideoProps>(
  ({ className = '', onClick, style, videoId }, ref) => {
    if (!videoId) {
      return null;
    }

    return (
      <Box ref={ref} className={className} style={style} onClick={onClick}>
        <div className="pb-fluid-video relative h-0">
          <LoomVideoElement videoId={videoId} />
        </div>
      </Box>
    );
  },
);

LoomVideo.defaultProps = {
  className: '',
  videoId: '',
};

export default LoomVideo;
