import get from 'lodash/get';
import { Element } from '../models/Element';
import { ProjectSpace, ProjectSpaces } from '../models/Project';

export const CURRENT_SPACE = 'noloco.currentSpaceId';

export const getSpacesArray = (spaces: ProjectSpaces) =>
  Object.entries(spaces)
    .reduce(
      (result, [id, space]) => {
        if (space) {
          result.push({ id, space });
        }

        return result;
      },
      [] as { id: string; space: ProjectSpace }[],
    )
    .sort((a, b) => a.space.order - b.space.order);

export const getOrderValue = (
  elements: (Element | ProjectSpace)[],
  orderKey: string[],
): number =>
  Math.ceil(
    elements.length > 0
      ? Math.max(...elements.map((element) => get(element, orderKey, 0)))
      : 0,
  ) + 1;

export const getOrderValueAfterReOrder = (
  newOrder: (Element | ProjectSpace)[],
  droppedIndex: number,
  orderKey: string[],
): number => {
  const getOrder = (index: number) => get(newOrder, [index, ...orderKey], 0);

  return (getOrder(droppedIndex - 1) + getOrder(droppedIndex + 1)) / 2;
};
