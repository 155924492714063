import createReactComponent from '../createReactComponent';
export default createReactComponent('brand-instagram', 'IconBrandInstagram', [
  [
    'path',
    {
      d: 'M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M16.5 7.5l0 .01', key: 'svg-2' }],
]);
