import upperFirst from 'lodash/upperFirst';
import * as dataTypes from '../constants/dataTypes';
import { DataField } from '../models/DataTypeFields';
import { isOptionType } from '../utils/options';
import pascalCase from '../utils/pascalCase';
import { isMultiField } from '../utils/relationships';

const dataFieldTypeToGraphQLType: Record<string, string> = {
  [dataTypes.TEXT]: 'String',
  [dataTypes.DATE]: 'DateTime',
  [dataTypes.INTEGER]: 'Int',
  [dataTypes.DECIMAL]: 'Float',
  [dataTypes.DURATION]: 'Duration',
  [dataTypes.FILE_TYPE]: 'FileType',
  [dataTypes.BOOLEAN]: 'Boolean',
  [dataTypes.SINGLE_OPTION]: 'ENUM',
  [dataTypes.MULTIPLE_OPTION]: 'ENUM',
};
export const modifyIfRequired = (isRequired: boolean) => (type: string) =>
  isRequired ? `${type}!` : type;
export const getGraphQlFieldType = (
  dataTypeName: string,
  field: DataField,
  useSingular?: boolean,
  isRequired?: boolean,
): string => {
  const withRequired = modifyIfRequired(isRequired as boolean);

  if (isOptionType(field.type as dataTypes.DataFieldType)) {
    const name = `${upperFirst(dataTypeName)}${pascalCase(field.apiName)}`;

    return field.type === dataTypes.MULTIPLE_OPTION && !useSingular
      ? withRequired(`[${withRequired(name)}]`)
      : withRequired(name);
  }

  if (field.type === dataTypes.OBJECT) {
    const objectType = `${pascalCase(field.typeOptions?.format!)}Object`;

    return field.multiple && !useSingular
      ? withRequired(`[${objectType}!]`)
      : objectType;
  }

  if (field.name === 'id') {
    return withRequired('ID');
  }

  if (field.relationship || field.relatedField) {
    return withRequired(isMultiField(field) ? '[ID!]' : 'ID');
  }

  const graphQlType = dataFieldTypeToGraphQLType[field.type];

  if (!graphQlType) {
    throw new Error(
      `Unknown data field type: ${field.type} for ${field.name} on ${dataTypeName}. This is likely a misconfigured relationship.`,
    );
  }

  const baseFieldType = withRequired(graphQlType);

  if (field.multiple) {
    return withRequired(`[${baseFieldType}!]`);
  }

  return baseFieldType;
};

export const TestingExportForSpy = {
  getGraphQlFieldType,
  modifyIfRequired,
};
