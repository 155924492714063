import React, { createContext, useContext } from 'react';
import { useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { AUTH_WRAPPER_ID } from '../../constants/auth';
import { USER } from '../../constants/builtInDataTypes';
import { LINK, PAGE, PAGE_SWITCH } from '../../constants/elements';
import DataTypes, { DataType } from '../../models/DataTypes';
import { User } from '../../models/User';
import {
  getCurrentUserQueryString,
  getGhostUserQueryString,
} from '../../queries/project';
import { ghostUserIdSelector } from '../../selectors/dataSelectors';
import {
  projectElementsSelector,
  projectIntegrationsSelector,
  projectNameSelector,
  projectSettingsSelector,
} from '../../selectors/projectSelectors';
import { authWrapperQuerySelector } from '../../selectors/queriesSelectors';
import { findDependentValues, transformDepsToQueryObject } from '../data';
import { getCurrentPageFromPathname, getPagesConfig } from '../pages';
import { transformColumnarScope } from '../scope';
import { getDepsForViewSections } from '../sectionDependencies';
import { useAuth } from './useAuth';
import useCacheQuery from './useCacheQuery';
import usePrevious from './usePrevious';
import useRouter from './useRouter';
import { useSaveGhostUserData } from './useSaveGhostUserData';
import { useInvalidateProjectData } from './useServerEvents';
import useSetQuery from './useSetQuery';
import useSetScopeFieldValue from './useSetScopeFieldValue';

export type AuthWrapperUser =
  | (User & { isLoggedIn: boolean; loading: boolean })
  | undefined;

interface AuthWrapperContext {
  isGhost: boolean;
  fetchedUser: boolean;
  loading: boolean;
  user: AuthWrapperUser;
}

const authWrapperContext = createContext<AuthWrapperContext>({
  isGhost: false,
  fetchedUser: false,
  loading: false,
  user: undefined,
});

export const getAuthScope = (
  loading: boolean,
  currentUser: User,
  userType: DataType,
  dataTypes: DataTypes,
): User & { isLoggedIn: boolean; loading: boolean } => ({
  isLoggedIn: !!currentUser,
  ...transformColumnarScope(currentUser || {}, userType, dataTypes),
  loading: loading,
});

export const useUpdateUserCache = () => {
  const authWrapperQs = useSelector(authWrapperQuerySelector);
  // @ts-expect-error typing needs updating
  const { client: apolloClient } = useAuth();

  return useCallback(
    (user: any) => {
      apolloClient.writeQuery({
        query: gql`
          ${authWrapperQs}
        `,
        data: {
          currentUser: {
            token: null,
            user: user,
            __typename: 'AuthPayload',
          },
        },
      });
    },
    [apolloClient, authWrapperQs],
  );
};

const MOCK_USER_FOR_DEPS = { id: 0, role: { id: 0, dataAdmin: true } } as User;

export const useAuthWrapperUserFields = (
  dataTypes: DataTypes = new DataTypes([]),
) => {
  const userType = useMemo(
    () => dataTypes.getByName(USER),
    [dataTypes],
  ) as DataType;
  const { pathname } = useRouter();
  const elements = useSelector(projectElementsSelector);
  const settings = useSelector(projectSettingsSelector);
  const integrations = useSelector(projectIntegrationsSelector);

  const { projectPages } = useMemo(
    () => getPagesConfig(elements, settings),
    [elements, settings],
  );

  const currentPage = useMemo(
    () => getCurrentPageFromPathname(projectPages, pathname),
    [pathname, projectPages],
  );

  const elementsForDeps = useMemo(
    () =>
      currentPage
        ? projectPages.map((page) =>
            page.id === currentPage.id
              ? page
              : {
                  id: page.id,
                  props:
                    page.type === LINK
                      ? { link: get(page, 'props.link') }
                      : {
                          // Because a form can be used anywhere as a nested create - we need to include forms
                          new: { fields: get(page, 'props.new.fields') },
                          record: {
                            // Because we redirect away from a tab if the visibility rules don't match
                            // we need to include them in the initial load
                            tabs: get(page, 'props.record.tabs'),
                          },
                        },
                  type: page.type,
                  visibilityRules: page.visibilityRules,
                },
          )
        : projectPages,
    [currentPage, projectPages],
  );

  const loggedInUserDeps = useMemo(() => {
    const baseDeps = findDependentValues(
      AUTH_WRAPPER_ID,
      {
        id: 'root',
        type: PAGE_SWITCH,
        props: {},
        children: elementsForDeps,
      },
      dataTypes,
    ).filter((dep: any) => !dep.args || dep.args.parent); // Dep args are a legacy thing that we don't really use anymore, but it seems we accidentally use them for fields with parents (like address)

    const blankPages = elementsForDeps.filter(
      (page: any) => page?.type === PAGE && page?.props?.V2,
    );

    return blankPages.reduce(
      (depAcc: any, blankPage: any) => [
        ...depAcc,
        ...getDepsForViewSections(
          blankPage,
          get(blankPage, 'props.sections', []),
          dataTypes,
          userType,
          MOCK_USER_FOR_DEPS, // mock user because we don't have a user yet. This just allows us to fetch all fields possible
        ).map((dep: any) => ({ ...dep, id: AUTH_WRAPPER_ID })),
      ],
      baseDeps,
    );
  }, [dataTypes, elementsForDeps, userType]);

  return useMemo(() => {
    const userFields = transformDepsToQueryObject(
      userType,
      dataTypes,
      loggedInUserDeps,
    );
    const { stripe } = integrations ?? {};
    const membershipsEnabled =
      stripe && (stripe.accountId || (stripe.account && stripe.account.id));
    userFields.id = true;
    userFields.uuid = true;
    userFields.firstName = true;
    userFields.lastName = true;
    userFields.email = true;
    userFields.createdAt = true;
    userFields._nolocoUserId = true;
    userFields._commentNotificationPreference = true;

    userFields.profilePicture = {
      id: true,
      uuid: true,
      url: true,
      fileType: true,
      mimetype: true,
    };

    userFields.company = {
      id: true,
      uuid: true,
      name: true,
      logo: {
        id: true,
        url: true,
        fileType: true,
        mimetype: true,
      },
      website: true,
      ...userFields.company,
    };

    if (membershipsEnabled) {
      userFields.company.customerPaymentsId = true;
    }

    userFields.isInternal = true;
    userFields.role = {
      name: true,
      referenceId: true,
      internal: true,
      dataAdmin: true,
      builder: true,
      id: true,
      uuid: true,
    };

    return userFields;
  }, [loggedInUserDeps, dataTypes, integrations, userType]);
};

const useAuthWrapperContext = (
  dataTypes: DataTypes,
  forceGhostQuery = false,
): AuthWrapperContext => {
  const projectName = useSelector(projectNameSelector);
  const ghostUserId = useSelector(ghostUserIdSelector);
  const useGhostQuery = ghostUserId || forceGhostQuery;
  const userFields = useAuthWrapperUserFields(dataTypes);
  const previousGhostUserId = usePrevious(ghostUserId);

  const userType = useMemo(
    () => dataTypes.getByName(USER) as DataType,
    [dataTypes],
  );
  const currentUserQs = useMemo(
    () =>
      !useGhostQuery
        ? getCurrentUserQueryString(userFields)
        : getGhostUserQueryString(userFields, ghostUserId),
    [ghostUserId, useGhostQuery, userFields],
  );
  const queryObject = useMemo(
    () => ({
      id: AUTH_WRAPPER_ID,
      variables: {},
      query: currentUserQs,
      dataType: 'currentUser',
      valuePath: 'user.',
      type: PAGE,
    }),
    [currentUserQs],
  );
  useSetQuery(queryObject);

  const { called, loading, data, error, refetch } = useCacheQuery(
    gql`
      ${currentUserQs}
    `,
    {
      context: { projectQuery: true, withCoreAuth: true, projectName },
      errorPolicy: 'all',
      returnPartialData: true,
    },
  );

  useInvalidateProjectData(refetch);

  if (error) {
    console.log('Error fetching current user:', error);
  }

  const userObject = useMemo(
    () => get(data, [useGhostQuery ? 'ghostUser' : 'currentUser', 'user']),
    [data, useGhostQuery],
  );

  const { loading: tokenLoading } = useSaveGhostUserData(
    projectName,
    ghostUserId,
    useGhostQuery,
  );

  const isLoading = loading || tokenLoading;

  const scope = useMemo(
    () => getAuthScope(isLoading && !data, userObject, userType, dataTypes),
    [data, isLoading, dataTypes, userObject, userType],
  );

  useSetScopeFieldValue(loading, AUTH_WRAPPER_ID, scope);

  const userContext = useMemo(
    () => ({
      isGhost: !!useGhostQuery,
      fetchedUser:
        called &&
        (!isLoading || userObject) &&
        (previousGhostUserId === ghostUserId ||
          (isNil(previousGhostUserId) && isNil(ghostUserId))),
      loading: isLoading,
      user: scope,
    }),
    [
      called,
      ghostUserId,
      isLoading,
      previousGhostUserId,
      scope,
      useGhostQuery,
      userObject,
    ],
  );

  return userContext;
};

// Provider component that wraps your app and makes auth scoper object ...
// ... available to any child component that calls useAuthWrapper().
export const AuthWrapperProvider = ({
  forceGhostQuery,
  dataTypes,
  children,
}: {
  forceGhostQuery?: boolean;
  dataTypes: DataTypes;
  children: any;
}) => {
  const auth = useAuthWrapperContext(dataTypes, forceGhostQuery);

  return (
    <authWrapperContext.Provider value={auth}>
      {children}
    </authWrapperContext.Provider>
  );
};

export const useAuthWrapper = () => useContext(authWrapperContext);

export default useAuthWrapper;
