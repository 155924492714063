import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'switch-horizontal',
  'IconSwitchHorizontal',
  [
    ['path', { d: 'M16 3l4 4l-4 4', key: 'svg-0' }],
    ['path', { d: 'M10 7l10 0', key: 'svg-1' }],
    ['path', { d: 'M8 13l-4 4l4 4', key: 'svg-2' }],
    ['path', { d: 'M4 17l9 0', key: 'svg-3' }],
  ],
);
