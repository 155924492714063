import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getColorShade, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormField, Theme } from '@noloco/components';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import { LIGHT } from '@noloco/components/src/constants/surface';
import MarkdownText from '../components/MarkdownText';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SamlLogin from '../components/SamlLogin';
import SocialLogin from '../components/SocialLogin';
import { REQUIRED, SETUP_REQUIRED } from '../constants/twoFactorAuth';
import MailSent from '../img/undraw/MailSent';
import { ProjectSettings } from '../models/Project';
import { User } from '../models/User';
import {
  projectIntegrationsSelector,
  projectMediaSelector,
  projectNameSelector,
} from '../selectors/projectSelectors';
import {
  isMagicLinkSignInEnabled,
  isPasswordSignInEnabled,
  isSsoEnabled,
} from '../utils/auth';
import { extractErrorMessages } from '../utils/errors';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import useRouter from '../utils/hooks/useRouter';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';
import { TwoFactorAuthLogin } from './TwoFactorAuthLogin';
import { TwoFactorAuthSetup } from './TwoFactorAuthSetup';

interface Props {
  logo: any;
  preview?: boolean;
  settings: ProjectSettings;
  theme: Theme;
}

const Login = ({ logo, preview = false, settings, theme }: Props) => {
  const {
    query: { redirectPath, email: providedEmail },
    push,
  } = useRouter();

  const projectName = useSelector(projectNameSelector);
  const integrations = useSelector(projectIntegrationsSelector);
  const media = useSelector(projectMediaSelector);

  const [errors, setErrors] = useState([]);
  const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false);
  const [email, setEmail] = useState(decodeURIComponent(providedEmail ?? ''));
  const [password, setPassword] = useState('');
  const { login, magicLinkLogin } = useAuth();
  const updateUserCache = useUpdateUserCache();

  const [requiresSecondFactor, setRequiresSecondFactor] = useState<
    typeof REQUIRED | typeof SETUP_REQUIRED | undefined
  >(undefined);
  const [secondFactorAuthToken, setSecondFactorAuthToken] = useState<
    string | undefined
  >(undefined);

  const primaryColor = useMemo(
    () => theme.brandColors.primary,
    [theme.brandColors.primary],
  );

  const {
    googleClientId,
    loginSettings,
    ssoEnabled,
    openSignUpEnabled,
    passwordSignInEnabled,
    magicLinkSignInEnabled,
    showEmailInput,
  } = useMemo(() => {
    const passwordSignInEnabled = isPasswordSignInEnabled(settings);
    const magicLinkSignInEnabled = isMagicLinkSignInEnabled(settings);
    const googleClientId = get(integrations, 'google.signIn.clientId', null);

    return {
      googleClientId,
      loginSettings: get(settings, 'login', null),
      ssoEnabled: isSsoEnabled(settings),
      openSignUpEnabled: get(settings, 'openSignUp.enabled', false),
      passwordSignInEnabled,
      magicLinkSignInEnabled,
      showEmailInput:
        passwordSignInEnabled || magicLinkSignInEnabled || !googleClientId,
    };
  }, [integrations, settings]);

  const title = useMemo(
    () => loginSettings?.title || getText('auth.login.title'),
    [loginSettings?.title],
  );

  const subTitle = useMemo(() => {
    if (openSignUpEnabled) {
      return (
        <span>
          <span>{getText('auth.login.or')}</span>
          <Link
            to="/register"
            className={classNames(
              'ml-1 font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
              `text-${getColorShade(
                primaryColor,
                600,
              )} hover:text-${getColorShade(primaryColor, 600)}`,
            )}
          >
            {getText('auth.login.appRegisterLink')}
          </Link>
        </span>
      );
    }

    if (loginSettings?.subTitle) {
      return (
        <MarkdownText disabledHeadings={true}>
          {loginSettings.subTitle}
        </MarkdownText>
      );
    }

    return null;
  }, [openSignUpEnabled, primaryColor, loginSettings]);

  const handleOnSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      setErrors([]);

      if (!hasSubmittedEmail) {
        if (magicLinkSignInEnabled) {
          magicLinkLogin(email).catch((error: any) => {
            setErrors(
              error.graphQLErrors?.map((er: any) => er.message) ?? [
                error.message,
              ],
            );
            console.warn('ERROR', error);
          });
        }
        setHasSubmittedEmail(true);
      } else {
        login(email, password)
          .then((res: any) => {
            if (res?.requiresSecondFactor) {
              setSecondFactorAuthToken(res.secondFactorAuthToken);
              setRequiresSecondFactor(res.requiresSecondFactor);

              return;
            } else {
              updateUserCache(res);

              if (redirectPath) {
                push(decodeURIComponent(redirectPath));
              } else {
                push('/');
              }
            }
          })
          .catch((error: any) => {
            const errors = extractErrorMessages(error);

            if (errors.length > 0) {
              // @ts-expect-error TS(2345): Argument of type 'String[]' is not assignable to p... Remove this comment to see the full error message
              setErrors(errors);
            }

            console.warn('ERROR', JSON.stringify(error, undefined, 2));
          });
      }
    },
    [
      email,
      hasSubmittedEmail,
      login,
      magicLinkLogin,
      magicLinkSignInEnabled,
      password,
      push,
      redirectPath,
      updateUserCache,
    ],
  );

  useEffect(() => {
    /*
     * If an email is provided in the query params, we want to automatically
     * submit the form and trigger the magic link login.
     *
     * This allows for us to handle fallback for SAML logins where the user
     * didn't match any provider rules.
     */
    if (providedEmail && !hasSubmittedEmail) {
      handleOnSubmit({ preventDefault: () => null });
    }
  }, [handleOnSubmit, hasSubmittedEmail, providedEmail, setEmail]);

  const onSocialLogin = (user: User) => {
    updateUserCache(user);
  };

  const { src: logoUrl = getProjectAuthLogo(settings, media) } = logo || {};

  if (ssoEnabled && !providedEmail) {
    return (
      <SamlLogin
        logo={logo}
        media={media}
        preview={preview}
        projectName={projectName}
        settings={settings}
        redirectPath={redirectPath}
      />
    );
  }

  return (
    <div
      className={classNames(
        'flex w-full flex-col items-center justify-center overflow-hidden bg-gray-100',
        { 'min-h-screen': !preview },
      )}
    >
      {!requiresSecondFactor && (
        <SimpleLayout
          errors={errors}
          logoUrl={logoUrl}
          onSubmit={handleOnSubmit}
          titleText={title}
          subTitleText={subTitle}
        >
          <div className="mx-2 mt-6 overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
            {!hasSubmittedEmail && (
              <>
                {showEmailInput && (
                  <>
                    <FormField
                      aria-label="email"
                      autoComplete="email"
                      name="email"
                      disabled={preview}
                      type="text"
                      onChange={({ target: { value } }: any) => setEmail(value)}
                      required
                      errorType="below-solid"
                      label={getText('auth.fields.email')}
                      placeholder=""
                      value={email}
                      surface={LIGHT}
                    />
                    <SubmitButton disabled={!email || preview}>
                      {getText('auth.login.next')}
                    </SubmitButton>
                  </>
                )}
                {googleClientId && (
                  <SocialLogin
                    clientId={googleClientId}
                    loginPath={redirectPath || '/'}
                    setErrors={setErrors}
                    onLogin={onSocialLogin}
                    hideOr={!showEmailInput}
                  />
                )}
              </>
            )}
            {hasSubmittedEmail && (
              <>
                {magicLinkSignInEnabled && (
                  <>
                    <div
                      className={`mx-auto mb-4 w-24 overflow-hidden text-${getColorShade(
                        primaryColor,
                        500,
                      )}`}
                    >
                      <MailSent />
                    </div>
                    <h3 className="my-3 text-center text-base font-medium tracking-wider">
                      {getText('auth.login.magicLink.title')}
                    </h3>
                    <p className="mb-3 text-center text-sm">
                      {getText({ email }, 'auth.login.magicLink.subtitle')}
                    </p>
                  </>
                )}
                {magicLinkSignInEnabled && passwordSignInEnabled && (
                  <div className="my-6 flex items-center">
                    <span className="h-px w-full bg-gray-300" />
                    <span className="mx-3 text-center text-sm uppercase text-gray-500">
                      {getText('auth.login.or')}
                    </span>
                    <span className="h-px w-full bg-gray-300" />
                  </div>
                )}
                {passwordSignInEnabled && (
                  <>
                    <h3 className="my-3 text-center font-medium tracking-wider">
                      {getText('auth.login.magicLink.password')}
                    </h3>
                    <FormField
                      aria-label="email"
                      autoComplete="email"
                      name="email"
                      className="hidden"
                      type="text"
                      readOnly={true}
                      onChange={({ target: { value } }: any) => setEmail(value)}
                      required
                      errorType="below-solid"
                      label={getText('auth.fields.email')}
                      placeholder=""
                      value={email}
                      surface={LIGHT}
                    />
                    <FormField
                      aria-label="password"
                      autoComplete="password"
                      className="mt-3"
                      name="password"
                      type="password"
                      onChange={({ target: { value } }: any) =>
                        setPassword(value)
                      }
                      required
                      errorType="below-solid"
                      label=""
                      placeholder={getText('auth.fields.password')}
                      value={password}
                      surface={LIGHT}
                    />
                    <SubmitButton disabled={!email || !password}>
                      {getText('auth.login.button')}
                    </SubmitButton>
                    <div className="mt-3 w-full text-center text-xs leading-5">
                      <Link
                        to="/forgot"
                        className={classNames(
                          'font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
                          `text-${getColorShade(primaryColor, 500)}`,
                          `hover:text-${getColorShade(primaryColor, 600)}`,
                        )}
                      >
                        {getText('auth.login.forgot')}
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </SimpleLayout>
      )}
      {secondFactorAuthToken && requiresSecondFactor && (
        <div className="flex w-full flex-col items-center justify-center overflow-hidden bg-gray-100">
          <div className="flex w-full justify-center px-6 py-7 sm:px-0">
            <div className="w-full max-w-sm">
              {logoUrl && (
                <img className="mx-auto h-10 w-auto" src={logoUrl} alt="logo" />
              )}
              <h2 className="mt-4 text-center text-xl font-extrabold leading-9 text-gray-900">
                {getText(
                  'auth.twoFactorAuth',
                  `${requiresSecondFactor === REQUIRED ? 'title' : 'setupTitle'}`,
                )}
              </h2>
              <p className="mt-1.5 text-center text-sm leading-5 text-gray-600 sm:px-2">
                {getText('auth.twoFactorAuth.setupSubtitle')}
              </p>
              <div className="mx-2 mt-6 flex flex-col items-center overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
                {requiresSecondFactor === SETUP_REQUIRED ? (
                  <TwoFactorAuthSetup
                    secondFactorAuthToken={secondFactorAuthToken}
                    redirectPath={redirectPath}
                    updateUserCache={updateUserCache}
                  />
                ) : (
                  <TwoFactorAuthLogin
                    secondFactorAuthToken={secondFactorAuthToken}
                    redirectPath={redirectPath}
                    updateUserCache={updateUserCache}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <PoweredByNoloco
        className="mx-auto flex-wrap justify-center text-gray-800"
        projectName={projectName}
        utmSource="noloco_login"
      />
    </div>
  );
};

export default withTheme(Login);
