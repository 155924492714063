import React from 'react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

interface Props {
  className?: string;
  children: any;
  surface?: Surface;
}

const SimpleTableBody = ({ className, children, surface = DARK }: Props) => (
  <tbody
    className={classNames(className, 'divide-y', {
      'divide-slate-900 bg-slate-800': surface === DARK,
      'divide-gray-100 bg-white': surface === LIGHT,
    })}
  >
    {children}
  </tbody>
);

export default SimpleTableBody;
