import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconLock,
  IconPlus,
  IconShieldCheck,
  IconShieldLock,
} from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';
import shortId from 'shortid';
import {
  Badge,
  Button,
  HelpTooltip,
  Loader,
  Modal,
  Switch,
  TextInput,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { CREATE, UPDATE } from '@noloco/core/src/constants/actionTypes';
import { DEFAULT_ROLES } from '@noloco/core/src/constants/defaultRoleReferences';
import { USER_ROLES } from '@noloco/core/src/constants/features';
import { PROJECT } from '@noloco/core/src/constants/usage';
import DeleteItemConfirmButton from '@noloco/core/src/elements/sections/DeleteItemConfirmButton';
import { Role } from '@noloco/core/src/models/User';
import {
  getCollectionDataQueryString,
  getMutationQueryString,
} from '@noloco/core/src/queries/project';
import {
  decrementUsage,
  incrementUsage,
} from '@noloco/core/src/reducers/billingPlan';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  USER_ROLE_ADDED,
  USER_ROLE_UPDATED,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { removeDataItemFromCollectionCache } from '@noloco/core/src/utils/apolloCache';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { useUserRoles } from '../../utils/hooks/useUserRoles';
import FeatureLockedButton from '../FeatureLockedButton';
import Guide from '../Guide';
import ProFeatureBadge from '../ProFeatureBadge';

const DRAFT_ROLE = {
  name: '',
  internal: false,
  dataAdmin: false,
  builder: false,
  enabled: true,
};

const ROLE_FIELDS = {
  id: true,
  name: true,
  referenceId: true,
  internal: true,
  dataAdmin: true,
  builder: true,
};

const ROLE = 'role';

const UserRolesSettings = () => {
  const project = useSelector(projectDataSelector);
  const dispatch = useDispatch();

  const { push, location } = useRouter();

  const [loading, setLoading] = useState(false);
  const queryOptions = {
    context: {
      authQuery: true,
      projectQuery: true,
      projectName: project.name,
    },
  };
  const queryString = getCollectionDataQueryString(ROLE, {
    edges: {
      node: ROLE_FIELDS,
    },
    totalCount: true,
  });
  const roleDataType = useMemo(
    () => project.dataTypes.getByName(ROLE)!,
    [project.dataTypes],
  );
  const creationQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(CREATE, ROLE, roleDataType.fields, ROLE_FIELDS)}
    `,
    [roleDataType.fields],
  );
  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(UPDATE, ROLE, roleDataType.fields, ROLE_FIELDS)}
    `,
    [roleDataType.fields],
  );

  const [createRole, { client }] = useMutation(
    creationQueryString,
    queryOptions,
  );
  const [updateRole] = useMutation(updateQueryString, queryOptions);

  const [selectedRole, setSelectedRole] = useState<Omit<Role, 'id'> | null>(
    null,
  );

  const {
    loading: dataLoading,
    refetch,
    roles,
  } = useUserRoles(project.name, project.dataTypes, {
    internal: true,
    dataAdmin: true,
    builder: true,
  });

  if (selectedRole && !(selectedRole as any).id) {
    roles.push(selectedRole);
  }

  useEffect(() => {
    const roleIds = roles.map((r: any) => r.id);

    if (
      !dataLoading &&
      roles.length > 0 &&
      (!selectedRole || roleIds.indexOf((selectedRole as any).id) < 0)
    ) {
      setSelectedRole(roles[0]);
    }
  }, [dataLoading, roles, selectedRole]);

  const onAddRole = () => {
    dispatch(incrementUsage({ category: PROJECT, property: USER_ROLES }));
    setSelectedRole({ ...DRAFT_ROLE, referenceId: shortId.generate() });
  };

  const onDeleteRole = (deletedRole: any) => {
    dispatch(decrementUsage({ category: PROJECT, property: USER_ROLES }));
    // @ts-expect-error TS(2554): Expected 5-6 arguments, but got 4.
    removeDataItemFromCollectionCache(
      deletedRole,
      client,
      gql`
        ${queryString}
      `,
      ROLE,
    );
    setSelectedRole(null);
  };

  const onUpdateRoleName = ({ target: { value } }: any) => {
    setSelectedRole({
      ...selectedRole,
      name: value,
    });
  };

  const onUpdateRoleInternal = (internal: any) => {
    setSelectedRole({
      ...selectedRole,
      internal,
    });
  };

  const onUpdateRoleDataAdmin = (dataAdmin: any) => {
    setSelectedRole({
      ...selectedRole,
      dataAdmin,
    });
  };

  const onUpdateRoleBuilder = (builder: any) => {
    setSelectedRole({
      ...selectedRole,
      builder,
    });
  };

  const onSave = (event: any) => {
    event.preventDefault();

    if (!selectedRole) {
      return null;
    }

    const mutation = selectedRole.id ? updateRole : createRole;

    trackEvent(
      selectedRole.id ? USER_ROLE_UPDATED : USER_ROLE_ADDED,
      undefined,
      undefined,
      {
        internal: selectedRole.internal,
        builder: selectedRole.builder,
        dataAdmin: selectedRole.dataAdmin,
      },
    );

    const dataKey = selectedRole.id ? 'update' : 'create';
    setLoading(true);
    mutation({
      variables: {
        id: selectedRole.id,
        name: selectedRole.name,
        internal: isDefaultRole ? undefined : selectedRole.internal,
        builder: isDefaultRole ? undefined : selectedRole.builder,
        dataAdmin: isDefaultRole ? undefined : selectedRole.dataAdmin,
        referenceId: isDefaultRole ? undefined : selectedRole.referenceId,
      },
    })
      .then(({ data }) => {
        const newRole = data[`${dataKey}Role`];

        if (newRole) {
          refetch().then(() => {
            setSelectedRole(newRole);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isDefaultRole = useMemo(
    () =>
      selectedRole && DEFAULT_ROLES.includes((selectedRole as any).referenceId),
    [selectedRole],
  );

  return (
    <Modal
      canCancel={false}
      confirmText={getText('data.dataTypes.roles.done')}
      onConfirm={() => push(`/_/users${location.search}`)}
      closeOnOutsideClick={false}
    >
      <Guide
        className="mb-2"
        href="https://guides.noloco.io/users-and-permissions/user-roles-and-permissions"
      >
        {getText('data.dataTypes.roles.guide')}
      </Guide>
      <div className="relative mb-4 flex items-start">
        <div className="mt-1 flex-shrink-0">
          <IconShieldLock className="text-gray-800" size={24} />
        </div>
        <p className="ml-4 text-sm">
          {getText('data.dataTypes.roles.explanation')}
        </p>
      </div>
      {!dataLoading && roles.length > 0 && (
        <div className="flex flex-col">
          <div className="flex w-full">
            <div className="w-56 border-r border-gray-600 p-4">
              <div className="flex flex-col space-y-2">
                {roles.map((role: any) => (
                  <div
                    key={role.id}
                    onClick={() => setSelectedRole(role)}
                    className={classNames(
                      'flex cursor-pointer items-start justify-between rounded-lg p-2 text-sm',
                      selectedRole && (selectedRole as any).id === role.id
                        ? 'bg-gray-200 font-medium text-gray-800'
                        : 'hover:bg-gray-100',
                    )}
                  >
                    <span>
                      {role.name || getText('data.dataTypes.roles.placeholder')}
                    </span>
                    {!role.enabled && (
                      <div className="my-0.5 flex">
                        <ProFeatureBadge
                          compact={true}
                          feature={USER_ROLES}
                          inline={true}
                          showAfterTrial={!role.enabled}
                        />
                      </div>
                    )}
                    {role.id && (
                      <Tooltip content={getText('data.dataTypes.roles.id')}>
                        <span className="mt-px opacity-75">#{role.id}</span>
                      </Tooltip>
                    )}
                  </div>
                ))}
              </div>
              <hr className="my-5 w-full border-gray-600 px-2" />
              <FeatureLockedButton
                currentNumber={roles.length}
                feature={USER_ROLES}
                onClick={onAddRole}
              >
                <IconPlus className="mr-2 opacity-75" size={16} />
                {getText('data.dataTypes.roles.new')}
              </FeatureLockedButton>
            </div>
            {selectedRole && (
              <div className="flex flex-grow p-4">
                <form className="flex w-full flex-col" onSubmit={onSave}>
                  <div className="flex flex-col">
                    <div className="mb-2 flex items-center justify-between space-x-2">
                      <label className="whitespace-nowrap text-sm font-medium tracking-wide">
                        {getText('data.dataTypes.roles.name')}
                      </label>
                      {isDefaultRole && (
                        <Badge
                          color="teal"
                          className="flex items-center space-x-2"
                        >
                          <IconLock size={14} className="opacity-75" />
                          <span>{getText('data.dataTypes.roles.default')}</span>
                        </Badge>
                      )}
                    </div>
                    <TextInput
                      value={(selectedRole as any).name}
                      readOnly={!(selectedRole as any).enabled}
                      onChange={onUpdateRoleName}
                      placeholder={getText('data.dataTypes.roles.placeholder')}
                      surface={LIGHT}
                    />
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex items-center">
                      <label className="mr-2 whitespace-nowrap text-sm font-medium tracking-wide">
                        {getText('data.dataTypes.roles.internal.label')}
                      </label>
                      <HelpTooltip className="text-teal-600">
                        {getText('data.dataTypes.roles.internal.help')}
                      </HelpTooltip>
                    </div>
                    <Switch
                      className="ml-auto"
                      disabled={isDefaultRole || !(selectedRole as any).enabled}
                      value={(selectedRole as any).internal}
                      onChange={onUpdateRoleInternal}
                      size="sm"
                    />
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex items-center">
                      <label className="mr-2 whitespace-nowrap text-sm font-medium tracking-wide">
                        {getText('data.dataTypes.roles.dataAdmin.label')}
                      </label>
                      <HelpTooltip className="text-teal-600">
                        {getText('data.dataTypes.roles.dataAdmin.help')}
                      </HelpTooltip>
                    </div>
                    <Switch
                      className="ml-auto"
                      disabled={isDefaultRole || !(selectedRole as any).enabled}
                      value={(selectedRole as any).dataAdmin}
                      onChange={onUpdateRoleDataAdmin}
                      size="sm"
                    />
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex items-center">
                      <label className="mr-2 whitespace-nowrap text-sm font-medium tracking-wide">
                        {getText('data.dataTypes.roles.builder.label')}
                      </label>
                      <HelpTooltip className="text-teal-600">
                        {getText('data.dataTypes.roles.builder.help')}
                      </HelpTooltip>
                    </div>
                    <Switch
                      className="ml-auto"
                      disabled={isDefaultRole || !(selectedRole as any).enabled}
                      value={(selectedRole as any).builder}
                      onChange={onUpdateRoleBuilder}
                      size="sm"
                    />
                  </div>
                  <div className="mt-8 flex w-full items-center space-x-4">
                    <Button
                      disabled={
                        !(selectedRole as any).name ||
                        loading ||
                        !(selectedRole as any).enabled
                      }
                      className=""
                      submitFormOnClick={true}
                    >
                      {getText('data.dataTypes.roles.save')}
                    </Button>
                    {(selectedRole as any).id && !isDefaultRole && (
                      <DeleteItemConfirmButton
                        className="flex items-center"
                        confirmDeleteText={(selectedRole as any).name}
                        dataType={roleDataType}
                        itemId={(selectedRole as any).id}
                        onDelete={onDeleteRole}
                        project={project}
                      >
                        <span>{getText('data.dataTypes.roles.delete')}</span>
                      </DeleteItemConfirmButton>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
      {dataLoading && (
        <div className="flex h-48 w-full items-center justify-center">
          <Loader type="Bars" size="md" className="text-brand-dark" />
        </div>
      )}
      {!dataLoading && roles.length === 0 && (
        <div className="flex h-48 flex-col items-center justify-center text-center">
          <h2 className="text-lg">{getText('data.dataTypes.roles.empty')}</h2>
          <Button className="mt-4" onClick={onAddRole}>
            <div className="flex items-center">
              <IconShieldCheck className="mr-2 opacity-75" size={16} />
              <span>{getText('data.dataTypes.roles.new')}</span>
            </div>
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default UserRolesSettings;
