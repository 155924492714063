import { useMemo } from 'react';
import {
  IconBolt,
  IconDatabase,
  IconFile,
  IconSettings,
  IconUsers,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, Tooltip } from '@noloco/components';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import {
  dataTypesSelector,
  hasUnpublishedChangesSelector,
  projectCreatorSelector,
  projectDomainsSelector,
  projectNameSelector,
  publishedVersionSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { LOGO_CLICKED, trackEvent } from '@noloco/core/src/utils/analytics';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useIsTrialExpired from '@noloco/core/src/utils/hooks/useIsTrialExpired';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useScript from '@noloco/core/src/utils/hooks/useScript';
import { useSegmentAdminDestinationsMiddleware } from '@noloco/core/src/utils/hooks/useSegmentSourceMiddlewares';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import { useSubscribeToProjectUpdates } from '../utils/hooks/projectHooks';
import useAppPageUrl from '../utils/hooks/useAppPageUrl';
import useBuilderOnlyScripts from '../utils/hooks/useBuilderOnlyScripts';
import useFetchProjectForSettings from '../utils/hooks/useFetchProjectForSettings';
import AirtableOauthAlert from './AirtableOauthAlert';
import DataTabWarningDot from './DataTabWarningDot';
import FullstoryRecording from './FullstoryRecording';
import HelpPopover from './HelpPopover';
import PublishButton from './PublishButton';
import SmartSuiteOAuthAlert from './SmartSuiteOAuthAlert';
import TrialProgressTooltip from './TrialProgressTooltip';
import OnboardingChecklistRoute from './onboarding/OnboardingChecklistRoute';

export const LeftTabs = {
  ELEMENTS: 'elements',
  DATA: 'data',
  WORKFLOWS: 'workflows',
  USERS: 'users',
  SETTINGS: 'settings',
};

export const leftTabIcons = {
  [LeftTabs.ELEMENTS]: IconFile,
  [LeftTabs.DATA]: IconDatabase,
  [LeftTabs.WORKFLOWS]: IconBolt,
  [LeftTabs.USERS]: IconUsers,
  [LeftTabs.SETTINGS]: IconSettings,
};

export const TRIAL_EXPIRED_NAV_ITEMS = [
  LeftTabs.ELEMENTS,
  LeftTabs.USERS,
  LeftTabs.SETTINGS,
];

const INSTATUS_EMBED_CODE = 'https://status.noloco.io/widget/script.js';

const AdminNavbar = ({ settings, showBuilderExp, showDataTab }: any) => {
  const {
    pathname,
    query: { __onboarding },
  } = useRouter();

  const dataTypes = useSelector(dataTypesSelector);
  const projectName = useSelector(projectNameSelector);
  const currentPublishedProjectVersion = useSelector(publishedVersionSelector);
  const domains = useSelector(projectDomainsSelector);

  const hasUnpublishedChanges = useSelector(hasUnpublishedChangesSelector);
  const publishedVersion = useSelector(publishedVersionSelector);
  const creator = useSelector(projectCreatorSelector);

  const { user } = useAuth();
  const { isBuilder, isNolocoAdmin } = useIsBuilder();

  const isTrialExpired = useIsTrialExpired();
  const hideRoutesWhenTrialExpired = useMemo(
    () => isTrialExpired && !isNolocoAdmin,
    [isNolocoAdmin, isTrialExpired],
  );

  const { loading } = useFetchProjectForSettings(!isBuilder);

  useSubscribeToProjectUpdates();
  useBuilderOnlyScripts();
  useScript(INSTATUS_EMBED_CODE);
  useSegmentAdminDestinationsMiddleware(!!isBuilder && !!user?._nolocoUserId);

  const appPageUrl = useAppPageUrl();

  return (
    <div className="flex h-screen w-16 flex-col border-r border-slate-700 bg-slate-900 text-white sm:hidden">
      <a
        href={APP_DASHBOARD_URL}
        className="block h-16 w-16 px-3 py-4"
        onClick={() => {
          trackEvent(LOGO_CLICKED);
        }}
      >
        <NolocoIcon className="h-full bg-slate-900 text-white hover:text-cyan-500" />
      </a>
      {[
        LeftTabs.ELEMENTS,
        ...(showDataTab ? [LeftTabs.DATA, LeftTabs.USERS] : []),
        ...(showBuilderExp ? [LeftTabs.WORKFLOWS, LeftTabs.SETTINGS] : []),
      ].map((tab) => {
        if (
          hideRoutesWhenTrialExpired &&
          !TRIAL_EXPIRED_NAV_ITEMS.includes(tab)
        ) {
          return null;
        }

        const Icon = leftTabIcons[tab];
        const isActive =
          tab === LeftTabs.ELEMENTS
            ? !pathname.startsWith(`/_/`)
            : pathname.startsWith(`/_/${tab}`);

        return (
          <Tooltip
            content={getText('leftSidebar.nav', tab)}
            placement="right"
            key={tab}
            bg="white"
          >
            <Link
              key={tab}
              className={classNames(
                'relative flex h-10 cursor-pointer items-center justify-center px-3 py-2',
                {
                  'bg-slate-900 text-white': isActive,
                  'bg-slate-900 text-slate-500 hover:text-slate-200': !isActive,
                },
              )}
              to={tab === LeftTabs.ELEMENTS ? appPageUrl : `/_/${tab}`}
              data-tour={`${tab}-link`}
              data-testid={`admin-nav-${tab.toLowerCase()}`}
            >
              <Icon size={20} className="mx-auto" />
              {tab === LeftTabs.DATA && (
                <DataTabWarningDot dataTypes={dataTypes} />
              )}
            </Link>
          </Tooltip>
        );
      })}
      {showBuilderExp && !hideRoutesWhenTrialExpired && (
        <PublishButton
          domains={domains}
          hasUnpublishedChanges={hasUnpublishedChanges}
          projectName={projectName}
          currentPublishedProjectVersion={currentPublishedProjectVersion}
          publishedVersion={publishedVersion}
        />
      )}
      <HelpPopover settings={settings} />
      {loading && (
        <div className="my-2 flex items-center justify-center">
          <Loader size="sm" />
        </div>
      )}
      <OnboardingChecklistRoute />
      <div className="mb-4 mt-auto flex flex-col space-y-1">
        {/* @ts-expect-error TS(2322): Type '{ startDate: any; }' is not assignable to ty... Remove this comment to see the full error message */}
        <TrialProgressTooltip startDate={get(creator, 'createdAt')} />
      </div>
      <FullstoryRecording projectName={projectName} />
      {isBuilder && <AirtableOauthAlert />}
      {isBuilder && <SmartSuiteOAuthAlert />}
    </div>
  );
};

export default AdminNavbar;
