import { useMemo } from 'react';
import { IconArrowLeft, IconChevronRight } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  LeftEditorSectionOptions,
  OPTIONS,
  VISIBILITY,
} from '../../../constants/buildMode';
import { USER } from '../../../constants/builtInDataTypes';
import { PAGE } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { ElementPath } from '../../../models/Element';
import {
  setLeftEditorSection,
  setSelectedSectionPath,
} from '../../../reducers/elements';
import { selectedSectionSelector } from '../../../selectors/elementsSelectors';
import { projectDataSelector } from '../../../selectors/projectSelectors';
import useEditorTabs from '../../../utils/hooks/useEditorTabs';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import BuildModeVisibilityTab from '../BuildModeVisibilityTab';
import { isPageFullScreenIframe } from '../LeftBuildModePageEditor';
import StandaloneRightBuildMode from '../StandaloneRightBuildMode';
import BuildModePageOptionsTab from './BuildModePageOptionsTab';

export interface BuildModePageEditorProps {
  elementPath: ElementPath;
  isRecordView?: boolean;
  leftEditor?: LeftEditorSectionOptions | null;
  page: Page;
}

const DEBOUNCE_TIME = 300;
const EditorTabMap = {
  [OPTIONS]: BuildModePageOptionsTab,
  [VISIBILITY]: BuildModeVisibilityTab,
};

const BuildModePageEditor = ({
  elementPath,
  isRecordView = false,
  leftEditor,
  page,
}: BuildModePageEditorProps) => {
  const dispatch = useDispatch();
  const selectedSectionPath = useSelector(selectedSectionSelector);
  const sections = get(page, 'props.sections');
  const section = get(sections, selectedSectionPath);
  const project = useSelector(projectDataSelector);
  const [updateProperty] = useUpdateProperty(elementPath, project);
  const debounceUpdateProperty = useMemo(
    () => debounce(updateProperty, DEBOUNCE_TIME),
    [updateProperty],
  );
  const [editorTab, setEditorTab] = useEditorTabs('blankPage', OPTIONS);
  const pageIsIframe = useMemo(() => isPageFullScreenIframe(page), [page]);
  const Editor = useMemo(() => EditorTabMap[editorTab], [editorTab]);

  const dataType = useMemo(() => {
    const typeName = get(
      selectedSectionPath.length > 0 ? section : page,
      'props.dataList.dataType',
    );

    return (
      (typeName && project.dataTypes.getByName(typeName)) ??
      project.dataTypes.getByName(USER)
    );
  }, [page, section, project.dataTypes, selectedSectionPath]);

  return (
    <div className="w-full">
      <div className="sticky top-0 z-10 flex h-12 w-full items-center space-x-2 border-b border-gray-700 bg-slate-800 px-2 text-gray-300">
        {leftEditor && !pageIsIframe ? (
          <IconArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => {
              dispatch(setSelectedSectionPath([]));
              dispatch(setLeftEditorSection(null));
            }}
          />
        ) : (
          <Icon icon={page.props.icon} className="h-5 w-5" />
        )}
        <div className="flex items-center space-x-2">
          <span>{page.props.name}</span>
          {leftEditor && !pageIsIframe && (
            <>
              <IconChevronRight size={12} className="text-gray-400" />
              <span>
                {section?.name ?? getText('elements', leftEditor, 'title')}
              </span>
            </>
          )}
        </div>
      </div>
      {leftEditor || pageIsIframe ? (
        <StandaloneRightBuildMode
          dataType={dataType}
          debouncedUpdateProperty={debounceUpdateProperty}
          element={page}
          elementPath={elementPath}
          elementType={PAGE}
          isRecordView={isRecordView}
          project={project}
          section={section}
          selectedSectionPath={selectedSectionPath}
          updateProperty={updateProperty}
        />
      ) : (
        <>
          <div className="sticky top-12 z-10 w-full bg-slate-800">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={PAGE}
              setEditorTab={setEditorTab}
            />
          </div>
          <Editor
            dataType={dataType}
            debouncedUpdateProperty={debounceUpdateProperty}
            element={page}
            elementPath={elementPath}
            project={project}
            updateProperty={updateProperty}
            visibilityRules={page.visibilityRules}
          />
        </>
      )}
    </div>
  );
};

export default BuildModePageEditor;
