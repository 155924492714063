import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import { Tooltip } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import Avatar from '../../../components/Avatar';
import MarkdownText from '../../../components/MarkdownText';
import { COMMENT_MENTION_REGEX } from '../../../constants/comments';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getFullName } from '../../../utils/user';
import FilePreview from '../forms/FilePreview';
import RecordCommentMenu from './RecordCommentMenu';

const Mention = ({ id, mentionedUsers }: any) => {
  const user = useMemo(
    () =>
      get(mentionedUsers, 'edges', []).find(
        (userEdge: any) => userEdge.node.id === String(id),
      ),
    [id, mentionedUsers],
  );

  if (!user) {
    return null;
  }

  return (
    <span
      className={classNames(
        `mr-0.5 rounded bg-blue-200 p-0.5 dark:bg-blue-900`,
      )}
    >
      {getFullName(user.node)}
    </span>
  );
};

const formatCommentTime = (createdAt: any, isDarkModeEnabled: boolean) => {
  const createdAtDt = DateTime.fromISO(createdAt);
  const relativeTime = createdAtDt.toRelativeCalendar();
  const time =
    relativeTime === 'today'
      ? `${createdAtDt.toLocaleString(DateTime.TIME_24_SIMPLE)} • `
      : '';

  return (
    <Tooltip
      placement="top-end"
      content={
        <span className={isDarkModeEnabled ? darkModeColors.text.primary : ''}>
          {createdAtDt.toLocaleString(DateTime.DATETIME_SHORT)}
        </span>
      }
      bg={isDarkModeEnabled ? '' : 'white'}
    >
      <span className="cursor-default">
        {time}
        {/* @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message */}
        {upperFirst(relativeTime)}
      </span>
    </Tooltip>
  );
};

const convertMentionsToComponents = (commentText: any) =>
  commentText.replace(
    COMMENT_MENTION_REGEX,
    (_0: any, _1: any, id: any) => `<Mention id={${id}} />`,
  );

interface Props {
  canDelete: boolean;
  comment: any;
  onDelete?: () => Promise<void>;
}

const RecordComment = ({ canDelete, comment, onDelete }: Props) => {
  const { author, attachments, createdAt, mentionedUsers, text } = comment;
  const [isDarkModeEnabled] = useDarkMode();
  const fileAttachments = get(attachments, 'edges', []).map(
    (edge: any) => edge.node,
  );

  const mentionOverrideConfig = useMemo(
    () => ({
      Mention: {
        component: ({ id }: any) => (
          <Mention id={id} mentionedUsers={mentionedUsers} />
        ),
      },
    }),
    [mentionedUsers],
  );

  return (
    <div
      className={classNames('flex flex-col rounded-lg p-3', {
        'bg-pink-50 dark:bg-pink-900': comment.internal,
      })}
    >
      <div className="flex w-full items-center">
        {author && <Avatar initialsSize="text-xs" user={author} size={6} />}
        {author && (
          <span className="ml-2 text-sm font-medium">
            {author.firstName || ''} {author.lastName || ''}
          </span>
        )}
        <div className="ml-auto text-xs text-gray-500">
          {formatCommentTime(createdAt, isDarkModeEnabled as boolean)}
        </div>
        {canDelete && onDelete && <RecordCommentMenu onDelete={onDelete} />}
      </div>
      {text && (
        <div
          className={`mt-2 text-sm ${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
          }`}
        >
          <MarkdownText
            disabledHeadings={true}
            overrides={mentionOverrideConfig}
            openLinksInNewTab={true}
          >
            {convertMentionsToComponents(text)}
          </MarkdownText>
        </div>
      )}
      {fileAttachments.length > 0 && (
        <div className="my-2 flex items-stretch">
          <FilePreview
            id={comment.uuid}
            files={fileAttachments}
            isMultiple={true}
            readOnly={true}
            maxH={16}
            surface={LIGHT}
          />
        </div>
      )}
    </div>
  );
};

export default memo(RecordComment);
