import React, { useMemo } from 'react';
import classNames from 'classnames';
import { SelectInput } from '@noloco/components';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import { ASC, DESC } from '@noloco/core/src/constants/orderByDirections';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { canBeSorted } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { getSubFieldsAsDataFields } from '@noloco/core/src/utils/objects';

const getSortOptionsForFields = (
  field: DataField,
  path: string[] = [],
): any[] => {
  if (field.type === OBJECT) {
    return [
      {
        label: field.display,
        options: getSubFieldsAsDataFields(field, { includeRoot: true }).reduce(
          (subFieldOptions: any[], subField) =>
            subFieldOptions.concat(
              getSortOptionsForFields(subField, [field.name]),
            ),
          [],
        ),
        value: { field: field.name },
      },
    ];
  }

  return [DESC, ASC].map((sort) => ({
    label: `${field.display}: ${getText(
      'elements.LIST.sort',
      field.type,
      sort,
    )}`,
    value: {
      field: [...path, field.name].join('.'),
      direction: sort,
    },
  }));
};

const findSortOption = (
  path: string[] | undefined,
  direction: string | undefined,
  options: any[],
  depth = 0,
): any | null => {
  if (!direction || !path || path.length === 0 || options.length === 0) {
    return null;
  }

  const sortOption = options.find(
    ({ value = {} }) =>
      direction === value.direction && path.join('.') === value.field,
  );

  if (sortOption) {
    return sortOption;
  }

  const parentOption = options.find(
    ({ value = {} }) => !value.direction && path[depth] === value.field,
  );

  if (
    !parentOption ||
    !parentOption.options ||
    parentOption.options.length < 1
  ) {
    return null;
  }

  return findSortOption(path, direction, parentOption.options, depth + 1);
};

const CollectionSortInput = ({
  className,
  dataType,
  onChange,
  placement,
  value,
}: any) => {
  const sortOptions = useMemo(
    () => [
      {
        label: getText('elements.LIST.sort.default'),
        value: undefined,
      },
      ...(dataType
        ? dataType.fields
            .filter(canBeSorted)
            .reduce(
              (fieldOptions: any, field: any) => [
                ...fieldOptions,
                ...getSortOptionsForFields(field),
              ],
              [],
            )
        : []),
    ],
    [dataType],
  );

  const SortButton = useMemo(
    () =>
      ({ value }: any) => {
        const path = value?.field ? value.field.split('.') : undefined;
        const direction = value?.direction;

        const selectedOption = findSortOption(path, direction, sortOptions);

        return selectedOption
          ? selectedOption.label
          : getText('elements.LIST.sort.default');
      },
    [sortOptions],
  );

  return (
    <SelectInput
      Button={SortButton}
      placement={placement}
      className={classNames('text-black', className)}
      contained={true}
      value={value}
      options={sortOptions}
      onChange={onChange}
      placeholder={getText('elements.LIST.sort.default')}
      searchable={true}
    />
  );
};

export default CollectionSortInput;
