import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconAlertTriangle,
  IconBookmark,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { cloneDeep, isEqual, pick } from 'lodash';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import {
  Button,
  ErrorText,
  FormField,
  Label,
  Loader,
  Surface,
  Switch,
} from '@noloco/components';
import { OUTLINE } from '@noloco/components/src/components/button/buttonTypes';
import HelpTooltip from '@noloco/components/src/components/popover/HelpTooltip';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { LG, MD, SM, XS } from '@noloco/components/src/constants/tShirtSizes';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import baseDataTypes, {
  AI_GENERATION,
  BOOLEAN,
  DATE,
  DURATION,
  DataFieldType,
  FORMULA,
  LOOKUP,
  MULTIPLE_OPTION,
  NUMERIC_DATATYPES,
  OBJECT,
  ROLLUP,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  FieldFormat,
  OBJECT_FORMATS,
  ObjectFieldFormat,
  PHONE_NUMBER,
} from '@noloco/core/src/constants/fieldFormats';
import { SUB_FIELD_CONFIG } from '@noloco/core/src/constants/objects';
import {
  DataField,
  DataFieldOption,
  FieldTypeOptions,
} from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { updateDataType as updateDataTypeState } from '@noloco/core/src/reducers/project';
import {
  DATA_FIELD_UPDATED,
  NEW_DATA_FIELD_SAVED,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { addRelatedFieldsToDataType } from '@noloco/core/src/utils/data';
import { getValueForFieldTypeInput } from '@noloco/core/src/utils/dataTypes';
import { getValidTypeOptionsForFieldSource } from '@noloco/core/src/utils/fieldTypeOptions';
import { allowNegative, sortOptions } from '@noloco/core/src/utils/fields';
import { isFormulaValid } from '@noloco/core/src/utils/formulas/isFormulaValid';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useHasFeatureFlag, {
  AI_GENERATION_FIELDS,
} from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import { useMutableFieldProperties } from '@noloco/core/src/utils/hooks/useMutableFieldProperties';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionType, isOptionValid } from '@noloco/core/src/utils/options';
import {
  getRelationshipFromMultiOpts,
  isMultiField,
  isReverseMultiField,
} from '@noloco/core/src/utils/relationships';
import sampleFieldNames from '../../../constants/sampleFieldNames';
import {
  ADD_DATA_FIELD,
  UPDATE_DATA_TYPE,
  UPSERT_DATA_FIELD,
} from '../../../queries/project';
import {
  useFieldNameValidation,
  useReverseFieldNameValidation,
} from '../../../utils/hooks/useDataTypeNameValidation';
import {
  useAddDataField,
  useUpdateDataField,
} from '../../../utils/hooks/useUpdateDataTypes';
import Guide from '../../Guide';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';
import FieldVariableNameDetails from '../../dataTable/FieldVariableNameDetails';
import AiGenerationFieldEditor from './AiGenerationFieldEditor';
import ChangeFieldTypeWarningModal from './ChangeFieldTypeWarningModal';
import DeleteFieldModal from './DeleteFieldModal';
import ExitFormWarningModal from './ExitFormWarningModal';
import FieldOptionsInput, { cleanOptions } from './FieldOptionsInput';
import FieldTypeInput from './FieldTypeInput';
import FieldTypeOptionsEditor, {
  FIELDS_WITH_TYPE_OPTIONS,
} from './FieldTypeOptionsInput';
import LookupEditor from './LookupEditor';
import OptionEditor from './OptionEditor';
import RelationshipEditor from './RelationshipEditor';
import RollupEditor from './RollupEditor';

export type UpsertedOption = Partial<Omit<DataFieldOption, 'id'>> & {
  display: string;
  order: number;
  id: string | number; //if is termporal ID, it's a uuid string
};
export interface AutoRelationshipConfigInput {
  id?: number;
  lookupFieldId: number;
  sourceFieldId: number;
}

const sampleFieldNameOptions = sampleFieldNames.map((value) => ({
  value,
  label: value,
}));

const NON_UNIQUE_DATA_TYPES = [
  BOOLEAN,
  DATE,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  DURATION,
];

const getDefaultFormValues = (
  value: Partial<
    DataField & { autoRelationshipConfig: AutoRelationshipConfigInput }
  > = {},
) => {
  const isMulti = value.type ? isMultiField(value as DataField) : false;
  const isReverseMulti = value.type
    ? isReverseMultiField(value as DataField)
    : true;
  const isAiGeneration = value.typeOptions?.aiGenerationOptions;

  return {
    id: value.id ?? null,
    displayName: value.display ?? '',
    source: value.source ?? null,
    internal: value.internal ?? false,
    // TODO - in a follow-up formulas should also always be FORMULA here + use provided type
    type: isAiGeneration
      ? AI_GENERATION
      : ((value.type ?? null) as string | null),
    isMulti,
    isReverseMulti,
    unique: value.unique ?? false,
    lookupId: value.lookup?.id ?? null,
    lookupSourceFieldId: value.lookup?.sourceField?.id ?? null,
    lookupValueFieldId: value.lookup?.valueField?.id ?? null,
    rollupRelatedField: value.rollup?.relatedField ?? null,
    rollupId: value.rollup?.id ?? null,
    rollupField: value.rollup?.field ?? null,
    aggregation: value.rollup?.aggregation ?? null,
    options:
      (value && isOptionType(value.type)
        ? (sortOptions(value.options ?? []) as UpsertedOption[])
        : undefined) ?? [],
    reverseName: (value.reverseDisplayName ?? '') as string,
    relatedField: value.relatedField ?? null,
    typeOptions: value.typeOptions ?? ({} as Partial<FieldTypeOptions>),
    autoRelationshipConfig: value?.autoRelationshipConfig
      ? pick(value.autoRelationshipConfig, [
          'id',
          'lookupFieldId',
          'sourceFieldId',
        ])
      : null,
    relationship: getRelationshipFromMultiOpts(isMulti, isReverseMulti),
  };
};

export type FormValues = ReturnType<typeof getDefaultFormValues>;

const getSampleDisplayNameForType = (type: any, dataTypes: any) => {
  const isDataType =
    !baseDataTypes.includes(type) &&
    type !== FORMULA &&
    type !== LOOKUP &&
    type !== ROLLUP &&
    type !== AI_GENERATION;

  if (isDataType) {
    const dt = dataTypes.getByName(type);

    return dt ? dt.display : type;
  } else if (type === LOOKUP) {
    return getText('data.typeInput.lookup.label');
  } else if (type === ROLLUP) {
    return getText('data.typeInput.rollup.label');
  } else if (type === FORMULA) {
    return getText('data.typeInput.formula.label');
  } else if (type === AI_GENERATION) {
    return getText('data.typeInput.aiGeneration.label');
  }

  return String(getText('data.types', type, 'label'))
    .split('(')[0]
    .trim();
};

interface DataFieldFormProps {
  dataType: DataType;
  onClose: () => void;
  dataTypes: DataTypes;
  project: Project;
  surface?: Surface;
  value?: DataField;
  canDelete?: boolean;
}

const DataFieldForm = ({
  dataType,
  onClose,
  dataTypes,
  project,
  surface = DARK,
  value,
  canDelete = false,
}: DataFieldFormProps) => {
  const projectName = useMemo(() => project.name, [project]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [showEditFieldTypeModal, setShowEditFieldTypeModal] =
    useState<boolean>(false);
  const [saveDataField] = useMutation(ADD_DATA_FIELD);
  const [upsertDataField] = useMutation(UPSERT_DATA_FIELD);

  const aiGenerationFieldsEnabled = useHasFeatureFlag(AI_GENERATION_FIELDS);
  const [initialForm] = useState(() => getDefaultFormValues(value));
  type FormKeys = keyof typeof initialForm;

  const [formValues, setFormValues] = useState<FormValues & { isDirty: any }>({
    ...cloneDeep(initialForm),
    isDirty: {},
  });
  const type = useMemo(() => formValues.type, [formValues.type]);
  const relationship = useMemo(
    () => formValues.relationship,
    [formValues.relationship],
  );
  const [providedValueType, setProvidedValueType] =
    useState<DataFieldType | null>(
      value?.typeOptions?.aiGenerationOptions
        ? (value.type as DataFieldType)
        : null,
    );

  const getFormValueSetter = useCallback(
    (key: FormKeys) => (arg: any | ((old: any) => any)) => {
      setFormValues((prevFormValue: any) => {
        const newValue =
          typeof arg === 'function' ? arg(prevFormValue[key]) : arg;

        const isDirty = !isEqual(newValue, initialForm[key]);

        return {
          ...prevFormValue,
          [key]: newValue,
          isDirty: { ...prevFormValue.isDirty, [key]: isDirty },
        };
      });
    },
    [initialForm],
  );

  const setIsMulti = useMemo(
    () => getFormValueSetter('isMulti'),
    [getFormValueSetter],
  );
  const setReverseName = useMemo(
    () => getFormValueSetter('reverseName'),
    [getFormValueSetter],
  );
  const setIsReverseMulti = useMemo(
    () => getFormValueSetter('isReverseMulti'),
    [getFormValueSetter],
  );
  const setAutoRelationshipConfig = useMemo(
    () => getFormValueSetter('autoRelationshipConfig'),
    [getFormValueSetter],
  );

  const setTypeOptions = useMemo(
    () => getFormValueSetter('typeOptions'),
    [getFormValueSetter],
  );
  const setType = useMemo(
    () => getFormValueSetter('type'),
    [getFormValueSetter],
  );
  const setDisplayName = useMemo(
    () => getFormValueSetter('displayName'),
    [getFormValueSetter],
  );
  const setOptions = useMemo(
    () => getFormValueSetter('options'),
    [getFormValueSetter],
  );
  const setUnique = useMemo(
    () => getFormValueSetter('unique'),
    [getFormValueSetter],
  );
  const setLookupSourceFieldId = useMemo(
    () => getFormValueSetter('lookupSourceFieldId'),
    [getFormValueSetter],
  );
  const setLookupValueFieldId = useMemo(
    () => getFormValueSetter('lookupValueFieldId'),
    [getFormValueSetter],
  );
  const setAggregation = useMemo(
    () => getFormValueSetter('aggregation'),
    [getFormValueSetter],
  );
  const setRollupField = useMemo(
    () => getFormValueSetter('rollupField'),
    [getFormValueSetter],
  );
  const setRollupRelatedField = useMemo(
    () => getFormValueSetter('rollupRelatedField'),
    [getFormValueSetter],
  );
  const setRelationship = useMemo(
    () => getFormValueSetter('relationship'),
    [getFormValueSetter],
  );

  const resetForm = () => {
    setFormValues({ ...getDefaultFormValues(), isDirty: {} });
    onClose();
  };
  const isFormDirty = useMemo(
    () => Object.values(formValues.isDirty).some((v) => v),
    [formValues.isDirty],
  );

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.returnValue = `Are you sure you want to leave?`;
    };

    if (isFormDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const handleOnClose = useCallback(() => {
    if (isFormDirty) {
      setShowExitModal(true);

      return;
    }
    onClose();
  }, [isFormDirty, onClose]);

  const onChangeProvidedType = useCallback(
    (newType: DataFieldType | null) => {
      setProvidedValueType(newType);
      const validTypeOptions = getValidTypeOptionsForFieldSource(
        newType as string,
        dataType.source.type,
      );
      setTypeOptions((prevTypeOptions: any) => {
        const newTypeOptions = {
          ...prevTypeOptions,
          format: undefined,
          subFields: undefined,
        };
        Object.entries(validTypeOptions).forEach(([key, value]) => {
          if (type === FORMULA && key === 'formula') {
            return;
          }

          if (type === AI_GENERATION && key === 'aiGenerationOptions') {
            return;
          }

          if (!value) {
            delete newTypeOptions[key];
          }
        });

        return newTypeOptions;
      });
    },
    [dataType.source.type, setTypeOptions, type],
  );

  const addDataField = useAddDataField();
  const updateDataField = useUpdateDataField();
  const errorAlert = useGraphQlErrorAlert();
  const [isLoading, setIsLoading] = useState(false);

  const [showBulkAddScreen, setShowBulkAddScreen] = useState(false);

  const isLookup = useMemo(
    () => type === LOOKUP || (value && value.lookup),
    [type, value],
  );
  const isRollup = useMemo(
    () => type === ROLLUP || (value && value.rollup),
    [type, value],
  );
  const isFormula = useMemo(
    () => type === FORMULA || (value && value.typeOptions?.formula),
    [type, value],
  );
  const isAiGeneration = useMemo(
    () =>
      type === AI_GENERATION ||
      (value && value.typeOptions?.aiGenerationOptions),
    [type, value],
  );
  const isObject = useMemo(
    () => type === OBJECT || (value && value.typeOptions?.format),
    [type, value],
  );

  const [isUpdate] = useState<boolean>(!!value?.id);

  const {
    canChangeFieldDisplay,
    canChangeFieldOptions,
    canChangeFieldType,
    canChangeFieldTypeOptions,
    canChangeFieldOptionsColor,
    canDeleteField,
    canSetFieldAsPrimary,
    canChangeRelationship,
    canChangeFieldCanBeNegative,
    canChangeRollup,
    canChangeLookup,
  } = useMutableFieldProperties(dataType, formValues, providedValueType);

  const showFieldTypeOptions = useMemo(
    () =>
      (isUpdate && canChangeFieldTypeOptions) ||
      ((FIELDS_WITH_TYPE_OPTIONS.includes(type ?? '') ||
        isFormula ||
        isAiGeneration) &&
        !isLookup &&
        !isRollup),
    [
      isUpdate,
      canChangeFieldTypeOptions,
      type,
      isFormula,
      isAiGeneration,
      isLookup,
      isRollup,
    ],
  );

  const dispatch = useDispatch();
  const [updateDataType, { loading: updateLoading }] =
    useMutation(UPDATE_DATA_TYPE);
  const onAllowNegativeChange = useCallback(
    (value: boolean) =>
      setTypeOptions((typeOptions: any) => ({
        ...typeOptions,
        allowNegative: value,
      })),
    [setTypeOptions],
  );

  const setPrimaryField = useCallback(() => {
    if (!value?.id) {
      return;
    }
    updateDataType({
      variables: {
        projectName,
        id: dataType.id,
        primaryField: value.id,
      },
    }).then(({ data }) => {
      const updatedDataType = data.updateDataType;
      dispatch(
        updateDataTypeState(
          addRelatedFieldsToDataType(updatedDataType, dataTypes),
        ),
      );
    });
  }, [
    dataType.id,
    dataTypes,
    dispatch,
    projectName,
    updateDataType,
    value?.id,
  ]);

  const typeIsCustomRelationship = useCallback(
    (type: string | null) =>
      Boolean(
        type &&
          !baseDataTypes.includes(type as any) &&
          !isFormula &&
          !isLookup &&
          !isRollup &&
          !isAiGeneration &&
          !isObject,
      ),
    [isAiGeneration, isFormula, isLookup, isObject, isRollup],
  );

  const onTypeChange = useCallback(
    (
      newType:
        | DataFieldType
        | FieldFormat
        | ObjectFieldFormat
        | typeof FORMULA
        | typeof AI_GENERATION,
    ) => {
      const newTypeIsCustomRelationship = typeIsCustomRelationship(newType);
      const isObjectFormat = OBJECT_FORMATS.includes(
        newType as ObjectFieldFormat,
      );

      if (isObjectFormat) {
        setType(OBJECT);
      } else {
        setType(newType);
      }

      if (
        providedValueType &&
        newType !== FORMULA &&
        newType !== AI_GENERATION
      ) {
        setProvidedValueType(null);
      }

      if (newTypeIsCustomRelationship) {
        setIsReverseMulti(true);
      }

      if (
        !formValues.displayName ||
        getSampleDisplayNameForType(type, dataTypes) === formValues.displayName
      ) {
        setDisplayName(getSampleDisplayNameForType(newType, dataTypes));
      }

      if (formValues.autoRelationshipConfig) {
        if (!newTypeIsCustomRelationship) {
          setAutoRelationshipConfig(null);
        } else {
          setAutoRelationshipConfig((previousConfig: any) => ({
            ...previousConfig,
            lookupFieldId: null,
          }));
        }
      }

      const validTypeOptions = getValidTypeOptionsForFieldSource(
        isObjectFormat ? OBJECT : newType,
        dataType.source.type,
      );
      setTypeOptions((prevTypeOptions: any) => {
        const newTypeOptions = {
          ...prevTypeOptions,
          format: isObjectFormat ? newType : undefined,
          subFields: isObjectFormat
            ? get(SUB_FIELD_CONFIG, [newType])
            : undefined,
        };
        const ObjectOptions = ['format', 'subFields', 'time'];
        Object.entries(validTypeOptions).forEach(([key, value]) => {
          if (!value && !isObjectFormat) {
            if (isObjectFormat && ObjectOptions.includes(key)) {
              return;
            }
            delete newTypeOptions[key];
          }
        });

        if (newType === PHONE_NUMBER) {
          newTypeOptions.format = PHONE_NUMBER;
        }

        if (newType === FORMULA) {
          newTypeOptions.formula = '';
        }

        return newTypeOptions;
      });
    },
    [
      typeIsCustomRelationship,
      providedValueType,
      formValues.displayName,
      formValues.autoRelationshipConfig,
      type,
      dataTypes,
      dataType.source.type,
      setTypeOptions,
      setType,
      setIsReverseMulti,
      setDisplayName,
      setAutoRelationshipConfig,
    ],
  );

  const formulaIsValid = useMemo(() => {
    if (!formValues.typeOptions.formula) {
      return false;
    }

    return isFormulaValid(
      formValues.typeOptions.formula,
      dataType.fields,
      [...formValues.typeOptions.formula.matchAll(/{{([A-z0-9]+)}}/g)].map(
        (match) => match[1],
      ),
    ).valid;
  }, [dataType.fields, formValues.typeOptions.formula]);

  const optionsAreValid =
    formValues.options.slice(0, -1).every(isOptionValid) &&
    formValues.options.length > 1;

  const { isValid: isDisplayNameValid, validationMessage } =
    useFieldNameValidation(
      formValues.displayName,
      type as DataFieldType | null,
      dataType,
      dataTypes,
      value,
    );

  const isCustomRelationship = useMemo(
    () => typeIsCustomRelationship(type),
    [type, typeIsCustomRelationship],
  );

  const relatedType = useMemo(
    () => (type && isCustomRelationship ? dataTypes.getByName(type) : null),
    [dataTypes, isCustomRelationship, type],
  );

  const {
    isValid: isReverseDisplayNameValid,
    validationMessage: reverseNameValidationMessage,
  } = useReverseFieldNameValidation(
    formValues.reverseName,
    relatedType!,
    relationship,
    dataType.fields,
    value?.id,
  );

  const requiresReverseName =
    isCustomRelationship && relationship && type !== FILE;

  const fieldCanBeNegative = type && NUMERIC_DATATYPES.includes(type);

  const fieldCanBeUnique =
    type &&
    !isCustomRelationship &&
    !NON_UNIQUE_DATA_TYPES.includes(type) &&
    !isFormula &&
    !isLookup &&
    !isObject &&
    !isAiGeneration &&
    !isRollup;

  const [aiGenerationOptionsValid, setAiGenerationOptionsValid] =
    useState(false);
  const [lookupIsValid, setLookupIsValid] = useState(false);
  const [rollupIsValid, setRollupIsValid] = useState(false);
  const areOptionsNeeded = useMemo(
    () =>
      isOptionType(providedValueType ?? type) &&
      (!value || isOptionType(providedValueType ?? value.type)),
    [providedValueType, type, value],
  );
  const isValid = useMemo(() => {
    if (!isFormDirty) {
      return false;
    }

    if (isLookup) {
      return isDisplayNameValid && lookupIsValid;
    }

    if (isRollup) {
      return isDisplayNameValid && type && rollupIsValid;
    }

    return (
      isDisplayNameValid &&
      type &&
      (!isCustomRelationship || relationship) &&
      (!areOptionsNeeded || optionsAreValid) &&
      (!isFormula || formulaIsValid) &&
      (!isAiGeneration || aiGenerationOptionsValid) &&
      (!requiresReverseName || isReverseDisplayNameValid) &&
      (!formValues.autoRelationshipConfig ||
        ((formValues.autoRelationshipConfig as any).sourceFieldId &&
          (formValues.autoRelationshipConfig as any).lookupFieldId))
    );
  }, [
    isFormDirty,
    isLookup,
    isRollup,
    isDisplayNameValid,
    type,
    isCustomRelationship,
    relationship,
    areOptionsNeeded,
    optionsAreValid,
    isFormula,
    formulaIsValid,
    isAiGeneration,
    aiGenerationOptionsValid,
    requiresReverseName,
    isReverseDisplayNameValid,
    formValues.autoRelationshipConfig,
    lookupIsValid,
    rollupIsValid,
  ]);

  const handleOnSave = async (confirmDataFieldTypeChange = false) => {
    setIsLoading(true);

    try {
      let rollup = undefined;
      let lookup = undefined;

      if (isRollup) {
        rollup = {
          id: value?.rollup?.id ?? undefined,
          relatedField: formValues.rollupRelatedField,
          field: formValues.rollupField,
          aggregation: formValues.aggregation,
        };
      }

      if (isLookup) {
        lookup = {
          id: value?.lookup?.id ?? undefined,
          sourceFieldId: formValues.lookupSourceFieldId,
          valueFieldId: formValues.lookupValueFieldId,
        };
      }

      const newField = {
        projectName: projectName,
        dataTypeId: dataType.id,
        display: formValues.displayName,
        // TODO - in a follow-up formulas should also use provided type + a chooser
        type: providedValueType ?? (type === FORMULA ? TEXT : type),
        relationship: isCustomRelationship ? relationship : undefined,
        reverseDisplay: isCustomRelationship
          ? formValues.reverseName
          : type === FILE
            ? dataType.name
            : undefined,
        unique: fieldCanBeUnique && formValues.unique,
        options: cleanOptions(formValues.options),
        typeOptions: formValues.typeOptions,
        autoRelationshipConfig: isCustomRelationship
          ? formValues.autoRelationshipConfig
          : undefined,
        rollup,
        lookup,
      };

      if (!value) {
        trackEvent(NEW_DATA_FIELD_SAVED);

        const { data } = await saveDataField({
          variables: newField,
        });

        if (data.addDataField) {
          addDataField({
            dataField: data.addDataField,
            dataTypeName: dataType.name,
          });
        }
      } else {
        trackEvent(DATA_FIELD_UPDATED);

        if (value.type !== newField.type) {
          //editing type
          if (!confirmDataFieldTypeChange) {
            setShowEditFieldTypeModal(true);

            return;
          }
          setShowEditFieldTypeModal(false);
        }
        const { data } = await upsertDataField({
          variables: {
            id: value.id,
            ...newField,
          },
        });

        if (data.upsertDataField) {
          updateDataField({
            dataField: data.upsertDataField,
            dataTypeId: dataType.id,
          });
        }
      }
      setIsLoading(false);
      resetForm();
    } catch (e) {
      errorAlert(getText('data.fields.error'), e);

      setIsLoading(false);
    }
  };

  return (
    <>
      <RightPopoutMenu
        title={value ? value.display : getText('data.fields.new')}
        footer={
          <div className="flex items-center space-x-2">
            <Button
              small={true}
              data-testid="new-field-cancel"
              disabled={isLoading}
              onClick={resetForm}
              type={OUTLINE}
            >
              {getText('data.fields.cancel')}
            </Button>
            <Button
              small={true}
              data-testid="new-field-submit"
              disabled={!isValid || isLoading}
              onClick={() => handleOnSave()} //not same as conClick={handleOnSave} we don't want the event object
            >
              {isLoading ? (
                <Loader size={XS} />
              ) : (
                getText('data.fields', value ? 'save' : 'add')
              )}
            </Button>
          </div>
        }
        onClose={handleOnClose}
        size={MD}
        forceCloseOnScape={false}
      >
        <div className="flex flex-col p-4">
          <Guide
            className="mb-4"
            href="https://guides.noloco.io/data/collections/field-types"
          >
            {getText('data.fields.guide')}
          </Guide>
          {dataType.source.type !== INTERNAL && !value && (
            <ErrorText className="mb-4 flex items-center">
              <IconAlertTriangle
                size={22}
                className="mr-4 flex-shrink-0 opacity-75"
              />
              <div
                className="flex flex-col"
                data-testid="new-field-data-source-warning"
              >
                <span>{getText('data.fields.externalSource.warning')}</span>
                <span className="mt-px text-xs text-gray-100">
                  {getText('data.fields.externalSource', dataType.source.type)}
                </span>
              </div>
            </ErrorText>
          )}
          <FormField
            className="mb-2 text-black"
            data-testid="new-field-name"
            errorMessage={
              validationMessage && (
                <span className="text-white">{validationMessage}</span>
              )
            }
            label={getText('data.fields.label')}
            placeholder={getText('data.fields.placeholder')}
            tooltip={getText('data.fields.tooltip')}
            value={formValues.displayName}
            options={sampleFieldNameOptions}
            inputType="autocomplete"
            errorType="below-solid"
            onChange={({ target: { value } }: any) => setDisplayName(value)}
            size={LG}
            surface={surface}
            disabled={!!value && !canChangeFieldDisplay}
          />

          <Label surface={surface} className="mb-1 mt-4">
            {getText('data.typeInput.label')}
          </Label>
          <FieldTypeInput
            aiGenerationFieldsEnabled={aiGenerationFieldsEnabled}
            readOnly={value && !canChangeFieldType}
            dataTypes={dataTypes}
            onChange={onTypeChange}
            surface={surface}
            value={getValueForFieldTypeInput(type, formValues.typeOptions)}
            dataField={value}
            dataTypeSource={dataType.source?.type ?? INTERNAL}
          />
          {isAiGeneration && (
            <AiGenerationFieldEditor
              dataType={dataType}
              dataTypes={dataTypes}
              onValidChange={setAiGenerationOptionsValid}
              onProvidedTypeChange={onChangeProvidedType}
              providedType={providedValueType}
              onChangeTypeOptions={setTypeOptions}
              onChangeOptions={setOptions}
              typeOptions={formValues.typeOptions}
              surface={DARK}
            />
          )}
          {showFieldTypeOptions && type && (
            <FieldTypeOptionsEditor
              project={project}
              dataType={dataType}
              className="mt-4"
              fieldType={
                providedValueType ??
                ((type === OBJECT
                  ? (formValues.typeOptions?.format ?? '')
                  : type) as DataFieldType)
              }
              fieldSource={value?.source}
              onChange={setTypeOptions}
              surface={surface}
              value={formValues.typeOptions}
              readOnly={isUpdate && !canChangeFieldTypeOptions}
              dataFieldId={value?.id}
            />
          )}
          {isOptionType(providedValueType ?? type) &&
            (value ? (
              areOptionsNeeded && (
                <div className="flex w-full flex-col py-3">
                  <FieldOptionsInput
                    dataTypeId={dataType.id}
                    projectName={projectName}
                    readOnly={!canChangeFieldOptions}
                    readOnlyColor={!canChangeFieldOptionsColor}
                    field={value}
                    options={formValues.options}
                    setOptions={setOptions}
                    showBulkAddScreen={showBulkAddScreen}
                    setShowBulkAddScreen={setShowBulkAddScreen}
                    surface={DARK}
                  />
                  {!showBulkAddScreen && canChangeFieldOptions && (
                    <button
                      className="mr-auto mt-4 text-left text-sm text-gray-500 hover:underline"
                      onClick={() => setShowBulkAddScreen(true)}
                    >
                      {getText('data.fields.bulkAdd.title')}
                    </button>
                  )}
                </div>
              )
            ) : (
              <OptionEditor
                className="my-2"
                options={formValues.options}
                readOnly={isAiGeneration && !canChangeFieldOptions}
                setOptions={setOptions}
                surface={surface}
              />
            ))}
          {isCustomRelationship && (!value || canChangeRelationship) && (
            <RelationshipEditor
              allowAutoRelationships={true}
              autoRelationshipConfig={formValues.autoRelationshipConfig}
              dataType={dataType}
              dataTypes={dataTypes}
              displayName={formValues.displayName}
              isMulti={formValues.isMulti}
              isReverseMulti={formValues.isReverseMulti}
              readOnly={false}
              relationship={relationship}
              relatedType={relatedType!}
              reverseName={formValues.reverseName ?? ''}
              requiresReverseName={requiresReverseName}
              reverseNameValidationMessage={reverseNameValidationMessage}
              setAutoRelationshipConfig={setAutoRelationshipConfig}
              setIsMulti={setIsMulti}
              setIsReverseMulti={setIsReverseMulti}
              setRelationship={setRelationship}
              setReverseName={setReverseName}
              surface={surface}
              type={type}
            />
          )}
          {fieldCanBeNegative && (
            <>
              <div className="my-4 flex items-center">
                <Label surface={surface} m={0}>
                  {getText('data.allowNegative.label')}
                </Label>
                <HelpTooltip
                  className={classNames('ml-2 hover:text-gray-500', {
                    'text-gray-800': surface === LIGHT,
                    'text-gray-200': surface === DARK,
                  })}
                  placement="right"
                >
                  <p>{getText('data.allowNegative.tooltip')}</p>
                </HelpTooltip>
                <Switch
                  size={SM}
                  data-testid="field-allow-negative-switch"
                  className="ml-auto"
                  value={allowNegative({
                    typeOptions: formValues.typeOptions,
                  } as DataField)}
                  onChange={onAllowNegativeChange}
                  disabled={isUpdate && !canChangeFieldCanBeNegative}
                />
              </div>
            </>
          )}
          {!value && fieldCanBeUnique && (
            <>
              <div className="my-4 flex items-center">
                <Label surface={surface} m={0}>
                  {getText('data.uniqueInput.label')}
                </Label>
                <HelpTooltip
                  className={classNames('ml-2 hover:text-gray-500', {
                    'text-gray-800': surface === LIGHT,
                    'text-gray-200': surface === DARK,
                  })}
                  placement="right"
                >
                  <p>{getText('data.uniqueInput.tooltip')}</p>
                </HelpTooltip>
                <Switch
                  size={SM}
                  data-testid="field-unique-switch"
                  className="ml-auto"
                  value={formValues.unique}
                  onChange={setUnique}
                />
              </div>
            </>
          )}
          {isLookup && (
            <LookupEditor
              dataType={dataType}
              dataTypes={dataTypes}
              sourceFieldId={formValues.lookupSourceFieldId}
              valueFieldId={formValues.lookupValueFieldId}
              readOnly={value && !canChangeLookup}
              setSourceFieldId={setLookupSourceFieldId}
              setValueFieldId={setLookupValueFieldId}
              onValidChange={setLookupIsValid}
              surface={DARK}
            />
          )}
          {isRollup && (
            <RollupEditor
              aggregation={formValues.aggregation}
              dataType={dataType}
              dataTypes={dataTypes}
              rollupField={formValues.rollupField}
              rollupRelatedField={formValues.rollupRelatedField}
              setAggregation={setAggregation}
              setRollupField={setRollupField}
              setRollupRelatedField={setRollupRelatedField}
              onValidChange={setRollupIsValid}
              surface={DARK}
              readOnly={isUpdate && !canChangeRollup}
            />
          )}
          {value && <FieldVariableNameDetails field={value} />}
          {((canSetFieldAsPrimary && isUpdate) ||
            (canDelete && canDeleteField)) && <hr className="mx-4 my-4" />}
          {canSetFieldAsPrimary && isUpdate && (
            <button
              className="flex items-center rounded-lg px-3 py-3 text-left text-sm text-white hover:bg-gray-400 disabled:opacity-50"
              disabled={updateLoading}
              onClick={setPrimaryField}
            >
              <IconBookmark size={16} className="mr-4 opacity-75" />
              <span className="w-72">
                {getText('data.fields.primaryField.set')}
              </span>
            </button>
          )}
          {canDelete && canDeleteField && (
            <button
              className="flex items-center rounded-lg px-3 py-3 text-left text-sm text-white hover:bg-red-400"
              onClick={() => setShowDeleteModal(true)}
            >
              <IconTrash size={16} className="mr-4 opacity-75" />
              <span className="w-72">
                {getText('data.fields.delete.button')}
              </span>
            </button>
          )}
        </div>
      </RightPopoutMenu>
      {showExitModal && (
        <ExitFormWarningModal
          onClose={() => setShowExitModal(false)}
          onConfirm={onClose}
        />
      )}

      {showEditFieldTypeModal && (
        <ChangeFieldTypeWarningModal
          onClose={() => {
            setIsLoading(false);
            setShowEditFieldTypeModal(false);
          }}
          onConfirm={() => {
            setIsLoading(true);
            setShowEditFieldTypeModal(false);
            handleOnSave(true);
          }}
        />
      )}
      {value && showDeleteModal && (
        <DeleteFieldModal
          field={value}
          onClose={() => setShowDeleteModal(false)}
          onDeleteSuccess={onClose}
          projectName={projectName}
          dataTypeId={dataType.id}
        />
      )}
    </>
  );
};

export default DataFieldForm;
