import { useCallback, useState } from 'react';
import {
  IconBrandYoutube,
  IconHammer,
  IconHelp,
  IconKeyboard,
  IconLifebuoy,
  IconMessage,
  IconMessage2Question,
  IconSchool,
  IconUserCircle,
  IconVideo,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Badge, Popover } from '@noloco/components/src';
import { LIVE_CHAT } from '@noloco/core/src/constants/features';
import {
  CMD,
  ESCAPE,
  KEY_H,
  KEY_SLASH,
} from '@noloco/core/src/constants/shortcuts';
import {
  ACADEMY_URL,
  COMMUNITY_URL,
  EXPERT_URL,
  GUIDES_URL,
  WEBINARS_URL,
  YOUTUBE_CHANNEL_URL,
} from '@noloco/core/src/constants/urls';
import ShortcutKeys from '@noloco/core/src/elements/sections/view/ShortcutKeys';
import { ProjectSettings } from '@noloco/core/src/models/Project';
import { editorModeSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { getValidPlanLangKey } from '@noloco/core/src/utils/features';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import useIntercom from '../utils/hooks/useIntercom';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import useValidFeaturePlan from '../utils/hooks/useValidFeaturePlan';
import KeyboardShortcutsModal from './KeyboardShortcutsModal';
import OnboardingChecklistSummary from './OnboardingChecklistSummary';

interface HelpPopoverProps {
  settings: ProjectSettings;
}

const LANG_KEY = 'toolbar.help';

const HelpPopover = ({ settings }: HelpPopoverProps) => {
  const userHasChatAccess = useIsFeatureEnabled(LIVE_CHAT);
  const planForChatAccess = useValidFeaturePlan(LIVE_CHAT);
  const { showIntercom } = useIntercom(settings);
  const editorMode = useSelector(editorModeSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isKeyboardShortcutsOpen, setIsKeyboardShortcutsOpen] =
    useState<boolean>(false);

  const showIntercomChat = useCallback(() => showIntercom(), [showIntercom]);

  useOnKeyPress(ESCAPE, () => setIsOpen(false), { enabled: isOpen });

  useOnKeyPress(KEY_H, () => setIsOpen(!isOpen), {
    enabled: true,
    altKey: true,
  });

  useOnKeyPress(
    KEY_SLASH,
    () => setIsKeyboardShortcutsOpen(!isKeyboardShortcutsOpen),
    { ctrlKey: true, enabled: !editorMode },
  );

  return (
    <>
      <Popover
        bg="white"
        className="overflow-hidden bg-white"
        content={
          <div className="flex w-80 flex-col bg-white p-2 text-left text-sm">
            <OnboardingChecklistSummary />
            <button
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
              onClick={() => setIsKeyboardShortcutsOpen(true)}
            >
              <IconKeyboard size={16} />
              <span className="ml-2 flex w-full items-center justify-between">
                <span>{getText(LANG_KEY, 'keyboardShortcutsModal.title')}</span>
                <ShortcutKeys keys={[CMD, KEY_SLASH]} />
              </span>
            </button>
            <button
              disabled={!userHasChatAccess}
              className={classNames(
                'flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100',
                { 'cursor-not-allowed': !userHasChatAccess },
              )}
              onClick={() => {
                if (userHasChatAccess) {
                  showIntercomChat();
                }
              }}
            >
              <div
                className={classNames('flex items-center', {
                  'opacity-75': !userHasChatAccess,
                })}
              >
                <IconMessage size={16} />
                <span className="ml-2">{getText(LANG_KEY, 'liveChat')}</span>
              </div>
              {!userHasChatAccess && (
                <Badge color="pink-500" m={{ l: 'auto', r: 0 }}>
                  {getText(
                    'billing.plans',
                    getValidPlanLangKey(planForChatAccess),
                    'name',
                  )}
                </Badge>
              )}
            </button>
            <a
              href={COMMUNITY_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconMessage2Question size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'community')}</span>
            </a>
            <a
              href={GUIDES_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconLifebuoy size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'support')}</span>
            </a>
            <a
              href={ACADEMY_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-gray-100"
            >
              <IconSchool size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'academy')}</span>
            </a>
            <a
              href={YOUTUBE_CHANNEL_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconBrandYoutube size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'youtube')}</span>
            </a>
            <a
              href={WEBINARS_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconVideo size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'webinars')}</span>
            </a>
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconUserCircle size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'billing')}</span>
            </a>
            <a
              href={EXPERT_URL}
              target="_blank"
              rel="noreferrer noopener"
              className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
            >
              <IconHammer size={16} />
              <span className="ml-2">{getText(LANG_KEY, 'expert')}</span>
            </a>
          </div>
        }
        delayHide={300}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        p={0}
        placement="right"
        rounded="lg"
        shadow="lg"
        trigger="hover"
      >
        <div className="flex items-center" data-tour="help-wrapper">
          <button
            className="flex h-14 w-16 cursor-pointer items-center justify-center px-3 py-2 text-slate-500 hover:text-slate-200 hover:opacity-75"
            data-tour="help"
          >
            <IconHelp size={20} />
          </button>
        </div>
      </Popover>
      {isKeyboardShortcutsOpen && (
        <KeyboardShortcutsModal
          onClose={() => setIsKeyboardShortcutsOpen(false)}
        />
      )}
    </>
  );
};

export default HelpPopover;
