import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import first from 'lodash/first';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { MIN_DATA_TYPES_TO_SHOW_SELECT } from '@noloco/core/src/constants/dataSources';
import { DataSource } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { updateSource } from '@noloco/core/src/reducers/project';
import {
  getTextFromError,
  useGraphQlErrorAlert,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import {
  CONNECT_DATA_SOURCE,
  SYNC_DATA_SOURCE_DATA,
  UPDATE_DATA_SOURCE,
} from '../../queries/project';
import { useBuildDataSourceLayouts } from './useBuildDataSourceLayouts';
import { useCheckDataSourceSyncStatus } from './useCheckDataSourceSyncStatus';
import { useExistingDataSource } from './useExistingDataSource';
import { useIsDataSourceNameValid } from './useIsDataSourceNameValid';

const LANG_KEY = 'data.dataSources';

interface UseAddDataSourceParams {
  project: Project;
  connection: any;
  display: any;
  type: any;
  formSteps: any;
  onAuthenticationFail: any;
  usePreBuiltLayout?: boolean;
  inOnboarding: boolean;
  canShowTableChooser: boolean;
}

export const useAddDataSource = ({
  project,
  connection,
  display,
  type,
  formSteps,
  onAuthenticationFail,
  usePreBuiltLayout,
  inOnboarding,
  canShowTableChooser,
}: UseAddDataSourceParams) => {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const errorAlert = useGraphQlErrorAlert();
  const successAlert = useInfoAlert();

  const {
    query: { id },
    push,
  } = useRouter<{ id: number }>();

  const { existingDataSource, isConnect, isUpdate } = useExistingDataSource(
    id,
    project,
  );

  const isNameValid = useIsDataSourceNameValid(
    project,
    display,
    isUpdate,
    existingDataSource,
  );

  const [
    connectDataSource,
    { data: createdDataSource, loading: isConnecting },
  ] = useMutation(CONNECT_DATA_SOURCE);
  const [updateDataSource] = useMutation(UPDATE_DATA_SOURCE);
  const [isUpdating, setIsUpdating] = useState(false);

  const [syncDataSourceData] = useMutation(SYNC_DATA_SOURCE_DATA);
  const [hasSyncedData, setHasSyncedData] = useState(false);

  const [step, setStep] = useState(0);

  const onBuildViewsFinish = useCallback(
    () => setStep((currentStep) => currentStep + 1),
    [setStep],
  );
  const {
    buildLayouts,
    redirectToFirstNewView,
    builtPages,
    inProgressPages,
    skippedPages,
  } = useBuildDataSourceLayouts({
    project,
    onFinish: onBuildViewsFinish,
    skipWebSockets: inOnboarding,
    usePreBuiltLayout,
  });

  const onSyncComplete = useCallback(
    async (projectName: string, dataSource: DataSource) => {
      setHasSyncedData(true);
      setStep((currentStep) => currentStep + 1);
      await buildLayouts(projectName, dataSource);
    },
    [buildLayouts],
  );
  const onSyncTimeout = useCallback(
    async (projectName: string, dataSource: DataSource) => {
      setHasSyncedData(false);
      setStep((currentStep) => currentStep + 1);

      await buildLayouts(projectName, dataSource);
    },
    [buildLayouts],
  );

  const { checkSyncStatus } = useCheckDataSourceSyncStatus(
    project.name,
    onSyncComplete,
    onSyncTimeout,
  );

  const onConnectDataSource = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setError(null);
    connectDataSource({
      variables: {
        projectName: project.name,
        display,
        connection,
        type,
      },
    })
      .then(({ data: createdDataSourceData }) => {
        if (createdDataSourceData && createdDataSourceData.connectDataSource) {
          const types = get(
            createdDataSourceData,
            'connectDataSource.types',
            [],
          );
          const shouldShowTableSelection =
            canShowTableChooser && types.length > MIN_DATA_TYPES_TO_SHOW_SELECT;
          setStep(
            (currentStep) => currentStep + (shouldShowTableSelection ? 1 : 2),
          );

          if (!shouldShowTableSelection) {
            return syncDataSourceData({
              variables: {
                projectName: project.name,
                id: createdDataSourceData.connectDataSource.id,
                isFirstSync: true,
              },
              context: {
                authQuery: true,
              },
            }).then(() =>
              checkSyncStatus(createdDataSourceData.connectDataSource),
            );
          }
        }
      })
      .catch((error) => {
        const errorText = getTextFromError(error);
        // @ts-expect-error TS(2345): Argument of type '{ title?: undefined; message?: u... Remove this comment to see the full error message
        setError(errorText);
        setStep((currentStep) => Math.max(currentStep - 1, 0));

        if (error.graphQLErrors) {
          const firstError = first(error.graphQLErrors);
          const status = get(firstError, 'data.status');

          if (status === 401 || status === 404) {
            onAuthenticationFail && onAuthenticationFail();
          }
        }
      });
  }, [
    canShowTableChooser,
    checkSyncStatus,
    connectDataSource,
    connection,
    display,
    onAuthenticationFail,
    project.name,
    syncDataSourceData,
    type,
  ]);

  const onUpdateDataSource = useCallback(() => {
    setIsUpdating(true);
    updateDataSource({
      variables: {
        connection,
        display,
        externalId: existingDataSource.externalId,
        id,
        projectName: project.name,
        type,
      },
    })
      .then(({ data: { updateDataSource } }) => {
        dispatch(updateSource(updateDataSource));
        successAlert(getText(LANG_KEY, 'update.title'));
        push(`/_/data/internal`);
      })
      .catch((e) => {
        errorAlert(getText(LANG_KEY, 'error'), e);
        setIsUpdating(false);
        setStep((currentStep) => currentStep - 1);
      });
  }, [
    updateDataSource,
    connection,
    display,
    existingDataSource?.externalId,
    id,
    project.name,
    type,
    dispatch,
    successAlert,
    push,
    errorAlert,
  ]);

  const onClickNext = useCallback(() => {
    setStep((currentStep) => currentStep + 1);

    if (step < formSteps - 1) {
      return;
    }

    if (isConnect) {
      onConnectDataSource();
    }

    if (isUpdate) {
      onUpdateDataSource();
    }
  }, [
    formSteps,
    isConnect,
    isUpdate,
    onConnectDataSource,
    onUpdateDataSource,
    setStep,
    step,
  ]);

  const onDataTypesSelected = useCallback(
    (enabledDataTypes: any) => {
      checkSyncStatus({
        ...createdDataSource.connectDataSource,
        types: enabledDataTypes,
      });
      setStep((currentStep) => currentStep + 1);
    },
    [checkSyncStatus, createdDataSource, setStep],
  );

  return {
    error,
    builtPages,
    createdDataSource: get(createdDataSource, 'connectDataSource'),
    dataSourceId: id,
    existingDataSource,
    hasSyncedData,
    inProgressPages,
    isConnect,
    isConnecting,
    isNameValid,
    isUpdate,
    isUpdating,
    onClickNext,
    onDataTypesSelected,
    onFinish: redirectToFirstNewView,
    skippedPages,
    step,
  };
};
