import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import { DataType } from '../../models/DataTypes';
import { ElementPath } from '../../models/Element';
import { VisibilityRules } from '../../models/Element';
import { Project } from '../../models/Project';

interface BuildModeVisibilityTabProps {
  dataType: DataType;
  elementPath: ElementPath;
  project: Project;
  visibilityRules?: VisibilityRules;
}

const BuildModeVisibilityTab = ({
  dataType,
  elementPath,
  project,
  visibilityRules,
}: BuildModeVisibilityTabProps) => (
  <>
    <hr className="w-full border-slate-700" />
    <VisibilityRulesEditor
      dataType={dataType}
      elementPath={elementPath}
      project={project}
      visibilityRules={visibilityRules}
    />
  </>
);

export default BuildModeVisibilityTab;
