import { useCallback, useEffect, useState } from 'react';
import {
  ENTER,
  ESCAPE,
  KEY_S,
  KEY_U,
  ShortcutKeyType,
} from '../../constants/shortcuts';
import useIsMacOs from './useIsMacOs';

const KEYS_ALLOWED_ON_INPUT = [KEY_U];

const useOnKeyPress = (
  targetKey: ShortcutKeyType,
  onKeyDown: (event: React.KeyboardEvent) => void,
  {
    altKey = false,
    ctrlKey = false,
    enabled = true,
    onKeyUp,
    shiftKey = false,
    useKeyCode = false,
  }: {
    altKey?: boolean;
    ctrlKey?: boolean;
    enabled?: boolean;
    onKeyUp?: (event: React.KeyboardEvent) => void;
    shiftKey?: boolean;
    useKeyCode?: boolean;
  } = {},
) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const isMacOs = useIsMacOs();

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        (altKey || useKeyCode
          ? event.code === targetKey
          : event.key === targetKey) &&
        shiftKey === event.shiftKey &&
        altKey === event.altKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(true);

        if (onKeyDown) {
          const isInput = event.target.tagName === 'INPUT';

          if (isInput && ctrlKey && KEYS_ALLOWED_ON_INPUT.includes(targetKey)) {
            return onKeyDown(event);
          }

          if (
            !isInput ||
            [ESCAPE, ENTER].includes(targetKey) ||
            (altKey && targetKey === KEY_S)
          ) {
            return onKeyDown(event);
          }
        }
      }
    },
    [
      altKey,
      ctrlKey,
      enabled,
      isMacOs,
      onKeyDown,
      shiftKey,
      targetKey,
      useKeyCode,
    ],
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        event.key === targetKey &&
        shiftKey === event.shiftKey &&
        altKey === event.altKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(false);

        if (onKeyUp) {
          onKeyUp(event);
        }
      }
    },
    [enabled, targetKey, shiftKey, altKey, isMacOs, ctrlKey, onKeyUp],
  );

  // Add event listeners
  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    }

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [enabled, upHandler, downHandler]);

  return keyPressed;
};

export default useOnKeyPress;
