import React, { memo } from 'react';
import { IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.dataSources.connect.layout.preBuilt';

interface Props {
  loading: boolean;
  surface: Surface;
}

const DataSourceImportPreBuiltLayouts = memo(
  ({ loading, surface = DARK }: Props) => (
    <div
      className={classNames('flex w-full items-center rounded-lg border p-3', {
        'border-brand-light text-white': surface !== LIGHT,
        'border-gray-200 bg-white text-gray-700': surface === LIGHT,
      })}
    >
      <div className="h-full">
        <div className="my-2 flex w-16 flex-shrink-0 justify-center">
          {loading ? (
            <Loader
              className={surface === LIGHT ? 'text-gray-700' : 'text-white'}
            />
          ) : (
            <IconCircleCheck className="text-teal-500" size={40} />
          )}
        </div>
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-lg font-medium tracking-wider">
          {getText(LANG_KEY, 'title')}
        </span>
        <span className="font-gray-200 mt-1 text-sm">
          {getText(LANG_KEY, 'subtitle')}
        </span>
      </div>
    </div>
  ),
);

export default DataSourceImportPreBuiltLayouts;
