import get from 'lodash/get';
import {
  AUDIO,
  DOCUMENT,
  FileType,
  IMAGE,
  TEXT,
  VIDEO,
} from '../constants/fileTypes';
import {
  AUDIO_MIMETYPES,
  DOCUMENT_MIMETYPES,
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from '../constants/mimetypes';
import { DataField } from '../models/DataTypeFields';
import { BaseRecord } from '../models/Record';
import { getValuePathForFieldConfig } from './fields';
import { isMultiRelationship } from './relationships';

// BOM: https://en.wikipedia.org/wiki/Byte_order_mark
const WINDOWS_EXCEL_UTF8_BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
export const getFileTypeFromMimetype = (mimetype: string): FileType => {
  if (AUDIO_MIMETYPES.includes(mimetype)) {
    return AUDIO;
  }

  if (IMAGE_MIMETYPES.includes(mimetype)) {
    return IMAGE;
  }

  if (VIDEO_MIMETYPES.includes(mimetype)) {
    return VIDEO;
  }

  if (DOCUMENT_MIMETYPES.includes(mimetype)) {
    return DOCUMENT;
  }

  return TEXT;
};

export const downloadCsvStringAsFile = (
  csvString: string,
  fileName: string,
  isWindows: boolean,
  extension = 'csv',
): void => {
  const csvData = new Blob(
    isWindows ? [WINDOWS_EXCEL_UTF8_BOM, csvString] : [csvString],
    {
      type: 'text/csv;charset=utf-8;',
    },
  );
  const csvURL = window.URL.createObjectURL(csvData);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL as string;
  tempLink.setAttribute('download', `${fileName}.${extension}`);
  tempLink.click();
};

export const downloadFileFromUrl = async (
  fileUrl: string,
  fileName: string,
): Promise<void> => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    })
    .catch(console.error);
};

export const getImageFilesFromFieldValues = (
  field: DataField,
  record: BaseRecord,
  parent?: DataField,
) => {
  if (isMultiRelationship(field.relationship)) {
    const fileValues = get(
      record,
      [...getValuePathForFieldConfig(field, parent), 'edges'],
      [],
    );

    const imageFiles =
      fileValues &&
      fileValues
        .filter((fileEdge: any) => fileEdge.node.fileType === IMAGE)
        .map((fileEdge: any) => ({
          id: fileEdge.node.id,
          name: fileEdge.node.name,
          src: fileEdge.node.url,
        }));

    if (imageFiles && imageFiles.length > 0) {
      return imageFiles;
    }
  } else {
    const fileValue = get(record, getValuePathForFieldConfig(field, parent));

    if (fileValue && fileValue.fileType === IMAGE) {
      return [
        {
          id: fileValue.id,
          name: fileValue.name,
          src: fileValue.url,
        },
      ];
    }
  }

  return null;
};

export const getExtension = (name = '') => {
  const splitName = name.split('.');

  if (splitName.length < 2) {
    return '';
  }

  return `.${splitName.splice(-1)[0]}`;
};

export const getNameWithoutExtension = (
  name: string | undefined,
  extension: string,
) => {
  if (!name) {
    return '';
  }

  return name.slice(0, name.length - extension.length);
};
