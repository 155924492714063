import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Button, Notice, SelectInput, TextInput } from '@noloco/components';
import { LG, MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  DATE,
  NUMERIC_DATATYPES,
  QR_DATATYPES,
} from '@noloco/core/src/constants/dataTypes';
import {
  BUTTON,
  ElementType,
  IMAGE,
  LINK,
  MARKDOWN,
  PROGRESS_BAR,
  PROGRESS_RING,
  RELATIVE_DATE,
  TEXT,
} from '@noloco/core/src/constants/elements';
import {
  CONDENSED_RELATIONSHIP,
  COUNT,
  FieldDisplayType,
  JSON_FORMAT,
  QR_CODE,
} from '@noloco/core/src/constants/fieldDisplayTypes';
import {
  EMAIL,
  IP_ADDRESS,
  SLIDER,
  URL,
} from '@noloco/core/src/constants/fieldFormats';
import fileFieldElementTypes from '@noloco/core/src/constants/fileFieldElementTypes';
import {
  BOLD,
  H1,
  H2,
  H3,
  H4,
  TextType,
} from '@noloco/core/src/constants/textTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { setSelectedElement } from '@noloco/core/src/reducers/elements';
import { isRatingField } from '@noloco/core/src/utils/fields';
import { useAddView } from '@noloco/core/src/utils/hooks/useAddView';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import { getViewForDataType } from '@noloco/core/src/utils/urls';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import StringPropEditor from '../../canvas/StringPropEditor';
import { UpdateFieldsCallback } from './FieldsListEditor';
import FileFieldLayoutEditor from './FileFieldLayoutEditor';
import SizeEditor from './SizeEditor';

const LANG_KEY = 'elements.VIEW.fields.elementType';
const RING_SIZES = [SM, MD, LG];
const DEFAULT_RING_SIZE = SM;

const isValidRelationshipField = (field: any) =>
  (field.relationship && field.type !== FILE) || field.relatedField;

const getLabelForFieldValue = (field: DataField, value: any) => {
  if (field.type === FILE) {
    return getText('elements.elementType', FILE, value);
  }

  if ([BOLD, H1, H2, H3, H4].includes(value)) {
    return getText('elements', TEXT, 'type', value);
  }

  if (
    isValidRelationshipField(field) &&
    (value === COUNT || value === CONDENSED_RELATIONSHIP)
  ) {
    return getText('elements.displayTypes', value);
  }

  if (value === JSON_FORMAT || value === QR_CODE) {
    return getText('elements.displayTypes', value);
  }

  if (NUMERIC_DATATYPES.includes(field.type)) {
    return getText(LANG_KEY, 'numeric', value, 'label');
  }

  return getText('elements', value, 'label');
};

const getOptionsForFieldType = (field: DataField) => {
  const options = new Set<ElementType | TextType | FieldDisplayType>();

  if (field.multiple) {
    return [];
  }

  if (field.type === FILE) {
    return fileFieldElementTypes;
  }

  if (isValidRelationshipField(field)) {
    return [
      TEXT,
      ...(isMultiField(field) ? [CONDENSED_RELATIONSHIP, COUNT] : []),
    ];
  }

  if (NUMERIC_DATATYPES.includes(field.type)) {
    options.add(PROGRESS_BAR);
    options.add(PROGRESS_RING);
  }

  if (field.type === TEXT) {
    options.add(LINK);
    options.add(BUTTON);
    options.add(IMAGE);

    if (
      field.typeOptions?.format !== URL &&
      field.typeOptions?.format !== IP_ADDRESS
    ) {
      options.add(MARKDOWN);
      options.add(JSON_FORMAT);
      options.add(QR_CODE);
      options.add(BOLD);
      options.add(H1);
      options.add(H2);
      options.add(H3);
      options.add(H4);
    }
  }

  if (field.type === DATE) {
    options.add(RELATIVE_DATE);
  }

  if (QR_DATATYPES.includes(field.type)) {
    options.add(QR_CODE);
  }

  return Array.from(options);
};

interface FieldElementTypeEditorProps {
  config: any;
  elementPath: ElementPath;
  field: DataField;
  index: number;
  project: Project;
  updateFields: UpdateFieldsCallback;
}

const FieldElementTypeEditor = ({
  config,
  elementPath,
  field,
  index,
  project,
  updateFields,
}: FieldElementTypeEditorProps) => {
  const dispatch = useDispatch();
  const format = get(field, 'typeOptions.format', null);
  const elementType = get(config, 'elementType', null);
  const elementTypeOptions = useMemo(
    () => [
      {
        value: null,
        label: getText(LANG_KEY, 'default'),
      },
      ...getOptionsForFieldType(field).map((value) => ({
        value,
        label: getLabelForFieldValue(field, value),
      })),
    ],
    [field],
  );

  const label = useMemo(
    () => (field.type === FILE ? 'click' : 'display'),
    [field.type],
  );

  const valueOrDefault = useMemo(() => elementType || null, [elementType]);

  const fieldDataType = useMemo(() => {
    if (!field.relationship && !field.relatedField) {
      return null;
    }

    return project.dataTypes.getByName(field.type);
  }, [field.relatedField, field.relationship, field.type, project.dataTypes]);

  const showFieldLinkWarning = useMemo(() => {
    if (!fieldDataType || fieldDataType.name === FILE || !!elementType) {
      return false;
    }

    const viewForField = getViewForDataType(fieldDataType.name, project);

    return !viewForField;
  }, [fieldDataType, elementType, project]);

  const onAddView = useAddView(project);
  const onAddMissingView = useCallback(() => {
    if (fieldDataType) {
      onAddView(fieldDataType);
      dispatch(setSelectedElement([]));
    }
  }, [dispatch, fieldDataType, onAddView]);

  if (
    (![TEXT, FILE, DATE, ...NUMERIC_DATATYPES].includes(field.type) &&
      !isValidRelationshipField(field)) ||
    isRatingField(field)
  ) {
    return null;
  }

  return (
    <>
      {field.type === FILE && (
        <FileFieldLayoutEditor
          onUpdate={(path, value) =>
            updateFields([index, 'fileLayout', ...path], value)
          }
          fileLayout={config.fileLayout}
        />
      )}
      {format !== SLIDER && format !== EMAIL && (
        <BuildModeInput label={getText(LANG_KEY, 'label', label)}>
          <SelectInput
            onChange={(value: any) =>
              updateFields([index, 'elementType'], value)
            }
            options={elementTypeOptions}
            value={valueOrDefault}
            contained={true}
          />
        </BuildModeInput>
      )}
      {showFieldLinkWarning && (
        <Notice
          type="primary"
          subtitle={getText('elements.VIEW.fields.linkWarning.title')}
        >
          <Button className="ml-2" size={SM} onClick={onAddMissingView}>
            {getText('elements.VIEW.fields.linkWarning.cta')}
          </Button>
        </Notice>
      )}
      {[LINK, BUTTON].includes(elementType) &&
        field.type !== FILE &&
        format !== EMAIL && (
          <>
            <BuildModeInput label={getText(LANG_KEY, elementType, 'label')}>
              <TextInput
                debounceMs={UPDATE_DEBOUNCE_MS}
                onChange={({ target: { value } }: any) =>
                  updateFields([index, 'elementConfig', 'text'], value)
                }
                placeholder={getText(LANG_KEY, 'LINK.default')}
                value={get(config, 'elementConfig.text')}
              />
            </BuildModeInput>
            <BuildModeSwitchSection
              label={getText(LANG_KEY, 'newTab')}
              onChange={(value: any) =>
                updateFields([index, 'elementConfig', 'newTab'], value)
              }
              value={get(config, 'elementConfig.newTab', false)}
            />
          </>
        )}
      {elementType === IMAGE && (
        <BuildModeInput label={getText(LANG_KEY, 'IMAGE.label')}>
          <TextInput
            debounceMs={UPDATE_DEBOUNCE_MS}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              updateFields([index, 'elementConfig', 'alt'], value)
            }
            placeholder=""
            value={get(config, 'elementConfig.alt')}
          />
        </BuildModeInput>
      )}
      {[PROGRESS_BAR, PROGRESS_RING].includes(elementType) &&
        format !== SLIDER && (
          <>
            <BuildModeInput
              label={getText(LANG_KEY, 'numeric', elementType, 'maxValue')}
            >
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
                acceptableDataTypes={NUMERIC_DATATYPES}
                elementPath={elementPath}
                includeSelf={true}
                project={project}
                placeholder={getText(LANG_KEY, 'numeric.exampleMaxValue')}
                value={get(config, 'elementConfig.maxValue')}
                onChange={(value: any) =>
                  updateFields(
                    [index, 'elementConfig', 'maxValue'],
                    value.length > 0 ? value : undefined,
                  )
                }
              />
            </BuildModeInput>
            <BuildModeSwitchSection
              label={getText(LANG_KEY, 'numeric.showNumber')}
              onChange={(value: boolean) =>
                updateFields([index, 'elementConfig', 'showNumber'], value)
              }
              value={get(config, 'elementConfig.showNumber', false)}
            />
            {elementType === PROGRESS_RING && (
              <BuildModeInput
                label={getText(LANG_KEY, 'numeric', elementType, 'ringSize')}
              >
                <SizeEditor
                  defaultSize={DEFAULT_RING_SIZE}
                  onChange={(value) =>
                    updateFields([index, 'elementConfig', 'ringSize'], value)
                  }
                  sizes={RING_SIZES}
                  value={get(config, 'elementConfig.ringSize', MD)}
                />
              </BuildModeInput>
            )}
          </>
        )}
    </>
  );
};

export default FieldElementTypeEditor;
