import React from 'react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

interface Props {
  className?: string;
  children: any;
  surface?: Surface;
}

const SimpleTableHead = ({ className, children, surface = DARK }: Props) => (
  <thead
    className={classNames(className, 'sticky top-0 z-20', {
      'bg-slate-900': surface === DARK,
      'bg-gray-100': surface === LIGHT,
    })}
  >
    <tr>{children}</tr>
  </thead>
);

export default SimpleTableHead;
