import React from 'react';
import { ELEMENTS } from '../../constants/leftPopoutMenuTypes';
import ComponentList from './ComponentList';

interface Props {
  onClose: (...args: any[]) => any;
  selectedMenu?: any; // TODO: PropTypes.oneOf([ELEMENTS, null])
}

const LeftPopoutMenuManager = ({ selectedMenu, onClose }: Props) =>
  selectedMenu === ELEMENTS && (
    // @ts-expect-error TS(2322): Type '{ onClose: (...args: any[]) => any; project:... Remove this comment to see the full error message
    <ComponentList onClose={onClose} />
  );

export default LeftPopoutMenuManager;
