import React, { useMemo } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Loader } from '@noloco/components';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { Element } from '../models/Element';
import { Project } from '../models/Project';
import { scopeSelector } from '../selectors/dataSelectors';
import {
  editingPageSelector,
  editorModeSelector,
} from '../selectors/elementsSelectors';
import { lookupOfArray } from '../utils/arrays';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import useCurrentSpace from '../utils/hooks/useCurrentSpace';
import { getText } from '../utils/lang';
import { getVisibleRoutes } from '../utils/navigation';
import { getFirstPageInSpace, replaceDoubleSlashes } from '../utils/pages';
import CommentRedirect from './CommentRedirect';

interface PageSwitchProps {
  children?: React.ReactNode;
  project?: Project;
}

const PageSwitch = ({ children, project }: PageSwitchProps) => {
  // This could be a risk, but it solves the issue of figuring out which sub-routes to render
  const scope = useSelector(scopeSelector);
  const [currentSpace] = useCurrentSpace();

  const elementMap = useMemo(
    () =>
      project?.elements &&
      lookupOfArray<Element, string>(project.elements, 'id'),
    [project],
  );

  const editorMode = useSelector(editorModeSelector);
  const editingPage = useSelector(editingPageSelector);
  const { fetchedUser, user, loading } = useAuthWrapper();

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const childRoutes = useMemo(
    () =>
      project &&
      elementMap &&
      getVisibleRoutes(
        React.Children.toArray(children) as any,
        elementMap,
        fetchedUser,
        project,
        user,
        scope,
        editorMode,
        customRulesEnabled,
      ),
    [
      children,
      customRulesEnabled,
      editorMode,
      elementMap,
      fetchedUser,
      project,
      scope,
      user,
    ],
  );

  const firstRoute = useMemo(() => {
    if (currentSpace?.id && project?.elements) {
      return {
        path: get(
          getFirstPageInSpace(project.elements, currentSpace.id),
          'props.routePath',
          '',
        ),
      };
    }

    return childRoutes?.find(
      ({ element, parentPageId }) =>
        !get(element, 'props.dataType') &&
        element.id !== 'PROFILE' &&
        !parentPageId,
    );
  }, [childRoutes, currentSpace?.id, project]);

  const firstRoutePath = useMemo(
    () =>
      firstRoute && firstRoute.path
        ? `${
            firstRoute.path.indexOf('/') !== 0 ? '/' : ''
          }${replaceDoubleSlashes(firstRoute.path)}${document.location.search}`
        : undefined,
    [firstRoute],
  );

  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-800 dark:text-white">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <Switch>
      <Route path="/~/comments/:dataTypeId/:recordId">
        <CommentRedirect firstRoutePath={firstRoutePath} />
      </Route>
      {childRoutes
        ?.filter((route) => !route.parentPageId)
        .map((route) => (
          <Route
            // To ensure that on editing page URL path, the app doesn't redirect to the first page.
            path={editorMode && route.id === editingPage ? '' : route.path}
            exact={route.path === '/'}
            key={route.id}
          >
            <Switch>
              {childRoutes
                ?.filter((childRoute) => childRoute.parentPageId === route.id)
                .map((childRoute) => (
                  <Route
                    exact={false}
                    key={childRoute.id}
                    path={
                      editorMode && childRoute.id === editingPage
                        ? ''
                        : childRoute.path
                    }
                  >
                    {childRoute.child as any}
                  </Route>
                ))}
              <Route>{route.child as any}</Route>
            </Switch>
          </Route>
        ))}
      {firstRoutePath && <Redirect to={firstRoutePath} />}
      {!firstRoute && (
        <Route>
          <div className="w-full p-32 text-center">
            {getText('errors.pageNotFound')}
          </div>
        </Route>
      )}
    </Switch>
  );
};

export default PageSwitch;
