import { useMemo } from 'react';
import merge from 'lodash/fp/merge';
import { DARK } from '@noloco/components/src/constants/surface';
import { Theme } from '@noloco/components/src/models';
import { darkModeColors } from '../../constants/darkModeColors';
import { ProjectSettings } from '../../models/Project';
import { mergeThemes } from '../styles';
import useCurrentSpace from './useCurrentSpace';
import useDarkMode from './useDarkMode';

const darkModeTheme = {
  borderColors: {
    dark: darkModeColors.borders.oneLiteralColor,
    default: darkModeColors.borders.oneLiteralColor,
    light: darkModeColors.borders.oneLiteralColor,
  },
  textColors: {
    body: darkModeColors.text.primaryLiteralColor,
    emphasis: darkModeColors.text.secondaryLiteralColor,
    on: {
      danger: darkModeColors.text.primaryLiteralColor,
      dark: darkModeColors.text.primaryLiteralColor,
      info: darkModeColors.text.secondaryLiteralColor,
      primary: darkModeColors.text.primaryLiteralColor,
      secondary: darkModeColors.text.secondaryLiteralColor,
      success: darkModeColors.text.primaryLiteralColor,
      warning: darkModeColors.text.primaryLiteralColor,
    },
  },
  dropzone: {
    surface: DARK,
    textColor: darkModeColors.text.primaryLiteralColor,
  },
  surfaceColors: {
    dark: darkModeColors.surfaces.elevation2LiteralColor,
    default: darkModeColors.surfaces.elevation2LiteralColor,
    light: darkModeColors.surfaces.elevation2LiteralColor,
  },
  textInput: {
    surface: DARK,
    textColor: darkModeColors.text.primaryLiteralColor,
  },
  optionList: {
    surface: DARK,
    textColor: darkModeColors.text.primaryLiteralColor,
  },
  selectButton: {
    surface: DARK,
    textColor: darkModeColors.text.primaryLiteralColor,
  },
  selectInput: {
    surface: DARK,
    textColor: darkModeColors.text.primaryLiteralColor,
  },
  datePicker: {
    surface: DARK,
    textColor: darkModeColors.text.secondary,
  },
  modal: {
    surface: DARK,
    textColor: darkModeColors.text.primary,
    secondaryTextColor: darkModeColors.text.secondary,
    backgroundColor: darkModeColors.surfaces.elevation1,
    secondaryBackgroundColor: darkModeColors.surfaces.elevation0,
    borderColor: darkModeColors.borders.one,
  },
  secondaryButton: {
    surface: DARK,
    textColor: 'gray-300',
    borderColor: 'default',
  },
};

const useAppTheme = (settings: Partial<ProjectSettings> = {}): Theme => {
  const [space] = useCurrentSpace();
  const { space: currentSpace } = space ?? {};
  const { theme: currentSpaceTheme } = currentSpace ?? {};

  const [lightTheme, darkTheme] = useMemo(() => {
    const baseTheme = { ...settings?.theme, ...currentSpaceTheme };
    const lightTheme = mergeThemes(baseTheme);
    const darkTheme = merge(lightTheme, darkModeTheme);

    return [lightTheme, darkTheme];
  }, [settings.theme, currentSpaceTheme]);

  const [isDarkModeEnabled] = useDarkMode();

  return isDarkModeEnabled ? darkTheme : lightTheme;
};

export default useAppTheme;
