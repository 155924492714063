import React from 'react';
import {
  IconAdjustmentsAlt,
  IconDatabase,
  IconUsers,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Role } from '@noloco/core/src/models/User';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'userTable.form.roleSummary';

interface UserRoleSummaryProps {
  className?: string;
  role: Role;
  firstName: string;
}

const UserRoleSummary = ({
  className,
  role,
  firstName,
}: UserRoleSummaryProps) => (
  <div className={classNames(className, 'mt-6 flex flex-col space-y-3')}>
    <div className="flex items-center space-x-4">
      <IconUsers size={20} />
      <span>{getText({ firstName }, LANG_KEY, 'internal', role.internal)}</span>
    </div>
    <div className="flex items-center space-x-4">
      <IconDatabase size={20} />
      <span>
        {getText({ firstName }, LANG_KEY, 'dataAdmin', role.dataAdmin)}
      </span>
    </div>
    <div className="flex items-center space-x-4">
      <IconAdjustmentsAlt size={20} />
      <span>{getText({ firstName }, LANG_KEY, 'builder', role.builder)}</span>
    </div>
  </div>
);

export default UserRoleSummary;
