import { useCallback } from 'react';
import { IconMoon, IconSun } from '@tabler/icons-react';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { ALT, KEY_D } from '@noloco/core/src/constants/shortcuts';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import {
  DARK_MODE_TOGGLED_BY_USER,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';

interface DarkModeSwitchProps {
  primaryColor: string;
  isThemePreview?: boolean;
}

const DarkModeSwitch = ({
  primaryColor,
  isThemePreview,
}: DarkModeSwitchProps) => {
  const [isDarkModeEnabled, setDarkModeEnabled] = useDarkMode();

  const textColor = `text-${
    isDarkModeEnabled
      ? getColorShade(primaryColor, 100)
      : getColorShade(primaryColor, 800)
  }`;

  const backgroundColor = `bg-${
    isDarkModeEnabled
      ? getColorShade(primaryColor, 800)
      : getColorShade(primaryColor, 100)
  }`;

  const hoverBackgroundColor = `hover:bg-${
    isDarkModeEnabled
      ? getColorShade(primaryColor, 700)
      : getColorShade(primaryColor, 200)
  }`;

  const borderColor = `border-${
    isDarkModeEnabled
      ? getColorShade(primaryColor, 400)
      : getColorShade(primaryColor, 300)
  }`;

  const toggleDarkMode = useCallback(() => {
    if (!isThemePreview) {
      trackEvent(DARK_MODE_TOGGLED_BY_USER, {
        newDarkModeValue: !isDarkModeEnabled,
      });
      setDarkModeEnabled(!isDarkModeEnabled);
    }
  }, [isThemePreview, isDarkModeEnabled, setDarkModeEnabled]);

  useOnKeyPress(KEY_D, toggleDarkMode, {
    altKey: true,
    enabled: !isThemePreview,
  });

  return (
    <KeyboardShortcutTooltip
      buildMode={false}
      delayShow={400}
      keys={[ALT, KEY_D]}
      label={getText(
        isDarkModeEnabled
          ? 'hints.darkMode.lightModeToggle'
          : 'hints.darkMode.darkModeToggle',
      )}
      offset={[0, 8]}
      placement="right"
    >
      <div
        className={classNames(
          'z-20 ml-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border px-2 py-2 text-xs md:hidden',
          textColor,
          backgroundColor,
          hoverBackgroundColor,
          borderColor,
        )}
        data-testid="dark-mode-switch"
        onClick={toggleDarkMode}
      >
        {isDarkModeEnabled ? (
          <div className="p-px">
            <IconSun size={16} />
          </div>
        ) : (
          <div className="p-px">
            <IconMoon size={16} />
          </div>
        )}
      </div>
    </KeyboardShortcutTooltip>
  );
};

export default DarkModeSwitch;
