export const elementStyleProps = {
  h1: {
    fontWeight: 'black',
    textSize: '6xl',
    mt: 0,
    mb: 8,
    leading: 'none',
    borderColor: undefined,
    borderL: undefined,
    italic: false,
  },
  h2: {
    fontWeight: 'bold',
    textSize: '4xl',
    mt: 0,
    mb: 6,
    leading: 'snug',
    borderColor: undefined,
    borderL: undefined,
    italic: false,
  },
  h3: {
    fontWeight: 'semibold',
    textSize: '2xl',
    mt: 6,
    mb: 4,
    leading: 'normal',
    borderColor: undefined,
    borderL: undefined,
    italic: false,
  },
  blockquote: {
    fontWeight: 'base',
    borderColor: 'gray-200',
    borderL: 2,
    italic: true,
    textSize: 'base',
    leading: 'relaxed',
    mt: 6,
    mb: 6,
    pl: 5,
  },
  p: {
    fontWeight: 'base',
    textSize: 'base',
    borderColor: undefined,
    borderL: undefined,
    italic: false,
    mt: 5,
    mb: 5,
  },
};

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

export const getNodePropsFromStyleProps = (styleProps = {}) => {
  const nodeProps = {};

  if ((styleProps as any).underline) {
    (nodeProps as any).underline = true;
  }

  if ((styleProps as any).fontWeight === 'bold') {
    (nodeProps as any).bold = true;
  }

  if ((styleProps as any).italic) {
    (nodeProps as any).italic = true;
  }

  if ((styleProps as any).fontFamily === 'mono') {
    (nodeProps as any).code = true;
  }

  return nodeProps;
};

export const getStylePropsFromNodeProps = (nodeProps = {}) => {
  const styleProps = {};

  if ((nodeProps as any).underline) {
    (styleProps as any).underline = true;
  }

  if ((nodeProps as any).bold) {
    (styleProps as any).fontWeight = 'bold';
  }

  if ((nodeProps as any).italic) {
    (styleProps as any).italic = true;
  }

  if ((nodeProps as any).code) {
    (styleProps as any).fontFamily = 'mono';
  }

  return styleProps;
};
