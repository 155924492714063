export const TEXT = 'TEXT';
export const DATE = 'DATE';
export const INTEGER = 'INTEGER';
export const DECIMAL = 'DECIMAL';
export const DURATION = 'DURATION';
export const NUMERIC_DATATYPES = [INTEGER, DECIMAL];
export const QR_DATATYPES = [TEXT, INTEGER, DECIMAL];

// This is the "FileType" enum
export const FILE_TYPE = 'FILE_TYPE';
export const BOOLEAN = 'BOOLEAN';
export const ARRAY = 'ARRAY';
export const SINGLE_OPTION = 'SINGLE_OPTION';
export const MULTIPLE_OPTION = 'MULTIPLE_OPTION';

// Not explicit types
export const LOOKUP = 'LOOKUP';
export const OBJECT = 'OBJECT';
export const ROLLUP = 'ROLLUP';
export const FORMULA = 'FORMULA';
export const AI_GENERATION = 'AI_GENERATION';

const dataTypes: DataFieldType[] = [
  TEXT,
  DATE,
  INTEGER,
  DECIMAL,
  DURATION,
  FILE_TYPE,
  BOOLEAN,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
];

export const DATA_FIELD_TYPE = {
  TEXT: TEXT,
  DATE: DATE,
  INTEGER: INTEGER,
  DECIMAL: DECIMAL,
  DURATION: DURATION,
  FILE_TYPE: FILE_TYPE,
  BOOLEAN: BOOLEAN,
  SINGLE_OPTION: SINGLE_OPTION,
  MULTIPLE_OPTION: MULTIPLE_OPTION,
  OBJECT: OBJECT,
} as const;

export type DataFieldType =
  | 'TEXT'
  | 'DATE'
  | 'INTEGER'
  | 'DECIMAL'
  | 'DURATION'
  | 'FILE_TYPE'
  | 'BOOLEAN'
  | 'SINGLE_OPTION'
  | 'MULTIPLE_OPTION'
  | 'OBJECT';

const INVALID_OPTION_FIELD_NAMES = [
  'collectionFilterInput',
  'connection',
  'edge',
  'importFields',
  'input',
  'relation',
  'relationFilterInput',
  'whereInput',
];

export const INVALID_FIELD_NAME_LOOKUP = {
  [MULTIPLE_OPTION]: INVALID_OPTION_FIELD_NAMES,
  [SINGLE_OPTION]: INVALID_OPTION_FIELD_NAMES,
};

export default dataTypes;
