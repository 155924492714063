import gillAvatar from '../img/gill.png';
import { GILL } from './onboardingManagers';

type OnboardingManagersConfig = Record<
  string,
  { calendly: string; email: string; avatar: string }
>;

const onboardingManagersConfig: OnboardingManagersConfig = {
  [GILL]: {
    calendly: 'https://calendly.com/gill-noloco/30min',
    email: 'gill@noloco.io',
    avatar: gillAvatar,
  },
};

export default onboardingManagersConfig;
