import { useRef } from 'react';
import QRCode from 'react-qr-code';
import FileDownloadButton from '../forms/FileDownloadButton';

const DownloadableQRCode = ({ value }: { value: string }) => {
  const qrcodeRef = useRef<string>(
    crypto.getRandomValues(new Uint8Array(8)).join('').slice(0, 12),
  );

  const handleDownload = () => {
    const qrcodeElement = document.getElementById(qrcodeRef.current);

    if (!qrcodeElement) {
      return;
    }

    const svgData = new XMLSerializer().serializeToString(qrcodeElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const img = new Image();

    img.onload = () => {
      canvas.width = img.width + 16;
      canvas.height = img.height + 16;

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, 8, 8);

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div className="group relative mx-auto w-fit overflow-hidden">
      <div className="absolute right-4 top-4 hidden rounded-lg bg-gray-700/80 p-1 group-hover:block">
        <FileDownloadButton
          onClick={handleDownload}
          className="text-white/60 hover:text-white/100"
        />
      </div>
      <QRCode
        id={qrcodeRef.current}
        value={value}
        className="aspect-square h-fit w-full border-[8px] border-white"
      />
    </div>
  );
};

export default DownloadableQRCode;
