import React, { Dispatch, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import { IconPlaneDeparture } from '@tabler/icons-react';
import { Popover } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import {
  setHasUnpublishedChanges,
  setPublishedVersion,
} from '@noloco/core/src/reducers/project';
import { PUBLISH_CLICKED, trackEvent } from '@noloco/core/src/utils/analytics';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useNolocoTheme from '@noloco/core/src/utils/hooks/useNolocoTheme';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';
import { PUBLISH_PROJECT } from '../../queries/project';
import AppSharingDetails from './AppSharingDetails';
import InviteUserSection from './InviteUserSection';
import PublishSection from './PublishSection';

type PublishPage = 'published' | 'unpublished';

interface PublishPopoverProps {
  dispatch: Dispatch<any>;
  domains: any[];
  hasUnpublishedChanges: boolean;
  isOpen: boolean;
  projectName: string;
  __publish: string;
  publishedVersion: number;
  publishMessage: string;
  setPublishMessage: (value: string) => void;
  setIsOpen: (isOpen: boolean) => void;
  isPublishComplete: boolean;
  versionHistoryModalIsOpen: boolean;
  setVersionHistoryModalIsOpen: (versionHistoryModalIsOpen: boolean) => void;
  onOpenChange: (nextIsOpen: any) => void;
}
export const PublishPopover = ({
  dispatch,
  domains,
  hasUnpublishedChanges,
  isOpen,
  projectName,
  publishMessage,
  setPublishMessage,
  setIsOpen,
  isPublishComplete,

  versionHistoryModalIsOpen,
  setVersionHistoryModalIsOpen,
  onOpenChange,
}: PublishPopoverProps) => {
  const errorAlert = useGraphQlErrorAlert();
  const [publishProject] = useMutation(PUBLISH_PROJECT);
  const [publishPage, setPublishPage] = useState<PublishPage>('unpublished');
  const [isLoading, setIsLoading] = useState(false);

  const nolocoTheme = useNolocoTheme();

  const onPublishClick = useCallback(
    (finalPublishMessage: string | null = null) => {
      trackEvent(PUBLISH_CLICKED);
      setIsLoading(true);
      const variables: Record<any, any> = {
        name: projectName,
      };

      if (finalPublishMessage) {
        variables.publishMessage = finalPublishMessage;
      }

      publishProject({
        variables,
      })
        .then((result) => {
          setPublishPage('published');
          setIsLoading(false);

          dispatch(setHasUnpublishedChanges(false));
          dispatch(
            setPublishedVersion(result?.data?.publishProject?.publishedVersion),
          );
        })
        .catch((error) => {
          console.error(error);
          errorAlert(getText('toolbar.publish.error'), error);
          setIsLoading(false);
        });
    },
    [publishProject, projectName, dispatch, errorAlert],
  );
  const nolocoDomain = getProjectDomain(projectName);

  return (
    <Popover
      trigger={null}
      isOpen={isOpen}
      placement="right"
      surface={LIGHT}
      closeOnOutsideClick={!versionHistoryModalIsOpen}
      onOpenChange={onOpenChange}
      bg="white"
      content={
        <TailwindThemeProvider theme={nolocoTheme}>
          <div className="min-w-84 my-3.5 flex min-h-80 grow flex-col px-2">
            <AppSharingDetails
              nolocoDomain={nolocoDomain}
              setIsOpen={setIsOpen}
              domains={domains}
            />
            {publishPage === 'unpublished' && (
              <>
                <p className="mb-5 mt-5 text-sm">
                  {getText('toolbar.publish.explainSuccinct')}
                </p>
                <PublishSection
                  setPublishMessage={setPublishMessage}
                  setVersionHistoryModalIsOpen={setVersionHistoryModalIsOpen}
                  onPublishClick={onPublishClick}
                  publishMessage={publishMessage}
                  isLoading={isLoading}
                  isPublishComplete={isPublishComplete}
                />
              </>
            )}
            {publishPage === 'published' && (
              <InviteUserSection
                loading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        </TailwindThemeProvider>
      }
    >
      <button
        onClick={() => {
          onOpenChange(!isOpen);
        }}
        className="relative flex h-14 cursor-pointer items-center justify-center bg-slate-900 px-3 py-2 text-slate-500 hover:text-slate-200"
        data-tour="publish"
        data-test="admin-nav-publish"
      >
        <div className="mx-auto flex">
          <IconPlaneDeparture size={20} />
        </div>
        {hasUnpublishedChanges && (
          <div className="absolute right-1 top-1 h-2 w-2 rounded-full bg-pink-400" />
        )}
      </button>
    </Popover>
  );
};
