import React, { useMemo } from 'react';
import {
  IconLink,
  IconMathFunction,
  IconRotateClockwise2,
  IconSparkles,
  IconZoomInArea,
} from '@tabler/icons-react';
import { SelectInput, Surface } from '@noloco/components';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  AIRTABLE,
  DataSourceType,
  INTERNAL,
} from '@noloco/core/src/constants/dataSources';
import {
  AI_GENERATION,
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  FORMULA,
  INTEGER,
  LOOKUP,
  MULTIPLE_OPTION,
  OBJECT,
  ROLLUP,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  VALID_FORMULA_TYPES_TO_CONVERT,
  VALID_TYPES_TO_CONVERT,
} from '@noloco/core/src/constants/fieldConversion';
import {
  DUE_DATE,
  FieldFormat,
  OBJECT_FORMATS,
  ObjectFieldFormat,
  PHONE_NUMBER,
} from '@noloco/core/src/constants/fieldFormats';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getText } from '@noloco/core/src/utils/lang';
import {
  HIDDEN_DATA_TYPES,
  getGroupedDataTypeOptions,
} from '../../../utils/dataTypes';
import DataFieldIcon from '../../DataFieldIcon';

const iconClassName = 'mr-2 w-5 text-gray-200';

const LANG_KEY = 'data.typeInput';

const isObjectFormat = (type: string) => OBJECT_FORMATS.includes(type as any);
const getOptionItemFromTypeString = (typeString: string) =>
  isObjectFormat(typeString)
    ? {
        value: typeString,
        label: getText('data.types', OBJECT, typeString, 'label'),
        icon: (
          <DataFieldIcon
            field={
              {
                type: OBJECT,
                typeOptions: { format: typeString },
              } as DataField
            }
            className={iconClassName}
          />
        ),
      }
    : {
        value: typeString,
        label: getText('data.types', typeString, 'label'),
        icon: (
          <DataFieldIcon
            field={{ type: typeString } as DataField}
            className={iconClassName}
          />
        ),
      };

const AVAILABLE_DATA_TYPES = [
  TEXT,
  DATE,
  INTEGER,
  DECIMAL,
  BOOLEAN,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  DURATION,
];

const dataTypeFilter = ({ name, internal }: any) =>
  !HIDDEN_DATA_TYPES.includes(name) && !internal;

interface Props {
  aiGenerationFieldsEnabled: boolean;
  value: string | null;
  dataTypes: any;
  filter?: any;
  onChange: (
    value: DataFieldType | FieldFormat | ObjectFieldFormat | typeof FORMULA,
  ) => void;
  refetchData?: any;
  surface: Surface;
  typeOptions?: string[];
  readOnly?: boolean;
  isLinkField?: boolean;
  dataField?: DataField;
  optionsFilter?: (option: any) => boolean;
  dataTypeSource: DataSourceType;
}

const FieldTypeInput = ({
  dataTypes,
  filter = dataTypeFilter,
  aiGenerationFieldsEnabled,
  onChange,
  readOnly = false,
  surface,
  value,
  dataField,
  optionsFilter,
  dataTypeSource,
}: Props) => {
  const groupedDataTypeOptions = useMemo(
    () =>
      getGroupedDataTypeOptions(dataTypes, {
        filter,
      }),
    [dataTypes, filter],
  );

  const defaultOptions = useMemo(
    () => [
      ...AVAILABLE_DATA_TYPES.map(getOptionItemFromTypeString),
      ...OBJECT_FORMATS.filter((format) => format !== DUE_DATE).map(
        getOptionItemFromTypeString,
      ),
      {
        value: FILE,
        label: getText(LANG_KEY, 'fileLabel'),
        icon: (
          <DataFieldIcon
            field={{ type: FILE } as DataField}
            className={iconClassName}
          />
        ),
      },
      {
        icon: <IconMathFunction size={16} className={iconClassName} />,
        label: getText(LANG_KEY, 'formula.label'),
        help: getText(LANG_KEY, 'formula.help'),
        value: FORMULA,
      },
      ...(aiGenerationFieldsEnabled && dataTypeSource === INTERNAL
        ? [
            {
              icon: <IconSparkles size={16} className={iconClassName} />,
              label: getText(LANG_KEY, 'aiGeneration.label'),
              help: getText(LANG_KEY, 'aiGeneration.help'),
              value: AI_GENERATION,
            },
          ]
        : []),

      {
        icon: <IconZoomInArea size={16} className={iconClassName} />,
        label: getText(LANG_KEY, 'lookup.label'),
        help: getText(LANG_KEY, 'lookup.help'),
        value: LOOKUP,
      },
      {
        icon: <IconRotateClockwise2 size={16} className={iconClassName} />,
        label: getText(LANG_KEY, 'rollup.label'),
        help: getText(LANG_KEY, 'rollup.help'),
        value: ROLLUP,
      },
      {
        icon: <IconLink size={16} className={iconClassName} />,
        label: getText(LANG_KEY, 'relations'),
        options: groupedDataTypeOptions,
      },
    ],
    [aiGenerationFieldsEnabled, dataTypeSource, groupedDataTypeOptions],
  );

  const options = useMemo(() => {
    if (optionsFilter) {
      return defaultOptions.filter(optionsFilter);
    }

    if (!dataField) {
      return defaultOptions;
    }

    if (dataField.typeOptions?.aiGenerationOptions) {
      return [
        {
          icon: <IconSparkles size={16} className={iconClassName} />,
          label: getText(LANG_KEY, 'aiGeneration.label'),
          help: getText(LANG_KEY, 'aiGeneration.help'),
          value: AI_GENERATION,
        },
      ];
    }

    const isAirTable = dataField.source === AIRTABLE;

    if (isAirTable) {
      return [TEXT, PHONE_NUMBER].map(getOptionItemFromTypeString);
    }
    const isFormula = !!dataField.typeOptions?.formula;

    if (isFormula) {
      return VALID_FORMULA_TYPES_TO_CONVERT.map(getOptionItemFromTypeString);
    }

    if (dataField.relationship && dataField.type !== FILE) {
      return [getOptionItemFromTypeString(TEXT), ...groupedDataTypeOptions];
    }

    if (dataField.type === TEXT) {
      return [
        ...VALID_TYPES_TO_CONVERT.map(getOptionItemFromTypeString),
        {
          icon: <IconLink size={16} className={iconClassName} />,
          label: getText(LANG_KEY, 'relations'),
          options: groupedDataTypeOptions,
        },
      ];
    }

    if (VALID_TYPES_TO_CONVERT.includes(dataField.type)) {
      return VALID_TYPES_TO_CONVERT.map(getOptionItemFromTypeString);
    }

    return defaultOptions;
  }, [dataField, defaultOptions, groupedDataTypeOptions, optionsFilter]);

  return (
    <SelectInput
      className="mb-2 text-black"
      data-testid="field-type-input"
      contained={true}
      disabled={readOnly}
      options={options}
      placeholder={getText(LANG_KEY, 'placeholder')}
      readOnly={readOnly}
      searchable={true}
      onChange={onChange}
      surface={surface}
      value={value}
    />
  );
};

export default FieldTypeInput;
