import React, { useLayoutEffect, useMemo, useState } from 'react';
import { IconMenu2 } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import {
  HorizontalNav,
  Popover,
  VerticalNav,
  getColorShade,
} from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import { UpdateProjectCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { MenuStyle, SIDE_MENU, TOP_MENU } from '../../constants/menuStyles';
import { ElementPath } from '../../models/Element';
import { selectedPagePathSelector } from '../../selectors/elementsSelectors';
import { projectDataSelector } from '../../selectors/projectSelectors';
import useCurrentSpace from '../../utils/hooks/useCurrentSpace';
import useDarkMode from '../../utils/hooks/useDarkMode';
import { Page } from '../../utils/pages';
import AddPageLine from './AddPageLine';
import HiddenNavItem from './HiddenNavItem';
import NavItem from './NavItem';

const NAV_REFRESH_MS = 300;
const PADDING_PX = 8;
const MARGIN_X_PX = 4;
const NAV_OVERFLOW_BUTTON_WIDTH_PX = 80;

const calculateVisiblePages = (screenWidth: number | undefined) => {
  const navItemsWrapper = document.querySelector('.nav-items-wrapper');

  if (navItemsWrapper) {
    const navItemsWrapperWidth = Math.min(
      navItemsWrapper.clientWidth,
      (screenWidth ?? Infinity) - 200,
    );

    const navItems = [
      ...navItemsWrapper.querySelectorAll(
        '[navoptions] [data-testid="sidebar-nav-item"]',
      ),
    ];

    let visiblePagesIds: (string | undefined)[] = [];
    let visibleNavItemsWidth = PADDING_PX * 2;
    let calculate = true;

    while (calculate && visiblePagesIds.length < navItems.length) {
      const navItem = navItems[visiblePagesIds.length];
      visibleNavItemsWidth =
        visibleNavItemsWidth + navItem.clientWidth + MARGIN_X_PX;

      const willOverflow = navItems.length > visiblePagesIds.length + 1;

      if (
        visibleNavItemsWidth <
        navItemsWrapperWidth - (willOverflow ? NAV_OVERFLOW_BUTTON_WIDTH_PX : 0)
      ) {
        const navId = navItem.getAttribute('data-nav-item-id') as
          | string
          | undefined;

        visiblePagesIds.push(navId);
      } else {
        calculate = false;
      }
    }

    const cleanVisiblePages = visiblePagesIds.filter(Boolean) as string[];
    const hasOverflow = navItems.length > cleanVisiblePages.length;

    return { visiblePagesIds: cleanVisiblePages, hasOverflow };
  }

  return { visiblePagesIds: undefined, hasOverflow: false };
};

interface SidebarNavProps {
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  editingPage: string;
  editorMode: boolean;
  handleSetEditingPage: (newEditingPage: string) => void;
  menuStyle: MenuStyle;
  onEditSelectPage: (id: string, path: ElementPath) => void;
  pages: Page[];
  pagesPath: ElementPath;
  portalPages: Page[];
  portalPath: ElementPath;
  primaryColor: string;
  showExpandedNav: boolean;
  updateProject: UpdateProjectCallback;
  updateProperty: (path: ElementPath, value: any) => void;
}

const SidebarNav = ({
  debouncedUpdateProperty,
  editingPage,
  editorMode,
  handleSetEditingPage,
  menuStyle,
  onEditSelectPage,
  pages,
  pagesPath,
  portalPages,
  portalPath,
  primaryColor,
  showExpandedNav,
  updateProject,
  updateProperty,
}: SidebarNavProps) => {
  const { sm: isSmScreen } = useBreakpoints();
  const { width } = useWindowSize();
  const project = useSelector(projectDataSelector);
  const selectedPagePath = useSelector(selectedPagePathSelector);
  const [isDarkModeEnabled] = useDarkMode();
  const [currentSpace] = useCurrentSpace();
  const [visiblePageIds, setVisiblePageIds] = useState<undefined | string[]>(
    undefined,
  );
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (menuStyle === TOP_MENU && !isSmScreen) {
      interval = setInterval(() => {
        const result = calculateVisiblePages(width);

        if (!isEqual(visiblePageIds, result.visiblePagesIds)) {
          setVisiblePageIds(result.visiblePagesIds);
          setHasOverflow(result.hasOverflow);
        }
      }, NAV_REFRESH_MS);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isSmScreen, menuStyle, visiblePageIds, width, portalPages]);

  const hiddenPages = useMemo(
    () =>
      editorMode
        ? portalPages
            .map((page, index) => ({
              page,
              index,
            }))
            .filter(
              ({ page }: any) =>
                page.id &&
                get(page, 'props.hide') &&
                !get(page, 'props.parentPage'),
            )
        : [],
    [editorMode, portalPages],
  );

  const RootComponent = useMemo(
    () => (menuStyle === SIDE_MENU || isSmScreen ? VerticalNav : HorizontalNav),
    [menuStyle, isSmScreen],
  );

  const sortedPortalPages = useMemo(() => {
    if (editorMode || !currentSpace?.id) {
      return portalPages;
    }

    return sortBy(portalPages, (portalPage) =>
      get(portalPage, ['spaces', currentSpace.id!, 'order'], 0),
    );
  }, [editorMode, portalPages, currentSpace]);

  return (
    <RootComponent
      bg="transparent"
      border={0}
      center={false}
      rounded={0}
      type="rounded"
    >
      {sortedPortalPages.map((page: Page, index: number) => (
        <React.Fragment key={page.id}>
          {editorMode && menuStyle === SIDE_MENU && (
            <AddPageLine
              page={page}
              portalPages={portalPages}
              primaryColor={primaryColor}
              project={project}
            />
          )}
          <NavItem
            className={classNames({
              'invisible absolute':
                !isSmScreen &&
                menuStyle === TOP_MENU &&
                visiblePageIds &&
                !visiblePageIds.includes(page.id),
            })}
            debouncedUpdateProperty={debouncedUpdateProperty}
            editingPage={editingPage}
            editorMode={editorMode}
            handleSetEditingPage={handleSetEditingPage}
            index={index}
            key={page.id}
            menuStyle={isSmScreen ? SIDE_MENU : menuStyle}
            onEditSelectPage={onEditSelectPage}
            page={page}
            pages={pages}
            pagesPath={pagesPath}
            portalPages={portalPages}
            portalPath={portalPath}
            primaryColor={primaryColor}
            project={project}
            showExpandedNav={showExpandedNav}
            updateProject={updateProject}
            updateProperty={updateProperty}
          />
        </React.Fragment>
      ))}
      {hasOverflow && visiblePageIds && (
        <Popover
          content={
            // @ts-expect-error TS(2786): 'SimpleBar' cannot be used as a JSX component.
            <SimpleBar
              className={classNames(
                'max-h-screen-75 flex w-64 flex-col overflow-y-auto px-2 py-1 text-xs',
                `bg-${getColorShade(
                  primaryColor,
                  isDarkModeEnabled ? 800 : 700,
                )}`,
              )}
            >
              {portalPages.map(
                (page: Page, index: number) =>
                  visiblePageIds &&
                  !visiblePageIds.includes(page.id) && (
                    <NavItem
                      debouncedUpdateProperty={debouncedUpdateProperty}
                      editingPage={editingPage}
                      editorMode={editorMode}
                      handleSetEditingPage={handleSetEditingPage}
                      index={index}
                      key={page.id}
                      menuStyle={SIDE_MENU}
                      onEditSelectPage={onEditSelectPage}
                      page={page}
                      pages={pages}
                      pagesPath={pagesPath}
                      portalPages={portalPages}
                      portalPath={portalPath}
                      primaryColor={primaryColor}
                      project={project}
                      showExpandedNav={showExpandedNav}
                      updateProject={updateProject}
                      updateProperty={updateProperty}
                    />
                  ),
              )}
            </SimpleBar>
          }
          trigger="hover"
          border={0}
          delayHide={150}
          p={0}
          showArrow={false}
        >
          <button
            className={classNames(
              'ml-1 flex h-full flex-shrink-0 rounded-lg px-3 py-2 sm:hidden',
              `bg-${getColorShade(
                primaryColor,
                isDarkModeEnabled ? 800 : 700,
              )}`,
              `hover:bg-${getColorShade(primaryColor, 600)}`,
              `focus:bg-${getColorShade(primaryColor, 600)}`,
              `text-${getColorShade(primaryColor, 200)}`,
            )}
          >
            <IconMenu2 size={20} />
          </button>
        </Popover>
      )}
      {editorMode && portalPages.length > 0 && menuStyle === SIDE_MENU && (
        <AddPageLine
          portalPages={portalPages}
          primaryColor={primaryColor}
          project={project}
        />
      )}
      {editorMode && hiddenPages.length > 0 && menuStyle === SIDE_MENU && (
        <hr
          className={classNames(
            'mx-6 my-3',
            `border-${getColorShade(primaryColor, 500)}`,
          )}
        />
      )}
      {editorMode &&
        menuStyle === SIDE_MENU &&
        hiddenPages.map(({ page, index }: any) => (
          <HiddenNavItem
            editingPage={editingPage}
            editorMode={editorMode}
            debouncedUpdateProperty={debouncedUpdateProperty}
            handleSetEditingPage={handleSetEditingPage}
            index={index}
            onEditSelectPage={onEditSelectPage}
            page={page}
            pages={pages}
            portalPath={portalPath}
            portalPages={portalPages}
            pagesPath={pagesPath}
            primaryColor={primaryColor}
            project={project}
            selectedPagePath={selectedPagePath}
            showExpandedNav={showExpandedNav}
            updateProject={updateProject}
            updateProperty={updateProperty}
            key={page.id}
          />
        ))}
    </RootComponent>
  );
};

export default SidebarNav;
