import React from 'react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import {
  LG,
  MD,
  SM,
  ShirtSize,
  XS,
} from '@noloco/components/src/constants/tShirtSizes';

interface Props {
  className?: string;
  children: any;
  size?: ShirtSize;
  surface?: Surface;
}

const SimpleTableCell = ({
  className,
  children,
  size = MD,
  surface = DARK,
}: Props) => (
  <td
    className={classNames(
      'whitespace-nowrap text-left text-xs font-semibold',
      {
        'px-1 py-0.5': size === XS,
        'px-2 py-1': size === SM,
        'px-3 py-2': size === MD,
        'px-4 py-3': size === LG,
        'text-gray-100': surface === DARK,
        'text-gray-900': surface === LIGHT,
      },
      className,
    )}
  >
    {children}
  </td>
);

export default SimpleTableCell;
