import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconDownload } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader } from '@noloco/components';
import { File } from '../../../models/File';
import { downloadFileFromUrl } from '../../../utils/files';

interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  file?: File;
  is?: 'button' | 'a';
  onClick?: () => void;
}

const FileDownloadButton = ({
  children,
  className,
  disabled,
  file,
  is = 'button',
  onClick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const hasValidCallback = useMemo(
    () => (file && file.url) || onClick,
    [file, onClick],
  );

  const handleOnClick = useCallback(() => {
    if (file && file.url) {
      return downloadFileFromUrl(file.url, file.name).finally(() => {
        setIsLoading(false);
      });
    }

    onClick?.();
    setIsLoading(false);
  }, [file, onClick]);

  const onDownload = useCallback(() => {
    setIsLoading(true);
    handleOnClick();
  }, [handleOnClick]);

  if (!hasValidCallback) {
    return null;
  }

  return (
    <Box
      className={classNames(
        className,
        'disabled:opacity50 group flex items-center justify-center',
      )}
      disabled={disabled}
      onClick={isLoading ? undefined : onDownload}
      is={is}
    >
      {isLoading ? (
        <Loader size="sm" />
      ) : (
        <IconDownload
          className="opacity-75 group-hover:opacity-100"
          size={16}
        />
      )}
      {children}
    </Box>
  );
};

export default FileDownloadButton;
