import { useMemo } from 'react';
import { SelectInput } from '@noloco/components/src';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import fileDisplays, {
  FILE_NAME,
  THUMBNAIL,
} from '@noloco/core/src/constants/fileDisplay';
import { FileLayout } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdateProjectCallback } from '../../../utils/hooks/projectHooks';
import SizeEditor from './SizeEditor';

interface FileFieldLayoutEditorProps {
  fileLayout?: FileLayout;
  onUpdate: UpdateProjectCallback;
}

const LANG_KEY = 'elements.VIEW.fields.fileLayout';

const SIZES = [SM, MD, LG, XL];

const FileFieldLayoutEditor = ({
  fileLayout,
  onUpdate,
}: FileFieldLayoutEditorProps) => {
  const displayOptions = useMemo(
    () =>
      fileDisplays.map((fileLayout: string) => ({
        value: fileLayout,
        label: getText(LANG_KEY, 'options', fileLayout),
      })),
    [],
  );

  const { display = THUMBNAIL, size = MD } = fileLayout ?? {};

  return (
    <div className="flex flex-col space-y-4">
      <BuildModeInput label={getText(LANG_KEY, 'label')}>
        <SelectInput
          onChange={(value: any) => onUpdate(['display'], value)}
          options={displayOptions}
          value={display}
          contained={true}
        />
      </BuildModeInput>
      {display !== FILE_NAME && (
        <BuildModeInput label={getText(LANG_KEY, 'size')}>
          <SizeEditor
            defaultSize={MD}
            onChange={(value: any) => onUpdate(['size'], value)}
            sizes={SIZES}
            value={size}
          />
        </BuildModeInput>
      )}
    </div>
  );
};

export default FileFieldLayoutEditor;
