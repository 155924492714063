import React from 'react';
import { HelpTooltip, Switch } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'dynamicValueInput.dynamicSwitch';

interface DynamicSwitchProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

const DynamicSwitch = ({ onChange, value }: DynamicSwitchProps) => (
  <div className="flex w-full max-w-full items-center text-sm text-gray-100">
    <span className="mr-1 whitespace-nowrap text-xs">
      {getText(LANG_KEY, 'label')}
    </span>
    <HelpTooltip className="mr-4 text-white hover:text-gray-500">
      <span>{getText(LANG_KEY, 'tooltip')}</span>
    </HelpTooltip>
    <Switch size="sm" className="ml-auto" value={value} onChange={onChange} />
  </div>
);

export default DynamicSwitch;
