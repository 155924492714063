import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { SelectInput } from '@noloco/components';
import { BOOLEAN } from '@noloco/core/src/constants/dataTypes';
import {
  EMAIL,
  EMPTY,
  FALSE,
  IN,
  NOT_EMPTY,
  NOT_IN,
  TRUE,
  URL,
} from '@noloco/core/src/constants/operators';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Condition } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getDataItemDataType } from '@noloco/core/src/utils/data';
import { getFieldFromDependency } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { getOperatorsForFieldType } from '@noloco/core/src/utils/operator';
import { flattenStateItem } from '@noloco/core/src/utils/state';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import ConditionValueEditor from '../../canvas/ConditionValueEditor';

interface FieldValidationRuleEditorProps {
  contained?: boolean;
  dataType: DataType;
  fieldOptions: any[];
  inline: boolean;
  updateCondition: UpdatePropertyCallback;
  elementPath: any[];
  condition: Condition;
  project: Project;
  recordScopeOptions: any[];
  validationRules: any[];
  ValueInput?: any;
}

const FieldValidationRuleEditor = ({
  condition,
  contained = true,
  dataType,
  elementPath,
  fieldOptions,
  inline,
  project,
  recordScopeOptions,
  updateCondition,
  validationRules,
  ValueInput = ConditionValueEditor,
}: FieldValidationRuleEditorProps) => {
  const fieldOptionOperators = useMemo(
    () =>
      fieldOptions.map((fieldOption: any) => {
        const fieldOptionDataType = getDataItemDataType(fieldOption.value);
        const operators = fieldOption
          ? getOperatorsForFieldType(fieldOptionDataType)
          : [];

        return {
          ...fieldOption,
          options: operators.map((operator) => ({
            icon: fieldOption.icon,
            label: upperFirst(
              getText(
                { name: fieldOption.label },
                'operators',
                operator,
                'label.named',
              ).trim(),
            ),
            value: {
              field: fieldOption.value,
              operator,
            },
          })),
        };
      }),
    [fieldOptions],
  );

  const ruleOptions = useMemo(() => {
    const options =
      fieldOptionOperators.length === 1
        ? get(fieldOptionOperators, [0, 'options'])
        : fieldOptionOperators;

    return [...options, ...validationRules];
  }, [fieldOptionOperators, validationRules]);

  const flatRuleOptions = useMemo(
    () =>
      ruleOptions.reduce(
        (acc, ruleOption) => [
          ...acc,
          ...(ruleOption.options ? ruleOption.options : [ruleOption]),
        ],
        [],
      ),
    [ruleOptions],
  );

  const selectedRuleOption = useMemo(
    () =>
      flatRuleOptions.find(
        (ruleOption: any) =>
          condition &&
          flattenStateItem(ruleOption.value.field) ===
            flattenStateItem(condition.field) &&
          ruleOption.value.operator === condition.operator,
      ),
    [condition, flatRuleOptions],
  );

  const onChange = useCallback(
    (newValue) =>
      updateCondition([], {
        ...condition,
        operator: newValue.operator,
        field: newValue.field,
        value:
          newValue.field.dataType === BOOLEAN
            ? newValue.operator === TRUE
            : condition.value,
      }),
    [condition, updateCondition],
  );

  const onValueChange = useCallback(
    (newValue) => updateCondition(['value'], newValue),
    [updateCondition],
  );

  const debouncedOnValueChange = useMemo(
    () => debounce(onValueChange, 500),
    [onValueChange],
  );

  const { field, fieldDataType } = useMemo(() => {
    if (!condition || !condition.field || !condition.field.path || !dataType) {
      return {};
    }

    const dataTypes = project.dataTypes;
    const fieldResult = getFieldFromDependency(
      condition.field.path.split('.'),
      dataType,
      dataTypes,
    );

    if (!fieldResult) {
      return {};
    }

    return {
      fieldDataType: fieldResult.dataType,
      field: fieldResult.field,
    };
  }, [condition, dataType, project.dataTypes]);

  return (
    <div
      className={classNames('flex', {
        'flex-col justify-center space-y-4': !inline,
        'items-center space-x-2': inline,
      })}
    >
      <SelectInput
        contained={contained}
        className="w-full"
        value={get(selectedRuleOption, 'label')}
        options={ruleOptions}
        onChange={onChange}
        placeholder={getText('validationRuleEditor.rule.placeholder')}
      />
      {![EMPTY, NOT_EMPTY, TRUE, FALSE, EMAIL, URL].includes(
        condition.operator,
      ) && (
        <ValueInput
          additionalScopeItems={recordScopeOptions}
          field={field}
          dataType={fieldDataType}
          project={project}
          contained={contained}
          onChange={debouncedOnValueChange}
          disabled={!condition.operator}
          multiple={[IN, NOT_IN].includes(condition.operator)}
          elementPath={elementPath}
          placeholder={getText('validationRuleEditor.value.placeholder')}
          value={condition.value || []}
        />
      )}
    </div>
  );
};

export default FieldValidationRuleEditor;
