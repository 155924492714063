import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconCircleNumber1,
  IconCircleNumber2,
  IconExclamationCircle,
  IconFileImport,
} from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import Papa from 'papaparse';
import {
  AutoSizedTextInput,
  ErrorText,
  Modal,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { XL } from '@noloco/components/src/constants/tShirtSizes';
import Dropzone from '@noloco/core/src/components/dropzone/Dropzone';
import { DataSchemaField } from '@noloco/core/src/constants/dataSchema';
import { DOCUMENT } from '@noloco/core/src/constants/fileTypes';
import { TEXT_CSV } from '@noloco/core/src/constants/mimetypes';
import DropzonePreview from '@noloco/core/src/elements/sections/forms/DropzonePreview';
import DataTypes, {
  DataType,
  DataTypeArray,
} from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { buildFields } from '@noloco/core/src/utils/dataSchema';
import { useErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import { ADD_DATA_TYPE } from '../../queries/project';
import { useAddElements } from '../../utils/hooks/useAddElements';
import { useBuildDataTypeLayout } from '../../utils/hooks/useBuildDataTypeLayout';
import { useImportCsvFile } from '../../utils/hooks/useImportCsvFile';
import useIsNameUnique from '../../utils/hooks/useIsNameUnique';
import { useAddDataType } from '../../utils/hooks/useUpdateDataTypes';
import Guide from '../Guide';
import DataSourceImportBuildLayouts from './DataSourceImportBuildLayouts';
import DataSourceImportStatusSection from './DataSourceImportStatusSection';
import DataSchemaEditor from './dataSchema/DataSchemaEditor';

const LANG_KEY = 'data.csv';
const SHARED_LANG_KEY = 'data.dataSources.connect';

interface Props {
  inOnboarding?: boolean;
  onCancel: (...args: any[]) => void;
  onConfirm?: (...args: any[]) => void;
  project: Project;
}

const AddCsv = ({
  inOnboarding = false,
  onCancel,
  onConfirm,
  project,
}: Props) => {
  const addDataType = useAddDataType();
  const errorAlert = useErrorAlert();
  const { push } = useRouter();
  const [step, setStep] = useState(0);

  const [parsingFile, setParsingFile] = useState(false);

  const [file, setFile] = useState(null);
  const [results, setResults] = useState<any>(null);

  const [display, setDisplay] = useState<string>('');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [fields, setFields] = useState<DataSchemaField[] | null>(null);

  const [createDataType] = useMutation(ADD_DATA_TYPE);

  const [creatingDataType, setCreatingDataType] = useState(false);
  const [dataType, setDataType] = useState<DataType | null>(null);

  const [importing, setImporting] = useState(false);
  const importCsvFile = useImportCsvFile(project);

  const [view, setView] = useState<any>(null);
  const buildDataTypeLayout = useBuildDataTypeLayout(() => setStep(4));
  const addElements = useAddElements(project, inOnboarding);

  const redirectToNewView = useCallback(() => {
    if (!view) {
      push('/_/data/internal/');
    }

    push(`/${get(view, 'props.routePath')}`);
  }, [push, view]);

  useSetDocumentTitle(getText(LANG_KEY, 'title'));

  const isDisplayValid = useIsNameUnique(display, project.dataTypes);

  const displayError = useMemo(
    () =>
      isTouched && (!display || !isDisplayValid || step > 0)
        ? getText(LANG_KEY, 'display.invalid')
        : null,
    [display, isDisplayValid, step, isTouched],
  );

  const onDropFile = useCallback(([newFile]) => {
    setFile(newFile);
    setParsingFile(true);
    Papa.parse(newFile, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
      error: (err) => {
        console.log('ERROR', err);
        setParsingFile(false);
      },
      complete: (results: any) => {
        setParsingFile(false);
        setResults(results);
        setFields(buildFields([], results.data, null, false, false));
      },
    });
  }, []);

  const onImport = useCallback(async () => {
    if (!display || !fields || step > 0) {
      return;
    }
    setStep(1);

    try {
      setCreatingDataType(true);
      const { data } = await createDataType({
        variables: {
          display,
          fields: fields.map((field) => ({
            display: field.display,
            type: field.type,
            options: field.options,
            relationship: field.relationship,
          })),
          projectName: project.name,
        },
      });

      addDataType(data.addDataType);
      setDataType(data.addDataType);
      setCreatingDataType(false);
      setStep(2);

      setImporting(true);
      await importCsvFile(data.addDataType, fields, file);
      setStep(3);
      setImporting(false);

      const newView = await buildDataTypeLayout(
        set(
          'dataTypes',
          new DataTypes([...project.dataTypes, data.addDataType!]),
          project,
        ),
        DataTypeArray.formatDataType(data.addDataType),
      );
      setView(newView);
      addElements([newView]);
      setStep(4);
    } catch (e) {
      setCreatingDataType(false);
      setImporting(false);

      setDataType(null);
      setView(null);

      setStep(0);
      console.error(e);
      errorAlert(getText(LANG_KEY, 'error'));
    }
  }, [
    addDataType,
    addElements,
    buildDataTypeLayout,
    createDataType,
    display,
    errorAlert,
    fields,
    file,
    importCsvFile,
    project,
    step,
  ]);

  const confirmDisabled = useMemo(() => {
    if (!displayError && isTouched && results && step === 0) {
      return false;
    }

    if (step >= 4) {
      return false;
    }

    return true;
  }, [displayError, isTouched, results, step]);

  return (
    <Modal
      canCancel={!inOnboarding}
      canConfirm={true}
      closeOnOutsideClick={!inOnboarding}
      confirmDisabled={confirmDisabled}
      confirmText={
        step < 4
          ? getText(LANG_KEY, 'confirm')
          : getText(SHARED_LANG_KEY, 'finish')
      }
      icon={<IconFileImport size={18} />}
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={step < 4 ? onImport : (onConfirm ?? redirectToNewView)}
      size={XL}
      title={
        <div className="flex">
          <h3 className="text-lg font-semibold">
            {getText(LANG_KEY, 'title')}
          </h3>
        </div>
      }
    >
      <div className="flex h-96 w-full flex-col overflow-y-auto">
        {step === 0 && (
          <>
            <div className="mb-4 space-y-4">
              <Guide href="https://guides.noloco.io/data/collections/import-a-file">
                {getText(LANG_KEY, 'help')}
              </Guide>
            </div>
            <div className="mb-2 flex w-full flex-col items-baseline">
              <div className="mb-2 flex w-full gap-2 pb-1">
                <IconCircleNumber1 size={22} className="mr-2 text-gray-600" />
                <h4 className="text-sm font-medium text-gray-600">
                  {getText(LANG_KEY, 'display.pickName')}
                </h4>
                {displayError && (
                  <Tooltip content={displayError}>
                    <IconExclamationCircle
                      className="my-auto text-red-600"
                      size={20}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="w-full px-3">
                <AutoSizedTextInput
                  disabled={step > 0}
                  minWidth={1000}
                  className="mb-2 rounded-lg border border-gray-300 p-3 text-xs text-gray-400 placeholder-gray-500"
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setDisplay(value);
                    setIsTouched(true);
                  }}
                  placeholder={getText(LANG_KEY, 'display.placeholder')}
                  readOnly={step > 0}
                  value={display}
                />
              </div>
            </div>

            {displayError && (
              <ErrorText className="mb-8">{displayError}</ErrorText>
            )}
          </>
        )}
        {!results && step === 0 && (
          <div className="flex h-full flex-col">
            <div className="flex flex-row gap-2 text-gray-600">
              <IconCircleNumber2 size={22} className="mr-2 text-gray-600" />

              <h4 className="mb-2 text-sm font-medium text-gray-600">
                {getText(LANG_KEY, 'display.dropFile')}
              </h4>
            </div>
            {getText(LANG_KEY, 'file.help')}
            <div className="mx-1 mt-1">
              <Dropzone
                acceptedMimetypes={[TEXT_CSV]}
                className="h-44"
                disabled={step > 0}
                maxFiles={1}
                onChange={onDropFile}
                p={4}
                surface={LIGHT}
              >
                <DropzonePreview
                  disabled={step > 0}
                  fileType={DOCUMENT}
                  loading={parsingFile}
                  mediaItem={null}
                  onRemove={() => null}
                  placeholder={getText(LANG_KEY, 'file.placeholder')}
                  showIcon={true}
                />
              </Dropzone>
            </div>
          </div>
        )}
        {results && step === 0 && (
          <div className="flex h-full flex-col">
            <div className="text-gray-600">
              {getText(LANG_KEY, 'schema.help')}
            </div>
            <div className="mt-auto h-64 p-2">
              <DataSchemaEditor
                className="rounded-lg border border-gray-300"
                fields={fields}
                idField={null}
                includeDefault={false}
                includeEmpty={false}
                onUpdateFields={setFields}
                records={results.data}
                surface={LIGHT}
              />
            </div>
          </div>
        )}
        {step > 0 && (
          <div className="mx-auto mb-8 mt-6 flex w-full max-w-2xl flex-col space-y-4 overflow-y-auto px-4">
            <DataSourceImportStatusSection
              loading={creatingDataType}
              title={getText(LANG_KEY, 'dataType.label')}
              subtitle={getText(LANG_KEY, 'dataType.help')}
              surface={LIGHT}
            />
            {dataType && fields && step > 1 && (
              <DataSourceImportStatusSection
                loading={importing}
                title={getText(LANG_KEY, 'import.label')}
                subtitle={getText(LANG_KEY, 'import.help')}
                surface={LIGHT}
              />
            )}
            {dataType && step > 2 && (
              <DataSourceImportBuildLayouts
                builtPages={step === 4 ? [dataType] : []}
                inProgressPages={[dataType]}
                loading={step < 4}
                skippedPages={[]}
                surface={LIGHT}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddCsv;
