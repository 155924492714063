import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash/debounce';
import TextInput, {
  TextInputProps,
} from '@noloco/components/src/components/input/TextInput';
import { FieldFormat, MINUTES_SECONDS } from '../constants/fieldFormats';
import {
  durationToString,
  formatDuration as formatInputDuration,
  getDurationFromString,
} from '../utils/durations';

type Props = {
  format?: FieldFormat;
  onBlur?: (event: any) => void;
  value?: string | null;
} & TextInputProps;

const DurationInput = forwardRef<TextInputProps, Props>(
  ({ format, onBlur, onChange, value, ...rest }, ref) => {
    const [localValidationError, setLocalValidationError] = useState(false);

    const debouncedOnChange = useMemo(
      () =>
        debounce(
          // Using any since we're injecting a value
          (newVal: string | null) =>
            onChange?.({ target: { value: newVal } } as any),
          600,
        ),
      [onChange],
    );

    const formatDuration = useCallback(
      (value: string | null) => formatInputDuration(value, format),
      [format],
    );

    const validateAndFormatDuration = useCallback(
      (value: string) => {
        if (!value) {
          setLocalValidationError(false);

          return null;
        }

        const duration = getDurationFromString(
          value,
          format === MINUTES_SECONDS,
        );

        if (!duration || !duration.isValid) {
          setLocalValidationError(true);

          return value;
        }

        setLocalValidationError(false);

        return durationToString(duration);
      },
      [format],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const nextValue = event.target.value;

        if (!nextValue) {
          setLocalValidationError(false);

          return debouncedOnChange(null);
        }

        const formattedValue = validateAndFormatDuration(nextValue);

        if (formattedValue) {
          debouncedOnChange(formattedValue);
        }
      },
      [debouncedOnChange, validateAndFormatDuration],
    );

    const handleBlur = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatDuration(event.target.value);

        if (formattedValue !== event.target.value) {
          onChange?.({ target: { value: formattedValue } } as any);
        }

        onBlur?.(event);
      },
      [formatDuration, onBlur, onChange],
    );

    const displayValue = useMemo(
      () => formatDuration(value ?? null),
      [formatDuration, value],
    );

    return (
      <TextInput
        ref={ref}
        valid={!localValidationError}
        value={displayValue || ''}
        onBlur={handleBlur}
        onChange={handleChange}
        autoComplete="off"
        {...rest}
      />
    );
  },
);

export default DurationInput;
