import { Button } from '@noloco/components/src';
import { getText } from '../../../utils/lang';

const LANG_KEY = 'auth.twoFactorAuth.backupCodes';

export const GenerateOTPBackupCodes = ({
  generateBackupCodes,
  buttonText,
}: {
  generateBackupCodes: any;
  buttonText: string;
}) => (
  <>
    <h2 className="mb-4 text-lg font-semibold text-gray-900">
      {getText(LANG_KEY, 'title')}
    </h2>
    <p className="mb-2 text-gray-700">{getText(LANG_KEY, 'description')}</p>
    <ul className="mb-4 list-inside list-disc text-gray-700">
      <li>
        <strong>{getText(LANG_KEY, 'keepPrivate')}</strong>
        {getText(LANG_KEY, 'keepPrivateDescription')}
      </li>
      <li>
        <strong>{getText(LANG_KEY, 'store')}</strong>
        {getText(LANG_KEY, 'storeDescription')}
      </li>
    </ul>
    <Button className="mt-6" onClick={generateBackupCodes}>
      {buttonText}
    </Button>
  </>
);
