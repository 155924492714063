import createReactComponent from '../createReactComponent';
export default createReactComponent('moon', 'IconMoon', [
  [
    'path',
    {
      d: 'M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z',
      key: 'svg-0',
    },
  ],
]);
