import React, { useState } from 'react';
import { FormField, Loader } from '@noloco/components/src';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '../../../utils/lang';

export const BackupCodeInput = ({
  handleVerifyOTPBackupCode,
  loading,
}: {
  handleVerifyOTPBackupCode: (backupCode: string) => void;
  loading: boolean;
}) => {
  const [backupCode, setBackupCode] = useState<string>('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (backupCode.trim()) {
      handleVerifyOTPBackupCode(backupCode.trim());
      setBackupCode('');
    }
  };

  return (
    <div className="w-full">
      {loading ? (
        <Loader size={MD} />
      ) : (
        <form onSubmit={handleSubmit} className="w-full space-y-4">
          <FormField
            aria-label="backup code"
            name="backup code"
            type="text"
            placeholder=""
            onChange={({ target: { value } }: any) => setBackupCode(value)}
            required
            value={backupCode}
            surface={LIGHT}
            label="Enter your backup code"
          />
          <div className="w-full">
            <SubmitButton type="submit" disabed={!backupCode}>
              {getText('auth.twoFactorAuth.submit')}
            </SubmitButton>
          </div>
        </form>
      )}
    </div>
  );
};
