import React, { memo, useMemo } from 'react';
import { IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.dataSources.connect.layout';

interface Props {
  builtPages: DataType[];
  inProgressPages: DataType[];
  loading: boolean;
  skippedPages: DataType[];
  surface: Surface;
}

const DataSourceImportBuildLayouts = memo(
  ({
    builtPages,
    inProgressPages,
    loading,
    skippedPages,
    surface = DARK,
  }: Props) => {
    const isBuilt = useMemo(() => {
      if (builtPages) {
        return builtPages.reduce(
          (acc: Record<number, true>, page: DataType) => {
            acc[page.id] = true;

            return acc;
          },
          {},
        );
      }

      return {};
    }, [builtPages]);

    return (
      <div
        className={classNames(
          'flex w-full items-center rounded-lg border p-3',
          {
            'border-brand-light text-white': surface !== LIGHT,
            'border-gray-200 bg-white text-gray-700': surface === LIGHT,
          },
        )}
      >
        <div className="h-full">
          <div className="my-2 flex w-16 flex-shrink-0 justify-center">
            {loading ? (
              <Loader
                className={surface === LIGHT ? 'text-gray-700' : 'text-white'}
              />
            ) : (
              <IconCircleCheck className="text-teal-500" size={40} />
            )}
          </div>
        </div>
        <div className="ml-3 flex flex-col">
          <span className="text-lg font-medium tracking-wider">
            {getText(LANG_KEY, 'title')}
          </span>
          <span className="font-gray-200 mt-1 text-sm">
            {getText(LANG_KEY, 'subtitle')}
          </span>
          {inProgressPages?.length > 1 && (
            <ul className="mb-8 mt-2 space-y-2">
              {inProgressPages.map((dataType: DataType) => (
                <li className="flex space-x-2" key={dataType.id}>
                  <div className="my-auto flex">
                    {isBuilt[dataType.id] ? (
                      <IconCircleCheck className="text-teal-500" size={16} />
                    ) : (
                      <Loader
                        className={
                          surface === LIGHT ? 'text-gray-700' : 'text-white'
                        }
                        size={XS}
                      />
                    )}
                  </div>
                  <span className="flex text-base">{dataType.display}</span>
                </li>
              ))}
            </ul>
          )}
          {skippedPages?.length > 0 && (
            <span className="font-gray-200 mt-2 text-sm">
              {getText(LANG_KEY, 'skipped')}
            </span>
          )}
        </div>
      </div>
    );
  },
);

export default DataSourceImportBuildLayouts;
