import { useCallback, useMemo } from 'react';
import { omit } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import { TEXT } from '../../constants/dataTypes';
import { DataType } from '../../models/DataTypes';
import { Action, NavigateAction, ScanActionType } from '../../models/Element';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { scopeSelector } from '../../selectors/dataSelectors';
import { getFieldFromDependency } from '../fields';
import getActionButtonFormFieldConfig from '../getActionButtonFormFieldConfig';
import { getText } from '../lang';
import { transformColumnarScope } from '../scope';
import useActionWithHiddenInputsMutation from './useActionWithHiddenInputsMutation';
import useActionWithHiddenInputsQuery from './useActionWithHiddenInputsQuery';
import useActionsWithHiddenInputs from './useActionsWithHiddenInputs';
import { useGraphQlErrorAlert } from './useAlerts';
import useAutoFormVariables from './useAutoFormVariables';
import useNavigateAction from './useNavigateAction';

const useOneClickActionMutations = (
  action: Action,
  dataType: DataType,
  project: Project,
  record: BaseRecord | undefined,
  onNext: () => void,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const networkErrorAlert = useGraphQlErrorAlert();

  const createMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    CREATE,
    project,
    record,
  );

  const updateMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    UPDATE,
    project,
    record,
  );

  const getRecordQuery = useActionWithHiddenInputsQuery(
    action,
    dataType,
    project,
  );

  const scope = useSelector(scopeSelector);

  const onNavigate = useNavigateAction();

  const { onCreate, onUpdate } = useActionsWithHiddenInputs(
    createMutation,
    updateMutation,
    onNext,
  );

  const { actionDataType } = useMemo(
    () =>
      getActionButtonFormFieldConfig(
        action.field!,
        dataType,
        project.dataTypes,
      ),
    [dataType, action, project.dataTypes],
  );

  const dep = getFieldFromDependency(
    action.barcodeField?.path.split('.') as string[],
    actionDataType,
    project.dataTypes,
  )!;

  // @ts-expect-error TS(2554): Expected 8-11 arguments, but got 4.
  const { getQueryVariables } = useAutoFormVariables(
    project,
    actionDataType,
    [{ config: { name: dep?.field.name }, field: dep?.field }],
    action.scanActionType === ScanActionType.CREATE ? CREATE : UPDATE,
  );

  const getRecordScope = useCallback(
    (record: any) => {
      const recordWithColumnar = transformColumnarScope(
        record,
        dataType,
        project.dataTypes,
      );
      const pageId = action.navigate?.page?.[0];

      return {
        ...scope,
        [`${pageId}:VIEW`]: recordWithColumnar,
        ...recordWithColumnar,
      };
    },
    [dataType, project.dataTypes, action.navigate?.page, scope],
  );

  const onScan = useCallback(
    async (barcodeValue) => {
      const barcodeFieldValue =
        dep.field.type === TEXT ? barcodeValue : Number(barcodeValue);
      const { variables } = getQueryVariables(
        { [dep.field.name]: barcodeFieldValue },
        null,
        dep.field,
      );

      const onAction =
        action.scanActionType === ScanActionType.CREATE ? onCreate : onUpdate;

      if (action.scanActionType === ScanActionType.NAVIGATE) {
        const record = await getRecordQuery({ value: barcodeFieldValue });

        try {
          onNavigate(
            action as NavigateAction,
            {
              ...getRecordScope(record),
            },
            onNext,
          );

          return { success: true, data: record };
        } catch (e) {
          console.error('Error running one-click create action', e);
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);

          return { success: false, error: e };
        }
      }

      return onAction(action, setIsLoading, omit(['id'], variables));
    },
    [
      dep?.field,
      getQueryVariables,
      action,
      onCreate,
      onUpdate,
      setIsLoading,
      getRecordQuery,
      onNavigate,
      getRecordScope,
      onNext,
      networkErrorAlert,
    ],
  );

  return { onCreate, onUpdate, onScan };
};

export default useOneClickActionMutations;
