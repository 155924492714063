import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconArrowBackUp,
  IconBrandGoogleDrive,
  IconChevronRight,
  IconCloudUpload,
  IconDots,
  IconFileUpload,
  IconFolder,
  IconFolderPlus,
  IconPencil,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import first from 'lodash/first';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import shortId from 'shortid';
import SimpleBar from 'simplebar-react';
import {
  AutoSizedTextInput,
  Button,
  Loader,
  Popover,
  TextInput,
  Tooltip,
  getColorShade,
} from '@noloco/components';
import Avatar from '../../components/Avatar';
import InternalLayoutWrapper from '../../components/InternalLayoutWrapper';
import BaseDropzone from '../../components/dropzone/BaseDropzone';
import { COMPANY } from '../../constants/builtInDataTypes';
import { DATE_SHORT } from '../../constants/dateFormatOptions';
import { ACCEPTED_MIMETYPES } from '../../constants/mimetypes';
import { ASC } from '../../constants/orderByDirections';
import { MANY_TO_ONE } from '../../constants/relationships';
import { ENTER } from '../../constants/shortcuts';
import imagePlaceholder from '../../img/image-placeholder.png';
import { getPreviewableFieldsQueryObject } from '../../queries/data';
import {
  CREATE_SHARED_FILE_MUTATION,
  DELETE_SHARED_FILE_MUTATION,
  SHARED_FILE_COLLECTION_QUERY,
  SHARED_FILE_QUERY,
  UPDATE_SHARED_FILE_MUTATION,
  getCollectionDataQueryString,
} from '../../queries/project';
import {
  addDataItemToCollectionCache,
  removeDataItemFromCollectionCache,
} from '../../utils/apolloCache';
import { useGraphQlErrorAlert } from '../../utils/hooks/useAlerts';
import useCacheQuery from '../../utils/hooks/useCacheQuery';
import useReadFileInputAttachments from '../../utils/hooks/useReadFileInputAttachments';
import useRouter from '../../utils/hooks/useRouter';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { isInternal } from '../../utils/user';
import Title from './Title';
import RelatedCellItem from './collections/RelatedCellItem';
import FilePreviewModal from './forms/FilePreviewModal';
import AttachmentIcon from './messaging/AttachmentIcon';

const GOOGLE_DRIVE_SOURCE = 'GOOGLE_DRIVE';

const sortFiles = (fileA: any, fileB: any) => {
  if (fileB.file && !fileA.file) {
    return -1;
  }

  if (fileA.file && !fileB.file) {
    return 1;
  }

  if (!fileB.file && !fileA.file) {
    return fileB.name.toLowerCase() < fileA.name.toLowerCase() ? 1 : -1;
  }

  if (fileB.file.name.toLowerCase() === fileA.file.name.toLowerCase()) {
    return fileB.id - fileA.id;
  }

  return fileB.file.name.toLowerCase() < fileA.file.name.toLowerCase() ? 1 : -1;
};

const filterCompanies = (searchText: any) => (company: any) =>
  !searchText || !searchText.trim()
    ? true
    : company.name &&
      company.name.toLowerCase().includes(searchText.toLowerCase().trim());

interface FileSharingProps {}

// @ts-expect-error TS(2339): Property 'project' does not exist on type 'FileSha... Remove this comment to see the full error message
const FileSharing = ({ project, theme }: FileSharingProps) => {
  const user = useScopeUser();

  const errorAlert = useGraphQlErrorAlert();
  const {
    mergeParams,
    query: { folderId },
    pushQueryParams,
  } = useRouter();
  const secondaryColor = theme.brandColors.secondary;
  const fileInput = useRef();
  const [uploadingFileIds, setUploadingFileIds] = useState([]);
  const [highlightedFile, setHighlightedFile] = useState(null);
  const [searchText, setSearchText] = useState();
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [renamingFile, setRenamingFile] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(null);

  const context = {
    projectQuery: true,
    projectName: project.name,
  };

  const companyWithRelations = project.dataTypes.getByName(COMPANY);
  const userCompanyId = isInternal(user)
    ? undefined
    : get(user, ['company', 'id']);
  const [companyId, setCompanyId] = useState(userCompanyId);

  const changeCompany = useCallback(
    (nextCompanyId: any) => {
      setCompanyId(nextCompanyId);
      pushQueryParams({ folderId: undefined });
    },
    [pushQueryParams],
  );

  const companyFields = useMemo(() => {
    const baseFields = getPreviewableFieldsQueryObject(
      companyWithRelations.fields,
    );
    baseFields.usersCollection = {
      edges: {
        node: {
          id: true,
          uuid: true,
          firstName: true,
          lastName: true,
          profilePicture: {
            id: true,
            url: true,
          },
        },
      },
    };

    return baseFields;
  }, [companyWithRelations]);

  const filesQuery = gql`
    ${SHARED_FILE_COLLECTION_QUERY}
  `;
  const folderQuery = gql`
    ${SHARED_FILE_QUERY}
  `;
  const [createSharedFile, { client: apolloClient }] = useMutation(
    gql`
      ${CREATE_SHARED_FILE_MUTATION},
    `,
    {
      context,
    },
  );

  const [updateSharedFile] = useMutation(
    gql`
      ${UPDATE_SHARED_FILE_MUTATION},
    `,
    {
      context,
    },
  );

  const [deleteSharedFile] = useMutation(
    gql`
      ${DELETE_SHARED_FILE_MUTATION},
    `,
    {
      context,
    },
  );

  const queryVariables = useMemo(
    () => ({
      companyId,
      folderId: folderId,
      after: null,
    }),
    [companyId, folderId],
  );

  const {
    data: filesData,
    loading,
    fetchMore,
  } = useCacheQuery(filesQuery, {
    variables: queryVariables,
    skip: !companyId,
    context,
  });
  const { data: folderData } = useCacheQuery(folderQuery, {
    variables: {
      folderId: folderId,
    },
    skip: !folderId,
    context,
  });
  const companiesQuery = useMemo(
    () =>
      getCollectionDataQueryString(companyWithRelations.name, {
        edges: {
          node: companyFields,
        },
        __args: { orderBy: { field: 'name', direction: ASC } },
      }),
    [companyFields, companyWithRelations],
  );
  const { data: companyData } = useCacheQuery(
    gql`
      ${companiesQuery}
    `,
    { context, skip: !isInternal(user) },
  );

  const companies = useMemo(
    () =>
      get(
        companyData,
        [`${companyWithRelations.name}Collection`, 'edges'],
        [],
      ).map((edge: any) => edge.node),
    [companyData, companyWithRelations.name],
  );

  const filteredCompanies = useMemo(
    () => companies.filter(filterCompanies(searchText)),
    [companies, searchText],
  );

  const selectedFolder = get(folderData, 'sharedFile');

  useEffect(() => {
    if (!companyId && companies.length > 0) {
      setCompanyId(companies[0].id);
    }
  }, [companyId, companies]);
  const selectedCompany = companies.find((c: any) => c.id === companyId);
  const selectedCompanyUsers =
    selectedCompany &&
    selectedCompany.usersCollection.edges.map((edge: any) => edge.node);

  const uploadFile = useCallback(
    (name: any, file: any) => {
      const tempId = shortId.generate();
      // @ts-expect-error TS(2345): Argument of type '(currentIds: never[]) => string[... Remove this comment to see the full error message
      setUploadingFileIds((currentIds) => [...currentIds, tempId]);

      return createSharedFile({
        variables: {
          name,
          file,
          folderId: folderId,
          companyId,
        },
      })
        .then(({ data: newFileData }) => {
          if (newFileData.createSharedFile) {
            addDataItemToCollectionCache(
              newFileData,
              apolloClient,
              filesQuery,
              'sharedFile',
              {
                after: null,
                folderId: folderId,
                companyId,
              },
            );
          }

          return newFileData.createSharedFile;
        })
        .catch((error) => {
          errorAlert(getText('core.FILE_SHARING.errors.upload'), error);
        })
        .finally(() => {
          setUploadingFileIds((currentIds) =>
            currentIds.filter((id) => id !== tempId),
          );
        });
    },
    [
      apolloClient,
      companyId,
      createSharedFile,
      errorAlert,
      filesQuery,
      folderId,
    ],
  );

  const filesCollection = get(filesData, 'sharedFileCollection', {});
  const { edges: fileEdges = [], pageInfo = {} } = filesCollection;
  const files = useMemo(
    () => fileEdges.map((f: any) => f.node).sort(sortFiles),
    [fileEdges],
  );

  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: pageInfo.hasNextPage,
    onLoadMore: () => {
      fetchMore({
        variables: {
          companyId,
          folderId: folderId,
          after: pageInfo.endCursor,
        },
        // @ts-expect-error TS(2345): Argument of type '{ variables: { companyId: any; f... Remove this comment to see the full error message
        disabled: !pageInfo,
        updateQuery: (previousResults, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResults;
          }

          return {
            sharedFileCollection: {
              ...fetchMoreResult.sharedFileCollection,
              edges: [
                ...previousResults.sharedFileCollection.edges,
                ...fetchMoreResult.sharedFileCollection.edges,
              ],
            },
            __typename: 'SharedFileConnection',
          };
        },
      });
    },
    disabled: false,
    rootMargin: '0px 0px 100px 0px',
  });

  const onCreateNewFolder = useCallback(
    async (event: any) => {
      event.preventDefault();

      if (newFolderName.trim().length > 0) {
        try {
          const newFolder = await uploadFile(newFolderName.trim(), null);
          setNewFolderName('');
          setShowNewFolder(false);

          if (newFolder) {
            setHighlightedFile(newFolder.id);
            const fileElement = document.querySelector(
              `.shared-file-${newFolder.id}`,
            );

            if (fileElement) {
              fileElement.scrollIntoView(true);
            }
          }
        } catch (error) {
          errorAlert(getText('core.FILE_SHARING.errors.createFolder'), error);
        }
      }
    },
    [errorAlert, newFolderName, uploadFile],
  );

  const onClickDelete = useCallback(
    (file: any) => {
      if (
        window.confirm(
          getText(
            { name: file.name },
            'core.FILE_SHARING.confirmDelete',
            file.file ? 'file' : 'folder',
          ),
        )
      ) {
        deleteSharedFile({ variables: { id: file.id } })
          .then(() => {
            removeDataItemFromCollectionCache(
              file.id,
              apolloClient,
              filesQuery,
              'sharedFile',
              queryVariables,
            );
          })
          .catch((error) => {
            errorAlert(getText('core.FILE_SHARING.errors.rename'), error);
          });
      }
    },
    [apolloClient, deleteSharedFile, errorAlert, filesQuery, queryVariables],
  );

  const handleSearchKeyDown = useCallback(
    (event: any) => {
      if (event.key === ENTER) {
        const firstCompany = first(filteredCompanies);

        if (firstCompany) {
          changeCompany((firstCompany as any).id);
        }
      }
    },
    [changeCompany, filteredCompanies],
  );

  const onClickRename = useCallback((file: any) => {
    setRenamingFile(file);
    setHighlightedFile(file.id);
  }, []);

  const onRenameFile = useCallback(
    (event: any) => {
      event.preventDefault();

      if (
        renamingFile &&
        (renamingFile as any).name &&
        (renamingFile as any).name.trim().length > 0
      ) {
        updateSharedFile({
          variables: {
            id: (renamingFile as any).id,
            name: (renamingFile as any).name,
          },
        })
          .catch((error) => {
            errorAlert(getText('core.FILE_SHARING.errors.rename'), error);
          })
          .finally(() => {
            setRenamingFile(null);
          });
      }
    },
    [errorAlert, renamingFile, updateSharedFile],
  );

  const onDropFiles = useCallback(
    (files: any) => {
      Promise.all(
        files.map((resolvedFile: any) =>
          uploadFile(resolvedFile[0].name, resolvedFile[0]),
        ),
      );
    },
    [uploadFile],
  );

  const readFileInputAttachments = useReadFileInputAttachments();
  const onSelectFiles = useCallback(
    ({ target: { files } }: any) => {
      readFileInputAttachments(files).then((resolvedFiles) =>
        Promise.all(
          resolvedFiles.map((resolvedFile) =>
            uploadFile((resolvedFile as any)[0].name, (resolvedFile as any)[0]),
          ),
        ),
      );
    },
    [readFileInputAttachments, uploadFile],
  );

  const {
    query: { _fileId },
  } = useRouter();

  const filteredFiles = useMemo(
    () =>
      files
        .filter((file: any) => file.file && file.source !== GOOGLE_DRIVE_SOURCE)
        .map((file: any) => ({
          ...file,
          fileType: file.file.fileType,
          mimetype: file.file.mimetype,
          url: file.file.url,
        })),
    [files],
  );

  const onClose = useCallback(() => {
    setPreviewIndex(null);
  }, []);

  const openFile = useCallback((fileId: any, files: any) => {
    const index = files.findIndex((file: any) => file.id === fileId);
    setPreviewIndex(index);
  }, []);

  return (
    <Switch>
      <Route>
        {/* @ts-expect-error TS(2322): Type '{ children: Element[]; innerClassName: strin... Remove this comment to see the full error message */}
        <InternalLayoutWrapper
          innerClassName="overflow-y-hidden"
          sidebarContent={
            <>
              <div className="sticky top-0 border-b bg-white p-3">
                <TextInput
                  border={[true, 'gray-200']}
                  placeholder={getText('core.MESSAGING.search.placeholder')}
                  onChange={({ target: { value } }: any) =>
                    setSearchText(value)
                  }
                  onKeyDown={handleSearchKeyDown}
                  value={searchText}
                />
              </div>
              {filteredCompanies.map((conversation: any) => (
                <div
                  className={classNames(
                    'flex cursor-pointer items-center border-b border-gray-100 px-3 py-3 hover:bg-gray-100',
                  )}
                  key={conversation.id}
                  onClick={() => changeCompany(conversation.id)}
                >
                  <RelatedCellItem
                    className="text-base"
                    innerClassName="ml-4"
                    dataTypes={project.dataTypes}
                    field={{
                      type: companyWithRelations.name,
                      relationship: MANY_TO_ONE,
                    }}
                    single={true}
                    imagePlaceholder={imagePlaceholder}
                    size={14}
                    value={conversation}
                  />
                </div>
              ))}
            </>
          }
          headerContent={
            selectedCompany && (
              <RelatedCellItem
                className="text-xl font-medium"
                innerClassName="ml-4"
                dataTypes={project.dataTypes}
                field={{
                  type: companyWithRelations.name,
                  relationship: MANY_TO_ONE,
                }}
                imagePlaceholder={imagePlaceholder}
                size={14}
                single={true}
                value={selectedCompany}
              >
                <div className="flex flex-wrap text-sm text-gray-800">
                  {selectedCompanyUsers.map((companyUser: any, index: any) => (
                    <div
                      className="my-1 mr-2 flex items-center"
                      key={companyUser.id}
                    >
                      <Avatar
                        className="mr-2 text-gray-100"
                        initialsSize="xs"
                        size={6}
                        user={companyUser}
                      />
                      <span className="whitespace-nowrap">
                        {companyUser.firstName} {companyUser.lastName}
                      </span>
                      {index !== selectedCompanyUsers.length - 1 && (
                        <span>,</span>
                      )}
                    </div>
                  ))}
                </div>
              </RelatedCellItem>
            )
          }
        >
          <input
            // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
            ref={fileInput}
            accept={ACCEPTED_MIMETYPES.join(',')}
            onChange={onSelectFiles}
            type="file"
            multiple={true}
            id="file-upload"
            className="invisible absolute h-0 w-0"
          />
          <div className="bg-white p-4">
            <Title
              title={{
                value: (
                  <div className="flex items-center">
                    <span>{getText('core.FILE_SHARING.title')}</span>
                    {selectedFolder && (
                      <>
                        <IconChevronRight
                          className="mx-3 opacity-50"
                          size={20}
                        />
                        <span>{selectedFolder.name}</span>
                      </>
                    )}
                  </div>
                ),
              }}
              subtitle={{ hidden: true }}
            >
              <Popover
                placement="bottom-end"
                trigger="click"
                className="overflow-hidden bg-white"
                p={0}
                rounded="lg"
                shadow="lg"
                showArrow={false}
                closeOnClick={true}
                closeOnOutsideClick={true}
                content={
                  <div className="flex w-56 flex-col text-left">
                    <button
                      onClick={() => {
                        if (fileInput.current) {
                          (fileInput.current as any).click();
                        }
                      }}
                      className="flex items-center border-b border-gray-200 px-6 py-4 text-left hover:bg-gray-100 disabled:opacity-50"
                    >
                      <IconFileUpload size={18} className="mr-2 opacity-75" />
                      <span>{getText('core.FILE_SHARING.new.upload')}</span>
                    </button>
                    <button
                      onClick={() => setShowNewFolder(true)}
                      className="flex items-center border-b border-gray-200 px-6 py-4 text-left hover:bg-gray-100 disabled:opacity-50"
                      disabled={
                        selectedFolder &&
                        selectedFolder.source === GOOGLE_DRIVE_SOURCE
                      }
                    >
                      <IconFolderPlus size={18} className="mr-2 opacity-75" />
                      <span>{getText('core.FILE_SHARING.new.folder')}</span>
                    </button>
                  </div>
                }
              >
                <Button>
                  <IconPlus size={14} />
                </Button>
              </Popover>
            </Title>
          </div>
          <div
            className={classNames(
              'flex w-full items-center border-b bg-white px-3 py-3 text-sm font-medium text-gray-500',
            )}
          >
            <div className="flex w-1/2 items-center">
              <span className="truncate">
                {getText('core.FILE_SHARING.headers.name')}
              </span>
            </div>
            <div className="flex w-1/2 items-center">
              <div className="flex w-1/2 items-center">
                <span className="truncate">
                  {getText('core.FILE_SHARING.headers.creator')}
                </span>
              </div>
              <div className="flex w-1/2 items-center justify-end">
                <span className="mx-4 truncate">
                  {getText('core.FILE_SHARING.headers.created')}
                </span>
                <div className="h-1 w-16" />
              </div>
            </div>
          </div>
          <BaseDropzone
            acceptedMimetypes={ACCEPTED_MIMETYPES}
            disabled={
              false &&
              selectedFolder &&
              selectedFolder.source === GOOGLE_DRIVE_SOURCE
            }
            className="divide-y-gray-300 flex flex-grow flex-col divide-y overflow-y-hidden"
            onChange={onDropFiles}
            maxFiles={50}
            noClick={true}
          >
            {({ isDragActive, isDragReject }: any) => (
              <div className="relative flex w-full flex-grow overflow-y-hidden">
                {isDragActive && (
                  <div
                    className={classNames(
                      'absolute bottom-0 left-0 right-0 z-20 flex w-full flex-col items-center justify-center p-8',
                    )}
                  >
                    {!isDragReject && (
                      <Box
                        text={getColorShade(secondaryColor, 500)}
                        className="mx-auto mb-8 animate-bounce"
                      >
                        <IconCloudUpload size={48} />
                      </Box>
                    )}
                    <div
                      className={classNames(
                        'mx-auto flex flex-col items-center justify-center rounded-lg px-16 py-8 text-center text-gray-800 shadow',
                        {
                          'bg-white': !isDragReject,
                          'bg-red-200': isDragReject,
                        },
                      )}
                    >
                      <span className="font-medium">
                        {getText(
                          'core.FILE_SHARING.drop',
                          isDragReject ? 'reject' : 'title',
                        )}
                      </span>
                      {!isDragReject && (
                        <span className="text-sm text-gray-600">
                          {getText('core.FILE_SHARING.drop.subtitle')}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {/* @ts-expect-error TS2786: 'SimpleBar' cannot be used as a JSX component. */}
                <SimpleBar className="flex flex-grow overflow-y-auto overflow-x-hidden">
                  <div className="divide-y-gray-300 flex flex-col divide-y">
                    {showNewFolder && (
                      <form
                        className={classNames(
                          'flex w-full items-center bg-blue-100 px-3 py-3',
                        )}
                        onSubmit={onCreateNewFolder}
                      >
                        <div className="flex w-1/2 items-center">
                          <IconFolder size={28} />
                          <span className="ml-4 truncate text-base text-gray-800">
                            <AutoSizedTextInput
                              autoFocus={true}
                              placeholder={getText(
                                'core.FILE_SHARING.new.folder',
                              )}
                              value={newFolderName}
                              onChange={({ target: { value } }: any) =>
                                setNewFolderName(value)
                              }
                            />
                          </span>
                        </div>
                        <div className="flex w-1/2 items-center justify-end">
                          <Button
                            className="mr-2"
                            type="outline"
                            onClick={() => setShowNewFolder(false)}
                          >
                            {getText('core.FILE_SHARING.new.cancel')}
                          </Button>
                          <Button submitFormOnClick={true}>
                            {getText('core.FILE_SHARING.new.save')}
                          </Button>
                        </div>
                      </form>
                    )}
                    {selectedFolder && (
                      <Link
                        className={classNames(
                          'flex w-full items-center bg-gray-100 px-3 py-3 text-base hover:bg-gray-200',
                        )}
                        to={mergeParams({
                          folderId: selectedFolder.parent
                            ? selectedFolder.parent.source ===
                              GOOGLE_DRIVE_SOURCE
                              ? selectedFolder.parent.uuid
                              : selectedFolder.parent.id
                            : null,
                        })}
                      >
                        <div className="flex w-1/2 items-center">
                          <IconArrowBackUp size={28} />
                          <span className="ml-4 truncate text-base text-gray-800">
                            {selectedFolder.parent
                              ? selectedFolder.parent.name
                              : getText('core.FILE_SHARING.root')}
                          </span>
                        </div>
                      </Link>
                    )}
                    {files.map((file: any, index: any) => {
                      const isOwnerOrInternal =
                        isInternal(user) ||
                        (file.creator && file.creator.id === user.id);
                      const Row = file.file ? 'a' : Link;
                      const rowProps = file.file
                        ? file.source === GOOGLE_DRIVE_SOURCE
                          ? {
                              href: file.file.url,
                              target: '_blank',
                              rel: 'noreferrer noopener',
                            }
                          : null
                        : {
                            to: mergeParams({
                              folderId:
                                file.source === GOOGLE_DRIVE_SOURCE
                                  ? file.uuid
                                  : file.id,
                            }),
                          };

                      if (
                        renamingFile &&
                        (renamingFile as any).id === file.id
                      ) {
                        return (
                          <form
                            className={classNames(
                              'flex w-full items-center bg-blue-100 px-3 py-3 text-base',
                            )}
                            onSubmit={onRenameFile}
                            key={`${file.id}-${index}`}
                          >
                            <div className="flex w-1/2 items-center">
                              {file.file ? (
                                <AttachmentIcon
                                  mimetype={file.file.mimetype}
                                  size={28}
                                />
                              ) : (
                                <IconFolder size={28} />
                              )}
                              <span className="ml-4 truncate text-base text-gray-800">
                                <AutoSizedTextInput
                                  autoFocus={true}
                                  placeholder={getText(
                                    'core.FILE_SHARING.placeholder',
                                  )}
                                  value={(renamingFile as any).name}
                                  onChange={({ target: { value } }: any) =>
                                    setRenamingFile({
                                      ...file,
                                      name: value,
                                    })
                                  }
                                />
                              </span>
                            </div>
                            <div className="flex w-1/2 items-center justify-end">
                              <Button
                                className="mr-2"
                                type="outline"
                                // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
                                onClick={() => setRenamingFile(false)}
                              >
                                {getText('core.FILE_SHARING.new.cancel')}
                              </Button>
                              <Button submitFormOnClick={true}>
                                {getText('core.FILE_SHARING.new.save')}
                              </Button>
                            </div>
                          </form>
                        );
                      }

                      if (previewIndex !== null && previewIndex >= 0) {
                        return (
                          <FilePreviewModal
                            files={filteredFiles}
                            initialIndex={previewIndex}
                            onClose={onClose}
                            queryFileId={_fileId}
                          />
                        );
                      }

                      return (
                        // @ts-expect-error TS(2322): Type '{ children: Element[]; key: string; classNam... Remove this comment to see the full error message
                        <Row
                          className={classNames(
                            'group flex w-full items-center px-3 py-3 hover:bg-gray-100',
                            {
                              'bg-blue-100 hover:bg-blue-200':
                                file.id === highlightedFile,
                            },
                            `shared-file-${file.id}`,
                          )}
                          onClick={() => {
                            if (highlightedFile) {
                              setHighlightedFile(null);
                            }

                            if (
                              file.file &&
                              file.source !== GOOGLE_DRIVE_SOURCE
                            ) {
                              openFile(file.id, filteredFiles);
                            }
                          }}
                          {...rowProps}
                          key={`${file.id}-${index}`}
                        >
                          <div className="flex w-1/2 items-center">
                            {file.file && (
                              <>
                                <div className="block w-10">
                                  <AttachmentIcon
                                    mimetype={file.file.mimetype}
                                    size={28}
                                  />
                                </div>
                                <span className="mx-4 truncate text-sm text-gray-800">
                                  {file.name}
                                </span>
                              </>
                            )}
                            {!file.file && (
                              <>
                                <div className="block w-10">
                                  <IconFolder size={28} />
                                </div>
                                <span className="mx-4 truncate text-base text-gray-800">
                                  {file.name}
                                </span>
                              </>
                            )}
                            {file.source === GOOGLE_DRIVE_SOURCE && (
                              <Tooltip
                                content={getText(
                                  'core.FILE_SHARING.googleDriveFile',
                                )}
                              >
                                <div className="ml-auto mr-4 pl-2 opacity-50">
                                  <IconBrandGoogleDrive size={18} />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                          <div className="flex w-1/2 items-center">
                            <div className="flex w-1/2 items-center">
                              {file.creator && (
                                <>
                                  <Avatar size={5} user={file.creator} />
                                  <span className="ml-4 text-sm font-medium">
                                    {file.creator.firstName}{' '}
                                    {file.creator.lastName}
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="flex w-1/2 items-center justify-end">
                              <span className="mx-4 mb-px text-sm text-gray-600">
                                {DateTime.fromISO(
                                  file.createdAt,
                                  // @ts-expect-error TS(2559): Type '"D"' has no properties in common with type '... Remove this comment to see the full error message
                                ).toLocaleString(DATE_SHORT)}
                              </span>
                              {isOwnerOrInternal && (
                                <Popover
                                  closeOnOutsideClick={true}
                                  placement="bottom-end"
                                  className="overflow-hidden bg-white"
                                  p={0}
                                  disabled={file.source === GOOGLE_DRIVE_SOURCE}
                                  rounded="lg"
                                  shadow="lg"
                                  content={
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      className="flex w-56 flex-col text-left"
                                    >
                                      {isOwnerOrInternal && (
                                        <button
                                          onClick={() => onClickRename(file)}
                                          className="flex items-center border-b border-gray-100 px-6 py-4 text-left hover:bg-gray-100"
                                        >
                                          <IconPencil
                                            size={16}
                                            className="mr-4 opacity-75"
                                          />
                                          <span>
                                            {getText(
                                              'core.FILE_SHARING.rename',
                                            )}
                                          </span>
                                        </button>
                                      )}
                                      {isOwnerOrInternal && (
                                        <button
                                          onClick={() => onClickDelete(file)}
                                          className="flex items-center px-6 py-4 text-left hover:bg-gray-100"
                                        >
                                          <IconTrash
                                            size={16}
                                            className="mr-4 opacity-75"
                                          />
                                          <span>
                                            {getText(
                                              'core.FILE_SHARING.delete',
                                            )}
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  }
                                >
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    className={classNames(
                                      'invisible flex w-16 items-center justify-center rounded-full p-2 text-gray-500 hover:bg-gray-100 focus:visible focus:bg-gray-100',
                                      {
                                        'group-hover:visible':
                                          file.source !== GOOGLE_DRIVE_SOURCE,
                                      },
                                    )}
                                  >
                                    <IconDots size={16} />
                                  </button>
                                </Popover>
                              )}
                              {!isOwnerOrInternal && (
                                <div className="h-1 w-16" />
                              )}
                            </div>
                          </div>
                        </Row>
                      );
                    })}
                  </div>
                  {((pageInfo && pageInfo.hasNextPage) || loading) && (
                    <div
                      className="flex w-full justify-center py-4"
                      ref={loaderRef}
                    >
                      <Loader size="sm" />
                    </div>
                  )}
                  {files.length === 0 && !loading && (
                    <div className="flex w-full flex-grow p-8 text-center">
                      <h2 className="w-full text-base font-medium">
                        {getText('core.FILE_SHARING.empty.title')}
                      </h2>
                    </div>
                  )}
                </SimpleBar>
                {uploadingFileIds.length > 0 && (
                  <div
                    className={classNames(
                      'absolute bottom-0 right-0 z-20 mb-8 mr-8 flex items-center justify-center rounded-lg border bg-white px-8 py-4 text-gray-800 shadow',
                    )}
                  >
                    <Loader size="sm" />
                    <span className="ml-4">
                      {getText('core.FILE_SHARING.uploading')}
                    </span>
                  </div>
                )}
              </div>
            )}
          </BaseDropzone>
        </InternalLayoutWrapper>
      </Route>
    </Switch>
  );
};

const FileSharingWithTheme = withTheme(FileSharing);

const FileSharingWrapper = (props: any) => {
  const user = useScopeUser();

  if (!user || !user.id) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader type="bars" />
      </div>
    );
  }

  return <FileSharingWithTheme {...props} />;
};

export default FileSharingWrapper;
