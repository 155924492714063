import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Color, Popover, getColorShade } from '@noloco/components';
import { UpdateProjectCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { DIVIDER, LINK } from '../../constants/elements';
import { MenuStyle, SIDE_MENU, TOP_MENU } from '../../constants/menuStyles';
import DraggableSidebarItem from '../../elements/DraggableSidebarItem';
import { ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import useCurrentSpace from '../../utils/hooks/useCurrentSpace';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useRouter from '../../utils/hooks/useRouter';
import { Page, replaceDoubleSlashes } from '../../utils/pages';
import { getPageTo } from '../../utils/urls';
import SidebarItem from '../SidebarItem';
import withVisibilityRules from '../canvas/withVisibilityRules';
import AddPageLine from './AddPageLine';
import SubNavItem from './SubNavItem';

const TOP_NAV_DROPDOWN_OFFSET = [0, 8];

const Wrapper = ({
  children,
  hasChildPages,
  isDarkModeEnabled,
  primaryColor,
  childPages,
  menuStyle,
}: {
  children: any;
  hasChildPages: boolean;
  isDarkModeEnabled: boolean;
  primaryColor: Color;
  childPages: null | JSX.Element;
  menuStyle: MenuStyle;
}) => {
  if (menuStyle === TOP_MENU && hasChildPages) {
    return (
      <Popover
        trigger="hover"
        content={
          menuStyle === TOP_MENU && (
            <div
              className={classNames(
                'flex flex-col py-2 text-xs',
                `bg-${getColorShade(
                  primaryColor,
                  isDarkModeEnabled ? 800 : 700,
                )}`,
              )}
            >
              {childPages}
            </div>
          )
        }
        border={0}
        offset={TOP_NAV_DROPDOWN_OFFSET}
        delayHide={150}
        p={0}
        showArrow={false}
      >
        <div className="flex">{children}</div>
      </Popover>
    );
  }

  return children;
};

interface NavItemProps {
  className?: string;
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  editingPage: string;
  editorMode: boolean;
  handleSetEditingPage: (newEditingPage: string) => void;
  index: number;
  menuStyle: MenuStyle;
  onEditSelectPage: (id: string, path: ElementPath) => void;
  page: Page;
  pages: Page[];
  pagesPath: ElementPath;
  portalPages: Page[];
  portalPath: ElementPath;
  primaryColor: string;
  project: Project;
  showExpandedNav: boolean;
  updateProject: UpdateProjectCallback;
  updateProperty: (path: ElementPath, value: any) => void;
}

const NavItem = ({
  className,
  debouncedUpdateProperty,
  editorMode,
  editingPage,
  index,
  handleSetEditingPage,
  menuStyle,
  onEditSelectPage,
  page,
  pages,
  pagesPath,
  project,
  primaryColor,
  portalPath,
  portalPages,
  showExpandedNav,
  updateProject,
  updateProperty,
}: NavItemProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const to = useMemo(
    () => getPageTo(['PORTAL', page.id], {}, pages, {}),
    [page.id, pages],
  );
  const { pathname } = useRouter();
  const [space] = useCurrentSpace();
  const splitPathname = pathname.split('/');
  const active = useMemo(
    () =>
      page.type !== LINK &&
      page.type !== DIVIDER &&
      (!to || to === '/'
        ? pathname === '/'
        : to
            .split('/')
            .every(
              (pathSegment, index) =>
                splitPathname.length - 1 >= index &&
                pathSegment === splitPathname[index],
            )),
    [page.type, pathname, splitPathname, to],
  );

  const SidebarItemVisibilityRules = useMemo(
    () =>
      editorMode && page.id === editingPage
        ? DraggableSidebarItem
        : withVisibilityRules(
            DraggableSidebarItem,
            editorMode,
            page.visibilityRules,
            !!space,
            space?.id,
          ),
    [editingPage, editorMode, page.id, page.visibilityRules, space],
  );

  const childPages = useMemo(
    () =>
      portalPages
        .map((childPage, idx) => ({
          childPage,
          originalChildIndex: idx,
        }))
        .filter(
          ({ childPage }: any) =>
            get(childPage, 'props.parentPage') === page.id &&
            !get(page, 'props.hide', null),
        ),
    [page, portalPages],
  );

  const subPages = useMemo(() => get(page, 'props.SubPages', []), [page]);
  const hasValidParentPage = useMemo(
    () =>
      get(page, 'props.parentPage') &&
      portalPages.find(
        (parentPage: any) =>
          get(parentPage, 'id') === get(page, 'props.parentPage'),
      ),
    [page, portalPages],
  );

  const shouldRenderNavItem = useMemo(
    () =>
      page &&
      !!page.id &&
      !!page.type &&
      (get(page, 'props') || page.type === DIVIDER) &&
      !get(page, 'props.dataType', null) &&
      !hasValidParentPage &&
      !get(page, 'props.hide', null) &&
      page.id !== 'PROFILE',
    [page, hasValidParentPage],
  );

  const elementPathSuffix = useMemo(
    () => (active && subPages.length > 0 ? ['props', 'SubPages'] : []),
    [active, subPages],
  );

  const elementPath = useMemo(
    () => [
      ...portalPath,
      ...(get(project, 'settings.flags.v2', false) ? [] : ['children']),
      index,
      ...(elementPathSuffix ? elementPathSuffix : []),
    ],
    [elementPathSuffix, index, portalPath, project],
  );

  const hasChildPages = childPages.length > 0 || subPages.length > 0;

  const childPageNavItems = useMemo(
    () =>
      hasChildPages ? (
        <>
          {(active || menuStyle === TOP_MENU) && (
            <>
              <div
                className={classNames('flex w-full flex-col sm:pl-6', {
                  'pl-6': showExpandedNav && menuStyle === SIDE_MENU,
                })}
              >
                {childPages.map(({ childPage, originalChildIndex }) => (
                  <>
                    {editorMode && (
                      <AddPageLine
                        page={childPage}
                        portalPages={portalPages}
                        primaryColor={primaryColor}
                        project={project}
                        parentPage={page}
                      />
                    )}
                    <SubNavItem
                      debouncedUpdateProperty={debouncedUpdateProperty}
                      editorMode={editorMode}
                      elementPath={elementPath}
                      editingPage={editingPage}
                      index={originalChildIndex}
                      handleSetEditingPage={handleSetEditingPage}
                      parentTo={to}
                      menuStyle={menuStyle}
                      primaryColor={primaryColor}
                      page={childPage}
                      pagesPath={pagesPath}
                      portalPath={portalPath}
                      portalPages={portalPages}
                      project={project}
                      onEditSelectPage={onEditSelectPage}
                      splitPathname={splitPathname}
                      showExpandedNav={showExpandedNav}
                      updateProject={updateProject}
                      updateProperty={updateProperty}
                      key={childPage.id}
                    />
                  </>
                ))}
              </div>
            </>
          )}
          {(active || menuStyle === TOP_MENU) && subPages.length > 0 && (
            <div
              className={classNames('flex w-full flex-col sm:pl-6', {
                'pl-6': showExpandedNav && menuStyle === SIDE_MENU,
              })}
            >
              {subPages.map((subPage: Page, subIndex: number) => {
                const SubSidebarItemVisibilityRules =
                  editorMode && subPage.id === editingPage
                    ? SidebarItem
                    : withVisibilityRules(
                        SidebarItem,
                        editorMode,
                        subPage.visibilityRules,
                      );

                const subTo = replaceDoubleSlashes(
                  `${to}/${subPage.props.routePath}`,
                );

                const subActive = pathname.startsWith(subTo);

                return (
                  <SubSidebarItemVisibilityRules
                    active={subActive}
                    allowSubPages={false}
                    debouncedUpdateProperty={debouncedUpdateProperty}
                    elementPathSuffix={[...elementPathSuffix, subIndex]}
                    editorMode={editorMode}
                    editingPage={editingPage}
                    hideIcon={true}
                    index={index}
                    isNavExpanded={showExpandedNav}
                    onEditSelectPage={onEditSelectPage}
                    page={subPage}
                    navId={subPage.id}
                    key={subPage.id}
                    portalPath={portalPath}
                    project={project}
                    setEditingPage={handleSetEditingPage}
                    to={subTo}
                    updateProperty={updateProperty}
                  />
                );
              })}
            </div>
          )}
        </>
      ) : null,
    [
      active,
      childPages,
      debouncedUpdateProperty,
      editingPage,
      editorMode,
      elementPath,
      elementPathSuffix,
      handleSetEditingPage,
      hasChildPages,
      index,
      menuStyle,
      onEditSelectPage,
      page,
      pagesPath,
      pathname,
      portalPages,
      portalPath,
      primaryColor,
      project,
      showExpandedNav,
      splitPathname,
      subPages,
      to,
      updateProject,
      updateProperty,
    ],
  );

  if (!shouldRenderNavItem) {
    return null;
  }

  return (
    <React.Fragment key={page.id}>
      <SidebarItemVisibilityRules
        className={className}
        draggable={editorMode}
        key={page.id}
        navId={page.id}
        page={page}
        path={[...pagesPath, index]}
        project={project}
        updateProject={updateProject}
      >
        <Wrapper
          hasChildPages={hasChildPages}
          menuStyle={menuStyle}
          childPages={childPageNavItems}
          primaryColor={primaryColor}
          isDarkModeEnabled={isDarkModeEnabled}
        >
          <SidebarItem
            className={classNames({ 'w-full': menuStyle === SIDE_MENU })}
            active={active}
            depth={0}
            debouncedUpdateProperty={debouncedUpdateProperty}
            editorMode={editorMode}
            editingPage={editingPage}
            elementPath={elementPath}
            hasChildPages={hasChildPages}
            index={index}
            menuStyle={menuStyle}
            onEditSelectPage={onEditSelectPage}
            isNavExpanded={showExpandedNav}
            page={page}
            primaryColor={primaryColor}
            key={page.id}
            portalPath={portalPath}
            project={project}
            setEditingPage={handleSetEditingPage}
            to={to}
            updateProperty={updateProperty}
          />
          {menuStyle === SIDE_MENU && hasChildPages && childPageNavItems}
        </Wrapper>
      </SidebarItemVisibilityRules>
    </React.Fragment>
  );
};

export default NavItem;
