import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Switch } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeLinkEditor from '@noloco/core/src/components/buildMode/BuildModeLinkEditor';
import IconEditor from '@noloco/core/src/components/editor/IconEditor';
import { WithDraggable } from '@noloco/core/src/components/withDnD';
import { OPTIONS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { LINK } from '@noloco/core/src/constants/draggableItemTypes';
import { QUICK_LINKS } from '@noloco/core/src/constants/elements';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath, ID } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import StringPropEditor from '../../canvas/StringPropEditor';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import DraggableListItem from './DraggableListItem';

const LANG_KEY = 'elements.LINK';

interface QuickLinkEditorProps {
  activeListItem: number | null;
  className?: string;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  draggable?: boolean;
  elementPath: ElementPath;
  index: number;
  isOver?: boolean;
  link: any;
  listOptions: {
    icon?: JSX.Element;
    label?: string;
    value: string | number;
  }[];
  onClone: (id: ID, newItemId: ID, tab?: boolean) => void;
  onRemove: (id: ID) => void;
  popoutOpen: boolean;
  project: Project;
  setActiveListItem: (activeListItem: number | null) => void;
  setPopoutOpen: (popoutOpen: boolean) => void;
  updateProperty: UpdatePropertyCallback;
}

export const getLinkTitle = (title: any, index: number) =>
  title
    .map((dataItem: any) =>
      dataItem.data ? `{ ${dataItem.data.path} }` : dataItem.text || '',
    )
    .join('') || `${getText({ index: index + 1 }, LANG_KEY, 'label')}`;

const QuickLinkEditor = forwardRef(
  (
    {
      activeListItem,
      className,
      dataType,
      debouncedUpdateProperty,
      draggable,
      elementPath,
      index,
      isOver,
      link,
      listOptions,
      onClone,
      onRemove,
      popoutOpen,
      project,
      setActiveListItem,
      setPopoutOpen,
      updateProperty,
    }: QuickLinkEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [editorTab, setEditorTab] = useEditorTabs(
      'record.quickLinks',
      OPTIONS,
    );

    const title = useMemo(() => getLinkTitle(link.title, index), [link, index]);

    return (
      <DraggableListItem
        activeListItem={activeListItem}
        canDelete={true}
        draggable={draggable}
        icon={link.icon}
        id={link.id}
        index={index}
        isOver={isOver}
        key={link.id}
        listOptions={listOptions}
        onClone={onClone}
        onRemove={onRemove}
        popoutOpen={popoutOpen}
        ref={ref}
        setActiveListItem={setActiveListItem}
        setPopoutOpen={setPopoutOpen}
        title={title}
      >
        <div className={classNames(className, 'flex flex-col')}>
          <div className="flex flex-col">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={QUICK_LINKS}
              setEditorTab={setEditorTab}
            />
            <hr className="w-full border-slate-700" />
            {editorTab === OPTIONS && (
              <div className="space-y-4 p-2">
                <BuildModeInput label={getText(LANG_KEY, 'title')}>
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
                    project={project}
                    onChange={(value: any) =>
                      debouncedUpdateProperty([index, 'title'], value)
                    }
                    elementPath={elementPath}
                    value={link.title}
                    includeSelf={true}
                  />
                </BuildModeInput>
                <BuildModeInput label={getText(LANG_KEY, 'description')}>
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
                    project={project}
                    onChange={(value: any) =>
                      debouncedUpdateProperty([index, 'description'], value)
                    }
                    elementPath={elementPath}
                    value={link.description}
                    includeSelf={true}
                    multiLine={true}
                  />
                </BuildModeInput>
                <BuildModeLinkEditor
                  elementProps={link.link || {}}
                  elementPath={elementPath}
                  project={project}
                  propDefinition={{ includeSelf: true }}
                  updateProperty={(propPath: any, newValue: any) =>
                    updateProperty([index, 'link', ...propPath], newValue)
                  }
                  debouncedUpdateProperty={(propPath: any, newValue: any) =>
                    debouncedUpdateProperty(
                      [index, 'link', ...propPath],
                      newValue,
                    )
                  }
                />
                <div className="flex items-center justify-between">
                  <BuildModeLabel>{getText(LANG_KEY, 'icon')}</BuildModeLabel>
                  <Switch
                    size={SM}
                    value={!get(link, 'icon.hidden', false)}
                    onChange={(value: boolean) =>
                      updateProperty([index, 'icon', 'hidden'], !value)
                    }
                  />
                </div>
                {!get(link, 'icon.hidden', false) && (
                  <IconEditor
                    updateProperty={(path: ElementPath, icon: string | null) =>
                      updateProperty([index, 'icon', ...path], icon)
                    }
                    elementProps={link.icon}
                    surface={DARK}
                    inline={false}
                  />
                )}
              </div>
            )}
            {editorTab === VISIBILITY && (
              <VisibilityRulesEditor
                dataType={dataType}
                elementPath={[...elementPath, 'quickLink']}
                onChange={(path: ElementPath, value: any) =>
                  updateProperty([index, 'visibilityRules', ...path], value)
                }
                project={project}
                visibilityRules={link.visibilityRules}
              />
            )}
          </div>
        </div>
      </DraggableListItem>
    );
  },
);

QuickLinkEditor.displayName = 'QuickLinkEditor';

export default WithDraggable(QuickLinkEditor, LINK);
