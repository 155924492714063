import React, { Suspense, lazy, useState } from 'react';
import classNames from 'classnames';
import Loader from '@noloco/components/src/components/loading/Loader';
import {
  AUDIO_MIMETYPES,
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from '../../../constants/mimetypes';
import AttachmentIcon from './AttachmentIcon';

const LazyFilePreviewModal = lazy(() => import('../forms/FilePreviewModal'));

const getPreview = (attachment: any, bg: any) => {
  if (AUDIO_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div
        className={classNames(
          bg,
          'm-1 flex max-w-full items-center rounded-lg p-4',
        )}
      >
        <AttachmentIcon
          mimetype={attachment.mimetype}
          size={24}
          className="mr-2 inline-block opacity-50"
        />
        <audio controls={true}>
          <source src={attachment.url} type={attachment.mimetype} />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (IMAGE_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div className={classNames(bg, 'm-1 w-10/12 rounded-lg p-2')}>
        <img
          src={attachment.url}
          alt={attachment.name}
          className="h-auto w-full rounded-lg"
        />
      </div>
    );
  }

  if (VIDEO_MIMETYPES.includes(attachment.mimetype)) {
    return (
      <div className={classNames(bg, 'm-1 w-10/12 rounded-lg p-2')}>
        <video
          src={attachment.url}
          className="h-auto w-full rounded-lg"
          controls={true}
        />
      </div>
    );
  }

  return (
    <div className={classNames(bg, 'm-1 max-w-full truncate rounded-lg p-4')}>
      <AttachmentIcon
        mimetype={attachment.mimetype}
        size={18}
        className="mr-2 inline-block opacity-50"
      />
      <span className="text-gray-700">{attachment.name}</span>
    </div>
  );
};

const AttachmentPreview = ({ attachment, bg, className }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={classNames('cursor-pointer', className)}
        onClick={() => setIsOpen(true)}
      >
        {getPreview(attachment, bg)}
      </div>
      {isOpen && (
        <Suspense fallback={<Loader />}>
          <LazyFilePreviewModal
            files={[attachment]}
            onClose={() => setIsOpen(false)}
          />
        </Suspense>
      )}
    </>
  );
};

AttachmentPreview.defaultProps = {
  bg: 'bg-gray-100',
};

export default AttachmentPreview;
