import { IconKeyboard } from '@tabler/icons-react';
import classNames from 'classnames';
import { Modal } from '@noloco/components/src/components/modal';
import { DARK } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  ALT,
  CMD,
  COMMA,
  ENTER,
  ESCAPE,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_BACKSPACE,
  KEY_BACK_SLASH,
  KEY_D,
  KEY_E,
  KEY_G,
  KEY_H,
  KEY_M,
  KEY_P,
  KEY_SLASH,
  KEY_SQUARE_BRACKETS_CLOSE,
  KEY_T,
  KEY_U,
  KEY_W,
  KEY_X,
  KEY_Z,
  PERIOD,
  SHIFT,
  ShortcutKeyType,
} from '@noloco/core/src/constants/shortcuts';
import ShortcutKeys from '@noloco/core/src/elements/sections/view/ShortcutKeys';
import useDarkModeSurface from '@noloco/core/src/utils/hooks/useDarkModeSurface';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';

interface KeyboardShortcutsModalProps {
  onClose: () => void;
}

const LANG_KEY = 'toolbar.help.keyboardShortcutsModal';

const shortcutHeading = [
  'basic',
  'editor',
  'calendar',
  'comments',
  'bulkActions',
];

const shortcuts: Record<
  string,
  {
    shortcuts: Record<string, { description: string; keys: ShortcutKeyType[] }>;
    title: string;
  }
> = {
  basic: {
    shortcuts: {
      buildModeTrigger: {
        description: 'shortcuts.basic.buildModeTrigger',
        keys: [CMD, KEY_E],
      },
      helpMenu: {
        description: 'shortcuts.basic.helpMenu',
        keys: [ALT, KEY_H],
      },
      keyboardShortcutsList: {
        description: 'shortcuts.basic.keyboardShortcutsList',
        keys: [CMD, KEY_SLASH],
      },
      openPublishModal: {
        description: 'shortcuts.basic.openPublishModal',
        keys: [ALT, KEY_P],
      },
      toggleDarkMode: {
        description: 'shortcuts.basic.toggleDarkMode',
        keys: [ALT, KEY_D],
      },
      toggleSidebar: {
        description: 'shortcuts.basic.toggleSidebar',
        keys: [CMD, KEY_BACK_SLASH],
      },
      viewAsAnotherUser: {
        description: 'shortcuts.basic.viewAsAnotherUser',
        keys: [CMD, SHIFT, KEY_U],
      },
    },
    title: 'shortcuts.basic.title',
  },
  editor: {
    shortcuts: {
      addNewSection: {
        description: 'shortcuts.editorShortcuts.addNewSection',
        keys: [CMD, KEY_SLASH],
      },
      cloneSection: {
        description: 'shortcuts.editorShortcuts.cloneSection',
        keys: [CMD, KEY_D],
      },
      closeRightEditor: {
        description: 'shortcuts.editorShortcuts.closeRightEditor',
        keys: [ESCAPE],
      },
      deleteSection: {
        description: 'shortcuts.editorShortcuts.deleteSection',
        keys: [CMD, KEY_BACKSPACE],
      },
      moveSectionDown: {
        description: 'shortcuts.editorShortcuts.moveSectionDown',
        keys: [CMD, SHIFT, KEY_ARROW_DOWN],
      },
      moveSectionUp: {
        description: 'shortcuts.editorShortcuts.moveSectionUp',
        keys: [CMD, SHIFT, KEY_ARROW_UP],
      },
      nextEditorTab: {
        description: 'shortcuts.editorShortcuts.nextEditorTab',
        keys: [CMD, SHIFT, PERIOD],
      },
      prevEditorTab: {
        description: 'shortcuts.editorShortcuts.prevEditorTab',
        keys: [CMD, SHIFT, COMMA],
      },
      redo: {
        description: 'shortcuts.editorShortcuts.redo',
        keys: [CMD, SHIFT, KEY_Z],
      },
      saveUpdatedPageName: {
        description: 'shortcuts.editorShortcuts.saveUpdatedPageName',
        keys: [CMD, ENTER],
      },
      undo: {
        description: 'shortcuts.editorShortcuts.undo',
        keys: [CMD, KEY_Z],
      },
    },
    title: 'shortcuts.editorShortcuts.title',
  },
  calendar: {
    shortcuts: {
      nextPeriod: {
        description: 'shortcuts.calendarShortcuts.nextPeriod',
        keys: [PERIOD],
      },
      previousPeriod: {
        description: 'shortcuts.calendarShortcuts.previousPeriod',
        keys: [COMMA],
      },
      goToToday: {
        description: 'shortcuts.calendarShortcuts.goToToday',
        keys: [KEY_T],
      },
      monthView: {
        description: 'shortcuts.calendarShortcuts.monthView',
        keys: [KEY_M],
      },
      weekView: {
        description: 'shortcuts.calendarShortcuts.weekView',
        keys: [KEY_W],
      },
      workWeekView: {
        description: 'shortcuts.calendarShortcuts.workWeekView',
        keys: [KEY_X],
      },
      dayView: {
        description: 'shortcuts.calendarShortcuts.dayView',
        keys: [KEY_D],
      },
      datePicker: {
        description: 'shortcuts.calendarShortcuts.datePicker',
        keys: [KEY_G],
      },
    },
    title: 'shortcuts.calendarShortcuts.title',
  },
  comments: {
    shortcuts: {
      toggleComments: {
        description: 'shortcuts.commentsShortcuts.toggleComments',
        keys: [CMD, KEY_SQUARE_BRACKETS_CLOSE],
      },
    },
    title: 'shortcuts.commentsShortcuts.title',
  },
  bulkActions: {
    shortcuts: {
      toggleUpdateAllRecords: {
        description: 'shortcuts.bulkActionsShortcuts.toggleUpdateAllRecords',
        keys: [CMD, KEY_U],
      },
    },
    title: 'shortcuts.bulkActionsShortcuts.title',
  },
};

const KeyboardShortcutsModal = ({ onClose }: KeyboardShortcutsModalProps) => {
  const surface = useDarkModeSurface();
  useOnKeyPress(ESCAPE, () => onClose(), {
    enabled: true,
  });

  return (
    <Modal
      cancelText={getText(LANG_KEY, 'close')}
      canConfirm={false}
      icon={<IconKeyboard size={24} />}
      onCancel={onClose}
      onClose={onClose}
      size={SM}
      title={getText(LANG_KEY, 'title')}
    >
      <div className="flex flex-col space-y-4 overflow-y-auto overflow-x-hidden">
        {shortcutHeading.map((heading) => (
          <div className="flex h-full flex-col space-y-2" key={heading}>
            <h3
              className={classNames(
                'text-lg font-semibold',
                surface === DARK ? 'text-slate-200' : 'text-slate-800',
              )}
            >
              {getText(LANG_KEY, 'shortcuts', heading, 'title')}
            </h3>
            {Object.entries(shortcuts[heading].shortcuts).map(
              ([key, value]) => (
                <div className="flex items-center justify-between" key={key}>
                  <p>{getText(LANG_KEY, 'shortcuts', heading, key)}</p>
                  <ShortcutKeys keys={value.keys} surface={surface} />
                </div>
              ),
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default KeyboardShortcutsModal;
