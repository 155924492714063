import React, { useCallback, useMemo } from 'react';
import { IconListCheck } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import Guide from '@noloco/ui/src/components/Guide';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import EditRelatedRecordsEditor from '@noloco/ui/src/components/editor/customerEditors/EditRelatedRecordsEditor';
import ExportButtonEditor from '@noloco/ui/src/components/editor/customerEditors/ExportButtonEditor';
import ImportButtonEditor from '@noloco/ui/src/components/editor/customerEditors/ImportButtonEditor';
import NewButtonEditor from '@noloco/ui/src/components/editor/customerEditors/NewButtonEditor';
import ViewCollectionFilterFieldsEditor from '@noloco/ui/src/components/editor/customerEditors/ViewCollectionFilterFieldsEditor';
import useEditorHint from '@noloco/ui/src/utils/hooks/useEditorHint';
import { ONE_CLICK } from '../../../constants/actionButtons';
import { NAVIGATE, SCAN_BARCODE } from '../../../constants/actionTypes';
import {
  VIEW_BUTTONS,
  VIEW_EMPTY_STATE,
  VIEW_FILTER_FIELDS,
} from '../../../constants/buildMode';
import { USER } from '../../../constants/builtInDataTypes';
import {
  CHARTS,
  LAYOUT_SUPPORTS_RECORD_COLORING,
  PIVOT_TABLE,
  SINGLE_RECORD,
} from '../../../constants/collectionLayouts';
import { COLLECTION, VIEW } from '../../../constants/elements';
import { BULK_ACTIONS } from '../../../constants/features';
import { FILTER_FIELDS } from '../../../constants/hints';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath, ScanActionType } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getFieldFromDependency } from '../../../utils/fields';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeFeatureLockedSwitchSection from '../BuildModeFeatureLockedSwitchSection';
import BuildModeImageEditor from '../BuildModeImageEditor';
import BuildModeInput from '../BuildModeInput';
import BuildModeLabel from '../BuildModeLabel';
import BuildModeSection from '../BuildModeSection';
import BuildModeSwitchSection from '../BuildModeSwitchSection';
import BuildModeActionButtonsEditor from '../actionButton/BuildModeActionButtonsEditor';
import BuildModeViewColorConditionsEditor from './BuildModeViewColorConditionsEditor';
import BuildModeViewFilterSettings from './BuildModeViewFilterSettings';
import BuildModeViewSortAndLimitSettings from './BuildModeViewSortAndLimitSettings';

interface BuildModeViewOptionsTabProps {
  dataType: DataType;
  debouncedUpdateProperty: (elementPath: ElementPath, value: any) => void;
  element: Page;
  elementPath: ElementPath;
  filterOptions?: any;
  isRecordView?: boolean;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
  updateProperty: (elementPath: ElementPath, value: any) => void;
}

const LANG_KEY = 'elements.VIEW';

const BuildModeViewOptionsTab = ({
  dataType: rootDataType,
  debouncedUpdateProperty,
  element,
  elementPath,
  filterOptions,
  isRecordView = true,
  project,
  section,
  sectionPropPath = [],
  updateProperty,
}: BuildModeViewOptionsTabProps) => {
  const collectionElement = useMemo(
    () => (section ?? element) as Page | Element,
    [section, element],
  );

  const collectionElementPath = useMemo(
    () => (section ? sectionPropPath : elementPath),
    [section, sectionPropPath, elementPath],
  );

  const {
    actionButtons = [],
    bulkActionsEnabled = false,
    dataList = {},
    emptyState,
    filters = [],
    layout,
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    search: { enabled: searchEnabled, placeholder: searchPlaceholder } = {},
    hideNewButton,
    newButton = {},
    newButtonText,
    editRelatedRecordButtons: {
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      config: editRelatedRecordsConfig,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      show: showEditRelatedRecords,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      unlinkButtonText: unlinkRelatedRecordButtonText,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      hideUnlinkButton: hideUnlinkRecordButton,
      // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
      hideAddRelatedRecordButton: hideRelatedRecordButton,
    } = {},
    importButton = {},
    // @ts-expect-error TS(2525): Initializer provides no value for this binding ele... Remove this comment to see the full error message
    exportButton: { show: showExportButton, text: exportButtonText } = {},
    newLink,
    openFormInModal,
    record = {},
    fields,
  } = collectionElement.props || {};

  const dataType = useMemo(() => {
    const typeName = get(dataList, 'dataType');

    return (
      (typeName && project.dataTypes.getByName(typeName)) ??
      project.dataTypes.getByName(USER)
    );
  }, [dataList, project.dataTypes]);

  const emptyStateHidden = useMemo(
    () => !emptyState || get(emptyState, 'image.hidden', false),
    [emptyState],
  );

  const isSingleLayout = layout === SINGLE_RECORD;

  const updateImageProps = useCallback(
    (path, value) =>
      updateProperty(['emptyState', 'image', 'value', ...path], value),
    [updateProperty],
  );

  const debouncedUpdateImageProps = useCallback(
    (path, value) =>
      debouncedUpdateProperty(['emptyState', 'image', 'value', ...path], value),
    [debouncedUpdateProperty],
  );

  const hideImage = useCallback(
    (value) => updateProperty(['emptyState', 'image', 'hidden'], value),
    [updateProperty],
  );

  const updateRecordProps = useCallback(
    (path, value) => updateProperty(['record', ...path], value),
    [updateProperty],
  );

  const debounceUpdateRecordProps = useMemo(
    () => debounce(updateRecordProps, 300),
    [updateRecordProps],
  );

  const { feedback: filterFieldsFeedback, hint: filterFieldsHint } =
    useEditorHint(project, dataType, FILTER_FIELDS);

  const dataListFilterField = useMemo(() => {
    if (
      isRecordView &&
      dataType &&
      dataList &&
      dataList.filter &&
      dataList.filter.path
    ) {
      return getFieldFromDependency(
        get(dataList, ['filter', 'path'], '').split('.'),
        rootDataType,
        project.dataTypes,
      );
    }
  }, [dataList, dataType, rootDataType, isRecordView, project.dataTypes]);

  const elementPropsRecordPath = [...collectionElementPath, 'props', 'record'];

  return (
    <>
      <BuildModeViewFilterSettings
        dataType={dataType}
        element={collectionElement}
        elementPath={collectionElementPath}
        filterOptions={filterOptions}
        project={project}
        updateProperty={updateProperty}
      />
      {![CHARTS, PIVOT_TABLE].includes(layout) && dataType && (
        <BuildModeViewSortAndLimitSettings
          dataType={dataType}
          element={collectionElement}
          updateProperty={updateProperty}
        />
      )}
      {!isSingleLayout && (
        <>
          <BuildModeSection
            className="border-t"
            id={VIEW_EMPTY_STATE}
            sticky={true}
            title={getText('rightSidebar.editor.emptyState')}
            guide={
              <Guide
                href="https://guides.noloco.io/pages/collection-views/empty-state"
                arcade="https://demo.arcade.software/Qz28xM1ZAJIQJcvYkWHL?embed"
              >
                {getText(LANG_KEY, 'emptyState.guide')}
              </Guide>
            }
          >
            <div className="mb-2 w-full p-2 text-xs text-gray-400">
              <div className="flex flex-col">
                <BuildModeInput
                  label={getText('elements.VIEW.emptyState.title')}
                >
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
                    contained={true}
                    project={project}
                    onChange={(value: any) =>
                      debouncedUpdateProperty(
                        ['emptyState', 'title', 'value'],
                        value,
                      )
                    }
                    elementPath={collectionElementPath}
                    placeholder=""
                    value={get(emptyState, 'title.value')}
                  />
                </BuildModeInput>
              </div>
              <div className="mt-2 flex flex-col">
                <BuildModeInput
                  label={getText('elements.VIEW.emptyState.subtitle')}
                  markdown={true}
                >
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
                    contained={true}
                    project={project}
                    onChange={(value: any) =>
                      debouncedUpdateProperty(
                        ['emptyState', 'subtitle', 'value'],
                        value,
                      )
                    }
                    elementPath={collectionElementPath}
                    placeholder=""
                    value={get(emptyState, 'subtitle.value')}
                  />
                </BuildModeInput>
              </div>
              <div className="mt-4 flex flex-col">
                <BuildModeImageEditor
                  contained={true}
                  debouncedUpdateProperty={debouncedUpdateImageProps}
                  elementPath={collectionElementPath}
                  elementProps={get(emptyState, 'image.value', {})}
                  hidden={emptyStateHidden}
                  hideImage={hideImage}
                  project={project}
                  updateProperty={updateImageProps}
                />
              </div>
              {collectionElement.type === COLLECTION && (
                <div className="mt-4">
                  <BuildModeSwitchSection
                    label={getText(LANG_KEY, 'emptyState.hideIfEmpty')}
                    onChange={(value) =>
                      debouncedUpdateProperty(
                        ['emptyState', 'hideIfEmpty'],
                        value,
                      )
                    }
                    value={get(emptyState, 'hideIfEmpty', false)}
                  />
                </div>
              )}
            </div>
          </BuildModeSection>
          {layout !== PIVOT_TABLE && (
            <BuildModeSection
              className="border-t"
              id={VIEW_BUTTONS}
              sticky={true}
              title={getText('rightSidebar.editor.buttons')}
            >
              <div className="flex flex-col">
                {layout !== CHARTS && (
                  <div className="mb-2 flex flex-col space-y-2 p-2">
                    <BuildModeFeatureLockedSwitchSection
                      feature={BULK_ACTIONS}
                      className="py-2"
                      label={
                        <>
                          <IconListCheck size={16} />
                          <BuildModeLabel>
                            {getText(
                              LANG_KEY,
                              'actionButtons.label.enableBulkActions',
                            )}
                          </BuildModeLabel>
                        </>
                      }
                      value={bulkActionsEnabled}
                      onChange={(value: boolean) =>
                        updateProperty(['bulkActionsEnabled'], value)
                      }
                    />
                    <NewButtonEditor
                      dataType={dataType}
                      debouncedUpdateProperty={debouncedUpdateProperty}
                      elementPath={collectionElementPath}
                      hideNewButton={hideNewButton}
                      newButton={newButton}
                      newButtonText={newButtonText}
                      newLink={newLink}
                      project={project}
                      openFormInModal={openFormInModal}
                      rootDataType={rootDataType}
                      updateProperty={updateProperty}
                      allowOpenFormInModal={
                        element.type === VIEW && !isRecordView
                      }
                    />
                    {isRecordView && dataListFilterField && (
                      <EditRelatedRecordsEditor
                        config={editRelatedRecordsConfig}
                        dataList={dataList}
                        dataType={dataType}
                        debouncedUpdateProperty={debouncedUpdateProperty}
                        elementPath={collectionElementPath}
                        enabled={showEditRelatedRecords}
                        project={project}
                        rootDataType={rootDataType}
                        unlinkButtonText={unlinkRelatedRecordButtonText}
                        updateProperty={updateProperty}
                        hideUnlinkRecordButton={hideUnlinkRecordButton}
                        hideRelatedRecordButton={hideRelatedRecordButton}
                      />
                    )}
                    <ExportButtonEditor
                      dataType={dataType}
                      debouncedUpdateProperty={debouncedUpdateProperty}
                      elementPath={collectionElementPath}
                      exportButtonText={exportButtonText}
                      project={project}
                      showExportButton={showExportButton}
                      element={collectionElement}
                      updateProperty={updateProperty}
                      fields={fields}
                      rootDataType={rootDataType}
                    />
                    <ImportButtonEditor
                      dataType={dataType}
                      debouncedUpdateProperty={debouncedUpdateProperty}
                      elementPath={collectionElementPath}
                      importButton={importButton}
                      project={project}
                      updateProperty={updateProperty}
                      rootDataType={rootDataType}
                    />
                  </div>
                )}
                {element.type === VIEW && !isRecordView && (
                  <BuildModeActionButtonsEditor
                    actionButtons={actionButtons || []}
                    scanActionsAvailable={[
                      ScanActionType.CREATE,
                      ScanActionType.NAVIGATE,
                    ]}
                    actionOptions={[NAVIGATE, SCAN_BARCODE]}
                    className="mb-1 mt-3"
                    dataType={dataType}
                    debouncedUpdateProperty={debouncedUpdateProperty}
                    defaultExecution={ONE_CLICK}
                    elementPath={collectionElementPath}
                    label={getText(LANG_KEY, 'actionButtons.label.navigation')}
                    project={project}
                    updateProperty={updateProperty}
                  />
                )}
                {layout !== CHARTS && (
                  <BuildModeActionButtonsEditor
                    actionButtons={record.actionButtons || []}
                    scanActionsAvailable={[
                      ScanActionType.CREATE,
                      ScanActionType.UPDATE,
                    ]}
                    className="mb-1 mt-3"
                    dataType={dataType}
                    debouncedUpdateProperty={debounceUpdateRecordProps}
                    display={
                      collectionElement.type === VIEW ? 'collection' : undefined
                    }
                    elementPath={elementPropsRecordPath}
                    label={getText(LANG_KEY, 'actionButtons.label.record')}
                    project={project}
                    sectionPropPath={
                      isRecordView ? collectionElementPath : undefined
                    }
                    updateProperty={updateRecordProps}
                  />
                )}
                {LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout) && (
                  <BuildModeViewColorConditionsEditor
                    dataType={dataType}
                    element={collectionElement}
                    elementPath={collectionElementPath}
                    project={project}
                    updateProperty={updateProperty}
                  />
                )}
              </div>
            </BuildModeSection>
          )}
          {dataType && (
            <BuildModeSection
              className="border-t"
              count={filters.length}
              id={VIEW_FILTER_FIELDS}
              sticky={true}
              title={getText('rightSidebar.editor.filterFields')}
            >
              <ViewCollectionFilterFieldsEditor
                dataList={dataList}
                dataType={dataType}
                debouncedUpdateProperty={debouncedUpdateProperty}
                elementId={collectionElement.id}
                elementPath={collectionElementPath}
                filters={filters}
                hint={filterFieldsHint}
                isRecordView={false}
                onHintFeedback={filterFieldsFeedback}
                project={project}
                searchEnabled={searchEnabled}
                searchPlaceholder={searchPlaceholder}
                sticky={true}
                updateProperty={updateProperty}
              />
            </BuildModeSection>
          )}
        </>
      )}
    </>
  );
};

export default BuildModeViewOptionsTab;
