import { DataSourceType, DataSourceTypeType } from '../constants/dataSources';
import { BaseJsonMaps, JsonMaps } from './BaseArrayTypeMap';
import DataTypeFields, { BaseDataField, DataField } from './DataTypeFields';
import DataTypePermissions from './DataTypePermissions';
import { FieldPermission, Permission } from './Permission';
import ProjectArrayTypeMap, { DataTypeIdentifier } from './ProjectArrayTypeMap';
import { Workflow } from './Workflow';

export interface BaseDataSource {
  id: number | null;
  name?: string | null;
  display: string | null;
  type: DataSourceType;
  collectionType?: DataSourceTypeType;
  connection?: any;
}

export interface DataSource extends BaseDataSource {
  id: number;
  display: string;
}

export interface BaseDataType extends DataTypeIdentifier {
  fields: any;
  permissions?: DataTypePermissions;
}

export interface DataType extends BaseDataType {
  display: string;
  fields: DataTypeFields;
  permissionsEnabled: boolean;
  primaryField: Pick<DataField, 'id' | 'name' | 'apiName'> | null;
  readOnly?: boolean;
  internal: boolean;
  permissions: DataTypePermissions;
  rowCount: number;
  source: BaseDataSource;
  workflows: Workflow[];
  description?: string;
}

export class DataTypeArray<
  T extends BaseDataType,
> extends ProjectArrayTypeMap<T> {
  updatedAt: number;

  constructor(dataTypes?: T[] | number | DataTypeArray<T>) {
    if (dataTypes instanceof DataTypeArray) {
      //Will copy maps for dataTypes in constructor & reuse current instances of DataTypeFields
      super(dataTypes);
      this.updatedAt = dataTypes.updatedAt ?? Date.now();
    } else if (dataTypes !== undefined && typeof dataTypes !== 'number') {
      const dataTypesWithFields = dataTypes.map(DataTypeArray.formatDataType);

      super(dataTypesWithFields);
      this.updatedAt = Date.now();
    } else {
      super(dataTypes);
      this.updatedAt = Date.now();
    }
  }

  static fromJSON<T extends BaseDataType>(
    json: JsonMaps<
      T & {
        fields: BaseJsonMaps<BaseDataField>;
        permissions?: JsonMaps<
          Permission & {
            fieldPermissions?: JsonMaps<
              FieldPermission,
              { fieldMap: Record<string, number> }
            >;
          },
          { roleMap: number[] }
        >;
      },
      {
        nameMap: Record<string, number>;
        apiNameMap: Record<string, number>;
      }
    >,
  ) {
    const jsonMap = json;

    Object.keys(json.idMap).forEach((dataTypeKey) => {
      const dataTypeId = Number(dataTypeKey);
      const dataType = jsonMap.idMap[dataTypeId];

      dataType.fields = DataTypeFields.fromJSON(dataType.fields);

      if (dataType.permissions) {
        // This thinks it should be JsonMaps<Permission> but it should be DataTypePermissions
        (dataType.permissions as DataTypePermissions) =
          DataTypePermissions.fromJSON(dataType.permissions);
      }

      jsonMap.idMap[dataTypeId] = dataType;
    });

    return DataTypeArray._fromJSON(jsonMap) as DataTypeArray<T>;
  }

  static formatDataType<D extends BaseDataType>(dataType: D) {
    return {
      ...dataType,
      fields: new DataTypeFields(dataType.fields),
      permissions: new DataTypePermissions(dataType.permissions || []),
    };
  }

  async setup() {
    await this.asyncSetup();
    await Promise.all(
      this.map(async (dataType) => {
        await dataType.fields.asyncSetup();

        if (dataType.permissions) {
          await dataType.permissions.asyncSetup();
        }
      }),
    );

    return this;
  }

  setupSync() {
    this.forEach((dataType) => {
      dataType.fields.syncSetup();

      if (dataType.permissions) {
        dataType.permissions.syncSetup();
      }
    });

    super.syncSetup();

    return this;
  }

  update() {
    this.updatedAt = Date.now();

    return this;
  }
}

class DataTypes extends DataTypeArray<DataType> {}

export default DataTypes;
