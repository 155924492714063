import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMenu2 } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { getColorShade } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import {
  useUpdateElements,
  useUpdateProperty,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SidebarNav from '../components/sidebar/SidebarNav';
import SidebarNavFooter from '../components/sidebar/SidebarNavFooter';
import { MenuStyle, SIDE_MENU, TOP_MENU } from '../constants/menuStyles';
import { ElementPath } from '../models/Element';
import { setEditingPage, setSelectedElement } from '../reducers/elements';
import {
  editingPageSelector,
  selectedElementPathSelector,
} from '../selectors/elementsSelectors';
import { projectDataSelector } from '../selectors/projectSelectors';
import useDarkMode, { useIsDarkModeDefault } from '../utils/hooks/useDarkMode';
import useSpaces from '../utils/hooks/useSpaces';
import { getProjectLogo } from '../utils/image';
import { getPages, getPagesConfig } from '../utils/pages';
import CommandPalette from './CommandPalette';
import RecordViewNavTitle from './RecordViewNavTitle';
import SpaceSelector from './SpaceSelector';
import SidebarCollapseButton from './sections/collections/SidebarCollapseButton';

interface SidebarProps {
  className?: string;
  editorMode: boolean;
  isNavExpanded: boolean;
  isThemePreview?: boolean;
  menuStyle: MenuStyle;
  primaryColor: string;
  setGhostUserId: (ghostUserId: string) => void;
  setIsNavExpanded: (isNavExpanded: boolean) => void;
}

const DEBOUNCE_TIME = 300;

const Sidebar = ({
  className,
  editorMode,
  isNavExpanded,
  isThemePreview,
  menuStyle,
  primaryColor,
  setGhostUserId,
  setIsNavExpanded,
}: SidebarProps) => {
  const project = useSelector(projectDataSelector);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const closeNav = useCallback(() => setIsNavOpen(false), []);
  const dispatch = useDispatch();
  const { sm: isSmScreen } = useBreakpoints();
  const selectedPath = useSelector(selectedElementPathSelector);
  const editingPage = useSelector(editingPageSelector);
  const [updateProperty] = useUpdateProperty(selectedPath, project);
  const [updateProject] = useUpdateElements(project);
  const spaces = useSpaces();

  const debouncedUpdateProperty = useMemo(
    () => debounce(updateProperty, DEBOUNCE_TIME),
    [updateProperty],
  );
  const showExpandedNav =
    isNavExpanded || editorMode || menuStyle !== SIDE_MENU;
  const [darkMode] = useDarkMode();
  const isDarkModeToggleEnabled = useIsDarkModeDefault(project.settings);

  const isDarkModeEnabled = useMemo(
    () => (isThemePreview ? isDarkModeToggleEnabled : darkMode),
    [darkMode, isDarkModeToggleEnabled, isThemePreview],
  );

  const { isV2, portalPath, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project.elements, project.settings],
  );

  const handleSetEditingPage = useCallback(
    (newEditingPage: string) => dispatch(setEditingPage(newEditingPage)),
    [dispatch],
  );

  const commandPaletteEnabled = useMemo(
    () => get(project, 'settings.navigation.commandPalette.enabled', false),
    [project],
  );

  const portalPages = useMemo(
    () => (isV2 ? project.elements : get(project.elements, pagesPath, [])),
    [isV2, project.elements, pagesPath],
  );

  const pages = useMemo(() => getPages(project.elements), [project.elements]);

  const showProfile = useMemo(
    () => !!pages.find((page) => page.props.routePath === 'profile'),
    [pages],
  );

  const onEditSelectPage = useCallback(
    (id: string, path: ElementPath) => {
      dispatch(setSelectedElement(path));
      handleSetEditingPage(id);
    },
    [dispatch, handleSetEditingPage],
  );

  const toggleSidebar = useCallback(
    () => setIsNavExpanded(!isNavExpanded),
    [setIsNavExpanded, isNavExpanded],
  );

  return (
    <div
      className={classNames(
        className,
        'sidebar text-xs text-white sm:relative sm:h-16 sm:min-h-0 sm:w-full sm:max-w-none sm:flex-col',
        `bg-${getColorShade(primaryColor, isDarkModeEnabled ? 800 : 700)}`,
        {
          'relative h-full max-h-screen flex-col items-start':
            menuStyle === SIDE_MENU,
          'sticky top-0 z-10 flex h-16 w-full flex-shrink-0 items-center px-4 sm:p-0':
            menuStyle === TOP_MENU,
          'sm:max-h-screen sm:min-h-screen': isNavOpen,
          'hidden sm:flex': editorMode && menuStyle === SIDE_MENU,
          flex: !editorMode && menuStyle === SIDE_MENU,
          'w-full max-w-64': showExpandedNav && menuStyle === SIDE_MENU,
          'w-20': !showExpandedNav && menuStyle === SIDE_MENU,
        },
      )}
      data-testid="navigation-sidebar"
    >
      {!editorMode && menuStyle === SIDE_MENU && !isSmScreen && (
        <SidebarCollapseButton
          isOpen={showExpandedNav}
          onClick={toggleSidebar}
        />
      )}
      <div
        className={classNames(
          'flex items-center sm:w-full sm:justify-between',
          {
            'w-full p-3 py-6 sm:p-0': menuStyle === SIDE_MENU,
            'mr-4 h-full flex-shrink-0 py-2 sm:mr-0 sm:h-16 sm:px-4':
              menuStyle === TOP_MENU,
            'sm:h-full': !isNavOpen,
            'my-2': isNavOpen && menuStyle === SIDE_MENU,
          },
        )}
      >
        <Link
          to="/"
          className="flex h-8 w-full select-none items-center justify-center sm:mx-2 sm:h-10 sm:w-auto sm:justify-start"
        >
          <img
            className="max-h-full sm:h-10"
            src={getProjectLogo(project.settings, project.media)}
            alt="App logo"
            data-testid="sidebar-logo"
          />
        </Link>
        {isSmScreen && !isNavOpen && (
          <RecordViewNavTitle
            projectName={project.name}
            utmSource="noloco_sidebar"
          />
        )}
        <button
          className="hidden items-center justify-center rounded-full p-3 hover:bg-white hover:bg-opacity-25 focus:bg-white focus:bg-opacity-25 sm:flex"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <IconMenu2 />
        </button>
      </div>
      {spaces.length > 0 &&
        (isSmScreen ? isNavOpen : menuStyle === SIDE_MENU) && (
          <SpaceSelector
            elements={project.elements}
            isNavExpanded={isSmScreen || isNavExpanded}
            primaryColor={primaryColor}
            spaces={spaces}
          />
        )}
      {commandPaletteEnabled && menuStyle === SIDE_MENU && (
        <CommandPalette
          className="mt-3"
          isNavExpanded={isNavExpanded}
          pages={pages}
          primaryColor={primaryColor}
        />
      )}
      {/* @ts-expect-error TS2786: 'SimpleBar' cannot be used as a JSX component. */}
      <SimpleBar
        className={classNames(
          'nav-items-wrapper flex w-full flex-grow sm:max-h-full',
          {
            'sm:hidden': !isNavOpen,
            'flex-col overflow-y-auto pb-6 pt-3': menuStyle === SIDE_MENU,
            'max-w-full sm:flex-col sm:overflow-y-auto sm:pb-6 sm:pt-3':
              menuStyle === TOP_MENU,
          },
        )}
        onClick={closeNav}
      >
        <SidebarNav
          debouncedUpdateProperty={debouncedUpdateProperty}
          editingPage={editingPage}
          editorMode={editorMode}
          handleSetEditingPage={handleSetEditingPage}
          menuStyle={menuStyle}
          onEditSelectPage={onEditSelectPage}
          pages={pages}
          pagesPath={pagesPath}
          portalPages={portalPages}
          portalPath={portalPath}
          primaryColor={primaryColor}
          showExpandedNav={showExpandedNav}
          updateProject={updateProject}
          updateProperty={updateProperty}
        />
      </SimpleBar>
      {showExpandedNav &&
        menuStyle === SIDE_MENU &&
        ((isNavOpen && isSmScreen) || !isSmScreen) && (
          <PoweredByNoloco
            className={classNames(
              'border-t px-3 py-2 text-white sm:hidden',
              `bg-${getColorShade(
                primaryColor,
                isDarkModeEnabled ? 800 : 700,
              )}`,
              `border-${getColorShade(primaryColor, 900)}`,
            )}
            projectName={project.name}
            utmSource="noloco_sidebar"
          />
        )}
      {commandPaletteEnabled && menuStyle === TOP_MENU && (
        <CommandPalette
          className="ml-auto max-w-36"
          isNavExpanded={true}
          pages={pages}
          primaryColor={primaryColor}
        />
      )}
      {spaces.length > 0 && menuStyle === TOP_MENU && !isSmScreen && (
        <SpaceSelector
          elements={project.elements}
          menuStyle={menuStyle}
          primaryColor={primaryColor}
          spaces={spaces}
        />
      )}
      <SidebarNavFooter
        editorMode={editorMode}
        isNavExpanded={showExpandedNav}
        isNavOpen={isNavOpen}
        menuStyle={menuStyle}
        primaryColor={primaryColor}
        setGhostUserId={setGhostUserId}
        setIsNavOpen={setIsNavOpen}
        showProfile={showProfile}
      />
    </div>
  );
};

export default withTheme(Sidebar) as React.FC<Omit<SidebarProps, 'theme'>>;
