import React from 'react';
import { SelectInput } from '@noloco/components';
import { getDataTypeOptionsOfType } from '@noloco/core/src/utils/renderedOptions';
import { withNullOption } from '@noloco/core/src/utils/settings';
import ContentDisplayName from './ContentDisplayName';

const NON_BREAKING_SPACE = '\u00a0';

export const DataTypeValue = ({ options, placeholder, value }: any) =>
  value ? (
    <ContentDisplayName data={value} dataOptions={options} />
  ) : (
    (placeholder && placeholder.trim()) || NON_BREAKING_SPACE
  );

interface OwnDataTypeInputProps {
  elementPath?: (string | number)[];
  dataType?: string;
  onChange?: (...args: any[]) => any;
}

// @ts-expect-error TS(2456): Type alias 'DataTypeInputProps' circularly referen... Remove this comment to see the full error message
type DataTypeInputProps = OwnDataTypeInputProps &
  typeof DataTypeInput.defaultProps;

// @ts-expect-error TS(7022): 'DataTypeInput' implicitly has type 'any' because ... Remove this comment to see the full error message
const DataTypeInput = ({
  additionalScopeItems = [],
  contained,
  dataType,
  elementPath,
  includeUniqueColumnar,
  includeSelf,
  onChange,
  placeholder,
  project,
  value,
}: DataTypeInputProps) => {
  const fieldOptions = withNullOption(
    [
      ...additionalScopeItems,
      ...getDataTypeOptionsOfType(project, elementPath, dataType, {
        includeSelf,
        includeUniqueColumnar,
      }),
    ],
    false,
  );

  return (
    <SelectInput
      Button={DataTypeValue}
      className="mb-2 w-full text-black"
      contained={contained}
      value={value}
      options={fieldOptions}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

DataTypeInput.defaultProps = {
  includeUniqueColumnar: false,
  includeSelf: false,
  placeholder: '----',
};

export default DataTypeInput;
