import { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconCheck, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  RatingInput,
  RelativeDateTimeTooltip,
  Theme,
  getColorShade,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import useLocale, {
  getLocaleName,
} from '@noloco/components/src/utils/hooks/useLocale';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import MarkdownText from '../../../components/MarkdownText';
import {
  CollectionLayout,
  ROWS,
  SPLIT,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  BOOLEAN,
  DECIMAL,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '../../../constants/dataTypes';
import {
  BUTTON,
  FIELD_CELL,
  IMAGE,
  LINK,
  MARKDOWN,
  PROGRESS_BAR,
  PROGRESS_RING,
  RELATIVE_DATE,
  TEXT,
} from '../../../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import {
  CONDENSED_RELATIONSHIP,
  COUNT,
  JSON_FORMAT,
  QR_CODE,
} from '../../../constants/fieldDisplayTypes';
import {
  DUE_DATE,
  EMAIL,
  IP_ADDRESS,
  PHONE_NUMBER,
  RATING,
  SLIDER,
  URL,
} from '../../../constants/fieldFormats';
import { BOLD, H1, H2, H3, H4 } from '../../../constants/textTypes';
import InlineAutoForm from '../../../elements/sections/forms/InlineAutoForm';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord, DueDate, RecordValue } from '../../../models/Record';
import { FormFieldConfig } from '../../../models/View';
import { scopeSelector } from '../../../selectors/dataSelectors';
import { ensureArray } from '../../../utils/arrays';
import { formatFieldValue } from '../../../utils/fieldValues';
import { canBeCopiedToClipboard } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import { getOrBuildFormFieldsConfigForType } from '../../../utils/hooks/useFormFields';
import useIsTable, { layoutIsTable } from '../../../utils/hooks/useIsTable';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { getText } from '../../../utils/lang';
import { getOptionByName } from '../../../utils/options';
import { isMultiField } from '../../../utils/relationships';
import {
  formatUrl,
  getAllowedViewRoutePrefixForDataType,
  getViewRoutePrefixForViewId,
} from '../../../utils/urls';
import Progress from '../Progress';
import DownloadableQRCode from '../view/DownloadableQRCode';
import ArrayValueBadge from './ArrayValueBadge';
import CopyFieldValueButton from './CopyFieldValueButton';
import DueDateCell from './DueDateCell';
import OptionBadge from './OptionBadge';
import RelatedCellItem from './RelatedCellItem';

const shouldAlignRight = (layout: CollectionLayout, field: DataField) =>
  layoutIsTable(layout) && (field.type === DECIMAL || field.type === INTEGER);

export const getFormFieldConfig = (
  field: any,
  project: any,
  user: any,
  fieldPermissionsEnabled: any,
  fieldConfig: FormFieldConfig | undefined,
): FormFieldConfig => {
  const allowNewRecords =
    fieldConfig?.allowNewRecords && (field.relationship || field.relatedField);
  const relatedType =
    allowNewRecords && project.dataTypes.getByName(field.type);
  const { fields: newRecordFields, title: newRecordTitle }: any =
    allowNewRecords
      ? getOrBuildFormFieldsConfigForType(
          relatedType,
          project,
          user,
          fieldPermissionsEnabled,
          fieldConfig?.newRecordForm,
        )
      : {};

  const config = fieldConfig || ({} as FormFieldConfig);

  return {
    ...config,
    required: config.required,
    placeholder: !field.relationship
      ? config.placeholder || ''
      : config.placeholder,
    allowNewRecords,
    newRecordFields,
    newRecordTitle,
    fieldObj: field,
  } as FormFieldConfig & { newRecordFields: FormFieldConfig[] };
};

export const formatValue = (
  value: RecordValue,
  field: DataField,
  config: any = {},
  locale?: Locale,
) => {
  const localeName = getLocaleName();

  const formattedValue = formatFieldValue(
    value,
    field,
    config,
    localeName,
    locale,
  );

  const { typeOptions: { format } = {} } = field;

  if (format === DUE_DATE) {
    return (
      <DueDateCell config={config} field={field} value={value as DueDate} />
    );
  }

  if (formattedValue !== undefined) {
    return formattedValue;
  }

  return value;
};

const getSafeJSON = (text: string) => {
  try {
    const formattedJSON = JSON.parse(text);

    return JSON.stringify(formattedJSON, undefined, 2);
  } catch {
    return text;
  }
};

const stopPropagation = (e: any) => e.stopPropagation();

const renderLink = (
  content: string,
  href: string,
  theme: any,
  openInNewTab?: boolean,
) => {
  const primaryColor = get(theme, 'brandColorGroups.primary');

  if (first(href) === '/') {
    return (
      <Link
        to={href}
        className={`truncate text-${getColorShade(
          primaryColor,
          500,
        )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
      >
        {content}
      </Link>
    );
  }

  return (
    <a
      href={href}
      onClick={stopPropagation}
      {...(openInNewTab
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {})}
      className={`truncate text-${getColorShade(
        primaryColor,
        500,
      )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
    >
      {content}
    </a>
  );
};

const renderElementType = (formattedValue: any, config: any, theme: Theme) => {
  if (!config.elementType) {
    return formattedValue;
  }

  if (config.elementType === BOLD) {
    return <strong className="my-0 font-semibold">{formattedValue}</strong>;
  }

  if (config.elementType === H1) {
    return (
      <h1 className="my-0 pb-2 text-3xl font-black leading-none">
        {formattedValue}
      </h1>
    );
  }

  if (config.elementType === H2) {
    return (
      <h2 className="my-0 text-2xl font-bold leading-snug">{formattedValue}</h2>
    );
  }

  if (config.elementType === H3) {
    return (
      <h3 className="my-0 text-xl font-semibold leading-normal">
        {formattedValue}
      </h3>
    );
  }

  if (config.elementType === H4) {
    return (
      <h4 className="my-0 text-lg font-semibold leading-normal">
        {formattedValue}
      </h4>
    );
  }

  if (config.elementType === JSON_FORMAT) {
    return (
      <div className="font-mono tracking-wider">
        {getSafeJSON(formattedValue)}
      </div>
    );
  }

  const linkContent =
    get(config, 'elementConfig.text') ||
    getText('elements.VIEW.fields.elementType.LINK.default');

  if (config.elementType === LINK) {
    return renderLink(
      linkContent,
      formattedValue,
      theme,
      !!get(config, 'elementConfig.newTab'),
    );
  }

  if (config.elementType === BUTTON) {
    if (first(formattedValue) === '/') {
      return (
        <Link to={formattedValue}>
          <Button className="whitespace-nowrap">{linkContent}</Button>
        </Link>
      );
    }

    return (
      <Button
        is="a"
        className="whitespace-nowrap"
        href={formattedValue}
        onClick={stopPropagation}
        {...(get(config, 'elementConfig.newTab')
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
      >
        {linkContent}
      </Button>
    );
  }

  if (config.elementType === MARKDOWN) {
    return <MarkdownText>{formattedValue}</MarkdownText>;
  }

  if (config.elementType === IMAGE) {
    return (
      <img
        src={formattedValue}
        alt={get(config, 'elementConfig.alt')}
        className="w-full overflow-hidden rounded-lg"
      />
    );
  }

  if (config.elementType === QR_CODE) {
    return <DownloadableQRCode value={formattedValue} />;
  }

  return formattedValue;
};

const RelatedFieldCell = ({
  backLink,
  condensed,
  config,
  field,
  value,
  project,
}: any) => {
  const user = useScopeUser();
  const scope = useSelector(scopeSelector);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const recordLinkRoot = useMemo(() => {
    if (config.elementType === TEXT) {
      return null;
    }

    if (config.rowLink) {
      return getViewRoutePrefixForViewId(config.rowLink, project);
    }

    return getAllowedViewRoutePrefixForDataType(
      field.type,
      project,
      user,
      scope,
      customRulesEnabled,
    );
  }, [
    config.elementType,
    config.rowLink,
    customRulesEnabled,
    field.type,
    project,
    scope,
    user,
  ]);

  return (
    <RelatedCellItem
      className="max-w-full"
      condensed={condensed}
      innerClassName=""
      backLink={backLink}
      field={field}
      recordLinkRoot={recordLinkRoot}
      value={value}
      dataTypes={project.dataTypes}
      elementType={config.elementType}
      fileLayout={config.fileLayout}
    />
  );
};

export const ReadOnlyFieldCellValue = ({
  backLink,
  field,
  value,
  config,
  layout,
  project,
  theme,
  section,
}: any) => {
  const surface = useDarkModeSurface();
  const locale = useLocale();
  const format = get(field, 'typeOptions.format', null);
  const isTable = useIsTable(layout);

  if (!field.relationship && !field.relatedField) {
    if (isNil(value)) {
      return <span className="text-gray-500">--</span>;
    }

    if (field.multiple) {
      return (
        <div className="flex max-w-xl flex-wrap gap-1 overflow-hidden">
          {ensureArray(value).map((item: any) => {
            if (field.type === BOOLEAN) {
              return (
                <ArrayValueBadge
                  key={`${field.name}-${item}`}
                  value={item ? <IconCheck size={20} /> : <IconX size={20} />}
                />
              );
            }

            return (
              <ArrayValueBadge
                key={`${field.name}-${item}`}
                value={formatValue(item, field, config) as RecordValue}
              />
            );
          })}
        </div>
      );
    }

    if (field.type === SINGLE_OPTION) {
      const option = getOptionByName(value, field);

      if (option) {
        return <OptionBadge className="mr-auto" option={option} />;
      }
    }

    if (field.type === MULTIPLE_OPTION) {
      return (
        <div className="flex max-w-xl flex-wrap gap-1 overflow-hidden">
          {ensureArray(value).map((rawOption: any) => {
            const option = getOptionByName(rawOption, field);

            if (option) {
              return <OptionBadge key={option.name} option={option} />;
            }

            return null;
          })}
        </div>
      );
    }

    if (field.type === BOOLEAN) {
      return value ? (
        <IconCheck size={20} />
      ) : (
        <span className="text-gray-500">--</span>
      );
    }

    if (format === EMAIL) {
      return renderLink(value, `mailto:${value}`, theme);
    }

    if (field.typeOptions?.format === IP_ADDRESS) {
      return renderLink(value, `http://${value}`, theme);
    }

    if (field.typeOptions?.format === RATING) {
      return (
        <RatingInput
          disabled={true}
          maxRating={get(field.typeOptions, 'max')}
          value={value}
        />
      );
    }

    if (format === URL && !config.elementType) {
      return renderLink(value, formatUrl(value) ?? '', theme);
    }

    const formattedValue = formatValue(value, field, config, locale);

    if (format === PHONE_NUMBER && formattedValue) {
      return renderLink(
        formattedValue as string,
        `tel:${formattedValue}`,
        theme,
      );
    }

    if (
      [PROGRESS_BAR, PROGRESS_RING].includes(config.elementType) ||
      format === SLIDER
    ) {
      return (
        <Progress
          config={config}
          value={value}
          formattedMaxValue={
            formatValue(
              get(config, 'elementConfig.maxValue', 100),
              field,
              config,
            ) as number
          }
          formattedValue={formattedValue as number}
          primaryColor={get(theme, 'brandColors.primary')}
          section={section}
          typeOptions={field.typeOptions}
          layout={layout}
        />
      );
    }

    if (
      isTable &&
      (!config.elementType ||
        [MARKDOWN, LINK, BOLD, H1, H2, H3].includes(config.elementType))
    ) {
      const elementResult = renderElementType(formattedValue, config, theme);

      return (
        <div
          className={classNames('max-w-full whitespace-pre-wrap break-words', {
            'h-full': config.truncate,
            'w-full': shouldAlignRight(layout, field),
          })}
        >
          <span
            className={classNames('block', {
              'h-full truncate': config.truncate,
            })}
          >
            {elementResult}
          </span>
          <span
            aria-hidden="true"
            className="invisible block h-0 overflow-hidden whitespace-nowrap"
          >
            {elementResult}
          </span>
        </div>
      );
    }

    if (config.elementType === RELATIVE_DATE) {
      return (
        <RelativeDateTimeTooltip
          className="flex flex-shrink"
          date={value}
          surface={surface}
          format={field.typeOptions?.format}
          timeZone={field.typeOptions?.timeZone}
        />
      );
    }

    return (
      <span className="w-full truncate whitespace-pre-wrap text-balance break-words">
        {renderElementType(formattedValue, config, theme)}
      </span>
    );
  }

  if (isMultiField(field) && config.elementType === COUNT) {
    return (
      <span className="whitespace-pre-wrap break-words">
        {get(value, 'edges', []).length}
      </span>
    );
  }

  if (
    isNil(value) ||
    (isMultiField(field) && get(value, 'edges', []).length === 0)
  ) {
    return <span className="text-gray-500">--</span>;
  }

  return (
    <RelatedFieldCell
      backLink={backLink}
      condensed={
        isMultiField(field) && config.elementType === CONDENSED_RELATIONSHIP
      }
      config={config}
      field={field}
      value={value}
      project={project}
    />
  );
};

interface InlineFieldAutoFormProps {
  backLink?: string[];
  field: DataField;
  dataType: DataType;
  layout: CollectionLayout;
  project: Project;
  resolvedConfig: FormFieldConfig;
  scope: Record<string, any>;
  skipResolvingForValueIds: string[];
  theme: Theme;
  transformScope: (scope: Record<string, any>) => Record<string, any>;
  value: RecordValue;
  bulkActionsEnabled?: boolean;
  isRowChecked?: boolean;
  selectedRows?: BaseRecord[];
}

const InlineFieldAutoForm = ({
  backLink,
  field,
  dataType,
  layout,
  project,
  resolvedConfig,
  scope,
  theme,
  value,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: InlineFieldAutoFormProps) => (
  <InlineAutoForm
    className={classNames('w-full max-w-full', {
      'rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700':
        field.type !== BOOLEAN,
    })}
    dataType={dataType}
    elementId="id"
    field={field}
    scope={scope}
    surface={LIGHT}
    ReadOnlyCell={() => (
      <ReadOnlyFieldCellValue
        backLink={backLink}
        field={field}
        layout={layout}
        value={value}
        config={resolvedConfig}
        project={project}
        theme={theme}
      />
    )}
    project={project}
    bulkActionsEnabled={bulkActionsEnabled}
    isRowChecked={isRowChecked}
    selectedRows={selectedRows}
  />
);

const FieldCellValue = ({
  backLink,
  config,
  dataType,
  field,
  layout,
  permissions,
  record,
  showInput,
  value,
  project,
  skipResolvingForValueIds = [],
  transformScope = identity,
  theme,
  section,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: any) => {
  const locale = useLocale();
  const scope = useMemo(
    () =>
      transformScope(
        {
          id: { edges: { node: record } },
        },
        record,
      ),
    [transformScope, record],
  );

  const resolvedConfig = useSectionScopeVariables(
    FIELD_CELL,
    config,
    project,
    [],
    scope,
  );

  const showInlineForm = useMemo(
    () =>
      showInput &&
      permissions.update &&
      !field.readOnly &&
      ((config.elementType && config.elementType === PROGRESS_BAR) ||
        !config.elementType),
    [config.elementType, field.readOnly, permissions.update, showInput],
  );

  if (!permissions.read) {
    return null;
  }

  if (showInlineForm) {
    return (
      <InlineFieldAutoForm
        field={field}
        dataType={dataType}
        layout={layout}
        project={project}
        resolvedConfig={resolvedConfig}
        scope={scope}
        skipResolvingForValueIds={skipResolvingForValueIds}
        theme={theme}
        transformScope={transformScope}
        value={value}
        bulkActionsEnabled={bulkActionsEnabled}
        isRowChecked={isRowChecked}
        selectedRows={selectedRows}
      />
    );
  }

  const showCopyToClipboard =
    config.copyToClipboard && canBeCopiedToClipboard(field) && value;

  return (
    <div
      className={classNames('group/field-cell flex items-center', {
        relative: showCopyToClipboard,
      })}
    >
      <ReadOnlyFieldCellValue
        backLink={backLink}
        field={field}
        layout={layout}
        value={value}
        config={resolvedConfig}
        project={project}
        theme={theme}
        section={section}
      />
      {showCopyToClipboard && (
        <CopyFieldValueButton
          layout={layout}
          value={formatValue(value, field, config, locale)}
        />
      )}
    </div>
  );
};

const FieldCell = ({
  backLink,
  className,
  config,
  dataType,
  field,
  transformScope,
  layout,
  permissions,
  value,
  record,
  showInput,
  showLabel,
  project,
  theme,
  readOnly,
  skipResolvingForValueIds,
  section = null,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();

  const textColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-500';

  const alignRight = shouldAlignRight(layout, field);
  const columnWidth =
    config.columnWidth || (config.columnSpan ? config.columnSpan * 4 : null);
  const isGridLayout = layout === ROWS || layout === SPLIT;

  return (
    <div
      className={classNames(className, 'w-full max-w-full', {
        'md:col-span-12': isGridLayout,
        'col-span-3 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 3 || (!columnWidth && layout === ROWS)),
        'col-span-4 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 4 || (!columnWidth && layout === SPLIT)),
        'col-span-6': isGridLayout && columnWidth === 6,
        'col-span-8': isGridLayout && columnWidth === 8,
        'col-span-9': isGridLayout && columnWidth === 9,
        'col-span-12': isGridLayout && columnWidth === 12,
        'mr-auto': !alignRight,
      })}
      data-testid="field-cell"
    >
      <div
        className={classNames(className, { 'w-full text-right': alignRight })}
      >
        {showLabel && config.label && !config.label.hidden && (
          <span
            className={classNames(
              'mb-2 text-xs font-medium tracking-wider',
              textColor,
            )}
            data-testid="field-cell-label"
          >
            {config.label.value}
          </span>
        )}
        <FieldCellValue
          backLink={backLink}
          config={config}
          dataType={dataType}
          field={field}
          layout={layout}
          permissions={permissions}
          showInput={(showInput || config.editInline) && !readOnly}
          record={record}
          value={value}
          showLabel={showLabel}
          project={project}
          theme={theme}
          skipResolvingForValueIds={skipResolvingForValueIds}
          transformScope={transformScope}
          section={section}
          bulkActionsEnabled={bulkActionsEnabled}
          isRowChecked={isRowChecked}
          selectedRows={selectedRows}
        />
      </div>
    </div>
  );
};

FieldCell.defaultProps = {
  columns: 2,
  skipResolvingForValueIds: [],
};

export default withTheme(FieldCell);
