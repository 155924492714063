import React, { useCallback, useState } from 'react';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { TablerIconsProps } from '@tabler/icons-react';
import { LIGHT } from '../../constants/surface';
import { Surface } from '../../models';
import { Tooltip } from '../popover';

interface CopyButtonProps {
  className?: string;
  Icon?: (props: TablerIconsProps) => JSX.Element;
  placement?: string;
  showArrow?: boolean;
  size?: number;
  surface?: Surface;
  tooltipContent: string | JSX.Element;
  value: string;
}

const CopyButton = ({
  className,
  Icon = IconCopy,
  placement = 'top',
  showArrow = true,
  size,
  surface = LIGHT,
  tooltipContent,
  value,
}: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const copyValue = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    },
    [value],
  );

  return (
    <Tooltip
      content={
        <div className="flex items-center space-x-2">
          <IconCheck size={size} className="flex-shrink-0 text-green-500" />
          <span>{tooltipContent}</span>
        </div>
      }
      isOpen={copied}
      placement={placement}
      showArrow={showArrow}
      surface={surface}
      trigger="none"
    >
      <button className={className} onClick={copyValue}>
        <Icon size={size} className="flex-shrink-0" />
      </button>
    </Tooltip>
  );
};

export default CopyButton;
