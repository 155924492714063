import React, { forwardRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { SelectInput } from '@noloco/components';
import { AUTH_WRAPPER_ID } from '@noloco/core/src/constants/auth';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import {
  EMPTY,
  FALSE,
  IN,
  NOT_EMPTY,
  NOT_IN,
  Operator,
  TRUE,
} from '@noloco/core/src/constants/operators';
import { Project } from '@noloco/core/src/models/Project';
import { getDataItemDataType } from '@noloco/core/src/utils/data';
import { getFieldFromDependency } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { getOperatorsForFieldType } from '@noloco/core/src/utils/operator';
import { BaseConditionValueEditorProps } from '../../models/BaseConditionValueEditorProps';
import ConditionValueEditor from '../canvas/ConditionValueEditor';
import ContentDisplayName, { findOption } from '../canvas/ContentDisplayName';

interface Props {
  contained: boolean;
  inline: boolean;
  dataType: any;
  project: Project;
  fieldOptions: any[];
  updateCondition: (...args: any[]) => any;
  elementPath?: any[];
  condition: {
    field?: {
      id: string;
      path: string;
      source: string;
      dataType: string;
      display: string;
    };
    operator?: Operator;
    value?: any;
  };
  FieldItem?: React.ForwardRefExoticComponent<
    { value: any } & React.RefAttributes<any>
  >;
  ValueInput?: React.ExoticComponent<BaseConditionValueEditorProps>;
}

const OPERATORS_WITHOUT_VALUE: Operator[] = [EMPTY, NOT_EMPTY, TRUE, FALSE];
const OPERATORS_WITH_MULTIPLE_VALUES: Operator[] = [IN, NOT_IN];

const ConditionEditor = ({
  contained = true,
  dataType,
  inline,
  fieldOptions = [],
  updateCondition,
  condition,
  elementPath,
  project,
  FieldItem,
  ValueInput = ConditionValueEditor,
}: Props) => {
  const DefaultFieldItem = forwardRef(({ value }: { value: any }, ref) => (
    <ContentDisplayName
      dataOptions={fieldOptions}
      data={value}
      parentPath={elementPath}
      ref={ref}
    />
  ));

  const onFieldChange = (newField: any) => updateCondition(['field'], newField);
  const onOperatorChange = (newOperator: any) =>
    updateCondition(['operator'], newOperator);

  const onValueChange = useCallback(
    (newValue) => updateCondition(['value'], newValue),
    [updateCondition],
  );

  const debouncedOnValueChange = useMemo(
    () => debounce(onValueChange, 500),
    [onValueChange],
  );

  const conditionDataType = useMemo(() => {
    const conditionFieldId = get(condition, ['field', 'id']);

    if (conditionFieldId === AUTH_WRAPPER_ID) {
      return project.dataTypes.getByName(USER);
    }

    if (elementPath) {
      const option = findOption(fieldOptions, condition.field, []);

      return option?.option?.dataType ?? dataType;
    }

    return dataType;
  }, [condition, dataType, elementPath, fieldOptions, project.dataTypes]);

  const { field, fieldDataType, fieldType, parent } = useMemo(() => {
    if (
      !condition ||
      !condition.field ||
      !condition.field.path ||
      !conditionDataType
    ) {
      return {};
    }

    const baseDataType = getDataItemDataType(condition.field);
    const fieldResult = getFieldFromDependency(
      // We can remove `._columns.id` to trick it into using the parent multi-field
      condition.field.path.replace(/\._columns\.id$/, '').split('.'),
      conditionDataType,
      project.dataTypes,
    );

    if (!fieldResult) {
      return {
        fieldType: baseDataType,
      };
    }

    return {
      fieldDataType: fieldResult.dataType,
      fieldType: fieldResult.field.type,
      field: fieldResult.field,
      parent: fieldResult.parent,
    };
  }, [condition, conditionDataType, project.dataTypes]);

  const operatorOptions = useMemo(
    () =>
      (condition.field ? getOperatorsForFieldType(fieldType) : []).map(
        (op) => ({
          label: getText('operators', op, 'label.default'),
          value: op,
        }),
      ),
    [condition.field, fieldType],
  );

  return (
    <div
      className={classNames('flex', {
        'flex-col justify-center space-y-4': !inline,
        'flex-wrap items-center gap-2': inline,
      })}
    >
      <SelectInput
        Button={FieldItem ?? DefaultFieldItem}
        contained={contained}
        onChange={onFieldChange}
        options={fieldOptions}
        placeholder={getText('conditionEditor.field.placeholder')}
        searchable={true}
        shiftRight={true}
        value={condition.field}
      />
      <SelectInput
        value={condition.operator}
        contained={contained}
        options={operatorOptions}
        onChange={onOperatorChange}
        placeholder={getText('conditionEditor.operator.placeholder')}
      />
      {!OPERATORS_WITHOUT_VALUE.includes(condition.operator!) && (
        <ValueInput
          field={field}
          dataType={fieldDataType}
          project={project}
          contained={contained}
          onChange={debouncedOnValueChange}
          disabled={!condition.operator}
          multiple={OPERATORS_WITH_MULTIPLE_VALUES.includes(
            condition.operator!,
          )}
          elementPath={elementPath}
          parent={parent}
          placeholder={getText('conditionEditor.value.placeholder')}
          value={condition.value || []}
        />
      )}
    </div>
  );
};

export default ConditionEditor;
