import {
  IconBrandStripe,
  IconClick,
  IconCopy,
  IconEdit,
  IconEye,
  IconFileCode,
  IconLocation,
  IconMessage2,
  IconScan,
  IconSquarePlus,
  IconSquareX,
} from '@tabler/icons-react';
import { ActionButtonActionConfig } from '../models/ActionConfig';
import DataTypes from '../models/DataTypes';
import { Action } from '../models/Element';
import {
  ADD_COMMENT,
  COPY,
  CREATE,
  DELETE,
  IFRAME,
  NAVIGATE,
  ON_DEMAND,
  OPEN_STRIPE_PORTAL,
  SCAN_BARCODE,
  UPDATE,
  VIEW,
} from './actionTypes';
import baseActions, { getDataTypeStateOptions } from './baseActions';

const getDataTypeMutationScope = (action: Action, dataTypes: DataTypes) => {
  const { dataType: dataTypeName } = action;
  const dataType = dataTypeName && dataTypes.getByName(dataTypeName);

  if (dataType) {
    return getDataTypeStateOptions(action.id, dataType);
  }

  return [];
};

const actions = {
  [CREATE]: new ActionButtonActionConfig({
    Icon: IconSquarePlus,
    deriveScope: getDataTypeMutationScope,
    getDataItems: baseActions[CREATE],
  }),
  [UPDATE]: new ActionButtonActionConfig({
    Icon: IconEdit,
    deriveScope: getDataTypeMutationScope,
    getDataItems: baseActions[UPDATE],
  }),
  [VIEW]: new ActionButtonActionConfig({
    Icon: IconEye,
    deriveScope: getDataTypeMutationScope,
    getDataItems: baseActions[VIEW], // this is a no op
  }),
  [DELETE]: new ActionButtonActionConfig({
    Icon: IconSquareX,
    deriveScope: getDataTypeMutationScope,
  }),
  [ADD_COMMENT]: new ActionButtonActionConfig({
    Icon: IconMessage2,
  }),
  [ON_DEMAND]: new ActionButtonActionConfig({
    Icon: IconClick,
  }),
  [IFRAME]: new ActionButtonActionConfig({
    Icon: IconFileCode,
  }),
  [OPEN_STRIPE_PORTAL]: new ActionButtonActionConfig({
    Icon: IconBrandStripe,
  }),
  [NAVIGATE]: new ActionButtonActionConfig({
    Icon: IconLocation,
    getDataItems: baseActions[NAVIGATE],
  }),
  [COPY]: new ActionButtonActionConfig({
    Icon: IconCopy,
  }),
  [SCAN_BARCODE]: new ActionButtonActionConfig({
    Icon: IconScan,
  }),
};

export default actions;
