import React from 'react';
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Endpoint } from '@noloco/core/src/constants/apis';
import { getText } from '@noloco/core/src/utils/lang';

interface Props {
  disabled: boolean;
  error: string | null;
  endpoints: any[];
  endpointsAreValid?: boolean[];
  onSelectEndpoint: (index: number) => () => void;
  selectedEndpoint: number | null;
}

const ApiEndpoints = ({
  disabled,
  error,
  endpoints,
  endpointsAreValid,
  onSelectEndpoint,
  selectedEndpoint,
}: Props) => {
  if (error) {
    return (
      <p className="mt-2 rounded-lg border border-dashed border-gray-300 p-3 text-center">
        {error}
      </p>
    );
  }

  if (get(endpoints, ['length'], 0) === 0) {
    return (
      <p className="mt-2 rounded-lg border border-dashed border-gray-300 p-3 text-center">
        {getText('apis.form.endpoints.empty')}
      </p>
    );
  }

  return (
    <div>
      {(endpoints || []).map((endpoint: Endpoint, index: number) => (
        <div
          key={endpoint.display}
          className={classNames('my-2 rounded-lg p-3', {
            'bg-slate-700': index !== selectedEndpoint,
            'bg-slate-900': index === selectedEndpoint,
            'cursor-pointer hover:bg-slate-600': !disabled,
          })}
          onClick={disabled ? undefined : onSelectEndpoint(index)}
        >
          <div className="flex items-center">
            <span>{endpoint.display}</span>
            {!!endpointsAreValid && (
              <span className="ml-auto">
                {endpointsAreValid[index] ? (
                  <IconCircleCheck className="text-green-500" size={16} />
                ) : (
                  <IconAlertCircle className="text-red-500" size={16} />
                )}
              </span>
            )}
          </div>
          <div className="mt-2 flex items-center">
            <span className="mr-4 rounded-lg bg-pink-500 px-2 py-0.5 text-xs font-bold text-white">
              {endpoint.method}
            </span>
            <span className="text-sm text-gray-300">{endpoint.path}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApiEndpoints;
