import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'arrow-autofit-content',
  'IconArrowAutofitContent',
  [
    ['path', { d: 'M6 4l-3 3l3 3', key: 'svg-0' }],
    ['path', { d: 'M18 4l3 3l-3 3', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M4 14m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M10 7h-7', key: 'svg-3' }],
    ['path', { d: 'M21 7h-7', key: 'svg-4' }],
  ],
);
