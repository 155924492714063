import React from 'react';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { getIconForDataField } from '@noloco/core/src/utils/dataFieldIcons';

interface Props {
  field: DataField;
  [rest: string]: any;
}

const DataFieldIcon = ({ field, ...rest }: Props) => {
  const Icon = getIconForDataField(field);

  return <Icon {...rest} />;
};

export default DataFieldIcon;
