import React, { useCallback, useMemo } from 'react';
import { SelectInput, Surface } from '@noloco/components';
import { SINGLE_OPTION } from '@noloco/core/src/constants/dataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { sortOptions } from '@noloco/core/src/utils/fields';
import { ColorSwatch } from '../../leftSidebar/data/ColorOptionInput';
import DynamicSwitch from './DynamicSwitch';

interface StaticOptionFieldInputProps {
  dataType: DataType;
  isOpen: boolean;
  onChange: (value: StringPropSegment[] | null) => void;
  onSetDynamic: (dynamic: boolean) => void;
  onClose: () => void;
  field: DataField;
  multiple: boolean;
  placeholder: string | undefined;
  surface: Surface;
  value: StringPropSegment[];
}

const StaticOptionFieldInput = ({
  dataType,
  field,
  isOpen,
  multiple,
  value,
  onChange,
  onClose,
  onSetDynamic,
  placeholder,
  surface,
}: StaticOptionFieldInputProps) => {
  const options = useMemo(
    () =>
      sortOptions(field.options!).map((option, index) => ({
        label: option.display,
        value: option.name,
        icon: <ColorSwatch color={option.color ?? getColorByIndex(index)} />,
      })),
    [field.options],
  );

  const optionValue = useMemo(() => {
    if (value.length === 0) {
      return multiple ? [] : null;
    }

    if (!multiple && value.length === 1 && value[0].data) {
      return value[0].data.path.split('.')[2] ?? null;
    }

    if (multiple) {
      return value
        .filter((valueItem) => valueItem.data)
        .map((valueItem) => valueItem.data?.path.split('.')[2] ?? null)
        .filter(Boolean);
    }

    return multiple ? [] : null;
  }, [multiple, value]);

  const onOptionChange = useCallback(
    (optionValue: string | string[] | null) => {
      if (!optionValue) {
        onChange(null);
      } else {
        if (!multiple) {
          onChange([
            {
              static: true,
              data: {
                id: SINGLE_OPTION,
                path: `${dataType.name}.${field.name}.${optionValue}.name`,
                dataType: SINGLE_OPTION,
              },
            },
          ]);
        } else {
          const newValue = (optionValue as string[]).reduce(
            (acc, optionName, index) => {
              if (index > 0) {
                acc.push({ text: ',' });
              }

              acc.push({
                static: true,
                data: {
                  id: SINGLE_OPTION,
                  path: `${dataType.name}.${field.name}.${optionName}.name`,
                  dataType: SINGLE_OPTION,
                },
              });

              return acc;
            },
            [] as StringPropSegment[],
          );

          onChange(newValue);
        }
      }
    },
    [dataType.name, field.name, multiple, onChange],
  );

  return (
    <SelectInput
      className="w-full"
      onChange={onOptionChange}
      footer={<DynamicSwitch value={false} onChange={onSetDynamic} />}
      value={optionValue}
      options={options}
      open={isOpen ? true : undefined}
      onOpenChange={isOpen ? onClose : undefined}
      searchable={true}
      multiple={multiple}
      placeholder={placeholder}
      placement="top-start"
      surface={surface}
    />
  );
};

export default StaticOptionFieldInput;
