import { countries } from 'countries-list';
import { SubField } from '../models/DataTypeFields';
import { BOOLEAN, DATE, DECIMAL, SINGLE_OPTION, TEXT } from './dataTypes';
import {
  ADDRESS,
  COORDINATES,
  DATE_RANGE,
  DUE_DATE,
  FULL_NAME,
  PHONE_NUMBER,
  SINGLE_LINE_TEXT,
} from './fieldFormats';

export const FORMATS_WITH_ROOT = [ADDRESS, FULL_NAME, PHONE_NUMBER];

export const OBJECT_FORMATS_WITH_DISPLAY = [
  ...FORMATS_WITH_ROOT,
  DATE_RANGE,
  DUE_DATE,
  COORDINATES,
];

export const OFFICE: PhoneNumberType = 'office';
export const MOBILE: PhoneNumberType = 'mobile';
export const HOME: PhoneNumberType = 'home';
export const FAX: PhoneNumberType = 'fax';
export const OTHER: PhoneNumberType = 'other';

export const PHONE_NUMBER_TYPES: PhoneNumberType[] = [
  OFFICE,
  MOBILE,
  HOME,
  FAX,
  OTHER,
];

export type PhoneNumberType = 'office' | 'mobile' | 'home' | 'fax' | 'other';

const { phoneCountryOptions, addressCountryOptions } = Object.entries(
  countries,
).reduce(
  (
    acc: {
      phoneCountryOptions: { name: string; display: string }[];
      addressCountryOptions: { name: string; display: string }[];
    },
    [countryKey, country],
  ) => {
    acc.phoneCountryOptions.push({
      name: countryKey,
      display: country.name,
    });
    acc.addressCountryOptions.push({
      name: country.name,
      display: country.name,
    });

    return acc;
  },
  { phoneCountryOptions: [], addressCountryOptions: [] },
);

export const SUB_FIELD_CONFIG: Record<string, Record<string, SubField>> = {
  [ADDRESS]: {
    street: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    suiteAptBldg: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    city: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    stateRegion: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    postalCode: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    country: {
      options: addressCountryOptions,
      type: SINGLE_OPTION,
    },
    latitude: {
      type: DECIMAL,
    },
    longitude: {
      type: DECIMAL,
    },
  },
  [COORDINATES]: {
    latitude: {
      type: DECIMAL,
    },
    longitude: {
      type: DECIMAL,
    },
  },
  [DATE_RANGE]: {
    from: {
      type: DATE,
    },
    to: {
      type: DATE,
    },
  },
  [DUE_DATE]: {
    from: {
      type: DATE,
    },
    to: {
      type: DATE,
    },
    overdue: {
      type: BOOLEAN,
    },
    complete: {
      type: BOOLEAN,
    },
    updatedAt: {
      type: DATE,
    },
  },
  [FULL_NAME]: {
    title: {
      options: [{ display: 'Mr.' }, { display: 'Mrs.' }, { display: '' }],
      type: SINGLE_OPTION,
    },
    first: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    middle: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    last: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
  },
  [PHONE_NUMBER]: {
    country: {
      type: SINGLE_OPTION,
      options: phoneCountryOptions,
    },
    extension: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
    number: {
      type: TEXT,
      typeOptions: {
        format: SINGLE_LINE_TEXT,
      },
    },
  },
};
