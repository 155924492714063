import React, { useCallback, useState } from 'react';
import { IconSettings } from '@tabler/icons-react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { SwitchButton, TextInput } from '@noloco/components';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { FIELDS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { IMPORT_BUTTON } from '@noloco/core/src/constants/elements';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath, VisibilityRules } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { FormFieldConfig, ImportButton } from '@noloco/core/src/models/View';
import { isImportableField } from '@noloco/core/src/utils/dataImport';
import { filterDefaultFields } from '@noloco/core/src/utils/defaultFields';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import Guide from '../../Guide';
import FieldStringPropEditor from '../../canvas/FieldStringPropEditor';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';
import StringPropEditor from '../../canvas/StringPropEditor';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import FieldsListEditor, { UpdateFieldsCallback } from './FieldsListEditor';

const LANG_KEY = 'elements.VIEW.buttons.importButton';

interface ImportButtonEditorProps {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  importButton: ImportButton;
  project: Project;
  rootDataType: DataType;
  updateProperty: UpdatePropertyCallback;
}

const ImportButtonEditor = ({
  dataType,
  debouncedUpdateProperty,
  elementPath,
  importButton,
  project,
  updateProperty,
  rootDataType,
}: ImportButtonEditorProps) => {
  const importEnabled = !dataType.readOnly;
  const {
    show: showImportButton = false,
    text: importButtonText,
    fields: importFields = [],
  } = importButton || {};
  const [isOpen, setIsOpen] = useState(false);
  const [editorTab, setEditorTab] = useEditorTabs('importButton', FIELDS);

  const onToggleEnabled = useCallback(
    (nextEnabled) => {
      if (importFields.length === 0 && nextEnabled) {
        updateProperty(['importButton'], {
          show: true,
          text: importButtonText,
          fields: dataType.fields
            .filter(filterDefaultFields)
            .filter(isImportableField)
            .slice(0, 10)
            .map((field: any) => ({
              name: field.name,
              label: field.display,
            })),
        });
      } else if (nextEnabled) {
        updateProperty(['importButton'], {
          show: true,
          text: importButtonText,
        });
      } else {
        updateProperty(['importButton', 'show'], nextEnabled);
      }
    },
    [dataType.fields, importButtonText, importFields.length, updateProperty],
  );

  const onFieldsChange = useCallback(
    (path, value) => updateProperty(['importButton', 'fields', ...path], value),
    [updateProperty],
  );

  const handleImportButtonClick = useCallback(
    () => (!showImportButton ? () => setIsOpen(true) : null),
    [showImportButton],
  );

  const onVisibilityRulesChange = useCallback(
    (path: ElementPath, value: VisibilityRules) =>
      updateProperty(['importButton', 'visibilityRules', ...path], value),
    [updateProperty],
  );

  return (
    <>
      <BuildModeSwitchSection
        button={
          showImportButton && (
            <IconSettings
              className="cursor-pointer text-slate-500 hover:text-slate-400"
              onClick={() => setIsOpen(true)}
              size={16}
            />
          )
        }
        disabled={!importEnabled}
        guide={
          <Guide
            hideIcon={true}
            href="https://guides.noloco.io/data-management/import-data"
          >
            {getText(LANG_KEY, 'guide')}
          </Guide>
        }
        label={getText(LANG_KEY, 'label')}
        onChange={onToggleEnabled}
        onClick={handleImportButtonClick}
        showOnlyButton={true}
        value={showImportButton}
      />
      {isOpen && showImportButton && importEnabled && (
        <RightPopoutMenu
          onClose={() => setIsOpen(false)}
          rootSelector=".noloco-project"
          source="buildMode"
          title={getText(LANG_KEY, 'label')}
          usePortal={true}
        >
          <div className="flex w-screen max-w-full flex-col">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={IMPORT_BUTTON}
              isNavEnabled={false}
              setEditorTab={setEditorTab}
            />
            {editorTab === FIELDS && (
              <>
                <div className="flex flex-col space-y-2 p-2">
                  <BuildModeInput label={getText(LANG_KEY, 'buttonText')}>
                    <StringPropEditor
                      // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
                      contained={true}
                      project={project}
                      onChange={(value: any) =>
                        debouncedUpdateProperty(['importButton', 'text'], value)
                      }
                      elementPath={elementPath}
                      placeholder=""
                      value={importButtonText}
                    />
                  </BuildModeInput>
                </div>
                <FieldsListEditor
                  dataType={dataType}
                  dataTypes={project.dataTypes}
                  fields={dataType.fields}
                  filter={(field: DataField) =>
                    isImportableField(field) ||
                    (isMultiField(field) && !field.readOnly)
                  }
                  getNewFieldConfig={(field: DataField) => ({
                    label: upperFirst(field.display),
                    hidden: !isImportableField(field),
                  })}
                  onFieldsChange={onFieldsChange}
                  permissionType="create"
                  value={importFields}
                >
                  {({
                    config,
                    field,
                    index,
                    updateFields,
                  }: {
                    config: FormFieldConfig;
                    field: DataField;
                    index: number;
                    updateFields: UpdateFieldsCallback;
                  }) => (
                    <div className="flex flex-col space-y-2">
                      <SwitchButton
                        options={[
                          ...(isImportableField(field)
                            ? [
                                {
                                  label: getText('elements.VIEW.new.field'),
                                  value: false,
                                },
                              ]
                            : []),
                          {
                            label: getText('elements.VIEW.new.hidden.label'),
                            value: true,
                          },
                        ]}
                        onChange={(value) =>
                          updateFields([index, 'hidden'], value)
                        }
                        value={config.hidden || false}
                      />
                      {config.hidden ? (
                        <div className="flex flex-col space-y-2">
                          <p className="text-slate-500">
                            {getText(LANG_KEY, 'hidden.description')}
                          </p>
                          <BuildModeInput
                            label={getText('elements.VIEW.new.value')}
                          >
                            <FieldStringPropEditor
                              // @ts-expect-error TS(2322): Type '{ contained: boolean; dataType: any; field: ... Remove this comment to see the full error message
                              contained={true}
                              dataType={dataType}
                              field={field}
                              project={project}
                              onChange={(value: any) =>
                                updateFields([index, 'value'], value)
                              }
                              value={get(config, 'value')}
                              elementPath={elementPath}
                              placeholder=""
                            />
                          </BuildModeInput>
                        </div>
                      ) : (
                        <div className="flex flex-col space-y-2">
                          <BuildModeInput
                            label={getText('elements.VIEW.fields.label')}
                          >
                            <TextInput
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                updateFields(
                                  [index, 'label'],
                                  event.target.value,
                                )
                              }
                              value={config.label}
                              placeholder={field.display}
                            />
                          </BuildModeInput>
                        </div>
                      )}
                    </div>
                  )}
                </FieldsListEditor>
              </>
            )}
            {editorTab === VISIBILITY && (
              <VisibilityRulesEditor
                dataType={rootDataType}
                elementPath={elementPath}
                onChange={onVisibilityRulesChange}
                project={project}
                visibilityRules={importButton.visibilityRules}
              />
            )}
          </div>
        </RightPopoutMenu>
      )}
    </>
  );
};

export default ImportButtonEditor;
