import React, { useCallback, useMemo, useState } from 'react';
import set from 'lodash/fp/set';
import { FormattedNumberInput, SelectInput, Switch } from '@noloco/components';
import {
  CURSOR,
  Endpoint,
  OFFSET,
  PAGE,
  PATH,
} from '@noloco/core/src/constants/apis';
import { toPlaceholder } from '@noloco/core/src/utils/apis';
import { findStringPaths } from '@noloco/core/src/utils/dataSchema';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.api.endpoints.pagination';

interface Props {
  data: any;
  endpoint: Endpoint;
  onUpdateEndpoint: (endpoint: Endpoint) => void;
}

const ApiEndpointPaginationEditor = ({
  data,
  endpoint,
  onUpdateEndpoint,
}: Props) => {
  const [paginate, setPaginate] = useState(!!endpoint.pagination);
  const onTogglePaginate = useCallback(
    (value: boolean) => {
      setPaginate(value);

      if (!value) {
        onUpdateEndpoint(set('pagination', null, endpoint));
      }
    },
    [endpoint, onUpdateEndpoint],
  );

  const paginationOptions = useMemo(
    () =>
      [CURSOR, PAGE, OFFSET, PATH].map((paginationMethod) => ({
        value: paginationMethod,
        label: getText(LANG_KEY, 'methods', paginationMethod, 'label'),
        help: (
          <p className="text-gray-400">
            {getText(LANG_KEY, 'methods', paginationMethod, 'help')}
          </p>
        ),
      })),
    [],
  );

  const [showCursorPathOptions, setShowCursorPathOptions] = useState(
    !!endpoint.cursorPath,
  );

  const pathToStringOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    return findStringPaths(data).map((stringPath: string[]) => ({
      value: stringPath,
      label: <span className="font-mono">{stringPath.join(' > ')}</span>,
    }));
  }, [data]);

  return (
    <div className="mb-auto flex w-full max-w-2xl flex-col overflow-y-auto px-4 py-16 text-sm text-white">
      <div className="mt-6 flex w-full">
        <div>
          <label className="mb-2 font-medium leading-5">
            {getText(LANG_KEY, 'label')}
          </label>
          <p className="mb-2 text-sm text-gray-400">
            {getText(LANG_KEY, 'help')}
          </p>
        </div>
        <div className="my-auto ml-auto">
          <Switch onChange={onTogglePaginate} value={paginate} />
        </div>
      </div>
      {paginate && (
        <div className="mt-2 space-y-4">
          <div className="flex">
            <p>{getText(LANG_KEY, 'methods.label')}</p>
            <SelectInput
              className="ml-auto max-w-md"
              placeholder={getText(LANG_KEY, 'methods.placeholder')}
              options={paginationOptions}
              value={endpoint.pagination}
              onChange={(value: any) =>
                onUpdateEndpoint(set('pagination', value, endpoint))
              }
            />
          </div>
          {endpoint.pagination === CURSOR && (
            <div className="space-y-4">
              <p className="text-gray-400">
                {getText(
                  { placeholder: toPlaceholder(CURSOR) },
                  LANG_KEY,
                  'methods',
                  CURSOR,
                  'instructions',
                )}
              </p>
              <div className="flex">
                <p>{getText(LANG_KEY, 'cursorPath.switch')}</p>
                <div className="ml-auto">
                  <Switch
                    className="ml-auto"
                    onChange={(useIdAsCursor: boolean) => {
                      setShowCursorPathOptions(!useIdAsCursor);

                      if (useIdAsCursor) {
                        onUpdateEndpoint(set('cursorPath', null, endpoint));
                      }
                    }}
                    value={!showCursorPathOptions}
                  />
                </div>
              </div>
              {showCursorPathOptions && (
                <div className="flex">
                  <p>{getText(LANG_KEY, 'cursorPath.label')}</p>
                  <div className="ml-auto">
                    <SelectInput
                      placeholder={getText(LANG_KEY, 'cursorPath.placeholder')}
                      value={endpoint.cursorPath}
                      onChange={(value: any) =>
                        onUpdateEndpoint(set('cursorPath', value, endpoint))
                      }
                      options={pathToStringOptions}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {endpoint.pagination === PAGE && (
            <div className="space-y-4">
              <p className="text-gray-400">
                {getText(
                  { placeholder: toPlaceholder(PAGE) },
                  LANG_KEY,
                  'methods',
                  PAGE,
                  'instructions',
                )}
              </p>
              <div className="flex">
                <p className="w-full">What is the number of the first page?</p>
                <FormattedNumberInput
                  className="ml-auto"
                  onChange={(value: number) => {
                    onUpdateEndpoint(set('firstPage', value, endpoint));
                  }}
                  value={endpoint.firstPage}
                  placeholder={0}
                />
              </div>
            </div>
          )}
          {endpoint.pagination === OFFSET && (
            <div className="space-y-4">
              <p className="text-gray-400">
                {getText(
                  { placeholder: toPlaceholder(OFFSET) },
                  LANG_KEY,
                  'methods',
                  OFFSET,
                  'instructions',
                )}
              </p>
            </div>
          )}
          {endpoint.pagination === PATH && (
            <div className="space-y-4">
              <p className="text-gray-400">
                {getText(LANG_KEY, 'methods', PATH, 'instructions')}
              </p>
              <div className="flex">
                <p>{getText(LANG_KEY, 'pathPath.label')}</p>
                <div className="ml-auto">
                  <SelectInput
                    placeholder={getText(LANG_KEY, 'pathPath.placeholder')}
                    value={endpoint.pathPath}
                    onChange={(value: any) =>
                      onUpdateEndpoint(set('pathPath', value, endpoint))
                    }
                    options={pathToStringOptions}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApiEndpointPaginationEditor;
