import { DataField } from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { getFieldReverseApiName } from './fields';

const getActionButtonFormFieldConfig = (
  fieldName: string | undefined,
  dataType: DataType,
  dataTypes: DataTypes,
): {
  field?: string;
  actionDataType?: DataType;
  selectedField?: DataField;
  actionField?: DataField;
  mutationField?: DataField;
} => {
  const field = dataType.fields.getByName(fieldName || 'id');

  if (!field || field.name === 'id') {
    return {
      actionDataType: dataType,
      selectedField: field,
    };
  }

  const relatedDataType = dataTypes.getByName(field.type);

  if (relatedDataType) {
    if (field.relatedField) {
      const relatedField = relatedDataType.fields.getByName(
        field.relatedField.name,
      );

      return {
        field: fieldName,
        actionDataType: relatedDataType,
        selectedField: field,
        mutationField: relatedField,
      };
    } else {
      const reverseFieldName = getFieldReverseApiName(field, relatedDataType);
      const relatedField = relatedDataType.fields.getByName(
        reverseFieldName as string,
      );

      return {
        field: fieldName,
        actionDataType: relatedDataType,
        selectedField: field,
        mutationField: relatedField,
      };
    }
  }

  return {};
};

export default getActionButtonFormFieldConfig;
