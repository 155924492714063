import React from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import { Button } from '@noloco/components';
import { ElementPath } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';
import { BaseConditionValueEditorProps } from '../../models/BaseConditionValueEditorProps';
import ConditionEditor from './ConditionEditor';

const NEW_CONDITION = {
  field: null,
  operator: null,
  value: null,
};

interface Props {
  className?: string;
  contained?: boolean;
  dataType: any;
  fieldOptions: any;
  project: any;
  elementPath?: ElementPath;
  rules: any;
  inline?: boolean;
  updateConditions: (...args: any[]) => any;
  condition?: {
    name?: string;
    conditions: {
      name?: any;
      operator?: string;
      value?: any;
    }[];
  };
  FieldItem?: React.ForwardRefExoticComponent<
    { value: any } & React.RefAttributes<any>
  >;
  ValueInput?: React.ExoticComponent<BaseConditionValueEditorProps>;
}

const ConditionsEditor = ({
  className,
  contained = true,
  inline = false,
  dataType,
  fieldOptions,
  project,
  elementPath,
  updateConditions,
  rules,
  FieldItem,
  ValueInput,
}: Props) => {
  const updateCondition =
    (groupIndex: number, index: number) =>
    (propertyPath: ElementPath, value: any) =>
      updateConditions([groupIndex, index, ...propertyPath], value);

  const removeCondition = (groupIndex: any, conditionIndex: any) => {
    const conditionsInGroup = rules[groupIndex].filter(
      (__: any, index: any) => index !== conditionIndex,
    );

    if (conditionsInGroup.length > 0) {
      updateConditions([], set([groupIndex], conditionsInGroup, rules));
    } else {
      updateConditions(
        [],
        rules.filter((__: any, index: any) => index !== groupIndex),
      );
    }
  };

  const addAndCondition = (groupIndex: any) =>
    updateConditions([groupIndex], [...rules[groupIndex], NEW_CONDITION]);

  const addOrCondition = () =>
    updateConditions([], [...rules, [NEW_CONDITION]]);

  return (
    <div
      className={classNames('flex w-full flex-col justify-center', className)}
    >
      {rules.map((conditionGroup: any, groupIndex: any) => (
        <>
          {conditionGroup.map((conditionX: any, index: any) => (
            <div className="flex flex-col" key={index}>
              <div
                className={classNames(
                  'border-brand-light relative rounded-lg border border-dotted',
                  inline ? 'mr-2 p-2' : 'p-4',
                )}
              >
                <button
                  onClick={() => removeCondition(groupIndex, index)}
                  className="bg-brand-light absolute right-0 top-0 -mr-2 -mt-2 flex items-center justify-center rounded-full p-1 text-white hover:bg-cyan-500"
                >
                  <IconX size={12} />
                </button>
                <ConditionEditor
                  contained={contained}
                  dataType={dataType}
                  inline={inline}
                  fieldOptions={fieldOptions}
                  updateCondition={updateCondition(groupIndex, index)}
                  elementPath={elementPath}
                  condition={conditionX}
                  project={project}
                  FieldItem={FieldItem}
                  ValueInput={ValueInput}
                />
              </div>
              <div className="border-brand-light ml-4 h-4 w-1 border-l border-dotted" />
            </div>
          ))}
          <div className="flex items-end space-x-2">
            <Button
              onClick={() => addAndCondition(groupIndex)}
              size="sm"
              variant="secondary"
            >
              {getText('conditionEditor.and')}
            </Button>
            {groupIndex === rules.length - 1 && (
              <Button
                className="mt-px"
                onClick={addOrCondition}
                variant="secondary"
                size="sm"
              >
                <span className="block px-1">
                  {getText('conditionEditor.or')}
                </span>
              </Button>
            )}
          </div>
          {groupIndex < rules.length - 1 && (
            <div className="my-2 flex h-8 w-full">
              <div className="border-brand-light h-4 w-full border-b border-dotted" />
              <div className="mx-4 flex items-center">
                <span className="text-sm uppercase">
                  {getText('conditionEditor.or')}
                </span>
              </div>
              <div className="border-brand-light h-4 w-full border-b border-dotted" />
            </div>
          )}
        </>
      ))}
      {rules.length === 0 && (
        <Button
          className="mr-auto"
          onClick={addOrCondition}
          variant="secondary"
        >
          <span className="block px-2">{getText('conditionEditor.new')}</span>
        </Button>
      )}
    </div>
  );
};

export default ConditionsEditor;
