import React, { forwardRef, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import BuildModeSection from '@noloco/core/src/components/buildMode/BuildModeSection';
import BuildModeActionButtonEditor from '@noloco/core/src/components/buildMode/actionButton/BuildModeActionButtonEditor';
import { WithDropable } from '@noloco/core/src/components/withDnD';
import {
  ActionButtonExecutionType,
  COLLECTION,
  RECORD,
} from '@noloco/core/src/constants/actionButtons';
import { ActionType } from '@noloco/core/src/constants/actionTypes';
import { RECORD_ACTION_BUTTONS } from '@noloco/core/src/constants/buildMode';
import { ACTION_BUTTON } from '@noloco/core/src/constants/draggableItemTypes';
import Icon from '@noloco/core/src/elements/Icon';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ActionButton, ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import useItemListEditor from '@noloco/core/src/utils/hooks/useItemListEditor';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import Guide from '../../Guide';

const LANG_KEY = 'elements.VIEW';

export interface ActionButtonsEditorBodyProps {
  actionButtons: ActionButton[];
  actionOptions?: ActionType[];
  availableExecutions?: ActionButtonExecutionType[];
  className?: string;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  defaultExecution?: ActionButtonExecutionType;
  display?: 'collection' | 'record';
  elementPath: ElementPath;
  label: string;
  project: Project;
  sectionPropPath?: ElementPath;
  updateProperty: UpdatePropertyCallback;
}

const ActionButtonsEditorBody = forwardRef(
  (
    {
      actionButtons,
      actionOptions,
      availableExecutions,
      dataType,
      debouncedUpdateProperty,
      defaultExecution,
      display,
      elementPath,
      project,
      sectionPropPath,
      updateProperty,
    }: ActionButtonsEditorBodyProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    const [popoutOpen, setPopoutOpen] = useState(false);

    const createNewActionButton = () => ({
      id: shortId.generate(),
      buttonText: getText(
        { index: actionButtons.length + 1 },
        LANG_KEY,
        'actionButtons',
        'defaultButtonText',
      ),
      title: '',
      description: '',
      actions: [],
      display: display
        ? {
            collection: display === COLLECTION,
            record: display === RECORD,
          }
        : undefined,
    });

    const {
      formatItem,
      draftItems: draftActionButtons,
      findItem,
      onSaveOrder,
      onRemoveItem: onRemoveActionButton,
      onCloneItem: onCloneActionButton,
      onAddNewItem: onAddNewActionButton,
      onDebounceUpdateItem: onDebounceUpdateActionButton,
      onUpdateItem: onUpdateActionButton,
    } = useItemListEditor(
      actionButtons,
      updateProperty,
      debouncedUpdateProperty,
      createNewActionButton,
      ['actionButtons'],
      false,
    );

    const listOptions = useMemo(
      () =>
        draftActionButtons.map((actionButton, index) => ({
          icon: actionButton.icon && (
            <Icon className="h-4 w-4" icon={actionButton.icon} />
          ),
          label: actionButton.buttonText,
          value: index,
        })),
      [draftActionButtons],
    );

    const actionButtonsEditor = useMemo(
      () =>
        draftActionButtons.map((actionButton: any, index: any) => (
          <BuildModeActionButtonEditor
            actionButton={actionButton}
            actionOptions={actionOptions}
            activeListItem={activeListItem}
            availableExecutions={availableExecutions}
            dataType={dataType}
            debouncedUpdateProperty={debouncedUpdateProperty}
            defaultExecution={defaultExecution}
            display={display}
            draggable={true}
            elementPath={elementPath}
            findItem={findItem}
            index={index}
            item={formatItem(actionButton)}
            key={actionButton.id}
            listOptions={listOptions}
            onClone={onCloneActionButton}
            onDebounceUpdateActionButton={onDebounceUpdateActionButton}
            onRemove={onRemoveActionButton}
            onSaveOrder={onSaveOrder}
            onUpdateActionButton={onUpdateActionButton}
            popoutOpen={popoutOpen}
            project={project}
            sectionPropPath={sectionPropPath}
            setActiveListItem={setActiveListItem}
            setPopoutOpen={setPopoutOpen}
            updateProperty={updateProperty}
          />
        )),
      [
        actionOptions,
        activeListItem,
        availableExecutions,
        dataType,
        debouncedUpdateProperty,
        defaultExecution,
        display,
        draftActionButtons,
        elementPath,
        findItem,
        formatItem,
        listOptions,
        onCloneActionButton,
        onDebounceUpdateActionButton,
        onRemoveActionButton,
        onSaveOrder,
        onUpdateActionButton,
        popoutOpen,
        project,
        sectionPropPath,
        updateProperty,
      ],
    );

    return (
      <>
        <BuildModeSection
          id={RECORD_ACTION_BUTTONS}
          className="border-t p-2"
          emptyState={getText(LANG_KEY, 'actionButtons.noActionsButtons')}
          endComponent={
            <div
              className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-gray-700 hover:opacity-100"
              onClick={onAddNewActionButton}
            >
              <IconPlus size={16} />
            </div>
          }
          guide={
            <Guide
              hideIcon={true}
              href="https://guides.noloco.io/actions/action-buttons"
            >
              {getText(LANG_KEY, 'actionButtons.guide')}
            </Guide>
          }
          showEmptyState={actionButtons.length === 0}
          title={getText(LANG_KEY, 'actionButtons.label.default')}
        >
          <div className="space-y-2 p-2" ref={ref}>
            {actionButtonsEditor}
          </div>
        </BuildModeSection>
      </>
    );
  },
);
ActionButtonsEditorBody.displayName = 'ActionButtonsEditorBody';

export default WithDropable(ActionButtonsEditorBody, ACTION_BUTTON);
