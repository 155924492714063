import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  IconCopy,
  IconDotsVertical,
  IconLock,
  IconMail,
  IconMailForward,
  IconPencil,
  IconRefresh,
  IconShield,
  IconUserCheck,
  IconUserOff,
  IconUserX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Button,
  ConfirmationButton,
  Loader,
  Popover,
  TextInput,
  Tooltip,
} from '@noloco/components';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import Avatar from '@noloco/core/src/components/Avatar';
import { DELETE } from '@noloco/core/src/constants/actionTypes';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import RelatedCellItem from '@noloco/core/src/elements/sections/collections/RelatedCellItem';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ID } from '@noloco/core/src/models/Element';
import { RecordEdge } from '@noloco/core/src/models/Record';
import { User } from '@noloco/core/src/models/User';
import {
  INVITE_PENDING_USERS_QUERY,
  getMutationQueryString,
} from '@noloco/core/src/queries/project';
import {
  dataTypesSelector,
  projectLiveSelector,
  projectNameSelector,
  projectUsersSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  addDataItemToCollectionCache,
  removeDataItemFromCollectionCache,
} from '@noloco/core/src/utils/apolloCache';
import { lookupOfArray } from '@noloco/core/src/utils/arrays';
import { copyTextToClipboard } from '@noloco/core/src/utils/copyTextToClipboard';
import {
  useGraphQlErrorAlert,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getFullName } from '@noloco/core/src/utils/user';
import {
  DEACTIVATE_USER,
  REACTIVATE_USER,
  USERS_QUERY,
} from '../../queries/userTable';
import useInviteUser from '../../utils/hooks/useInviteUser';
import Guide from '../Guide';
import UserRolesSettings from '../settings/UserRolesSettings';
import SimpleTable from '../table/SimpleTable';
import SimpleTableBody from '../table/SimpleTableBody';
import SimpleTableCell from '../table/SimpleTableCell';
import SimpleTableFooter from '../table/SimpleTableFooter';
import SimpleTableHead from '../table/SimpleTableHead';
import SimpleTableHeadCell from '../table/SimpleTableHeadCell';
import SimpleTableRow from '../table/SimpleTableRow';
import EditUserModal, { NEW_ID } from './EditUserModal';
import UserActiveCell from './UserActiveCell';
import UserDeactivationModal from './UserDeactivationModal';

const LazyUserListsSettings = lazy(
  () => import('../settings/UserListsSettings'),
);

const LANG_KEY = 'userTable';

interface CellProps {
  children: any;
  className?: string;
}

const ActionItem = ({
  children,
  className,
  loading,
  onClick,
  icon: Icon,
}: CellProps & {
  icon: any;
  onClick?: () => void;
  loading?: boolean;
}) => (
  <button
    className={classNames(
      className,
      'flex items-center px-3 py-3 text-left hover:bg-gray-100',
    )}
    disabled={loading}
    onClick={onClick}
  >
    {loading ? (
      <Loader size="xs" className="mr-3" />
    ) : (
      <Icon size={16} className="mr-3 opacity-75" />
    )}
    {children}
  </button>
);

const UsersTable = () => {
  const {
    query: { _q, _after, _before },
    pushQueryParams,
    location,
  }: any = useRouter();
  const { user: currentUser } = useAuth();
  const { isBuilder } = useIsBuilder();
  const projectLive = useSelector(projectLiveSelector);
  const projectName = useSelector(projectNameSelector);
  const projectUsers = useSelector(projectUsersSelector);

  const successAlert = useInfoAlert();
  const errorAlert = useGraphQlErrorAlert();
  const { recoverPassword } = useAuth();

  const dataTypes = useSelector(dataTypesSelector);
  const userType = useMemo(
    () => dataTypes.getByName(USER) as DataType,
    [dataTypes],
  );

  const buildersMap = useMemo(
    () => lookupOfArray(projectUsers ?? [], 'user.email'),
    [projectUsers],
  );

  const hasExternalSource = useMemo(
    () => dataTypes.some((dataType) => dataType.source.type !== INTERNAL),
    [dataTypes],
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuOpenChange = useCallback(
    (isOpen) => {
      setIsMenuOpen(isOpen);
    },
    [setIsMenuOpen],
  );

  const context = useMemo(
    () => ({
      projectQuery: true,
      projectName,
      authQuery: true,
    }),
    [projectName],
  );

  const [onInviteUser, { loading: inviteLoading }] = useInviteUser({
    authQuery: true,
  });

  const setPaginationQuery = useCallback(
    ({ after, before }) =>
      pushQueryParams({
        _before: before,
        _after: after,
      }),
    [pushQueryParams],
  );

  const debounceUpdateSearch = useMemo(
    () => debounce((nextQ) => pushQueryParams({ _q: nextQ }), 400),
    [pushQueryParams],
  );

  const where = useMemo(() => {
    const w: Record<string, any> = {
      email: { not: { equals: null } },
    };

    if (_q) {
      w.OR = ['firstName', 'lastName', 'email'].map((fieldName) => ({
        [fieldName]: { contains: _q },
      }));
    }

    return w;
  }, [_q]);

  const variables = useMemo(
    () => ({ where, after: _after, before: _before }),
    [_after, _before, where],
  );

  const deleteQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(DELETE, userType.apiName, userType.fields, {
        id: true,
      })}
    `,
    [userType.apiName, userType.fields],
  );

  const [deleteDataItem] = useMutation(deleteQueryString, { context });

  const { data, loading, client } = useCacheQuery(USERS_QUERY, {
    context,
    variables,
  });

  const {
    edges = [],
    totalCount,
    pageInfo = {},
  } = useMemo(() => get(data, 'userCollection', {}), [data]);

  const users = useMemo(
    () => edges.map((edge: RecordEdge) => edge.node as User),
    [edges],
  );

  const [invitePendingUsers] = useMutation(
    gql`
      ${INVITE_PENDING_USERS_QUERY}
    `,
    { context },
  );

  const infoAlert = useInfoAlert();

  const onInvitePendingUsers = useCallback(
    () =>
      invitePendingUsers().then(({ data }) => {
        const count = data.invitePendingUsers.totalCount;
        infoAlert(
          getText('data.dataTypes.inviteReminder.success.title'),
          getText(
            {
              context: count,
            },
            'data.dataTypes.inviteReminder.success.message',
          ),
        );
      }),
    [infoAlert, invitePendingUsers],
  );

  const onDelete = useCallback(
    (user: User) =>
      deleteDataItem({ variables: { id: user.id } })
        .then(() => {
          removeDataItemFromCollectionCache(
            user.id,
            client,
            USERS_QUERY,
            USER,
            variables,
          );
          infoAlert(
            getText({ email: user.email }, LANG_KEY, 'actions.delete.success'),
          );
        })
        .catch((error) => {
          errorAlert(
            getText({ email: user.email }, LANG_KEY, 'actions.delete.error'),
            error,
          );
        }),
    [deleteDataItem, client, variables, infoAlert, errorAlert],
  );

  const onUserAdded = useCallback(
    (userId: ID) =>
      addDataItemToCollectionCache(
        userId,
        client,
        USERS_QUERY,
        USER,
        variables,
      ),
    [client, variables],
  );

  const [deactivateUser] = useMutation(
    gql`
      ${DEACTIVATE_USER}
    `,
    { context },
  );
  const [reactivateUser] = useMutation(
    gql`
      ${REACTIVATE_USER}
    `,
    { context },
  );

  const onDeactivate = useCallback(
    (user: User) => deactivateUser({ variables: { userId: user.id } }),
    [deactivateUser],
  );
  const onReactivate = useCallback(
    (user: User) => reactivateUser({ variables: { userId: user.id } }),
    [reactivateUser],
  );

  const copyInvitationUrlToClipboard = useCallback(
    (user: User) => {
      const invitationUrl = `https://${document.location.host}/join?invitationToken=${user.invitationToken}`;
      copyTextToClipboard(invitationUrl);
      successAlert(getText(LANG_KEY, 'actions.invitationUrlCopied'));
    },
    [successAlert],
  );

  const sendPasswordResetEmail = useCallback(
    (user: User) =>
      recoverPassword(user.email).then(() => {
        successAlert(
          getText({ email: user.email }, LANG_KEY, 'actions.passwordResetSent'),
        );
      }),
    [recoverPassword, successAlert],
  );

  return (
    <div className="flex h-full w-full flex-col overflow-hidden bg-slate-800 pt-10">
      <div className="flex items-center px-8 sm:px-6 md:flex-wrap">
        <div className="mr-4 flex-auto">
          <Guide
            className="mb-3 text-sm"
            href="https://guides.noloco.io/users-and-permissions/user-table"
          >
            {getText(LANG_KEY, 'description')}
          </Guide>
          <div className="flex items-center justify-start space-x-4">
            <div className="w-full max-w-xs">
              <TextInput
                value={_q}
                placeholder={getText(LANG_KEY, 'search')}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) =>
                  debounceUpdateSearch(value)
                }
              />
            </div>
            {loading && <Loader size="sm" className="text-white" />}
          </div>
        </div>
        <div className="flex items-center space-x-4 md:flex-none">
          {isBuilder && (
            <Tooltip
              content={getText(
                'data.dataTypes.inviteReminder.tooltip',
                projectLive ? 'live' : 'test',
              )}
              placement="bottom"
              bg="white"
            >
              <span>
                <ConfirmationButton
                  className="mt-2"
                  confirmText={getText(
                    'data.dataTypes.inviteReminder.confirmation.button',
                  )}
                  description={getText(
                    'data.dataTypes.inviteReminder.confirmation.description',
                  )}
                  disabled={!projectLive}
                  icon={<IconMailForward size={16} />}
                  title={getText(
                    'data.dataTypes.inviteReminder.confirmation.title',
                  )}
                  variant={SECONDARY}
                  onConfirm={onInvitePendingUsers}
                >
                  {getText('data.dataTypes.inviteReminder.button')}
                </ConfirmationButton>
              </span>
            </Tooltip>
          )}
          {isBuilder && (
            <Popover
              className="overflow-hidden bg-white"
              closeOnOutsideClick={true}
              content={
                <div className="flex flex-col p-2">
                  <Link
                    className="flex items-center rounded-lg px-2 py-2 text-left text-xs hover:bg-gray-100"
                    to={`/_/users/roles${location.search}`}
                  >
                    <IconShield
                      size={16}
                      className="mr-4 flex-shrink-0 opacity-75"
                    />
                    {getText(LANG_KEY, 'manageRoles')}
                  </Link>
                  {hasExternalSource && (
                    <Tooltip
                      bg="white"
                      content={getText('settings.signUp.userLists.explainer')}
                      offset={[0, 20]}
                      placement="left"
                    >
                      <Link
                        to="/_/users/sync"
                        className="flex items-center rounded-lg px-2 py-2 text-left text-xs hover:bg-gray-100"
                      >
                        <IconRefresh
                          size={16}
                          className="mr-4 flex-shrink-0 opacity-75"
                        />
                        {getText('settings.signUp.userLists.sync')}
                      </Link>
                    </Tooltip>
                  )}
                </div>
              }
              isOpen={isMenuOpen}
              onOpenChange={onMenuOpenChange}
              p={0}
              placement="bottom-start"
              rounded="lg"
              shadow="lg"
              showArrow={false}
              trigger="none"
            >
              <Button
                className="mt-2"
                onClick={() => setIsMenuOpen(true)}
                variant={SECONDARY}
              >
                <IconDotsVertical size={16} />
              </Button>
            </Popover>
          )}
          <Link
            to={`/_/users/manage/${NEW_ID}${location.search}`}
            className="mt-2 inline-block"
          >
            <Button>{getText(LANG_KEY, 'add')}</Button>
          </Link>
        </div>
      </div>
      <SimpleTable className="mt-4">
        <SimpleTableHead>
          <SimpleTableHeadCell className="pl-8">
            {getText(LANG_KEY, 'fields.name')}
          </SimpleTableHeadCell>
          <SimpleTableHeadCell>
            {getText(LANG_KEY, 'fields.email')}
          </SimpleTableHeadCell>
          <SimpleTableHeadCell>
            {getText(LANG_KEY, 'fields.isActive')}
          </SimpleTableHeadCell>
          <SimpleTableHeadCell>
            {getText(LANG_KEY, 'fields.role')}
          </SimpleTableHeadCell>
          <SimpleTableHeadCell className="pr-8">
            <span className="sr-only">{getText(LANG_KEY, 'email')}</span>
          </SimpleTableHeadCell>
        </SimpleTableHead>
        <SimpleTableBody>
          {users.map((user: User) => (
            <SimpleTableRow key={user.id}>
              <SimpleTableCell className="pl-8">
                <div className="flex max-w-xl items-center space-x-4 md:max-w-xs">
                  <Avatar size={8} user={user} />
                  <span className="truncate">{getFullName(user)}</span>
                </div>
              </SimpleTableCell>
              <SimpleTableCell>{user.email}</SimpleTableCell>
              <SimpleTableCell>
                <UserActiveCell user={user} builders={buildersMap} />
              </SimpleTableCell>
              <SimpleTableCell>
                <RelatedCellItem
                  dataTypes={dataTypes}
                  field={userType.fields.getByName('role')}
                  value={get(user, 'role')}
                />
              </SimpleTableCell>
              <SimpleTableCell>
                <div className="hidden group-hover:flex">
                  <Popover
                    closeOnClick={true}
                    content={
                      <div className="flex w-72 flex-col text-left text-sm">
                        <Link
                          className="flex items-center px-3 py-3 text-left hover:bg-gray-100"
                          to={`/_/users/manage/${user.id}${location.search}`}
                        >
                          <IconPencil size={16} className="mr-3 opacity-75" />
                          <span>{getText(LANG_KEY, 'actions.edit')}</span>
                        </Link>
                        {!user.lastActiveAt && (
                          <ActionItem
                            icon={IconMail}
                            loading={inviteLoading}
                            onClick={() => onInviteUser(user.email)}
                          >
                            <span>{getText(LANG_KEY, 'actions.invite')}</span>
                          </ActionItem>
                        )}
                        {user.invitationToken && (
                          <ActionItem
                            icon={IconCopy}
                            onClick={() => copyInvitationUrlToClipboard(user)}
                          >
                            <span>
                              {getText(LANG_KEY, 'actions.copyInvite')}
                            </span>
                          </ActionItem>
                        )}
                        {!user.lastActiveAt && (
                          <ActionItem
                            icon={IconLock}
                            onClick={() => sendPasswordResetEmail(user)}
                          >
                            <span>
                              {getText(LANG_KEY, 'actions.passwordReset')}
                            </span>
                          </ActionItem>
                        )}
                        {currentUser!.id !== user.id && !user.deactivatedAt && (
                          <Link
                            className="flex items-center px-3 py-3 text-left hover:bg-gray-100"
                            to={`/_/users/deactivate/${user.id}${location.search}`}
                          >
                            <IconUserOff
                              size={16}
                              className="mr-3 opacity-75"
                            />
                            <span>
                              {getText(LANG_KEY, 'actions.deactivate.label')}
                            </span>
                          </Link>
                        )}
                        {currentUser!.id !== user.id && user.deactivatedAt && (
                          <Link
                            className="flex items-center px-3 py-3 text-left hover:bg-gray-100"
                            to={`/_/users/reactivate/${user.id}${location.search}`}
                          >
                            <IconUserCheck
                              size={16}
                              className="mr-3 opacity-75"
                            />
                            <span>
                              {getText(LANG_KEY, 'actions.reactivate.label')}
                            </span>
                          </Link>
                        )}
                        {currentUser!.id !== user.id && (
                          <Link
                            className="flex items-center px-3 py-3 text-left hover:bg-gray-100"
                            to={`/_/users/delete/${user.id}${location.search}`}
                          >
                            <IconUserX size={16} className="mr-3 opacity-75" />
                            <span>
                              {getText(LANG_KEY, 'actions.delete.label')}
                            </span>
                          </Link>
                        )}
                      </div>
                    }
                    className="overflow-hidden bg-white"
                    p={0}
                    rounded="lg"
                    placement="bottom-end"
                    shadow="lg"
                    showArrow={false}
                  >
                    <button className="flex">
                      <IconPencil size={16} />
                    </button>
                  </Popover>
                </div>
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
        </SimpleTableBody>
      </SimpleTable>
      <SimpleTableFooter
        className="border-brand-darkest border-t px-6"
        count={users.length}
        pageInfo={pageInfo}
        setPaginationQuery={setPaginationQuery}
        totalCount={totalCount}
      />
      <Switch>
        <Route path="/_/users/deactivate/:userId?">
          <UserDeactivationModal mode="deactivate" onClick={onDeactivate} />
        </Route>
        <Route path="/_/users/reactivate/:userId?">
          <UserDeactivationModal mode="reactivate" onClick={onReactivate} />
        </Route>
        <Route path="/_/users/delete/:userId?">
          <UserDeactivationModal mode="delete" onClick={onDelete} />
        </Route>
        <Route path="/_/users/manage/:userId?">
          <EditUserModal userType={userType} onUserCreated={onUserAdded} />
        </Route>
        <Route path="/_/users/roles">
          <UserRolesSettings />
        </Route>
        <Route path="/_/users/sync">
          <Suspense fallback={<Loader />}>
            <LazyUserListsSettings />
          </Suspense>
        </Route>
      </Switch>
    </div>
  );
};

export default UsersTable;
