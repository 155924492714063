import React from 'react';
import set from 'lodash/fp/set';
import { Endpoint } from '@noloco/core/src/constants/apis';
import { KeyValue } from '@noloco/core/src/constants/keyValue';
import { getText } from '@noloco/core/src/utils/lang';
import KeyValueEditor from '../../editor/KeyValueEditor';

const LANG_KEY = 'data.api.endpoints.parameters';

interface Props {
  endpoint: Endpoint;
  onUpdateEndpoint: (endpoint: Endpoint) => void;
}

const ApiEndpointParametersEditor = ({ endpoint, onUpdateEndpoint }: Props) => (
  <div className="mb-auto flex w-full max-w-2xl flex-col overflow-y-auto px-4 py-16 text-sm text-white">
    <label className="mb-2 mt-6 font-medium leading-5">
      {getText(LANG_KEY, 'label')}
    </label>
    <p className="mb-2 text-sm text-gray-400">{getText(LANG_KEY, 'help')}</p>
    <KeyValueEditor
      pairs={endpoint.parameters}
      onUpdatePairs={(pairs: KeyValue[]) =>
        onUpdateEndpoint(set('parameters', pairs, endpoint))
      }
    />
  </div>
);

export default ApiEndpointParametersEditor;
