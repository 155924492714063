import React from 'react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

interface Props {
  className?: string;
  children: any;
  surface?: Surface;
}

const SimpleTableRow = ({ className, children, surface = DARK }: Props) => (
  <tr
    className={classNames(className, 'group', {
      'hover:bg-slate-700': surface === DARK,
      'hover:bg-pink-50': surface === LIGHT,
    })}
  >
    {children}
  </tr>
);

export default SimpleTableRow;
