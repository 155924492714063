import { useCallback, useMemo, useState } from 'react';
import {
  IconCornerUpLeft,
  IconCornerUpRight,
  IconEye,
  IconEyeOff,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import last from 'lodash/last';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loader } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import {
  UpdateProjectCallback,
  useRemoveSelected,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import useToggleEditorMode from '@noloco/ui/src/utils/hooks/useToggleEditorMode';
import { Item, ItemTypes } from '../../constants/buildMode';
import { CMD, KEY_E, KEY_Z, SHIFT } from '../../constants/shortcuts';
import KeyboardShortcutTooltip from '../../elements/sections/view/KeyboardShortcutTooltip';
import { Project } from '../../models/Project';
import { setGhostUserId } from '../../reducers/data';
import { redo, undo } from '../../reducers/project';
import { ghostUserIdSelector } from '../../selectors/dataSelectors';
import {
  dataTypesSelector,
  projectNameSelector,
  redoStackSelector,
  undoStackSelector,
} from '../../selectors/projectSelectors';
import { useInfoAlert } from '../../utils/hooks/useAlerts';
import { useAuth } from '../../utils/hooks/useAuth';
import useOnKeyPress from '../../utils/hooks/useOnKeyPress';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { isBuilder } from '../../utils/user';
import ChangeUserPopover from '../ChangeUserPopover';
import { removeParentPageSettingsFromRemovedPage } from '../editor/PageEditor';
import BuildModeIcon from './BuildModeIcon';

interface BuildModeFooterProps {
  editorMode: boolean;
  isUpdating: boolean;
  project: Project;
  sidebarExpanded: boolean;
  updateProject: UpdateProjectCallback;
}

const BuildModeFooter = ({
  editorMode,
  isUpdating,
  project,
  sidebarExpanded,
  updateProject,
}: BuildModeFooterProps) => {
  const dispatch = useDispatch();
  const infoAlert = useInfoAlert();
  const undoStack = useSelector(undoStackSelector);
  const redoStack = useSelector(redoStackSelector);
  const projectName = useSelector(projectNameSelector);
  const dataTypes = useSelector(dataTypesSelector);
  const { isLoading: isToggling, toggleEditorMode } =
    useToggleEditorMode(editorMode);
  const [isOpen, setIsOpen] = useState(false);
  const isLoading = useMemo<boolean>(
    () => isToggling || isUpdating,
    [isToggling, isUpdating],
  );

  const user = useScopeUser();
  const { user: baseUser } = useAuth();
  const userIsBuilder = baseUser && isBuilder(baseUser);
  const ghostUserId = useSelector(ghostUserIdSelector);

  const onSetGhostUserId = useCallback(
    (newGhostUserId) => dispatch(setGhostUserId(newGhostUserId)),
    [dispatch],
  );

  const showViewingAs = useMemo(
    () => userIsBuilder && ghostUserId,
    [userIsBuilder, ghostUserId],
  );

  const onUndo = useCallback(() => {
    if (undoStack.length > 0) {
      const previousProjectElements = last(undoStack);
      // Removes the update from the history
      dispatch(undo());
      updateProject(
        (previousProjectElements as any).path,
        (previousProjectElements as any).value,
        { skipHistory: true },
      );
    } else {
      infoAlert(getText('leftSidebar.auth.undo.error'));
    }
  }, [undoStack, dispatch, updateProject, infoAlert]);

  const onRedo = useCallback(() => {
    if (redoStack.length > 0) {
      const previousProjectElements = last(redoStack);
      // Removes the update from the history
      dispatch(redo());
      updateProject(
        (previousProjectElements as any).path,
        (previousProjectElements as any).value,
        { skipHistory: true },
      );
    } else {
      infoAlert(getText('leftSidebar.auth.redo.error'));
    }
  }, [redoStack, dispatch, updateProject, infoAlert]);

  const [{ draggedItem }] = useDrag({
    collect: (monitor) => ({ draggedItem: monitor.getItem() }),
    type: ItemTypes.SINGLE_ITEM,
  });

  const [removePage] = useRemoveSelected(
    project,
    get(draggedItem, 'elementPath', []),
    // @ts-expect-error TS2345: Argument of type '(newPages: Element[], removedPage: Element) => Element[]' is not assignable to parameter of type '{  (value: T): T; (): undefined; }'.
    removeParentPageSettingsFromRemovedPage,
  );

  const itemTypes = [
    ItemTypes.SINGLE_ITEM,
    ItemTypes.FOLDER,
    ItemTypes.NESTED_ITEM,
  ];

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: itemTypes,
    canDrop: (item: Item) => itemTypes.includes(item.type),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: () => removePage(),
  });

  const handleDrop = useCallback(
    (item: Item) => {
      const isHidden: boolean = get(item, 'page.props.hide', false);

      if (item.type === ItemTypes.SINGLE_ITEM && item.parentPageId) {
        return updateProject(['elements', ...item.elementPath], {
          ...item.page,
          props: {
            ...item.page.props,
            hide: !isHidden,
            parentPage: null,
          },
        });
      }

      updateProject(['elements', ...item.elementPath], {
        ...item.page,
        props: {
          ...item.page.props,
          hide: !isHidden,
        },
      });
    },
    [updateProject],
  );

  const [{ canDrop: canDropToHide, isOver: isOverHideDropZone }, hideDropRef] =
    useDrop({
      accept: itemTypes,
      canDrop: (item: Item) => itemTypes.includes(item.type),
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop: handleDrop,
    });

  useOnKeyPress(KEY_Z, onUndo, {
    ctrlKey: true,
    enabled: editorMode,
  });

  useOnKeyPress(KEY_Z, onRedo, {
    ctrlKey: true,
    shiftKey: true,
    enabled: editorMode,
  });

  useOnKeyPress(KEY_E, toggleEditorMode, {
    ctrlKey: true,
    enabled: !isLoading,
  });

  return (
    <div
      className={classNames(
        'fixed bottom-0 left-16 flex flex-col items-center bg-slate-700',
        { 'w-full max-w-64': sidebarExpanded, 'w-20': !sidebarExpanded },
      )}
    >
      {showViewingAs && sidebarExpanded && (
        <div className="flex h-10 w-full items-center justify-between border-b border-slate-600 bg-slate-700 p-2 text-slate-200">
          <span className="mr-auto truncate font-medium tracking-wider">
            {getText({ name: user.firstName }, 'leftSidebar.auth.viewingAs')}
          </span>
          <button onClick={() => onSetGhostUserId(null)}>
            <IconX size={16} />
          </button>
        </div>
      )}
      {(canDrop || canDropToHide) && draggedItem ? (
        <div className="grid w-full grid-cols-2">
          {canDropToHide && (
            <div
              className={classNames(
                'flex h-20 w-full flex-col items-center justify-center space-y-2 bg-cyan-300 text-cyan-900',
                {
                  'opacity-75': !isOverHideDropZone,
                  'opacity-100': isOverHideDropZone,
                },
              )}
              ref={hideDropRef}
            >
              {get(draggedItem, 'page.props.hide', false) ? (
                <>
                  <IconEye size={16} />
                  <span>{getText('leftSidebar.auth.dropToUnhide')}</span>
                </>
              ) : (
                <>
                  <IconEyeOff size={16} />
                  <span>{getText('leftSidebar.auth.dropToHide')}</span>
                </>
              )}
            </div>
          )}
          {canDrop && (
            <div
              className={classNames(
                'flex h-20 w-full flex-col items-center justify-center space-y-2 bg-red-300 text-red-900',
                { 'opacity-75': !isOver, 'opacity-100': isOver },
              )}
              ref={dropRef}
            >
              <IconTrash size={16} />
              <span>{getText('leftSidebar.auth.dropToDelete')}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            className={classNames('flex w-full items-center', {
              'h-10 px-2': sidebarExpanded,
              'h-20': !sidebarExpanded,
            })}
          >
            <div
              className={classNames('flex w-full items-center', {
                'flex-col justify-center space-y-2': !sidebarExpanded,
              })}
            >
              <div
                className={classNames('flex items-center', {
                  'w-2/3 space-x-2': sidebarExpanded,
                  'w-full justify-center space-x-2 border-b border-slate-600 pb-1':
                    !sidebarExpanded,
                })}
              >
                <KeyboardShortcutTooltip
                  keys={[CMD, KEY_Z]}
                  label={getText('leftSidebar.auth.undo.tooltip')}
                  offset={[0, 8]}
                  placement="top"
                >
                  <BuildModeIcon
                    disabled={undoStack.length === 0}
                    Icon={IconCornerUpLeft}
                    onClick={onUndo}
                  />
                </KeyboardShortcutTooltip>
                <KeyboardShortcutTooltip
                  keys={[CMD, SHIFT, KEY_Z]}
                  label={getText('leftSidebar.auth.redo.tooltip')}
                  offset={[0, 8]}
                  placement="top"
                >
                  <BuildModeIcon
                    disabled={redoStack.length === 0}
                    Icon={IconCornerUpRight}
                    onClick={onRedo}
                  />
                </KeyboardShortcutTooltip>
              </div>
              <div
                className={classNames('flex items-center space-x-2', {
                  'w-1/3 justify-end': sidebarExpanded,
                  'w-full justify-center': !sidebarExpanded,
                })}
              >
                <ChangeUserPopover
                  dataTypes={dataTypes}
                  delayShow={0}
                  isOpen={isOpen}
                  onOpenChange={setIsOpen}
                  projectName={projectName}
                  showArrow={false}
                />
                <Button onClick={isLoading ? null : toggleEditorMode} size={SM}>
                  {isLoading ? (
                    <Loader size="xs" />
                  ) : (
                    getText('leftSidebar.auth.done')
                  )}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BuildModeFooter;
