import React, { useMemo, useState } from 'react';
import { Switch, Tooltip } from '@noloco/components';
import { Feature } from '@noloco/core/src/constants/features';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import { useFeatureLimit } from '../utils/hooks/useFeatureLimit';
import { useFeatureLockedTooltip } from '../utils/hooks/useFeatureLockedTooltip';
import useIsFeatureEnabled from '../utils/hooks/useIsFeatureEnabled';
import OverageWarningModal from './OverageWarningModal';
import ProFeatureBadge from './ProFeatureBadge';

interface OwnProps {
  blockTrial?: boolean;
  checkEnabled?: boolean;
  currentNumber?: number;
  onClick?: (...args: any[]) => any;
  feature?: Feature;
  tooltip?: React.ReactNode | string;
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FeatureLockedSwitch.defaultProps;

// @ts-expect-error TS(7022): 'FeatureLockedSwitch' implicitly has type 'any' be... Remove this comment to see the full error message
const FeatureLockedSwitch = ({
  onChange,
  blockTrial,
  disabled,
  feature,
  checkEnabled,
  currentNumber,
  tooltipPlacement,
  value,
  ...rest
}: Props) => {
  const [overageModalIsOpen, setOverageModalIsOpen] = useState(false);

  const isEnabled = useIsFeatureEnabled(feature, {
    currentValue: currentNumber,
    blockTrial,
  });
  const { limit, overage } = useFeatureLimit(feature);
  const tooltip = useFeatureLockedTooltip(feature, currentNumber);

  const featureEnabled = useMemo(
    () => !checkEnabled || isEnabled || !!overage,
    [checkEnabled, isEnabled, overage],
  );

  const showOverageModal = useMemo(
    () =>
      checkEnabled &&
      !isEnabled &&
      !value &&
      overage &&
      (limit - currentNumber) % overage.increment === 0,
    [checkEnabled, currentNumber, isEnabled, limit, overage, value],
  );

  return (
    <>
      <Tooltip
        delayHide={300}
        disabled={featureEnabled}
        placement={tooltipPlacement}
        content={
          <div className="flex flex-col text-sm">
            {tooltip}
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              className="mt-2 text-cyan-500 hover:underline"
            >
              {getText('features.explore')}
            </a>
          </div>
        }
        bg="white"
      >
        <span className="flex items-center space-x-2">
          <ProFeatureBadge
            currentNumber={currentNumber}
            feature={feature}
            inline={true}
            showAfterTrial={!featureEnabled}
          />
          <Switch
            className="ml-4"
            disabled={!featureEnabled || disabled}
            value={value}
            onChange={(nextValue: any) => {
              onChange(nextValue);

              if (showOverageModal) {
                setOverageModalIsOpen(true);
              }
            }}
            {...rest}
          />
        </span>
      </Tooltip>
      {overageModalIsOpen && (
        <OverageWarningModal
          // @ts-expect-error TS(2322): Type '{ handleCancel: () => any; handleClick: () =... Remove this comment to see the full error message
          handleCancel={() => onChange(false)}
          handleClick={() => onChange(true)}
          feature={feature}
          overage={overage}
          setIsOpen={setOverageModalIsOpen}
        />
      )}
    </>
  );
};

FeatureLockedSwitch.defaultProps = {
  checkEnabled: true,
};

export default FeatureLockedSwitch;
