import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Loader from '@noloco/components/src/components/loading/Loader';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import CircularProgress from '@noloco/core/src/components/CircularProgress';
import {
  projectDomainsSelector,
  projectUsersSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  ONBOARDING_CHECKLIST_OPENED,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import useOnboardingChecklist from '../utils/hooks/useOnboardingChecklist';

const LANG_KEY = 'onboardingChecklist';

const OnboardingChecklistSummary = () => {
  const users = useSelector(projectUsersSelector);
  const domains = useSelector(projectDomainsSelector);
  const { pushQueryParams } = useRouter();

  const onOpenOnboardingChecklist = useCallback(() => {
    trackEvent(ONBOARDING_CHECKLIST_OPENED);
    pushQueryParams({ __onboarding: 'true' });
  }, [pushQueryParams]);

  const { progressPx, loading, complete } = useOnboardingChecklist();

  if (complete || !users || !domains) {
    return null;
  }

  if (loading) {
    return (
      <div className="flex h-10 w-full items-center justify-center py-4">
        <Loader size={SM} />
      </div>
    );
  }

  return (
    <button
      onClick={onOpenOnboardingChecklist}
      className="mb-2 flex items-center rounded-lg bg-slate-800 px-4 py-3 text-left text-white hover:bg-slate-900"
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <span className="text-sm">{getText(LANG_KEY, 'title')}</span>
          <span className="text-xs text-gray-200">
            {getText(LANG_KEY, 'helpTitle')}
          </span>
        </div>
        <div className="flex items-center text-pink-400">
          <CircularProgress
            hideValue={true}
            progress={progressPx}
            maxProgress={100}
            size={SM}
          />
          <div className="flex flex-col">
            <span className="mt-1 text-xs">
              {getText(LANG_KEY, 'progress')}
            </span>
            <span className="text-xl font-bold">{progressPx}%</span>
          </div>
        </div>
      </div>
    </button>
  );
};

export default OnboardingChecklistSummary;
