export const HOURS_MINUTES_SECONDS = 'h:mm:ss';
export const HOURS_MINUTES = 'h:mm';
export const MINUTES_SECONDS = 'm:ss';
export const HOURS = 'h';

const durationFormatOptions = [
  HOURS_MINUTES_SECONDS,
  HOURS_MINUTES,
  MINUTES_SECONDS,
  HOURS,
];

export default durationFormatOptions;
