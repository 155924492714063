import get from 'lodash/get';
import last from 'lodash/last';
import { Page, PageTab } from '../models/AppNavigation';
import { Element, ViewTab } from '../models/Element';
import { ensureArray } from './arrays';
import { pagePathIdIsRecordPage } from './pages';

export const filterSections = (
  tabs: ViewTab[],
  visibleTabs: ViewTab[],
  sections: Element[],
  selectedTab: ViewTab | null | undefined,
) => {
  const useTabs = tabs && tabs.length > 1;
  const tabIds = ensureArray(tabs).map((tab) => tab.id);
  const visibleTabIds = ensureArray(visibleTabs).map((tab) => tab.id);

  if (visibleTabIds.length === 0 && useTabs) {
    return [];
  }

  return sections
    .map((section, index) => ({
      section,
      index,
    }))
    .filter(({ section }: any) => {
      if (!useTabs) {
        // If not using tabs then render anything that isn't hidden.
        return (
          !section ||
          !(
            tabIds.includes(section.tab) && !visibleTabIds.includes(section.tab)
          )
        );
      }

      return (
        section &&
        ((!selectedTab && (!section.tab || !tabIds.includes(section.tab))) ||
          (selectedTab && section.tab === selectedTab.id))
      );
    });
};

export const getTabsForPage = (page: Page, pageIds: string[]): PageTab[] => {
  const lastPageId = last(pageIds);

  if (!page || !page.props || !lastPageId) {
    return [];
  }

  if (pagePathIdIsRecordPage(lastPageId)) {
    return get(page, 'props.record.tabs', []);
  }

  return get(page, 'props.tabs', []);
};
