import React, { forwardRef, memo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { extractVideoId } from './utils/videoUtils';

interface VimeoVideoElementProps {
  videoId: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
}

const urlPatterns = [
  '(https:\\/\\/www\\.)?vimeo\\.com\\/([a-zA-Z0-9_-]+)',
  '(https:\\/\\/vimeo\\.com\\/)([a-zA-Z0-9_-]+)',
];

const VimeoVideoElement = memo(
  ({
    videoId,
    autoplay = false,
    controls = true,
    loop = false,
    muted = false,
  }: VimeoVideoElementProps) => {
    const handleRef = async (video: HTMLDivElement | null) => {
      if (!video) {
        return;
      }

      const cleanVideoId = extractVideoId(videoId, urlPatterns);

      const vimeoUrl = `https://vimeo.com/${cleanVideoId}`;
      const params = new URLSearchParams({
        url: vimeoUrl,
        autopause: '0',
        autoplay: autoplay ? '1' : '0',
        controls: controls ? '1' : '0',
        loop: loop ? '1' : '0',
        muted: muted ? '1' : '0',
        responsive: 'true',
      });

      try {
        const response = await fetch(
          `https://vimeo.com/api/oembed.json?${params.toString()}`,
        );
        const data = await response.json();

        if (data.html) {
          video.innerHTML = data.html;
          const iframe = video.querySelector('iframe');

          if (iframe) {
            iframe.style.position = 'absolute';
            iframe.style.top = '0';
            iframe.style.left = '0';
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.border = '0';
          }
        }
      } catch (error) {
        console.error('Error fetching Vimeo oembed:', error);
      }
    };

    return (
      <div ref={handleRef} className="absolute left-0 top-0 h-full w-full" />
    );
  },
);

interface VimeoVideoProps {
  className?: string;
  videoId: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  onClick?: (...args: any[]) => any;
}

const VimeoVideo = forwardRef<HTMLDivElement, VimeoVideoProps>(
  ({ className, videoId, autoplay, controls, loop, muted }, ref) => (
    <Box ref={ref} className={className}>
      <div className="pb-fluid-video relative h-0">
        <VimeoVideoElement
          videoId={videoId}
          autoplay={autoplay}
          controls={controls}
          loop={loop}
          muted={muted}
        />
      </div>
    </Box>
  ),
);

VimeoVideo.defaultProps = {
  className: '',
  videoId: '',
  autoplay: false,
  controls: true,
  loop: false,
  muted: false,
};

export default VimeoVideo;
