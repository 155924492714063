import { LINK_REPLACED_CLASS } from './common';
import { oembed } from './oembed';
import { isValidLinkNode } from './validate';

const createNodeFromString = (htmlString: string): ChildNode | null => {
  const containerNode = document.createElement('div');

  containerNode.innerHTML = htmlString;

  return containerNode.firstChild;
};

const injectVideo = (linkNode: ChildNode, embedString: string): void => {
  const embedNode = createNodeFromString(embedString);

  if (embedNode && linkNode.parentNode) {
    linkNode.parentNode.insertBefore(embedNode, linkNode);
  }
};

const linkReplace = (
  selector = 'a',
  options = {},
  target: Document = document
): void => {
  const linkNodes = <[HTMLAnchorElement]>[
    ...Array.from(target.querySelectorAll(selector)),
  ];

  linkNodes
    .filter(item => isValidLinkNode(item))
    .forEach(async node => {
      const { html } = await oembed(node.href, options);

      injectVideo(node, html);

      node.className = `${node.className} ${LINK_REPLACED_CLASS}`;

      return true;
    });
};

export { linkReplace };
