import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { BaseModal, Button } from '@noloco/components';
import {
  ACTIVE,
  TRIALING,
  TRIAL_EXPIRED,
} from '@noloco/core/src/constants/accountStatus';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';
import creditCardIllustration from '../img/undraw/credit-card.svg';

const LANG_KEY = 'billing.invalid';

const BillingPlanStatusAlert = () => {
  const [isDarkModeEnabled] = useDarkMode();
  const billingPlan = useSelector(billingPlanSelector);

  const periodEnd = useMemo(
    () =>
      billingPlan && billingPlan.periodEnd
        ? DateTime.fromISO(billingPlan.periodEnd)
        : DateTime.now().startOf('day'),
    [billingPlan],
  );

  const isInvalid = useMemo(
    () =>
      billingPlan &&
      billingPlan.status !== ACTIVE &&
      billingPlan.status !== TRIALING &&
      billingPlan.status !== TRIAL_EXPIRED,
    [billingPlan],
  );

  const lastPaymentDate = useMemo(() => {
    if (!isInvalid || !billingPlan) {
      return null;
    }

    if (billingPlan.interval === 'year') {
      return periodEnd.minus({ year: 1, days: 3 });
    }

    return periodEnd.minus({ month: 1, days: 3 });
  }, [billingPlan, isInvalid, periodEnd]);

  const [isOpen, setIsOpen] = useState(isInvalid);

  // If the periodEnd is within 3 days it can be dismissed.
  const canBeDismissed = useMemo(
    () => isInvalid && periodEnd > DateTime.now(),
    [isInvalid, periodEnd],
  );

  const status = useMemo(() => billingPlan?.status, [billingPlan]);

  const { isBuilder } = useIsBuilder();
  const [counter, setCounter] = useState(0);

  const increaseCounter = useCallback(() => {
    setTimeout(() => {
      if (isInvalid) {
        setCounter((currentVal) => currentVal + 1);
        increaseCounter();
      }
    }, 2000);
  }, [isInvalid]);

  useEffect(() => {
    if (isInvalid && counter === 0) {
      increaseCounter();
    }
  }, [counter, increaseCounter, isInvalid]);

  const modal =
    typeof document !== 'undefined' &&
    document.querySelector('.billing-plan-modal');

  if (!modal && counter % 2 === 0 && !canBeDismissed) {
    return null;
  }

  if (!isInvalid || !isOpen || (!isBuilder && canBeDismissed)) {
    return null;
  }

  return (
    <BaseModal size="xl" onClose={() => null}>
      <div
        className={classNames('billing-plan-modal flex w-full', {
          'bg-gray-100': !isDarkModeEnabled,
          [darkModeColors.surfaces.elevation0]: isDarkModeEnabled,
        })}
      >
        <div className="w-full px-8 py-16">
          <h1
            className={classNames('mx-auto max-w-lg text-2xl font-medium', {
              'text-gray-900': !isDarkModeEnabled,
              [darkModeColors.text.primary]: isDarkModeEnabled,
            })}
          >
            {getText(LANG_KEY, status, 'title')}
          </h1>
          <p
            className={classNames('mx-auto mt-6 max-w-lg text-base', {
              'text-gray-700': !isDarkModeEnabled,
              [darkModeColors.text.secondary]: isDarkModeEnabled,
            })}
          >
            {getText(
              {
                dueDate: lastPaymentDate!.toLocaleString(DateTime.DATE_MED),
              },
              LANG_KEY,
              status,
              'description',
            )}
          </p>
          <p
            className={classNames(
              'mx-auto mb-1 mt-8 max-w-lg text-base sm:mt-4',
              {
                'text-gray-700': !isDarkModeEnabled,
                [darkModeColors.text.secondary]: isDarkModeEnabled,
              },
            )}
          >
            {getText(LANG_KEY, 'general.continue')}
          </p>
          <div className="mx-auto mt-16 flex max-w-lg items-center space-x-4">
            {canBeDismissed && (
              <Button
                onClick={() => setIsOpen(false)}
                type="outline"
                size="lg"
                className="flex w-full items-center justify-center rounded-lg"
              >
                <span>{getText(LANG_KEY, 'general.remind')}</span>
              </Button>
            )}
            {isBuilder && (
              <a href={`${APP_DASHBOARD_URL}/billing`} className="block w-full">
                <Button size="lg" className="w-full rounded-lg">
                  <span>{getText(LANG_KEY, 'general.cta')}</span>
                </Button>
              </a>
            )}
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center justify-center p-16 md:hidden">
          <img
            src={creditCardIllustration}
            className="h-auto w-full max-w-xs"
            alt="Credit card illustration"
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default BillingPlanStatusAlert;
