import createReactComponent from '../createReactComponent';
export default createReactComponent('device-floppy', 'IconDeviceFloppy', [
  [
    'path',
    {
      d: 'M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M14 4l0 4l-6 0l0 -4', key: 'svg-2' }],
]);
