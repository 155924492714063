import { useEffect, useState } from 'react';
import { DataType } from '../../../models/DataTypes';
import { Action } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import useActions, { ActionHandler } from '../../../utils/hooks/useActions';
import useOneClickActionMutations from '../../../utils/hooks/useOneClickActionMutations';

interface OneClickRecordActionButtonProps {
  action: Action;
  actionIndex: number;
  dataType: DataType;
  onAddComment: ActionHandler;
  onCopy: ActionHandler;
  onDelete: ActionHandler;
  onNavigate: (action: Action) => void;
  onNext: (actionResult?: any) => void;
  onRunWorkflow: ActionHandler;
  project: Project;
  record?: BaseRecord;
}

const OneClickRecordActionButton = ({
  action,
  actionIndex,
  dataType,
  onCopy,
  onAddComment,
  onDelete,
  onNavigate,
  onNext,
  onRunWorkflow,
  project,
  record,
}: OneClickRecordActionButtonProps) => {
  const [lastExecuted, setLastExecuted] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { onCreate, onUpdate } = useOneClickActionMutations(
    action,
    dataType,
    project,
    record,
    onNext,
  );

  const { onExecuteAction } = useActions(
    onCreate,
    onDelete,
    onNavigate,
    onNext,
    onRunWorkflow,
    onUpdate,
    onCopy,
    onAddComment,
  );

  useEffect(() => {
    if (!isLoading && lastExecuted !== actionIndex) {
      setLastExecuted(actionIndex);
      onExecuteAction(action, setIsLoading);
    }
  }, [
    action,
    actionIndex,
    isLoading,
    lastExecuted,
    onExecuteAction,
    setIsLoading,
  ]);

  return null;
};

export default OneClickRecordActionButton;
