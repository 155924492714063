import camelCase from 'lodash/camelCase';
import first from 'lodash/first';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import { UPDATE } from '../constants/actionTypes';
import { FILE, USER } from '../constants/builtInDataTypes';
import {
  BOARD,
  CALENDAR,
  CARDS,
  COLUMNS,
  CollectionLayout,
  MAP,
  PIVOT_TABLE,
  TABLE,
  TABLE_FULL,
} from '../constants/collectionLayouts';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  NUMERIC_DATATYPES,
  OBJECT,
  SINGLE_OPTION,
  TEXT,
} from '../constants/dataTypes';
import { CREATED_AT_ID, UPDATED_AT_ID } from '../constants/defaultFields';
import { RATING } from '../constants/fieldFormats';
import { ONE_TO_MANY, ONE_TO_ONE } from '../constants/relationships';
import {
  BaseDataField,
  DataField,
  DataFieldOption,
} from '../models/DataTypeFields';
import DataTypes, { DataType } from '../models/DataTypes';
import { Condition } from '../models/Element';
import { DataTypeIdentifier } from '../models/ProjectArrayTypeMap';
import { FormFieldConfig } from '../models/View';
import { MutationType } from '../queries/project';
import { ensureArray } from './arrays';
import { filterDefaultFields, isDefaultFieldById } from './defaultFields';
import { getSubFieldsAsDataFields, isEmpty } from './objects';
import { isMultiField, isMultiRelationship } from './relationships';

export const canBeSorted = (field: DataField) =>
  !['id', 'uuid'].includes(field.name) &&
  !field.multiple &&
  !field.relationship &&
  !field.relatedField &&
  ![MULTIPLE_OPTION, SINGLE_OPTION].includes(field.type);

export const canBeStickyColumn = (
  index: number,
  layout: CollectionLayout,
): boolean => index === 0 && [TABLE, TABLE_FULL].includes(layout);

export const canBeCardHeroImage = (
  index: number,
  field: DataField,
  layout: CollectionLayout,
): boolean =>
  index === 0 &&
  field.type === FILE &&
  [CARDS, BOARD, COLUMNS, CALENDAR, MAP].includes(layout);

export const canBeStages = (field: DataField): boolean =>
  field.type === SINGLE_OPTION;

export const canBeCopiedToClipboard = (field: DataField): boolean =>
  field.type === TEXT ||
  field.type === DECIMAL ||
  field.type === INTEGER ||
  field.type === DATE ||
  field.type === DURATION;

export const canHaveGroupSummary = (
  index: number,
  field: DataField,
  layout: CollectionLayout,
): boolean =>
  index > 0 &&
  (layout === TABLE || layout === TABLE_FULL || layout === PIVOT_TABLE) &&
  (field.type === DECIMAL || field.type === INTEGER || field.type === DURATION);

export const formFieldIsRequired = (
  field: DataField,
  config: FormFieldConfig,
  mutationType: MutationType,
  fieldHasChanged: boolean,
  requiredConditionsAreEnabled: boolean,
  requiredConditionsAreMet: (requiredConditions: Condition[][]) => boolean,
): boolean => {
  const fieldIsIncluded = mutationType !== UPDATE || fieldHasChanged;

  // If the field is  required, it is required if included
  if (fieldIsIncluded && field.required) {
    return true;
  }

  // If the field is unique, it is required if included
  if (fieldIsIncluded && field.unique) {
    return true;
  }

  // If the field config is not marked as required, it is never required.
  if (!config.required) {
    return false;
  }

  // If the field config is marked as required and required rules are disabled
  // or there are no required rules to check, it is required.
  if (
    !requiredConditionsAreEnabled ||
    !config.requiredConditions ||
    config.requiredConditions.length === 0
  ) {
    return true;
  }

  // If the field config is marked as required and required rules are enabled,
  // it is required only if the rules are met.
  return requiredConditionsAreMet(config.requiredConditions);
};

export const getFieldByName = (fieldName: string, dataType: DataType) =>
  fieldName && dataType.fields.getByName(fieldName);

export const getRootFieldDataType = (
  rootField: string | undefined,
  dataType: DataType,
  dataTypes: DataTypes,
) => {
  if (rootField) {
    const relatedField = getFieldByName(rootField, dataType);

    if (relatedField) {
      const relatedDataType = dataTypes.getByName(relatedField.type);

      if (relatedDataType) {
        return relatedDataType;
      }
    }
  }

  return dataType;
};

export const getValuePathForFieldConfig = (
  field: DataField,
  parent?: DataField,
) => [...(parent ? [parent.apiName] : []), field.apiName];

export const getBaseFieldReverseName = (
  field: BaseDataField,
  reverseDataType: DataTypeIdentifier,
): string => {
  if (field.relationship === ONE_TO_ONE || !field.reverseName) {
    return field.reverseName || reverseDataType.apiName;
  }

  return field.reverseName;
};

export const getFieldRecordKey = (field: DataField): string =>
  `${field.name}${
    !field.relationship || isMultiRelationship(field.relationship) ? '' : 'Id'
  }`;

export const getFieldKey = (
  field: Pick<DataField, 'name' | 'type' | 'relatedField' | 'relationship'>,
): string => {
  if (field.relatedField) {
    return getFieldReverseMutationInputName(field.relatedField, {
      apiName: field.type,
    } as DataType);
  }

  return field.relationship ? `${field.name}Id` : field.name;
};

export const getFieldMutationInputName = (field: BaseDataField): string =>
  field.relationship && field.type !== FILE
    ? `${field.apiName}Id`
    : field.apiName;

export const getFieldReverseMutationInputName = (
  field: BaseDataField,
  reverseDataType: DataTypeIdentifier,
): string => `${getBaseFieldReverseName(field, reverseDataType)}Id`;

export const getFieldReverseApiName = (
  field: DataField,
  reverseDataType: DataType,
) => {
  if (field.relationship === ONE_TO_ONE) {
    return field.reverseName || reverseDataType.apiName;
  } else if (field.relationship === ONE_TO_MANY) {
    return field.reverseName;
  }

  return `${camelCase(field.reverseName as string)}Collection`;
};

export const getFieldReverseName = (
  field: BaseDataField,
  reverseDataType: DataTypeIdentifier,
) => {
  if (field.relationship === ONE_TO_ONE) {
    return field.reverseName || reverseDataType.name;
  } else if (field.relationship === ONE_TO_MANY) {
    return field.reverseName;
  }

  return `${camelCase(field.reverseName as string)}Collection`;
};

const sortFieldsWithPrimaryField =
  (primaryField: DataField | null | undefined) =>
  (fieldA: DataField, fieldB: DataField) => {
    if (fieldA.name === 'id') {
      return -1;
    }

    if (fieldB.name === 'id') {
      return 1;
    }

    if (fieldA.name === 'uuid') {
      return 1;
    }

    if (fieldB.name === 'uuid') {
      return -1;
    }

    if (primaryField) {
      const primaryFieldId = primaryField.id;

      if (fieldA.id === primaryFieldId) {
        return -1;
      }

      if (fieldB.id === primaryFieldId) {
        return 1;
      }
    }

    if (fieldA.relatedField && !fieldB.relatedField) {
      return 1;
    }

    if (fieldB.relatedField && !fieldA.relatedField) {
      return -1;
    }

    if (fieldA.id === CREATED_AT_ID || fieldA.id === UPDATED_AT_ID) {
      return 1;
    }

    if (fieldB.id === CREATED_AT_ID || fieldB.id === UPDATED_AT_ID) {
      return -1;
    }

    if (fieldA.rollup || fieldB.rollup) {
      return 0;
    }

    if (
      isDefaultFieldById(fieldA.id as number) &&
      !isDefaultFieldById(fieldB.id as number)
    ) {
      return -1;
    }

    if (
      isDefaultFieldById(fieldB.id as number) &&
      !isDefaultFieldById(fieldA.id as number)
    ) {
      return 1;
    }

    if (!isNil(fieldA.order) && fieldB.order === null) {
      return -1;
    }

    if (fieldA.order === null && !isNil(fieldB.order)) {
      return 1;
    }

    if (!isNil(fieldA.order) && !isNil(fieldB.order)) {
      return fieldA.order! - fieldB.order!;
    }

    return (fieldA.id as number) - (fieldB.id as number);
  };

export const getPrimaryField = (dataType: DataType): DataField | undefined => {
  if (dataType.primaryField) {
    const primaryField = dataType.fields.getById(dataType.primaryField?.id);

    if (primaryField) {
      return primaryField;
    }
  }

  if (dataType.name === USER) {
    const emailField = dataType.fields.getByName('email');

    if (emailField) {
      return emailField;
    }
  }

  const sortedFields = dataType.fields
    .slice()
    .sort(sortFieldsWithPrimaryField(null));

  const firstTextField = sortedFields
    .filter(filterDefaultFields)
    .find(({ type, name }) => type === TEXT && name !== 'email');

  if (firstTextField) {
    return firstTextField;
  }

  return sortedFields.find(({ name }) => name === 'uuid');
};

export const sortFields = (dataType: DataType) => {
  const primaryField = getPrimaryField(dataType);

  return sortFieldsWithPrimaryField(primaryField);
};

export const sortOptions = (options: DataFieldOption[]) =>
  [...options].sort((optionA, optionB) => optionA.order - optionB.order);

export const collectionDeps = [
  'endCursor',
  'startCursor',
  'totalCount',
  'hasNextPage',
  'hasPreviousPage',
];

export interface DataFieldDependency {
  dataType: DataType;
  field: DataField;
  parent?: DataField;
}

const CONNECTION_PATHS = ['edges', 'pageInfo', 'totalCount', '_columns'];
const RELATIONAL_PATHS = ['_columns', 'edges', 'node'];

export const getFieldFromDependency = (
  dependencyPath: string[],
  dataType: DataType | undefined,
  dataTypesWithRelations: DataTypes,
): DataFieldDependency | null => {
  if (!dataType) {
    return null;
  }

  const firstPathSegment = first(dependencyPath);

  if (!firstPathSegment) {
    return null;
  }

  if (collectionDeps.includes(firstPathSegment)) {
    return {
      dataType,
      field: {
        name: firstPathSegment,
        apiName: firstPathSegment,
        type: DECIMAL,
      } as DataField,
    };
  }

  if (dependencyPath.includes('pageInfo')) {
    return {
      dataType,
      field: {
        name: dependencyPath.join('.'),
        apiName: dependencyPath.join('.'),
        type: TEXT,
      } as DataField,
    };
  }

  const dataTypeWithRelations = dataTypesWithRelations.getByName(dataType.name);

  if (!dataTypeWithRelations) {
    return null;
  }

  const relevantFields = dataTypeWithRelations.fields;

  if (RELATIONAL_PATHS.includes(firstPathSegment)) {
    return getFieldFromDependency(
      dependencyPath.slice(1),
      dataType,
      dataTypesWithRelations,
    );
  }

  const field = relevantFields.getByName(firstPathSegment);

  if (!field) {
    return null;
  }

  if (dependencyPath.length > 1) {
    const nextPathSegment = dependencyPath[1];

    const isMultiRelationship = isMultiField(field);

    if (
      nextPathSegment &&
      field.type === OBJECT &&
      dependencyPath.length === 2
    ) {
      const subField = getSubFieldsAsDataFields(field).find(
        ({ apiName }) => apiName === nextPathSegment,
      );

      if (subField) {
        return {
          dataType,
          field: {
            ...subField,
            name: nextPathSegment,
            apiName: nextPathSegment,
          } as DataField,
          parent: field,
        };
      }
    }

    // If the next segment is `_columns` or `edges` and the field is now
    // a multi-relationship field, skip it
    if (
      nextPathSegment &&
      CONNECTION_PATHS.includes(nextPathSegment) &&
      !isMultiRelationship
    ) {
      return null;
    }

    // If the next segment is NOT `_columns` or `edges` and the field is now
    // a single-relationship field, skip it

    if (
      nextPathSegment &&
      isMultiRelationship &&
      !CONNECTION_PATHS.includes(nextPathSegment)
    ) {
      return null;
    }

    if (field.relationship || field.relatedField) {
      const relatedType = dataTypesWithRelations.getByName(field.type);

      if (!relatedType) {
        return null;
      }

      return getFieldFromDependency(
        dependencyPath.slice(1),
        relatedType,
        dataTypesWithRelations,
      );
    } else {
      return null;
    }
  }

  return { field, dataType };
};

export const isFormFieldEmpty = (
  field: DataField,
  fieldValue: any,
): boolean => {
  if (field.multiple) {
    return ensureArray(fieldValue).filter(Boolean).length === 0;
  }

  if (isNil(fieldValue)) {
    return true;
  }

  if (field.type === BOOLEAN) {
    return !fieldValue;
  }

  switch (field.type) {
    case DURATION:
      return fieldValue === '';
    case MULTIPLE_OPTION:
      return isArray(fieldValue) && fieldValue.length === 0;
    case OBJECT:
      return isEmpty(field, fieldValue);
    case TEXT:
      return fieldValue === '';
    default:
      break;
  }

  if (isMultiField(field)) {
    const edges = get(fieldValue, ['edges'], []);

    return isArray(edges) && edges.length === 0;
  }

  return false;
};

export const isRatingField = (field: DataField) =>
  NUMERIC_DATATYPES.includes(field.type) &&
  field.typeOptions?.format === RATING;

export const backfillOrder = (
  primaryField: DataField | undefined,
  newOrder: DataField[],
  droppedIndex: number,
  firstDraggableFieldIndex: number,
  firstUnsortedIndex: number,
) => {
  if (firstUnsortedIndex === -1 || firstUnsortedIndex >= droppedIndex) {
    return {
      backfill: [],
      predecessor:
        droppedIndex <= firstDraggableFieldIndex
          ? 0
          : (newOrder[droppedIndex - 1].order ?? 0),
    };
  }

  const lastSortedOrder =
    firstUnsortedIndex <= firstDraggableFieldIndex
      ? 0
      : newOrder[firstUnsortedIndex - 1].order;

  const backfill = newOrder
    .slice(firstUnsortedIndex, droppedIndex)
    .map(({ id }, index) => ({
      id,
      order: index + 1 + lastSortedOrder!,
    }));

  if (firstUnsortedIndex === firstDraggableFieldIndex && primaryField) {
    backfill.unshift({ id: primaryField.id, order: 0 });
  }

  return {
    backfill,
    predecessor: last(backfill)!.order,
  };
};

export const allowNegative = (field: DataField) =>
  field.typeOptions?.allowNegative !== false;
