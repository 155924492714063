import createReactComponent from '../createReactComponent';
export default createReactComponent('pinned-off', 'IconPinnedOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 4.5l-3.249 3.249m-2.57 1.433l-2.181 .818l-1.5 1.5l7 7l1.5 -1.5l.82 -2.186m1.43 -2.563l3.25 -3.251',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 15l-4.5 4.5', key: 'svg-2' }],
  ['path', { d: 'M14.5 4l5.5 5.5', key: 'svg-3' }],
]);
