import { useMemo } from 'react';
import set from 'lodash/fp/set';
import tailwindTheme from '@noloco/ui/src/constants/tailwindTheme';

const useNolocoTheme = () => {
  const nolocoTheme = useMemo(
    () => set('brandColors.primary', 'cyan-500', tailwindTheme),
    [],
  );

  return nolocoTheme;
};

export default useNolocoTheme;
