import createReactComponent from '../createReactComponent';
export default createReactComponent('fingerprint', 'IconFingerprint', [
  ['path', { d: 'M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3', key: 'svg-0' }],
  ['path', { d: 'M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6', key: 'svg-1' }],
  ['path', { d: 'M12 11v2a14 14 0 0 0 2.5 8', key: 'svg-2' }],
  ['path', { d: 'M8 15a18 18 0 0 0 1.8 6', key: 'svg-3' }],
  [
    'path',
    { d: 'M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95', key: 'svg-4' },
  ],
]);
