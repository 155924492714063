export const permissionRuleFragment = `
id
name
description
roleIds
create
update
filter
fieldPermissions {
  id
  dataField {
    id
    name
    display
  }
  read
  update
  create
}`;

export const workflowFragment = `
id
enabled
name
description
trigger
referenceId
`;

export const workflowWithConfigFragment = `
${workflowFragment}
workflow {
  id
  config
}
`;

export const lookupFragment = `
id
sourceField {
  id
}
valueField {
  id
}
`;

export const rollupFragment = `
id
name
display
relatedField
field
aggregation
`;
export const autoRelationshipFragment = `
id
sourceFieldId
lookupFieldId
`;
export const fieldFragment = `
id
name
apiName
display
type
typeOptions
unique
order
relationship
reverseDisplayName
reverseName
internal
hidden
lookup {
  ${lookupFragment}
}
readOnly
required
rollup {
  ${rollupFragment}
}
options {
  id
  name
  display
  order
  color
}
source
multiple
autoRelationshipConfig{
  ${autoRelationshipFragment}
}
`;

export const dataTypesFragment = `
dataTypes {
  id
  name
  apiName
  display
  description
  internal
  fields {
    ${fieldFragment}
  }
  primaryField {
    id
    name
    apiName
  }
  workflows {
    ${workflowFragment}
  }
  permissions {
    ${permissionRuleFragment}
  }
  permissionsEnabled
  readOnly
  source {
    id
    type
    collectionType
    display
    syncEnabled
  }
}
`;

export const mediaFragment = `
  media {
    id
    name
    fileType
    size
    uri
    mimetype
  }`;

export const publicIntegrationsFragment = `
  integrations {
    stripe {
      id
      account {
        id
        chargesEnabled
      }
      publicKey
    }
    google {
      signIn {
        id
        clientId
      }
    }
  }`;

export const publicProjectWithoutDataTypesFragment = `
  id
  name
  elements
  settings
  spaces
  live
  hasExternalUsers
  internalDomains
  createdAt
  partnerTrialEnd
  ${mediaFragment}
  `;

export const publicProjectFragment = `
  ${publicProjectWithoutDataTypesFragment}
  ${dataTypesFragment}
  `;

export const PUBLIC_PROJECT_QUERY = `
  query($projectId: String, $domain: String, $published: Boolean) {
      publicProject(projectId: $projectId, domain: $domain, published: $published) {
        ${publicProjectFragment}
        ${publicIntegrationsFragment}
      }
  }
`;
export const dataTypeFragment = `
  id
  name
  apiName
  display
  description
  internal
  fields {
    ${fieldFragment}
  }
  primaryField {
    id
    name
    apiName
  }
  workflows {
    ${workflowFragment}
  }
  permissions {
    ${permissionRuleFragment}
  }
  permissionsEnabled
  readOnly
  rowCount
  source {
    id
    type
    collectionType
    connection
    display
    syncEnabled
  }
`;

export const domainsFragment = `
  domains {
    id
    name
    active
  }
`;

export const usersFragment = `
users {
  createdAt
  user {
    id
    email
    firstName
    lastName
  }
}`;

export const adminIntegrationsFragment = `
integrations {
  stripe {
    id
    account {
      id
      chargesEnabled
      country
      defaultCurrency
      payoutsEnabled
    }
  }
  airtable {
    id
    scopes
  }
  google {
    signIn {
      id
      clientId
    }
    drive {
      id
    }
  }
  docsAutomator {
    id
  }
  hubspot {
    id
    scopes
    scopeGroups
  }
  openAI {
    id
  }
  slack {
   id
   scopes
  }
  smartSuite {
    id
    domain
    scopes
  }
  smtp {
    id
    from
    host
    secure
    port
    username
    password
  }
}`;

export const apiKeysFragment = `
  apiKeys {
    user
    project
  }`;

export const creatorFragment = `
  creator {
    id
    createdAt
  }
`;

export const hasUnpublishedChangesFragment = `
  hasUnpublishedChanges`;

export const baseProjectFragment = `
  publishedVersion
  live
  template
  hasExternalUsers
  internalDomains
`;

const getProjectFragment = (publicProject: string) => `
  ${publicProject}
  ${baseProjectFragment}
  workflows
  ${hasUnpublishedChangesFragment}
  ${domainsFragment}
  ${usersFragment}
  ${adminIntegrationsFragment}
  ${apiKeysFragment}
  ${creatorFragment}
`;
export const projectFragment = getProjectFragment(publicProjectFragment);
export const projectWithoutDataTypesFragment = getProjectFragment(
  publicProjectWithoutDataTypesFragment,
);

export const getProjectQueryWithFragment = (fragment: string) => `
  query project ($projectId: String!) {
    project(projectId: $projectId) {
      ${fragment}
    }
  }
`;

export const PROJECT_QUERY_STRING =
  getProjectQueryWithFragment(projectFragment);

const projectWithTeamFragment = `  
  ${creatorFragment}
  team{
    id
    name
    plan {
      id
      type
    }
  }
  `;

export const PROJECT_QUERY_WITH_TEAM_STRING = getProjectQueryWithFragment(
  projectWithTeamFragment,
);

export const PROJECT_WITHOUT_DATA_TYPES_QUERY_STRING = `
query project ($projectId: String!) {
    project(projectId: $projectId) {
      ${projectWithoutDataTypesFragment}
    }
  }
`;

export const PROJECT_DOCUMENT_QUERY_STRING = `
query project ($projectId: String!) {
    project(projectId: $projectId) {
      id
      name
      elements
      workflows
      settings
      spaces
    }
  }
`;

export const PROJECT_DATA_TYPES_QUERY_STRING = `
  query publicProject ($projectId: String!) {
    publicProject(projectId: $projectId) {
      id
      dataTypes {
        ${dataTypeFragment}
      }
    }
  }
`;

export const PROJECT_HAS_UNPUBLISHED_CHANGES_QUERY =
  getProjectQueryWithFragment(`
  ${hasUnpublishedChangesFragment}
`);
