import React, { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { Surface } from '@noloco/components';
import DatePicker from '@noloco/core/src/components/DatePicker';
import { DATE as DATE_FORMAT } from '@noloco/core/src/constants/fieldFormats';
import { formatDateValue } from '@noloco/core/src/elements/sections/forms/DataFieldInput';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import { getDateFromValue } from '@noloco/core/src/utils/dates';
import DynamicSwitch from './DynamicSwitch';

interface StaticDateInputProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: StringPropSegment[] | null) => void;
  onSetDynamic: (dynamic: boolean) => void;
  field: DataField;
  placeholder: string | undefined;
  surface: Surface;
  value: StringPropSegment[];
}

const StaticDateInput = ({
  field,
  isOpen,
  value,
  onChange,
  onClose,
  onSetDynamic,
  placeholder,
  surface,
}: StaticDateInputProps) => {
  const dateValue = useMemo(() => {
    if (value.length === 0) {
      return null;
    }

    if (value.length === 1 && value[0].text) {
      const dateValue = getDateFromValue(value[0].text);

      if (dateValue?.isValid) {
        return dateValue;
      }
    }

    return null;
  }, [value]);

  const onDateChange = useCallback(
    (dateValue: DateTime | null) => {
      if (!dateValue) {
        onChange(null);
      } else {
        const isoDate = formatDateValue(dateValue, field.typeOptions?.format);

        onChange([{ text: isoDate, static: true }]);
      }
    },
    [field.typeOptions?.format, onChange],
  );

  return (
    <DatePicker
      //  @ts-expect-error DatePicker is not typed
      autoFocus={false}
      disabled={false}
      footer={
        <div className="border-t border-gray-500 pb-1 pt-3">
          <DynamicSwitch value={false} onChange={onSetDynamic} />
        </div>
      }
      selectTime={field.typeOptions?.format !== DATE_FORMAT}
      inline={false}
      value={dateValue}
      onChange={onDateChange}
      open={isOpen ? true : undefined}
      onOpenChange={isOpen ? onClose : undefined}
      surface={surface}
      placement="top-start"
      placeholder={placeholder || ''}
      timeZone={field.typeOptions?.timeZone}
      w="full"
    />
  );
};

export default StaticDateInput;
