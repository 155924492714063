import createReactComponent from '../createReactComponent';
export default createReactComponent('circle-off', 'IconCircleOff', [
  [
    'path',
    {
      d: 'M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
