import React, { useCallback } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge } from '@noloco/components';
import { BuildModeSectionType } from '../../constants/buildMode';
import useBoolLocalStorageState from '../../utils/hooks/useBoolLocalStorageState';

interface BuildModeSectionProps {
  children?: React.ReactNode;
  className?: string;
  count?: number;
  emptyState?: string;
  enabled?: boolean;
  endComponent?: JSX.Element;
  guide?: JSX.Element;
  id: BuildModeSectionType;
  keepOpen?: boolean;
  showEmptyState?: boolean;
  sticky?: boolean;
  title: string | JSX.Element;
}

const BuildModeSection = ({
  children,
  className,
  count = 0,
  emptyState,
  enabled = true,
  endComponent,
  guide,
  id,
  keepOpen = false,
  showEmptyState = false,
  sticky = false,
  title,
}: BuildModeSectionProps) => {
  const [isSectionExpanded, setIsSectionExpanded] = useBoolLocalStorageState(
    `noloco.buildMode.${id}.expanded`,
    true,
  );

  const handleOnClick = useCallback(() => {
    if (!keepOpen) {
      setIsSectionExpanded(!isSectionExpanded);

      if (!isSectionExpanded) {
        setTimeout(() => {
          const section = document.getElementById(`section-id-${id}`);
          section?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }, 100);
      }
    }
  }, [keepOpen, isSectionExpanded, id, setIsSectionExpanded]);

  const titleSection = (
    <div className={classNames('flex items-center space-x-2 text-slate-300')}>
      <span className="uppercase">{title}</span>
      {count > 0 && <Badge>{count}</Badge>}
    </div>
  );

  if (!enabled) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(
          'flex h-12 w-full select-none items-center justify-between border-slate-700 bg-slate-800 px-2',
          className,
          { 'sticky top-24 z-10': sticky },
        )}
      >
        <div
          className={classNames('flex items-center space-x-2 text-sm', {
            'text-slate-300': isSectionExpanded || keepOpen,
            'text-slate-400': !isSectionExpanded,
          })}
          onClick={handleOnClick}
        >
          <div className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-slate-700 hover:opacity-100">
            {isSectionExpanded || keepOpen ? (
              <IconChevronDown size={16} />
            ) : (
              <IconChevronRight size={16} />
            )}
          </div>
          <div className="flex cursor-pointer items-center space-x-2">
            {guide
              ? React.cloneElement(guide, {
                  ...guide.props,
                  ...((guide.props.arcade || guide.props.video) && {
                    offset: [0, 45],
                  }),
                  showTooltip: true,
                  tooltipLabel: guide.props.children,
                  children: titleSection,
                })
              : titleSection}
          </div>
        </div>
        {(isSectionExpanded || keepOpen) && endComponent}
      </div>
      {(isSectionExpanded || keepOpen) && (
        <div id={`section-id-${id}`}>
          {showEmptyState ? (
            <div className="flex items-center justify-center p-2 text-slate-400">
              {emptyState}
            </div>
          ) : (
            children
          )}
        </div>
      )}
    </>
  );
};

export default BuildModeSection;
