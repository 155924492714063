import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { IconPlaneDeparture } from '@tabler/icons-react';
import { Button, Loader, TextArea, Tooltip } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { APP_VERSIONS } from '@noloco/core/src/constants/features';
import { getText } from '@noloco/core/src/utils/lang';
import FeatureLockedButton from '../FeatureLockedButton';

export interface PublishSectionProps {
  setVersionHistoryModalIsOpen: (isOpen: boolean) => void;
  onPublishClick: (publishMessage: string) => void;
  publishMessage: string;
  isLoading: boolean;
  isPublishComplete: boolean;
  setPublishMessage: (value: string) => void;
}

const PublishSection: React.FC<PublishSectionProps> = ({
  setVersionHistoryModalIsOpen,
  setPublishMessage,
  onPublishClick,
  publishMessage,
  isLoading,
  isPublishComplete,
}) => (
  <section className="mt-2 flex w-full flex-col overflow-hidden rounded-md">
    <header className="my my-1 mt-1 flex w-full justify-between gap-1 self-start pt-2">
      <div className="flex gap-1">
        <h2 className="grow text-sm font-semibold text-black">
          {getText('toolbar.publish.publishMessage.label')}
        </h2>
        <Tooltip
          delayShow={400}
          content={
            <span className="text-sm">
              {getText('toolbar.publish.publishMessage.explain')}
            </span>
          }
          placement="right"
        >
          <IconInfoCircle size={16} className="text-slate-500" />
        </Tooltip>
      </div>
      <FeatureLockedButton
        type="link"
        feature={APP_VERSIONS}
        onClick={() => setVersionHistoryModalIsOpen(true)}
        iconClassName="text-slate-500"
      >
        <span className="my-auto pt-1 text-xs text-cyan-500 decoration-cyan-500">
          {getText('toolbar.publish.versionHistory.linkText')}
        </span>
      </FeatureLockedButton>
    </header>
    <TextArea
      bg="bg-white my-1"
      placeholder={getText('toolbar.publish.publishMessage.placeholder')}
      onChange={({ target: { value } }: any) => setPublishMessage(value)}
      className="text-gray-900"
      surface={LIGHT}
      rows={4}
    />
    <Button
      className="mt-3 whitespace-nowrap rounded-md px-16 py-1 text-base font-semibold text-white"
      disabled={isLoading || isPublishComplete}
      variant="primary"
      onClick={() => onPublishClick(publishMessage)}
    >
      <div className="flex justify-between">
        {!isLoading ? (
          <>
            <div></div>
            <span className="ml-2">{getText('toolbar.publish.button')}</span>
            <IconPlaneDeparture size={16} />
          </>
        ) : (
          <Loader size="sm" className="mx-auto" />
        )}
      </div>
    </Button>
  </section>
);

export default PublishSection;
