import { useCallback } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import {
  GENERATE_OTP_BACKUP_CODES_QUERY,
  GENERATE_OTP_QUERY,
  buildVerifyOTPBackupCodeMutation,
  buildVerifyOTPMutation,
} from '../../queries/auth';

interface UseOTPAuthProps<AuthUser> {
  userQueryObject: Record<string, any>;
  queryOptions?: MutationHookOptions;
  signOut: () => Promise<void>;
  finishLogin: (data: any) => AuthUser;
}

interface UseOTPAuthReturn<AuthUser> {
  generateOTP: (secondFactorAuthToken?: string) => Promise<Record<string, any>>;
  verifyOTP: (otp: string, secondFactorAuthToken?: string) => Promise<AuthUser>;
  generateOTPBackupCodes: () => Promise<Record<string, any>>;
  verifyOTPBackupCode: (
    token: string,
    secondFactorAuthToken?: string,
  ) => Promise<AuthUser>;
}

export const useOTPAuth = <AuthUser>({
  userQueryObject,
  queryOptions,
  signOut,
  finishLogin,
}: UseOTPAuthProps<AuthUser>): UseOTPAuthReturn<AuthUser> => {
  const verifyOTPQuery = buildVerifyOTPMutation(userQueryObject);
  const verifyOTPBackupCodeQuery =
    buildVerifyOTPBackupCodeMutation(userQueryObject);

  const [generateOTPMutation] = useMutation(GENERATE_OTP_QUERY, queryOptions);
  const [verifyOTPMutation] = useMutation(verifyOTPQuery, queryOptions);
  const [generateOTPBackupCodesMutation] = useMutation(
    GENERATE_OTP_BACKUP_CODES_QUERY,
    queryOptions,
  );
  const [verifyOTPBackupCodeMutation] = useMutation(
    verifyOTPBackupCodeQuery,
    queryOptions,
  );

  const generateOTP = useCallback(
    async (secondFactorAuthToken?: string) =>
      await generateOTPMutation({
        context: { ...(queryOptions?.context ?? {}), secondFactorAuthToken },
      }).then(({ data, errors }) => {
        if (errors && !data?.generateOTP) {
          throw errors;
        }

        return data.generateOTP;
      }),
    [queryOptions, generateOTPMutation],
  );

  const verifyOTP = useCallback(
    (token: string, secondFactorAuthToken?: string) => {
      if (!token) {
        throw new Error('OTP token is required');
      }

      return verifyOTPMutation({
        variables: { token },
        context: { ...(queryOptions?.context ?? {}), secondFactorAuthToken },
      }).then(({ data, errors }) => {
        if (errors && !data.verifyOTP) {
          throw errors;
        }

        return signOut().then(() => finishLogin(data.verifyOTP));
      });
    },
    [queryOptions, verifyOTPMutation, finishLogin, signOut],
  );

  const generateOTPBackupCodes = useCallback(
    () =>
      generateOTPBackupCodesMutation().then(({ data, errors }) => {
        if (errors && !data.generateOTPBackupCodes) {
          throw errors;
        }

        return data.generateOTPBackupCodes;
      }),
    [generateOTPBackupCodesMutation],
  );

  const verifyOTPBackupCode = useCallback(
    (code: string, secondFactorAuthToken?: string) =>
      verifyOTPBackupCodeMutation({
        variables: { code },
        context: { ...(queryOptions?.context ?? {}), secondFactorAuthToken },
      }).then(({ data, errors }) => {
        if (errors && !data.verifyOTPBackupCode) {
          throw errors;
        }

        return signOut().then(() => finishLogin(data.verifyOTPBackupCode));
      }),
    [queryOptions, signOut, finishLogin, verifyOTPBackupCodeMutation],
  );

  return {
    generateOTP,
    verifyOTP,
    generateOTPBackupCodes,
    verifyOTPBackupCode,
  };
};
