import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { Variant } from '@noloco/components/src/constants/variants';
import { ActionButtonExecutionType } from '../constants/actionButtons';
import { ActionType } from '../constants/actionTypes';
import { AuthVisibilityRule } from '../constants/authVisibilityRules';
import { DeviceVisibilityRule } from '../constants/deviceRuleVisibilityRules';
import { ElementType } from '../constants/elements';
import { FileDisplay } from '../constants/fileDisplay';
import { InternalUserVisibilityRule } from '../constants/internalUsersVisibilityRules';
import { LinkType } from '../constants/linkTypes';
import { Operator } from '../constants/operators';
import { RoleVisibilityRule } from '../constants/roleVisibilityRules';
import { WorkflowActionType } from '../constants/workflowActionTypes';
import { IconValue } from './IconValue';

export type ID = string | number;

export interface DepValue {
  args?: Record<any, any>;
  dataType?: string;
  display?: string;
  id: string | number;
  path: string;
  source?: string;
}

export interface StringPropSegment {
  text?: string;
  data?: DepValue;
  static?: boolean;
}

export type StringPropValue = StringPropSegment[];

export interface Condition {
  field: DepValue;
  operator: Operator;
  result: undefined | StringPropValue;
  value?: undefined | StringPropValue;
}

export interface VisibilityRules {
  auth?: AuthVisibilityRule | undefined;
  customRules?: Condition[][];
  deviceRule?: DeviceVisibilityRule;
  roleRule?: RoleVisibilityRule | undefined;
  roles?: string[];
  spaces?: string[];
  type?: InternalUserVisibilityRule | undefined;
}

export interface ViewTab {
  id: string;
  title: string;
  visibilityRules?: VisibilityRules;
}

export interface Section {
  id: string;
  type: string;
  tab?: string;
}

export interface FileLayout {
  display?: FileDisplay;
  size?: ShirtSize;
}

export interface Navigate {
  email: string | undefined;
  page: string[] | undefined;
  pageData: Record<any, any>;
  phone: string | undefined;
  subject: string | undefined;
  url: string | undefined;
  newTab: boolean | undefined;
  type: LinkType;
}

export interface BaseAction {
  id: string;
  type: ActionType | WorkflowActionType;
}

export enum ScanActionType {
  'CREATE' = 'CREATE',
  'UPDATE' = 'UPDATE',
  'NAVIGATE' = 'NAVIGATE',
}

export interface AddCommentActionConfig {
  note?: boolean;
  body?: StringPropValue;
}

export type Action = BaseAction & {
  barcodeField?: DepValue;
  copy?: { value: string };
  dataType?: string;
  detailsFields?: any[];
  field?: string;
  fields?: Record<string, DepValue>;
  formFields?: any[];
  iframe?: { src: string; width?: ShirtSize };
  multiScan?: boolean;
  navigate?: Navigate;
  redirect?: boolean;
  scanActionType?: ScanActionType;
  size?: ShirtSize;
  type: ActionType;
  workflow?: string;
  addComment?: AddCommentActionConfig;
};

export type NavigateAction = Action & {
  navigate: Navigate;
};

export interface ActionButton {
  id: string;
  appearance?: string;
  execution?: ActionButtonExecutionType;
  buttonText?: string;
  actions: Action[];
  icon?: IconValue;
  title?: string;
  description?: string;
  tooltip?: string;
  visibilityRules?: VisibilityRules;
  notification?: {
    enabled?: boolean;
    text?: string;
    type?: Variant | 'confetti';
  };
}

export interface FieldConfigValue {
  name: string;
}

export type ElementPathSegment = number | string;
export type ElementPath = ElementPathSegment[];

export interface Element {
  actions?: any[];
  children?: Element[];
  container?: string;
  id: string;
  name?: string;
  props: Record<string, any>;
  spaces?: Record<string, { order: number }>;
  static?: boolean;
  type: ElementType;
  visibilityRules?: VisibilityRules;
}
