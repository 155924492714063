import { useCallback, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import BuildModeSection from '@noloco/core/src/components/buildMode/BuildModeSection';
import { WithDropable } from '@noloco/core/src/components/withDnD';
import { RECORD_QUICK_LINKS } from '@noloco/core/src/constants/buildMode';
import { LINK } from '@noloco/core/src/constants/draggableItemTypes';
import { QUICK_LINKS } from '@noloco/core/src/constants/elements';
import Icon from '@noloco/core/src/elements/Icon';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import useItemListEditor from '@noloco/core/src/utils/hooks/useItemListEditor';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import QuickLinkEditor, { getLinkTitle } from './QuickLinkEditor';

interface QuickLinksEditorProps {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  links: any[];
  project: Project;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
}

const QuickLinksEditor = ({
  dataType,
  debouncedUpdateProperty,
  elementPath,
  links,
  project,
  sectionPropPath,
  updateProperty,
}: QuickLinksEditorProps) => {
  const [activeListItem, setActiveListItem] = useState<number | null>(null);
  const [popoutOpen, setPopoutOpen] = useState(false);

  const createNewQuickLink = useCallback(
    () => ({
      id: shortId.generate(),
      title: [{ text: 'Link title' }],
      description: [{ text: 'Link description' }],
      icon: { name: 'Calendar' },
    }),
    [],
  );

  const {
    formatItem,
    draftItems,
    findItem,
    onSaveOrder,
    onRemoveItem,
    onCloneItem,
    onAddNewItem,
    onDebounceUpdateItem,
    onUpdateItem,
  } = useItemListEditor(
    links,
    updateProperty,
    debouncedUpdateProperty,
    createNewQuickLink,
    ['links'],
    false,
  );

  const listOptions = useMemo(
    () =>
      draftItems.map((draftItem, index) => ({
        icon: draftItem.icon && (
          <Icon className="h-4 w-4" icon={draftItem.icon} />
        ),
        label: getLinkTitle(draftItem.title, index),
        value: index,
      })),
    [draftItems],
  );

  return (
    <BuildModeSection
      id={RECORD_QUICK_LINKS}
      className="border-t p-2"
      endComponent={
        <div
          className="flex cursor-pointer items-center justify-center rounded-md p-1 text-gray-300 opacity-75 hover:bg-gray-700 hover:opacity-100"
          onClick={onAddNewItem}
        >
          <IconPlus size={16} />
        </div>
      }
      title={getText('elements', QUICK_LINKS, 'link')}
    >
      <div className="space-y-2 p-2">
        {draftItems.map((link: any, index: any) => (
          <QuickLinkEditor
            activeListItem={activeListItem}
            dataType={dataType}
            debouncedUpdateProperty={onDebounceUpdateItem}
            draggable={true}
            elementPath={elementPath}
            findItem={findItem}
            index={index}
            item={formatItem(link)}
            key={link.id}
            listOptions={listOptions}
            link={link}
            onClone={onCloneItem}
            onRemove={onRemoveItem}
            onSaveOrder={onSaveOrder}
            popoutOpen={popoutOpen}
            project={project}
            sectionPropPath={sectionPropPath}
            setActiveListItem={setActiveListItem}
            setPopoutOpen={setPopoutOpen}
            updateProperty={onUpdateItem}
          />
        ))}
      </div>
    </BuildModeSection>
  );
};

QuickLinksEditor.displayName = 'QuickLinksEditor';

export default WithDropable(QuickLinksEditor, LINK);
