import QRCode from 'react-qr-code';
import { Button, Loader } from '@noloco/components/src';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '../../../utils/lang';

export const AuthUrl = ({
  otpAuthUrl,
  base32,
  onNext,
}: {
  otpAuthUrl: string | undefined;
  base32: string | undefined;
  onNext: () => void;
}) => (
  <div className="flex flex-col items-center space-y-4">
    <h3 className="my-3 text-center text-base font-medium tracking-wider">
      {getText('auth.twoFactorAuth.scanQRCode')}
    </h3>
    {!otpAuthUrl ? (
      <Loader size={MD} />
    ) : (
      otpAuthUrl && <QRCode value={otpAuthUrl} />
    )}
    <div className="my-6 flex w-full items-center">
      <span className="h-[1px] w-full bg-gray-300" />
      <span className="mx-3 text-center text-sm uppercase text-gray-500">
        {getText('auth.twoFactorAuth.or')}
      </span>
      <span className="h-[1px] w-full bg-gray-300" />
    </div>
    <div className="w-full text-left">
      <h3>{getText('auth.twoFactorAuth.enterCode')}</h3>
      <h3 className="font-bold">{base32}</h3>
    </div>
    <h3>{getText('auth.twoFactorAuth.proceed')}</h3>
    <Button onClick={onNext}>{getText('auth.twoFactorAuth.next')}</Button>
  </div>
);
