import React, { useMemo, useState } from 'react';
import {
  IconCalendarEvent,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Button, DatePickerPopover, Loader, Popover } from '@noloco/components';
import { Navigate } from '@noloco/react-big-calendar';
import { Views } from '@noloco/react-big-calendar';
import { TWO_WEEK } from '../../../../constants/collection';
import { darkModeColors } from '../../../../constants/darkModeColors';
import {
  COMMA,
  KEY_2,
  KEY_D,
  KEY_G,
  KEY_M,
  KEY_T,
  KEY_W,
  KEY_X,
  PERIOD,
  ShortcutKeyType,
} from '../../../../constants/shortcuts';
import { editorModeSelector } from '../../../../selectors/elementsSelectors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../../utils/hooks/useDarkModeSurface';
import useOnKeyPress from '../../../../utils/hooks/useOnKeyPress';
import { getText } from '../../../../utils/lang';
import KeyboardShortcutTooltip from '../../view/KeyboardShortcutTooltip';
import ShortcutKeys from '../../view/ShortcutKeys';

const LANG_KEY = 'elements.COLLECTION.calendar';

const getShortcutKeyForView: Record<string, ShortcutKeyType> = {
  [TWO_WEEK]: KEY_2,
  [Views.DAY]: KEY_D,
  [Views.MONTH]: KEY_M,
  [Views.WEEK]: KEY_W,
  [Views.WORK_WEEK]: KEY_X,
};

const CalendarToolbar = ({
  date,
  enableShortcuts,
  label,
  loading,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const surface = useDarkModeSurface();
  const editorMode = useSelector(editorModeSelector);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);

  const shortcutsEnabled = useMemo(
    () => enableShortcuts && !editorMode,
    [editorMode, enableShortcuts],
  );

  useOnKeyPress(KEY_M, () => onView(Views.MONTH), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(KEY_2, () => onView(TWO_WEEK), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(KEY_W, () => onView(Views.WEEK), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(KEY_X, () => onView(Views.WORK_WEEK), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(KEY_D, () => onView(Views.DAY), {
    enabled: shortcutsEnabled,
    useKeyCode: true,
  });

  useOnKeyPress(KEY_T, () => onNavigate(Navigate.TODAY), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(COMMA, () => onNavigate(Navigate.PREVIOUS), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(PERIOD, () => onNavigate(Navigate.NEXT), {
    enabled: shortcutsEnabled,
  });

  useOnKeyPress(KEY_G, () => setIsDatePopoverOpen(!isDatePopoverOpen), {
    enabled: shortcutsEnabled,
  });

  return (
    <div className="mb-4 mt-2 flex items-center px-8 sm:px-2">
      <DatePickerPopover
        clearable={false}
        onChange={(val: any) => onNavigate(Navigate.DATE, val.toJSDate())}
        onOpenChange={setIsDatePopoverOpen}
        open={isDatePopoverOpen}
        value={date ? DateTime.fromJSDate(date) : null}
      >
        {() => (
          <div>
            <KeyboardShortcutTooltip
              buildMode={false}
              disabled={!shortcutsEnabled}
              keys={[KEY_G]}
              label={getText(LANG_KEY, 'datePicker')}
              offset={[0, 8]}
            >
              <div className="group flex h-8 cursor-pointer items-center justify-center space-x-2 rounded-lg border border-gray-300 bg-white px-3 text-sm text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:shadow-none focus:outline-none sm:h-10 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800">
                <h2 className="tracking-wider">{label}</h2>
                <IconChevronDown
                  className="hidden opacity-50 group-hover:block"
                  size={20}
                />
              </div>
            </KeyboardShortcutTooltip>
          </div>
        )}
      </DatePickerPopover>
      <div className="ml-auto flex items-center">
        <div className="mr-4 flex items-center sm:mx-2">
          {loading && <Loader className="mr-4" size="sm" />}
          <KeyboardShortcutTooltip
            buildMode={false}
            disabled={!shortcutsEnabled}
            keys={[COMMA]}
            label={getText(
              { view: getText(LANG_KEY, 'views', view) },
              LANG_KEY,
              'navigation.previous',
            )}
            offset={[0, 8]}
          >
            <button
              className={`rounded-bl-lg rounded-tl-lg border p-1.5 sm:h-10 ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two} hover:${darkModeColors.surfaces.elevation2}`
                  : 'border-gray-300 bg-white hover:bg-gray-100'
              }`}
              type="button"
              onClick={() => onNavigate(Navigate.PREVIOUS)}
              aria-label={messages?.previous}
            >
              <IconChevronLeft className="my-px opacity-75" size={15} />
            </button>
          </KeyboardShortcutTooltip>
          <KeyboardShortcutTooltip
            buildMode={false}
            disabled={!shortcutsEnabled}
            keys={[PERIOD]}
            label={getText(
              { view: getText(LANG_KEY, 'views', view) },
              LANG_KEY,
              'navigation.next',
            )}
            offset={[0, 8]}
          >
            <button
              className={`rounded-br-lg rounded-tr-lg border border-l-0 p-1.5 sm:h-10 ${
                isDarkModeEnabled
                  ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.two} hover:${darkModeColors.surfaces.elevation2}`
                  : 'border-gray-300 bg-white hover:bg-gray-100'
              }`}
              type="button"
              onClick={() => onNavigate(Navigate.NEXT)}
              aria-label={messages?.next}
            >
              <IconChevronRight className="my-px opacity-75" size={15} />
            </button>
          </KeyboardShortcutTooltip>
          <KeyboardShortcutTooltip
            buildMode={false}
            disabled={!shortcutsEnabled}
            keys={[KEY_T]}
            label={getText(LANG_KEY, 'today')}
            offset={[0, 8]}
          >
            <Button
              variant="secondary"
              className="ml-2 flex items-center"
              onClick={() => onNavigate(Navigate.TODAY)}
              isDarkModeEnabled={isDarkModeEnabled}
            >
              <span className="sm:hidden">{getText(LANG_KEY, 'today')}</span>
              <IconCalendarEvent className="hidden sm:block" size={18} />
            </Button>
          </KeyboardShortcutTooltip>
        </div>
        <Popover
          closeOnClick={true}
          content={
            <div className="flex w-40 flex-col p-2 text-left">
              {views.map((viewName: string) => (
                <button
                  className={classNames(
                    `flex items-center justify-between space-x-2 rounded-lg p-2 text-left text-sm`,
                    {
                      [`hover:bg-gray-600 ${darkModeColors.text.primary}`]:
                        isDarkModeEnabled,
                      'hover:bg-gray-200': !isDarkModeEnabled,
                    },
                  )}
                  key={viewName}
                  onClick={() => onView(viewName)}
                >
                  <span>{getText(LANG_KEY, 'views', viewName)}</span>
                  {shortcutsEnabled && (
                    <ShortcutKeys
                      background={false}
                      keys={[getShortcutKeyForView[viewName]]}
                      surface={surface}
                    />
                  )}
                </button>
              ))}
            </div>
          }
          p={0}
          placement="bottom-end"
          rounded="lg"
          shadow="lg"
          showArrow={false}
          surface={surface}
        >
          <Button variant="secondary" className="flex items-center">
            <span>{getText(LANG_KEY, 'views', view)}</span>
            <IconChevronDown className="ml-4 opacity-50" size={16} />
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default CalendarToolbar;
