import React, { useCallback, useMemo } from 'react';
import { IconArrowLeft, IconChevronRight } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  LeftEditorSectionOptions,
  OPTIONS,
  VISIBILITY,
} from '../../../constants/buildMode';
import { RECORD } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { ElementPath } from '../../../models/Element';
import {
  setLeftEditorSection,
  setSelectedSectionPath,
} from '../../../reducers/elements';
import { selectedSectionSelector } from '../../../selectors/elementsSelectors';
import { projectDataSelector } from '../../../selectors/projectSelectors';
import useEditorTabs from '../../../utils/hooks/useEditorTabs';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import BuildModeVisibilityTab from '../BuildModeVisibilityTab';
import StandaloneRightBuildMode from '../StandaloneRightBuildMode';
import BuildModeRecordOptionsTab from './BuildModeRecordOptionsTab';

export interface BuildModeRecordEditorProps {
  elementPath: ElementPath;
  leftEditor?: LeftEditorSectionOptions | null;
  page: Page;
}

const DEBOUNCE_TIME = 300;
const EditorTabMap = {
  [OPTIONS]: BuildModeRecordOptionsTab,
  [VISIBILITY]: BuildModeVisibilityTab,
};

const BuildModeRecordEditor = ({
  elementPath,
  leftEditor,
  page,
}: BuildModeRecordEditorProps) => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const selectedSectionPath = useSelector(selectedSectionSelector);
  const sections = get(page, 'props.record.sections');
  const section = get(sections, selectedSectionPath);
  const [updateProperty] = useUpdateProperty(elementPath, project);
  const updateRecordProps = useCallback(
    (path, value) => updateProperty(['record', ...path], value),
    [updateProperty],
  );
  const debounceUpdateRecordProps = useMemo(
    () => debounce(updateRecordProps, DEBOUNCE_TIME),
    [updateRecordProps],
  );

  const [editorTab, setEditorTab] = useEditorTabs('record', OPTIONS);
  const dataType = useMemo(() => {
    const typeName = get(page.props.dataList, 'dataType');

    return typeName && project.dataTypes.getByName(typeName);
  }, [page, project.dataTypes]);

  const Editor = useMemo(
    () => EditorTabMap[editorTab] ?? BuildModeRecordOptionsTab,
    [editorTab],
  );

  return (
    <div className="w-full">
      <div className="sticky top-0 z-10 flex h-12 w-full items-center space-x-2 border-b border-gray-700 bg-slate-800 px-2 text-gray-300">
        {leftEditor ? (
          <IconArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => {
              dispatch(setSelectedSectionPath([]));
              dispatch(setLeftEditorSection(null));
            }}
          />
        ) : (
          <Icon icon={page.props.icon} className="h-5 w-5" />
        )}
        <div className="flex items-center space-x-2">
          <span>{page.props.name}</span>
          <IconChevronRight size={12} className="text-gray-400" />
          <span>{getText('leftSidebar.auth.baseRecord')}</span>
          {leftEditor && (
            <>
              <IconChevronRight size={12} className="text-gray-400" />
              <span>
                {section?.name ?? getText('elements', leftEditor, 'title')}
              </span>
            </>
          )}
        </div>
      </div>
      {leftEditor ? (
        <StandaloneRightBuildMode
          dataType={dataType}
          debouncedUpdateProperty={debounceUpdateRecordProps}
          element={page}
          elementPath={elementPath}
          elementType={RECORD}
          project={project}
          section={section}
          selectedSectionPath={selectedSectionPath}
          updateProperty={updateRecordProps}
        />
      ) : (
        <>
          <div className="sticky top-12 z-10 w-full bg-slate-800">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={RECORD}
              setEditorTab={setEditorTab}
            />
          </div>
          <Editor
            dataType={dataType}
            debouncedUpdateProperty={debounceUpdateRecordProps}
            element={page}
            elementPath={elementPath}
            project={project}
            updateProperty={updateRecordProps}
            updateViewProperty={updateProperty}
            visibilityRules={page.visibilityRules}
          />
        </>
      )}
    </div>
  );
};

export default BuildModeRecordEditor;
