import React, { memo } from 'react';
import { IconBan, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import { Tooltip } from '@noloco/components';
import {
  DELETE,
  DISABLE,
} from '@noloco/core/src/constants/deleteDataTypeActions';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { deleteDataTypeAction } from '@noloco/core/src/utils/deleteDataTypes';
import { getText } from '@noloco/core/src/utils/lang';

interface DeleteDataTypeButtonProps {
  className: string;
  dataType: DataType;
  groupSize: number;
  onDelete: () => void;
}

const DeleteDataTypeButton = memo(
  ({ className, dataType, groupSize, onDelete }: DeleteDataTypeButtonProps) => {
    const action = deleteDataTypeAction(dataType);

    if (!action) {
      return null;
    }
    const actionText = camelCase(action);

    let children;
    let disabled;

    if (action === DELETE) {
      children = <IconTrash size={16} />;
      disabled = ['user', 'company'].includes(dataType.name);
    }

    if (action === DISABLE) {
      children = <IconBan size={16} />;
      disabled = groupSize === 1;
    }

    return (
      <Tooltip content={getText('leftSidebar.data', actionText)}>
        <button
          className={classNames(
            className,
            disabled
              ? 'text-gray-600 opacity-75'
              : 'text-gray-500 hover:text-white',
          )}
          onClick={onDelete}
          disabled={disabled}
        >
          {children}
        </button>
      </Tooltip>
    );
  },
);

export default DeleteDataTypeButton;
