import React, { useCallback, useState } from 'react';
import { IconGitFork, IconX } from '@tabler/icons-react';
import get from 'lodash/get';
import shortid from 'shortid';
import { Button } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import OrFilterBranchInput from './OrFilterBranchInput';

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

interface OwnOrFilterInputProps {}

// @ts-expect-error TS(2456): Type alias 'OrFilterInputProps' circularly referen... Remove this comment to see the full error message
type OrFilterInputProps = OwnOrFilterInputProps &
  typeof OrFilterInput.defaultProps;

// @ts-expect-error TS(7022): 'OrFilterInput' implicitly has type 'any' because ... Remove this comment to see the full error message
const OrFilterInput = ({
  additionalScopeItems = [],
  elementPath,
  generateAdditionalFilter,
  project,
  onChange,
  onRemove,
  dataType,
  value,
  ValueInput,
}: OrFilterInputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const handleOrBranchAdded = useCallback(() => {
    const branches = [
      ...localValue.branches,
      {
        filters: [generateAdditionalFilter()],
        id: shortid.generate(),
      },
    ];
    const newLocalValue = {
      ...localValue,
      branches,
    };

    setLocalValue(newLocalValue);
    onChange(newLocalValue);

    return newLocalValue;
  }, [generateAdditionalFilter, localValue, onChange]);

  return (
    <div className="bg-brand-darker grid grid-flow-row items-center space-x-2 rounded-lg">
      <div className="relative rounded-lg p-4">
        <button
          onClick={onRemove}
          className="bg-brand-light absolute right-0 top-0 -mr-2 -mt-2 flex items-center justify-center rounded-full p-1 text-white hover:bg-pink-500"
        >
          <IconX size={12} />
        </button>
        {get(localValue, 'branches', []).map(
          (branch: any, branchIndex: any) => (
            <OrFilterBranchInput
              additionalScopeItems={additionalScopeItems}
              branch={branch}
              dataType={dataType}
              elementPath={elementPath}
              generateAdditionalFilter={generateAdditionalFilter}
              index={branchIndex}
              key={branch.id}
              onChange={onChange}
              project={project}
              setValue={setLocalValue}
              value={localValue}
              ValueInput={ValueInput}
            />
          ),
        )}
        <div className="flex">
          <Button
            className="mt-2 flex items-center"
            onClick={handleOrBranchAdded}
            variant="secondary"
          >
            <IconGitFork className="mr-2" size={16} />
            <span>{getTranslation('addBranchToOr')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

OrFilterInput.defaultProps = {
  additionalScopeItems: [],
};

export default OrFilterInput;
