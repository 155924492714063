import createReactComponent from '../createReactComponent';
export default createReactComponent('coin', 'IconCoin', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 1 0 0 4h2a2 2 0 1 1 0 4h-2a2 2 0 0 1 -1.8 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 7v10', key: 'svg-2' }],
]);
