import React, { useCallback } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconFileAlert } from '@tabler/icons-react';
import { Theme, getColorShade } from '@noloco/components';
import { UPDATE } from '../../../constants/actionTypes';
import { DataType } from '../../../models/DataTypes';
import { Action } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import { MutationType } from '../../../queries/project';
import useActionButtonFormFields from '../../../utils/hooks/useActionButtonFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import { getText } from '../../../utils/lang';
import FormSection from '../FormSection';
import { COMPACT } from '../forms/AutoFormSection';

const LANG_KEY = 'elements.VIEW.actionButtons';

interface ActionButtonFormProps {
  action: Action;
  dataType: DataType;
  onLoadingChange: any;
  onSuccess: any;
  project: Project;
  record?: BaseRecord;
  scope?: Record<string, any>;
  theme: Theme;
}

const ActionButtonForm = ({
  action,
  dataType,
  onLoadingChange,
  onSuccess,
  project,
  record,
  scope,
  theme,
}: ActionButtonFormProps) => {
  const { id, type = UPDATE, field, formFields = [] } = action;
  const errorColor = theme.brandColorGroups.warning;

  const { actionDataType, fields, mutationType, recordId } =
    useActionButtonFormFields(
      dataType,
      field,
      formFields,
      project,
      record,
      type as MutationType, //TODO fix this
    );
  const sections = useFormSections(formFields);

  const transformRecordScope = useCallback(
    (formScope) => ({
      ...scope,
      ...formScope,
    }),
    [scope],
  );

  if (!actionDataType) {
    return null;
  }

  if (type === UPDATE && !recordId) {
    return (
      <Box
        bg={getColorShade(errorColor, '400')}
        className="mb-6 mt-2 flex items-center rounded-lg p-4 text-white"
      >
        <IconFileAlert className="mr-3 h-6 w-6 opacity-50" />
        <div className="flex flex-col">
          <span className="text-sm font-medium">
            {getText(LANG_KEY, 'errors.UPDATE.notFound.title')}
          </span>
          <div className="mt-1 flex flex-col space-y-1 text-xs">
            <span>{getText(LANG_KEY, 'errors.UPDATE.notFound.message')}</span>
          </div>
        </div>
      </Box>
    );
  }

  return (
    actionDataType && (
      <FormSection
        className={`action-${id}-form`}
        dataType={actionDataType.name}
        fields={fields}
        sectionFormat={COMPACT}
        sections={sections}
        type={mutationType}
        project={project}
        onLoadingChange={onLoadingChange}
        onSuccess={onSuccess}
        showSuccess={false}
        successMessage={{
          message: '',
        }}
        errorMessage={{
          message: getText('core.COLLECTION.form.invalid'),
        }}
        which={recordId}
        transformRecordScope={transformRecordScope}
      />
    )
  );
};

export default withTheme(ActionButtonForm);
