import React from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Modal } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

const ExitFormWarningModal = ({ onConfirm, onClose }: Props) => (
  <Modal
    icon={<IconAlertTriangle size={18} />}
    title={getText('data.fields.exitForm.title')}
    confirmText={getText('data.fields.exitForm.confirm')}
    cancelText={getText('data.fields.exitForm.cancel')}
    onClose={onClose}
    onCancel={onClose}
    onConfirm={onConfirm}
    data-testid="exit-form-warning-modal"
    variant="warning"
  >
    <p className="mb-4">{getText('data.fields.exitForm.body')}</p>
  </Modal>
);

export default ExitFormWarningModal;
