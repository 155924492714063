import gql from 'graphql-tag';
import { buildMutationQueryString, buildQueryString } from './project';

const usageFragment = `
  usage {
    plan {
      activeUsers
      activeWorkflows
      builders
      internalActiveUsers
      externalActiveUsers
      projects
      rollingActiveUsers
      seats
      teamMembers
      workflowRuns
    }
    projects {
      id
      name
      addons {
        externalUsers
      }
      usage {
        externalUsers
        filesGb
        userRoles
      }
    }
  }
`;

export const PLAN_FRAGMENT = `
  id
  type
  customerId
  subscriptionId
  paymentMethodId
  createdAt
  status
  interval
  trialEnd
  periodEnd
  subscriptionChangedAt
  customConfig
`;

export const teamFragment = `
    id
    name
    plan {
      ${PLAN_FRAGMENT}
      balance
    }
    users {
      id
      firstName
      lastName
      email
    }
`;

export const userFragment = `
  id
  email
  firstName
  lastName
  onboardingDate
  onboardingCompleted
  referralToken
  profilePicture {
    id
    uri
    name
    size
    fileType
    mimetype
    encoding
  }
  team {
    ${teamFragment}
  }
  teams {
    ${teamFragment}
  }
  integrations {
    airtable {
      id
    }
  }
`;

export const REGISTRATION_MUTATION = gql`
  mutation register(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
  ) {
    register(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
    ) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const ACCEPT_INVITE_MUTATION = gql`
  mutation acceptInvite(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $firstName: String!
    $lastName: String!
    $invitationToken: String!
  ) {
    acceptInvite(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
      invitationToken: $invitationToken
    ) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const MAGIC_LINK_LOGIN_MUTATION = gql`
  mutation magicLinkLogin($email: String!) {
    magicLinkLogin(email: $email)
  }
`;

export const GOOGLE_LOGIN_MUTATION = gql`
  mutation googleLogin($tokenId: String!) {
    googleLogin(tokenId: $tokenId) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const FETCH_INVITATION_QUERY = gql`
  query invitation($token: String!) {
    invitation(token: $token) {
      email
      firstName
      lastName
    }
  }
`;

export const RECOVER_PASSWORD_MUTATION = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(email: $email) {
      email
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $email: String!
    $resetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      email: $email
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
    ) {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      token
      user {
        ${userFragment}
      }
    }
  }
`;

const teamUsageFragment = `
    id
    name
    plan {
      id
      ${usageFragment}
    }
`;

export const CURRENT_USER_BILLING_QUERY = gql`
  query currentUser {
    currentUser {
      user {
        id
        team {
          ${teamUsageFragment}
        }
        teams {
          ${teamUsageFragment}
        }
      }
    }
  }
`;

export const GENERATE_OTP_QUERY = gql`
  mutation generateOTP {
    generateOTP {
      otpAuthUrl
      base32
    }
  }
`;

export const GENERATE_OTP_BACKUP_CODES_QUERY = gql`
  mutation generateOTPBackupCodes {
    generateOTPBackupCodes {
      codes
    }
  }
`;

export const buildCurrentUserQuery = (userFields = {}) => {
  const queryBuilder = buildQueryString({});

  return gql`
    ${queryBuilder(
      'currentUser',
      {},
      {
        token: true,
        id: true,
        user: userFields,
        uuid: undefined,
      },
    )}
  `;
};

export const buildLoginMutation = (userFields = {}) => {
  const queryBuilder = buildMutationQueryString({
    email: 'String!',
    password: 'String!',
  });

  return gql`
    ${queryBuilder(
      'login',
      {},
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildGoogleLoginMutation = (userFields = {}) => {
  const queryBuilder = buildMutationQueryString({
    tokenId: 'String!',
  });

  return gql`
    ${queryBuilder(
      'googleLogin',
      {},
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildRegisterMutation = (userFields = {}, userArgs = {}) => {
  const queryBuilder = buildMutationQueryString({
    password: 'String!',
    confirmPassword: 'String!',
  });

  return gql`
    ${queryBuilder(
      'register',
      { ...userArgs, email: 'String!' },
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildAcceptInviteMutation = (userFields = {}, userArgs = {}) => {
  const queryBuilder = buildMutationQueryString({
    password: 'String!',
    confirmPassword: 'String!',
  });

  return gql`
    ${queryBuilder(
      'acceptInvite',
      {
        ...userArgs,
        invitationToken: 'String!',
      },
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildRecoverPasswordMutation = () => {
  const queryBuilder = buildMutationQueryString({
    email: 'String!',
  });

  return gql`
    ${queryBuilder(
      'recoverPassword',
      {},
      {
        email: true,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildResetPasswordMutation = (userFields = {}) => {
  const queryBuilder = buildMutationQueryString({
    email: 'String!',
    resetToken: 'String!',
    password: 'String!',
    confirmPassword: 'String!',
  });

  return gql`
    ${queryBuilder(
      'resetPassword',
      {},
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildVerifyOTPMutation = (userFields = {}) => {
  const queryBuilder = buildMutationQueryString({
    token: 'String!',
  });

  return gql`
    ${queryBuilder(
      'verifyOTP',
      {},
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};

export const buildVerifyOTPBackupCodeMutation = (userFields = {}) => {
  const queryBuilder = buildMutationQueryString({
    code: 'String!',
  });

  return gql`
    ${queryBuilder(
      'verifyOTPBackupCode',
      {},
      {
        token: true,
        user: userFields,
        id: undefined,
        uuid: undefined,
      },
    )}
  `;
};
