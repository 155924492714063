import { Suspense, lazy } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import Loader from '@noloco/components/src/components/loading/Loader';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import useBoolLocalStorageState from '@noloco/core/src/utils/hooks/useBoolLocalStorageState';
import useHasFeatureFlag, {
  AI_TABLE,
} from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import AdminLoadableFallback from './AdminLoadableFallback';
import { LeftTabs } from './AdminNavbar';
import VideoModal from './VideoModal';
import AddCsv from './dataTable/AddCsv';
import DataSourceSidebar from './leftSidebar/DataSourceSidebar';
import DataTabActionBar from './leftSidebar/DataTabActionBar';

const LazyDataTable = lazy(() => import('./dataTable/DataTable'));
const LazyDataTypeEditorManager = lazy(
  () => import('./leftSidebar/data/DataTypeEditorManager'),
);

const DataTabRoutes = ({ userFirstName }: { userFirstName: string }) => {
  const { push } = useRouter();
  const aiTableEnabled = useHasFeatureFlag(AI_TABLE);
  const project = useSelector(projectDataSelector);

  const [isSidebarOpen, setIsSidebarOpen] = useBoolLocalStorageState(
    'dataTable.sidebar.expanded',
    true,
  );
  const { isBuilder } = useIsBuilder();

  return (
    <Switch>
      <Route path={`/_/${LeftTabs.DATA}/internal/:dataType?`}>
        <Switch>
          {isBuilder && (
            <Route path={`/_/${LeftTabs.DATA}/internal/~new`}>
              <div className="flex h-full max-h-full max-w-full flex-grow flex-col overflow-hidden bg-slate-700">
                <DataTabActionBar
                  isSidebarOpen={isSidebarOpen}
                  setSidebarOpen={setIsSidebarOpen}
                  showNewDataSourceButton={false}
                />
                <div className="relative mx-auto flex h-full max-h-full w-full flex-grow overflow-hidden">
                  {isSidebarOpen && (
                    <div className="min-64 flex w-64 max-w-64 flex-shrink-0 flex-col overflow-hidden bg-slate-700 text-slate-200">
                      <DataSourceSidebar project={project} />
                    </div>
                  )}
                  <div
                    className={classNames('flex h-full w-full', {
                      'items-center justify-center': aiTableEnabled,
                    })}
                  >
                    <Suspense fallback={<Loader className="text-slate-200" />}>
                      <LazyDataTypeEditorManager
                        className="h-full w-full rounded-lg"
                        dataTypes={project.dataTypes}
                        projectName={project.name}
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
              <Route path={`/_/${LeftTabs.DATA}/internal/~new/intro`}>
                <div className="flex h-full max-h-full max-w-full flex-grow flex-col overflow-hidden bg-slate-700">
                  <VideoModal
                    confirmText={getText('collectionsVideo.button')}
                    description={getText('collectionsVideo.description')}
                    onClose={() => {
                      push(`/_/${LeftTabs.DATA}/internal/~new`);
                    }}
                    title={getText(
                      { firstName: userFirstName },
                      'introVideo.title',
                    )}
                    videoId="ZtztzotFL1Q"
                  />
                </div>
              </Route>
            </Route>
          )}
          {isBuilder && (
            <Route path={`/_/${LeftTabs.DATA}/internal/~import`}>
              <div className="flex h-full max-h-full max-w-full flex-grow flex-col overflow-hidden bg-slate-700">
                <DataTabActionBar
                  isSidebarOpen={isSidebarOpen}
                  setSidebarOpen={setIsSidebarOpen}
                  showNewDataSourceButton={false}
                />
                <div className="relative mx-auto flex h-full max-h-full w-full flex-grow overflow-hidden">
                  {isSidebarOpen && (
                    <div className="min-64 flex w-64 max-w-64 flex-shrink-0 flex-col overflow-hidden bg-slate-700 text-slate-200">
                      <DataSourceSidebar project={project} />
                    </div>
                  )}
                  <AddCsv
                    onCancel={() => {
                      push(`/_/${LeftTabs.DATA}/internal`);
                    }}
                    project={project}
                  />
                </div>
              </div>
            </Route>
          )}
          <Route>
            <Suspense fallback={<AdminLoadableFallback />}>
              <LazyDataTable
                dataTypes={project.dataTypes}
                projectName={project.name}
                project={project}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isBuilder={isBuilder}
              />
            </Suspense>
          </Route>
        </Switch>
      </Route>
      <Redirect to={`/_/${LeftTabs.DATA}/internal/`} />
    </Switch>
  );
};

export default DataTabRoutes;
