import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import Guide from '@noloco/ui/src/components/Guide';
import TabEditor from '@noloco/ui/src/components/editor/customerEditors/TabEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { RECORD_TABS } from '../../constants/buildMode';
import { RECORD_TAB } from '../../constants/draggableItemTypes';
import Icon from '../../elements/Icon';
import { PageTab } from '../../models/AppNavigation';
import { DataType } from '../../models/DataTypes';
import { ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import { ensureArray } from '../../utils/arrays';
import useItemListEditor from '../../utils/hooks/useItemListEditor';
import { getText } from '../../utils/lang';
import { WithDropable } from '../withDnD';
import BuildModeSection from './BuildModeSection';

interface BuildModeTabEditorProps {
  cloneTabElements: (tabId: string | null, newTabId: string) => void;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  project: Project;
  tabs: PageTab[];
  updateProperty: UpdatePropertyCallback;
}

const LANG_KEY = 'elements.VIEW.tabs';
const TABS_LANG_KEY = 'elements.VIEW.tabs';
const DEFAULT_TAB_ID = 'DEFAULT_TAB';

const BuildModeTabEditor = forwardRef(
  (
    {
      dataType,
      debouncedUpdateProperty,
      cloneTabElements,
      elementPath,
      project,
      tabs,
      updateProperty,
    }: BuildModeTabEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    const [popoutOpen, setPopoutOpen] = useState(false);

    const updateTabs = useCallback(
      (path, value) => updateProperty(['tabs', ...path], value),
      [updateProperty],
    );

    const debounceUpdateTabs = useCallback(
      (path, value) => debouncedUpdateProperty(['tabs', ...path], value),
      [debouncedUpdateProperty],
    );

    const createNewTab = useCallback((currentTabs) => {
      if (currentTabs.length > 0) {
        return {
          id: shortId.generate(),
          title: getText(
            { count: currentTabs.length + 1 },
            LANG_KEY,
            'newTitle',
          ),
        };
      }

      return {
        id: DEFAULT_TAB_ID,
        title: getText(LANG_KEY, 'main'),
      };
    }, []);

    const {
      formatItem,
      draftItems: draftTabs,
      findItem,
      onSaveOrder,
      onRemoveItem: onRemoveTab,
      onCloneItem: onCloneTab,
      onAddNewItem: onAddNewTab,
      onDebounceUpdateItem: onDebounceUpdateTab,
    } = useItemListEditor(tabs, updateTabs, debounceUpdateTabs, createNewTab);

    const handleCloneTab = useCallback(
      (id: string, newId: string, tab: ViewTab) => {
        onCloneTab(id, newId, tab);
        cloneTabElements(id === DEFAULT_TAB_ID ? null : id, newId);
      },
      [cloneTabElements, onCloneTab],
    );

    const listOptions = useMemo(
      () =>
        ensureArray(draftTabs).map((draftTab, index) => ({
          icon: draftTab.icon && (
            <Icon className="h-4 w-4" icon={draftTab.icon} />
          ),
          label: draftTab.title,
          value: index,
        })),
      [draftTabs],
    );

    return (
      <BuildModeSection
        className="border-t p-2"
        guide={
          <Guide
            href="https://guides.noloco.io/pages/tabs"
            arcade="https://demo.arcade.software/a4YOMftNridI9vPcRg6F?embed"
          >
            {getText(TABS_LANG_KEY, 'guide')}
          </Guide>
        }
        endComponent={
          <div
            className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-gray-700 hover:opacity-100"
            onClick={onAddNewTab}
          >
            <IconPlus size={16} />
          </div>
        }
        id={RECORD_TABS}
        sticky={true}
        title={getText(TABS_LANG_KEY, 'label')}
      >
        <div ref={ref} className="mb-2 flex flex-col space-y-2 p-2">
          {ensureArray(draftTabs)
            .filter((tab) => tab)
            .map((tab, index) => (
              <TabEditor
                activeListItem={activeListItem}
                dataType={dataType}
                draggable={tab.id !== DEFAULT_TAB_ID}
                elementPath={elementPath}
                findItem={findItem}
                index={index}
                isDefault={tab.id === DEFAULT_TAB_ID}
                item={formatItem(tab)}
                key={`${tab.id}-${index}`}
                listOptions={listOptions}
                onClone={handleCloneTab}
                onRemove={onRemoveTab}
                onSaveOrder={onSaveOrder}
                onUpdate={onDebounceUpdateTab}
                popoutOpen={popoutOpen}
                project={project}
                setActiveListItem={setActiveListItem}
                setPopoutOpen={setPopoutOpen}
                tab={tab}
                visibilityRulesEnabled={
                  tab.id !== DEFAULT_TAB_ID || draftTabs.length > 1
                }
              />
            ))}
        </div>
      </BuildModeSection>
    );
  },
);

BuildModeTabEditor.displayName = 'BuildModeTabEditor';

export default WithDropable(BuildModeTabEditor, RECORD_TAB);
