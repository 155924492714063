import { useMemo } from 'react';
import {
  CollectionLayout,
  PIVOT_TABLE,
  TABLE,
  TABLE_FULL,
} from '../../constants/collectionLayouts';

export const layoutIsTable = (layout: CollectionLayout) =>
  [TABLE, TABLE_FULL, PIVOT_TABLE].includes(layout);

const useIsTable = (layout: CollectionLayout) =>
  useMemo(() => layoutIsTable(layout), [layout]);

export default useIsTable;
