import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { SCAN_BARCODE } from '../../constants/actionTypes';
import { DataType } from '../../models/DataTypes';
import { Action } from '../../models/Element';
import { Project } from '../../models/Project';
import { getCollectionDataQueryString } from '../../queries/project';
import { transformDepsToQueryObject } from '../data';

const getRecordQueryString =
  (
    dataType: DataType,
    dataTypes: DataType[],
    barcodeField: string,
    requestField: string,
  ) =>
  (value: string) => {
    const queryArgs = {
      where: { [barcodeField]: { equals: value } },
      first: 1,
    };

    const node = transformDepsToQueryObject(dataType, dataTypes, [
      { path: requestField },
    ]);

    const qs = getCollectionDataQueryString(
      dataType.apiName,
      {
        edges: {
          node,
        },
      },
      queryArgs,
    );

    return gql`
      ${qs}
    `;
  };

const useActionWithHiddenInputsQuery = (
  action: Action,
  dataType: DataType,
  project: Project,
) => {
  const apolloClient = useApolloClient();

  return useCallback(
    (additionalValue?: { value: string }) => {
      if (action.type !== SCAN_BARCODE) {
        // Realistically, this should never run, but it could if someone calls it incorrectly
        throw new Error('Invalid action');
      }
      const field = dataType.fields.getByName(
        action.barcodeField?.path ?? 'id',
      );

      if (!field) {
        throw new Error('Field not found');
      }

      const requestedField =
        action.navigate?.pageData?.[dataType.apiName]?.path || 'uuid';

      const getQueryString = getRecordQueryString(
        dataType,
        project.dataTypes,
        field.apiName,
        requestedField,
      );

      return apolloClient
        .query({
          query: getQueryString(additionalValue?.value ?? ''),
          context: {
            projectQuery: true,
            projectName: project.name,
          },
        })
        .then(({ data }) => {
          const queryName = `${dataType.apiName}Collection`;
          const record = get(data, [queryName, 'edges', 0, 'node'], null);

          if (record) {
            return record;
          }

          return undefined;
        });
    },
    [action, apolloClient, dataType, project],
  );
};

export default useActionWithHiddenInputsQuery;
