import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { IconAlertTriangleFilled, IconCheck } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Loader, Tooltip } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { ProjectUserRecord } from '@noloco/core/src/models/Project';
import { User } from '@noloco/core/src/models/User';
import { addProjectUsers } from '@noloco/core/src/reducers/project';
import { projectNameSelector } from '@noloco/core/src/selectors/projectSelectors';
import { getText } from '@noloco/core/src/utils/lang';
import { isBuilder } from '@noloco/core/src/utils/user';
import { INVITE_APP_USERS } from '../../queries/project';

const LANG_KEY = 'userTable';

const UserActiveCell = ({
  user,
  builders,
}: {
  user: User;
  builders: Record<string, ProjectUserRecord>;
}) => {
  const dispatch = useDispatch();
  const projectName = useSelector(projectNameSelector);
  const userIsBuilder = useMemo(
    () => isBuilder(user) && builders[user.email],
    [builders, user],
  );

  const isActiveBuilder = userIsBuilder && !!builders[user.email]?.createdAt;
  const needsBuilderInvite = useMemo(
    () => !userIsBuilder && isBuilder(user),
    [user, userIsBuilder],
  );

  const [inviteAppUsers, { loading }] = useMutation(INVITE_APP_USERS);
  const sendBuilderInvitation = useCallback(() => {
    inviteAppUsers({
      variables: {
        projectName: projectName,
        userEmails: [user.email],
      },
    }).then(({ data }) => {
      const newUsers = data.inviteUsers;

      if (newUsers) {
        dispatch(addProjectUsers(newUsers));
      }
    });
  }, [dispatch, inviteAppUsers, projectName, user.email]);

  const lastActiveText = useMemo(
    () =>
      user.lastActiveAt
        ? getText(
            {
              dateTime: DateTime.fromISO(
                user.lastActiveAt as string,
              ).toLocaleString(DateTime.DATETIME_MED),
            },
            LANG_KEY,
            'fields.lastActive',
          )
        : null,
    [user.lastActiveAt],
  );

  if (user.deactivatedAt) {
    return (
      <Tooltip
        content={getText(
          { deactivatedAt: user.deactivatedAt.toLocaleString() },
          LANG_KEY,
          'deactivated.tooltip',
        )}
      >
        <div className="flex w-full">
          <Badge className="mx-auto" color="gray">
            {getText(LANG_KEY, 'deactivated.badge')}
          </Badge>
        </div>
      </Tooltip>
    );
  }

  if (needsBuilderInvite) {
    if (loading) {
      return <Loader size={SM} />;
    }

    return (
      <Tooltip
        content={
          <div className="flex flex-col items-center whitespace-nowrap">
            <span>{getText(LANG_KEY, 'builderWarning.label')}</span>
            <div className="mt-2 flex w-full items-end">
              <Button
                onClick={sendBuilderInvitation}
                className="ml-auto"
                size={SM}
              >
                {getText(LANG_KEY, 'builderWarning.cta')}
              </Button>
            </div>
          </div>
        }
      >
        <div className="flex items-center">
          <IconAlertTriangleFilled className="mx-auto" size={20} />
        </div>
      </Tooltip>
    );
  }

  if ((!userIsBuilder || isActiveBuilder) && user.lastActiveAt) {
    return (
      <Tooltip content={lastActiveText}>
        <div className="flex items-center">
          <IconCheck className="mx-auto" size={20} />
        </div>
      </Tooltip>
    );
  }

  if (userIsBuilder) {
    return (
      <Tooltip
        content={
          <div className="flex flex-col space-y-2">
            <span>{getText(LANG_KEY, 'fields.pendingInvite.tooltip')}</span>
            {lastActiveText && <span>{lastActiveText}</span>}
          </div>
        }
      >
        <Badge color="pink">
          {getText(LANG_KEY, 'fields.pendingInvite.badge')}
        </Badge>
      </Tooltip>
    );
  }

  return null;
};

export default UserActiveCell;
