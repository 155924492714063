import React, { useCallback, useEffect, useState } from 'react';
import { IconCheck, IconPencil } from '@tabler/icons-react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { TextInput } from '@noloco/components/src';
import { CMD, ENTER, ESCAPE } from '../../constants/shortcuts';
import KeyboardShortcutTooltip from '../../elements/sections/view/KeyboardShortcutTooltip';
import { setLeftEditorSection } from '../../reducers/elements';
import useOnKeyPress from '../../utils/hooks/useOnKeyPress';

interface EditableLabelProps {
  value: string;
  onChange?: (newValue: string) => void;
  onUpdate: (newValue: string) => void;
  placeholder: string;
  active: boolean;
  isLink?: boolean;
  iconEditorIsOpen?: boolean;
  rightOffset?: string;
  onEditModeEnter?: () => void;
}

const EditableLabel = ({
  value,
  onChange,
  onUpdate,
  placeholder,
  active,
  isLink = false,
  rightOffset = '4',
  iconEditorIsOpen = false,
  onEditModeEnter,
}: EditableLabelProps) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleEnterEditMode = useCallback(() => {
    setIsEditing(true);

    if (onEditModeEnter) {
      onEditModeEnter();
    }
  }, [onEditModeEnter]);

  const handleUpdate = useCallback(() => {
    onUpdate(localValue);
    setIsEditing(false);
  }, [localValue, onUpdate]);

  useOnKeyPress(ENTER, handleUpdate, {
    ctrlKey: true,
    enabled: isEditing,
  });

  useOnKeyPress(ESCAPE, () => setIsEditing(false), {
    enabled: isEditing,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setLocalValue(newValue);
    onChange?.(newValue);
  };

  return (
    <div className="group flex w-full max-w-full items-center justify-between space-x-2 overflow-hidden truncate">
      {!isEditing && !iconEditorIsOpen ? (
        <label
          className={
            'flex-grow cursor-pointer text-xs font-medium tracking-wider text-gray-300'
          }
        >
          {value || placeholder}
        </label>
      ) : (
        <TextInput
          className={classNames('rounded', {
            'bg-slate-700': active,
            'group-hover:bg-slate-700': isLink,
          })}
          border={0}
          h={6}
          inline={true}
          onChange={handleChange}
          onFocus={() => dispatch(setLeftEditorSection(null))}
          placeholder={placeholder}
          surface="dark"
          value={localValue}
        />
      )}
      <div
        className={classNames('group-hover:flex', {
          hidden: !active && !isEditing,
        })}
      >
        <div className="flex h-6 items-center justify-between space-x-1">
          <button
            className={classNames(
              'rounded-md bg-slate-700 p-1 text-slate-400 hover:text-slate-200',
              {
                'absolute z-10 hidden group-hover:flex': !isEditing,
                [`right-${rightOffset}`]: true,
                flex: isEditing,
              },
            )}
            onClick={isEditing ? handleUpdate : handleEnterEditMode}
          >
            {isEditing ? (
              <KeyboardShortcutTooltip delayShow={400} keys={[CMD, ENTER]}>
                <IconCheck size={16} />
              </KeyboardShortcutTooltip>
            ) : (
              <IconPencil size={16} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditableLabel;
