import React, { useCallback, useMemo } from 'react';
import { IconCircleCheck, IconCircleDotted } from '@tabler/icons-react';
import { SelectInput, Surface } from '@noloco/components';
import { BOOLEAN } from '@noloco/core/src/constants/dataTypes';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';
import DynamicSwitch from './DynamicSwitch';

interface StaticBooleanFieldInputProps {
  isOpen: boolean;
  onChange: (value: StringPropSegment[] | null) => void;
  onSetDynamic: (dynamic: boolean) => void;
  onClose: () => void;
  placeholder: string | undefined;
  surface: Surface;
  value: StringPropSegment[];
}

const StaticBooleanFieldInput = ({
  isOpen,
  onChange,
  onClose,
  onSetDynamic,
  placeholder,
  surface,
  value,
}: StaticBooleanFieldInputProps) => {
  const options = useMemo(
    () => [
      {
        value: undefined,
        label: '----',
      },
      {
        value: true,
        label: getText('contentEditor.values', BOOLEAN, true),
        icon: <IconCircleCheck size={16} />,
      },
      {
        value: false,
        label: getText('contentEditor.values', BOOLEAN, false),
        icon: <IconCircleDotted size={16} />,
      },
    ],
    [],
  );

  const onOptionChange = useCallback(
    (optionValue: boolean) => {
      if (optionValue === undefined) {
        onChange(null);
      } else {
        onChange([
          {
            static: true,
            data: {
              id: 'values',
              path: `${BOOLEAN}.${optionValue}`,
              dataType: BOOLEAN,
            },
          },
        ]);
      }
    },
    [onChange],
  );

  const booleanValue = useMemo(() => {
    if (value.length === 0) {
      return null;
    }

    if (value.length === 1 && value[0].data) {
      const val = value[0].data.path;

      if (val === `${BOOLEAN}.true`) {
        return true;
      } else if (val === `${BOOLEAN}.false`) {
        return false;
      }
    }

    return null;
  }, [value]);

  return (
    <SelectInput
      className="w-full"
      onChange={onOptionChange}
      footer={<DynamicSwitch value={false} onChange={onSetDynamic} />}
      value={booleanValue}
      options={options}
      open={isOpen ? true : undefined}
      onOpenChange={isOpen ? onClose : undefined}
      placeholder={placeholder}
      placement="top-start"
      surface={surface}
    />
  );
};

export default StaticBooleanFieldInput;
