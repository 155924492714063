import React from 'react';
import { useSelector } from 'react-redux';
import {
  projectDomainsSelector,
  projectUsersSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import OnboardingChecklist from './OnboardingChecklist';

const OnboardingChecklistRoute = () => {
  const users = useSelector(projectUsersSelector);
  const domains = useSelector(projectDomainsSelector);

  const {
    query: { __onboarding },
  } = useRouter();

  if (!users || !domains || !__onboarding) {
    return null;
  }

  return <OnboardingChecklist />;
};

export default OnboardingChecklistRoute;
