import React, { useCallback, useMemo } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import { Button } from '@noloco/components';
import ProFeatureBadge from '@noloco/ui/src/components/ProFeatureBadge';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { ActionButtonExecutionType } from '../../../constants/actionButtons';
import { ActionType, SCAN_BARCODE } from '../../../constants/actionTypes';
import actions from '../../../constants/actions';
import { RECORD_ACTION_BUTTON_ACTIONS } from '../../../constants/buildMode';
import { BARCODE_SCAN } from '../../../constants/features';
import { DataType } from '../../../models/DataTypes';
import { Action, ElementPath, ScanActionType } from '../../../models/Element';
import { Project } from '../../../models/Project';
import {
  ACTION_ADDED,
  ACTION_DELETED,
  trackEvent,
} from '../../../utils/analytics';
import { getText } from '../../../utils/lang';
import BuildModeSection from '../BuildModeSection';
import BuildModeActionItemEditor from './BuildModeActionItemEditor';

const mapActionOptions = (
  actionTypes: ActionType[],
  barcodeScanningIncludedInPlan: boolean,
) =>
  actionTypes.map((actionType) => {
    const { Icon } = actions[actionType];

    return {
      icon: <Icon size={16} />,
      label: (
        <div className="flex space-x-2">
          <span>{getText('actions.types', actionType, 'label')}</span>
          {actionType === SCAN_BARCODE && (
            <ProFeatureBadge
              feature={BARCODE_SCAN}
              inline={true}
              showAfterTrial={!barcodeScanningIncludedInPlan}
            />
          )}
        </div>
      ),
      value: actionType,
      disabled: actionType === SCAN_BARCODE && !barcodeScanningIncludedInPlan,
    };
  });

interface BuildModeActionsEditorProps {
  actionOptions: ActionType[];
  actions: Action[];
  dataType: DataType;
  elementPath: ElementPath;
  eventType: 'CLICK';
  execution: ActionButtonExecutionType;
  onChange: UpdatePropertyCallback;
  project: Project;
  scanActionsAvailable: ScanActionType[];
  updateActionButton: UpdatePropertyCallback;
}

const LANG_KEY = 'elements.VIEW.actionButtons';

const BuildModeActionsEditor = ({
  actionOptions,
  actions = [],
  dataType,
  elementPath,
  eventType,
  execution,
  onChange,
  project,
  scanActionsAvailable,
  updateActionButton,
}: BuildModeActionsEditorProps) => {
  const updateActions = onChange;
  const barcodeScanningIncludedInPlan = useIsFeatureEnabled(BARCODE_SCAN);
  const actionTypeOptions = useMemo(
    () => mapActionOptions(actionOptions, barcodeScanningIncludedInPlan),
    [actionOptions, barcodeScanningIncludedInPlan],
  );
  const initialEventState: any = [];

  const addNewAction = (e: any) => {
    e.stopPropagation();
    const newAction = {
      id: shortId.generate(),
      type: null,
      event: eventType,
    };

    if (actions.length === 0) {
      updateActions([], [newAction]);
    } else {
      updateActions([actions.length], newAction);
    }
    trackEvent(ACTION_ADDED);
  };

  const actionIndex = useCallback(
    (action: Action) => actions.indexOf(action),
    [actions],
  );

  const onRemoveAction = useCallback(
    (action: Action) => {
      trackEvent(ACTION_DELETED);
      const index = actionIndex(action);

      return updateActions(
        [],
        actions.filter(
          (_: Action, actionIndex: number) => actionIndex !== index,
        ),
      );
    },
    [actionIndex, updateActions, actions],
  );

  const eventActions = actions.filter(
    (action: any) => !action.event || action.event === eventType,
  );

  return (
    <BuildModeSection
      id={RECORD_ACTION_BUTTON_ACTIONS}
      className="border-t p-2"
      emptyState={getText(LANG_KEY, 'noActionsDefined')}
      endComponent={
        <div
          className="flex cursor-pointer items-center justify-center rounded-md p-1 text-slate-300 opacity-75 hover:bg-slate-700 hover:opacity-100"
          onClick={addNewAction}
        >
          <IconPlus size={16} />
        </div>
      }
      showEmptyState={actions.length === 0}
      title={getText(LANG_KEY, 'actions')}
    >
      <div className="flex flex-col p-2 text-slate-400">
        {eventActions.map((action, index) => (
          <div key={action.id || index}>
            <BuildModeActionItemEditor
              action={action}
              actionIndex={actionIndex(action)}
              actionTypeOptions={actionTypeOptions}
              elementPath={elementPath}
              execution={execution}
              initialEventState={initialEventState}
              onRemoveAction={onRemoveAction}
              parentDataType={dataType}
              previousActions={eventActions.slice(0, index)}
              project={project}
              scanActionsAvailable={scanActionsAvailable}
              updateActionButton={updateActionButton}
              updateActions={updateActions}
            />
            <div className="mx-auto h-5 w-1 bg-slate-500" />
          </div>
        ))}
        {actions.length > 0 && (
          <Button
            className="flex items-center justify-center hover:text-slate-500 focus:text-slate-500"
            onClick={addNewAction}
          >
            <IconPlus className="mr-2 opacity-75" size={16} />
            <span>{getText('actions.add')}</span>
          </Button>
        )}
      </div>
    </BuildModeSection>
  );
};

export default BuildModeActionsEditor;
