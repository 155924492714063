import React, { useMemo } from 'react';
import classNames from 'classnames';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { VisibilityRules } from '../../models/Element';
import { Project } from '../../models/Project';
import {
  shouldCheckVisibilityRules,
  shouldRenderComponent,
} from '../../utils/elementVisibility';
import useCurrentSpace from '../../utils/hooks/useCurrentSpace';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';

interface VisibilityRulesWrapperProps {
  children: React.ReactElement;
  editorMode: boolean;
  project: Project;
  visibilityRules?: VisibilityRules;
  visibilityRulesScope?: Record<string, any>;
}

const VisibilityRulesWrapper = ({
  children,
  editorMode,
  project,
  visibilityRules,
  visibilityRulesScope = {},
}: VisibilityRulesWrapperProps) => {
  const currentUser = useScopeUser();
  const scope = useMergedScope(visibilityRulesScope);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const [currentSpace] = useCurrentSpace();
  const checkSpace = !!currentSpace;
  const { sm: isSmScreen } = useBreakpoints();

  const shouldRender = useMemo(() => {
    if (
      !visibilityRules ||
      !shouldCheckVisibilityRules(visibilityRules, checkSpace)
    ) {
      return true;
    }

    return shouldRenderComponent({
      checkSpace,
      currentSpaceId: currentSpace?.id,
      currentUser,
      customRulesEnabled,
      isSmScreen,
      project,
      scope,
      visibilityRules,
    });
  }, [
    checkSpace,
    currentSpace,
    currentUser,
    customRulesEnabled,
    isSmScreen,
    project,
    scope,
    visibilityRules,
  ]);

  const childrenToRender = useMemo(() => {
    if (!shouldRender && !editorMode) {
      return null;
    }

    if (!shouldRender && editorMode) {
      return React.cloneElement(children, {
        className: classNames(children.props.className, 'opacity-50'),
      });
    }

    return children;
  }, [children, editorMode, shouldRender]);

  return childrenToRender;
};

export default VisibilityRulesWrapper;
