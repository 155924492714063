import { Link } from 'react-router-dom';
import { getText } from '@noloco/core/src/utils/lang';

export const SetupDomainLink = ({ onClick }: { onClick: () => void }) => (
  <Link
    to={`/_/settings/domain`}
    onClick={onClick}
    className="my-2 text-xs font-semibold text-gray-500 underline hover:text-cyan-600"
  >
    {getText('toolbar.publish.connectDomain')}
  </Link>
);
