import { DEFAULT_FIELDS, ID_FIELDS } from '../constants/defaultFields';
import { DataFieldIdentifier } from '../models/ProjectArrayTypeMap';

export const isDefaultFieldById = (id: number): boolean =>
  id > 10000 && id < 10100;

export const isIdField = (field: Pick<DataFieldIdentifier, 'name'>): boolean =>
  ID_FIELDS.includes(field.name);
export const isDefaultField = (
  field: Pick<DataFieldIdentifier, 'name'>,
): boolean => DEFAULT_FIELDS.includes(field.name);

export const filterDefaultFields = (
  field: Pick<DataFieldIdentifier, 'name'>,
): boolean => !isDefaultField(field);
