import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { ADDITIONAL_USAGE_CONFIG } from '@noloco/core/src/constants/usage';
import { billingPlanTypeSelector } from '@noloco/core/src/selectors/billingPlanSelectors';

export const usePlanOverages = () => {
  const billingPlanType = useSelector(billingPlanTypeSelector);

  // @ts-expect-error TS(2769): No overload matches this call.
  return get(ADDITIONAL_USAGE_CONFIG, billingPlanType);
};
