import { useMemo } from 'react';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import { DataType } from '../../models/DataTypes';
import { Action } from '../../models/Element';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { MutationType } from '../../queries/project';
import getActionButtonFormFieldConfig from '../getActionButtonFormFieldConfig';
import getFormFieldRecordId from '../getFormFieldRecordId';
import { isMultiField } from '../relationships';
import useFormFields from './useFormFields';

const useActionButtonFormFields = (
  dataType: DataType,
  field: Action['field'],
  formFields: Action['formFields'],
  project: Project,
  record: BaseRecord | undefined,
  type: MutationType | undefined,
) => {
  const { selectedField, mutationField, actionDataType } = useMemo(
    () => getActionButtonFormFieldConfig(field, dataType, project.dataTypes),
    [dataType, field, project.dataTypes],
  );

  const recordId = useMemo(
    // @ts-expect-error TS(2345): Argument of type 'DataField | undefined' is not as... Remove this comment to see the full error message
    () => getFormFieldRecordId(record, selectedField),
    [record, selectedField],
  );

  const mutationType =
    !recordId && type === UPDATE ? CREATE : type || undefined;

  const formFieldConfig = useFormFields(formFields, actionDataType, project, {
    mutationType,
    useUrlValues: false,
  });

  const fields = useMemo(
    () => [
      ...formFieldConfig,
      ...(mutationField &&
      mutationField.name !== 'id' &&
      record &&
      type === CREATE
        ? [
            {
              hidden: true,
              field: mutationField.name,
              value: isMultiField(mutationField) ? [record.id] : record.id,
            },
          ]
        : []),
    ],
    [formFieldConfig, mutationField, record, type],
  );

  return {
    actionDataType,
    fields,
    mutationType,
    recordId,
  };
};

export default useActionButtonFormFields;
