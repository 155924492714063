import { IconUsers } from '@tabler/icons-react';
import { Popover } from '@noloco/components';
import { CMD, ESCAPE, KEY_U, SHIFT } from '../constants/shortcuts';
import KeyboardShortcutTooltip from '../elements/sections/view/KeyboardShortcutTooltip';
import DataTypes from '../models/DataTypes';
import useDarkModeSurface from '../utils/hooks/useDarkModeSurface';
import useOnKeyPress from '../utils/hooks/useOnKeyPress';
import { getText } from '../utils/lang';
import ChangeUserPopoverBody from './ChangeUserPopoverBody';

interface ChangeUserPopoverProps {
  dataTypes: DataTypes;
  delayShow?: number;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  placement?: string;
  projectName: string;
  showArrow?: boolean;
  size?: number;
}

const ChangeUserPopover = ({
  dataTypes,
  delayShow = 400,
  isOpen = false,
  onOpenChange,
  placement = 'top',
  projectName,
  showArrow = true,
}: ChangeUserPopoverProps) => {
  const surface = useDarkModeSurface();

  useOnKeyPress(KEY_U, () => onOpenChange!(!isOpen), {
    ctrlKey: true,
    shiftKey: true,
  });

  useOnKeyPress(ESCAPE, () => onOpenChange!(false), { enabled: isOpen });

  return (
    <Popover
      content={
        <ChangeUserPopoverBody
          dataTypes={dataTypes}
          projectName={projectName}
        />
      }
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      p={0}
      placement="top-end"
      showArrow={showArrow}
      surface={surface}
    >
      <div className="flex items-center justify-center opacity-75 hover:opacity-100">
        <KeyboardShortcutTooltip
          buildMode={false}
          delayShow={delayShow}
          keys={[CMD, SHIFT, KEY_U]}
          label={getText('leftSidebar.auth.change')}
          offset={[0, 16]}
          placement={placement}
        >
          <div className="cursor-pointer">
            <IconUsers size={16} />
          </div>
        </KeyboardShortcutTooltip>
      </div>
    </Popover>
  );
};

export default ChangeUserPopover;
