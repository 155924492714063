import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { DIVIDER, LINK } from '../../constants/elements';
import useRouter from '../../utils/hooks/useRouter';
import { getPageTo } from '../../utils/urls';
import SidebarItem from '../SidebarItem';
import SubNavItem from './SubNavItem';

const HiddenNavItem = ({
  editingPage,
  editorMode,
  debouncedUpdateProperty,
  handleSetEditingPage,
  index,
  onEditSelectPage,
  page,
  pages,
  portalPath,
  portalPages,
  pagesPath,
  project,
  primaryColor,
  showExpandedNav,
  updateProject,
  updateProperty,
}: any) => {
  const { pathname } = useRouter();
  const splitPathname = pathname.split('/');

  const to = useMemo(
    () => getPageTo(['PORTAL', page.id], {}, pages, {}),
    [page.id, pages],
  );

  const active = useMemo(
    () =>
      page.type !== LINK &&
      page.type !== DIVIDER &&
      (!to || to === '/'
        ? pathname === '/'
        : to
            .split('/')
            .every(
              (pathSegment, index) =>
                splitPathname.length - 1 >= index &&
                pathSegment === splitPathname[index],
            )),
    [page.type, pathname, splitPathname, to],
  );

  const childPages = useMemo(
    () =>
      portalPages
        // @ts-expect-error TS(7006): Parameter 'childPage' implicitly has an 'any' type... Remove this comment to see the full error message
        .map((childPage, idx) => ({
          childPage,
          originalChildIndex: idx,
        }))
        .filter(
          ({ childPage }: any) =>
            get(childPage, 'props.parentPage') === page.id &&
            get(page, 'props.hide', null),
        ),
    [page, portalPages],
  );

  const subPages = useMemo(() => get(page, 'props.SubPages', []), [page]);

  const elementPath = useMemo(
    () => [
      ...portalPath,
      ...(get(project, 'settings.flags.v2', false) ? [] : ['children']),
      index,
      [],
    ],
    [index, portalPath, project],
  );

  return (
    <div className="flex w-full flex-col">
      <SidebarItem
        active={active}
        depth={0}
        debouncedUpdateProperty={debouncedUpdateProperty}
        editorMode={editorMode}
        elementPath={elementPath}
        editingPage={editingPage}
        hasChildPages={childPages.length > 0 || subPages.length > 0}
        index={index}
        icon="FileCheck"
        onEditSelectPage={onEditSelectPage}
        page={page}
        primaryColor={primaryColor}
        isNavExpanded={showExpandedNav}
        navId={page.id}
        key={page.id}
        portalPath={portalPath}
        project={project}
        setEditingPage={handleSetEditingPage}
        to={to}
        updateProperty={updateProperty}
      />
      {active && (
        <div
          className={classNames('flex w-full flex-col sm:pl-6', {
            'pl-6': showExpandedNav,
          })}
        >
          {childPages.map(({ childPage, originalChildIndex }: any) => (
            <SubNavItem
              debouncedUpdateProperty={debouncedUpdateProperty}
              editorMode={editorMode}
              editingPage={editingPage}
              index={originalChildIndex}
              handleSetEditingPage={handleSetEditingPage}
              parentTo={to}
              page={childPage}
              pagesPath={pagesPath}
              portalPath={portalPath}
              portalPages={portalPages}
              project={project}
              primaryColor={primaryColor}
              onEditSelectPage={onEditSelectPage}
              splitPathname={splitPathname}
              showExpandedNav={showExpandedNav}
              updateProject={updateProject}
              updateProperty={updateProperty}
              key={childPage.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HiddenNavItem;
