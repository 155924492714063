import React, { useMemo } from 'react';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { TEXT } from '@noloco/core/src/constants/elements';
import { COUNT } from '@noloco/core/src/constants/fieldDisplayTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { Project } from '@noloco/core/src/models/Project';
import { FormFieldConfig } from '@noloco/core/src/models/View';
import ViewLinkEditor from './ViewLinkEditor';

interface FieldViewLinkEditorProps {
  config: FormFieldConfig;
  field: DataField;
  label: string;
  onChange: (value: string) => void;
  project: Project;
  value: string | undefined;
}

const FieldViewLinkEditor = ({
  config,
  field,
  label,
  onChange,
  project,
  value,
}: FieldViewLinkEditorProps) => {
  const fieldDataType = useMemo(() => {
    if (
      config.editInline ||
      field.type === FILE ||
      (!field.relationship && !field.relatedField) ||
      config.elementType === TEXT ||
      config.elementType === COUNT
    ) {
      return null;
    }

    return project.dataTypes.getByName(field.type);
  }, [
    config.editInline,
    config.elementType,
    field.relatedField,
    field.relationship,
    field.type,
    project.dataTypes,
  ]);

  if (!fieldDataType) {
    return null;
  }

  return (
    <ViewLinkEditor
      allowNone={false}
      dataType={fieldDataType}
      label={label}
      onChange={onChange}
      project={project}
      value={value}
    />
  );
};

export default FieldViewLinkEditor;
