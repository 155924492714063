import React from 'react';
import { FormFieldConfig } from '@noloco/core/src/models/View';

const FormLabel = ({
  config,
  htmlFor,
  label,
  required,
}: {
  config: FormFieldConfig;
  htmlFor: string;
  label: string;
  required: boolean;
}) => (
  <label
    className="text-xs font-medium tracking-wider text-gray-800"
    htmlFor={htmlFor}
  >
    <span>{label}</span>
    {required && !config.readOnly && (
      <span className="ml-px opacity-75">*</span>
    )}
  </label>
);

export default FormLabel;
