import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setProject } from '@noloco/core/src/reducers/project';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { PROJECT_WITHOUT_DATA_TYPES_QUERY } from '../../queries/project';

const useFetchProjectForSettings = (skip = false) => {
  const project = useSelector(projectDataSelector);
  const dispatch = useDispatch();

  const isFetched = !!project.apiKeys && !!project.workflows;
  const { data: projectData, loading } = useQuery(
    PROJECT_WITHOUT_DATA_TYPES_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        projectId: project.name,
      },
      skip: isFetched || skip,
    },
  );

  useMemo(() => {
    if (!isFetched && projectData && projectData?.project?.apiKeys) {
      const newProject = projectData.project;
      newProject.dataTypes = project.dataTypes;

      dispatch(setProject(newProject));
    }
  }, [dispatch, isFetched, project, projectData]);

  return { loading: !isFetched && loading };
};

export default useFetchProjectForSettings;
