import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  AIRTABLE,
  GOOGLE_SHEETS,
  POSTGRES,
} from '@noloco/core/src/constants/dataSources';
import { LIVE_CHAT } from '@noloco/core/src/constants/features';
import { ProjectSettings } from '@noloco/core/src/models/Project';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import {
  dataTypesSelector,
  projectIdSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  getUserIdentifier,
  useAuth,
} from '@noloco/core/src/utils/hooks/useAuth';
import { useIsBuilder } from '@noloco/core/src/utils/hooks/useIsBuilder';
import { getFullName } from '@noloco/core/src/utils/user';
import { IS_PRODUCTION } from '../../constants/env';
import useIsFeatureEnabled from './useIsFeatureEnabled';

const useIntercom = (settings: ProjectSettings) => {
  const { user } = useAuth();

  const projectName = useSelector(projectNameSelector);
  const projectId = useSelector(projectIdSelector);
  const dataTypes = useSelector(dataTypesSelector);

  const billingPlan = useSelector(billingPlanSelector);
  const userEmail = user && user.email;

  const { isBuilder, isNolocoAdmin } = useIsBuilder();

  const userHasChatAccess = useIsFeatureEnabled(LIVE_CHAT);
  const showMessenger = useMemo(
    () =>
      IS_PRODUCTION &&
      isBuilder &&
      !isNolocoAdmin &&
      userHasChatAccess &&
      !userEmail?.includes('@noloco.io'),
    [isBuilder, isNolocoAdmin, userEmail, userHasChatAccess],
  );

  useEffect(() => {
    if (showMessenger && window.loadIntercom) {
      window.loadIntercom();
    }
  }, [settings, showMessenger]);

  const attributes = useMemo(() => {
    if (billingPlan && user) {
      const attribs = {
        email: user.email,
        user_id: getUserIdentifier(user, `NOLOCO:`),
        created_at: user.createdAt
          ? new Date(user.createdAt).getTime() / 1000
          : undefined,
        name: getFullName(user),
        plan: billingPlan.type,
        status: billingPlan.status,
        trial_end_at: billingPlan.trialEnd
          ? new Date(billingPlan.trialEnd).getTime() / 1000
          : null,
        period_end_at: billingPlan.periodEnd
          ? new Date(billingPlan.periodEnd).getTime() / 1000
          : null,
        airtable_connected: false,
        google_sheets_connected: false,
        postgres_connected: false,
        permissions_enabled: false,
        project_name: projectName,
        project_id: projectId,
      };

      dataTypes.forEach((dataType: any) => {
        attribs.airtable_connected =
          attribs.airtable_connected || dataType.source.type === AIRTABLE;
        attribs.google_sheets_connected =
          attribs.google_sheets_connected ||
          dataType.source.type === GOOGLE_SHEETS;
        attribs.postgres_connected =
          attribs.postgres_connected || dataType.source.type === POSTGRES;

        attribs.permissions_enabled =
          attribs.permissions_enabled || dataType.permissionsEnabled;
      });

      return attribs;
    }
  }, [billingPlan, dataTypes, projectId, projectName, user]);

  useEffect(() => {
    window.intercomSettings = {
      ...(window.intercomSettings || {}),
      ...attributes,
    };

    if (window.Intercom) {
      window.Intercom('update');
    }
  }, [attributes]);

  const hideIntercom = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('hide');
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }, []);

  const showIntercom = useCallback(() => {
    if (window.Intercom) {
      window.Intercom('show');
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  }, []);

  return {
    hideIntercom,
    showIntercom,
  };
};
export default useIntercom;
