import React from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconAward } from '@tabler/icons-react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { useSelector } from 'react-redux';
import { Theme, getColorShade } from '@noloco/components';
import PoweredByNoloco from '@noloco/core/src/components/PoweredByNoloco';
import { VIEW } from '@noloco/core/src/constants/elements';
import NewForm from '@noloco/core/src/elements/sections/view/NewForm';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { skipPropResolvingByValueIds } from '@noloco/core/src/utils/elementPropResolvers';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSectionScopeVariables from '@noloco/core/src/utils/hooks/useSectionScopeVariables';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getProjectLogo } from '@noloco/core/src/utils/image';
import { getText } from '@noloco/core/src/utils/lang';
import { RECORD_SCOPE } from '@noloco/core/src/utils/scope';
import Canvas from './Canvas';

interface PublicFormsProjectRendererProps {
  theme: Theme;
}

const LANG_KEY = 'publicForms';

const PublicFormsProjectRenderer = ({
  theme,
}: PublicFormsProjectRendererProps) => {
  const {
    query: { elementId },
  } = useRouter();
  const project = useSelector(projectDataSelector);

  const targetElementIndex = project.elements.findIndex(
    (element: any) => element.id === elementId,
  );
  const view = project.elements[targetElementIndex];
  const viewProps = view.props;

  const {
    new: {
      coverPhoto,
      fields,
      icon,
      name,
      title,
      subtitle,
      publicForms,
      saveButtonText,
      onSave,
    },
  } = useSectionScopeVariables(
    VIEW,
    { new: viewProps.new },
    project,
    [targetElementIndex],
    {},
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const targetDataType = project.dataTypes.getByName(
    viewProps.dataList.dataType,
  ) as DataType;

  const primaryColor = theme.brandColors.primary;

  const onSubmitMessage = get(publicForms, [0, 'onSubmitMessage']) || '';
  const hideNavBar = get(publicForms, [0, 'hideNavBar'], false);

  const defaultTitle = getText(
    { dataType: upperFirst(targetDataType.display) },
    LANG_KEY,
    'new',
  );

  useSetDocumentTitle(title || defaultTitle);

  return (
    <Canvas isPublic={true}>
      <div className="mx-2 w-full">
        {!hideNavBar && (
          <div className={`bg-${getColorShade(primaryColor, 700)} w-full p-4`}>
            <img
              className="mx-auto h-10"
              src={getProjectLogo(project.settings, project.media)}
              alt="App logo"
              data-testid="top-bar-logo"
            />
          </div>
        )}
        <NewForm
          coverPhoto={coverPhoto}
          dataType={targetDataType}
          elementPath={[targetElementIndex]}
          fields={fields}
          icon={icon}
          name={name}
          onClick={undefined}
          project={project}
          rootPathname={`/${viewProps.routePath}`}
          subtitle={subtitle}
          title={title}
          viewRootPathname={`/${viewProps.routePath}`}
          hideBreadcrumbs={true}
          successMessage={{
            icon: IconAward,
            message: onSubmitMessage,
          }}
          isPublicForm={true}
          onSave={onSave}
          redirectOnSuccess={onSave && onSave.navigate}
          hideFormOnSuccess={true}
          disableFeatureCheck={true}
          neverAllowNewRecords={true}
          saveButtonText={saveButtonText}
        />
        <PoweredByNoloco
          className="mx-2 flex-wrap justify-center pt-3 text-gray-800"
          projectName={project.name}
          utmSource="noloco_login"
        />
      </div>
    </Canvas>
  );
};

export default withTheme(PublicFormsProjectRenderer);
