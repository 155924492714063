import React, { Dispatch, useCallback, useMemo, useRef, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import first from 'lodash/first';
import { useDrop } from 'react-dnd';
import { v4 as uuidV4, validate as uuidValidate } from 'uuid';
import { Label, Surface, TextInput } from '@noloco/components';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import {
  DataField,
  DataFieldOption,
} from '@noloco/core/src/models/DataTypeFields';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { sortOptions } from '@noloco/core/src/utils/fields';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionValid } from '@noloco/core/src/utils/options';
import BulkAddOptions from './BulkAddOptions';
import ColorOptionInput from './ColorOptionInput';
import { UpsertedOption } from './DataFieldForm';
import FieldOptionsInputEntry from './FieldOptionsInputEntry';

interface Props {
  dataTypeId: number;
  readOnly: boolean;
  readOnlyColor: boolean;
  projectName: string;
  field: DataField;
  showBulkAddScreen?: boolean;
  setShowBulkAddScreen: (showBulkAddScreen: boolean) => void;
  options: UpsertedOption[];
  setOptions: Dispatch<React.SetStateAction<UpsertedOption[]>>;
  surface: Surface;
}
/**
 * Removes options without display string. Also cleans up order, __typename and draftIds
 */
export const cleanOptions = (options: any[]) =>
  options
    .filter((o) => o.display)
    .map((option) => {
      const { id, order: _o, __typename, ...rest } = option;

      if (typeof id === 'string' && uuidValidate(id)) {
        //it's a draft ID
        return rest;
      }

      return { id, ...rest };
    });
const FieldOptionsInput = ({
  dataTypeId,
  projectName,
  readOnly,
  readOnlyColor,
  field,
  showBulkAddScreen = false,
  setShowBulkAddScreen,
  options,
  setOptions,
  surface,
}: Props) => {
  const oldOrderRef = useRef<{ id: string | number; order: number } | null>(
    null,
  );

  const isNolocoField = field.source === INTERNAL;

  const [draftOption, setDraftOption] = useState('');
  const [draftOptionColor, setDraftOptionColor] = useState(null);

  const canDelete = useMemo(() => options.length > 1, [options.length]);

  const sortedOptions = useMemo(
    () => sortOptions(options as DataFieldOption[]),
    [options],
  ) as UpsertedOption[];

  const onReorder = useCallback(
    (draggedId, newIndex) => {
      const option = options.find(({ id }: any) => id === draggedId);

      if (!option) {
        return;
      }
      const oldOrder = option.order;

      if (
        oldOrderRef.current &&
        oldOrder === oldOrderRef.current.order &&
        draggedId === oldOrderRef.current.id
      ) {
        return;
      }
      oldOrderRef.current = { order: oldOrder, id: draggedId };
      const newOptionOrder = [...sortedOptions];
      const field = first(newOptionOrder.splice(newIndex, 1));

      if (field) {
        newOptionOrder.splice(oldOrder, 0, field);
      }
      setOptions(
        newOptionOrder.map((option, order) => ({
          ...option,
          order: order,
        })),
      );
    },
    [options, setOptions, sortedOptions],
  );

  const updateOptionName = (option: UpsertedOption, newName: string) => {
    setOptions((currentOptions) => {
      const nextOptions = [...currentOptions];
      const index = nextOptions.findIndex((o: any) => o.id === option.id);
      nextOptions[index].display = newName;

      return nextOptions;
    });
  };

  const isDraftOptionValid =
    draftOption && isOptionValid({ display: draftOption });

  const deleteOption = (option: UpsertedOption) => {
    setOptions((currentOptions) => {
      const nextOptions = currentOptions.filter((o: any) => o.id !== option.id);

      return nextOptions.map((option, index) => ({
        ...option,
        order: index,
      }));
    });
  };

  const addOption = (event: any) => {
    event.preventDefault();

    if (isDraftOptionValid) {
      setOptions((currentOptions) => {
        const nextOptions = [...currentOptions];
        nextOptions.push({
          display: draftOption,
          color: draftOptionColor ?? undefined,
          order: nextOptions.length,
          id: uuidV4(), //temp ID
        });

        return nextOptions;
      });
      setDraftOption('');
      setDraftOptionColor(null);
    }
  };

  const updateOptionColor = (option: UpsertedOption, nextColor: string) => {
    setOptions((currentOptions) => {
      const newOptions = [...currentOptions];
      const index = newOptions.findIndex((o: any) => o.id === option.id);
      newOptions[index].color = nextColor;

      return newOptions;
    });
  };

  const [, drop] = useDrop({
    accept: 'OPTION',
  });

  return (
    <>
      <Label surface={surface}>{getText('data.options.label')}</Label>
      {!readOnly && (
        <p className="mb-4 text-sm text-gray-400">
          {getText('data.options.edit.disclaimer')}
        </p>
      )}
      {showBulkAddScreen ? (
        <BulkAddOptions
          setShowBulkAddScreen={setShowBulkAddScreen}
          options={options}
          setOptions={setOptions}
          projectName={projectName}
          dataTypeId={dataTypeId}
          dataField={field}
        />
      ) : (
        <>
          <div
            className="flex max-h-96 w-full flex-col gap-y-1 overflow-y-auto py-1 first:pt-0 last:pb-0"
            ref={drop}
            data-testid="field-options-edit-component"
          >
            {sortedOptions.map((option) => (
              <FieldOptionsInputEntry
                key={option.id}
                deleteDisabled={!canDelete}
                deleteOption={isNolocoField ? deleteOption : undefined}
                onReorder={onReorder}
                option={option}
                readOnly={readOnly}
                readOnlyColor={readOnlyColor}
                updateOptionName={updateOptionName}
                updateOptionColor={updateOptionColor}
              />
            ))}
          </div>
          {isNolocoField && !readOnly && (
            <form className="mt-4 flex items-center" onSubmit={addOption}>
              <ColorOptionInput
                className="mr-2"
                onChange={(nextColor: any) => setDraftOptionColor(nextColor)}
                value={draftOptionColor || getColorByIndex(options.length + 1)}
              />
              <TextInput
                placeholder={getText('data.options.edit.placeholder')}
                surface={surface}
                value={draftOption}
                onChange={({ target: { value } }: any) => setDraftOption(value)}
                data-testid="field-options-new-name-input"
              />
              <button
                className="ml-2 whitespace-nowrap p-1 opacity-75 hover:opacity-100 disabled:opacity-50"
                disabled={!isDraftOptionValid}
                type="submit"
                data-testid="field-options-add-button"
              >
                <IconPlus size={16} color="white" />
              </button>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default FieldOptionsInput;
