export const ALL = 'ALL';
export const MOBILE = 'MOBILE';
export const DESKTOP = 'DESKTOP';

export enum DeviceVisibilityRule {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  ALL = 'ALL',
}

export const deviceVisibilityRules = [
  DeviceVisibilityRule.ALL,
  DeviceVisibilityRule.MOBILE,
  DeviceVisibilityRule.DESKTOP,
];
