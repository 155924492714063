import React, { memo, useCallback, useMemo } from 'react';
import {
  IconAlertCircle,
  IconChevronDown,
  IconFile,
  IconLink,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Popover, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  ARRAY,
  DataSchemaField,
  NESTED,
  RelationshipOption,
} from '@noloco/core/src/constants/dataSchema';
import dataTypes, {
  DataFieldType,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { formatValue } from '@noloco/core/src/elements/sections/collections/FieldCell';
import OptionBadge from '@noloco/core/src/elements/sections/collections/OptionBadge';
import { FieldTypeOptions } from '@noloco/core/src/models/DataTypeFields';
import { getExcludedFields } from '@noloco/core/src/utils/dataSchema';
import DataFieldIcon from '../../DataFieldIcon';
import SimpleTable from '../../table/SimpleTable';
import SimpleTableBody from '../../table/SimpleTableBody';
import SimpleTableCell from '../../table/SimpleTableCell';
import SimpleTableHead from '../../table/SimpleTableHead';
import SimpleTableHeadCell from '../../table/SimpleTableHeadCell';
import SimpleTableRow from '../../table/SimpleTableRow';
import DataSchemaFieldPopover from './DataSchemaFieldPopover';

const NESTED_ARRAY = '[...]';
const NESTED_OBJECT = '{...}';

const stringifyNestedField = (record: any, path: string[]) => {
  const value = get(record, path);

  if (!value) {
    return '';
  }

  return JSON.stringify(value).slice(0, 100);
};

interface Props {
  buildFieldTypeOptions?: (
    idField: string | null,
    field: DataSchemaField,
  ) => FieldTypeOptions | undefined;
  className?: string;
  fields: DataSchemaField[] | null;
  idField: string | null;
  includeDefault?: boolean;
  includeEmpty?: boolean;
  onUpdateFields: (fields: DataSchemaField[]) => void;
  records: Record<string, any>[] | null;
  relationshipOptions?: RelationshipOption[];
  surface?: Surface;
}

const DataSchemaEditor = memo(
  ({
    buildFieldTypeOptions,
    className,
    fields,
    idField,
    includeDefault = true,
    includeEmpty = true,
    onUpdateFields,
    records,
    relationshipOptions,
    surface = DARK,
  }: Props) => {
    const onAdd = useCallback(
      (fieldToInclude: DataSchemaField) => () => {
        onUpdateFields([...(fields !== null ? fields : []), fieldToInclude]);
      },
      [fields, onUpdateFields],
    );

    const onReplace = useCallback(
      (fieldToReplace: DataSchemaField) => (newFields: DataSchemaField[]) => {
        if (fields === null) {
          return;
        }

        onUpdateFields(
          fields.reduce((acc: DataSchemaField[], field) => {
            if (field.apiName !== fieldToReplace.apiName) {
              return [...acc, field];
            } else {
              return [...acc, ...newFields];
            }
          }, []),
        );
      },
      [fields, onUpdateFields],
    );

    const excludedFields = useMemo(
      () => getExcludedFields(fields ?? [], records),
      [fields, records],
    );

    const dataFields = useMemo(() => {
      if (!fields) {
        return [];
      }

      return fields.concat(excludedFields).map((field) => ({
        ...field,
        id: 0,
        options: undefined,
        typeOptions: buildFieldTypeOptions
          ? buildFieldTypeOptions(idField, field)
          : undefined,
      }));
    }, [buildFieldTypeOptions, excludedFields, fields, idField]);

    if (!fields) {
      return null;
    }

    return (
      <SimpleTable className={className} surface={surface}>
        <SimpleTableHead surface={surface}>
          {fields
            .concat(excludedFields)
            .map((field: DataSchemaField, index: number) => (
              <SimpleTableHeadCell key={field.apiName} surface={surface}>
                <DataSchemaFieldPopover
                  disabled={!records}
                  relationshipOptions={relationshipOptions}
                  field={field}
                  idField={idField}
                  includeDefault={includeDefault}
                  includeEmpty={includeEmpty}
                  included={index < fields.length}
                  onIncludeField={onAdd(field)}
                  onReplaceField={onReplace(field)}
                  sample={(records ?? []).map((record) =>
                    get(record, field.path),
                  )}
                >
                  <span className="flex cursor-pointer space-x-2">
                    {[ARRAY, NESTED].includes(field.type) ? (
                      <IconAlertCircle
                        className={classNames({
                          'text-red-500': index < fields.length,
                          'text-gray-500': index >= fields.length,
                        })}
                        size={16}
                      />
                    ) : (
                      <DataFieldIcon
                        className="my-auto"
                        field={field as any}
                        size={14}
                      />
                    )}
                    <span
                      className={classNames('flex w-full', {
                        'text-white': index < fields.length && surface === DARK,
                        'text-black':
                          index < fields.length && surface === LIGHT,
                        'text-gray-500':
                          index >= fields.length && surface === DARK,
                        'text-gray-300':
                          index >= fields.length && surface === LIGHT,
                      })}
                    >
                      <p>{field.display}</p>
                      <IconChevronDown className="ml-auto" size={16} />
                    </span>
                  </span>
                </DataSchemaFieldPopover>
              </SimpleTableHeadCell>
            ))}
        </SimpleTableHead>
        <SimpleTableBody surface={surface}>
          {(records ?? []).map((record: any) => (
            <SimpleTableRow key={record.id} surface={surface}>
              {fields
                .concat(excludedFields)
                .map((field: DataSchemaField, fieldIndex: number) => (
                  <SimpleTableCell surface={surface}>
                    {[ARRAY, NESTED].includes(field.type) ? (
                      <Popover
                        content={
                          <span className="font-mono text-xs">
                            {stringifyNestedField(record, field.path)}
                          </span>
                        }
                      >
                        <span className="font-mono italic">
                          {field.type === ARRAY ? NESTED_ARRAY : NESTED_OBJECT}
                        </span>
                      </Popover>
                    ) : field.type === FILE ? (
                      <IconFile size={14} />
                    ) : field.type === MULTIPLE_OPTION ? (
                      <div className="space-x-1">
                        {(get(record, field.path) ?? []).map(
                          (display: string, index: number) => (
                            <OptionBadge
                              key={index}
                              option={{ display, order: 0 }}
                            />
                          ),
                        )}
                      </div>
                    ) : field.type === SINGLE_OPTION ? (
                      <OptionBadge
                        option={{ display: get(record, field.path), order: 0 }}
                      />
                    ) : dataTypes.includes(field.type as DataFieldType) ? (
                      formatValue(
                        get(record, field.path),
                        get(dataFields, fieldIndex),
                        {},
                      )
                    ) : (
                      <IconLink size={14} />
                    )}
                  </SimpleTableCell>
                ))}
            </SimpleTableRow>
          ))}
        </SimpleTableBody>
      </SimpleTable>
    );
  },
);

export default DataSchemaEditor;
