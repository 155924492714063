import { useMemo } from 'react';
import { SelectInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { getViewsForDataType } from '@noloco/core/src/utils/urls';

const LANG_KEY = 'elements.VIEW.rowLink';
interface ViewLinkEditorProps {
  allowNone?: boolean;
  dataType: DataType;
  label: string | JSX.Element;
  onChange: (value: any) => void;
  project: Project;
  value?: any;
  disabled?: boolean;
  inline?: boolean;
}
const ViewLinkEditor = ({
  allowNone = true,
  dataType,
  label,
  onChange,
  project,
  value,
  disabled,
  inline,
}: ViewLinkEditorProps) => {
  const options = useMemo(() => {
    const viewOptions = getViewsForDataType(dataType.name, project);

    return [
      {
        value: undefined,
        label: getText(LANG_KEY, 'default'),
      },
      ...(allowNone
        ? [
            {
              value: '#',
              label: getText(LANG_KEY, 'none'),
            },
          ]
        : []),
      {
        label: getText(LANG_KEY, 'views'),
        heading: true,
        options: viewOptions.map((view: any) => ({
          value: view.id,
          label: view.props.name,
        })),
      },
    ];
  }, [allowNone, dataType.name, project]);

  return (
    <BuildModeInput label={label} inline={inline}>
      <SelectInput
        className="w-full text-black"
        value={value}
        options={options}
        onChange={onChange}
        usePortal={false}
        disabled={disabled}
      />
    </BuildModeInput>
  );
};

export default ViewLinkEditor;
