import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconArrowUpRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import MarkdownText from '../../components/MarkdownText';
import { darkModeColors } from '../../constants/darkModeColors';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { VisibilityRules } from '../../models/Element';
import { IconValue } from '../../models/IconValue';
import { Project } from '../../models/Project';
import { getColorByIndex } from '../../utils/colors';
import { shouldRenderComponent } from '../../utils/elementVisibility';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';
import Icon from '../Icon';
import Link from '../Link';

interface QuickLink {
  description?: string;
  icon: IconValue;
  id: string;
  link: any;
  title: string;
  visibilityRules?: VisibilityRules;
}

interface QuickLinksProps {
  className?: string;
  dense?: boolean;
  editorMode: boolean;
  links: QuickLink[];
  onClick?: (event: any) => void;
  project: Project;
  recordScope: Record<string, any>;
  sectionWidth: 3 | 6 | 9 | 'lg' | 'xl';
}

const QuickLinks = forwardRef(
  (
    {
      className,
      dense,
      editorMode,
      links,
      onClick,
      project,
      recordScope,
      sectionWidth,
    }: QuickLinksProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();
    const { sm: isSmScreen } = useBreakpoints();
    const currentUser = useScopeUser();
    const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
    const scope = useMergedScope(recordScope);

    const visibleLinks = useMemo(
      () =>
        links.filter(({ visibilityRules }) =>
          shouldRenderComponent({
            currentUser,
            customRulesEnabled,
            isSmScreen,
            project,
            scope,
            visibilityRules,
          }),
        ),
      [links, currentUser, customRulesEnabled, isSmScreen, project, scope],
    );

    const quickLinks = useMemo(
      () => (editorMode ? links : visibleLinks),
      [editorMode, links, visibleLinks],
    );
    const numberOfLinksToDisplay = quickLinks.length;
    const totalSpaces = useMemo(
      () =>
        numberOfLinksToDisplay === 3
          ? 3
          : numberOfLinksToDisplay +
            2 -
            1 -
            ((numberOfLinksToDisplay + 2 - 1) % 2), // 5 -> 6, 6 -> 6
      [numberOfLinksToDisplay],
    );
    const isSmallContainer = useMemo(
      () => !!sectionWidth && (sectionWidth as number) <= 6,
      [sectionWidth],
    );

    const isLinkVisible = useCallback(
      (link) => visibleLinks.find((visibleLink) => visibleLink.id === link.id),
      [visibleLinks],
    );

    return (
      <div
        className={classNames(className, 'overflow-hidden')}
        data-testid="quick-links-section"
      >
        <div
          className={classNames(
            `grid w-auto items-center overflow-hidden rounded-lg border shadow md:grid-cols-1 ${
              isDarkModeEnabled
                ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
                : 'border-gray-100 bg-white'
            }`,
            {
              'grid-cols-1': numberOfLinksToDisplay === 1,
              'grid-cols-2':
                numberOfLinksToDisplay % 2 === 0 ||
                (numberOfLinksToDisplay !== 1 &&
                  numberOfLinksToDisplay !== 3) ||
                (isSmallContainer && numberOfLinksToDisplay !== 1),
              'grid-cols-3': numberOfLinksToDisplay === 3 && !isSmallContainer,
              'lg:grid-cols-1': isSmallContainer,
            },
          )}
          onClick={onClick}
          ref={ref}
        >
          {quickLinks.map((link, index) => (
            <Link
              editorMode={editorMode}
              link={link.link}
              className={classNames(
                `quick-link group relative flex h-full flex-col overflow-hidden p-6 ${
                  isDarkModeEnabled ? darkModeColors.borders.one : ''
                }`,
                `quick-link-${link.id}`,
                {
                  'border-b':
                    index < totalSpaces - 2 && numberOfLinksToDisplay > 3,
                  'md:border-b': index < numberOfLinksToDisplay - 1,
                  'border-r md:border-r-0':
                    (index % 2 === 0 && numberOfLinksToDisplay >= 2) ||
                    (numberOfLinksToDisplay === 3 && index === 1),
                  'opacity-50': !isLinkVisible(link),
                },
              )}
              key={index}
            >
              <div
                className={classNames('flex', {
                  'flex-col': !dense,
                  'pr-8': dense,
                })}
              >
                {link.icon && link.icon.name && !link.icon.hidden && (
                  <Box
                    className={classNames(
                      'mr-3 h-10 w-10 flex-shrink-0 rounded-lg p-2',
                      { 'mb-6': !dense },
                    )}
                    bg={getColorShade(getColorByIndex(index), 100)}
                    text={getColorShade(getColorByIndex(index), 600)}
                  >
                    <Icon
                      icon={link.icon}
                      size={20}
                      className="bg-opacity-75"
                    />
                  </Box>
                )}
                <div className="flex flex-col">
                  <span
                    className={`text-lg font-medium tracking-wider ${
                      isDarkModeEnabled
                        ? darkModeColors.text.primary
                        : 'text-gray-900'
                    }`}
                  >
                    {link.title || `Link ${index + 1}`}
                  </span>
                  {link.description && (
                    <MarkdownText
                      className={`mb-1 mt-2 text-sm tracking-wider ${
                        isDarkModeEnabled
                          ? darkModeColors.text.secondary
                          : 'text-gray-400'
                      }`}
                      small={true}
                    >
                      {link.description}
                    </MarkdownText>
                  )}
                </div>
                {link.link && (
                  <div
                    className={`absolute right-8 top-8 h-10 w-10 transform transition-transform duration-300 group-hover:-translate-y-4 group-hover:translate-x-4 ${
                      isDarkModeEnabled
                        ? darkModeColors.text.tertiary
                        : 'text-gray-200'
                    }`}
                  >
                    <IconArrowUpRight size={32} />
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  },
);

export default QuickLinks;
