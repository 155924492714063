// TEXT
export const EMAIL: FieldFormat = 'email';
export const IP_ADDRESS: FieldFormat = 'ipAddress';
export const MULTILINE_TEXT: FieldFormat = 'multiline';
export const SINGLE_LINE_TEXT: FieldFormat = 'singleLine';
export const URL: FieldFormat = 'url';

// NUMBERS (INTEGER / DECIMAL)
export const PERCENTAGE: FieldFormat = 'percentage';
export const CURRENCY: FieldFormat = 'currency';
export const UNFORMATTED_NUMBER: FieldFormat = 'unformatted-number';
export const RATING: FieldFormat = 'rating';
export const SLIDER: FieldFormat = 'slider';

// DATES
export const DATE: FieldFormat = 'date';
export const DATE_TIME: FieldFormat = 'dateTime';

// OBJECT
export const ADDRESS: FieldFormat = 'address';
export const COORDINATES: FieldFormat = 'coordinates';
export const DATE_RANGE: FieldFormat = 'dateRange';
export const DUE_DATE: FieldFormat = 'dueDate';
export const FULL_NAME: FieldFormat = 'fullName';
export const PHONE_NUMBER: FieldFormat = 'phoneNumber';

export type ObjectFieldFormat = Extract<
  FieldFormat,
  | 'address'
  | 'coordinates'
  | 'dateRange'
  | 'dueDate'
  | 'fullName'
  | 'phoneNumber'
>;

export const OBJECT_FORMATS: ObjectFieldFormat[] = [
  ADDRESS,
  COORDINATES,
  DATE_RANGE,
  DUE_DATE,
  FULL_NAME,
  PHONE_NUMBER,
];

// DURATION
export const TIME: FieldFormat = 'time';
export const HOURS_MINUTES: FieldFormat = 'hoursMinutes';
export const MINUTES_SECONDS: FieldFormat = 'minutesSeconds';

export type FieldFormat =
  | 'address'
  | 'coordinates'
  | 'currency'
  | 'date'
  | 'dateRange'
  | 'dateTime'
  | 'dueDate'
  | 'email'
  | 'fullName'
  | 'ipAddress'
  | 'multiline'
  | 'percentage'
  | 'phoneNumber'
  | 'rating'
  | 'singleLine'
  | 'slider'
  | 'time'
  | 'hoursMinutes'
  | 'minutesSeconds'
  | 'unformatted-number'
  | 'url';

export const TEXT_FIELD_FORMAT = {
  EMAIL: EMAIL,
  IP_ADDRESS: IP_ADDRESS,
  MULTILINE_TEXT: MULTILINE_TEXT,
  SINGLE_LINE_TEXT: SINGLE_LINE_TEXT,
  URL: URL,
};

export const NUMBER_FIELD_FORMAT = {
  PERCENTAGE: PERCENTAGE,
  CURRENCY: CURRENCY,
  UNFORMATTED_NUMBER: UNFORMATTED_NUMBER,
  RATING: RATING,
  SLIDER: SLIDER,
};

export const DATE_FIELD_FORMAT = {
  DATE: DATE,
  // Only possible to create directly via API (not in UI)
  DATE_TIME: DATE_TIME,
};

export const OBJECT_FIELD_FORMAT: Record<string, FieldFormat> = {
  ADDRESS: ADDRESS,
  COORDINATES: COORDINATES,
  DATE_RANGE: DATE_RANGE,
  DUE_DATE: DUE_DATE,
  FULL_NAME: FULL_NAME,
  PHONE_NUMBER: PHONE_NUMBER,
};
