import React, { forwardRef, useCallback, useMemo } from 'react';
import {
  IconAlertTriangle,
  IconArrowRight,
  IconArrowsSort,
  IconDatabase,
  IconFilter,
} from '@tabler/icons-react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { SelectInput, Switch, TextInput, Tooltip } from '@noloco/components';
import { CHARTS } from '@noloco/core/src/constants/collectionLayouts';
import { INTERNAL } from '@noloco/core/src/constants/dataWrapperTypes';
import { DATABASE } from '@noloco/core/src/constants/scopeTypes';
import { DataSource } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import StateItem from '@noloco/core/src/models/StateItem';
import cappedMemoize from '@noloco/core/src/utils/cappedMemoize';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getDataCollectionOptionsOfType } from '@noloco/core/src/utils/renderedOptions';
import { getGroupedDataTypeOptions } from '../../../utils/dataTypes';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import DataSourceIcon from '../../DataSourceIcon';
import Guide from '../../Guide';
import ContentDisplayName from '../../canvas/ContentDisplayName';
import CollectionSortInput from './CollectionSortInput';
import CustomFiltersEditor from './CustomFiltersEditor';
import EditorSectionPopover from './EditorSectionPopover';

const LANG_KEY = 'elements.LIST';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const isEmpty = (value: any) =>
  !value ||
  (Array.isArray(value) &&
    value.every((dataItem) => !dataItem.text && !dataItem.data));

export const getDataTypeOptionValue: (dataType: string) => {
  dataSource: DataSource;
  dataType: string;
} | null = cappedMemoize(
  (dataType) => {
    if (!dataType) {
      return null;
    }

    return {
      dataSource: INTERNAL,
      dataType,
    };
  },
  { maxKeys: 1000 },
);

interface OwnRepeatingListEditorProps {
  config: any;
  element: any;
  elementPath: (string | number)[];
  propPath?: ElementPath;
  updateProperty: (...args: any[]) => any;
}

// @ts-expect-error TS(2456): Type alias 'RepeatingListEditorProps' circularly r... Remove this comment to see the full error message
type RepeatingListEditorProps = OwnRepeatingListEditorProps &
  typeof RepeatingListEditor.defaultProps;

// @ts-expect-error TS(7022): 'RepeatingListEditor' implicitly has type 'any' be... Remove this comment to see the full error message
const RepeatingListEditor = ({
  element,
  elementPath,
  elementProps,
  filterOptions,
  hideDataTypeInput,
  layout,
  project,
  propPath,
  updateProperty,
}: RepeatingListEditorProps) => {
  const { pushQueryParams } = useRouter();

  const dataTypeOptions = useMemo(
    () => [
      ...getGroupedDataTypeOptions(project.dataTypes, {
        getValue: ({ name }: any) => getDataTypeOptionValue(name),
      }),
    ],
    [project.dataTypes],
  );

  const props = propPath.length > 0 ? elementProps : element.props;
  const {
    dataType,
    dataSource = INTERNAL,
    filter,
    limit,
    customFilters = [],
    orderBy,
    showPagination,
  } = props;

  const onUpdateDataType = useCallback(
    (newProps) => {
      const newDataListProps = {
        ...props,
        customFilters: [],
        filter: undefined,
        orderBy: undefined,
        ...newProps,
      };
      updateProperty([], newDataListProps);
    },
    [props, updateProperty],
  );

  const onUpdateDefaultFilter = (option: any) =>
    updateProperty(['filter'], option);

  const onUpdateSort = (option: any) => updateProperty(['orderBy'], option);

  const onUpdateShowPagination = (option: any) =>
    updateProperty(['showPagination'], option);

  const onUpdateLimit = ({ target: { value } }: any) => {
    updateProperty(['limit'], [{ text: value }]);
    const qsSuffix = element.id;
    // This handles both the cases where the query params are with and without qsSuffix.
    pushQueryParams({
      _after: undefined,
      _before: undefined,
      [`_after${qsSuffix}`]: undefined,
      [`_before${qsSuffix}`]: undefined,
    });
  };

  const onUpdateCustomFilters = (newCustomFilters: any) =>
    updateProperty(['customFilters'], newCustomFilters);

  const selectedDataType = project.dataTypes.getByName(dataType);

  const collectionFilterOptions = useMemo(() => {
    if (filterOptions) {
      return filterOptions;
    }

    const defaultFilterOptions =
      dataType && selectedDataType
        ? [
            {
              // @ts-expect-error TS(2345): Argument of type '{ path: string; dataType: any; s... Remove this comment to see the full error message
              value: new StateItem({
                path: '',
                dataType,
                source: DATABASE,
              }),
              label: `${getTranslation('all')}`,
              buttonLabel: `${getTranslation('all')}`,
            },
          ]
        : [];

    return getDataCollectionOptionsOfType(
      project,
      elementPath,
      dataType,
      // @ts-expect-error TS(2345): Argument of type '{ value: StateItem; label: strin... Remove this comment to see the full error message
      defaultFilterOptions,
    );
  }, [dataType, elementPath, filterOptions, project, selectedDataType]);

  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
  const DefaultFilterItem = forwardRef(({ value }, ref) => (
    <ContentDisplayName
      dataOptions={collectionFilterOptions}
      data={value}
      parentPath={elementPath}
      ref={ref}
    />
  ));

  const dataTypeValue = getDataTypeOptionValue(dataType);

  const viewDataTypeValue = useMemo(() => {
    if (hideDataTypeInput && dataTypeValue) {
      return project.dataTypes.getByName(dataTypeValue.dataType);
    }

    return null;
  }, [hideDataTypeInput, dataTypeValue, project.dataTypes]);

  return (
    <div className="flex flex-col">
      {!hideDataTypeInput && (
        <>
          <div className="mb-2 flex items-center">
            <IconDatabase size={20} className="mr-2 flex-shrink-0" />
            <label className="mr-8 whitespace-nowrap font-medium uppercase tracking-wider text-gray-300">
              {getTranslation('type')}
            </label>
          </div>
          <SelectInput
            className="mb-6 w-full overflow-hidden text-black"
            value={dataTypeValue}
            options={dataTypeOptions}
            onChange={onUpdateDataType}
            searchable={true}
          />
        </>
      )}
      {viewDataTypeValue && (
        <>
          <Tooltip
            content={getText(
              { dataType: viewDataTypeValue.display },
              LANG_KEY,
              'data.link',
            )}
          >
            <Link
              to={`/_/data/internal/${viewDataTypeValue.name}`}
              className="bg-brand-dark hover:bg-brand-darkest flex w-full items-center rounded-lg p-2"
            >
              <DataSourceIcon type={viewDataTypeValue.source.type} />
              <div className="ml-3 flex w-full flex-wrap text-white">
                {viewDataTypeValue.source.type !== INTERNAL && (
                  <span className="mr-2">
                    {viewDataTypeValue.source.display}
                  </span>
                )}
                {viewDataTypeValue.source.type !== INTERNAL && (
                  <IconArrowRight className="mr-2" size={16} />
                )}
                <span>{viewDataTypeValue.display}</span>
              </div>
              <IconDatabase className="ml-auto flex-shrink-0" size={16} />
            </Link>
          </Tooltip>
          <div className="mx-2 mb-4 mt-2">
            <Guide
              className="text-sm"
              href="https://guides.noloco.io/pages/collection-views"
              showTooltip={true}
              video="https://www.youtube.com/embed/6wEccR4NA4k?si=UIKI0ype5ZQhGzBA"
            >
              {getText(LANG_KEY, 'guide')}
            </Guide>
          </div>
        </>
      )}
      {selectedDataType && (
        <EditorSectionPopover
          label={getTranslation('filter.label')}
          Icon={IconFilter}
          dataTestId="filter"
        >
          <SelectInput
            Button={DefaultFilterItem}
            className="w-64 text-black"
            value={filter}
            options={collectionFilterOptions}
            onChange={onUpdateDefaultFilter}
            contained={true}
          />
          {dataSource === INTERNAL && selectedDataType && (
            <CustomFiltersEditor
              customFilters={customFilters}
              elementPath={elementPath}
              onUpdateCustomFilters={onUpdateCustomFilters}
              project={project}
              selectedDataType={selectedDataType}
            />
          )}
          <Guide
            className="mt-4 text-sm"
            href="https://guides.noloco.io/collections/filters"
          >
            {getTranslation('filter.guide')}
          </Guide>
        </EditorSectionPopover>
      )}
      {layout !== CHARTS && selectedDataType && (
        <EditorSectionPopover
          className="w-80 text-white"
          Icon={IconArrowsSort}
          label={getTranslation('sortAndLimit.label')}
          dataTestId="sort-and-limit"
        >
          {dataSource === INTERNAL && (
            <div className="flex w-full flex-col justify-center">
              <label className="mb-1">{getTranslation('sort')}</label>
              <CollectionSortInput
                dataType={selectedDataType}
                value={orderBy}
                onChange={onUpdateSort}
              />
            </div>
          )}
          <div className="mb-6 mt-6 flex w-full items-center">
            <label className="mr-4 whitespace-nowrap">
              {getTranslation('limit.label')}
            </label>
            <div className="ml-auto w-36">
              <TextInput
                debounceMs={UPDATE_DEBOUNCE_MS}
                onChange={onUpdateLimit}
                value={get(limit, '0.text', '')}
                type="number"
                placeholder={getTranslation('limit.placeholder')}
              />
            </div>
          </div>
          {dataSource === INTERNAL && isEmpty(limit) && (
            <div className="my-3 flex items-center space-x-3 rounded-lg bg-yellow-100 p-3 py-2">
              <IconAlertTriangle
                size={22}
                className="mt-1 flex-shrink-0 text-yellow-500"
              />

              <p className="text-xs text-yellow-700">
                {getTranslation('limit.warning')}
              </p>
            </div>
          )}
          {dataSource === INTERNAL && !isEmpty(limit) && (
            <div className="mb-6 flex w-full items-center">
              <label className="mr-4 whitespace-nowrap">
                {getTranslation('showPagination.label')}
              </label>
              <Switch
                className="ml-auto"
                value={showPagination}
                onChange={onUpdateShowPagination}
              />
            </div>
          )}
          <Guide
            className="mt-4 text-sm"
            showTooltip={true}
            video={
              'https://www.youtube.com/embed/MAOOFUXjNLY?si=VaMMxEwxrzIb1R20'
            }
            href="https://guides.noloco.io/collections/sort-and-limit"
          >
            {getTranslation('sortAndLimit.guide')}
          </Guide>
        </EditorSectionPopover>
      )}
    </div>
  );
};

RepeatingListEditor.defaultProps = {
  additionalFilterOptions: [],
  hideDataTypeInput: false,
};

export default RepeatingListEditor;
