import React, { memo } from 'react';
import { IconCurrencyDollar } from '@tabler/icons-react';
import { Modal } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import { APP_DASHBOARD_URL } from '../constants/env';

const OverageWarningModal = memo(
  // @ts-expect-error TS(2339): Property 'handleCancel' does not exist on type '{ ... Remove this comment to see the full error message
  ({ handleCancel, handleClick, feature, overage, setIsOpen }) => {
    const featureName = getText('features', feature).toLowerCase();

    return (
      <Modal
        onCancel={() => {
          if (handleCancel) {
            handleCancel();
          }

          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
        onConfirm={() => {
          if (handleClick) {
            handleClick();
          }

          setIsOpen(false);
        }}
        icon={<IconCurrencyDollar size={20} />}
        title={getText({ feature: featureName }, 'features.overage.title')}
        confirmText={getText('features.overage.confirm')}
        variant="primary"
      >
        <div className="space-y-4">
          <p>
            {getText(
              { feature: featureName },
              'features.overage.text.paragraph1',
            )}
          </p>
          <p>
            {getText(
              {
                cost: overage.cost,
                feature: featureName,
                increment: overage.increment,
              },
              'features.overage.text.paragraph2',
            )}
          </p>
          <p>
            <a
              href={`${APP_DASHBOARD_URL}/billing`}
              className="mt-2 text-cyan-500 hover:underline"
            >
              {getText('features.explore')}
            </a>
          </p>
        </div>
      </Modal>
    );
  },
);

export default OverageWarningModal;
