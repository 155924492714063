import React, { useMemo } from 'react';
import classNames from 'classnames';
import { SelectInput } from '@noloco/components';
import colorPalettes, {
  themePalette,
} from '@noloco/core/src/constants/colorPalettes';
import { Project } from '@noloco/core/src/models/Project';
import { getChartColorPalette } from '@noloco/core/src/utils/chartColorPalette';
import { mergeThemes } from '@noloco/core/src/utils/styles';

const EXPANDED_PALETTE_LENGTH = 16;

const PaletteItem = ({ palette }: { palette: string[] }) => (
  <div
    className={classNames('flex w-64 items-center space-x-1', {
      'space-x-2': palette.length <= EXPANDED_PALETTE_LENGTH,
      'space-x-0.5': palette.length > EXPANDED_PALETTE_LENGTH,
    })}
  >
    {palette.map((color) => (
      <div
        className={classNames('h-4 rounded', {
          'w-4': palette.length <= EXPANDED_PALETTE_LENGTH,
          'w-2': palette.length > EXPANDED_PALETTE_LENGTH,
        })}
        style={{ backgroundColor: color }}
        key={color}
      />
    ))}
  </div>
);

interface ChartPaletteEditorProps {
  onChange: (value: any) => void;
  project: Project;
  value: any;
}

const ChartPaletteEditor = ({
  onChange,
  project,
  value,
}: ChartPaletteEditorProps) => {
  const projectTheme = useMemo(
    () => mergeThemes(project.settings?.theme),
    [project.settings?.theme],
  );
  const options = useMemo(
    () => [
      {
        value: themePalette,
        label: (
          <PaletteItem
            palette={getChartColorPalette(themePalette, projectTheme)}
          />
        ),
      },
      ...Object.entries(colorPalettes).map(([paletteName, palette]) => ({
        value: paletteName,
        label: <PaletteItem palette={palette} />,
      })),
    ],
    [projectTheme],
  );

  return (
    <SelectInput
      options={options}
      onChange={onChange}
      value={value ?? themePalette}
    />
  );
};

export default ChartPaletteEditor;
