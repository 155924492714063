import React, { useCallback, useState } from 'react';
import ErrorText from '@noloco/components/src/components/form/ErrorText';
import { useAuth } from '../utils/hooks/useAuth';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import { BackupCodeInput } from './sections/twoFactorAuthentication/BackupCodeInput';
import { OTPInput } from './sections/twoFactorAuthentication/OTPInput';

interface TwoFactorAuthLoginProps {
  secondFactorAuthToken?: string;
  redirectPath?: string;
  updateUserCache: (user: any) => void;
}

export const TwoFactorAuthLogin = ({
  secondFactorAuthToken,
  redirectPath,
  updateUserCache,
}: TwoFactorAuthLoginProps) => {
  const { verifyOTP, verifyOTPBackupCode } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [useBackupCode, setUseBackupCode] = useState(false);
  const { push } = useRouter();

  const handleVerify = useCallback(
    (token: string) => {
      setLoading(true);
      verifyOTP!(token, secondFactorAuthToken)
        .then((user) => {
          if (user) {
            updateUserCache(user);
            push(redirectPath ? decodeURIComponent(redirectPath) : '/');
          }
        })
        .catch(() =>
          setErrors([getText('auth.twoFactorAuth.error.otpInvalid')]),
        )
        .finally(() => setLoading(false));
    },
    [verifyOTP, updateUserCache, secondFactorAuthToken, push, redirectPath],
  );

  const handleVerifyOTPBackupCode = useCallback(
    (backupCode: string) => {
      setLoading(true);
      verifyOTPBackupCode!(backupCode, secondFactorAuthToken)
        .then((user) => {
          if (user) {
            updateUserCache(user);
            push(redirectPath ? decodeURIComponent(redirectPath) : '/');
          }
        })
        .catch(() =>
          setErrors([getText('auth.twoFactorAuth.errors.backupCodeInvalid')]),
        )
        .finally(() => setLoading(false));
    },
    [
      verifyOTPBackupCode,
      updateUserCache,
      secondFactorAuthToken,
      push,
      redirectPath,
    ],
  );

  return (
    <>
      {errors.length > 0 && (
        <ErrorText>
          {errors.map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </ErrorText>
      )}
      {!useBackupCode ? (
        <div className="flex flex-col gap-4">
          <OTPInput handleVerify={handleVerify} loading={loading} />
          <div>
            <p>
              {getText('auth.twoFactorAuth.backupCodes.prompt')}
              <button
                onClick={() => setUseBackupCode(true)}
                disabled={loading}
                className="font-inherit text-md cursor-pointer border-none bg-none p-0 text-blue-500 underline disabled:opacity-75"
              >
                {getText('auth.twoFactorAuth.backupCodes.useBackupCode')}
              </button>
            </p>
          </div>
        </div>
      ) : (
        <BackupCodeInput
          handleVerifyOTPBackupCode={handleVerifyOTPBackupCode}
          loading={loading}
        />
      )}
    </>
  );
};
