import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  ARRAY,
  COMBO,
  GROUP,
  KEY_MAP,
  NODE,
  STRING,
} from '@noloco/core/src/constants/elementPropTypeTypes';
import elementsConfig from '@noloco/core/src/elements/baseElementConfig';
import { Project } from '@noloco/core/src/models/Project';
import ComboType from '@noloco/core/src/models/elementPropTypes/ComboPropType';
import DataPropType from '@noloco/core/src/models/elementPropTypes/DataPropPropType';
import StringType from '@noloco/core/src/models/elementPropTypes/StringPropType';
import { getPagesConfig } from '@noloco/core/src/utils/pages';

const convertArrayObjectToArray = (arrayObject: any) =>
  Object.values(arrayObject);

export const validateArrayProp = (
  arrayProp: any,
  elementPath: any,
  changes: any,
) => {
  let propValue = arrayProp;

  if (!Array.isArray(propValue)) {
    if (propValue[0]) {
      changes.push({
        description: 'Converting array object to Array',
        path: elementPath,
        value: propValue,
      });
      propValue = convertArrayObjectToArray(propValue);
    } else {
      changes.push({
        description: 'Converting invalid array to empty array',
        path: elementPath,
        value: propValue,
      });
      propValue = [];
    }
  }

  const length = propValue.length;
  propValue =
    propValue.includes(null) || propValue.includes(undefined)
      ? propValue.filter(Boolean)
      : propValue;

  if (length !== propValue.length) {
    changes.push({
      description: 'Removed empty elements from array',
      path: elementPath,
      value: propValue,
    });
  }

  return propValue;
};

export const validateProps = (
  propsShape: any,
  props: any,
  elementPath: any,
  changes: any,
) =>
  Object.entries(propsShape).reduce(
    (updatedProps, [propKey, propDefinition]) => {
      let propValue = get(props, propKey);

      switch (!isNil(propValue) && (propDefinition as any).type) {
        case STRING: {
          if (!Array.isArray(propValue)) {
            if (typeof propValue === 'string') {
              changes.push({
                description: 'Converting plain string to string prop type',
                path: [...elementPath, 'props', propKey],
                value: propValue,
              });
              propValue = [{ text: propValue }];
              break;
            }

            propValue = validateArrayProp(
              propValue,
              [...elementPath, 'props', propKey],
              changes,
            );
          }
          break;
        }
        case NODE: {
          propValue = validateArrayProp(
            propValue,
            [...elementPath, 'props', propKey],
            changes,
          );

          propValue = propValue.map((childNode: any, index: any) =>
            validateElement(
              childNode,
              [...elementPath, propKey, index],
              changes,
            ),
          );

          break;
        }
        case ARRAY: {
          propValue = validateArrayProp(
            propValue,
            [...elementPath, 'props', propKey],
            changes,
          );

          propValue = propValue.map((arrayValue: any, index: any) =>
            validateProps(
              (propDefinition as any).shape,
              arrayValue,
              [...elementPath, propKey, index],
              changes,
            ),
          );

          break;
        }
        case GROUP: {
          propValue = validateProps(
            (propDefinition as any).shape,
            propValue,
            elementPath,
            changes,
          );
          break;
        }
        case COMBO: {
          propValue = validateProps(
            (propDefinition as any).shape,
            propValue,
            [...elementPath, propKey],
            changes,
          );
          break;
        }
        case KEY_MAP: {
          if (typeof propValue === 'object') {
            propValue = Object.entries(propValue).reduce(
              (acc, [key, nestedPropValue]) => ({
                ...acc,
                [key]: validateProps(
                  (propDefinition as any).shape,
                  nestedPropValue,
                  [...elementPath, propKey, key],
                  changes,
                ),
              }),
              {},
            );
          }
          break;
        }
        default:
          break;
      }

      if (propValue !== get(props, propKey)) {
        return set(propKey, propValue, updatedProps);
      }

      return updatedProps;
    },
    props,
  );

const customRulePropShape = new ComboType({
  field: new DataPropType(),
  result: new StringType(),
});

const validateVisibilityRules = (
  visibilityRules: any,
  elementPath: any,
  changes: any,
) => {
  let customRules = visibilityRules.customRules;

  if (!customRules) {
    return visibilityRules;
  }

  customRules = validateArrayProp(
    customRules,
    [...elementPath, 'customRules'],
    changes,
  );

  customRules = customRules
    .map((customRuleBranch: any, branchIndex: any) => {
      if (!customRuleBranch) {
        return null;
      }

      let updatedCustomRuleBranch = validateArrayProp(
        customRuleBranch,
        [...elementPath, 'customRules', branchIndex],
        changes,
      );

      updatedCustomRuleBranch = updatedCustomRuleBranch.map(
        (customRule: any, index: any) =>
          validateProps(
            customRulePropShape.shape,
            customRule,
            [...elementPath, 'customRules', branchIndex, index],
            changes,
          ),
      );

      return updatedCustomRuleBranch;
    })
    .filter(Boolean);

  return {
    ...visibilityRules,
    customRules,
  };
};

export const validateElement = (
  element: any,
  elementPath: any,
  changes: any,
) => {
  if (!element.type) {
    return null;
  }

  const elementConfig = elementsConfig[element.type];

  const props = element.props;
  const updatedProps = validateProps(
    elementConfig.props,
    props,
    [...elementPath, 'props'],
    changes,
  );

  const updatedVisibilityRules = element.visibilityRules
    ? validateVisibilityRules(
        element.visibilityRules,
        [...elementPath, 'visibilityRules'],
        changes,
      )
    : undefined;

  return {
    ...element,
    props: updatedProps,
    visibilityRules: updatedVisibilityRules,
  };
};

const validateElements = (elements: any) => {
  const changes: any = [];
  let updatedElements = elements;

  updatedElements = elements.filter(
    (element: any) => element?.id && element?.type,
  );

  if (updatedElements.length !== elements.length) {
    changes.push({
      description: `Removing ${
        elements.length - updatedElements.length
      } invalid pages`,
      path: [],
    });
  }

  updatedElements = updatedElements.map((element: any, index: any) =>
    validateElement(element, [index], changes),
  );

  return { changes, updatedElements };
};

export const validateProjectElements = (
  projectDocument: Pick<Project, 'elements' | 'settings'>,
) => {
  const { pagesPath, projectPages } = getPagesConfig(
    projectDocument.elements,
    projectDocument.settings,
  );
  const { changes, updatedElements } = validateElements(projectPages);

  return { changes, path: pagesPath, updatedElements };
};
