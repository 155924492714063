import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import { COPY } from '@noloco/core/src/constants/elements';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import StringPropEditor from '../../canvas/StringPropEditor';

interface CopyEditorProps {
  additionalScopeItems?: any[];
  elementPath: ElementPath;
  elementProps: any;
  label?: string;
  project: Project;
  propDefinition?: any;
  config?: any;
  element?: any;
  debouncedUpdateProperty: (...args: any[]) => any;
}

const CopyEditor = ({
  additionalScopeItems = [],
  elementPath,
  elementProps,
  project,
  propDefinition,
  debouncedUpdateProperty,
}: CopyEditorProps) => (
  <div className="flex flex-col p-2">
    <BuildModeInput label={getText('elements', COPY, 'label')}>
      <StringPropEditor
        // @ts-expect-error TS(2322): Type '{ project: any; onChange: (value: any) => an... Remove this comment to see the full error message
        project={project}
        onChange={(value: any) => debouncedUpdateProperty(['value'], value)}
        elementPath={elementPath}
        value={elementProps}
        includeSelf={propDefinition?.includeSelf}
        additionalScopeItems={additionalScopeItems}
      />
    </BuildModeInput>
  </div>
);

export default CopyEditor;
