import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  Icon,
  IconChartArea,
  IconChartBar,
  IconChartDonut,
  IconChartInfographic,
  IconChartLine,
  IconChartRadar,
  IconChevronsLeft,
  IconFilter,
  IconGauge,
  IconNumbers,
  IconPlus,
  IconX,
} from '@tabler/icons-react';
import kebabCase from 'lodash/kebabCase';
import { useDispatch } from 'react-redux';
import shortId from 'shortid';
import { BasePopover, TextInput, Tooltip } from '@noloco/components';
import { variantIcon } from '@noloco/components/src/components/notice/Notice';
import {
  DANGER,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  Variant,
  WARNING,
} from '@noloco/components/src/constants/variants';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import { ACTION_OPTIONS, ActionType } from '../../constants/actionTypes';
import actions from '../../constants/actions';
import {
  ACTION_BUTTON_SECTION,
  BuildModeSectionType,
  CHARTS_SECTION,
  COLLECTION_SECTION,
  COMMONLY_USED,
  MEDIA_SECTION,
  NOTICE_SECTION,
  TITLE_SECTION,
} from '../../constants/buildMode';
import {
  AREA,
  BAR,
  ChartType,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '../../constants/chartTypes';
import collectionLayouts, {
  CHARTS,
  CONVERSATION,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  MAP,
  PIVOT_TABLE,
  SPLIT,
  TABLE_FULL,
} from '../../constants/collectionLayouts';
import { darkModeColors } from '../../constants/darkModeColors';
import {
  ACTION_BUTTONS,
  CHART,
  COLLECTION,
  CONTAINER,
  EMBED,
  FILE_GALLERY,
  IMAGE,
  NOTICE,
  PAGE,
  STAGES,
  TITLE,
  VIDEO,
  elements,
} from '../../constants/elements';
import { PREMIUM_LAYOUTS } from '../../constants/features';
import { CMD, ENTER, KEY_SLASH } from '../../constants/shortcuts';
import elementConfigs from '../../elements/elementConfig';
import KeyboardShortcutTooltip from '../../elements/sections/view/KeyboardShortcutTooltip';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import { setLeftEditorSection } from '../../reducers/elements';
import { isPremium } from '../../utils/collectionLayouts';
import { canBeStages } from '../../utils/fields';
import useAddSection from '../../utils/hooks/useAddSection';
import useFuzzySearch from '../../utils/hooks/useFuzzySearch';
import useHasFeatureFlag, {
  EMBED_COMPONENT_ENABLED,
} from '../../utils/hooks/useHasFeatureFlag';
import useOnKeyPress from '../../utils/hooks/useOnKeyPress';
import { getText } from '../../utils/lang';
import BuildModeIcon from './BuildModeIcon';
import BuildModeSection from './BuildModeSection';
import BuildModeSectionOption from './BuildModeSectionOption';
import { LayoutIcon } from './view/BuildModeViewLayoutEditor';

interface BuildModeSectionOptionsProps {
  children?: JSX.Element;
  container?: string;
  dataType: DataType;
  elementPath: ElementPath;
  elementType: string;
  popover?: boolean;
  project: Project;
  sections: Element[];
  selectedTab: ViewTab | null | undefined;
}

interface FilteredSection {
  collectionLayout?: CollectionLayout;
  element: string;
  id: string;
  props?: any;
  title: string;
}

const LANG_KEY = 'leftSidebar.editor';
const SECTION_OPTIONS_LANG_KEY = 'leftSidebar.editor.sectionOptions';
const variants: (Variant | 'success')[] = [
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARNING,
  DANGER,
];

const CHART_ICONS: Record<ChartType, Icon> = {
  [LINE]: IconChartLine,
  [BAR]: IconChartBar,
  [AREA]: IconChartArea,
  [PIE]: IconChartDonut,
  [FUNNEL]: IconFilter,
  [RADAR]: IconChartRadar,
  [STATISTIC]: IconNumbers,
  [GAUGE]: IconGauge,
  [STACKED_BAR]: IconChartInfographic, //TODO: Placeholder icon may need go outside tabler or settle
};

const SECTION_OPTIONS: {
  id: BuildModeSectionType;
  title: string;
  options: {
    disabled?: boolean;
    element: string;
    Icon?: any;
    props?: any;
    title: string;
  }[];
}[] = [
  {
    id: COMMONLY_USED,
    title: `${SECTION_OPTIONS_LANG_KEY}.commonlyUsed`,
    options: elements.map((element) => ({
      element,
      title: `elements.${element}.title`,
    })),
  },
  {
    id: COLLECTION_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.collections.title`,
    options: [],
  },
  {
    id: CHARTS_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.charts.title`,
    options: Object.entries(CHART_ICONS).map(([chartType, Icon]) => ({
      element: CHART,
      Icon,
      props: () => ({
        charts: [
          {
            chartType: chartType,
            id: shortId.generate(),
            series: [{ id: shortId.generate() }],
          },
        ],
      }),
      title: `elements.CHART.chartType.${chartType}`,
    })),
  },
  {
    id: MEDIA_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.media.title`,
    options: [
      {
        element: FILE_GALLERY,
        title: `${SECTION_OPTIONS_LANG_KEY}.media.gallery`,
      },
      { element: IMAGE, title: `${SECTION_OPTIONS_LANG_KEY}.media.image` },
      { element: VIDEO, title: `${SECTION_OPTIONS_LANG_KEY}.media.video` },
    ],
  },
  {
    id: TITLE_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.title.title`,
    options: [
      {
        element: TITLE,
        props: () => ({ subtitle: { hidden: true } }),
        title: `${SECTION_OPTIONS_LANG_KEY}.title.title`,
      },
      {
        element: TITLE,
        props: () => ({ subtitle: { hidden: false }, title: { value: [] } }),
        title: `${SECTION_OPTIONS_LANG_KEY}.title.subTitle`,
      },
    ],
  },
  {
    id: ACTION_BUTTON_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.actionButtons.title`,
    options: Object.entries(actions)
      .filter(([actionType]) =>
        ACTION_OPTIONS.includes(actionType as ActionType),
      )
      .map(([actionType, action]) => ({
        element: ACTION_BUTTONS,
        Icon: action.Icon,
        title: `actions.types.${actionType}.title`,
        props: () => ({
          actionButtons: [
            {
              actions: [
                { id: shortId.generate(), type: actionType, event: 'CLICK' },
              ],
              display: { collection: false, record: true },
              id: shortId.generate(),
            },
          ],
        }),
      })),
  },
  {
    id: NOTICE_SECTION,
    title: `${SECTION_OPTIONS_LANG_KEY}.notice.title`,
    options: variants.map((variant) => ({
      element: NOTICE,
      Icon: variantIcon(variant),
      props: () => ({ appearance: variant }),
      title: `elements.VIEW.actionButtons.variants.${variant}`,
    })),
  },
];

const BuildModeSectionOptions = ({
  children,
  container,
  dataType,
  elementPath,
  elementType,
  popover = true,
  project,
  sections,
  selectedTab,
}: BuildModeSectionOptionsProps) => {
  const dispatch = useDispatch();
  const [filter] = useState('');
  const [inputFocussed, setInputFocussed] = useState<boolean>(false);
  const [filteredSections, setFilteredSections] = useState<FilteredSection[]>(
    [],
  );
  const [optionsOpen, setOptionsOpen] = useState(false);

  const isEmbedComponentEnabled = useHasFeatureFlag(EMBED_COMPONENT_ENABLED);

  const isEnabled = useCallback(
    (element) => {
      if (element === STAGES) {
        return dataType.fields.some(canBeStages);
      }

      return true;
    },
    [dataType.fields],
  );

  const isVisible = useCallback(
    (element) => {
      if (element === CONTAINER && container) {
        return false;
      }

      if (element === EMBED) {
        return isEmbedComponentEnabled;
      }

      return true;
    },
    [isEmbedComponentEnabled, container],
  );

  const scrollToSection = useCallback((id: string) => {
    const sectionEl = document.getElementById(id);

    if (sectionEl) {
      sectionEl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  const [addSection] = useAddSection(
    project,
    sections,
    elementPath,
    elementType === PAGE ? ['sections'] : ['record', 'sections'],
    selectedTab,
    container,
  );

  const onAddNewSection = useCallback(
    (sectionType, props = () => undefined) => {
      const section = addSection(sectionType, props());
      dispatch(setLeftEditorSection(sectionType));
      setOptionsOpen(false);
      setTimeout(() => scrollToSection(section.id), 300);
    },
    [addSection, dispatch, scrollToSection],
  );

  const onOpenChange = useCallback((isOpen) => {
    setOptionsOpen(isOpen);
    setFilteredSections([]);
  }, []);

  const isSectionFiltered = useCallback(
    (title) => {
      if (filteredSections.length === 0) {
        return true;
      }

      return !!filteredSections.find(
        (section) => section.title === getText(title),
      );
    },
    [filteredSections],
  );

  const collectionOptions = useMemo(
    () =>
      (
        [
          ...collectionLayouts.filter(
            (collectionLayout) => collectionLayout !== CONVERSATION,
          ),
          SPLIT,
        ] as CollectionLayout[]
      )
        .filter(
          (collectionLayout) =>
            [...EVENT_BASED_LAYOUTS, MAP, CHARTS, PIVOT_TABLE].includes(
              collectionLayout,
            ) ||
            (!isPremium(collectionLayout) && collectionLayout !== TABLE_FULL),
        )
        .filter((collectionLayout) =>
          isSectionFiltered(
            getText('elements.COLLECTION.layout', collectionLayout),
          ),
        )
        .map((collectionLayout) => ({
          element: COLLECTION,
          collectionLayout,
          title: getText('elements.COLLECTION.layout', collectionLayout),
          component: (
            <FeatureLockedButton
              checkEnabled={isPremium(collectionLayout)}
              compactBadge={true}
              data-testid={`collection-layout-${kebabCase(collectionLayout)}`}
              iconClassName="absolute"
              feature={PREMIUM_LAYOUTS}
              is="button"
              key={collectionLayout}
              onClick={() =>
                onAddNewSection(COLLECTION, () => ({
                  layout: collectionLayout,
                }))
              }
            >
              <div className="relative flex w-full flex-col items-center space-y-2 rounded-lg bg-slate-900 p-2 text-xs text-slate-400 hover:bg-slate-700 hover:text-slate-200">
                <Box is={LayoutIcon[collectionLayout]} size={16} />
                <span className="truncate">
                  {getText('elements.COLLECTION.layout', collectionLayout)}
                </span>
              </div>
            </FeatureLockedButton>
          ),
        })),
    [isSectionFiltered, onAddNewSection],
  );

  const searchableList = useMemo(
    () =>
      SECTION_OPTIONS.reduce(
        (
          result: { element: string; id: string; title: string; props?: any }[],
          { id, options },
        ) => {
          const sectionData =
            id === COLLECTION_SECTION
              ? collectionOptions.map(({ title, element }) => ({
                  element,
                  id,
                  title,
                }))
              : options.map(({ element, title, props }) => ({
                  element,
                  id,
                  props,
                  title: getText(title),
                }));

          return result.concat(sectionData);
        },
        [],
      ),
    [collectionOptions],
  );

  const fuzzy = useFuzzySearch({
    keys: ['title'],
    list: searchableList,
  });

  const handleSearch = useCallback(
    (event) =>
      setFilteredSections(
        fuzzy.search(event.target.value).map(({ item }) => item),
      ),
    [fuzzy],
  );

  const handleEnter = useCallback(() => {
    if (filteredSections.length > 0) {
      onOpenChange(false);

      const section = filteredSections[0];

      if (section.element === COLLECTION) {
        return onAddNewSection(COLLECTION, () => ({
          layout: section.collectionLayout,
        }));
      }

      return onAddNewSection(section.element, section.props);
    }
  }, [filteredSections, onAddNewSection, onOpenChange]);

  useOnKeyPress(KEY_SLASH, () => setOptionsOpen(!optionsOpen), {
    ctrlKey: true,
    enabled: popover,
  });

  useOnKeyPress(ENTER, handleEnter, {
    enabled: popover && inputFocussed,
  });

  if (!popover) {
    return (
      <div className="my-8 flex w-full justify-center">
        <div className="grid grid-cols-2 gap-2">
          {elements.map((element) => {
            const { Icon } = elementConfigs[element];
            const enabled = isEnabled(element);

            if (!isVisible(element)) {
              return null;
            }

            return (
              <Tooltip
                content={
                  <span className={darkModeColors.text.primary}>
                    {getText('elements', element, 'disabled')}
                  </span>
                }
                disabled={enabled}
                key={element}
              >
                <button
                  className="flex w-full flex-col rounded-lg bg-slate-200 p-4 text-slate-500 hover:text-slate-700 hover:shadow-lg dark:bg-slate-800 dark:text-slate-400 dark:hover:text-slate-200"
                  disabled={!enabled}
                  onClick={() => onAddNewSection(element)}
                >
                  <div className="flex items-center space-x-4 text-left">
                    <Icon className="h-4 w-4" />
                    <div className="flex flex-col space-y-1">
                      <span className="text-sm">
                        {getText('elements', element, 'title')}
                      </span>
                      <span className="text-xs">
                        {getText('elements', element, 'description')}
                      </span>
                    </div>
                  </div>
                </button>
              </Tooltip>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    // @ts-expect-error TS(2322): Type '{ children: any; delayHide: number; showArro... Remove this comment to see the full error message
    <BasePopover
      className="max-w-84 h-screen min-h-screen w-full select-none border-l border-r border-slate-700 bg-slate-800"
      content={
        <div className="h-full max-h-full w-full overflow-y-auto">
          <div className="sticky top-0 z-10 flex h-12 items-center space-x-2 border-b border-slate-700 bg-slate-800 p-2">
            <TextInput
              autoFocus={true}
              onBlur={() => setInputFocussed(false)}
              onChange={handleSearch}
              onFocus={() => setInputFocussed(true)}
              placeholder={getText(LANG_KEY, 'searchComponents')}
              value={filter}
            />
            <BuildModeIcon
              Icon={IconChevronsLeft}
              onClick={() => setOptionsOpen(false)}
            />
          </div>
          {SECTION_OPTIONS.map((sectionOption) => {
            const isFiltered = !!filteredSections.find(
              (section) => section.id === sectionOption.id,
            );

            if (!isFiltered && filteredSections.length > 0) {
              return null;
            }

            return (
              <BuildModeSection
                className="sticky top-12 z-10 border-b bg-slate-800"
                id={sectionOption.id}
                title={getText(sectionOption.title)}
                key={sectionOption.id}
              >
                <div className="grid grid-cols-4 gap-1 border-b border-slate-700 p-2">
                  {sectionOption.id === COLLECTION_SECTION
                    ? collectionOptions.map((option) => option.component)
                    : sectionOption.options.map(
                        ({ element, Icon, props, title }) => (
                          <BuildModeSectionOption
                            element={element}
                            Icon={Icon}
                            isEnabled={isEnabled}
                            isSectionFiltered={isSectionFiltered}
                            isVisible={isVisible}
                            key={title}
                            onAddNewSection={onAddNewSection}
                            props={props}
                            title={title}
                          />
                        ),
                      )}
                </div>
              </BuildModeSection>
            );
          })}
        </div>
      }
      isOpen={optionsOpen}
      onOpenChange={onOpenChange}
      placement="right"
      showArrow={false}
    >
      <div>
        {children ?? (
          <KeyboardShortcutTooltip
            label={getText(LANG_KEY, 'addSection')}
            keys={[CMD, KEY_SLASH]}
            offset={[0, 8]}
            placement="right"
          >
            <BuildModeIcon Icon={optionsOpen ? IconX : IconPlus} />
          </KeyboardShortcutTooltip>
        )}
      </div>
    </BasePopover>
  );
};

export default BuildModeSectionOptions;
