import React, { useState } from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { Modal, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { Endpoint } from '@noloco/core/src/constants/apis';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.api.endpoints.delete';

interface Props {
  endpoint: Endpoint;
  onClose: () => any;
  onDelete: () => any;
}

const DeleteApiEndpointModal = ({ endpoint, onClose, onDelete }: Props) => {
  const [confirmText, setConfirmText] = useState('');

  return (
    <Modal
      icon={<IconAlertTriangle size={18} />}
      title={getText(LANG_KEY, 'title')}
      confirmText={getText(LANG_KEY, 'confirm')}
      cancelText={getText(LANG_KEY, 'cancel')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onDelete}
      confirmDisabled={confirmText !== endpoint.display}
      variant="danger"
    >
      <span className="mt-2 font-medium">
        {getText({ endpointName: endpoint.display }, LANG_KEY, 'confirmName')}
      </span>
      <TextInput
        className="my-3"
        placeholder={endpoint.display}
        surface={LIGHT}
        onChange={({ target: { value } }: any) => setConfirmText(value)}
        value={confirmText}
      />
    </Modal>
  );
};

export default DeleteApiEndpointModal;
