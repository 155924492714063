import isNil from 'lodash/isNil';
import { Duration } from 'luxon';
import { HOURS_MINUTES_SECONDS } from '../constants/durationFormatOptions';
import {
  HOURS_MINUTES as HOURS_MINUTES_FORMAT,
  MINUTES_SECONDS as MINUTES_SECONDS_FORMAT,
} from '../constants/durationFormatOptions';
import {
  FieldFormat,
  HOURS_MINUTES,
  MINUTES_SECONDS,
} from '../constants/fieldFormats';

export const getDurationFromString = (
  durationAnyString: any,
  prioritizeMinutes = false,
) => {
  const durationString = String(durationAnyString);

  if (!prioritizeMinutes) {
    const duration = Duration.fromISOTime(
      prioritizeMinutes ? `00:${durationString}` : String(durationString),
    );

    if (duration.isValid) {
      return duration;
    }
  }

  const formattedValue = String(durationString)
    .replace(/[^-\d:.]/g, '')
    .replace(/::/g, ':')
    .replace(/:$/g, '');

  const splitValues = formattedValue.split(':');

  if (isNil(splitValues[0])) {
    return null;
  }

  const firstNumber = parseInt(splitValues[0], 10);
  const isNegative = firstNumber < 0;

  // If there's only one number and prioritizeMinutes is true, treat it as minutes
  if (splitValues.length <= 2 && prioritizeMinutes) {
    try {
      return Duration.fromObject({
        hours: 0,
        minutes: firstNumber,
        seconds:
          splitValues.length > 1
            ? parseInt(splitValues[1], 10) * (isNegative ? -1 : 1)
            : undefined,
      });
    } catch (e) {
      // A parseInt is NaN, which means that there's no duration in the first place
      return null;
    }
  }

  // Otherwise treat first number as hours (original behavior)
  try {
    return Duration.fromObject({
      hours: firstNumber,
      minutes:
        splitValues.length > 1
          ? parseInt(splitValues[1], 10) * (isNegative ? -1 : 1)
          : undefined,
      seconds:
        splitValues.length > 2
          ? parseFloat(splitValues[2]) * (isNegative ? -1 : 1)
          : undefined,
    });
  } catch (e) {
    // A parseInt is NaN, which means that there's no duration in the first place
    return null;
  }
};

export const durationToString = (
  duration: Duration,
  format: string = HOURS_MINUTES_SECONDS,
) => {
  if (duration.toMillis() < 0) {
    return `-${duration.negate().toFormat(format)}`;
  }

  return duration.toFormat(format);
};

export const formatDuration = (
  duration: string | null,
  format?: FieldFormat,
): string | null => {
  if (!duration) {
    return null;
  }

  const parsedDuration = getDurationFromString(
    duration,
    format === MINUTES_SECONDS,
  );

  if (!parsedDuration || !parsedDuration.isValid) {
    return duration;
  }

  if (format === MINUTES_SECONDS || format === HOURS_MINUTES) {
    return durationToString(
      parsedDuration,
      format === MINUTES_SECONDS
        ? MINUTES_SECONDS_FORMAT
        : HOURS_MINUTES_FORMAT,
    );
  }

  return durationToString(parsedDuration);
};

export const millisecondsToDuration = (milliseconds: number) =>
  Duration.fromMillis(milliseconds);
