import { LINK_REPLACED_CLASS, LOOM_URL_REGEX } from './common';

export const isLoomUrl = (url: string): boolean => LOOM_URL_REGEX.test(url);

export const isValidEmbedUrl = (url: string): boolean => isLoomUrl(url);

export const isValidLinkNode = (node: HTMLAnchorElement): boolean =>
  !node.className.includes(LINK_REPLACED_CLASS) && isValidEmbedUrl(node.href);

export default {
  isLoomUrl,
};
