export const COUNT = 'COUNT';
export const CONDENSED_RELATIONSHIP = 'CONDENSED_RELATIONSHIP';
export const JSON_FORMAT = 'json';
export const QR_CODE = 'QR_CODE';

const fieldDisplayTypes = [COUNT, JSON_FORMAT, QR_CODE];

export type FieldDisplayType = (typeof fieldDisplayTypes)[number];

export default fieldDisplayTypes;
