import { SwitchButton } from '@noloco/components/src';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import { getText } from '@noloco/core/src/utils/lang';

const COLUMN_WIDTHS = [3, 4, 6, 8, 9, 12];

interface ColumnWidthEditorProps {
  defaultValue?: number;
  onChange: (width: OptionValue) => void;
  value: number;
  widths?: number[];
}

const ColumnWidthEditor = ({
  onChange,
  defaultValue = 3,
  value,
  widths = COLUMN_WIDTHS,
}: ColumnWidthEditorProps) => (
  <SwitchButton
    className="rounded-lg"
    onChange={(value) => onChange(value)}
    options={widths.map((colWidthValue) => ({
      label: getText(
        'elements.VIEW.fields.columnSpan',
        colWidthValue === defaultValue ? 'default' : colWidthValue,
      ),
      value: colWidthValue,
    }))}
    value={value ?? defaultValue}
  />
);

export default ColumnWidthEditor;
