import React from 'react';
import { IconStack } from '@tabler/icons-react';
import { getText } from '@noloco/core/src/utils/lang';
import {
  useOnAddComponent,
  useUpdateElements,
} from '../../../utils/hooks/projectHooks';
import SelectableElementItem from './SelectableElementItem';

interface OwnProps {
  onAdd: (...args: any[]) => any;
  element: any;
  project: any;
  newElementPath?: any[];
  selectedElementConfig?: any; // TODO: PropTypes.instanceOf(ElementConfig)
  setSelectedElement: (...args: any[]) => any;
}

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ComponentItem.defaultProps;

// @ts-expect-error TS(7022): 'ComponentItem' implicitly has type 'any' because ... Remove this comment to see the full error message
const ComponentItem = ({
  onAdd,
  newElementPath,
  selectedElementConfig,
  setSelectedElement,
  element,
  project,
}: Props) => {
  const { labelKey, Icon = IconStack } = element;
  const [updateElements] = useUpdateElements(project);
  const onAddComponent = useOnAddComponent();

  return (
    <SelectableElementItem
      className="flex w-1/3 cursor-pointer flex-col items-center rounded py-4 text-center hover:bg-slate-700"
      onAdd={onAdd}
      newElementPath={newElementPath}
      selectedElementConfig={selectedElementConfig}
      setSelectedElement={setSelectedElement}
      element={element}
      project={project}
      onAddComponent={onAddComponent}
      updateElements={updateElements}
    >
      <Icon className="mx-auto mb-2" size={28} />
      <span className="text-sm">{getText(labelKey.join('.'))}</span>
    </SelectableElementItem>
  );
};

ComponentItem.defaultProps = {};

export default ComponentItem;
