import React, { useCallback, useEffect, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import {
  HelpTooltip,
  Label,
  Notice,
  SelectInput,
  Surface,
} from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { INFO } from '@noloco/components/src/constants/variants';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import {
  DATA_FIELD_TYPE,
  DataFieldType,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import { VALID_FORMULA_OUTPUT_TYPES_TO_CONVERT } from '@noloco/core/src/constants/fieldConversion';
import PRIMITIVE_DATA_TYPES from '@noloco/core/src/constants/primitiveDataTypes';
import {
  AI_GENERATION_OPERATION,
  AiGenerationOperation,
  DataField,
  DataFieldOption,
  FieldTypeOptions,
  VALID_AI_COLUMN_OPERATIONS,
} from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { StringPropValue } from '@noloco/core/src/models/Element';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import { getText } from '@noloco/core/src/utils/lang';
import { isValidPrimaryFieldType } from '@noloco/core/src/utils/primaryFields';
import { RECORD_SCOPE } from '@noloco/core/src/utils/scope';
import { DataItemValueOption } from '@noloco/core/src/utils/state';
import Guide from '../../Guide';
import DraftEditor from '../../canvas/DraftEditor';
import FieldTypeInput from './FieldTypeInput';

const LANG_KEY = 'data.aiGeneration';

interface Props {
  dataType: DataType;
  dataTypes: DataTypes;
  onValidChange?: (isValid: boolean) => void;
  typeOptions: FieldTypeOptions;
  onChangeTypeOptions: (typeOptions: Record<string, any>) => void;
  onChangeOptions: (options: Omit<DataFieldOption, 'id'>[]) => void;
  onProvidedTypeChange: (type: DataFieldType | null) => void;
  providedType: DataFieldType | null;
  readOnly?: boolean;
  surface: Surface;
}

const OPERATIONS_WITH_PROVIDED_TYPES: (AiGenerationOperation | undefined)[] = [
  AI_GENERATION_OPERATION.CHAT_PROMPT,
  AI_GENERATION_OPERATION.CLASSIFY,
];
const CLASSIFY_TYPES = [SINGLE_OPTION, MULTIPLE_OPTION];

const AiOperationOutputTypes = {
  [AI_GENERATION_OPERATION.CLASSIFY]: DATA_FIELD_TYPE.SINGLE_OPTION,
  [AI_GENERATION_OPERATION.SUMMARIZE]: DATA_FIELD_TYPE.TEXT,
  [AI_GENERATION_OPERATION.SENTIMENT]: DATA_FIELD_TYPE.SINGLE_OPTION,
  [AI_GENERATION_OPERATION.CHAT_PROMPT]: DATA_FIELD_TYPE.TEXT,
  [AI_GENERATION_OPERATION.CORRECT_GRAMMAR]: DATA_FIELD_TYPE.TEXT,
  [AI_GENERATION_OPERATION.KEYWORD_EXTRACTION]: DATA_FIELD_TYPE.TEXT,
};

const AiGenerationFieldEditor = ({
  dataType,
  onValidChange,
  onProvidedTypeChange,
  onChangeTypeOptions,
  onChangeOptions,
  typeOptions,
  providedType,
  readOnly,
  surface,
}: Props) => {
  const isValid = useMemo(
    () =>
      !!(
        typeOptions &&
        typeOptions.aiGenerationOptions &&
        typeOptions.aiGenerationOptions.operation &&
        Array.isArray(typeOptions.aiGenerationOptions.prompt) &&
        typeOptions.aiGenerationOptions.prompt.length
      ),
    [typeOptions],
  );
  const previousIsValid = usePrevious(isValid);
  const operation = typeOptions.aiGenerationOptions?.operation;

  useEffect(() => {
    if (isValid !== previousIsValid && onValidChange) {
      onValidChange(isValid);
    }
  }, [isValid, onValidChange, previousIsValid]);

  const AiOperationChoices = useMemo(
    () =>
      VALID_AI_COLUMN_OPERATIONS.map((operation: string) => ({
        value: operation,
        label: getText(LANG_KEY, 'operation', operation),
      })),
    [],
  );

  const updateOperation = useCallback(
    (value: AiGenerationOperation) => {
      const operation = value;
      const valueForType = AiOperationOutputTypes[operation];
      onProvidedTypeChange(valueForType);
      const typeChanged = valueForType !== providedType;

      if (operation === AI_GENERATION_OPERATION.SENTIMENT) {
        onChangeOptions([
          {
            name: 'NEGATIVE',
            display: getText(LANG_KEY, 'sentiment.negative'),
            color: 'red',
            order: 1,
          },
          {
            name: 'NEUTRAL',
            display: getText(LANG_KEY, 'sentiment.neutral'),
            color: 'gray',
            order: 2,
          },
          {
            name: 'POSITIVE',
            display: getText(LANG_KEY, 'sentiment.positive'),
            color: 'green',
            order: 3,
          },
        ]);
      } else {
        onChangeOptions([]);
      }

      onChangeTypeOptions((previous: FieldTypeOptions) => ({
        ...(!typeChanged ? (previous ?? {}) : {}),
        aiGenerationOptions: {
          ...(previous?.aiGenerationOptions ?? {}),
          operation,
        },
      }));
    },
    [onChangeOptions, onChangeTypeOptions, onProvidedTypeChange, providedType],
  );

  const updatePrompt = useCallback(
    (value: StringPropValue) => {
      onChangeTypeOptions((previous: FieldTypeOptions) => ({
        ...(previous ?? {}),
        aiGenerationOptions: {
          ...(previous?.aiGenerationOptions ?? {}),
          prompt: value,
        },
      }));
    },
    [onChangeTypeOptions],
  );

  const dataOptions: DataItemValueOption[] = useMemo(() => {
    if (!dataType.fields) {
      return [
        {
          buttonLabel: [dataType.display],
          dataType,
          label: dataType.display,
          options: [],
        },
      ];
    }

    const fields: DataItemValueOption[] = dataType.fields
      .filter(
        (f: DataField) =>
          PRIMITIVE_DATA_TYPES.includes(f.type as DataFieldType) ||
          isValidPrimaryFieldType(f),
      )
      .map(function (field: DataField) {
        const { display, apiName } = field;

        return {
          label: display,
          field,
          buttonLabel: [dataType.display, display],
          dataType: dataType,
          value: {
            path: apiName,
            id: RECORD_SCOPE,
            apiName,
            display,
            dataType: dataType.apiName,
            buttonLabel: [dataType.display, display],
          },
        };
      });

    return [
      {
        buttonLabel: [dataType.display],
        dataType,
        field: undefined,
        icon: undefined,
        label: dataType.display,
        options: fields,
      },
    ];
  }, [dataType]);

  return (
    <>
      <Guide
        className="mt-2 text-sm"
        href="https://guides.noloco.io/data/collections/ai-generation"
      >
        {getText(LANG_KEY, 'guide')}
      </Guide>
      <div
        className="mt-4 flex flex-col"
        data-testid="field-ai-generation-editor"
      >
        <div className="mb-1 mt-2 flex">
          <Label surface={surface} m={0}>
            {getText(LANG_KEY, 'operation.label')}
          </Label>
          <HelpTooltip
            className={classNames('ml-2 hover:text-gray-500', {
              'text-gray-800': surface === LIGHT,
              'text-gray-200': surface === DARK,
            })}
            placement="right"
          >
            <p>{getText(LANG_KEY, 'operation.tooltip')}</p>
          </HelpTooltip>
        </div>
        <SelectInput
          options={AiOperationChoices}
          onChange={updateOperation}
          value={operation}
          searchable={false}
        />
        {operation && (
          <>
            <div className="mb-1 mt-2 flex">
              <Label surface={surface} m={0}>
                {getText(LANG_KEY, 'prompt', operation as string, 'label')}
              </Label>
              <HelpTooltip
                className={classNames('ml-2 hover:text-gray-500', {
                  'text-gray-800': surface === LIGHT,
                  'text-gray-200': surface === DARK,
                })}
                placement="right"
              >
                <p>
                  {getText(LANG_KEY, 'prompt', operation as string, 'tooltip')}
                </p>
              </HelpTooltip>
            </div>
            <div
              className={classNames(
                'w-full rounded-lg px-3 py-2 pl-2 text-sm',
                {
                  'bg-slate-700 text-white': surface === DARK || !surface,
                  'border border-gray-300 bg-white text-gray-800':
                    surface === LIGHT,
                },
              )}
              data-testid="formula-editor"
            >
              {/* @ts-expect-error TS(2786): 'ErrorBoundary' cannot be used as a JSX component. */}
              <ErrorBoundary>
                <DraftEditor
                  checkFieldPermissions={false}
                  key={'aiGenerationPrompt'}
                  disabled={readOnly}
                  elementPath={['aiGenerationPrompt']}
                  placeholder={getText(
                    LANG_KEY,
                    'prompt',
                    operation as string,
                    'placeholder',
                  )}
                  items={typeOptions?.aiGenerationOptions?.prompt ?? []}
                  onUpdate={updatePrompt}
                  multiLine={true}
                  dataOptions={dataOptions}
                />
              </ErrorBoundary>
            </div>
          </>
        )}
        {OPERATIONS_WITH_PROVIDED_TYPES.includes(operation) && (
          <div>
            <div className="mb-1 mt-2 flex">
              <Label surface={surface} m={0}>
                {getText(LANG_KEY, 'providedType.label')}
              </Label>
              <HelpTooltip
                className={classNames('ml-2 hover:text-gray-500', {
                  'text-gray-800': surface === LIGHT,
                  'text-gray-200': surface === DARK,
                })}
                placement="right"
              >
                <p>{getText(LANG_KEY, 'providedType.tooltip')}</p>
              </HelpTooltip>
            </div>
            <FieldTypeInput
              aiGenerationFieldsEnabled={false}
              value={providedType}
              dataTypes={[]}
              onChange={onProvidedTypeChange as any}
              surface={DARK}
              dataField={{ typeOptions } as DataField}
              dataTypeSource={dataType.source?.type ?? INTERNAL}
              optionsFilter={(option: any) =>
                (operation === AI_GENERATION_OPERATION.CHAT_PROMPT
                  ? VALID_FORMULA_OUTPUT_TYPES_TO_CONVERT
                  : CLASSIFY_TYPES
                ).includes(option.value)
              }
            />
          </div>
        )}
        <Notice type={INFO} className="mt-4">
          {getText(LANG_KEY, 'lazyEvaluationDisclaimer')}
        </Notice>
      </div>
    </>
  );
};

export default AiGenerationFieldEditor;
