import { useCallback, useState } from 'react';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { UPDATE } from '../../constants/actionTypes';
import { BOOLEAN, DECIMAL, INTEGER, TEXT } from '../../constants/dataTypes';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { Condition } from '../../models/Element';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { FormFieldConfig } from '../../models/View';
import { MutationType } from '../../queries/project';
import { failedConditions } from '../data';
import { formFieldIsRequired } from '../fields';
import { reduceFormConfigToQueryVariables } from '../formValues';
import {
  ValidationError,
  stringifyValidationRuleError,
} from '../validationRules';
import { useBuildDataItemMergedScope } from './useBuildDataItemMergedScope';

const checkInvalidRequiredValueForField = (field: any, value: any) => {
  switch (field.type) {
    case BOOLEAN: {
      return value !== true;
    }
    case INTEGER:
    case DECIMAL:
    case TEXT:
      return value === '';
    default:
      return false;
  }
};

export const getOptimisticResponse = (
  baseDataItem: BaseRecord,
  originalDataItem: Partial<BaseRecord> | null,
  dataType: DataType,
) => ({
  [`update${upperFirst(dataType.apiName)}`]: getOptimisticValue(
    baseDataItem,
    originalDataItem,
  ),
});
const getOptimisticValue = (
  baseDataItem: BaseRecord,
  originalDataItem: Partial<BaseRecord> | null,
): BaseRecord => ({
  ...(originalDataItem ?? {}),
  ...baseDataItem,
});
export const getBulkOptimisticResponse = (
  optimisticValues: BaseRecord[],
  dataType: DataType,
) => ({
  [`bulkUpdate${upperFirst(dataType.apiName)}`]: optimisticValues,
});

const useAutoFormVariables = (
  project: Project,
  dataType: DataType,
  fields: { field: DataField; config: FormFieldConfig }[],
  mutationType: MutationType,
  originalDataItem: Partial<BaseRecord> | null = null,
  onError = (e: any) => e,
  fieldRequiredRulesEnabled = false,
  fieldRequiredRulesAreMet: (requiredConditions: Condition[][]) => boolean,
  formatDataItem: (record: BaseRecord) => BaseRecord = identity,
  transformRecordScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any> = identity,
  initialChangedFieldsMap: Record<number, boolean> = {},
) => {
  const [changedFields, setChangedFields] = useState(initialChangedFieldsMap);

  const [uploads, setUploads] = useState({});

  const buildDataItemMergedScope = useBuildDataItemMergedScope(
    formatDataItem,
    transformRecordScope,
    dataType.name,
  );
  const getQueryVariables = useCallback(
    (
      rawDataItem: any,
      draftUploads: any,
      changedField: any,
      isBulk = false,
    ) => {
      const dataItem = rawDataItem || {};
      const scope = buildDataItemMergedScope(dataItem);

      const validateFieldValue = (newValue: any, field: any, config: any) => {
        const fieldIsEmpty =
          isNil(newValue) || checkInvalidRequiredValueForField(field, newValue);

        const fieldHasChanged = changedFields[field.id];

        if (
          fieldIsEmpty &&
          formFieldIsRequired(
            field,
            config,
            mutationType,
            fieldHasChanged,
            fieldRequiredRulesEnabled,
            fieldRequiredRulesAreMet,
          )
        ) {
          throw new ValidationError(
            `${config.label || field.display} is required`,
          );
        }

        // If a field is not required, and empty we should not try validate it
        if (config.validationRules && !fieldIsEmpty) {
          const validationFailures = failedConditions(
            config.validationRules,
            scope,
            project,
          );

          if (validationFailures.length > 0) {
            const failedConditionsString = validationFailures
              .map((rule: any) => stringifyValidationRuleError(rule, field))
              .join('\n');

            throw new ValidationError(failedConditionsString);
          }
        }
      };

      const currentChangedFields = { ...changedFields };

      if (changedField) {
        currentChangedFields[changedField.id] = true;
      }

      try {
        const validFieldConfigs = fields.filter(
          ({ field, config }: any) =>
            !field.readOnly &&
            (currentChangedFields[field.id] ||
              config.hidden ||
              config.defaultValue ||
              config.defaultValue === false ||
              config.prefilled ||
              formFieldIsRequired(
                field,
                config,
                mutationType,
                currentChangedFields[field.id],
                fieldRequiredRulesEnabled,
                fieldRequiredRulesAreMet,
              )),
        );
        const variables = reduceFormConfigToQueryVariables(
          mutationType,
          validFieldConfigs,
          originalDataItem,
          dataItem,
          draftUploads,
          validateFieldValue,
        );
        const optimisticResponse =
          !isBulk && mutationType === UPDATE
            ? getOptimisticResponse(dataItem, originalDataItem, dataType)
            : undefined;

        return {
          variables,
          changedFields: currentChangedFields,
          optimisticResponse,
          optimisticValue: isBulk
            ? getOptimisticValue(dataItem, originalDataItem)
            : undefined,
        };
      } catch (e) {
        if (onError) {
          onError(e);
        }
      }

      return {};
    },
    [
      buildDataItemMergedScope,
      changedFields,
      dataType,
      fieldRequiredRulesAreMet,
      fieldRequiredRulesEnabled,
      fields,
      mutationType,
      onError,
      originalDataItem,
      project,
    ],
  );

  return {
    changedFields,
    getQueryVariables,
    setChangedFields,
    setUploads,
    uploads,
  };
};

export default useAutoFormVariables;
