import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { DARK } from '../../constants/surface';
import { Surface } from '../../models';
import Tooltip from './Tooltip';

const DATE = 'date';

interface Props {
  className?: string;
  date: Date | string | undefined;
  surface?: Surface;
  tooltipPrefix?: string;
  tooltipSuffix?: string;
  live?: boolean;
  format?: string;
  timeZone?: string;
}

const RelativeDateTimeTooltip = ({
  className,
  date,
  surface,
  tooltipPrefix,
  tooltipSuffix,
  live = false,
  format,
  timeZone,
}: Props) => {
  const isDateField = format === DATE;

  const parsedDate = useMemo(() => {
    if (date) {
      const dt = DateTime.fromJSDate(new Date(date));

      if (isDateField) {
        return dt.toUTC().startOf('day');
      }

      if (timeZone) {
        return dt.setZone(timeZone);
      }

      return dt.setZone('local');
    }
  }, [date, timeZone, isDateField]);

  const localeString = useMemo(() => {
    if (parsedDate) {
      return isDateField
        ? parsedDate.toJSDate().toLocaleDateString()
        : parsedDate.toJSDate().toLocaleString();
    }
  }, [parsedDate, isDateField]);

  const [relativeTime, setRelativeTime] = useState<string | null>(null);

  const updateRelativeTime = useCallback(
    (dateTime: DateTime) => {
      if (isDateField) {
        const now = DateTime.now().toUTC().startOf('day');
        const diffInDays = now.diff(dateTime, 'days').days;

        if (diffInDays === 0) {
          return 'Today';
        }

        if (diffInDays === 1) {
          return 'Yesterday';
        }

        if (diffInDays === -1) {
          return 'Tomorrow';
        }
      }

      return dateTime.toRelative();
    },
    [isDateField],
  );

  useEffect(() => {
    if (parsedDate) {
      setRelativeTime(updateRelativeTime(parsedDate));

      if (live) {
        const interval = setInterval(() => {
          setRelativeTime(updateRelativeTime(parsedDate));
        }, 10000);

        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [live, parsedDate, updateRelativeTime]);

  return (
    <div
      className={classNames(
        {
          'text-gray-500': surface !== DARK && !date,
          'text-black': surface !== DARK && date,
          'text-gray-400': surface === DARK && !date,
          'text-white': surface === DARK && date,
        },
        className,
      )}
    >
      <Tooltip
        content={
          <span>
            {tooltipPrefix}
            {localeString}
            {tooltipSuffix}
          </span>
        }
        disabled={!parsedDate}
      >
        <p
          className={classNames({
            'opacity-50': !date,
            'cursor-pointer': date,
          })}
        >
          {relativeTime ?? '--'}
        </p>
      </Tooltip>
    </div>
  );
};

export default RelativeDateTimeTooltip;
