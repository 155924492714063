import React, { useCallback, useMemo } from 'react';
import { IconGitFork, IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import dropRight from 'lodash/fp/dropRight';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import shortid from 'shortid';
import { Button } from '@noloco/components';
import { OR } from '@noloco/core/src/constants/operators';
import { useFieldOperatorConflicts } from '@noloco/core/src/utils/hooks/useFieldOperatorConflicts';
import { getText } from '@noloco/core/src/utils/lang';
import FilterInput from '../FilterInput';
import FilterMergeWarning from '../FilterMergeWarning';
import OrFilterInput from '../OrFilterInput';

const ADDITIONAL_FILTER_BASE = {
  field: null,
  operator: null,
  result: null,
};
export const generateAdditionalFilter = (overrides = {}) => ({
  ...ADDITIONAL_FILTER_BASE,
  id: shortid.generate(),
  ...overrides,
});

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const CustomFiltersEditor = ({
  additionalScopeItems,
  contained,
  customFilters,
  elementPath,
  onUpdateCustomFilters,
  selectedDataType,
  project,
  ValueInput,
}: any) => {
  const orFilter = useMemo(() => {
    const foundFilter = customFilters.find((f: any) => f.operator === OR);

    if (!foundFilter) {
      return undefined;
    }

    return {
      filter: foundFilter,
      index: customFilters.indexOf(foundFilter),
    };
  }, [customFilters]);

  const andFilters = useMemo(() => {
    if (!orFilter) {
      return customFilters;
    }

    return dropRight(1, customFilters);
  }, [customFilters, orFilter]);

  const { fieldOperatorCounts, showFieldOperatorWarning } =
    useFieldOperatorConflicts(andFilters);

  const onAddAndFilter = useCallback(
    () =>
      onUpdateCustomFilters(
        [...customFilters, generateAdditionalFilter()].sort((a, b) => {
          // @ts-expect-error TS(2447): The '^' operator is not allowed for boolean types.... Remove this comment to see the full error message
          if ((a.operator === OR) ^ (b.operator === OR)) {
            return a.operator === OR ? 1 : -1;
          }

          return 0;
        }),
      ),
    [customFilters, onUpdateCustomFilters],
  );

  const onAddOrBlock = useCallback(
    () =>
      onUpdateCustomFilters([
        ...customFilters,
        {
          operator: OR,
          branches: [
            {
              filters: [generateAdditionalFilter()],
              id: shortid.generate(),
            },
          ],
        },
      ]),
    [customFilters, onUpdateCustomFilters],
  );

  const onUpdateFilter = (index: any) => (value: any) =>
    onUpdateCustomFilters(set(index, value, customFilters));

  const onRemoveFilter = (index: any) => () =>
    onUpdateCustomFilters(
      customFilters.filter((_: any, filterIndex: any) => filterIndex !== index),
    );

  return (
    <>
      {andFilters.length > 0 && (
        <>
          <label className="mb-2 mt-6 text-gray-400">
            {getTranslation('label')}
          </label>
          {showFieldOperatorWarning && <FilterMergeWarning />}
          <div
            className={classNames(
              'mt-2 flex w-screen max-w-xl flex-col space-y-4',
              { 'max-w-full': contained, 'max-w-xl': !contained },
            )}
          >
            {andFilters.map((additionalFilter: any, index: any) => (
              <FilterInput
                additionalScopeItems={additionalScopeItems}
                dataType={selectedDataType}
                key={
                  additionalFilter.id || `${additionalFilter.field}-${index}`
                }
                value={additionalFilter}
                onChange={onUpdateFilter(index)}
                onRemove={onRemoveFilter(index)}
                elementPath={elementPath}
                operatorValidationError={
                  get(
                    fieldOperatorCounts,
                    [additionalFilter.field, additionalFilter.operator],
                    0,
                  ) > 1
                }
                project={project}
                ValueInput={ValueInput}
              />
            ))}
          </div>
        </>
      )}
      <Button
        className="my-2 mr-auto flex items-center"
        onClick={onAddAndFilter}
        variant="secondary"
      >
        <IconPlus className="mr-2" size={16} />
        <span>{getTranslation('addAnd')}</span>
      </Button>
      {!isNil(orFilter) && (
        <OrFilterInput
          additionalScopeItems={additionalScopeItems}
          dataType={selectedDataType}
          elementPath={elementPath}
          generateAdditionalFilter={generateAdditionalFilter}
          onChange={onUpdateFilter(orFilter.index)}
          onRemove={onRemoveFilter(orFilter.index)}
          project={project}
          value={orFilter.filter}
          ValueInput={ValueInput}
        />
      )}
      {isNil(orFilter) && (
        <Button
          className="mr-auto mt-2 flex items-center"
          onClick={onAddOrBlock}
          variant="secondary"
        >
          <IconGitFork className="mr-2" size={16} />
          <span>{getTranslation('addOr')}</span>
        </Button>
      )}
    </>
  );
};

export default CustomFiltersEditor;
