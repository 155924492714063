import React, { useCallback, useMemo } from 'react';
import { IconDeviceFloppy, IconKey, IconPlus } from '@tabler/icons-react';
import { Button, Loader } from '@noloco/components';
import {
  ApiConnection,
  Authentication,
  Endpoint,
  OAUTH2,
  OAuth2Options,
} from '@noloco/core/src/constants/apis';
import { KeyValue } from '@noloco/core/src/constants/keyValue';
import { isEndpointValid } from '@noloco/core/src/utils/apis';
import { getText } from '@noloco/core/src/utils/lang';
import ApiHeadersEditor from '../../editor/KeyValueEditor';
import ApiAuthenticationEditor from '../../leftSidebar/data/api/ApiAuthenticationEditor';
import ApiBasicsEditor from '../../leftSidebar/data/api/ApiBasicsEditor';
import ApiEndpoints from '../../leftSidebar/data/api/ApiEndpoints';

const LANG_KEY = 'data.api';

interface Props {
  api: ApiConnection;
  callbackUrl: string | null;
  display: string;
  endpoints: Endpoint[];
  hasDataSourceId: boolean;
  isDisplayValid: boolean;
  loading: boolean;
  onAddEndpoint: () => void;
  onAuthorize: () => void;
  onCreateApi: () => void;
  onSaveApi: () => void;
  onSelectEndpoint: (index: number) => () => void;
  onUpdateApi: (path: string[], value: any) => void;
  onUpdateDisplay: (display: string) => void;
  saving: boolean;
  selectedEndpoint: number | null;
}

const ApiEditor = ({
  api,
  callbackUrl,
  display,
  endpoints,
  hasDataSourceId,
  isDisplayValid,
  loading,
  onAddEndpoint,
  onAuthorize,
  onCreateApi,
  onSaveApi,
  onSelectEndpoint,
  onUpdateApi,
  onUpdateDisplay,
  saving,
  selectedEndpoint,
}: Props) => {
  const isApiValid = isDisplayValid && !!api.baseUrl;

  const isAuthValid = useMemo(() => {
    switch (api.authentication) {
      case OAUTH2:
        return (
          api.options?.accessTokenUrl &&
          api.options?.authorizationUrl &&
          api.options?.clientId &&
          api.options?.clientSecret
        );
      default:
        return true;
    }
  }, [api.authentication, api.options]);

  const isEachEndpointValid = useMemo(
    () => endpoints.map(isEndpointValid),
    [endpoints],
  );

  const onUpdateAuthenticationMethod = useCallback(
    (authenticationMethod: Authentication) =>
      onUpdateApi(['authentication'], authenticationMethod),
    [onUpdateApi],
  );
  const onUpdateAuthenticationOptions = useCallback(
    (authenticationOptions: OAuth2Options | null) =>
      onUpdateApi(['options'], authenticationOptions),
    [onUpdateApi],
  );
  const onUpdateBaseUrl = useCallback(
    (baseUrl: string) => onUpdateApi(['baseUrl'], baseUrl),
    [onUpdateApi],
  );
  const onUpdateHeaders = useCallback(
    (headers: KeyValue[]) => onUpdateApi(['headers'], headers),
    [onUpdateApi],
  );

  if (loading) {
    return <Loader className="mx-auto" />;
  }

  return (
    <>
      <ApiBasicsEditor
        baseUrl={api.baseUrl}
        disabled={saving}
        display={display}
        isDisplayNameValid={isDisplayValid}
        onUpdateBaseUrl={onUpdateBaseUrl}
        onUpdateDisplay={onUpdateDisplay}
      />
      <label className="text-sm font-medium leading-5">
        {getText(LANG_KEY, 'headers.label')}
      </label>
      <p className="text-sm text-gray-400">
        {getText(LANG_KEY, 'headers.help')}
      </p>
      <ApiHeadersEditor
        disabled={saving}
        onUpdatePairs={onUpdateHeaders}
        pairs={api.headers}
      />
      <label className="text-sm font-medium leading-5">
        {getText(LANG_KEY, 'authentication.label')}
      </label>
      <p className="text-sm text-gray-400">
        {getText(LANG_KEY, 'authentication.help')}
      </p>
      <ApiAuthenticationEditor
        callbackUrl={callbackUrl}
        disabled={saving}
        method={api.authentication}
        options={api.options}
        onUpdateMethod={onUpdateAuthenticationMethod}
        onUpdateOptions={onUpdateAuthenticationOptions}
      />
      {hasDataSourceId && (
        <div>
          <label className="flex w-full text-sm font-medium leading-5">
            {getText(LANG_KEY, 'endpoints.label')}
            <span className="ml-auto cursor-pointer" onClick={onAddEndpoint}>
              <IconPlus size={16} />
            </span>
          </label>
          <ApiEndpoints
            disabled={saving}
            error={
              !api.baseUrl ? getText(LANG_KEY, 'endpoints.disabled') : null
            }
            endpoints={endpoints}
            endpointsAreValid={isEachEndpointValid}
            onSelectEndpoint={onSelectEndpoint}
            selectedEndpoint={selectedEndpoint}
          />
        </div>
      )}
      {!hasDataSourceId && (
        <Button
          className="mt-6 flex items-center disabled:opacity-75"
          disabled={!isApiValid}
          onClick={onCreateApi}
        >
          {saving ? (
            <>
              <span>{getText(LANG_KEY, 'creating')}</span>
              <Loader size="xs" className="ml-2" />
            </>
          ) : (
            <>
              <span>{getText(LANG_KEY, 'create')}</span>
              <IconPlus size={16} className="ml-2 opacity-75" />
            </>
          )}
        </Button>
      )}
      {hasDataSourceId && (
        <div className="flex space-x-2">
          <Button
            className="mt-6 flex items-center disabled:opacity-75"
            disabled={!isApiValid || saving}
            onClick={onSaveApi}
          >
            {saving ? (
              <>
                <span>{getText(LANG_KEY, 'save')}</span>
                <Loader size="xs" className="ml-2" />
              </>
            ) : (
              <>
                <span>{getText(LANG_KEY, 'save')}</span>
                <IconDeviceFloppy size={16} className="ml-2 opacity-75" />
              </>
            )}
          </Button>
          {api.authentication === OAUTH2 && (
            <Button
              className="mt-6 flex items-center disabled:opacity-75"
              disabled={!isAuthValid || saving}
              onClick={onAuthorize}
            >
              <span>
                {getText(
                  LANG_KEY,
                  api.options?.accessToken ? 'reauthorize' : 'authorize',
                )}
              </span>
              <IconKey size={16} className="ml-2 opacity-75" />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default ApiEditor;
