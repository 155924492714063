import React, { useCallback, useMemo } from 'react';
import set from 'lodash/fp/set';
import { FormField, SelectInput } from '@noloco/components';
import {
  Authentication,
  OAUTH2,
  OAuth2Options,
  apiAuthenticationTypes,
} from '@noloco/core/src/constants/apis';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'data.api.authentication';

interface Props {
  callbackUrl: string | null;
  disabled: boolean;
  method: string;
  options: null | OAuth2Options;
  onUpdateMethod: (method: Authentication) => void;
  onUpdateOptions: (options: null | OAuth2Options) => void;
}

const ApiAuthenticationEditor = ({
  callbackUrl,
  disabled,
  method,
  options,
  onUpdateMethod,
  onUpdateOptions,
}: Props) => {
  const methodOptions = useMemo(
    () =>
      apiAuthenticationTypes.map((option) => ({
        label: getText(LANG_KEY, 'options', option, 'label'),
        value: option,
      })),
    [],
  );

  const onUpdateOption = useCallback(
    (option: string, value: any) =>
      onUpdateOptions(set([option], value, options ?? {})),
    [onUpdateOptions, options],
  );

  return (
    <div className="mb-6 mt-3">
      <label className="text-sm font-medium leading-5">
        {getText(LANG_KEY, 'method.label')}
      </label>
      <SelectInput
        className="mb-4 mt-2 w-full"
        disabled={disabled}
        placeholder={getText(LANG_KEY, 'method.placeholder')}
        options={methodOptions}
        value={method}
        onChange={(value: any) => onUpdateMethod(value)}
      />
      {method === OAUTH2 && (
        <>
          <label className="mb-2 text-sm font-medium leading-5">
            {getText(LANG_KEY, 'options.label')}
          </label>
          <div className="mt-3 flex flex-col">
            <p className="rounded-lg bg-pink-900 p-3 text-sm text-pink-100">
              {getText(
                { oauthToken: '{{OAUTH2_TOKEN}}' },
                LANG_KEY,
                'options',
                OAUTH2,
                'help',
              )}
              <br />

              <code>{'Authorization: Bearer {{OAUTH2_TOKEN}}'}</code>
            </p>
            <FormField
              className="mb-4 mt-3 w-full"
              label={
                <span className="text-white">
                  {getText(LANG_KEY, 'options', OAUTH2, 'callbackUrl.label')}
                </span>
              }
              placeholder={getText(
                LANG_KEY,
                'options',
                OAUTH2,
                'callbackUrl.placeholder',
              )}
              readOnly={true}
              value={callbackUrl}
            />
            <FormField
              className="mb-4 mt-2 w-full"
              disabled={disabled}
              label={
                <span className="text-white">
                  {getText(
                    LANG_KEY,
                    'options',
                    OAUTH2,
                    'authorizationUrl.label',
                  )}
                </span>
              }
              placeholder={getText(
                LANG_KEY,
                'options',
                OAUTH2,
                'authorizationUrl.placeholder',
              )}
              value={options?.authorizationUrl}
              onChange={({ target: { value } }: any) =>
                onUpdateOption('authorizationUrl', value)
              }
            />
            <FormField
              className="mb-4 mt-2 w-full"
              disabled={disabled}
              label={
                <span className="text-white">
                  {getText(LANG_KEY, 'options', OAUTH2, 'accessTokenUrl.label')}
                </span>
              }
              placeholder={getText(
                LANG_KEY,
                'options',
                OAUTH2,
                'accessTokenUrl.placeholder',
              )}
              value={options?.accessTokenUrl}
              onChange={({ target: { value } }: any) =>
                onUpdateOption('accessTokenUrl', value)
              }
            />
            <FormField
              className="mb-4 mt-2 w-full"
              disabled={disabled}
              label={
                <span className="text-white">
                  {getText(LANG_KEY, 'options', OAUTH2, 'clientId.label')}
                </span>
              }
              placeholder=""
              value={options?.clientId}
              onChange={({ target: { value } }: any) =>
                onUpdateOption('clientId', value)
              }
            />
            <FormField
              className="mb-4 mt-2 w-full"
              disabled={disabled}
              label={
                <span className="text-white">
                  {getText(LANG_KEY, 'options', OAUTH2, 'clientSecret.label')}
                </span>
              }
              placeholder=""
              type="password"
              value={options?.clientSecret}
              onChange={({ target: { value } }: any) =>
                onUpdateOption('clientSecret', value)
              }
            />
            <FormField
              className="mb-4 mt-2 w-full"
              disabled={disabled}
              label={
                <span className="text-white">
                  {getText(LANG_KEY, 'options', OAUTH2, 'scopes.label')}
                </span>
              }
              placeholder=""
              value={options?.scopes}
              onChange={({ target: { value } }: any) =>
                onUpdateOption('scopes', value)
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ApiAuthenticationEditor;
