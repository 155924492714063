import React, { memo, useMemo } from 'react';
import get from 'lodash/get';
import { Redirect, Route, Switch } from 'react-router';
import ForgotPassword from '@noloco/core/src/elements/ForgotPassword';
import Join from '@noloco/core/src/elements/Join';
import Login from '@noloco/core/src/elements/Login';
import Register from '@noloco/core/src/elements/Register';
import { ProjectSettings } from '@noloco/core/src/models/Project';
import { User } from '@noloco/core/src/models/User';
import { isSsoEnabled } from '@noloco/core/src/utils/auth';
import useNolocoTheme from '@noloco/core/src/utils/hooks/useNolocoTheme';
import AdminNavbar from './AdminNavbar';
import AdminRoutes from './AdminRoutes';
import CanvasRoute from './CanvasRoute';
import PublicFormsProjectRenderer from './canvas/PublicFormsProjectRenderer';
import buildModeTheme from './canvas/buildModeTheme';

interface Props {
  settings: ProjectSettings;
  showBuilderExp?: boolean;
  showDataTab?: boolean;
  user: User;
}

const ProjectBody = memo(
  ({ settings, showBuilderExp, showDataTab, user }: Props) => {
    const openSignUpEnabled = useMemo(
      () => get(settings, 'openSignUp.enabled', false),
      [settings],
    );
    const ssoEnabled = useMemo(() => isSsoEnabled(settings), [settings]);
    const nolocoTheme = useNolocoTheme();

    return (
      <Switch>
        <Route path="/login">
          <Login settings={settings} type="CARD" />
        </Route>
        <Route path="/register">
          {openSignUpEnabled && !ssoEnabled ? (
            <Register settings={settings} type="CARD" />
          ) : (
            <Login settings={settings} type="CARD" />
          )}
        </Route>
        <Route path="/join/:invitationToken?">
          {!ssoEnabled ? (
            <Join settings={settings} type="CARD" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route path="/forgot">
          {!ssoEnabled ? (
            <ForgotPassword settings={settings} type="CARD" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route path="/+/:elementId/:referenceId">
          <PublicFormsProjectRenderer />
        </Route>
        <Route>
          <>
            {(showBuilderExp || showDataTab) && (
              <AdminNavbar
                settings={settings}
                showBuilderExp={showBuilderExp}
                showDataTab={showDataTab}
              />
            )}
            <Switch>
              {(showBuilderExp || showDataTab) && (
                <Route path="/_/" exact={false}>
                  <AdminRoutes
                    nolocoTheme={buildModeTheme}
                    userFirstName={user && user.firstName}
                  />
                </Route>
              )}
              <Route>
                <CanvasRoute nolocoTheme={nolocoTheme} />
              </Route>
            </Switch>
          </>
        </Route>
      </Switch>
    );
  },
);

ProjectBody.displayName = 'ProjectBody';

export default ProjectBody;
