import React, { forwardRef } from 'react';
import flow from 'lodash/flow';
import get from 'lodash/fp/get';
import initial from 'lodash/initial';
import { DragSource } from 'react-dnd';
import baseElementsConfig from '@noloco/core/src/elements/baseElementConfig';
import { ElementPath } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';
import {
  addNewElementAsChild,
  addNewElementToPathAtIndex,
} from '../../../utils/dragAndDrop';
import { generateNewElementFromNodeShape } from '../../../utils/elements';
import { useAddComponent } from '../../../utils/hooks/projectHooks';

interface SelectableElementItemProps {
  onAdd: (...args: any[]) => any;
  element: any;
  project: any;
  newElementPath: ElementPath;
  selectedElementConfig?: any; // TODO: PropTypes.instanceOf(ElementConfig)
  setSelectedElement: (...args: any[]) => any;
  onAddComponent: (...args: any[]) => any;
  updateElements: (...args: any[]) => any;
}

const SelectableElementItem = forwardRef<any, SelectableElementItemProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      // @ts-expect-error TS(2339): Property 'connectDragPreview' does not exist on ty... Remove this comment to see the full error message
      connectDragPreview,
      // @ts-expect-error TS(2339): Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
      connectDragSource,
      onAdd,
      newElementPath,
      element,
      project,
    },
    ref,
  ) => {
    const { name, render: __, labelKey, ...elementShape } = element;
    const addComponent = useAddComponent(project, newElementPath);

    const handleAddComponent = () => {
      addComponent(elementShape, name || getText(labelKey.join('.')), () => {
        onAdd();
      });
    };

    return connectDragPreview(
      connectDragSource(
        <div className={className} ref={ref} onClick={handleAddComponent}>
          <div>{children}</div>
        </div>,
      ),
    );
  },
);

SelectableElementItem.defaultProps = {};

const connectProps = ({
  selectedElementPath: path,
  project,
  element,
  onAdd,
  onAddComponent,
  updateElements,
}: any) => ({
  element,
  path,
  type: element.type,
  project,
  close: onAdd,
  onAddComponent,
  updateElements,
});

const sourceSpec = {
  canDrag: () => true,
  beginDrag: connectProps,
  endDrag: (props: any, monitor: any) => {
    const draggedItem = monitor.getItem();
    const droppedItem = monitor.getDropResult();

    if (!droppedItem || !draggedItem) {
      return;
    }
    const isDropDivider = droppedItem.isDivider;
    const projectElements = props.project.elements;
    const droppedElement = !isDropDivider
      ? get(droppedItem.path, projectElements)
      : null;

    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 3.
    const droppedSiblings = get(initial(droppedItem.path), projectElements, []);

    const { name, render: __, labelKey, ...elementShape } = draggedItem.element;
    const newElement = generateNewElementFromNodeShape({
      ...elementShape,
      id: undefined,
      props: {
        ...(elementShape.props || {}),
        name: name || getText(labelKey.join('.')),
      },
    });

    if (
      isDropDivider ||
      !baseElementsConfig[droppedItem.type].canHaveChildren
    ) {
      addNewElementToPathAtIndex(
        droppedSiblings,
        droppedItem.path,
        newElement,
        projectElements,
        draggedItem.updateElements,
      );
      draggedItem.close();

      return draggedItem.onAddComponent(newElement, droppedItem.path);
    }

    const newPath = addNewElementAsChild(
      droppedItem.path,
      droppedElement,
      newElement,
      projectElements,
      draggedItem.updateElements,
    );
    draggedItem.close();

    return draggedItem.onAddComponent(newElement, newPath);
  },
};

// @ts-expect-error TS(7006): Parameter 'dragConnect' implicitly has an 'any' ty... Remove this comment to see the full error message
const sourceCollect = (dragConnect, monitor) => ({
  connectDragSource: dragConnect.dragSource(),
  connectDragPreview: dragConnect.dragPreview(),
  isDragging: monitor.isDragging(),
});

const DraggableElementItem = flow([
  DragSource('component', sourceSpec, sourceCollect),
])(SelectableElementItem);

export default DraggableElementItem;
