import React, { useMemo, useState } from 'react';
import { IconPencilPlus, IconShieldLock } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Tooltip } from '@noloco/components';
import {
  API,
  CUSTOM_QUERY,
  INTERNAL,
} from '@noloco/core/src/constants/dataSources';
import { FIELD_LEVEL_PERMISSIONS } from '@noloco/core/src/constants/features';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { dataTypeHasFieldsWithoutRules } from '@noloco/core/src/utils/permissions';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import DeleteDataTypeButton from './components/DeleteDataTypeButton';
import EditDataTypeButton from './components/EditDataTypeButton';

interface DataTypeListItemProps {
  dataType: DataType;
  groupSize: number;
  selectedDataTypeName: string;
  setDataTypeToDelete: (dataTypeToDelete: DataType) => void;
  project: Project;
  isBuilder: boolean;
}

const DataTypeListItem = ({
  dataType,
  groupSize,
  selectedDataTypeName,
  setDataTypeToDelete,
  project,
  isBuilder,
}: DataTypeListItemProps) => {
  const [showEditCollectionNameModal, setShowEditCollectionNameModal] =
    useState(false);
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const isSelected = dataType.name === selectedDataTypeName;

  const hasFieldsWithoutRules = useMemo(
    () => fieldPermissionsEnabled && dataTypeHasFieldsWithoutRules(dataType),
    [dataType, fieldPermissionsEnabled],
  );
  const showButtons = isSelected || hasFieldsWithoutRules;

  return (
    <Link
      to={`/_/data/internal/${dataType.name}?lt=data`}
      key={dataType.name}
      className="mb-1 flex w-full flex-col"
    >
      <div
        className={classNames(
          'group flex items-center rounded-lg p-2 hover:bg-slate-800',
          {
            'bg-slate-900': isSelected,
          },
        )}
      >
        <span
          className={classNames('flex space-x-1 break-words', {
            'w-full': dataType.name !== selectedDataTypeName,
            'w-3/4': showButtons,
          })}
        >
          <span>{dataType.display}</span>
          {get(dataType, ['source', 'syncEnabled']) === false && (
            <Tooltip
              content={getText('leftSidebar.data.disabled')}
              bg={'white'}
            >
              <div className="h-2 w-2 rounded-full bg-yellow-400" />
            </Tooltip>
          )}
        </span>

        <span
          className={classNames(
            'absolute right-3.5 ml-auto items-center p-1.5 group-hover:flex',
            showButtons ? 'flex' : 'hidden',
          )}
        >
          {dataType.source.collectionType === CUSTOM_QUERY && isBuilder && (
            <Tooltip content={getText('leftSidebar.data.editCustomQuery')}>
              <Link
                className={classNames(
                  'relative ml-auto mr-2 text-gray-500 hover:text-white',
                  {
                    'invisible hover:visible': !isSelected,
                  },
                )}
                to={`/_/setup/${dataType.source.type.toLowerCase()}/${
                  dataType.source.id
                }/query/${dataType.id}`}
              >
                <IconPencilPlus size={16} />
              </Link>
            </Tooltip>
          )}
          {dataType.source.type === INTERNAL && isBuilder && (
            <EditDataTypeButton
              dataType={dataType}
              project={project}
              showEditCollectionNameModal={showEditCollectionNameModal}
              setShowEditCollectionNameModal={setShowEditCollectionNameModal}
              hasFieldsWithoutRules={hasFieldsWithoutRules}
              isSelected={isSelected}
            />
          )}
          {isBuilder && (
            <Tooltip
              disabled={hasFieldsWithoutRules}
              content={getText('leftSidebar.data.permissions')}
            >
              <Link
                className="relative ml-auto mr-2 text-gray-500 hover:text-white"
                to={`/_/data/internal/${dataType.name}/permissions`}
              >
                <IconShieldLock size={16} />
                {hasFieldsWithoutRules && (
                  <div className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-yellow-400" />
                )}
              </Link>
            </Tooltip>
          )}
          {dataType.source.type !== API && isBuilder && (
            <DeleteDataTypeButton
              className={
                !isSelected && hasFieldsWithoutRules
                  ? 'invisible group-hover:visible'
                  : ''
              }
              dataType={dataType}
              groupSize={groupSize}
              onDelete={() => setDataTypeToDelete(dataType)}
            />
          )}
        </span>
      </div>
    </Link>
  );
};

export default DataTypeListItem;
