import React from 'react';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { SIMPLE } from '@noloco/core/src/constants/elementGroups';
import compoundElements from '@noloco/core/src/elements/compoundElements';
import { setSelectedElement as dispatchSetSelectedElement } from '@noloco/core/src/reducers/elements';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { getText } from '@noloco/core/src/utils/lang';
import { getSelectedElementConfig } from '../../utils/elements';
import LeftPopoutMenu from '../canvas/LeftPopoutMenu';
import ComponentItem from './components/ComponentItem';

interface OwnComponentListProps {
  elements: {
    selected?: any[];
  };
  onClose: (...args: any[]) => any;
  blockProject: any;
  project: any;
  setSelectedElement: (...args: any[]) => any;
}

// @ts-expect-error TS(2456): Type alias 'ComponentListProps' circularly referen... Remove this comment to see the full error message
type ComponentListProps = OwnComponentListProps &
  typeof ComponentList.defaultProps;

// @ts-expect-error TS(7022): 'ComponentList' implicitly has type 'any' because ... Remove this comment to see the full error message
const ComponentList = ({
  elements: { newElementPath },
  onClose,
  setSelectedElement,
}: ComponentListProps) => {
  const project = useSelector(projectDataSelector);
  const selectedConfig = getSelectedElementConfig(
    project.elements,
    newElementPath,
  );

  return (
    <LeftPopoutMenu
      className={classNames('border-brand border')}
      onClose={onClose}
      title={<span>{getText('elementsList.title')}</span>}
    >
      <div className="flex flex-col">
        <div className="flex flex-wrap p-3">
          {compoundElements[SIMPLE].map((element) => (
            <ComponentItem
              element={element}
              key={element.labelKey.join('.')}
              onAdd={onClose}
              project={project}
              newElementPath={newElementPath}
              setSelectedElement={setSelectedElement}
              selectedElementConfig={selectedConfig}
            />
          ))}
        </div>
      </div>
    </LeftPopoutMenu>
  );
};

ComponentList.defaultProps = {};

const mapStateToProps = ({ elements }: any) => ({
  elements,
});

const mapDispatch = {
  setSelectedElement: dispatchSetSelectedElement,
};

export default connect(mapStateToProps, mapDispatch)(ComponentList);
