import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconCode,
  IconMenu2,
  IconSettings,
  IconTableImport,
  IconToggleRight,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { Loader, Popover } from '@noloco/components';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import { AIRTABLE, API } from '@noloco/core/src/constants/dataSources';
import {
  useGraphQlErrorAlert,
  useInfoAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import {
  SYNC_DATA_SOURCE_DATA,
  SYNC_DATA_SOURCE_SCHEMA,
} from '../../../queries/project';
import DeleteDataSourceModal from './DeleteDataSourceModal';
import EnableDataTypeModal from './EnableDataTypeModal';

const LANG_KEY = 'leftSidebar.data.sync';

const DeleteDataSourceButton = ({
  disable,
  setShowDeleteDataSourceModal,
}: {
  disable: boolean;
  setShowDeleteDataSourceModal: (show: boolean) => void;
}) => (
  <button
    className={classNames(
      'flex items-center rounded-lg p-2 text-left text-xs hover:bg-gray-100',
      {
        'text-red-200': disable,
        'text-red-500': !disable,
      },
    )}
    disabled={disable}
    onClick={() => setShowDeleteDataSourceModal(true)}
  >
    <IconTrash size={16} className="mr-4 flex-shrink-0 opacity-75" />
    <span className="w-36">{getText(LANG_KEY, 'delete.label')}</span>
  </button>
);

const DataSyncButton = ({
  dataLoading,
  handleDataSync,
}: {
  dataLoading: boolean;
  handleDataSync: () => void;
}) => (
  <button
    className="flex items-center rounded-lg p-2 text-left text-xs hover:bg-gray-100"
    disabled={dataLoading}
    onClick={handleDataSync}
  >
    <IconTableImport size={16} className="mr-4 flex-shrink-0 opacity-75" />
    <span className="w-36">{getText(LANG_KEY, 'data.label')}</span>
    {dataLoading && <Loader size={XS} className="ml-auto" />}
  </button>
);

const SchemaSyncButton = ({
  schemaLoading,
  handleSchemaSync,
}: {
  schemaLoading: boolean;
  handleSchemaSync: () => void;
}) => (
  <button
    className="flex items-center rounded-lg p-2 text-left text-xs hover:bg-gray-100"
    disabled={schemaLoading}
    onClick={handleSchemaSync}
  >
    <IconCode size={16} className="mr-4 flex-shrink-0 opacity-75" />
    <span className="w-36">{getText(LANG_KEY, 'schema.label')}</span>
    {schemaLoading && <Loader size={XS} className="ml-auto" />}
  </button>
);

const DataSourceMenu = ({ onDelete, projectName, source }: any) => {
  const successAlert = useInfoAlert();
  const errorAlert = useGraphQlErrorAlert();

  const { push } = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const onOpenChange = useCallback(
    (isOpen) => {
      setIsOpen(isOpen);
    },
    [setIsOpen],
  );

  const [showEnableCollectionsModal, setShowEnableCollectionsModal] =
    useState(false);
  const [showDeleteDataSourceModal, setShowDeleteDataSourceModal] =
    useState(false);

  const [syncData, { loading: dataLoading }] = useMutation(
    SYNC_DATA_SOURCE_DATA,
  );
  const [syncSchema, { loading: schemaLoading }] = useMutation(
    SYNC_DATA_SOURCE_SCHEMA,
  );
  const isLoading = dataLoading || schemaLoading;
  const previousLoading = usePrevious(isLoading);

  useEffect(() => {
    if (previousLoading && !isLoading) {
      return setIsOpen(false);
    }
  }, [previousLoading, isLoading, setIsOpen]);

  const queryOptions = useMemo(
    () => ({
      variables: {
        projectName,
        id: source.id,
      },
      context: {
        authQuery: true,
      },
    }),
    [projectName, source.id],
  );

  const handleDataSync = useCallback(
    () =>
      syncData(queryOptions)
        .then(() => {
          successAlert(
            getText(LANG_KEY, 'data.success.message'),
            getText(LANG_KEY, 'data.success.text'),
            { timeout: 30000 },
          );
        })
        .catch((e) => {
          errorAlert(getText(LANG_KEY, 'data.error'), e);
        }),
    [errorAlert, queryOptions, successAlert, syncData],
  );

  const handleSchemaSync = useCallback(
    () =>
      syncSchema(queryOptions)
        .then(() => {
          successAlert(
            getText(LANG_KEY, 'schema.success.message'),
            getText(LANG_KEY, 'schema.success.text'),
            { timeout: 30000 },
          );
        })
        .catch((e) => {
          errorAlert(getText(LANG_KEY, 'schema.error'), e);
        }),
    [errorAlert, queryOptions, successAlert, syncSchema],
  );

  const sourceAllowsSchemaSyncing = useMemo(
    () => source.type !== API,
    [source.type],
  );

  if (showEnableCollectionsModal) {
    return (
      <EnableDataTypeModal
        dataSource={source}
        onClose={() => setShowEnableCollectionsModal(false)}
        projectName={projectName}
      />
    );
  }

  if (showDeleteDataSourceModal) {
    return (
      <DeleteDataSourceModal
        dataSource={source}
        onClose={() => {
          onDelete();
          setShowDeleteDataSourceModal(false);
        }}
        projectName={projectName}
      />
    );
  }

  return (
    <Popover
      content={
        <div className="flex max-w-md flex-col space-y-0.5 p-2 text-left">
          {source.type !== AIRTABLE && (
            <button
              className="flex items-center rounded-lg p-2 text-left text-xs hover:bg-gray-100"
              onClick={() =>
                push(`/_/setup/${kebabCase(source.type)}/${source.id}`)
              }
            >
              <IconSettings
                size={16}
                className="mr-4 flex-shrink-0 opacity-75"
              />
              <span className="w-72">{getText(LANG_KEY, 'update.label')}</span>
            </button>
          )}
          <DataSyncButton
            dataLoading={dataLoading}
            handleDataSync={handleDataSync}
          />
          {sourceAllowsSchemaSyncing && (
            <SchemaSyncButton
              handleSchemaSync={handleSchemaSync}
              schemaLoading={schemaLoading}
            />
          )}
          <button
            className="flex items-center rounded-lg p-2 text-left text-xs hover:bg-gray-100"
            onClick={() => {
              setShowEnableCollectionsModal(true);
              setIsOpen(false);
            }}
          >
            <IconToggleRight
              size={16}
              className="mr-4 flex-shrink-0 opacity-75"
            />
            <span className="w-72">{getText(LANG_KEY, 'enable.label')}</span>
          </button>
          <DeleteDataSourceButton
            disable={false}
            setShowDeleteDataSourceModal={setShowDeleteDataSourceModal}
          />
        </div>
      }
      className="overflow-hidden bg-white"
      closeOnOutsideClick={true}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      p={0}
      rounded="lg"
      placement="bottom-start"
      shadow="lg"
      showArrow={false}
      trigger="none"
    >
      <button className="rounded-full opacity-75 hover:opacity-100">
        <IconMenu2 onClick={() => setIsOpen(true)} size={16} />
      </button>
    </Popover>
  );
};

export default DataSourceMenu;
