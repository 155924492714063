import { useCallback, useState } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import { CMD, KEY_BACK_SLASH } from '../../../constants/shortcuts';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import { getText } from '../../../utils/lang';
import KeyboardShortcutTooltip from '../view/KeyboardShortcutTooltip';

interface SidebarCollapseButtonProps {
  buildMode?: boolean;
  canToggleViaShortcut?: boolean;
  isOpen: boolean;
  onClick: (nextOpen: boolean) => void;
}

const SidebarCollapseButton = ({
  buildMode = false,
  canToggleViaShortcut = true,
  isOpen,
  onClick,
}: SidebarCollapseButtonProps) => {
  const [toggled, setToggled] = useState<boolean>(false);

  const handleToggle = useCallback(() => {
    setToggled(true);
    onClick(!isOpen);

    setTimeout(() => setToggled(false), 50);
  }, [isOpen, onClick]);

  useOnKeyPress(KEY_BACK_SLASH, handleToggle, {
    ctrlKey: true,
    enabled: canToggleViaShortcut,
  });

  const Component = toggled ? 'div' : KeyboardShortcutTooltip;

  return (
    <Component
      buildMode={buildMode}
      keys={[CMD, KEY_BACK_SLASH]}
      label={getText('leftSidebar.pages.toggle')}
      offset={[0, 8]}
      placement="right"
    >
      <button
        className={classNames(
          'right-0 z-50 flex h-8 w-3.5 items-center justify-center shadow-md sm:hidden',
          {
            'absolute top-2 bg-slate-600 text-slate-200 opacity-75 hover:opacity-100':
              buildMode,
            'absolute top-6 bg-white text-gray-500 hover:text-gray-800 dark:bg-gray-600 dark:text-gray-100':
              !buildMode,
            'rounded-bl-lg rounded-tl-lg bg-opacity-75': isOpen,
            '-mr-3.5 rounded-br-lg rounded-tr-lg': !isOpen,
          },
        )}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleToggle();
        }}
      >
        {isOpen ? (
          <IconChevronLeft size={15} />
        ) : (
          <IconChevronRight size={15} />
        )}
      </button>
    </Component>
  );
};

export default SidebarCollapseButton;
