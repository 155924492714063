import {
  ROADMAP,
  SATELLITE,
  TERRAIN,
} from '@noloco/components/src/constants/mapTypes';
import { LG } from '@noloco/components/src/constants/tShirtSizes';
import {
  DANGER,
  INFO,
  PRIMARY,
  WARNING,
} from '@noloco/components/src/constants/variants';
import {
  CUSTOMER_PORTAL,
  INTERNAL_TOOL,
} from '@noloco/core/src/constants/accountPlanProducts';
import {
  BUSINESS,
  BUSINESS_V2,
  ENTERPRISE,
  EXPERT,
  FREE,
  PRO,
  PROFESSIONAL,
  PRO_V2,
  SCALE,
  STARTER,
  STARTER_V2,
  TEAM,
} from '@noloco/core/src/constants/accountPlans';
import { CONFETTI } from '@noloco/core/src/constants/actionNotification';
import {
  ADD_COMMENT,
  API_REQUEST,
  COPY,
  CREATE,
  DELETE,
  IFRAME,
  NAVIGATE,
  ON_DEMAND,
  SCAN_BARCODE,
  UPDATE,
  VIEW,
} from '@noloco/core/src/constants/actionTypes';
import * as aggregationTypes from '@noloco/core/src/constants/aggregationTypes';
import { MAX, MIN } from '@noloco/core/src/constants/aggregationTypes';
import {
  AROUND,
  BETWEEN,
  CENTER,
  END,
  START,
} from '@noloco/core/src/constants/align';
import {
  CURSOR,
  NONE as NO_AUTH,
  OAUTH2,
  OFFSET,
  PATH,
} from '@noloco/core/src/constants/apis';
import {
  LOGGED_IN,
  NOT_LOGGED_IN,
} from '@noloco/core/src/constants/authVisibilityRules';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  AVERAGE,
  COUNT,
  MAXIMUM,
  MINIMUM,
  SUM,
} from '@noloco/core/src/constants/chartAggregations';
import {
  AREA,
  BAR,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '@noloco/core/src/constants/chartTypes';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  COLUMNS,
  CONVERSATION,
  GANTT,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '@noloco/core/src/constants/collectionLayouts';
import {
  AIRTABLE,
  API,
  CSV,
  FIREBASE,
  GOOGLE_SHEETS,
  HUBSPOT,
  MYSQL,
  POSTGRES,
  SALESFORCE,
  SMART_SUITE,
  XANO,
} from '@noloco/core/src/constants/dataSources';
import * as dataTypes from '@noloco/core/src/constants/dataTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  FORMULA,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import * as dataWrapperTypes from '@noloco/core/src/constants/dataWrapperTypes';
import * as dateFormatOptions from '@noloco/core/src/constants/dateFormatOptions';
import {
  ALL as ALL_DEVICES,
  DESKTOP,
  MOBILE,
} from '@noloco/core/src/constants/deviceRuleVisibilityRules';
import * as durationFormatOptions from '@noloco/core/src/constants/durationFormatOptions';
import * as elements from '@noloco/core/src/constants/elements';
import { QUERY } from '@noloco/core/src/constants/endpointTypes';
import {
  ACCOUNT_MANAGER,
  ACTIVE_INTERNAL_USERS,
  ACTIVE_USERS,
  ACTIVE_WORKFLOWS,
  API_ACCESS,
  APP_VERSIONS,
  AUTOMATIC_SOURCE_SYNCING,
  BUILDERS,
  BULK_ACTIONS,
  BULK_EDIT,
  COMMENT_NOTES,
  CONDITIONAL_FIELD_VISIBILITY,
  CONDITIONAL_USER_LIST_ROLES,
  CUSTOM_CODE,
  CUSTOM_DOMAIN,
  CUSTOM_LOGIN,
  CUSTOM_VISIBILITY_RULES,
  DATA_CONSULTATION,
  DATA_SOURCES,
  DISABLE_PASSWORD_LOGIN,
  EXTERNAL_USERS,
  FIELD_LEVEL_PERMISSIONS,
  FIELD_VALIDATION_RULES,
  FILE_STORAGE,
  LIVE_APPS,
  LIVE_CHAT,
  PREMIUM_LAYOUTS,
  PRIORITY_SOURCE_SYNCING,
  PRIVATE_SLACK_CHANNEL,
  PYTHON_SDK,
  SINGLE_SIGN_ON,
  SMTP,
  TEAM_MEMBERS,
  TWO_FACTOR_AUTHENTICATION,
  USER_ROLES,
  WHITE_LABEL,
  WORKFLOW_RUNS,
} from '@noloco/core/src/constants/features';
import {
  CONDENSED_RELATIONSHIP,
  JSON_FORMAT,
  QR_CODE,
} from '@noloco/core/src/constants/fieldDisplayTypes';
import {
  ADDRESS,
  COORDINATES,
  CURRENCY,
  DATE as DATE_FORMAT,
  DATE_RANGE,
  DATE_TIME,
  DUE_DATE,
  EMAIL as EMAIL_FORMAT,
  FULL_NAME,
  HOURS_MINUTES,
  IP_ADDRESS as IP_ADDRESS_FORMAT,
  MINUTES_SECONDS,
  MULTILINE_TEXT,
  PERCENTAGE,
  PHONE_NUMBER,
  RATING,
  SINGLE_LINE_TEXT,
  SLIDER,
  TIME,
  UNFORMATTED_NUMBER,
  URL as URL_FORMAT,
} from '@noloco/core/src/constants/fieldFormats';
import {
  CIRCLE,
  FILE_NAME,
  INLINE,
  THUMBNAIL,
} from '@noloco/core/src/constants/fileDisplay';
import {
  DOWNLOAD,
  LINK,
  PLAIN,
  PREVIEW_MODAL,
} from '@noloco/core/src/constants/fileFieldElementTypes';
import {
  AUDIO,
  DOCUMENT,
  IMAGE,
  TEXT as TEXT_FILE_TYPE,
  VIDEO,
} from '@noloco/core/src/constants/fileTypes';
import { DYNAMIC, FIELD, UPLOAD } from '@noloco/core/src/constants/imageTypes';
import {
  AUTOCOMPLETE_ADDRESS,
  CHECKBOX,
  COLORED_OPTIONS,
  DATE_AND_TIME,
  LIST,
  MARKDOWN,
  RADIO,
  SIGNATURE,
} from '@noloco/core/src/constants/inputTypes';
import {
  CLIENT,
  INTERNAL,
} from '@noloco/core/src/constants/internalUsersVisibilityRules';
import { EMAIL, PAGE, PHONE, URL } from '@noloco/core/src/constants/linkTypes';
import {
  WITHOUT_PLANS,
  WITH_PLANS,
} from '@noloco/core/src/constants/membershipVisibilityRules';
import {
  GPT_4_MODEL,
  GPT_4_O_MINI_MODEL,
  GPT_4_O_MODEL,
} from '@noloco/core/src/constants/openAi';
import {
  AFTER,
  AFTER_OR_EQUAL,
  BEFORE,
  BEFORE_OR_EQUAL,
  CONTAINS,
  DOES_NOT_CONTAIN,
  EMAIL as EMAIL_OPERATOR,
  EMPTY,
  EQUAL,
  EVERY,
  FALSE,
  GREATER,
  GREATER_OR_EQUAL,
  IN,
  IP_ADDRESS as IP_ADDRESS_OPERATOR,
  LESS,
  LESS_OR_EQUAL,
  NONE,
  NOT_EMPTY,
  NOT_EQUAL,
  NOT_IN,
  REGEX,
  SOME,
  TRUE,
  URL as URL_OPERATOR,
} from '@noloco/core/src/constants/operators';
import { ASC, DESC } from '@noloco/core/src/constants/orderByDirections';
import * as relationships from '@noloco/core/src/constants/relationships';
import {
  WITHOUT_ROLES,
  WITH_ROLES,
} from '@noloco/core/src/constants/roleVisibilityRules';
import { MD, SM, XL } from '@noloco/core/src/constants/screens';
import {
  AUTO,
  DAY,
  MONTH,
  QUARTER,
  WEEK,
  YEAR,
} from '@noloco/core/src/constants/timePeriods';
import {
  CREATE_COMMENT,
  CREATE_NOTE,
  CREATE_RECORD,
  DEACTIVATE_USER,
  DELETE_RECORD,
  DOCS_AUTOMATOR_CREATE,
  FILTER,
  FOR_EACH,
  OPEN_AI_CHAT_COMPLETION,
  OPEN_AI_CORRECT_GRAMMAR,
  OPEN_AI_KEYWORD_EXTRACTION,
  OPEN_AI_SENTIMENT_ANALYSIS,
  OPEN_AI_SUMMARIZATION,
  OPEN_AI_TEXT_COMPLETION,
  SEND_EMAIL,
  SEND_INVITATION_EMAIL,
  SEND_NOTIFICATION,
  SLACK_CHANNEL_NOTIFICATION,
  SLACK_USER_NOTIFICATION,
  UPDATE_RECORD,
  WEBHOOK,
} from '@noloco/core/src/constants/workflowActionTypes';
import coreLang from '@noloco/core/src/lang/en';
import { ScanActionType } from '@noloco/core/src/models/Element';
import { GILL } from '../constants/onboardingManagers';

const enLang = {
  ...coreLang,
  dashboard: {
    nav: {
      dashboard: 'Dashboard',
      plans: 'Plans',
      guides: 'Guides',
      community: 'Community',
      learn: {
        link: 'Learn',
        tooltip: 'Coming soon',
      },
      billing: 'Billing',
      refer: 'Refer a friend',
      signOut: 'Sign out',
      profile: 'Profile',
    },
  },
  features: {
    explore: 'Compare our plans and view your usage',
    overage: {
      confirm: 'Accept',
      text: {
        paragraph1: 'You have exhausted your usage allowance for {feature}.',
        paragraph2: `If your changes only involve existing {feature} in your plan you will not incur any new charges. However, any additional {feature} you add will be appended to your regular bill at a rate of \${cost} per {increment} {feature}.`,
        paragraph3: 'To accept this and continue please confirm below.',
      },
      title: 'You may be charged for additional {feature}.',
    },
    tooltip: {
      boolean: 'To use {feature} you can upgrade to the {lowestPlan} plan.',
      paidOnly: {
        trial: '{feature} is unavailable during your free trial.',
        free: 'To use {feature} you can upgrade to the {lowestPlan} plan.',
      },
      trial: {
        boolean:
          'During your trial you can use {feature}, however after it ends you will need to be on the {lowestPlan} plan to keep using it.',
        usage:
          'During your trial you can have {trialLimit} {feature}, however after it ends limits will apply. Based on your current usage you will need to be on the {lowestPlan} plan if you need more.',
      },
      usage:
        'Unfortunately you have met or exceeded your limit for {feature} on your plan. Your current usage is {usage}. To increase your limit you can upgrade to the {lowestPlan} plan.',
      expert:
        'Unfortunately, this is not available on the expert plan. Please ask Noloco to transfer ownership of this app to your client and start a free trial or subscription',
    },
    [ACCOUNT_MANAGER]: 'Account Manager',
    [ACTIVE_USERS]: 'Seats',
    [ACTIVE_INTERNAL_USERS]: 'Active team seats',
    [ACTIVE_WORKFLOWS]: 'Active Workflows',
    [API_ACCESS]: 'API Access',
    [APP_VERSIONS]: 'Version History',
    [AUTOMATIC_SOURCE_SYNCING]: 'Automatic Data Syncing',
    [BUILDERS]: 'Builders',
    [BULK_ACTIONS]: 'Bulk Actions',
    [BULK_EDIT]: 'Bulk Edit',
    [COMMENT_NOTES]: 'Notes',
    [CONDITIONAL_FIELD_VISIBILITY]: 'Conditional Field Visibility',
    [CONDITIONAL_USER_LIST_ROLES]: 'Conditional User List Roles',
    [CUSTOM_CODE]: 'Custom Code',
    [CUSTOM_DOMAIN]: 'Custom Domain',
    [CUSTOM_LOGIN]: 'Custom Login',
    [CUSTOM_VISIBILITY_RULES]: 'Custom Visibility Rules',
    [DATA_CONSULTATION]: 'Data Consultation',
    [DATA_SOURCES]: 'Advanced data sources',
    [DISABLE_PASSWORD_LOGIN]: 'Disable Password Login',
    [EXTERNAL_USERS]: 'The External Users Addon',
    [FIELD_LEVEL_PERMISSIONS]: 'Field-Level Permissions',
    [FIELD_VALIDATION_RULES]: 'Field Validation Rules',
    [FILE_STORAGE]: 'File Storage',
    [LIVE_CHAT]: 'Live Chat',
    [PRIORITY_SOURCE_SYNCING]: 'Priority Data Syncing',
    [PRIVATE_SLACK_CHANNEL]: 'Private Slack Channel',
    [LIVE_APPS]: 'Live Apps',
    [PREMIUM_LAYOUTS]: 'Premium Layouts',
    [PYTHON_SDK]: 'Python SDK',
    [SINGLE_SIGN_ON]: 'SSO',
    [SMTP]: 'SMTP',
    [TEAM_MEMBERS]: 'Team Members',
    [TWO_FACTOR_AUTHENTICATION]: '2FA',
    [USER_ROLES]: 'User Roles',
    [WHITE_LABEL]: 'Whitelabel',
    [WORKFLOW_RUNS]: 'Workflow Runs',
    usage: {
      title: 'Usage',
      description: 'Overview of your usage for the current billing period',
      tooltip: {
        text: 'Confused about how billing works at Noloco?',
        cta: 'View our Pricing FAQs',
      },
      activeUsers: {
        title: 'Active Users',
        usage: '{current} of {limit}',
      },
      internalActiveUsers: {
        title: 'Active Team Users',
        usage: '{current} of {limit}',
      },
      externalActiveUsers: {
        title: 'Active Client Users',
        usage: '{current} of {limit}',
      },
      seats: {
        title: 'Seats',
        usage: '{current} of {limit}',
      },
      activeWorkflows: {
        title: 'Active Workflows',
        usage: '{current} of {limit}',
      },
      [BUILDERS]: {
        title: 'Builders',
        usage: '{current} of {limit}',
      },
      records: {
        title: 'Total records',
        usage: '{current} of {limit}',
      },
      syncedRecords: {
        title: 'Synced records',
        usage: '{current} of {limit}',
      },
      externalUsers: {
        title: 'External users',
      },
      filesGb: {
        title: 'File Storage',
        units: 'GB',
        usage: '{current}GB of {limit}GB',
      },
      projects: {
        title: 'Live apps',
        usage: '{current} of {limit}',
      },
      teamMembers: {
        title: 'Team Members',
        usage: '{current} of {limit}',
      },
      userRoles: {
        title: 'User Roles',
        usage: '{current} of {limit}',
      },
      workflowRuns: {
        title: 'Workflow Runs',
        usage: '{current} of {limit}',
      },
    },
  },
  workspaces: {
    label: 'Workspaces',
    new: {
      tooltip: {
        disabled: 'You already have a workspace on a trial plan',
        enabled: 'Setup a new trial workspace',
      },
      title: 'Add a Workspace',
      description:
        'A workspace lets you separate apps, plans and collaborators. You can have as many workspaces as you need',
      name: {
        label: 'Choose a name for your new workspace',
        placeholder: 'Company Workspace',
      },
      cancel: 'Cancel',
      confirm: 'Add',
    },
    settings: {
      upgrade: 'Upgrade',
      collaborators: {
        title: 'Workspace Collaborators',
        invite: 'Invite',
      },
      update: {
        error: 'Oops,there was a problem updating your workspace name',
      },
      invite: {
        title: 'Invite collaborators to {workspace}',
        subtitle:
          'Workspace collaborators have access to all apps within the workspace.',
        email: {
          label: 'Invite by email',
          placeholder: 'teammate@example.com',
        },
        invite: 'Invite',
        confirm: 'Done',
        errors: {
          invalidEmail: '{email} is not a valid email address.',
          generic:
            'Something went wrong inviting {email} to this team. Please try again.',
        },
        success: 'Success! {email} has been invited to your workspace',
      },
    },
    select: {
      label: 'Workspace',
      placeholder: 'Select a workspace',
      expired: "This workspace's trial has expired",
    },
  },
  projectList: {
    title: 'My Apps',
    new: 'New App',
    options: {
      open: 'Open in studio',
      settings: 'App settings',
      preview: 'Preview',
      publishedLink: 'View published',
      clone: 'Clone',
      transfer: 'Transfer',
      delete: {
        buttonText: 'Delete',
        tooltip: 'Only the creator of an app can delete it',
        cancel: 'Cancel',
        confirm: 'Delete App',
        confirmText:
          'To confirm please type **{confirmText}** in the box below',
        title: 'Are you sure?',
        warningText:
          'App deletion is permanent and **cannot** be undone. Any connected data sources will not be affected, but will be removed',
      },
    },
    emptyState: {
      title: "You haven't added any apps yet",
      subtitle: 'Apps in your workspaces will appear here.',
      button: 'Build an app',
      trialEnded: 'To build a new app, you will need to upgrade to a paid plan',
      viewPlans: 'View our plans',
    },
    workspaceEmptyState: {
      title: 'There are no apps in this workspace',
      subtitle: 'Apps in this workspace will appear here.',
      button: 'Build an app',
    },
  },
  templateList: {
    title: 'Templates',
    subtitle: 'Get started with one of our ready-made templates',
    clone: {
      loading: 'Cloning {templateName}....',
      steps: {
        NAME: {
          title: 'What do you want to call your app?',
          next: 'Next',
        },
        DATA_SOURCE: {
          title: "Let's connect your data source",
          next: 'Create app',
        },
      },
    },
  },
  transferProject: {
    title: 'Transfer app to a different workspace',
    confirm: 'Transfer app',
    cancel: 'Cancel',
    success: 'App transferred successfully',
    loading: 'Transferring app...',
    chooseWorkspace: 'Choose a workspace to transfer this app to',
    chooseWorkspacePlaceHolder: 'Select a workspace',
    expertPlan: {
      chooseOwner: 'Choose the new owner of this app (optional)',
      chooseOwnerPlaceholder: 'Select a user',
    },
  },
  newProject: {
    noloco: 'Noloco',
    defaultTitle: "Let's create your app!",
    title: "Let's create your first app!",
    subtitle:
      'Start from scratch or get a head start with one of our templates',
    filter: 'Filter Templates',
    step: 'Step {step}',
    AIRTABLE: 'Airtable',
    INTERNAL: 'Noloco Collection',
    TEMPLATES:
      'Start from scratch or get a head start with one of our templates',
    TEMPLATE_SELECTED: "Let's configure your template",
    DETAILS: 'What do you want to call your app?',
    DESIGN: 'Customize how it looks',
    AUTH_DESIGN: 'Customize your login page',
    DATA_SOURCE: 'Connect your data to Noloco',
    DATA_SOURCE_SUBTITLE:
      'Noloco is powered by data in tables. Choose a data source to create an app on top of your data',
    CREATE: 'Creating, building and deploying your app',
    COLLECTION: 'Build your Noloco app using AI',
    COLLECTION_SUBTITLE:
      'Noloco apps are powered by collections, which are like tables that can store different types of information, such as customers, orders, or products. The information will be stored in dynamic tables organized into rows and columns.',
    CSV: 'Import your existing data to Noloco',
    collectionDefaults: {
      collectionName: 'Project',
      fieldName: 'Field Name',
      rowItem: {
        '1': 'Acme Corp Project',
        '2': 'Metro Project',
        '3': 'The Lorax Project',
      },
      rowPlaceholder: 'Row item {number}',
    },
    collectionQuestion: {
      collectionName: 'Store information about',
      collectionNameHelp: 'For example: "Project" or "Task"',
      addField: 'Add some fields to your {collection} collection',
      addFieldHelp:
        'Choose any example fields, or add a custom field such as "Project Name" or "Due Date"',
      fieldType: "What is the field's type?",
      customField: {
        button: 'Custom',
        label: 'What is your custom field called?',
        help: 'Name your custom field, choose a suggested name or pick your own. You can add more fields later',
      },
      addData: 'Add some rows to your new {collection} collection',
      addDataSubtitle:
        'For example input the first three rows for {field}. You can add more later.',
      rowItem: 'Row Item {number}',
    },
    collectionTooltips: {
      collection:
        'A collection stores a list of items of the same type, such as customers, orders, or products.',
      field:
        'Information related to each record in your collection is stored in a field',
      type: 'The type controls what type of data you can store in the field and what sort of input box you and your users will see when they interact with the field.',
      data: 'Adding data to the first three fields in your collection will create your first three records. You can add more fields and data later.',
    },
    connect: {
      businessPlan: 'Business',
      recommended: 'Recommended',
      [INTERNAL]: 'Use AI to generate the tables for your app',
      [CSV]: 'Import a file as a Noloco table',
      [AIRTABLE]: 'Sync your tables and data from your Airtable Base',
      [POSTGRES]: 'Sync your tables and data from your Postgres database',
      [GOOGLE_SHEETS]: 'Sync your tables and data from your Google Sheet',
      [MYSQL]: 'Sync your tables and data from your MySQL database',
      [SALESFORCE]: 'Salesforce',
      [FIREBASE]: 'Firebase',
      [XANO]: 'Sync your tables and data from your Xano database',
      [SMART_SUITE]: 'Sync your tables and data from your SmartSuite solution',
      other: 'Looking for something else?',
    },
    recommendations: {
      beta: 'Beta',
      building: 'Applying the finishing touches...',
      creating: {
        0: "Generating sample data... don't worry, we're not making it up as we go along.",
        1: 'Building your app... just like building a sandcastle, but with more code and less beach.',
        2: "Don't blink! Data is loading at the speed of light... or something like that.",
        3: "Your app is being scaffolded as we speak... don't worry, we're wearing hard hats.",
        4: "Have you heard the one about the data that took forever to load? Yeah, it's this one.",
        5: "Creating an app from scratch is like baking a cake without a recipe... that's why we're doing the heavy lifting for you.",
        6: 'Data loading in progress... please enjoy this soothing elevator music while you wait.',
        7: 'Your app is being created with the utmost care and precision... and a little bit of magic too.',
        8: "Loading data... just kidding, we're actually watching cat videos. But the data will be here soon!",
        9: "We're in the process of bringing your app to life... it's like Frankenstein's monster, but less scary and more useful.",
        10: "Hold on tight, we're preparing to blast off with your data.",
        11: "Please wait while we build your app... it's like Legos for grown-ups!",
        12: "While we wait for the data to load, let's all take a moment to appreciate the wonders of gravity.",
        13: "The suspense is killing us too... but don't worry, your app is coming to life as we speak.",
      },
      cta: 'Build my app',
      tryAgain: 'Try something different',
      dataTypes: {
        fields: {
          add: 'Add Field',
          editor: {
            confirmText: 'Save Field',
            name: {
              help: 'Give your field a useful name that describes the data it stores.',
              label: 'Name',
              placeholder: 'My Field',
            },
            title: {
              add: 'Add field to {dataType}',
              edit: 'Edit field on {dataType}',
            },
            type: {
              help: 'Select the type of data that will be stored in this field.',
              label: 'Type',
            },
          },
        },
        title:
          'We think these {count} tables might be a good starting point for you 🎉',
        visualize: {
          off: 'Editor',
          on: 'Visualize',
        },
      },
      error: {
        cta: 'Setup my data',
        title:
          'Looks like our assistant is having some trouble interpreting that description. Not to worry, you can create your data manually instead!',
      },
      loading: {
        0: "We're building the app of your dreams. Just a moment longer!",
        1: 'Sit back, relax, and let us do the heavy lifting for you!',
        2: "Our AI is working its magic behind the scenes. We'll have your tables in a jiffy!",
        3: 'Good things come to those who wait. Your perfect tables are on the way!',
        4: "Computers never sleep, so we're using them to do the work for you.",
        5: "Analyzing your description... we'll do the heavy lifting for you!",
        6: 'The machines are busy crunching numbers... try not to distract them!',
        7: "Fun fact: the average table contains around 5 columns. We'll help you build the perfect tables for your needs!",
        8: "Did you know that some companies have databases with millions of tables? We're making it easy to build just the ones you need!",
        9: "Reticulating splines... Just kidding, we're just loading your tables!",
        10: 'Please wait patiently, our robots are working hard behind the scenes.',
        11: 'Please enjoy the soothing hum of our machines, while we prepare your app.',
        12: 'Your tables are coming soon, we promise! In the meantime, please enjoy this loading message.',
        13: 'Please be patient, while we crunch the numbers and bring your description to life.',
      },
      prompt: {
        moreCharacters: {
          1: '{context} more character',
          _: '{context} more characters',
        },
        placeholder:
          'An inventory management system that allows us to track inventory levels, monitor stock levels and manage product orders. It should provide real-time data on stock levels, track product expiration dates and allow us to reorder items when levels get low.',
        subtitle:
          'Our assistant can magically generate the tables you need for your app! Be as detailed as possible, describing what you expect to be able to do in the app, and who will be using it. We recommend using as many nouns as possible.',
        tooltip: {
          beta: 'This feature is in beta. If you would like to opt-out click on the link at the bottom of the page.',
          feedback:
            'Have feedback? We would love to hear about it in our Slack community!',
        },
        title: 'Describe your app to our assistant',
        error: 'Sorry, there was a problem with your description',
      },
      submit: 'Next',
      skip: 'Skip this step',
    },
    CUSTOMISATION: "Let's customise your first view",
    customisationHeading: 'View your data in a new way...',
    selectACollection: 'Choose a collection to setup',
    collectionLoading: 'Loading your data...',
    selectAView: 'Choose a view to present your collection',
    collectionDemo: 'This is how you and your team will see it',
    collectionNote:
      "You'll be able to add more tables and fields once you're in your app.",
    customizationnote:
      "You'll be able to customize more views once you're in your app",
    noDataAdded:
      "You've not added any data to your collection. Please go back and add some data to see how it'll look in your app.",
    toApp: 'Bring me to my app',
    new: 'Create new app',
    blank: 'Click to create a new app from scratch or by importing your data',
    confirmClose:
      "You haven't yet completed the onboarding. Are you sure you want to exit?",
    exit: 'Your app has already been created. Are you sure you want to exit?',
    appAlreadyCreated:
      "Your app has already been created, but you haven't yet completed the onboarding. Are you sure you want to exit?",
    templatesSearch: 'Search templates',
    loadingTemplates: 'Loading templates',
    empty: 'No templates found',
    preview: {
      label: 'Preview',
      [AIRTABLE]: 'Preview with Airtable',
      [INTERNAL]: 'Preview with Noloco tables',
      [GOOGLE_SHEETS]: 'Preview with Google Sheet',
    },
    useTemplate: 'Use template',
    previewDataSource: {
      [AIRTABLE]: 'View Airtable base',
      [GOOGLE_SHEETS]: 'View Google Sheet',
    },
    templateSourceChoice: {
      title: 'This template can be powered by different sources',
      subtitle: 'Which one would you like to use?',
      [INTERNAL]: 'Your tables live in Noloco',
      [AIRTABLE]: 'Your data is synced to Noloco',
    },
    moreInfo: 'More info',
    onboardingModal: {
      title: 'Experience all of Noloco with a 30-day free trial',
      subTitle:
        "We've upgraded you to a 30-day free trial of the business plan. Try the full range of features on Noloco before deciding which plan is right for you..",
      getStarted: 'Get started',
      onboardingChecklist:
        'Make sure you check out the onboarding checklist to get the most out of Noloco during this time.',
    },
    name: {
      label: 'Choose a unique app name',
      help: 'This is how you will access your app before you set up your custom domain. It has to be unique and can contain only letters, numbers or dashes.',
      invalid: {
        description: "Sorry you can't use this name",
        length: 'It must have more than 3 characters',
        start: "It can't start with a number",
        characters: 'It can only contain letters, numbers and dashes',
        unique: 'This name is already taken',
      },
      adjectives: {
        0: 'awesome',
        1: 'breathtaking',
        2: 'amazing',
        3: 'stunning',
        4: 'astounding',
        5: 'astonishing',
        6: 'awe-inspiring',
        7: 'stupendous',
        8: 'staggering',
        9: 'extraordinary',
        10: 'incredible',
        11: 'unbelievable',
        12: 'magnificent',
        13: 'wonderful',
        14: 'spectacular',
        15: 'remarkable',
        16: 'phenomenal',
        17: 'prodigious',
        18: 'miraculous',
        19: 'sublime',
        20: 'impressive',
        21: 'mind-boggling',
        22: 'mind-blowing',
        23: 'wondrous',
      },
    },
    creation: {
      project: 'Building your app...',
      sidebarLogo: 'Uploading your main logo...',
      authLogo: 'Uploading your other logo...',
      details: 'Updating the details...',
      collection: "Creating the '{type}' collection...",
      field: "Adding the '{field}' field...",
      finish: 'Getting things ready...',
      button: 'Take me there',
    },
    designStep: {
      primaryColor: {
        label: 'Choose a primary color theme',
        help: 'This will be used to style your sidebar and primary buttons. Choose from one of our themes or set your own custom color',
      },
      logo: {
        label: 'Upload a logo for your sidebar',
        help: 'Try something that works well on a darker background',
      },
      authLogo: {
        label: 'Upload a logo for your login page',
        help: 'You can skip this if you want to use the same logo as before. Try something that works well on a light background',
      },
    },
    dataStep: {
      disabled: {
        title: "Sorry! This source isn't available yet, but it's coming soon.",
        subtitle:
          'In the meantime, please choose one of the available sources.',
      },
    },
    errors: {
      generic: 'Something went wrong, please try again',
      maybeNotUnique: 'Something went wrong - are you sure the name is unique?',
      projectNotFound: 'No app found.',
    },
    next: 'Next',
    previous: 'Previous',
    back: 'Back',
    skip: 'Skip',
    finish: 'Take me there',
    label: 'New app name',
    cancel: 'Cancel',
    clone: {
      placeholder: 'app',
      title: 'Pick a name for the clone of **{projectName}**',
      dataSourceEnabled: 'Replace connected data source with a clone.',
      confirm: 'Create my new app',
      dataSource: {
        title: 'Configure your data source',
        label: 'How do you want to clone _{dataSource}_?',
        false: {
          label: 'Keep existing',
          description: 'Use the existing data source',
        },
        true: {
          label: 'Replace',
          description: 'Replace the existing data source with a clone',
        },
        skipMissingFields: 'Skip any missing fields',
      },
      whatsCloned: {
        true: 'Your new app will inherit the theme, views, tables, fields, roles, permissions & workflows from {projectName}.\nYour new data source will be connected.',
        false:
          'Your new app will inherit the theme, views, data sources, tables, fields, roles, permissions & workflows from {projectName}.',
      },
    },
  },
  profile: {
    title: 'Profile',
    fullName: 'Full name',
    email: 'Email',
    profilePicture: 'Profile picture',
    updateProfilePicture: 'Update profile picture',
  },
  billing: {
    customerPortal: {
      open: 'Manage billing',
      error: 'Something went wrong, please try again',
    },
    trial: {
      free: {
        title: 'You are currently trialing all of our BUSINESS features',
        description:
          "Your trial of the BUSINESS plan has {remainingDays} days left and will expire on {endDate}. During this time you can use all of Noloco's features. At the end of your trial you will lose access to any PRO or BUSINESS features unless you upgrade to one of those plans. However, you can continue to use your FREE account for as long as you wish.",
      },
      compare: 'Compare plans',
      manageUsers: 'Manage users',
      canceled: {
        title: 'Your subscription has been canceled',
        description:
          "You don't currently have a Noloco subscription. Choose any of our plans to get started again",
      },
      expired: {
        title: 'Your trial has ended',
        summary:
          'Your Noloco trial ended on {endDate}. Your team will no longer be able to benefit from the great app you have built.',
        continue:
          "But it's not too late to take the next step. Upgrade to a paid plan to keep using your Noloco app.",
        contactAdmin:
          'Contact your admin to upgrade your account to a paid plan',
        description:
          "Your Noloco trial ended on {endDate}. But it's not too late to take the next step. Upgrade to a paid plan to keep using your Noloco apps.",
      },
      features: {
        branding: {
          title: 'Whitelabel & Domain',
          description:
            'Remove Noloco branding and access your app via a custom domain',
          shortUsage: {
            1: '1 custom domain',
            _: '{context} custom domains',
          },
          usage: {
            1: 'You are using a custom domain',
            _: 'You have {context} custom domains setup',
          },
        },
        users: {
          title: 'Collaborate',
          description: 'Invite your whole team to your app',
          shortUsage: {
            1: '1 seat',
            _: '{context} seats',
          },
          usage: {
            1: 'You currently have one seat',
            _: 'You currently have {context} seats',
          },
        },
        display: {
          title: 'Premium Layouts',
          description:
            'Access charts, map, calendar, split view and other premium layouts',
          shortUsage: {
            1: '1 premium layout',
            _: '{context} premium layouts',
          },
          usage: {
            1: 'You are using one premium layout',
            _: 'You are using {context} premium layouts',
          },
        },
        syncing: {
          title: 'Automatic syncing',
          description: 'Sync your data sources as your data updates',
          shortUsage: {
            1: '1 synced table',
            _: '{context} synced table',
          },
          usage: {
            1: 'One collection is being automatically synced',
            _: '{context} table are being automatically synced',
          },
        },
        permissions: {
          title: 'Field permissions',
          description: 'Closely control field permissions',
          shortUsage: {
            1: '1 protected collection',
            _: '{context} protected table',
          },
          usage: {
            1: 'You currently use field permissions in one collection',
            _: 'You currently use field permissions in {context} table',
          },
        },
        roles: {
          title: 'Unlimited User Roles',
          description: 'Use custom user roles',
        },
      },
      error: 'Something went wrong',
    },
    invalid: {
      incomplete: {
        title: 'Oh no, your payment failed',
        description:
          "It looks like your payment failed to complete when you subscribed to Noloco. Don't worry, this is often due to insufficient funds, failed authentication, or an expired card.",
      },
      incomplete_expired: {
        title: 'Oh no, you need to retry your payment',
        description:
          "Your payment failed to complete when you subscribed to Noloco. Don't worry, this is often due to insufficient funds, failed authentication, or an expired card. Please go through sign up flow again.",
      },
      unpaid: {
        title: 'Oh no, your last payment failed',
        description:
          "Your last payment was due on {dueDate} but the payment failed. Don't worry, this is often due to insufficient funds, failed authentication, or an expired card.",
      },
      past_due: {
        title: 'Your latest payment is overdue',
        description:
          "Your last payment was due on {dueDate} but unfortunately the payment failed. Don't worry, this is often due to insufficient funds, failed authentication, or an expired card.. We will automatically retry the payment.",
      },
      canceled: {
        title: 'Your subscription has been cancelled',
        description: 'Your Noloco subscription has been cancelled.',
      },
      general: {
        continue: 'To continue using Noloco please update your billing details',
        remind: 'Remind me later',
        cta: 'Manage billing',
      },
    },
    legacyWarning: {
      title: 'You are on a legacy plan!',
      subtitle:
        "As you might know, we have made some changes to our pricing. Don't worry, you can continue to use Noloco as before, and the price you pay will remain unchanged, or you can switch to one of the new plans below.",
      cta: 'Compare plans',
    },
    creditAlert: {
      title: 'You have {credit} in credits to use!',
      upgrade: 'Upgrade today to use your credits',
      nextInvoice: 'Your credits will be used against your next invoice',
    },
    plan: {
      title: 'Plan',
      help: 'Learn more about our plans',
      change: 'Change plan',
      manage: 'Manage plan',
      add: 'Add plan',
      legacy: 'Legacy',
      interval: {
        year: {
          label: 'Annually',
          pay: 'Pay annually',
          billed: 'Billed annually',
          save: 'Save 20%',
        },
        month: {
          label: 'Monthly',
          pay: 'Pay monthly',
          billed: 'Billed monthly',
        },
        // eslint-disable-next-line no-template-curly-in-string
        perMonth: '{amount} per month',
        // eslint-disable-next-line no-template-curly-in-string
        perActiveUserPerMonth: '{amount} per seat / month',
      },
      product: {
        [CUSTOMER_PORTAL]: {
          title: 'Customer Portal',
          subtitle: 'Apps used by your customers or partners',
          lineItem: {
            activation: 'Customer Portal Addon',
            additional: {
              title: 'Additional 100 User Bundles',
              unitLabel: 'Additional 100 User Bundle(s)',
            },
          },
        },
        [INTERNAL_TOOL]: {
          title: 'Internal Tool',
          subtitle: 'Apps used by you and your team',
        },
      },
    },
    details: {
      title: 'Billing details',
      subtitle: 'Payment and billing information for your account',
      paymentMethod: {
        title: 'Payment method',
        change: 'Change payment method',
      },
      info: {
        name: 'Name',
        email: 'Email',
        company: 'Company',
      },
    },
    error: 'Something went wrong, please try again',
    promoCodes: {
      MEOW50: 'MEOW50: 50% discount for the first 2 months',
      PH30: 'PH30: 30% discount for the first 6 months',
      PRODUCTHUNT50: 'PRODUCTHUNT50: 50% discount for the first 3 months',
      default: "Your code '{code}' will be applied, if valid",
    },
    credit: {
      label: 'Account credits',
      tooltip:
        'You have {credits} in credits that will be taken from the amount you owe today. If you have credits remaining after this transaction they will be subtracted from your next invoice.',
      total: '{credits} in credits available',
    },
    perUser: 'per seat',
    perMonth: 'per month',
    activeUsers: {
      1: '{context} seat',
      _: '{context} seats',
    },
    interval: {
      year: '1 year',
      month: '1 month',
    },
    plans: {
      [FREE]: {
        name: 'FREE',
        price: {
          annual: '$0',
          explainer: 'free forever',
        },
        useCase:
          'For individuals and small teams to get started with internal tools',
        features: {
          limits: 'Noloco branding',
          workflows: '500 workflow runs per month',
          team: '2 additional teammates',
          syncing: 'Manual data syncing',
          users: '3 seats',
        },
      },
      [STARTER]: {
        name: 'START',
        price: {
          annual: '$99 / mo',
          monthly: '$99 / mo',
          explainer: 'billed annually or $99 month-to-month',
        },
        useCase: 'For individuals and small teams to get started',
        features: {
          projects: '1 app',
          limits: '10,000 records & Noloco branding',
          workflows: '50 workflow runs per month',
          team: '2 additional teammates',
        },
      },
      [STARTER_V2]: {
        name: 'Starter',
        price: {
          annual: '$39',
          monthly: '$49',
          explainer: 'billed annually or $49 each month',
        },
        useCase: 'For individuals and small teams building apps',
        features: {
          rows: '5,000 rows',
          syncedRows: '2,500 synced rows',
          limits: 'Noloco branding & default User roles',
          workflows: '1,000 workflow runs per month',
          syncing: 'Standard data syncing',
          clientSeats: '10 active client seats',
          additionalClientSeats: '+$0.50 per extra seat',
          teamSeats: '4 active team seats',
          additionalTeamSeats: '+$4 per extra seat',
        },
      },
      [PROFESSIONAL]: {
        name: 'PRO',
        price: {
          annual: '$199 / mo',
          monthly: '$199 / mo',
          explainer: 'billed annually or $199 month-to-month',
        },
        useCase: 'For individuals and small teams to get started',
        features: {
          projects: 'Up to 3 apps',
          limits: 'Unlimited records & custom domain',
          workflows: '50 workflow runs per month',
          team: '5 additional teammates',
        },
      },
      [TEAM]: {
        name: 'TEAM',
        price: {
          annual: '$239 / mo',
          monthly: '$299 / mo',
          explainer: 'billed annually or $299 month-to-month',
        },
        useCase:
          'For small teams to collaborate across their data in real-time',
        features: {
          limits: 'User roles & field-level permissions',
          workflows: '1000 workflow runs per month',
          team: '9 additional teammates',
          syncing: 'Automatic data syncing',
          users: '100 seats',
        },
      },
      [SCALE]: {
        name: 'SCALE',
        price: {
          annual: '$639 / mo',
          monthly: '$799 / mo',
          explainer: 'billed annually or $799 month-to-month',
        },
        useCase:
          'For teams building advanced internal tools with more control over user access',
        features: {
          limits: 'Unlimited roles & data consultation',
          workflows: '5,000 workflow runs per month',
          syncing: 'Priority data syncing',
          users: '350 seats',
        },
      },
      [PRO]: {
        name: 'Pro',
        price: {
          annual: '$15',
          monthly: '$19',
          explainer: 'billed annually or $19 per seat month-to-month',
        },
        useCase:
          'For small teams to collaborate across their data in real-time',
        features: {
          limits: 'Noloco branding & 6 User roles',
          workflows: '3,000 workflow runs per month',
          syncing: 'Automatic data syncing',
        },
      },
      [PRO_V2]: {
        name: 'Pro',
        price: {
          annual: '$119',
          monthly: '$149',
          explainer: 'billed annually or $149 each month',
        },
        useCase:
          'For small teams to collaborate across their data in real-time',
        features: {
          rows: '50,000 rows',
          syncedRows: '25,000 synced rows',
          limits: '6 roles & no Noloco branding',
          workflows: '3,000 workflow runs per month',
          syncing: 'Standard data syncing',
          clientSeats: '50 active client seats',
          additionalClientSeats: '+$1 per extra seat',
          teamSeats: '10 active team seats',
          additionalTeamSeats: '+$6 per extra seat',
          rest: 'Everything in Starter',
        },
      },
      [BUSINESS]: {
        name: 'Business',
        price: {
          annual: '$28',
          monthly: '$35',
          explainer: 'billed annually or $35 per seat month-to-month',
        },
        useCase: 'For teams building advanced apps with access controls',
        features: {
          limits: 'Unlimited roles & data consultation',
          workflows: '10,000 workflow runs per month',
          syncing: 'Priority data syncing',
          rest: 'Everything in Pro',
        },
      },
      [BUSINESS_V2]: {
        name: 'Business',
        price: {
          annual: '$255',
          monthly: '$319',
          explainer: 'billed annually or $319 each month',
        },
        useCase: 'For teams building advanced apps with lots of users or rows',
        features: {
          rows: '150,000 rows',
          syncedRows: '50,000 synced rows',
          limits: 'Unlimited roles & data consultation',
          workflows: '10,000 workflow runs per month',
          syncing: 'Priority data syncing',
          clientSeats: '100 active client seats',
          additionalClientSeats: '+$1 per extra seat',
          teamSeats: '30 active team seats',
          additionalTeamSeats: '+$10 per extra seat',
          rest: 'Everything in Pro',
        },
      },
      [ENTERPRISE]: {
        name: 'Enterprise',
      },
      [EXPERT]: {
        name: 'Expert',
      },
    },
    addPlan: {
      title: 'Add a paid plan',
      card: 'Credit or Debit card',
      pay: 'Pay {amount} now',
    },
    upgrade: {
      why: {
        title: 'Thanks for upgrading your plan!',
        subtitle: 'Can you briefly describe why you upgraded your plan?',
        placeholder: 'Briefly describe why you upgraded your plan...',
      },
      call: {
        title: 'Book in your free consultation',
        subtitle:
          "Make sure you're getting the most from Noloco by booking in a free consultation with our in-house expert {onboardingManager}.",
      },
      submit: 'Submit',
      close: 'Close',
    },
    cancel: {
      button: 'Cancel my plan',
      title: '{name}, before you go...',
      subtitle: {
        why: "We're sorry you're thinking of cancelling your Noloco plan. Would you tell us why?",
        like: 'One more thing, was there anything you did like about Noloco?',
        confirm:
          'Thanks for the feedback, it really helps us to make Noloco better. Are you sure you still want to cancel your subscription? If you continue you will not be able to access any of your apps or data, but you will no longer be charged',
      },
      cancel: "Don't cancel",
      why: {
        placeholder: 'Briefly describe why you are cancelling...',
      },
      liked: {
        placeholder: 'If there is anything you liked about Noloco...',
      },
      continue: 'Continue',
      confirm: 'Yes, cancel my subscription',
      error: 'Sorry, there was an issue cancelling your plan',
    },
    changePlan: {
      title: {
        change: 'Change your plan',
        add: 'Add a plan',
      },
      interval: 'Payment interval',
      apps: 'Your apps',
      domains: "Your team's email domains",
      paymentDetails: 'Payment details',
      promotionCode: {
        apply: 'Apply',
        remove: 'Remove',
        placeholder: 'Promotion code',
        invalid: 'Invalid code',
      },
      change: {
        pay: 'Change my plan',
      },
      activeUserDisclaimer:
        'The price is based on the current number of seats. Changes in the number of seats will be reflected in your next bill.',
      externalUserActivationDisclaimer:
        'This is the flat cost to let external users log into your app and includes your first 100 external users. You will pay it each month your app allows external users to log in.',
      externalUserAdditionalDisclaimer:
        'The price is based on the number of external users active in this month. Each month you will be billed for the number of external users who have logged in to use your app.',
      upgrade: {
        explain:
          'Your billing date will not change but you will be charged the difference between your current plan and your new plan immediately.',
        pay: 'Upgrade my plan',
      },
      downgrade: {
        explain:
          'Are you sure you want to change your plan? You will not be able to access some of the features you currently can.',
        pay: 'Downgrade my plan',
      },
      confirmChange: {
        flat: {
          month:
            // eslint-disable-next-line no-template-curly-in-string
            'From {nextBillingDate} you will be charged ${amount} every month plus any overages incurred.',
          year:
            // eslint-disable-next-line no-template-curly-in-string
            'From {nextBillingDate} you will be charged ${amount} every year, any additional overages incurred throughout the year will be billed monthly.',
        },
        seats: {
          month:
            // eslint-disable-next-line no-template-curly-in-string
            'From {nextBillingDate} you will be charged ${amount} per seat every month plus any overages incurred.',
          year:
            // eslint-disable-next-line no-template-curly-in-string
            'From {nextBillingDate} you will be charged ${amount} per seat every year, any additional seats used throughout the year and overages incurred will be billed monthly.',
        },
      },
    },
  },
  referrals: {
    title: 'Referrals and credits',
    subtitle: 'Get $10 for every person you refer to Noloco',
    explainer:
      'You will receive $10 credit for every person you invite that signs up via the invitation email. You can use this credit to upgrade your account to a Pro or Business plan. By referring someone you do not invite them to the apps in your workspace or share any content with them. However, if you would like to collaborate with someone, you can add them as an admin to your app. You will receive credit for both referrals and collaborators once they sign up and verify their email address.',
    link: {
      copy: 'Copy Link',
      copied: 'Referral link copied to clipboard',
      label: 'Share this link',
    },
    email: {
      label: 'Email an invite',
      placeholder: 'jane@example.com',
      send: 'Send invite',
    },
    invites: {
      accepted: 'Accepted invites',
      pending: 'Pending invites',
      awarded: 'Credit received for invite',
    },
    errors: {
      invalidEmail: '{email} is not a valid email address.',
      generic: 'Something went wrong inviting {email}. Please try again.',
    },
    success: 'You have invited {email} to Noloco.',
    message: "You can add this user to an app from the app's user table",
  },
  propsEditor: {
    name: {
      label: 'Element name',
    },
    text: {
      label: 'Text',
    },
  },
  contentEditor: {
    placeholder: 'Data variable',
    groups: {
      data: 'Data',
      scope: 'Scope',
      pageFields: 'Page fields',
      values: 'Values',
      options: 'Options',
      math: 'Math',
    },
    values: {
      OTHER: {
        label: 'Utility values',
        help: 'Values to help with actions',
        empty: {
          label: 'Empty value',
        },
      },
      [BOOLEAN]: {
        true: 'Yes / True',
        false: 'No / False',
      },
      [FORMULA]: {
        label: 'Formula',
        placeholder: 'New formula',
        unsupported: 'Formulas are not supported here',
      },
      [DATE]: {
        dates: 'Relative Dates',
        help: 'Relative to the current date',
        workflowHelp: 'Relative to when the workflow runs',
        ago: 'Ago',
        from: 'From now',
        custom: 'Custom',
        hour: {
          label: 'Hours',
          from: {
            1: '{context} hour from now',
            _: '{context} hours from now',
          },
          ago: {
            1: '{context} hour ago',
            _: '{context} hours ago',
          },
        },
        day: {
          label: 'Days',
          ago: {
            1: '{context} day ago',
            _: '{context} days ago',
          },
          from: {
            1: '{context} day from now',
            _: '{context} days from now',
          },
        },
        today: {
          current: "Today's date",
          now: 'Now',
          start: 'Start of day',
          end: 'End of day',
        },
        week: {
          current: 'This week',
          start: 'Start of week',
          end: 'End of week',
          label: 'Weeks',
          from: {
            1: '{context} week from now',
            _: '{context} weeks from now',
          },
          ago: {
            1: '{context} week ago',
            _: '{context} weeks ago',
          },
        },
        month: {
          current: 'This month',
          start: 'Start of month',
          end: 'End of month',
          label: 'Months',
          from: {
            1: '{context} month from now',
            _: '{context} months from now',
          },
          ago: {
            1: '{context} month ago',
            _: '{context} months ago',
          },
        },
        quarter: {
          label: 'Quarters',
          from: {
            1: '{context} quarter from now',
            _: '{context} quarters from now',
          },
          ago: {
            1: '{context} quarter ago',
            _: '{context} quarters ago',
          },
        },
        year: {
          current: 'This year',
          start: 'Start of year',
          end: 'End of year',
          label: 'Years',
          from: {
            1: '{context} year from now',
            _: '{context} years from now',
          },
          ago: {
            1: '{context} year ago',
            _: '{context} years ago',
          },
        },
      },
    },
    options: {
      noOptions: 'No options',
      currentUser: 'Current User',
    },
    dateOptions: {
      label: 'Date format',
      [dateFormatOptions.DATE_SHORT]: 'Short date',
      [dateFormatOptions.DATE_MED]: 'Abbreviated date',
      [dateFormatOptions.TIME_SIMPLE]: 'Time',
      [dateFormatOptions.TIME_24_SIMPLE]: 'Time (24hrs)',
      [dateFormatOptions.TIME_WITH_SECONDS]: 'Time with seconds',
      [dateFormatOptions.TIME_24_WITH_SECONDS]: 'Time (24hrs) with seconds',
      [dateFormatOptions.DATETIME_SHORT]: 'Short date and time',
      [dateFormatOptions.DATETIME_MED]: 'Abbreviated date and time',
      [dateFormatOptions.WEEKDAY_SHORT]: 'Abbreviated weekday',
      [dateFormatOptions.WEEKDAY]: 'Weekday',
      [dateFormatOptions.DAY_SHORT]: 'Day of month',
      [dateFormatOptions.DAY]: 'Day of month (padded)',
      [dateFormatOptions.MONTH_SHORT]: 'Abbreviated month',
      [dateFormatOptions.MONTH]: 'Month',
      [dateFormatOptions.YEAR]: 'Year',
      [dateFormatOptions.ISO]: 'ISO',
    },
    durationOptions: {
      label: 'Duration format',
      [durationFormatOptions.HOURS_MINUTES_SECONDS]:
        'Hours minutes and seconds',
      [durationFormatOptions.HOURS_MINUTES]: 'Hours and minutes',
      [durationFormatOptions.HOURS]: 'Hours',
    },
  },
  propsGroups: {
    data: {
      title: 'Collection source',
    },
    vars: {
      title: 'List fields',
      labelField: 'Label',
      valueField: 'Value',
    },
    header: {
      title: 'Header',
    },
    filters: {
      title: 'Collection filters',
    },
    emptyState: {
      title: 'Empty state',
    },
    link: {
      title: 'Row link',
    },
    fields: {
      title: 'Fields',
    },
    submitButton: {
      title: 'Submit button',
    },
    additionalButtons: {
      title: 'Additional Buttons',
    },
    successMessage: {
      title: 'Success message',
    },
    errorMessage: {
      title: 'Error message',
    },
    formOptions: {
      title: 'Form options',
    },
    formFields: {
      title: 'Form field',
    },
    tabs: {
      title: 'Tab text',
    },
  },
  elements: {
    type: 'Type',
    empty: 'Add a section',
    elementType: {
      [FILE]: {
        [PLAIN]: 'Do nothing',
        [LINK]: 'Link to file in new tab',
        [PREVIEW_MODAL]: 'Open file in preview modal',
        [DOWNLOAD]: 'Download',
      },
    },
    displayTypes: {
      [COUNT]: 'Count',
      [CONDENSED_RELATIONSHIP]: 'Condensed',
      [JSON_FORMAT]: 'Formatted JSON',
      [QR_CODE]: 'QR Code',
    },
    [elements.ACTION_BUTTONS]: {
      label: 'Action buttons',
      title: 'Buttons',
      description: 'Add an action button row',
      configure: 'Add some action buttons',
      align: {
        [START]: 'Left',
        [CENTER]: 'Center',
        [END]: 'End',
      },
    },
    [elements.API_FORM]: {
      label: 'API form',
    },
    [elements.BADGE]: {
      label: 'Billing',
    },
    [elements.BREADCRUMBS]: {
      label: 'Breadcrumbs',
      back: 'Back to {pageName}',
    },
    [elements.BILLING]: {
      label: 'Billing',
    },
    [elements.BUTTON]: {
      label: 'Button',
    },
    [elements.CHART]: {
      label: 'Chart',
      title: 'Chart',
      description: 'Add a chart group element',
      chartType: {
        [BAR]: 'Bar',
        [LINE]: 'Line',
        [AREA]: 'Area',
        [PIE]: 'Pie',
        [FUNNEL]: 'Funnel',
        [RADAR]: 'Radar',
        [STATISTIC]: 'Statistic',
        [GAUGE]: 'Gauge',
        [STACKED_BAR]: 'Stacked Bar',
      },
      timePeriod: {
        [AUTO]: 'Auto',
        [DAY]: 'Group by day',
        [WEEK]: 'Group by week',
        [MONTH]: 'Group by month',
        [QUARTER]: 'Group by quarter',
        [YEAR]: 'Group by year',
      },
      aggregation: {
        [SUM]: 'Sum',
        [COUNT]: 'Count',
        [AVERAGE]: 'Average',
        [MIN]: 'Minimum',
        [MINIMUM]: 'Minimum',
        [MAXIMUM]: 'Maximum',
        [MAX]: 'Maximum',
      },
    },
    [elements.COLLECTION]: {
      label: 'Collection',
      title: 'Collection',
      description: 'Add a list related to this record',
      layout: {
        [ROWS]: 'Rows',
        [COLUMNS]: 'Columns',
        [CARDS]: 'Cards',
        [CALENDAR]: 'Calendar',
        [TIMELINE]: 'Timeline',
        [GANTT]: 'Gantt',
        [CHARTS]: 'Charts',
        [TABLE]: 'Table',
        [PIVOT_TABLE]: 'Pivot Table',
        [TABLE_FULL]: 'Grid',
        [BOARD]: 'Board',
        [SINGLE_RECORD]: 'Single Record',
        [SPLIT]: 'Split',
        [CONVERSATION]: 'Conversation',
        [MAP]: 'Map',
      },
      invalid: 'Choose a collection type to setup your view',
      variableProps: {
        hidden: 'Hidden',
      },
      vars: {
        labelField: 'Label',
        valueField: 'Value',
      },
      variables: {
        title: {
          label: 'Title',
          placeholder: 'Title {index}',
        },
        subtitle: {
          label: 'Subtitle',
        },
        image: {
          label: 'Image',
        },
        description: {
          label: 'Description',
        },
        secondaryText: {
          label: 'Secondary text',
        },
        emptyState: {
          title: {
            placeholder: 'No items could be found',
          },
        },
      },
      calendar: {
        views: {
          month: 'Month',
          week: 'Week',
          two_week: '2 Week',
          work_week: 'Work week',
          day: 'Day',
        },
        navigation: {
          next: 'Next {view}',
          previous: 'Previous {view}',
        },
        today: 'Today',
        open: 'Open',
        datePicker: 'Date picker',
        filterPreset: {
          last7Days: 'Last 7 days',
          last14Days: 'Last 14 days',
          last28Days: 'Last 28 days',
          last30Days: 'Last 30 days',
          today: 'Today',
          yesterday: 'Yesterday',
          thisWeek: 'This week',
          thisWeekToDate: 'This week to date',
          lastWeek: 'Last week',
          thisMonth: 'This month',
          thisMonthToDate: 'This month to date',
          lastMonth: 'Last month',
          thisQuarter: 'This quarter',
          thisQuarterToDate: 'This quarter to date',
          lastQuarter: 'Last quarter',
          thisYear: 'This year',
          thisYearToDate: 'This year to date',
          lastYear: 'Last year',
        },
      },
      split: {
        columnSpan: {
          label: 'Sidebar width',
        },
      },
      state: {
        label: 'Row values',
        help: "The current row's record values",
      },
    },
    [elements.DATA_WRAPPER]: {
      label: 'Data Wrapper',
      type: {
        [dataWrapperTypes.INTERNAL]: 'Data Type',
      },
      api: {
        endpoint: {
          label: 'API endpoint',
          placeholder: 'Select an API endpoint',
        },
      },
    },
    [elements.DETAILS]: {
      label: 'Details',
      title: 'Details',
      description: 'Add fields, a title or description',
      view: {
        empty: 'Configure which fields to display',
      },
    },
    [elements.EMBED]: {
      label: 'Embed',
      description: 'Add an embed',
      title: 'Embed',
      code: 'Code',
    },
    [elements.FILE_SHARING]: {
      label: 'File sharing',
    },
    [elements.FOLDER]: {
      label: 'Folder',
    },
    FORM: {
      required: {
        label: 'Required field',
        description:
          'When true, the form will not submit until this field has a value',
      },
    },
    FORMS: {
      api: {
        configure:
          'To get started, choose an API endpoint and specify the form fields from the sidebar',
      },
      sections: {
        add: 'Add section',
        label: 'Label',
        placeholder: 'New Section',
      },
      helpText: {
        formData: {
          label: 'Form values',
          help: 'The draft field values',
        },
        recordData: {
          label: 'Record values',
          help: 'The action values',
        },
        label: 'Help text',
        placeholder: {
          field: 'Help text for {field}',
          generic: 'Help text',
        },
      },
      fieldHelpText: {
        label: 'Help text',
        placeholder: 'Help text',
      },
      defaultValue: {
        label: 'Default value',
        placeholder: 'Default value for {field}',
      },
      width: 'Field width',
      options: {
        label: 'Show {type}',
      },
    },
    FILE_UPLOAD: {
      download: 'Download',
    },
    [elements.FORM_V2]: {
      label: 'Form',
    },
    [elements.GROUP]: {
      label: 'Group',
      column: {
        label: 'Column',
      },
      row: {
        label: 'Row',
      },
    },
    [elements.HIGHLIGHTS]: {
      label: 'Highlights',
      title: 'Highlights',
      description: 'Add a section to highlights fields',
    },
    [elements.ICON]: {
      label: 'Icon',
      placeholder: 'Select an icon',
      filter: 'Search...',
    },
    [elements.COPY]: {
      label: 'Text',
      confirm: 'Copy',
    },
    [elements.IFRAME]: {
      label: 'iframe',
      title: 'iframe',
      description: 'Add an iframe embed',
      empty: 'Configure the iframe type and URL',
      help: 'Choose the field with the iframe URL or input the URL below. Simply paste the iframe code provided by your app',
      options: {
        label: 'Type',
        placeholder: 'Choose a type',
        field: 'Data field',
        url: 'Input a url',
      },
      fields: {
        label: 'Iframe source field',
        placeholder: 'Choose a field',
        title: 'Source field',
      },
      size: {
        label: 'Iframe size',
        width: 'Iframe width',
      },
      url: {
        label: 'Iframe url/script',
        placeholder: 'e.g. https://www.youtube.com/embed/ye90Tje7VS0',
        title: 'URL/Script',
      },
    },
    [elements.IMAGE]: {
      label: 'Image',
      title: 'Image',
      description: 'Add an image element',
      help: 'Choose the field with the image link or input the URL below',
      type: {
        label: 'Image source',
        [DYNAMIC]: {
          label: 'Image URL',
          title: 'URL',
        },
        [UPLOAD]: {
          label: 'Upload an image',
          title: 'Upload',
        },
        [FIELD]: {
          label: 'Choose a field',
          title: 'Field',
        },
        NONE: {
          title: 'None',
        },
      },
      source: {
        label: 'Image URL',
        placeholder: 'example.com/image.jpg',
      },
      empty: 'No displayable image found',
    },
    [elements.LABEL]: {
      label: 'Label',
    },
    [elements.LINK]: {
      label: 'Link {index}',
      title: 'Title',
      description: 'Description',
      icon: 'Icon',
      type: {
        label: 'Link type',
      },
      visibility: 'Visibility',
      newTab: {
        label: 'Open in new tab?',
        options: {
          true: 'Yes',
          false: 'No',
        },
      },
      clear: 'Clear',
      types: {
        [URL]: {
          label: 'URL',
          url: {
            label: 'URL',
            placeholder: 'https://example.com/hello',
          },
        },
        [PAGE]: {
          label: 'Page',
          page: {
            label: 'Page',
            placeholder: 'Choose a page',
          },
          tab: {
            label: 'Tab',
            placeholder: 'Choose a tab',
          },
        },
        [PHONE]: {
          label: 'Phone',
          description:
            'Phone number links will only work on devices that can make calls',
          phone: {
            label: 'Phone number',
            placeholder: 'eg +01 23456789',
          },
        },
        [EMAIL]: {
          label: 'Email',
          email: {
            label: 'Email address',
            placeholder: 'eg. example@example.com',
          },
          subject: {
            label: 'Subject',
            placeholder: 'eg. Read my email!',
          },
        },
      },
      state: {
        active: 'Is active',
      },
    },
    [elements.LIST]: {
      label: 'List',
      guide: 'How do I set up a collection view?',
      type: 'List type',
      groups: {
        dataTypes: 'Collections',
        apiRequest: 'Custom APIs',
      },
      apiData: 'Custom APIs list data',
      apiRequestHelp:
        'To use a custom API endpoint you must nest this element in a Data Wrapper which first fetches your data',
      all: 'All',
      filter: {
        label: 'Filter',
        guide: 'Learn more about filtering views',
      },
      sortAndLimit: {
        label: 'Sort & limit',
        guide: 'Learn more about sorting and limiting',
      },
      pageSize: 'Page size',
      paginate: 'Paginate',
      none: 'None',
      limit: {
        label: 'Limit',
        placeholder: 'None',
        warning:
          'The data in this element will not automatically refresh if you do not provide a limit',
      },
      showPagination: {
        label: 'Show pagination ',
        true: 'Yes',
        false: 'No',
      },
      sort: {
        label: 'Sorting',
        default: 'Default: most recently created',
        [TEXT]: {
          ASC: 'A -> Z',
          DESC: 'Z -> A',
        },
        [SINGLE_OPTION]: {
          ASC: 'First -> Last',
          DESC: 'Last -> First',
        },
        [MULTIPLE_OPTION]: {
          ASC: 'First -> Last',
          DESC: 'Last -> First',
        },
        [DATE]: {
          ASC: 'Oldest -> Newest',
          DESC: 'Newest -> Oldest',
        },
        [INTEGER]: {
          ASC: 'Smallest -> Biggest',
          DESC: 'Biggest -> Smallest',
        },
        [DECIMAL]: {
          ASC: 'Smallest -> Biggest',
          DESC: 'Biggest -> Smallest',
        },
        [DURATION]: {
          ASC: 'Shortest -> Longest',
          DESC: 'Longest -> Shortest',
        },
        [BOOLEAN]: {
          ASC: 'True -> False',
          DESC: 'False -> True',
        },
      },
      state: {
        list: '{dataType} list',
        listItem: '{dataType} item',
      },
      filterInput: {
        label: 'Additional filters',
        new: 'Add another filter',
        addAnd: 'AND',
        addCondition: 'Add condition',
        addConditionGroup: 'Add condition group',
        conditionGroup: 'Condition group',
        addOr: 'Add an OR filter group',
        addBranchToOr: 'OR',
        record: '{dataType} Record',
        field: {
          placeholder: 'Select a field',
        },
        operator: {
          placeholder: 'Is equal to',
        },
        or: 'OR',
        result: {
          placeholder: 'Some value',
        },
        mergeWarning: {
          title: 'This filter might be invalid',
          message:
            'The operators highlighted below are reused by the same field.',
        },
        addAFilter: 'Add a filter',
      },
      data: {
        first: 'Is first',
        last: 'Is last',
        index: 'List index',
        totalCount: 'Total count',
        count: '{collection} count',
        hasNextPage: 'Has next page',
        hasPreviousPage: 'Has previous page',
        listField: '{fieldName} (list)',
        relatedListField: '{fieldName} ID (list)',
        link: "Go to {dataType}'s data",
      },
      apiParams: {
        title: 'API Parameters',
      },
    },
    [elements.MARKDOWN]: {
      label: 'Markdown',
      sectionLabel: 'Text',
      title: 'Text',
      description: 'Add a text block that supports markdown',
    },
    [elements.COMMENTS]: {
      label: 'Comments',
      title: 'Comments',
      description: 'Record comments section',
      empty: 'This linked record is empty',
    },
    [elements.DIVIDER]: {
      label: 'Divider',
      title: 'Divider',
      description: 'Add a divider',
    },
    [elements.FILE_GALLERY]: {
      label: 'File Gallery',
      title: 'Gallery',
      description: 'Add a preview for files',
      fields: {
        label: 'File field',
        placeholder: 'Choose a file field',
      },
      size: {
        label: 'Size',
      },
      showFileName: {
        label: 'Show file name?',
      },
    },
    [elements.MESSAGING]: {
      label: 'Messaging',
    },
    [elements.NOTICE]: {
      label: 'Notice',
      title: 'Notice',
      description: 'Add a notice element',
      placeholder: 'This is a notice!',
      icon: 'Icon',
      appearance: 'Appearance',
    },
    [elements.ONBOARDING_TASKS]: {
      label: 'Onboarding',
    },
    [elements.PAGE]: {
      label: 'Page',
      newPageEditorTitle: 'Add Page',
      addButton: 'Add',
      remove: 'Remove',
      addSubPage: 'New nested page',
      clone: 'Clone',
      addView: 'Add new view',
      addBlankPage: 'Add new blank page',
      cloneSub: 'Clone as nested view',
      hide: 'Hide from sidebar',
      show: 'Show in sidebar?',
      advanced: 'Advanced settings',
      visibility: 'Visibility settings',
      parent: {
        label: 'Where is your page going?',
        subLabel:
          "Nesting pages allows you to make use of common nav-bars or shared state and URL paths. Choosing another page places it with the other pages nested under that page. Selecting 'current element' will embed the new page at the currently selected element.",
        placeholder: 'It will be a top-level page',
        none: 'It will be a top-level page',
        currentElement: 'It will be embedded at the current element',
      },
      showRecordCount: 'Show record count?',
      name: {
        label: 'Page name',
        placeholder: 'eg. Followers',
      },
      dataType: {
        label: 'Page data type',
        subLabel:
          'Should this page link to one piece of data, like a particular user? This will allow you to specify an identifying property in the URL',
        none: 'None',
      },
      dataProperty: {
        label: 'Identifying property in the URL',
      },
      auth: {
        label: 'Must be logged in?',
        true: 'Yes',
        false: 'No',
      },
      routePath: {
        label: 'URL path',
        subLabel:
          'Tip: leave it blank to act as a wrapper page (for your navbar etc) or set it to "/" to catch all pages',
        placeholder: 'eg. followers',
        notUnique:
          'The path must be different than all the other pages in this page group',
      },
      result: {
        label: 'Result',
      },
      icon: {
        label: 'Icon',
      },
      parentView: {
        label: 'Parent',
        none: 'None',
        notice:
          'You can now use drag and drop to re-arrange pages and move them to and from folders or nested views',
      },
      state: {
        user: 'Logged in User',
        help: "The current user's values",
        loggedIn: 'Is logged in',
        pageType: 'Page {dataType}',
      },
      externalLink: {
        label: 'Link URL',
        placeholder: 'Link URL',
        editor: 'Link editor',
      },
      uniqueUrlPath: {
        label: 'URL path should be unique',
      },
      emptyState: { label: 'Add a component from the sidebar' },
    },
    [elements.PAGE_SWITCH]: {
      label: 'Page group',
    },
    [elements.QUICK_LINKS]: {
      label: 'Quick links',
      description: 'Add quick links',
      link: 'Links',
      dense: 'Dense',
      add: 'Add',
      title: 'Links',
    },
    [elements.RECORD]: {
      label: 'Related record',
    },
    SELECT_INPUT: {
      label: 'Select input',
      types: {
        DATA_TYPE: 'Data type',
        OPTION: 'Option',
        CUSTOM_OPTIONS: 'Custom options',
      },
      sort: {
        [ASC]: 'A -> Z (Ascending)',
        [DESC]: 'Z -> A (Descending)',
      },
      sizes: {
        [SM]: 'Small',
        [MD]: 'Default',
        [LG]: 'Large',
        [XL]: 'XL',
      },
      smallSizes: {
        [SM]: 'SM',
        [MD]: 'MD',
        [LG]: 'LG',
        [XL]: 'XL',
      },
      state: {
        field: 'Select input',
        value: 'value',
      },
      option: {
        placeholder: 'Select an option type',
        display: 'label',
        value: 'raw value',
        fieldLabel: 'Option',
        selected: 'Selected',
      },
    },
    [elements.RELATIVE_DATE]: {
      label: 'Relative date',
    },
    [elements.STAGES]: {
      label: 'Stages',
      title: 'Stages',
      description: 'Add a stages element',
      disabled:
        'Stages elements can only be added for tables with single option select fields',
      input: {
        label: 'Stage field',
        placeholder: 'Choose a field',
        useColors: 'Use option colors',
        disableEditing: 'Disable user edits',
      },
    },
    [elements.TABS]: {
      label: 'Tabs',
      new: 'Tab {count}',
      add: 'Add tab',
    },
    [elements.TEXT]: {
      label: 'Text',
      type: {
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        h4: 'Heading 4',
        h5: 'Heading 5',
        h6: 'Heading 6',
        p: 'Paragraph',
        b: 'Bold',
        span: 'Plain',
        blockquote: 'Block quote',
        LINK: 'Link',
        BUTTON: 'Button',
      },
    },
    [elements.TITLE]: {
      label: 'Title',
      description: 'Add a title and subtitle',
      buttons: {
        placeholder: 'Click me',
      },
      title: 'Title',
      subtitle: 'Subtitle',
      hideSubtitle: 'Hide subtitle',
      collapseSection: {
        label: 'Allow this section to be collapsed',
      },
    },
    [elements.VIDEO]: {
      label: 'Video',
      title: 'Video',
      description: 'Add a video element',
      type: {
        label: 'Video source',
        [DYNAMIC]: {
          label: 'Video URL or data property',
        },
        [UPLOAD]: {
          label: 'Upload or choose existing',
        },
      },
      source: {
        label: 'Video URL',
        placeholder: 'example.com/video.mp4',
      },
      dropZone: {
        label: 'Drag and drop a video or click to upload',
      },
      help: 'Choose the field with the video link or input the URL below. Regular Video links or Youtube links are supported.',
      options: {
        label: 'Type',
        placeholder: 'Choose a type',
        field: 'Data field',
        url: 'Input a url',
      },
      fields: {
        label: 'Video field',
        placeholder: 'Choose a field',
      },
      url: {
        label: 'Video Url',
        placeholder: 'e.g https://youtube.com/watch/?v=39weij',
      },
    },
    [elements.VIEW]: {
      label: 'View',
      guide: 'How do I set up a record page?',
      header: {
        label: 'Header',
        title: 'Title',
        subtitle: 'Subtitle',
        showBreadcrumbs: 'Show breadcrumbs',
        image: 'Image',
        coverPhoto: 'Cover photo',
        headerWidth: 'Header width',
      },
      size: {
        label: 'Size',
      },
      display: {
        label: 'Display',
        childLabel: 'Main display',
        groupBy: {
          grouping: 'Grouping',
          sort: 'Sort',
          timePeriod: 'Time period',
          label: 'Group records by a field',
          placeholder: 'Choose a field',
          collapsed: {
            true: 'Collapsed by default',
            false: 'Shown by default',
          },
          addGroup: 'Add a subgroup',
          noValue: 'No value',
        },
        dateStart: {
          label: 'Event start date field',
          placeholder: 'Choose a date field',
          title: 'Start date',
        },
        dateEnd: {
          label: 'Event end date field',
          placeholder: 'Choose a date field',
          title: 'End date',
        },
        startTime: {
          label: 'Event start time field',
          placeholder: 'Choose a time field',
          title: 'Start time',
        },
        endTime: {
          label: 'Event end time field',
          placeholder: 'Choose a time field',
          title: 'End time',
        },
        ganttDependency: {
          label: 'Dependency field',
          placeholder: 'Choose a dependency field',
          title: 'Dependency',
        },
        calendarView: {
          label: 'Default view',
        },
        calendarEnableEventDragAndDrop: {
          label: 'Enable event date drag and drop',
        },
        groupLayoutEnableDragAndDrop: {
          label: 'Enable drag and drop',
        },
        limitPerGroup: {
          label: 'Limit the number of records shown by default to:',
          placeholder: '5',
          limit: 'Record limit',
        },
        summary: {
          label: 'Board summary',
          placeholder: 'Choose a field',
        },
        recordTitle: {
          label: 'Title field',
        },
        showEmptyGroups: 'Show empty groups',
        map: {
          latitude: {
            label: 'Latitude',
            placeholder: 'Choose a latitude field',
          },
          longitude: {
            label: 'Longitude',
            placeholder: 'Choose a longitude field',
          },
          mapView: {
            label: 'Map type',
            mapTypes: {
              [SATELLITE]: 'Satellite',
              [TERRAIN]: 'Terrain',
              [ROADMAP]: 'Roadmap',
            },
          },
          apiKey: {
            invalid: {
              title: 'You need an API key',
              description:
                'Before you can use the map component you need to setup a Google Maps API Key',
              cta: 'Add an API Key',
            },
          },
        },
        bulkActions: {
          recordsSelected: {
            _: 'records selected',
            1: 'record selected',
          },
          unselectAll: 'Unselect all',
          selectAll: 'Select all',
          applyAction: 'Apply action to all selected records?',
          updateAll: 'Update all selected records',
          limitMaxBulkSizeError:
            'You can only update up to {max} records at a time',
          updating: 'Updating...',
        },
      },
      fileType: {
        label: 'Allowed File Types',
        fileTypeButtons: {
          [IMAGE]: 'Images',
          [DOCUMENT]: 'Docs',
          [AUDIO]: 'Audio',
          [VIDEO]: 'Video',
          [TEXT_FILE_TYPE]: 'Text',
        },
        singlFileTypeToolTip: 'Only {fileTyle} files are allowed',
        list: '{allowedFileTypes} and {lastFileType} files are allowed',
      },
      filters: {
        all: 'All',
        label: 'Filter fields',
        description: 'Configure which fields you want to be able to filter',
        defaultValueLabel: 'Default value',
        filterByEmpty: 'Show option to find Empty Values',
        helpText: 'Help text',
        placeholder: 'Filter {field}',
        placeholderLabel: 'Placeholder',
        icon: 'Icon',
        searchFilter: {
          label: 'Search box',
          help: 'This view search box will filter your view based on all of your visible text or number fields',
          placeholder: 'Search placeholder',
          disabled:
            'A view search box cannot be added to this view. Please remove your OR filter conditions first',
        },
        filterOperator: 'Filter operator',
        equal: 'Equal',
        between: 'Between',
        min: 'Min {field}',
        max: 'Max {field}',
        to: 'to',
        filterForEmptyLabel: 'Empty',
        sidebarTitle: 'Filters',
      },
      emptyState: {
        label: 'Empty state',
        title: 'Title',
        subtitle: 'Subtitle',
        image: 'Empty state image',
        hideIfEmpty: 'Hide component if empty',
        guide: 'Learn more about setting up Empty State',
      },
      rowLink: {
        label: 'Row link',
        description: 'Open record view on click',
        default: 'Default',
        none: 'None',
        views: 'Views',
        onClick: 'On click',
      },
      fields: {
        label: 'Label',
        nextField: 'Next field',
        prevField: 'Previous field',
        nextItem: 'Next item',
        prevItem: 'Previous item',
        renameEmptyValue: 'Empty Value Label',
        inlineEditing: {
          label: 'Enable editing inline',
          guide: 'What is inline editing?',
        },
        filter: 'Search by field name',
        multiple: 'Allow selecting multiple values?',
        heroImage: 'Use as card hero image',
        copyToClipboard: 'Quick copy to clipboard',
        copied: 'Copied',
        stickyColumn: 'Freeze column when scrolling',
        groupSummary: {
          label: 'Column summary',
          summaries: {
            [SUM]: 'Sum',
            [MAX]: 'Max',
            [MIN]: 'Min',
            [COUNT]: 'Count',
            [AVERAGE]: 'Avg',
          },
        },
        staleFieldsWarning: {
          subtitle:
            'Your config includes fields that no longer exist on this collection',
          cta: 'Fix this',
          tooltip:
            'The following field names no longer exist on this collection',
        },
        inputOptions: 'Form input options',
        parent: {
          label: 'Section record',
          record: 'This record',
          title: 'Record',
        },
        rowLink: {
          label: 'Which page should open on click?',
        },
        fileLayout: {
          label: 'Layout',
          size: 'Size',
          options: {
            [CIRCLE]: 'Circle Image',
            [FILE_NAME]: 'File name',
            [THUMBNAIL]: 'Thumbnail',
            [INLINE]: 'Inline',
          },
        },
        elementType: {
          label: {
            click: 'When clicked',
            display: 'Display as',
          },
          default: 'Default',
          numeric: {
            [elements.PROGRESS_BAR]: {
              label: 'Progress bar',
              maxValue: 'Maximum bar value',
            },
            [elements.PROGRESS_RING]: {
              label: 'Progress ring',
              maxValue: 'Maximum ring value',
              ringSize: 'Ring size',
            },
            exampleMaxValue: 'For example: 100',
            showNumber: 'Show number value',
          },
          [elements.LINK]: {
            default: 'Open',
            label: 'Link text',
          },
          [elements.BUTTON]: {
            default: 'Open',
            label: 'Button text',
          },
          [elements.IMAGE]: {
            label: 'Image alt text',
          },
          newTab: 'Open in new tab?',
        },
        inputType: {
          label: 'Display field as',
          default: 'Default',
          updateDisplayAs: {
            title: {
              markdown: 'Display field as Markdown for better user experience?',
              address: 'Display field as a Google Maps autocomplete field?',
            },
            cta: 'Update',
          },
          [DATE_AND_TIME]: 'Date and time',
          [DATE]: 'Date',
          [RADIO]: 'Radio buttons',
          [CHECKBOX]: 'Checkboxes',
          [COLORED_OPTIONS]: 'Colored options',
          [SIGNATURE]: 'E-Signature',
          [LIST]: 'List',
          [MARKDOWN]: 'Rich text editor',
          [AUTOCOMPLETE_ADDRESS]: 'Autocomplete address field',
        },
        linkWarning: {
          title:
            "To automatically link to records in this field you need to add a '{dataType} view'",
          cta: 'Add a view',
        },
        columnSpan: {
          label: 'Field width',
          default: 'Default',
          3: '25%',
          4: '33%',
          6: '50%',
          8: '66%',
          9: '75%',
          12: 'Full',
        },
        conditions: {
          fieldValues: 'Field Values',
          formValues: 'Form Values',
          label: 'Only show when conditions are met',
          guide: 'How do I choose when the field is visible?',
        },
        colorConditions: {
          label: 'Highlight this field based on conditions',
          guide: 'How do I conditionally highlight a field?',
        },
        validationRules: {
          add: 'Add Rule',
          label: 'Validate field inputs',
          labels: {
            characterLength: 'Text length',
            email: 'Should be an email address',
            regex: {
              label: 'Should match a specific pattern',
              help: 'Use custom regex to validate the text',
            },
            text: 'Text',
            url: 'Should be a URL',
            ipAddress: 'Should be an IP address',
            value: 'Value',
          },
          suffixes: {
            characterLength: ' characters',
          },
          required: '{fieldName} is required',
          invalidRule: 'This validation rule is invalid',
        },
      },
      charts: {
        label: 'Charts',
        description: 'Configure which charts you want to display',
        add: 'Add',
        guide: 'How do I set up a chart for my data?',
        chartType: 'Chart type',
        conditions: 'Show data that meets these conditions',
        placeholder: 'Chart {n}',
        xAxisValue: {
          label: 'Value for X-axis',
          title: 'X-axis',
          placeholder: 'Choose a value',
        },
        metric: {
          label: 'Metric',
          placeholder: 'Choose a metric to display',
        },
        xAxisLabel: 'X-axis label',
        yAxisLabel: 'Y-axis label',
        series: {
          label: 'Series',
          add: 'Add',
          placeholder: 'Series {n}',
        },
        yAxisValue: {
          label: 'Value for Y-axis',
          title: 'Y-axis',
          placeholder: 'Choose a value',
          name: 'Label',
        },
        max: {
          label: 'Maximum gauge value',
          title: 'Max value',
        },
        palette: 'Color Scheme',
        timePeriod: {
          label: 'Time period',
          placeholder: 'Choose a time period',
        },
        aggregation: {
          label: 'Summary',
          placeholder: 'Choose how to summarise',
        },
        title: 'Chart title',
        helpText: 'Help text',
        visibility: 'Chart Visibility',
        columnSpan: {
          label: 'Chart width',
          3: '25%',
          4: '33%',
          6: '50%',
          9: '75%',
          12: 'Full',
        },
        config: 'Titles & Axis labels',
      },
      tabs: {
        label: 'Tabs',
        add: 'Add',
        main: 'Details',
        title: 'Tab title',
        url: 'URL',
        newTitle: 'Tab {count}',
        invalidTitle: 'Cannot build a URL from this title',
        visibility: 'Visibility',
        empty: "Sorry, there's nothing on this page",
        icon: 'Icon',
        guide: 'Learn how to add Tabs',
      },
      buttons: {
        label: 'Buttons',
        newButton: {
          show: 'New record button',
          label: 'Show new record button',
          buttonText: 'Button text',
          noView: {
            description:
              "To add new records you need to add a '{dataType}' view. If you don't want to show this view in the sidebar you can hide it.",
            cta: 'Add a view',
          },
          newLink: 'What form should be used?',
          openFormInModal: {
            label: 'Open form in:',
            modal: 'Modal',
            newPage: 'Page',
          },
        },
        editButton: {
          show: 'Show edit button',
          editText: 'Edit button text',
          doneText: 'Done button text',
        },
        editRelatedRecordButtons: {
          show: 'Edit related records',
          unlinkButtonText: 'Unlink existing record button text',
          switch: {
            relatedRecord: 'Allow linking related records',
            unlinkButton: 'Allow unlinking related records',
          },
        },
        exportButton: {
          show: 'Export button',
          buttonText: 'Button text',
          defaultText: 'Export',
          error: 'Oops, there was a problem exporting your data',
          filename: 'Export',
          guide: 'Learn more about exporting data',
          exportIdValue: 'Include ID Value in export?',
        },
        importButton: {
          label: 'Import button',
          buttonText: 'Button text',
          fields: {
            label: 'Field label',
          },
          hidden: {
            description:
              'Providing a hidden value allows you to set a default value that each imported row will have. It also prevents the user from mapping a column in their CSV to this field',
          },
          guide: 'Learn more about importing data',
        },
        back: 'Back to list',
        saveButton: {
          label: 'Save button',
          text: 'Save button text',
        },
        onSave: {
          label: 'On save',
          help: 'Customize the page shown after the record is saved',
          scope: {
            label: 'New {dataType} record',
            help: 'The record that will be saved',
          },
        },
      },
      publicForms: {
        label: 'Public Forms',
      },
      comments: {
        label: 'Record comments',
        guide: 'What are record comments?',
        show: 'Show record comments',
        tooltip:
          "Record comments allow your apps's users to add rich comments to any record and get notified when others reply to those comments",
        new: 'New',
        openByDefault: 'Comments open by default',
        allowAttachments: 'Allow users to attach files',
      },
      sections: {
        add: 'Add an element to this page',
      },
      new: {
        description:
          'Configure which fields you want to include when creating a new record',
        required: 'Required',
        requiredConditions: 'Only required when conditions are met',
        optionsFilter: 'Options filter',
        allowNewRecords: 'Allow users to create new records?',
        onlyAllowNewRecords: 'Only allow users to create new records',
        hidden: {
          label: 'Hidden value',
          description:
            'Providing a hidden value hides the input field from the user and allows you to set a default value',
        },
        field: 'Input field',
        value: 'Field value',
      },
      edit: {
        label: 'Edit button',
        edit: 'Edit',
        done: 'Done',
        unsavedChanged: 'Some fields are invalid and might not be saved',
      },
      generateLayout: {
        new: 'Create a {dataType}',
      },
      recordColoring: {
        title: 'Record colors',
        noRecordColorings: 'No record colors have been set up',
        guide: 'How do I set up conditional record colors?',
      },
      actionButtons: {
        label: {
          default: 'Action buttons',
          collection: 'Collection buttons',
          navigation: 'Navigation buttons',
          record: 'Record buttons',
          enableBulkActions: 'Enable bulk actions',
        },
        execution: {
          title: 'Type',
          label: 'Execution type',
          MODAL: {
            description: 'A form will open for the user to complete',
            label: 'Modal',
          },
          ONE_CLICK: {
            description: 'The action button will execute immediately',
            disabled:
              'All form fields must be hidden to make an action button execute from one click',
            label: 'One Click',
          },
        },
        errors: {
          [UPDATE]: {
            notFound: {
              title: 'Cannot load the record to update',
              message:
                'We were not able to find the record you are trying to update.',
            },
          },
        },
        add: 'Add',
        defaultButtonText: 'Action #{index}',
        confirm: 'Confirm',
        next: 'Next',
        done: 'Done',
        buttonText: 'Button text',
        icon: 'Icon',
        title: 'Modal title',
        description: 'Modal description',
        buttonTooltip: 'Button tooltip',
        appearance: 'Appearance',
        config: 'Button settings',
        guide: 'How can action buttons make my app more interactive?',
        variants: {
          danger: 'Danger',
          primary: 'Primary',
          secondary: 'Default',
          success: 'Success',
          warning: 'Warning',
        },
        actions: 'Actions',
        invalid: 'This action is not fully configured',
        error: 'There was a problem running this action',
        visibility: 'Button visibility',
        notifications: {
          label: 'Notification',
          description: 'Show a notification when the actions complete?',
          type: 'Notification type',
          notificationType: {
            [PRIMARY]: 'Success',
            [INFO]: 'Information',
            [WARNING]: 'Warning',
            [DANGER]: 'Danger',
            [CONFETTI]: 'Confetti',
          },
          text: 'Notification text',
        },
        tooltip: 'Exit build mode to use action buttons',
        noActionsDefined: 'No actions defined',
        noActionsButtons: 'No action buttons added',
        featureNotAvailableInPlan:
          "Please upgrade to {plan} plan to use the '{action}' action",
      },
      pivotTable: {
        addValue: 'Add a value',
        columnGrouping: 'Column grouping',
        rowGrouping: 'Row grouping',
        showSummaryColumn: 'Show summary column',
        showTableSummary: 'Show summary row',
        summary: 'Summary',
        summaryColumnLabel: 'Summary column label',
        summaryType: 'Summary type',
        tableSummaryType: 'Row Summary',
        values: 'Values',
      },
      clone: 'clone',
      error: 'This collection no longer exists. Please remove this view',
      confirmDelete: 'Confirm delete?',
      newPublicForm: 'Create a Public Form',
      publicFormUrl: 'Public Form URL',
      enablePublicFormNav: 'Include a top-bar on your form?',
      enablePublicFormToggleLabel: 'Enable Public Form',
      customOnSubmitMessageInputLabel: 'On Submit Message',
      defaultOnSubmitMessage: 'Thank you, your response has been saved',
      publicFormsBlurb:
        'Create a form with a shareable URL that allows anyone to submit new records to this collection without logging in to your Noloco app',
      publicFormsProductGuideLinkText: 'Learn more about Public Forms',
      urlCopied: 'Public Form URL copied to clipboard',
      copied: 'Copied to clipboard',
      state: {
        recordView: {
          help: 'Record page values',
        },
      },
      barcodeScanner: {
        connectDevice: 'Please connect a compatible device',
        success: 'Scan successful',
        noBarcodeField: 'There is no barcode field defined',
      },
    },
    [elements.YOUTUBE_VIDEO]: {
      label: 'Youtube video',
    },
    [elements.CONTAINER]: {
      description: 'Add a container to group multiple sections together',
      label: 'Container',
      title: 'Container',
      empty: 'Configure which sections you want to display here',
    },
  },
  compoundElements: {
    columns: {
      label: 'Row & columns',
    },
    card: {
      label: 'Card',
    },
    textLink: {
      label: 'Text link',
    },
    buttonLink: {
      label: 'Button link',
    },
    linkBlock: {
      label: 'Link block',
    },
    simpleForm: {
      label: 'Simple form',
    },
    formBlock: {
      label: 'Form block',
    },
    simpleList: {
      label: 'Simple list',
    },
    listBlock: {
      label: 'List block',
    },
  },
  elementsList: {
    title: 'Add element',
    elements: 'Elements',
    blocks: 'Blocks',
  },
  editor: {
    title: {
      standard: '{projectName} - Noloco app builder',
    },
    connecting: 'Getting things ready...',
    layout: 'Layout',
    notStyleable: 'The current element is not styleable',
    emptyState: 'Select an element on the canvas to customize it',
    direction: {
      label: 'Direction',
      horizontal: {
        label: 'Row',
        tooltip: 'Child elements flow from left to right',
      },
      vertical: {
        label: 'Column',
        tooltip: 'Child elements flow from top to bottom',
      },
      grow: {
        label: 'Grow',
        description: 'Allow this element to fill any available space',
      },
      wrap: {
        label: 'Wrap',
        description:
          'Wrap children below if the content overflows the parent container',
      },
    },
    array: {
      done: 'Done',
      delete: 'Delete',
    },
    display: {
      label: 'Display',
      block: {
        label: 'Block',
        description: 'Starts on a new line and takes up the full width',
      },
      flex: {
        label: 'Flex',
        description:
          'Positions its children on an axis, with alignment options',
      },
      inlineBlock: {
        label: 'Inline block',
        description:
          'Takes up a specific width and height but inline with other elements',
      },
      hidden: {
        label: 'Hidden',
        description: 'Element is hidden',
      },
    },
    alignment: {
      horizontal: {
        label: 'Horizontal',
        [START]: {
          label: 'Left',
          description: 'Align child items to the left',
        },
        [CENTER]: {
          label: 'Center',
          description: 'Align child items in the center',
        },
        [END]: {
          label: 'Right',
          description: 'Align child items to the right',
        },
        [BETWEEN]: {
          label: 'Between',
          description:
            'Provide equal amount of horizontal space between each child',
        },
        [AROUND]: {
          label: 'Around',
          description:
            'Provide equal amount of horizontal space each side of each child',
        },
      },
      vertical: {
        label: 'Vertical',
        [START]: {
          label: 'Top',
          description: 'Align child items to the top',
        },
        [CENTER]: {
          label: 'Center',
          description: 'Align child items in the center',
        },
        [END]: {
          label: 'Bottom',
          description: 'Align child items to the bottom',
        },
      },
    },
    size: {
      label: 'Size',
      w: {
        label: 'Width',
      },
      h: {
        label: 'Height',
      },
      max: 'Max',
      min: 'Min',
    },
    overflow: {
      label: 'Overflow',
      visible: {
        label: 'Visible',
        description: 'Show all content that overflows its bounds',
      },
      hidden: {
        label: 'Hidden',
        description: 'Hide all content that overflows its bounds',
      },
      scroll: {
        label: 'Scroll',
        description: 'Always shows scrollbars',
      },
      auto: {
        label: 'Auto',
        description:
          'Add scrollbars if the content overflows the elements bounds',
      },
    },
    background: {
      label: 'Background',
      opacity: {
        label: 'Opacity',
      },
      color: {
        label: 'Color',
      },
      image: {
        label: 'Image & position',
        src: {
          placeholder: 'eg. https://imgur.com',
        },
        type: {
          upload: {
            label: 'Upload an image',
          },
          dynamic: {
            label: 'Image URL',
          },
        },
        size: {
          label: 'Size',
          auto: {
            label: 'Auto',
            help: 'Images default size',
          },
          cover: {
            label: 'Cover',
            help: 'Fill the background layer',
          },
          contain: {
            label: 'Contain',
            help: 'Scale to edges without cropping',
          },
        },
        repeat: {
          label: 'Tile',
          true: {
            label: 'Horizontally and Vertically',
          },
          'no-repeat': {
            label: 'No tiling',
          },
          'repeat-x': {
            label: 'Horizontally',
          },
          'repeat-y': {
            label: 'Vertically',
          },
        },
        position: {
          label: 'Position',
          'left-top': 'Left top',
          top: 'Top',
          'right-top': 'Right top',
          left: 'Left',
          center: 'Center',
          right: 'Right',
          'left-bottom': 'Left bottom',
          bottom: 'Bottom',
          'right-bottom': 'Right bottom',
        },
      },
    },
    typography: {
      label: 'Typography',
      align: {
        label: 'Align',
        left: {
          label: 'Align left',
        },
        center: {
          label: 'Align center',
        },
        right: {
          label: 'Align right',
        },
        justify: {
          label: 'Align justify',
        },
      },
      color: {
        label: 'Color',
      },
      size: {
        label: 'Size',
      },
      style: {
        label: 'Style',
      },
      weight: {
        label: 'Weight',
      },
      italic: {
        label: 'Italic',
      },
      underline: {
        label: 'Underline',
      },
      lineThrough: {
        label: 'Line through',
      },
      uppercase: {
        label: 'Uppercase text',
      },
    },
    spacing: {
      label: 'Spacing',
      margin: 'Margin',
      padding: 'Padding',
    },
    position: {
      label: 'Position',
      offsets: {
        label: 'Offsets',
      },
      zIndex: {
        label: 'z-index',
        placeholder: 'auto',
      },
      positions: {
        static: {
          label: 'Static',
          help: 'The default - position an element according to the normal flow of the document',
        },
        fixed: {
          label: 'Fixed',
          help: 'Position an element relative to the browser window.',
        },
        absolute: {
          label: 'Absolute',
          help: "Position an element outside of the normal flow of the document, causing neighboring elements to act as if the element doesn't exist.",
        },
        relative: {
          label: 'Relative',
          help: 'Position an element according to the normal flow of the document.',
        },
        sticky: {
          label: 'Sticky',
          help: 'Position an element as relative until it crosses a specified threshold, then treat it as fixed until its parent is off screen.',
        },
      },
    },
    borders: {
      label: 'Borders',
      radius: 'Roundness',
      border: 'Color',
      color: 'Color',
      style: 'Style',
      width: 'Width',
      styleOptions: {
        none: {
          label: 'None',
        },
        solid: {
          label: 'Solid',
        },
        dashed: {
          label: 'Dashed',
        },
        dotted: {
          label: 'Dotted',
        },
      },
    },
    effects: {
      label: 'Effects',
      shadow: {
        label: 'Shadow',
        base: 'Base',
      },
      cursor: {
        label: 'Cursor',
        auto: 'Auto',
        default: 'Default',
        pointer: 'Pointer',
        notAllowed: 'Not allowed',
        wait: 'Wait',
        text: 'Text',
        move: 'Move',
        help: 'Help',
      },
    },
    custom: {
      label: 'Custom properties',
    },
    addItem: 'Add item',
    boolean: {
      true: 'Yes / true',
      false: 'No / false',
    },
    hints: {
      tooltip: 'Our assistant has a suggestion',
    },
  },
  screens: {
    base: {
      title: 'All devices',
      subtitle: 'Base styles (unconstrained)',
      description:
        'These styles apply to all devices at all breakpoints. Make sure to start your styling here, and provide more specific styles for other screen types',
    },
    [SM]: {
      title: 'Mobile',
      subtitle: 'Below 478px',
      description:
        'These styles are applied to all devices smaller than 478px along with your desktop, laptop, tablet and base styles. This is about the width of the original iPhone',
    },
    [MD]: {
      title: 'Tablet',
      subtitle: 'Below 768px',
      description:
        'These styles are applied to all devices smaller than 768px along with your desktop, laptop and base styles. This is about the width of the original iPads',
    },
    [XL]: {
      title: 'Desktop & HD Laptops',
      subtitle: 'Below 1280px',
      description:
        'These styles are applied to all devices smaller than 1280px along with your base styles',
    },
  },
  tabs: {
    data: 'Data',
    elements: 'Elements',
    element: 'Element',
    style: 'Style',
  },
  project: {
    errors: {
      generic: 'Something went wrong, please try again.',
      notFound:
        "We looked everywhere but we couldn't find {projectName}. You might not have the right permissions",
      update:
        'Something went wrong updating your app. If this happens again please refresh the page',
      updateConnection: {
        title: 'Something went wrong updating your app',
        subtitle:
          'This update will be retried but if the issue persists please refresh the page',
      },
    },
  },
  settings: {
    title: 'Settings',
    project: {
      label: 'App settings',
      live: {
        label: 'Live mode',
        guide: 'Learn more about live mode',
        title: 'Turn on live-mode to allow your users to log in',
        description:
          'When your app is live, anyone in your User collection can log in to your app. When in test-mode only builders can log in and use the app',
        subtitle: {
          _:
            // eslint-disable-next-line no-template-curly-in-string
            'Each Noloco plan comes with 1 live app included. You currently have {context} live apps. Each additional live app is an additional ${cost} per month.',
          // eslint-disable-next-line no-template-curly-in-string
          0: 'Each Noloco plan comes with 1 live app included. You currently have no live apps. Each additional live app is an additional ${cost} per month.',
          // eslint-disable-next-line no-template-curly-in-string
          1: 'Each Noloco plan comes with 1 live app included. You currently have {context} live app. Each additional live app is an additional ${cost} per month.',
        },
        error: 'Oops, there was a problem changing your app state',
      },
      title: {
        label: 'App title',
        placeholder: 'My simple app',
      },
      description: {
        label: 'App description',
        placeholder: 'Describe what your app does',
      },
      templateInfoUrl: {
        label: 'Template Info URL',
        placeholder: 'Enter the Template Info/Marketing Page URL',
      },
      logo: {
        label: 'Sidebar logo',
        guide: 'Learn more about customizing your app logo',
        description:
          'Upload a rectangular PNG, JPEG or GIF to display in the app sidebar. Pick a logo that works well on dark backgrounds',
      },
      authLogo: {
        label: 'Email logo',
        description:
          'Upload a rectangular PNG, JPEG, or GIF to display in all emails. Pick a logo that works well on white backgrounds',
      },
      favicon: {
        label: 'Favicon',
        description:
          'Upload a small, square ICO, PNG, JPEG or GIF to display in the browser tab',
      },
      social: {
        label: 'Social settings',
        description: {
          label: 'Social media description',
          placeholder:
            'Describe what your app does. This will be shown when your app is shared on social media',
        },
        image: {
          label: 'Social media banner',
          description:
            'Upload a banner (recommended 1200px x 630px) ICO, PNG, JPEG or GIF to display when your app is shared on social media',
        },
      },
    },
    spaces: {
      create: 'Create a new space',
      description:
        "Spaces help you organize your app by creating separate, customizable spaces. This makes it easier for team or clients to find what they need and keeps large apps from feeling cluttered. \n\n You can assign pages to different spaces, set visibility rules (just like pages), and even customize the look and feel of each space. It's all about making navigation simpler and improving the user experience.",
      label: 'Spaces',
      noSpaces: 'You currently have no spaces',
      title: 'Setup spaces to group your pages',
      selectSpace: 'Select a space',
      urlCopied: 'Space shareable link copied to clipboard',
      searchSpaces: 'Search spaces',
      noSpacesFound: 'No spaces found',
      selectSpaceWithNumberKey: '{key} + (1-9) to select space',
      options: {
        setup: {
          placeholder: 'Space name',
          title: 'Setup',
          icon: 'Icon',
          name: 'Name',
          description:
            "Choose a name and icon for your space. This will help your app's users identify the space in the sidebar",
        },
        theme: {
          title: 'Theme',
        },
        pages: {
          noPages: 'You currently have no pages in this space',
          allPages: 'You have added all the available pages to this space',
        },
      },
    },
    emails: {
      label: 'Email settings',
      emailFrom: {
        label: 'Email from name',
        placeholder: 'e.g. Noloco',
        help: 'This is the name that your workflow emails will appear from',
      },
      invitations: {
        guide: 'Learn more about customizing your app invitations',
        client: {
          label: 'Customer invitation email',
          help: 'The email that will be sent to customers when they are invited to your app',
          subject: {
            label: 'Subject',
            placeholder: 'Please register for your app',
          },
          message: {
            label: 'Email message',
          },
        },
        internal: {
          label: 'Internal invitation email',
          help: 'The email that will be sent to your staff when they are invited to your app',
          subject: {
            label: 'Subject',
            placeholder: 'Please complete your registration',
          },
          message: {
            label: 'Email message',
          },
        },
      },
      smtp: {
        error: 'Oops, there was an issue saving your SMTP configuration',
        label: 'Custom from address (SMTP)',
        guide: 'Learn more about setting up SMTP for custom branded emails',
        title: 'Setup SMTP for truly branded emails',
        description:
          'Setup SMTP to deliver emails from an email address on your own domain using a 3rd party provider like Sendgrid, Mailgun, Sendinblue or GMass',
        save: 'Save',
        fields: {
          from: {
            label: 'Sender email',
            help: 'This is the email address that emails will be sent from',
            placeholder: 'jane@example.com',
          },
          host: {
            label: 'Host',
            placeholder: 'smtp.example.com',
          },
          username: {
            label: 'Username',
            placeholder: 'Your account username',
          },
          password: {
            label: 'Password',
            placeholder: 'Your account password',
          },
          port: {
            label: 'Port',
          },
          secure: {
            label: 'Use a secure connection',
          },
        },
        success: {
          title: 'Success! Your SMTP config has been saved',
          message:
            'We have sent you a test email, if it does not arrive within a few minutes please check the sender email is correct',
        },
      },
    },
    nav: {
      label: 'Navigation',
      guide: "Learn more about controlling your app's navigation",
      commandPalette: {
        title: 'Navbar search',
        description:
          "Allow your users to quickly navigate your app's pages from the navbar and with a keyboard shortcut",
      },
      style: {
        label: 'Menu style',
        description: "Customize your app's navigation menu style",
        options: {
          side: 'Sidebar',
          top: 'Top',
        },
      },
    },
    theme: {
      label: 'Theme & design',
      colorGroups: {
        label: 'App colors',
        description:
          "Choose your app colors to match your app's style. Pick from one of our preset themes or specify a custom color",
        preview: {
          title: 'Preview',
          button: 'Button',
        },
        custom: {
          label: 'Custom color',
          description:
            "Each theme is made up of 10 shades ranging from lightest to darkest, we've automatically generated the shades for you from your base color, but you can tweak the rest to your liking",
          placeholder: '#2522FC',
          shade: 'Shade',
        },
      },
      darkMode: {
        description: 'Allow your users to toggle between light and dark themes',
        defaultMode: {
          label: 'Theme',
          description: 'Choose the default theme for your app',
          auto: 'Auto',
          light: 'Light',
          dark: 'Dark',
        },
      },
      guide: 'Learn more about custom themes',
    },
    pwa: {
      label: 'Progressive Web App',
      enable: 'Enable Progressive Web App',
      guide: 'Learn more about Progressive Web Apps',
      description:
        'Experience your app as a Progressive Web App (PWA) for improved performance and offline access. When you enable this, your app users will be able to install the app as a PWA.',
      requirements: {
        title: 'Please make sure that you have added the following:',
        list: {
          title: 'App title and description',
          favicon: 'Favicon of at least 192px X 192px resolution',
          publish: 'Publish your app',
        },
        add: 'Add',
        publish: 'Publish',
      },
      ready: 'Your app is ready to be used as a PWA',
      offline: {
        title: 'You are offline',
        subtitle: 'Please check your internet connection and try again',
      },
      online: {
        title: 'You are back online',
      },
      installation: {
        title: 'Install {title}',
        description:
          'This site can be installed as an application. After installation, it will open in its own window.',
        install: 'Install',
        notNow: 'Not now',
      },
      update: {
        title: 'Update available',
        description: 'Your app will reload after you click Update',
        update: 'Update',
        notNow: 'Not now',
      },
      notifications: {
        consent: {
          modal: {
            confirm: 'Enable',
            text: 'Would you like to enable browser notifications?',
            title: 'Enable notifications',
          },
        },
      },
    },
    customCode: {
      label: 'Custom Code',
      guide: 'Learn more about adding custom code to your app',
      header: {
        label: 'Header code',
        fieldLabel: 'Add code to the end of the <head> tag',
        placeholder: 'Add <style> or <link> tags here',
        helpText:
          'Code and styles added to the header will not be validated and will only be added after you refresh the page. Ensure any custom CSS is wrapped in <style></style> tags. Adding <html>, <body>, or <head> tags in this section will break your app',
      },
      footer: {
        label: 'Footer code',
        fieldLabel: 'Add code before the </body> tag',
        placeholder: 'Add <script> tags or custom Javascript here',
        helpText:
          'Code added to the footer will not be validated and will only be added after you refresh the page. Ensure any custom Javascript is wrapped in <script></script> tags. Adding <html>, <body>, or <head> tags in this section will break your webapp',
      },
    },
    roles: {
      label: 'User roles',
    },
    signIn: {
      label: 'Login & Sign Up',
      signInOptions: {
        label: 'Login Options',
        or: 'OR',
        description: 'Control how your users sign into your app',
        magicLink: {
          label: 'Magic Link',
          description:
            'Email a link to your users that signs them in automatically',
        },
        password: {
          label: 'Email & Password',
          description: 'Allow your users to sign in with email and password',
          default: 'Default',
        },
        google: {
          label: 'Sign in With Google',
          description: 'Allow your users to sign in with their Google account',
        },
        noloco: {
          label: 'Noloco Login',
        },
        saml: {
          label: 'SAML Integration',
        },
      },
      sso: {
        title: 'Single Sign-On (SSO)',
        description: 'Login to your app using your organization’s credentials',
        saml: {
          supportedMethods: 'Noloco supports SAML 2.0 for Single Sign-On (SSO)',
          help: 'Learn more about Single Sign-On',
          button: 'Add SSO Provider',
          title: {
            add: 'Add Single Sign-On Provider',
            update: 'Update Single Sign-On Provider',
          },
          match: {
            domains: 'Users from {emailDomains}',
            all: 'All users',
            others: 'All other users',
          },
          loginUrl: 'Login URL',
          callback: 'SAML response callback',
          uploadMetadata: 'Upload metadata XML',
          manualConfiguration: 'Manual Configuration',
          metadata: 'Set-up your SAML integration',
          metadataUpload:
            'Upload the metadata XML file from your Identity Provider (IdP) to automatically set up your SAML provider',
          dropzone: {
            label:
              'Drag and drop your SAML metadata XML file or click to upload',
          },
          manual:
            'Enter the following details from your Identity Provider (IdP) to configure your SAML provider',
          entryPoint: {
            label: 'IdP entrypoint*',
            placeholder: 'sso.mydomain.com/saml',
          },
          domainMatch: {
            title: 'Email domain for this provider',
            label: 'Domain',
            placeholder: 'example.com',
            error: 'Invalid domain matching configuration',
            reusedDomainError: 'The domain {domain} is already in use',
            fallbackUsedError:
              'A provider for all other users already exists. A domain match must be configured to support multiple providers',
          },
          issuer: {
            label: 'IdP issuer (Entity ID)*',
            placeholder: 'urn:sso.mydomain.com',
          },
          cert: {
            label: 'IdP public signing certificate*',
          },
          attributeMapping: {
            label:
              'Provide the mapping from your SAML attributes to Noloco user fields',
            email: {
              label: 'Email Address Attribute*',
              placeholder: 'email_address',
            },
            firstName: {
              label: 'First Name Attribute',
              placeholder: 'first_name',
            },
            lastName: {
              label: 'Last Name Attribute',
              placeholder: 'last_name',
            },
          },
          defaultRole: {
            title:
              'Automatically assign a user role when new users sign in with SAML',
            label: 'Default User Role',
            placeholder: 'Choose a default role',
          },
          save: 'Save',
          remove: {
            button: 'Remove Provider',
            title: 'Remove SSO provider',
            description:
              'All of your existing users will still exist, but users will not be able to login or register with single sign-on.',
            confirm: 'Remove',
          },
          disabledOptions: 'Your app is currently using Single Sign On',
          reEnableOptions:
            'When single sign on is enabled all other login and sign up features are disabled.',
        },
      },
      openSignUp: {
        label: 'Open Sign Up',
        guide: 'Learn more about open sign up',
        description:
          'Open sign up allows anyone to sign up to your app through the registration page',
        button: 'Allow anyone to register for your app',
        registerPage: 'Go to registration page',
      },
      externalUsersAddon: {
        cancel: 'Cancel',
        save: 'Save',
        domain: {
          label: 'Internal email domains',
          help: 'List the email domains of your internal users.',
        },
        enable: {
          label: 'Enable',
          help: 'Activate the addon for this app.',
          warning:
            'The client portal addon is only available to apps with paid business domains. As your email uses the {domain} domain you cannot enable this addon.',
        },
        guide: 'What is the client portal addon?',
        label: 'Client portal addon',
        tooltip:
          'Enable the client portal addon to pay a different rate for users who are not in your organization',
        help: {
          p1: 'Save on costs and simplify the process of inviting external users to your app with the Client Portal Addon.',
          p2: "To benefit from the reduced pricing, you'll need to list the email domains of your internal users and all other users will be considered external. Any builder or data admin will be counted as an internal users, regardless of their email addresses.",
          p3: 'The cost of enabling the Client Portal Addon depends on your current plan. Check your plan details for pricing information.',
          p4: 'Remember, you can disable this addon at any time if you no longer require its functionality.',
        },
        modal: {
          body: 'Enabling the client portal addon will change how you are billed. Please make sure you have read our guides on this before you enable it.',

          cancel: 'Cancel',
          confirm: 'Agree & Enable',
          guide: 'How does billing work for the client portal addon?',
          title: 'Accept billing terms for the client portal addon',
        },
      },
    },
    loginScreen: {
      label: 'Login screen',
      guide: "Learn more about customizing your app's login screen",
      title: {
        label: 'Title',
        placeholder: 'Sign in to your account',
      },
      subTitle: {
        label: 'Subtitle',
        placeholder: 'A subtitle for your login page',
      },
      logo: {
        label: 'Logo',
        description:
          'Upload a rectangular PNG, JPEG, or GIF to display on the login and registration pages. Pick a logo that works well on white backgrounds',
        guide: 'Learn more about custom logos',
      },
    },
    signUp: {
      label: 'User Lists',
      userLists: {
        sync: 'Sync your users',
        label: 'Sync your users with a User List',
        explainer:
          'Control which users can access your app by syncing a customer list from one of your tables',
        guide: 'Learn how to setup your User List',
        close: 'Done',
      },
      userTable: {
        title: 'Add a new user list',
        editTitle: 'User list',
        description:
          'People in this list will be able to login to your app with their email address',
        error: 'Oops! There was a problem creating your user list',
        empty: {
          title:
            'Automatically give your customers, partners or team access to your app',
          subtitle:
            'Start by selecting an email field in one of your connected data sources',
        },
        name: {
          label: 'User List Name',
          help: 'What would you call a person on this list e.g. Contractor',
          placeholder: '{dataSource} User',
          suffix: 'List',
          exampleTableDisplay: 'My Collection',
        },
        linkField: {
          label:
            'You will be able to access this table with this new field on the *User* table:',
          user: 'Logged in User',
        },
        table: {
          label: 'What table are your users in?',
          placeholder: 'Choose a Users table',
        },
        filter: {
          label: 'Should all users be included?',
          description:
            'Only users who meet these conditions will be included in your user list.',
        },
        fields: {
          email: {
            label: 'Email address field*',
            placeholder: 'Choose email field',
          },
          firstName: {
            label: 'First name field',
            placeholder: 'Choose first name field',
          },
          lastName: {
            label: 'Last name field',
            placeholder: 'Choose last name field',
          },
          profilePicture: {
            label: 'Profile picture field',
            placeholder: 'Choose profile picture field',
          },
          usingFullName:
            'You have selected a full name field. Noloco will automatically split this into first and last name fields',
          company: {
            help: "Specify a company field if you need to use modules like Billing, Chat or File Sharing and you want to automatically sync your user's companies",
            label: 'Company field',
            googleSheets:
              'Company fields are not yet supported on Google Sheet tables, if would like to use this please let us know',
            placeholder: 'Choose a company field',
          },
          paymentsId: {
            help: "This is the company's Stripe customer ID",
            label: 'Payments ID field',
            placeholder: 'Choose a payments ID field',
          },
          fileSharingId: {
            help: "This is the company's Google Drive folder ID",
            label: 'File Sharing ID field',
            placeholder: 'Choose a file sharing ID field',
          },
        },
        defaultRole: {
          label: 'Default user role',
          placeholder: 'Choose a default role',
          help: 'Assign a role to your users if their role has not already been set. You cannot choose a role that gives users the ability to modify your app',
        },
        conditionalRole: {
          add: 'Conditional role',
          role: 'Role',
          conditions: 'Conditions',
          placeholder: 'Choose a conditional role',
          noAdminChangesExplainer:
            'Conditional roles will not modify the roles of admin users',
          help: 'Assign a role to your users if they match the following conditions. You cannot choose a role that gives users the ability to modify your app',
        },
        delete: {
          title: 'Remove this user list?',
          description:
            'Removing this user list will drop the field linking your users and this table. You can however, recreate it afterwards if you change your mind.',
          confirm: 'Yes, remove',
          button: 'Remove',
          error: 'Oops! There was a problem removing your user list',
        },
        saved: {
          title: 'User list saved',
          description:
            "Your users are now syncing in the background, please check back in a few minutes. You will receive an email when it's done",
        },
      },
      new: 'New user list',
      save: 'Save',
    },
    domain: {
      label: 'Domains',
      guide: 'Learn more about setting up a custom domain',
      title: 'Connect a custom domain',
      description:
        'By default, your site is always accessible via a Noloco subdomain. Custom domains allow you to access your site via one or more non-Noloco domain names.',
      button: 'Add domain',
      custom: 'Custom domain',
      production: 'Production subdomain',
      development: 'Development subdomain',
      add: {
        title: 'Add a new domain',
        confirm: 'Add domain',
        explain:
          'Custom domains allow your users to easily find your site with a domain you own.',
        placeholder: 'app.yourdomain.com',
      },
      dns: {
        title: 'DNS Configuration',
        confirm: 'Okay',
        explainer: {
          CNAME:
            'Log in to your DNS provider (usually where you bought the domain), and add a CNAME record for **{subdomain}** pointing to ssl.noloco.co.',
          ALIAS:
            'Log in to your DNS provider (usually where you bought the domain), and add an ALIAS record for your domain pointing to ssl.noloco.co.',
        },
        disclaimer:
          'Please allow up to 24 hours for these changes to propagate, although it usually happens in just a few minutes.',
      },
      remove: {
        title: 'Are you sure you want to remove this domain?',
        description:
          'If you remove this domain your app will no longer be accessible at **{domain}**.',
        confirm: 'Yes, remove it',
      },
      needsSetup: 'Needs setup',
      verify: 'Verify',
      domainActive: 'Domain is active',
      domainVerified: {
        title: 'Domain is now active',
        subtitle: '{domainName} is verified and ready for use',
      },
      domainNotVerified: {
        title: 'We are still awaiting the new DNS changes',
        subtitle: 'Please allow up to 24 hours for these changes to propagate',
      },
    },
    payments: {
      label: 'Payments',
      title: 'Connect a payments provider',
      subtitle: 'Connect a Stripe account to accept payments through Noloco',
      stripe: {
        name: 'Stripe',
        title: 'Connect a Stripe account to accept payments',
        subtitle:
          'Once you connect a Stripe account your customers can see their invoices in the Billing module',
        connect: 'Connect with',
        connectAlt: 'Connect with Stripe',
        continueSetup:
          'Your Stripe account has been connected but you need to finish setting it up',
        continueSetupButton: 'Continue setup',
        success:
          'Your Stripe account is linked and you are ready to start accepting payments in Noloco',
      },
      database: {
        title: 'What table are your membership plans related to?',
        subtitle:
          'For example, does a user pay a membership, or their organization?',
        fieldPlaceholder: 'membership',
        fieldTooltip:
          "This might be 'membership', 'subscription' or anything else. Tip: if you keep your fields lower-case it is easier to tell what is a field and what is a collection",
        save: 'Save',
        connected: 'Memberships are connected to your data',
        dataTypeLabel: 'Table',
        fieldLabel: 'Field name',
        nonUserRelation: {
          label: 'Which user field relates them to {dataType}?',
          placeholder: 'Select a field',
          link: 'Linked via',
        },
      },
      confirm: {
        explain:
          'We use Stripe to process payments. You’ll need to create a new Stripe account, or connect an existing one before you can accept payments.',
        warning:
          'You will be redirect to Stripe to connect your account, when you are done you will be brought back here',
        existing: 'Link existing account',
        confirmButton: 'Connect new account',
      },
      plans: {
        title: 'Membership plans',
        new: 'New plan',
        empty: {
          title: 'Set up free or paid memberships',
          subtitle: 'Customers can subscribe and pay via Stripe',
        },
      },
      plan: {
        new: 'New membership plan',
        disabled: 'You cannot create or edit plans in the playground',
        save: 'Create plan',
        edit: 'Edit',
        editTooltip:
          'This will open the subscription in your Stripe dashboard where you can change the name, price and interval',
        cancel: 'Cancel',
        name: {
          label: 'Membership name',
          help: 'Customers will see this on their profile and invoices',
          placeholder: 'e.g. Standard Plan',
        },
        currency: {
          label: 'Currency',
          placeholder: 'Select a currency',
        },
        amount: {
          label: 'How much does it cost?',
          help: 'You can put in 0 to create a free membership tier',
          placeholder: 'eg. 5.50',
        },
        interval: {
          label: 'Billing frequency',
          help: 'How often should your customers be charged?',
          placeholder: 'One-time',
          values: {
            'one-time': 'One-time',
            week: 'Weekly',
            month: 'Monthly',
            year: 'Yearly',
          },
        },
      },
    },
    integrations: {
      label: 'Integrations & API Keys',
      apiKeys: {
        description:
          'Your API keys allow you to access your data from other applications including Zapier and Make',
        guide: 'Learn more about your API keys',
        exploreProject: 'Explore App GraphQL schema',
        exploreUser: 'Explore Account GraphQL schema',
        project: 'App API Key',
        projectKeyCopied: 'App API key copied to clipboard',
        noProjectKey:
          'You must create at least one internal User to have an app API key',
        user: 'Account API Key',
        userKeyCopied: 'Account API key copied to clipboard',
      },
      integrations: {
        title: 'Integrations',
        guide: "Learn more about Noloco's integrations",
        connected: 'Connected',
        openAI: {
          title: 'OpenAI',
          details: 'Use OpenAI to supercharge your app with generative AI.',
          cta: 'Setup',
          reconnect: 'Reconnect',
          modal: {
            title: 'Add your OpenAI API Key',
            save: 'Save',
            cancel: 'Cancel',
            apiKey: 'Enter the new **API Key**',
            instructions: {
              details:
                'Please enter your OpenAI API key so we can get you started with generative AI features in your app.',
              link: 'Create an OpenAI API key',
            },
          },
        },
        zapier: {
          title: 'Zapier',
          details:
            'Use Zapier to sync Noloco data to your other services, or respond to events in your app.',
          cta: 'Build a Zap',
        },
        make: {
          title: 'Make (formerly Integromat)',
          details:
            'Use Make scenarios to sync Noloco data to your other services, or respond to events in your app.',
          cta: 'Build a Scenario',
        },
        stripe: {
          title: 'Stripe',
          details:
            'Connect Stripe to issue invoices and recurring payments to your clients',
          cta: 'Connect',
        },
        docsAutomator: {
          title: 'DocsAutomator',
          details:
            'Use DocsAutomator to automatically generate documents from your Noloco data',
          cta: 'Connect',
          reconnect: 'Reconnect',
          modal: {
            title: 'Add your DocsAutomator API Key',
            save: 'Save',
            cancel: 'Cancel',
            apiKey: 'Enter the new **API Key**',
            instructions: {
              details:
                'Please enter your DocsAutomator API key so we can get you started with document generation in your app.',
              link: 'Create a DocsAutomator API key',
            },
            invalidApiKey: 'Invalid API Key',
          },
        },
        airtable: {
          title: 'Airtable',
          details: 'Login with OAuth to connect an Airtable data source',
          cta: 'Connect',
          reconnect: 'Reconnect',
          connectionDetails: {
            button: 'View Accessible Bases',
            title: 'Accessible Bases',
            description: {
              part1:
                'The table below shows a list of Airtable bases connected to your app as well as any you have granted access to Noloco via OAuth. Any discrepancies here are highlighted for you to resolve, to change the tables that Noloco can access go to ',
              dashboardLink: 'your Airtable dashboard',
              part2:
                ', click on your profile picture in the top-right corner and select Integrations. Then switch to the third-party integrations tab and find Noloco. For more information see ',
              docsLink: 'the Airtable docs',
              part3: '.',
            },
            error:
              'It looks like your Noloco integration on Airtable might have been revoked. This might be because you or an admin in your workspace removed the integration, or Airtable might have automatically removed it for some reason. Reconnecting your account to Noloco should fix this.',
            confirm: 'Close',
            reconnect: 'Reconnect',
            table: {
              headers: {
                baseId: 'Base ID',
                baseName: 'Base Name',
                permissionLevel: 'Permission Level',
                connected: 'Imported to Noloco',
              },
            },
            reconfigure: {
              title: 'Action required for your Airtable integration',
              description: {
                hasOauth:
                  'Noloco needs to be reconnected to Airtable in order to apply additional permissions. This will enable us to improve the syncing experience & reduce our usage of your Airtable API quota.',
                noOauth:
                  'Noloco needs to be reconnected to Airtable before **1st February 2024** to continue syncing your data. After this date, Noloco will no longer be able to access your Airtable data due to the removal of Api Key support.',
              },
              ctaDescription:
                'Click the button below to reconnect your account.',
              button: 'Reconnect',
              remindMeLater: 'Remind me tomorrow',
            },
          },
        },
        'google-drive': {
          title: 'Google Drive',
          details: 'Connect Google Drive to connect a Google Sheet data source',
          cta: 'Connect',
          reconnect: 'Reconnect',
        },
        googleSignIn: {
          title: 'Google Sign In',
          details:
            "Setup 'Sign in With Google' for your clients to make it easier for them to access your app",
          cta: 'Setup',
          reconnect: 'Edit',
          modal: {
            title: "Setup 'Sign in with Google'",
            clientId: {
              label: 'Enter the new *Client Id*',
            },
            save: 'Save',
            cancel: 'Cancel',
            disable: 'Disable',
            instructions: {
              details:
                "To use 'Sign in With Google' you must first create a Google OAuth client, so that your customers can log in to **your** company access, instead of Noloco. You will need to follow the 6 simple steps below",
              1: '**Create** a Google Cloud Platform account (for free).',
              2: 'Go to the **Credentials** Page.',
              3: 'Click **Create credentials > OAuth client ID.**',
              4: 'Select the **Web application** application type.',
              5: 'Name your OAuth 2.0 client and click **Create** (this can be anything you want)',
              6: 'Add the following URLs as **Authorised JavaScript origins**:',
              7: '**Save** your new client',
              8: 'Copy the **Client ID** that was created and come back here and paste it in the box below.',
            },
          },
        },
        googleMaps: {
          title: 'Google Maps',
          details: 'Add a Google Maps API Key to use the map collection layout',
          cta: 'Setup',
          reconnect: 'Edit',
          modal: {
            title: 'Add a Google Maps API Key',
            save: 'Save',
            cancel: 'Cancel',
            apiKey: 'Enter the new **API Key**',
            instructions: {
              details:
                'To use the map collection layout you must first add a Google Maps API Key. This is free, and your usage will likely fall under the Google Maps free tier.',
              freeTier: 'Learn more',
              1: '**Create** a Google Cloud Platform account (for free).',
              2: 'Go to the **Google Maps Platform** > **Credentials** page.',
              3: 'On the **Credentials** page, click **Create credentials > API key**.',
              4: 'Copy the newly created API key and come back here and paste it in the box below.',
              5: 'Optionally, **restrict** your API key before publishing your app, by specifying the following domains as allowed referrers.',
            },
          },
        },
        hubspot: {
          title: 'HubSpot',
          details: 'Login with OAuth to connect your HubSpot account',
          cta: 'Connect',
          reconnect: 'Reconnect',
          modal: {
            title: 'Connect Noloco to HubSpot',
            cancel: 'Not now',
            instructions: {
              details:
                'You will be asked to authorize Noloco to access your HubSpot data.',
              connect: 'Connect',
              reconnect: 'Reconnect',
              selectScopes:
                'Select the permissions below that you will grant to Noloco',
              selectAll: 'Select all',
            },
            defaultEnabled:
              'This permission is required for Noloco integration to function',
          },
        },
        slack: {
          title: 'Slack',
          details: 'Use Slack to notify your team',
          cta: 'Connect',
          reconnect: 'Reconnect',
          modal: {
            title: 'Connect Noloco to Slack',
            cancel: 'Not now',
            instructions: {
              details:
                'Click the button below to connect Noloco to your Slack workspace. You will be asked to authorize Noloco to post messages to your slack workspace.',
            },
          },
        },
        'smart-suite': {
          title: 'SmartSuite',
          details: 'Login with OAuth to connect a SmartSuite data source',
          cta: 'Connect',
          reconnect: 'Reconnect',
          connectionDetails: {
            button: 'View Accessible Solutions',
            description:
              'The table(s) below show all of the SmartSuite solutions that Noloco can access via your OAuth integration. These are grouped into the workspace they belong in.',
            table: {
              headers: {
                connected: 'Already connected to Noloco?',
                solutionName: 'Solution',
              },
            },
            title: 'Accessible Solutions',
            reconfigure: {
              title: 'Action required for your SmartSuite integration',
              description:
                'Noloco needs to be reconnected to SmartSuite in order to keep syncing your workspace. This is due to a change in how SmartSuite authenticates Noloco as an integration.',
              ctaDescription:
                'Click the button below to reconnect your account.',
              button: 'Reconnect',
              remindMeLater: 'Remind me tomorrow',
            },
          },
        },
      },
    },
    network: {
      staticIpAddresses: {
        label: 'Noloco IP Addresses',
        help: 'The following IP addresses should be whitelisted if you gate access:',
      },
    },
    environments: {
      label: 'Data Environments',
      fields: {
        name: 'Name',
        description: 'Description',
        color: 'Color',
      },
      production: {
        name: 'Production (Default)',
        description:
          'This is your app’s default environment. All non-builder users see data from this environment when they log into the app',
      },
      new: 'New environment',
      editButton: 'Edit',
      explainer: 'Show different sets of records depending on environment',
      guide: 'Learn how to use environments',
      create: {
        title: 'Create a new environment',
        save: 'Save',
        name: {
          help: 'The name of the environment',
          placeholder: 'Staging',
        },
        description: {
          placeholder: 'Pre-production data',
        },
        error: 'Something went wrong creating your environment',
      },
      edit: {
        title: 'Edit environment - {envName}',
        save: 'Save changes to {envName}',
        error: 'Something went wrong editing {envName}',
      },
      createEdit: {
        description: {
          help: 'A description of the environment',
        },
        color: {
          help: 'A color for this environment',
        },
      },
      delete: {
        title: 'Are you sure you want to delete this environment?',
        disclaimer:
          'Deleting the *{environmentName}* environment will permanently delete all records under this environment. Your app might not function if you are still trying to use any of these records in your published app. **This cannot be undone**,',
        confirm: 'Delete environment',
        confirmName: {
          label:
            'To confirm please type **{environmentName}** in the box below',
        },
        error: 'Something went wrong deleting {envName}',
      },
      beta: 'BETA',
    },
    billing: {
      label: 'Billing & Usage',
      guide: 'Have a question about billing, explore our FAQs',
      billing: {
        title: 'Billing',
        manage: {
          label: 'Change your billing settings',
        },
        refer: {
          label: 'Earn credits by referring a friend',
          button: 'Refer and earn',
        },
      },
      plan: {
        description: 'Your Noloco plan',
        title: 'Plan',
      },
      account: {
        description: "How much you're using Noloco across all your apps.",
        title: 'Account Usage',
      },
      app: {
        description: 'Your usage of Noloco features in this specific app.',
        title: 'App Usage',
      },
    },
    support: {
      label: 'Support',
      community: {
        label: 'Join our community',
        description:
          'Noloco has a very active community where you can ask questions, learn from other members and get fast support while building your app.',
        cta: 'Join now',
      },
      guides: {
        label: 'Explore our guides',
        description:
          'Our guides explain how Noloco works and how you can get the most of it.',
        cta: 'Explore guides',
      },
      chat: {
        label: 'Chat',
        description:
          'When enabled you can use the chat widget in the corner to chat with the Noloco team while developing your app.',
      },
      academy: {
        label: 'Noloco Academy',
        description:
          'Learn more about Noloco and get on the path to becoming a certified expert.',
        cta: 'Sign up',
      },
      youtube: {
        label: 'Videos & tutorials',
        description:
          'Browse our library of tutorials that go through specific Noloco topics to help you get started',
        cta: 'Watch tutorials',
      },
      onboarding: {
        label: 'Onboarding checklist',
        description:
          'Complete your onboarding checklist to make the most of your Noloco app',
        cta: 'Open checklist',
      },
    },
  },
  leftSidebar: {
    nav: {
      elements: 'App',
      data: 'Data',
      workflows: 'Workflows',
      users: 'Users',
      settings: 'Settings',
      publish: 'Review & Publish',
    },
    layers: {
      label: 'Element tree',
      add: 'Add new element',
      clone: 'Clone',
      delete: 'Delete',
      removeIcon: 'Remove icon',
    },
    pages: {
      label: 'Page: {pageName}',
      defaultPage: 'Pages',
      addPage: 'Add page',
      addRecordPage: 'Add record page',
      newPageOptions: {
        add: 'Add to sidebar',
        addPageHere: 'Add a page here',
        collection: 'Collections',
        modules: 'Modules',
        advanced: {
          label: 'Advanced',
          folder: 'Folder',
          blank: 'Blank page',
          iframe: 'Iframe embed',
          link: 'External link',
          divider: 'Divider',
        },
        missing: {
          label: 'Something missing?',
          paragraph1:
            'Any tables that have been disabled can only be added as a view after ',
          link: 'reenabling them',
          paragraph2: '.',
        },
        search: 'Search collections, modules or advanced elements',
      },
      recordHelp:
        'A record page is a page template that creates a page for each table of that type. E.g. a page for each user',
      addPageGroup: 'Add page group',
      page: 'Page {count}',
      folder: 'Folder {count}',
      recordPage: '{dataType} Page',
      collections: 'Collections',
      apis: 'APIs',
      link: 'Link {count}',
      iframe: 'Iframe',
      toggle: 'Toggle sidebar',
    },
    data: {
      label: 'Data Sources',
      new: 'New source',
      beta: 'Beta',
      soon: 'Coming soon',
      planned: 'Roadmap',
      alreadyConnected: 'Noloco only supports one connected {source} source',
      sources: {
        [INTERNAL]: 'Noloco tables',
        [CSV]: 'Import a file',
        [AIRTABLE]: 'Airtable base',
        [API]: 'REST API',
        [POSTGRES]: 'Postgres DB',
        [GOOGLE_SHEETS]: 'Google sheets',
        [HUBSPOT]: 'HubSpot',
        [MYSQL]: 'MySQL',
        [SALESFORCE]: 'Salesforce',
        [SMART_SUITE]: 'SmartSuite',
        [FIREBASE]: 'Firebase',
        [XANO]: 'Xano',
        other: 'Looking for something else?',
      },
      disabled:
        'This collection failed to sync for over 3 hours so we are no longer trying to keep it up to date. You can re-enable it by requesting a manual data sync.',
      editCustomQuery: 'Edit query',
      permissions: 'Permissions',
      settings: 'Collection Settings',
      debugger: 'Sync Information',
      delete: 'Delete Collection',
      edit: {
        title: 'Edit',
        modalTitle: 'Edit collection details',
        name: 'Name',
        namePlaceholder: 'New collection name',
        description: 'Description',
        descriptionPlaceholder: 'Add a description for this collection',
        done: 'Done',
        error: 'Please use a unique display name for a better user experience',
      },
      disable: 'Disable Collection',
      missing: {
        intro: 'Something missing?',
        hint: "Noloco doesn't support all type of columns or tables and can't import views.",
        guides1: 'For more information and help see ',
        link: 'our guides',
        guides2: '.',
      },
      sync: {
        customQuery: {
          editor: {
            description:
              'Use this code editor to write the query that this collection will be synced from. When you are ready to verify it click the test button to see a sample of the results below.',
            debug: 'Test',
          },
          help: 'How do I create a query collection?',
          label: 'Add a query',
          namePlaceholder: 'Collection name',
          primaryKeyPlaceholder: 'Primary key',
          source: {
            description:
              'To help you build this query here is a list of the tables currently in the data source that are enabled in Noloco:',
          },
          test: {
            error:
              'Oops, looks like something went wrong when we ran the query against your database:',
          },
          save: 'Save',
        },
        data: {
          label: 'Queue data sync',
          error: 'Oops, there was an error syncing your data',
          success: {
            message: 'Data sync queued',
            text: 'Noloco has queued a data sync for this source and will process it shortly, you will receive an email when it has completed.',
          },
        },
        schema: {
          label: 'Queue schema sync',
          error: 'Oops, there was an error syncing your schema',
          success: {
            message: 'Schema sync queued',
            text: 'Noloco has queued a schema sync for this source and will process it shortly, you will receive an email when it has completed.',
          },
        },
        enable: {
          label: 'Enable collections',
        },
        update: {
          label: 'Update details',
          error: 'Oops, there was an error updating source details',
        },
        delete: {
          label: 'Delete source',
        },
        information: {
          button: 'Syncing',
          dataSyncLastFailedAt: {
            label: 'Failed Last',
          },
          dataSyncLastSucceededAt: {
            label: 'Data Last Synced',
          },
          error: 'Something went wrong!',
          failures: {
            airtable: {
              internal: {
                action:
                  'The Noloco team will monitor this and see if any action is needed. Check the Slack community for updates.',
                cta: 'Click here to check the Slack community.',
                summary:
                  'Airtable seems to be having some trouble responding to us.',
              },
              notAuthorized: {
                action:
                  'Check your Airtable integration settings in Noloco and see if any action is needed to reconnect to it.',
                cta: 'Click here to check your Airtable integration.',
                summary:
                  "Airtable wouldn't authenticate us with your credentials",
              },
            },
            recent: {
              label: 'Recent Failures',
            },
            unknown: {
              action:
                'Reach out to support if this keeps happening and we can help get you up and running again.',
              cta: 'Click here to get support in the Slack community.',
              summary: "We don't recognize this error.",
            },
            xano: {
              internal: {
                action:
                  'The Noloco team will monitor this and see if any action is needed. Check the Slack community for updates.',
                cta: 'Click here to get support in the Slack community.',
                summary:
                  'Xano seems to be having some trouble responding to us.',
              },
            },
            internal: {
              action:
                'We are already aware of this issue and are looking into it. If you continue to see it contact support.',
              cta: 'Click here to get support in the Slack community.',
              summary: 'Oops! Something went wrong on our end.',
            },
            table: {
              head: {
                failedAt: 'Failed At',
                failure: 'Failure',
              },
            },
          },
          fields: {
            button: 'Save',
            label: 'Synced fields',
          },
          fieldsLastChangedAt: {
            label: 'Fields Last Changed',
          },
          loading: 'Crunching some numbers for you...',
          status: {
            active: { label: 'Active' },
            manual: { label: 'Stopped' },
            stopped: { label: 'Stopped' },
          },
          syncNow: {
            label: 'Sync Now',
            enqueued:
              'Noloco has queued a sync for this collection and will process it shortly. The information above will update when it has completed.',
          },
          title: 'Sync Information for {display}',
        },
      },
    },
    apis: {
      label: 'External APIs',
      new: 'New API',
    },
    powered: 'Powered by',
    auth: {
      enableEditMode: 'Enable layout editing',
      edit: 'Build mode',
      undo: {
        tooltip: 'Undo',
        error: 'Nothing to undo',
      },
      editPage: 'Modify current page',
      closeSidebar: 'Close sidebar',
      redo: {
        tooltip: 'Redo',
        error: 'Nothing to redo',
      },
      desktop: {
        tooltip: 'Desktop preview',
      },
      mobile: {
        tooltip: 'Mobile preview',
      },
      change: 'View as another user',
      select: 'Select',
      inactive: 'Inactive',
      deactivated: 'This user is deactivated',
      viewingAs: 'Viewing as {name}',
      filter: 'Filter users by name, email, role or company',
      done: 'Done',
      save: 'Save',
      dropToDelete: 'Drop here to delete',
      dropToHide: 'Drop here to hide',
      dropToUnhide: 'Drop here to un-hide',
      menu: 'Menu',
      collapseMenu: 'Collapse menu',
      expandMenu: 'Expand menu',
      hiddenPages: 'Hidden pages',
      noHiddenPages: 'You have no hidden pages',
      noPages: 'Add a page',
      dropPage: 'Drop your page here to hide it',
      collapseHiddenPages: 'Collapse hidden pages',
      expandHiddenPages: 'Expand hidden pages',
      collapseAll: 'Collapse all',
      expandAll: 'Expand all',
      [elements.FORM]: 'Form',
      [elements.PAGE]: 'Page',
      [elements.RECORD]: 'Record view',
      [elements.VIEW]: 'View',
      baseRecord: 'Base record',
      blankPage: 'Blank page',
      baseForm: 'Create a record',
      header: 'Header',
    },
    editor: {
      searchComponents: 'Search components',
      empty: 'No pages found',
      noSearchResults: 'No components found',
      searchResults: 'Search results',
      sectionEditorEmptyState:
        'Click the plus icon above to add a new component',
      addSection: 'Add a new section',
      sectionOptions: {
        commonlyUsed: 'Commonly used',
        collections: {
          title: 'Collections',
        },
        title: {
          title: 'Title',
          subTitle: 'Subtitle',
        },
        actionButtons: {
          createButton: 'Create a record',
          navButton: 'Open a page',
          title: 'Action buttons',
          updateButton: 'Update a record',
        },
        notice: {
          title: 'Notice',
        },
        media: {
          gallery: 'Gallery',
          image: 'Image',
          title: 'Media',
          video: 'Video',
        },
        charts: {
          title: 'Charts',
        },
      },
    },
  },
  rightSidebar: {
    close: 'Close',
    actions: {
      label: 'Actions & Interactions',
    },
    style: {
      label: 'Element style',
    },
    properties: {
      label: 'Element configuration',
      title: 'Configuration',
      settings: {
        label: '{name} settings',
      },
      show: 'Open all properties',
    },
    visibility: {
      label: 'Visibility',
      title: 'Visibility',
      description: 'How do I choose when the element is visible?',
      authentication: {
        label: 'Authentication',
        ALL: 'All users',
        [LOGGED_IN]: 'Logged in users',
        [NOT_LOGGED_IN]: 'Non logged in users',
      },
      type: {
        label: 'User type',
        ALL: 'All users types',
        [INTERNAL]: 'Internal users',
        [CLIENT]: 'Clients',
        description: {
          ALL: 'Internal users & clients',
          [INTERNAL]: 'Members of your team',
          [CLIENT]: 'Your customers',
        },
        visibleTo: 'Visible to ',
        title: {
          ALL: 'All',
          [INTERNAL]: 'Internal',
          [CLIENT]: 'Clients',
        },
      },
      memberships: {
        label: 'Membership plans',
        ALL: 'All plans',
        [WITH_PLANS]: 'With these plans',
        [WITHOUT_PLANS]: 'Without these plans',
      },
      roles: {
        disabledWithChecked:
          'Existing visibility rules using custom roles will still function. Once removed you will be unable to re-select the role.',
        disabled: 'Custom user roles are only available on the TEAM plan.',
        label: 'User roles',
        title: 'Roles',
        ALL: 'All user roles',
        [WITH_ROLES]: 'Users with these roles',
        [WITHOUT_ROLES]: 'Users without these roles',
        compactTitle: {
          ALL: 'All',
          [WITH_ROLES]: 'With',
          [WITHOUT_ROLES]: 'Without',
        },
      },
      spaces: {
        label: 'Spaces',
        visibleTo: 'Appears in the nav bar in these spaces',
      },
      device: {
        label: 'Device',
        title: {
          [ALL_DEVICES]: 'All',
          [DESKTOP]: 'Desktop',
          [MOBILE]: 'Mobile',
        },
        description: {
          [ALL_DEVICES]: 'all users',
          [DESKTOP]: 'desktop users',
          [MOBILE]: 'mobile users',
        },
      },
      plans: 'Plans',
      custom: {
        label: 'Custom rules',
      },
    },
    data: {
      label: 'Data sources',
      description:
        "Add additional collection records or API data to this element's scope",
      add: 'Add source',
      placeholder: 'Source name',
      newName: 'Source {num}',
      options: {
        collection: {
          group: 'Collection items',
          item: 'Fetch a {dataType}',
          required: 'At least one of the following parameters are required',
        },
        apis: {
          group: 'API Requests',
          required: 'The following parameters are required',
        },
      },
    },
    states: {
      label: 'States',
      none: 'None',
      hover: 'Hovering',
      focus: 'Focusing',
      disabled: 'Disabled',
      'auth-wrapper:isLoggedIn::true': 'Logged in',
      'auth-wrapper:isLoggedIn::false': 'Logged out',
      conditions: {
        label: 'Conditions',
        new: 'New condition',
      },
    },
    stateOption: 'When',
    editor: {
      tabs: {
        charts: 'Charts',
        fields: 'Fields',
        general: 'General',
        nextTab: 'Next tab',
        options: 'Options',
        pages: 'Pages',
        prevTab: 'Prev tab',
        values: 'Values',
        visibility: 'Visibility',
      },
      page: 'Page',
      view: 'View',
      data: 'Data',
      header: 'Header',
      layout: 'Layout',
      navigation: 'Navigation',
      setup: 'Setup',
      actions: 'Actions',
      filter: {
        title: 'Filter',
        guide: 'Learn how to add filters',
      },
      addChart: 'Add chart',
      sortAndLimit: {
        title: 'Sort And Limit',
        guide: 'Learn how to sort and limit data',
      },
      emptyState: 'Empty state',
      relativeFilter: 'Relative filter',
      saveButton: {
        title: 'Save button',
        label: 'Button text',
      },
      publicForm: 'Public form',
      position: 'Position',
      filterFields: 'Filter fields',
      buttons: 'Buttons',
      folderSettings: 'Folder settings',
      folderVisibility: 'Folder visibility',
    },
  },
  conditionEditor: {
    name: {
      label: 'Condition name',
      placeholder: 'Unnamed condition',
    },
    field: {
      label: 'Field',
      placeholder: 'Field',
    },
    operator: {
      placeholder: 'Operator',
    },
    value: {
      placeholder: 'Value',
    },
    and: 'AND',
    or: 'OR',
    new: 'New rule',
    delete: 'Delete',
    save: 'Save',
  },
  validationRuleEditor: {
    rule: {
      placeholder: 'Rule',
    },
    value: {
      placeholder: 'Value',
    },
  },
  colorConditionEditor: {
    color: {
      label: 'Select Color',
      placeholder: 'Select Color',
    },
    new: 'New Color Rule',
  },
  signature: {
    placeholder: 'Drag and drop an image or scan, or click to upload',
  },
  data: {
    addVariableTooltip: 'Add dynamic text',
    toolbar: {
      bold: 'Bold (Ctrl + b)',
      italic: 'Italic (Ctrl + i)',
      underline: 'Underline (Ctrl + u)',
      code: 'Code (Ctrl + `)',
      h1: 'Header 1',
      h2: 'Header 2',
      h3: 'Header 3',
      blockquote: 'Blockquote',
      link: 'Link',
    },
    save: 'Save',
    newTitle: 'New Collection',
    editTitle: '{name} properties',
    internal: 'Noloco tables',
    api: {
      title: 'Sync data from your API',
      help: 'How do I connect my API?',
      authorize: 'Authorize',
      reauthorize: 'Reauthorize',
      create: 'Create',
      creating: 'Creating',
      next: 'Next',
      save: 'Save',
      saved: 'API saved',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'API',
        error:
          'Your API name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      baseUrl: {
        label: 'Base URL',
        help: 'Give us the base URL of your API',
        placeholder: 'https://api.example.com/',
      },
      headers: {
        help: 'If you need us to pass forward hard-coded request headers to interact with the API in the way that you want you can set these up here.',
        label: 'Request Headers',
      },
      authentication: {
        label: 'Authentication',
        help: 'If your API requires authentication from callers you can configure this here.',
        method: {
          label: 'Method',
        },
        options: {
          label: 'Options',
          [NO_AUTH]: { label: 'None' },
          [OAUTH2]: {
            label: 'OAuth2',
            help: 'OAuth 2.0 authorization allows you to grant your Noloco application access to the your protected resources in other apps, such as Google Drive, Google Sheets, etc. For server-side OAuth 2.0 authentication flow you must use the **{oauthToken}** placeholder in your query/headers to perform the request with the correct access token. A common location for this is as a header such as:',
            callbackUrl: {
              label: 'OAuth callback URL',
              placeholder: 'Available after you save the API...',
            },
            authorizationUrl: {
              label: 'Authorization URL',
              placeholder: 'https://accounts.google.com/o/oauth2/v2/auth',
            },
            accessTokenUrl: {
              label: 'Access Token URL',
              placeholder: 'https://accounts.google.com/o/oauth2/token',
            },
            clientId: {
              label: 'Client ID',
            },
            clientSecret: {
              label: 'Client Secret',
            },
            scopes: {
              label: 'Scopes (separated by spaces)',
            },
            authorize: 'Authorize',
            reauthorize: 'Reauthorize',
          },
        },
      },
      endpoints: {
        label: 'Endpoints',
        disabled: 'You can add endpoints after entering a base URL',
        tabs: {
          configuration: 'Configuration',
          headers: 'Headers',
          parameters: 'Parameters',
          pagination: 'Pagination',
          format: 'Format',
        },
        name: {
          label: 'Name',
          help: 'Give the endpoint a useful name to identify the type of data it returns.',
          placeholder: 'Endpoint Name',
        },
        test: {
          button: 'Test',
        },
        target: {
          label: 'Method & Path',
          help: 'Choose the HTTP method and enter the path to request your data from.',
          path: {
            placeholder: '/my-collection',
          },
        },
        body: {
          label: 'Request body',
          help: 'Write out the JSON structure of the body we should send with our requests.',
          placeholder: 'Enter valid JSON with variables',
        },
        headers: {
          label: 'Headers',
          help: 'Enter any request headers specific to this endpoint.',
        },
        parameters: {
          label: 'Parameters',
          help: 'Enter any URL parameters that should be included in requests.',
        },
        pagination: {
          label: 'API Pagination',
          help: "Enable this if your API doesn't return all of the records in one request.",
          methods: {
            label: 'Pagination method',
            placeholder: 'Tell us how your API is paginated',
            [CURSOR]: {
              label: 'Cursor pagination',
              help: 'Use a cursor to indicate the position of the last result retrieved.',
              instructions:
                'To setup cursor pagination add a {placeholder} placeholder to either your request parameters or in the request body. This will be substituted by the cursor when we sync your data. After that test your endpoint and select the path to the next page cursor in the result.',
            },
            [OFFSET]: {
              label: 'Offset pagination',
              help: 'Specify the starting position and number of results to retrieve for each page.',
              instructions:
                'To setup result offset (skip) pagination add a {placeholder} placeholder to either your request parameters or in the request body. This will be substituted by the number of items to skip when we sync your data.',
            },
            [PAGE]: {
              label: 'Page numbers',
              help: 'Fetch each page by its own number.',
              instructions:
                'To setup page number pagination add a {placeholder} placeholder to either your request parameters or in the request body. This will be substituted by the page number when we sync your data.',
            },
            [PATH]: {
              label: 'Path to next page',
              help: 'Specify the URL path to query for the next page.',
              instructions:
                'To setup path pagination simply test your endpoint and select the path to the next page URL path in the result.',
            },
          },
          cursorPath: {
            label: 'Where is the cursor for the next page?',
            placeholder: 'pageInfo > nextPage',
            switch: 'Use the object ID as the cursor?',
          },
          pathPath: {
            label: 'Where is the URL path for the next page?',
            placeholder: 'pageInfo > nextPath',
          },
        },
        data: {
          path: {
            label: 'Result Location',
            help: 'Tell us where in your response body we can find the array of records.',
            placeholder: 'Select the path to your records',
          },
          records: {
            label: 'Sample Data',
            help: 'Review the sample data that we found and configure the fields as needed.',
          },
          id: {
            label: 'ID Field',
            help: 'Tell us which field uniquely identifies the record.',
            placeholder: 'Select the ID field',
          },
          error: {
            cta: {
              format: 'Open Format Tab',
            },
            structure:
              'We could not automatically find your records in the API response. Tell us where they are by setting the Result Location in the Format tab.',
          },
        },
        save: 'Save',
        delete: {
          button: 'Delete',
          title: 'Delete endpoint',
          confirm: 'Delete',
          cancel: 'Cancel',
          confirmName: {
            label:
              'Deleting an endpoint is irreversible. The copy of your data in Noloco and any views you have setup will be permanently deleted. To confirm please type **{endpointName}** in the box below',
          },
        },
      },
      connecting: {
        title: 'Connecting to your API',
        subtitle: 'Analyzing your endpoints... this could take a minute',
      },
    },
    csv: {
      confirm: 'Import',
      dataType: {
        label: 'Building your Noloco table',
        help: 'We are building a Noloco table from your file.',
      },
      display: {
        label: 'Table name',
        pickName: 'Pick a Unique Name',
        dropFile: 'Drop Your File',
        help: 'Specify a name for your table',
        placeholder: 'My Imported File',
        invalid:
          'Your table name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      error:
        'Something went wrong importing your file, if this continues contact Noloco support.',
      file: {
        help: 'Please use the file dropzone below to upload a CSV file to Noloco. We will analyze the schema of the file and suggest a structure for the table that we can create from it.',
        placeholder: 'Drop a CSV file here to import it as a Noloco table',
      },
      help: 'How do I import a table from a CSV file?',
      import: {
        label: 'Importing your data',
        help: "Now we're importing your data into the new table.",
      },
      schema: {
        help: "Review the suggested table created from your file. You can change the type of any of the columns by clicking on them, as well as exclude any you don't want to import.",
      },
      title: 'Import a file',
    },
    airtable: {
      title: 'Sync data from',
      help: 'How do I connect my Airtable base?',
      next: 'Next',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'Airtable',
        invalid:
          'Your base name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      oauth: {
        label: 'Connect your Airtable account',
        button: {
          connect: 'Connect with Airtable',
          connected: 'Connected with Airtable',
        },
        base: 'Base',
        selectABase:
          'Select a base that is shared with Noloco on your OAuth integration.',
        selectCopiedBase: 'Select the base that you copied in step 1',
        selectABasePlaceholder: 'Select an accessible base',
        baseMissing: 'Why is my base missing?',
      },
      copy: {
        label: 'Copy the Airtable template base',
        noAccount: {
          question: 'Do you have an Airtable account?',
          yes: 'Yes',
          no: 'No',
          note: 'Before you get started, you will need a free Airtable account to use this template',
          createAirtableAccount: 'Create a free Airtable account',
        },

        cta: 'Copy base',
        help: 'Copy this Airtable base to your Airtable and select it from the bases list below',
        tooltip:
          "This template is powered by a connected Airtable base. You can copy this base to your Airtable account. If you don't yet have an Airtable account you can create one for free.",
      },
      refresh: 'Refresh',
      connecting: {
        title: 'Connecting to Airtable',
        subtitle: 'Analyzing your tables... this could take a minute',
      },
      error: 'Oops! Something went wrong adding your Airtable base.',
    },
    googleSheets: {
      title: 'Sync data from',
      help: 'How do I connect my Google Sheet?',
      next: 'Next',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'Google Sheet',
        invalid:
          'Your sheet name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      connectAccount: {
        label: 'Connect Noloco to your Google Account',
        button: {
          connect: 'Connect with Google',
          connected: 'Connected with Google',
        },
      },
      pickSheet: {
        label: 'Choose your Google Sheet',
        help: 'If you have reconnected Google Drive to Noloco, please re-select your sheet to grant Noloco access',
        button: {
          pick: 'Select a Google Sheet',
          picked: 'Google Sheet selected',
        },
        popup: {
          title: 'Google Sign-in will open in a new window.',
          subtitle: 'Please allow popups if prompted.',
          note: 'Do not close this window, it will close automatically once you have selected your file.',
          authenticateButtonText: 'Click to authenticate with Google',
          manualOpenInstructions:
            "If the pop up doesn't open automatically, click the button above",
        },
      },
      connecting: {
        title: 'Connecting to your Google Sheet',
        subtitle: 'Analyzing your worksheets... this could take a minute',
      },
      tabsWarnings:
        'Noloco will try to sync **every** tab within your Google Sheet, where each tab will create one collection (table) in your app.',
      error: 'Oops! Something went wrong adding your Google sheet.',
    },
    hubspot: {
      chooseObjectTypes: {
        label: 'Which HubSpot objects would you like to sync?',
        help: 'Only object types with the required permissions granted can be chosen. To adjust the permissions, click the reconfigure button above.',
        missingScope:
          'Noloco requires {scopeName} permission to access this object type. To adjust the permissions, click the reconfigure button above.',
      },
      connectAccount: {
        label: 'Connect Noloco to your HubSpot Account',
        button: {
          connect: 'Connect with HubSpot',
          reconnect: 'Reconfigure HubSpot permissions',
        },
      },
      connecting: {
        title: 'Connecting to HubSpot',
        subtitle: 'Analyzing your object types... this could take a minute',
      },
      help: 'How do I connect my HubSpot Account?',
      title: 'Sync data from',
      name: 'HubSpot',
      next: 'Next',
      builtInObjects: {
        calls: 'Calls',
        cart: 'Carts',
        commerce_payments: 'Payments',
        communications: 'Communications',
        companies: 'Companies',
        contacts: 'Contacts',
        'deals/splits': 'Deal Splits',
        deals: 'Deals',
        discount: 'Discounts',
        emails: 'Emails',
        fee: 'Fees',
        feedback_submissions: 'Feedback Submissions',
        goal_targets: 'Goals',
        invoices: 'Invoices',
        leads: 'Leads',
        line_items: 'Line Items',
        marketing_events: 'Marketing Events',
        meetings: 'Meetings',
        notes: 'Notes',
        order: 'Orders',
        owner: 'Owners',
        postal_mail: 'Postal Mail',
        products: 'Products',
        quotes: 'Quotes',
        subscriptions: 'Subscriptions',
        tasks: 'Tasks',
        tax: 'Taxes',
        tickets: 'Tickets',
        users: 'Users',
      },
      objectGroups: {
        commerce: 'Commerce',
        crm: 'CRM',
        engagements: 'Engagements',
        marketing: 'Marketing',
        users: 'Users',
      },
      scopeGroups: {
        carts: 'Carts',
        companies: 'Companies',
        contacts_and_engagements: 'Contacts and Engagements',
        custom_objects: 'Custom Objects',
        deals: 'Deals',
        feedback_submissions: 'Feedback Submissions',
        files: 'Files',
        goals: 'Goals',
        invoices: 'Invoices',
        leads: 'Leads',
        line_items: 'Line Items',
        marketing_events: 'Marketing Events',
        orders: 'Orders',
        owners: 'Owners',
        payments: 'Payments',
        products: 'Products',
        quotes: 'Quotes',
        sales_email: 'Sales Email',
        subscriptions: 'Subscriptions',
        tickets: 'Tickets',
        users: 'Users',
      },
    },
    mysql: {
      documentTitle: 'Sync data from MySQL',
      title: 'Sync data from',
      logoAlt: 'MySQL database logo',
      help: 'How do I connect my MySQL database?',
      sourceName: 'MySQL',
      next: 'Next',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'MySQL Database',
        invalid:
          'Your database name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      advancedSettings: 'Advanced Settings',
      ca: {
        label: 'CA Certificate',
        help: 'Override the default trusted CA certificates (optional).',
      },
      clientCert: {
        label: 'Client Certificate',
        help: 'Provide the client certificate in PEM format.',
      },
      clientKey: {
        label: 'Client Key',
        help: 'Provide the client key in PEM format.',
      },
      database: {
        label: 'Database name',
        help: 'This is the name of the database on your MySQL server.',
        placeholder: 'e.g. prod-db',
      },
      host: {
        label: 'Server hostname',
        help: 'This is the hostname of your MySQL server that you use to connect to it.',
        placeholder: 'e.g. mydb.123456789012.us-east-1.rds.amazonaws.com',
      },
      password: {
        label: 'Password',
        help: 'This is the password you use to log into your MySQL server.',
        placeholder: 'e.g. mysecretpassword',
      },
      port: {
        label: 'Server port',
        help: 'This is the port of your MySQL server that you use to connect to it.',
        placeholder: 'e.g. 3306',
      },
      readOnly: {
        label: 'Connect in read-only mode',
        help: 'This will disable functionality such as forms and field editing for this data source.',
      },
      selfSigned: {
        label: 'Provide self-signed certificates',
        help: 'If you need to provide us with a client certificate and key, enable this.',
      },
      user: {
        label: 'Username',
        help: 'This is the name of the user you log into your MySQL server as.',
        placeholder: 'e.g. mysql-user',
      },
      useSsl: {
        label: 'Connect using SSL',
        help: 'If you require SSL on your MySQL server toggle this on.',
      },
      connecting: {
        title: 'Connecting to MySQL',
        subtitle: 'Analyzing your tables... this could take a minute',
      },
      error: 'Something went wrong adding your MySQL database.',
    },
    postgres: {
      documentTitle: 'Sync data from Postgres',
      title: 'Sync data from',
      logoAlt: 'Postgres database logo',
      help: 'How do I connect my Postgres database?',
      sourceName: 'PostgreSQL',
      next: 'Next',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'Postgres Database',
        invalid:
          'Your database name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      advancedSettings: 'Advanced Settings',
      ca: {
        label: 'CA Certificate',
        help: 'Override the default trusted CA certificates (optional).',
      },
      clientCert: {
        label: 'Client Certificate',
        help: 'Provide the client certificate in PEM format.',
      },
      clientKey: {
        label: 'Client Key',
        help: 'Provide the client key in PEM format.',
      },
      database: {
        label: 'Database name',
        help: 'This is the name of the database on your Postgres server.',
        placeholder: 'e.g. prod-db',
      },
      host: {
        label: 'Server hostname',
        help: 'This is the hostname of your Postgres server that you use to connect to it.',
        placeholder: 'e.g. mydb.123456789012.us-east-1.rds.amazonaws.com',
      },
      password: {
        label: 'Password',
        help: 'This is the password you use to log into your Postgres server.',
        placeholder: 'e.g. mysecretpassword',
      },
      port: {
        label: 'Server port',
        help: 'This is the port of your Postgres server that you use to connect to it.',
        placeholder: 'e.g. 5432',
      },
      readOnly: {
        label: 'Connect in read-only mode',
        help: 'This will disable functionality such as forms and field editing for this data source.',
      },
      schema: {
        label: 'Database schema',
        help: 'This is the schema in your Postgres database that you want to import tables from.',
        placeholder: 'e.g. public',
      },
      selfSigned: {
        label: 'Provide self-signed certificates',
        help: 'If you need to provide us with a client certificate and key, enable this.',
      },
      user: {
        label: 'Username',
        help: 'This is the name of the user you log into your Postgres server as.',
        placeholder: 'e.g. postgres',
      },
      useSsl: {
        label: 'Connect using SSL',
        help: 'If you require SSL on your Postgres server toggle this on.',
      },
      connecting: {
        title: 'Connecting to Postgres',
        subtitle: 'Analyzing your tables... this could take a minute',
      },
      environments: {
        sectionHeader: 'Choose Environment',
        guideText: 'Learn more about environments',
        explain: ' The environment your configuration changes will apply to',
        beta: 'BETA',
      },
      error: 'Something went wrong adding your Postgres database.',
    },
    smartSuite: {
      title: 'Sync data from',
      sourceName: 'SmartSuite',
      help: 'How do I connect my SmartSuite solution?',
      next: 'Next',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'My SmartSuite Solution',
        invalid:
          'Your solution name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      noAccount: {
        question: 'Do you have a SmartSuite account?',
        yes: 'Yes',
        no: 'No',
        note: 'Before you get started, you will need a SmartSuite account',
        createSmartSuiteAccount: 'Create a SmartSuite account',
      },
      oauth: {
        label: 'Connect your SmartSuite account',
        button: {
          connect: 'Connect with SmartSuite',
          connected: 'Connected with SmartSuite',
        },
        solution: {
          description:
            'Select a solution that is shared with Noloco on your OAuth integration.',
          label: 'Solution',
          placeholder: 'Select an accessible solution',
        },
        solutionMissing: 'Why is my solution missing?',
        workspace: {
          description: 'Select the workspace your solution is in.',
          label: 'Workspace',
          placeholder: 'Select your SmartSuite workspace',
        },
      },
      refresh: 'Refresh',
      connecting: {
        title: 'Connecting to SmartSuite',
        subtitle: 'Analyzing your tables... this could take a minute',
      },
      error: 'Oops! Something went wrong adding your SmartSuite solution.',
    },
    xano: {
      title: 'Sync data from',
      help: 'How do I connect my Xano workspace?',
      next: 'Next',
      updating: 'Updating',
      name: {
        label: 'Name',
        help: 'Specify a name to help you identify your source',
        placeholder: 'Xano',
        invalid:
          'Your workspace name must contain only letters, numbers, dashes or underscores and must be unique',
      },
      connection: {
        label: 'Connect your Xano account',
        authToken: {
          label: 'Xano Personal Access Token',
          steps: {
            navigate: 'From your Xano account',
            create: 'Create a new Personal Access Token in Xano',
            permissions:
              'Noloco will need read and write permissions for your data, and at least read permissions on your schema.',
            copy: 'Copy the new personal access token',
          },
        },
        workspace: {
          label: 'Choose your Xano Workspace',
          help: 'If you are unable to choose your workspace, it is because it is already connected to this app.',
          placeholder: 'Choose a workspace',
          refresh: 'Refresh',
          error: 'There was a problem fetching your Xano workspaces.',
        },
      },
      connecting: {
        title: 'Connecting to Xano',
        subtitle: 'Analyzing your workspace tables... this could take a minute',
      },
      error: 'Oops! Something went wrong adding your Xano workspace.',
    },
    limits: {
      database: {
        title: 'Exceeding row limits - Upgrade to add records',
        description:
          'Your plan has an allowance of {limit} rows, however you are storing {usage} rows.',
      },
      synced: {
        title: 'Exceeding synced row limits - Upgrade to sync more',
        description:
          'Your plan has an allowance of {limit} rows, however you are syncing {usage} rows.',
      },
    },
    fields: {
      quickAdd: {
        label: 'Quickly add some fields to your collection',
        tooltip: 'Choose any quick-field now, or add your own fields later',
      },
      default: {
        label: 'Default fields',
        tooltip:
          'Every collection comes with the following fields by default to allow you to identify them and track when they were made',
      },
      custom: {
        label: 'Custom fields',
        tooltip:
          "You can add custom fields to a collection. For example a House might have 'name', 'address', 'beds' and 'owner' as custom fields",
      },
      new: 'New field',
      guide: 'Learn about the different fields you can add',
      label: 'Field name',
      tooltip:
        "This might be 'name', 'description', 'author', 'profile picture' or anything else. Tip: if you keep your fields lower-case it is easier to tell what is a field and what is a collection",
      cancel: 'Cancel',
      add: 'Add field',
      save: 'Save',
      bulkAdd: {
        title: 'Bulk add',
        subtitle: 'Input each of your options on a separate line',
        back: 'Back',
        add: {
          1: 'Add {context} option',
          _: 'Add {context} options',
        },
      },
      validation:
        'Name must be unique, not reserved, and can only contain letters, numbers, spaces, dashes and underscores.',
      placeholder: 'profile picture',
      relationship: {
        [relationships.ONE_TO_ONE]: '{type}',
        [relationships.MANY_TO_ONE]: '{type} collection',
        [relationships.ONE_TO_MANY]: '{type}',
        [relationships.MANY_TO_MANY]: '{type} collection',
      },
      reverse: {
        label: 'Field name on the {dataTypeNameB} collection',
        [relationships.ONE_TO_ONE]:
          'If a {parentName} record has a {relationshipName}, then a {relationshipTypeName} record has a:',
        [relationships.MANY_TO_ONE]:
          'If a {parentName} record has a {relationshipName}, then a {relationshipTypeName} record has many:',
        [relationships.ONE_TO_MANY]:
          'If a {parentName} record has many {relationshipName}, then a {relationshipTypeName} record has a:',
        [relationships.MANY_TO_MANY]:
          'If a {parentName} record has many {relationshipName}, then a {relationshipTypeName} record has many:',
      },
      primaryField: {
        set: 'Set as primary field',
      },
      edit: {
        title: 'Edit {field}',
        confirm: 'Close',
      },
      delete: {
        button: 'Delete field',
        title: 'Are you sure you want to delete this field?',
        disclaimer:
          "Deleting *{fieldName}* will permanently delete all data stored in this field. Your app might not function if you are still trying to use this field in your published app. **This can't be undone**",
        check:
          'Have you checked that you are no longer using *{fieldName}* **anywhere** in your app',
        confirm: 'Delete field',
        cancel: 'Cancel',
        confirmName: {
          label: 'To confirm please type **{fieldName}** in the box below',
        },
      },
      editType: {
        title: 'Are you sure you want to convert to this field type?',
        confirm: 'Convert',
        cancel: 'Cancel',
        body: 'Converting to this field type may clear some cell data from every record in the table.',
      },
      exitForm: {
        title: 'Are you sure you are done?',
        confirm: 'Exit',
        cancel: 'Cancel',
        body: 'Changes that you made may not be saved.',
      },
      externalSource: {
        warning: 'Are you sure you want to add a new field?',
        [AIRTABLE]:
          'You can add a new field to this collection, however, it will not sync back to your Airtable table. You may want to add it in your Airtable base instead',
        [API]:
          'You can add a new field to this collection, however, it will not sync back to your API. You may want to add it to your API endpoint instead',
        [GOOGLE_SHEETS]:
          'You can add a new field to this collection, however, it will not sync back to your Google Sheet. You may want to add it in your Google Sheet instead',
        [HUBSPOT]:
          'You can add a new field to this collection, however, it will not sync back to your HubSpot objects. You may want to add it in your HubSpot object properties instead',
        [MYSQL]:
          'You can add a new field to this collection, however, it will not sync back to your MySQL table. You may want to add it in your MySQL database instead',
        [POSTGRES]:
          'You can add a new field to this collection, however, it will not sync back to your PostgreSQL table. You may want to add it in your PostgreSQL database instead',
        [SMART_SUITE]:
          'You can add a new field to this collection, however, it will not sync back to your SmartSuite app. You may want to add it in your SmartSuite solution instead',
        [XANO]:
          'You can add a new field to this collection, however, it will not sync back to your Xano workspace. You may want to add it in your Xano database instead',
      },
      variablesNames: {
        formUrl: {
          label: 'Forms & API Input key',
          tooltip:
            "Use this key to prefill this field's value in a form by adding **?{key}=value** to your form's URL. This is also the key you use when using this field as an input in the API",
        },
      },
      error: 'Something went wrong adding your field',
    },
    dataSchema: {
      fields: {
        display: {
          help: 'This is the name of the field that will be shown in your app',
        },
        type: {
          help: "You can alter the type of the field if we didn't quite get it right",
        },
        file: {
          label: 'Convert URL to file field',
        },
        flatten: {
          label: 'Flatten nested fields into the collection',
        },
        multipleOption: {
          label: 'Convert to multiple-option field',
        },
        relate: {
          label: 'Relate this field to another collection',
        },
        include: {
          help: 'This field is not currently included in your collection',
          label: 'Include field',
        },
        exclude: {
          help: 'You can exclude this field by clicking the button below',
          label: 'Exclude field',
        },
      },
    },
    dataSources: {
      connect: {
        connected: {
          title: 'Table analysis complete',
          subtitle: {
            1: 'Found {context} table',
            _: 'Found {context} tables',
          },
        },
        selectDataTypes: {
          confirm: 'Confirm',
          title: 'Confirm which collections to import',
          subtitle:
            "Toggle off any collections you won't need in your Noloco app. If you disable larger collections that you don't need your syncs will be faster.",
          toggleAll: 'Toggle all',
        },
        finish: 'Bring me to my app',
        imported: {
          title: 'Import complete!',
          subtitle:
            'You are now ready to use your data source in Noloco. Changes to your data will be synced as quickly as possible',
        },
        importing: {
          title: 'Importing your data from {name}',
          subtitle:
            'This could take a few minutes depending on how large your data source is',
        },
        importPending: {
          title: 'Your import is working in the background',
          subtitle:
            'Your import is taking a little longer than usual. But most of your data should be ready to use in Noloco. Changes to your data will be synced as quickly as possible',
        },
        layout: {
          preBuilt: {
            title: 'Building your initial layout',
            subtitle:
              'Noloco is applying a layout to your app based on your data',
          },
          title: 'Using AI to customize your app',
          subtitle:
            'The Noloco assistant is designing the perfect layout for your app based on your data. This will only take a moment...',
          skipped:
            'To get you started faster we will just build layouts for your first 10 tables.',
        },
        userList: {
          title: 'Setup your User List',
          subtitle:
            'Does this source have a table of users that need to access your app?',
        },
      },
      delete: {
        title: 'Are you sure you want to delete this data source?',
        disclaimer:
          "Deleting the *{sourceName}* source will permanently delete all collections under this source. Your app might not function if you are still trying to use any of these collections in your published app. **This can't be undone**",
        check:
          'Have you checked that you are no longer using collections from the *{sourceName}* source **anywhere** in your app',
        confirm: 'Delete source',
        related:
          'The related collections and their fields will also be removed',
        cancel: 'Cancel',
        confirmName: {
          label: 'To confirm please type **{sourceName}** in the box below',
        },
      },
      disabledWarning: {
        title: 'Some of your collections are not syncing',
        description:
          'We are having trouble syncing some of your collections. You may want to review the errors in case there is something you can fix. Otherwise you might want to contact Noloco support.',
        review: 'Review',
        truncated: 'and {notShown} more',
      },
      update: {
        title: 'Data source updated',
        subtitle: 'Your data source details have been updated',
      },
    },
    dataTypes: {
      new: 'New table',
      guide: 'Learn more about Noloco tables',
      label: 'I want to store a...',
      tooltip:
        "This is the name of your new table. Try to use its singular name. For example AirBnb would have a 'House' table, not 'Houses'",
      placeholder: 'Book',
      aiTitle:
        'Enter the table name and description to generate a new table with AI',
      aiError: 'There was an error generating your table',
      generateMoreRows: 'Generate more rows',
      description:
        'This table will be used to store book data such as title, author, etc.',
      descriptionPlaceholder: 'Describe your table',
      cancel: 'Cancel',
      save: 'Save',
      reset: 'Reset',
      invite: 'Invite user',
      deleteItem: 'Delete',
      newItem: 'New record',
      newItemTooltip: 'Add a new {dataType} record',
      loading: 'Loading...',
      none: 'None',
      saveDisabled: {
        playground:
          'Saving new data or fields is not possible in the playground',
      },
      newItemNoFields: {
        title: 'You need to add a field',
        subtitle:
          'Before you can create a new record, you need to add some fields to your collection',
      },
      inviteReminder: {
        button: 'Send invite reminders',
        tooltip: {
          live: 'Send an invite reminder to all users who have been invited but are yet to log in to your app.',
          test: 'Enable live-mode in your app settings to allow all users to log into your app before sending invite reminders.',
        },
        confirmation: {
          title: 'Are you sure you want to send invite reminders?',
          description:
            'This will send an invite email to any user who has already been sent an invitation to your app but who has not yet logged in',
          button: 'Send reminders',
        },
        success: {
          title: 'Invitation reminders sent!',
          message: {
            1: 'Reminders sent to 1 user',
            _: 'Reminder sent to {context} users',
          },
        },
      },
      nameValidation: {
        length: 'The name has to be 3 characters or more',
        characters:
          'The name can only contain letters, numbers, spaces, dashes and underscores',
        unique: 'The name has to be unique',
        descriptionMinLength:
          'The description, if added, has to be {minLength} characters or more',
        descriptionMaxLength:
          'The description has to be {maxLength} characters or less',
        conflictingOption:
          'This option field on table {dataTypeDisplay} cannot be called {display} as it will conflict with table {conflict}.',
        conflictingRelationship:
          'This relationship field cannot be called {display} as it will conflict with {conflict}.',
        reserved: 'This is a reserved name',
        typeSafe: 'The name contains reserved name endings',
        reverseUnique:
          'The name has to be unique in the {reverseType} collection',
      },
      boolean: {
        true: 'Yes',
        false: 'No',
      },
      roles: {
        title: 'User roles',
        new: 'Add a role',
        placeholder: 'New role',
        name: 'Role name',
        save: 'Save role',
        delete: 'Delete',
        done: 'Done',
        default: 'Default',
        newDisabled:
          'Custom user roles are only available on the TEAM plan. Consider upgrading to define custom user roles',
        empty: 'You have not created any roles yet',
        guide: 'Learn more about how to setup and use user roles',
        explanation:
          'Users roles allow you to control access to pages and elements that users can view, edit and delete. You can also use permissions and rules to control which data collections users can read, create and update.',
        internal: {
          label: 'Team member',
          help: 'Users with this role will be treated as part of your team, and will have a team-view of modules like Billing and file-sharing.',
        },
        dataAdmin: {
          label: 'Access all data',
          help: 'Users with this role will be able to read and update all data across all collections',
        },
        builder: {
          label: 'Modify app',
          help: 'Users with this role will be able to modify the app layout, add views and collections',
        },
        id: 'The role ID can be used in the API or Zapier Integrations',
      },
      delete: {
        title: 'Are you sure you want to delete this data collection?',
        disclaimer:
          "Deleting the *{typeName}* collection will permanently delete all data stored in this collection. Your app might not function if you are still trying to use this collection in your published app. **This can't be undone**",
        check:
          'Have you checked that you are no longer using the *{typeName}* collection **anywhere** in your app',
        confirm: 'Delete collection',
        related: 'The related fields will also be removed',
        cancel: 'Cancel',
        confirmName: {
          label: 'To confirm please type **{typeName}** in the box below',
        },
      },
      disable: {
        title: 'Are you sure you want to disable this data collection?',
        disclaimer:
          "Disabling the *{typeName}* collection will stop it from syncing into your app and remove it from your data source list. Your app might not function if you are still trying to use this collection in your published app. **This can't be undone**",
        check:
          'Have you checked that you are no longer using the *{typeName}* collection **anywhere** in your app?',
        confirm: 'Disable collection',
        related: 'The related fields will also be removed',
        cancel: 'Cancel',
        confirmName: {
          label: 'To confirm please type **{typeName}** in the box below',
        },
      },
      enable: {
        title: 'Enable collections',
        subTitle:
          'Re-enabling a collection will sync the data in that collection to Noloco and allow you to use it in your app',
        noCollectionFound: "This source doesn't have any disabled collections.",
        toggleCollection: 'Choose the collections you would like to re-enable.',
        syncTimedOut: {
          title: 'Data sync timed out',
          subTitle:
            "The sync is taking longer than expected. You will receive an email when it's completed.",
        },
        done: 'Done',
      },
      workflows: {
        title: 'Workflows',
        state: {
          on: 'On',
          off: 'Off',
        },
        guide: 'How can workflows automate my app?',
        emptyState: {
          heading: 'Automate your app',
          button: 'Create a workflow',
        },
        name: {
          placeholder: 'Workflow name',
        },
        newName: '{dataType} workflow #{num}',
        filter: 'Filter by collection',
        dataTypeCollections: {
          help: 'For each {dataType}',
        },
        clone: 'Clone workflow',
        delete: {
          button: 'Delete workflow',
          cancel: 'Cancel',
          confirmDelete: 'Confirm delete?',
          confirm: 'Delete workflow',
          disclaimer:
            "Deleting *{workflowName}* will permanently delete the workflow. If you rely on this workflow to perform any automation it will no longer run. **This can't be undone**",
          check:
            'Have you checked that you are no longer using *{workflowName}* **anywhere** for automations?',
          confirmName:
            'To confirm please type **{workflowName}** in the box below',
          title: 'Delete workflow',
        },
        history: {
          button: 'Run history',
          execution: {
            duration: 'Ran in',
            end: {
              none: 'No runs in the past 30 days',
              some: 'You are limited to 30 days of history',
            },
            failureReason: 'Failure reason',
            successful: {
              true: 'Completed successfully',
              false: 'Failed',
              '': 'In progress',
            },
            title: 'Individual runs',
            trigger: 'Triggered by',
          },
          histogram: {
            yAxis: 'Number of runs',
          },
          statistics: {
            tasksAutomated: 'Tasks Automated',
            tasksFailed: 'Failures',
            title: 'Activity over the past 30 days',
          },
          title: 'Workflow {name} run history',
        },
        test: {
          button: 'Test workflow',
          destructive: {
            message:
              'One or more actions in this workflow are destructive and may delete records. Make sure the trigger record you use to test the workflow will not cause data to be deleted that you still need. There is no way to recover it.',
            title: 'This workflow has destructive actions',
          },
          duration: 'Ran in {duration}',
          result: {
            true: 'Completed successfully',
            false: 'Failed',
          },
          title: 'Test workflow {name}',
          trigger: {
            button: 'Test',
            description:
              'The workflow test run will use this record as if it had been triggered by it. You can search through all records in the collection to find one that would be the best test trigger.',
            label: 'Pick a record to trigger the workflow',
            placeholder: 'Choose a record to test',
          },
        },
        user: {
          label: 'User',
          [CREATE]: 'The user who created the record',
          [UPDATE]: 'The user who updated the record',
          [DELETE]: 'The user who deleted the record',
          [ON_DEMAND]: 'The user who triggered the workflow',
          [ADD_COMMENT]: 'The user who added the comment',
        },
        description: {
          label: 'Description',
          placeholder: 'Add a description for your workflow...',
        },
        trigger: {
          label: 'Trigger',
          save: 'Save',
          [CREATE]: {
            label: 'When a *{dataType}* record is created',
            help: 'The workflow will run when a new *{dataType}* record is created/added',
            step: 'The new {dataType} record',
          },
          [UPDATE]: {
            label: 'When a *{dataType}* record is updated',
            help: 'The workflow will run when an existing *{dataType}* record is updated/changed',
            step: 'The changed {dataType} record',
            fields: {
              label: 'Fields to watch',
              placeholder: 'Choose fields to watch',
              help: 'Only updates to the selected fields will run this workflow. If no fields are selected this workflow will run on every update',
            },
          },
          [DELETE]: {
            label: 'When a *{dataType}* record is deleted',
            help: 'The workflow will run when an existing *{dataType}* record is deleted',
            step: 'The deleted {dataType} record',
          },
          [ON_DEMAND]: {
            label: 'On demand, triggered by an action button',
            help: 'The workflow will run when an action button is pressed',
            step: 'The source {dataType} record',
          },
          [ADD_COMMENT]: {
            label: 'When a comment is added to a *{dataType}* record',
            help: 'The workflow will run when a new comment is added',
            step: 'The source {dataType} record',
            scope: {
              label: 'Trigger comment',
              help: 'The new comment',
            },
          },
        },
        action: {
          add: 'Add action',
          label: 'Action',
          placeholder: 'What do you want to happen?',
          step: 'Step {step}',
          done: 'Done',
          editingButtons: {
            edit: 'Edit Description',
            delete: 'Delete',
          },
          addNotePlaceholder: 'Add a note for your workflow...',
          filter: {
            true: 'Continued',
            false: 'Stopped',
          },
          forEach: {
            iterations: 'Ran {iterations} times',
          },
          succeeded: 'Succeeded',
        },
        actions: {
          OPEN_AI: {
            label: "Build workflows with OpenAI's powerful GPT models",
            connect: {
              label: 'Enter your OpenAI API key',
              button: 'Connect',
            },
            model: {
              label: 'Model',
              'GPT-3': {
                label: 'GPT-3.5',
              },
              [GPT_4_MODEL]: {
                label: 'GPT-4',
              },
              [GPT_4_O_MODEL]: {
                label: 'GPT-4o',
              },
              [GPT_4_O_MINI_MODEL]: {
                label: 'GPT-4o mini',
              },
            },
          },
          SLACK: {
            label: 'Send messages to Slack',
            connect: {
              label: 'Connect Noloco to Slack to send messages',
              button: 'Add to Slack',
            },
          },
          [OPEN_AI_CHAT_COMPLETION]: {
            label: 'Send a question or instruction to ChatGPT',
            prompt: {
              label: 'The question or instruction to send to ChatGPT',
              placeholder: 'Write a poem about no-code tools',
            },
            response: {
              label: "ChatGPT's response to your question or instruction",
            },
          },
          [OPEN_AI_CORRECT_GRAMMAR]: {
            label: 'Correct any English grammar mistakes',
            disabled:
              'Grammar correction is only available on the Business plan',
            prompt: {
              label: 'The body of text to check for grammatical errors',
              placeholder: 'She no read that email',
            },
            response: {
              label:
                'Standard English text with grammatical mistakes corrected',
            },
          },
          [OPEN_AI_KEYWORD_EXTRACTION]: {
            label: 'Extract keywords from some text',
            disabled:
              'Keyword extraction is only available on the Business plan',
            prompt: {
              label: 'The text to extract keywords from',
              placeholder:
                'A long piece of text which could be broken down into keywords',
            },
            response: {
              label: 'The list of keywords identified by AI',
            },
          },
          [OPEN_AI_SENTIMENT_ANALYSIS]: {
            label: 'Analyse the sentiment of a piece of text',
            disabled:
              'Sentiment analysis is only available on the Business plan',
            prompt: {
              label: 'The text to determine the sentiment of',
              placeholder:
                'A piece of text which should be classified into positive, negative or neutral sentiment',
            },
            response: {
              label: 'The sentiment determined by AI',
            },
          },
          [OPEN_AI_SUMMARIZATION]: {
            label: 'Summarize a long block of text',
            prompt: {
              label: 'The text to generate a summary for',
              placeholder: 'A long piece of text which should be summarized',
            },
            response: {
              label: 'The summary generated by AI',
            },
          },
          [OPEN_AI_TEXT_COMPLETION]: {
            label: 'Finish some text that I started writing',
            disabled: 'Text completion is only available on the Business plan',
            prompt: {
              label: 'The text to complete',
              placeholder: 'The start of a longer piece of text to finish',
            },
            response: {
              label: 'The full text completed by AI',
            },
          },
          [SEND_EMAIL]: {
            label: 'Send an email',
            to: {
              label: 'To',
              placeholder: 'eg example@noloco.io',
            },
            cc: {
              label: 'CC (optional)',
              placeholder: 'eg example@noloco.io',
            },
            bcc: {
              label: 'BCC (optional)',
              placeholder: 'eg example@noloco.io',
            },
            multipleAddresses: 'Separate multiple addresses with commas.',
            subject: {
              label: 'Subject',
              placeholder: 'Your email subject',
            },
            message: {
              label: 'Message',
              placeholder: 'Your message body',
            },
            ctaText: {
              label: 'Call-to-Action Text (optional)',
              placeholder: 'Click here',
            },
            ctaUrl: {
              label: 'Call-to-Action URL (optional)',
              placeholder: 'https://example.com',
            },
            attachments: {
              label: 'Attachments (Files)',
              placeholder: 'Choose a file field',
            },
          },
          [SEND_NOTIFICATION]: {
            label: 'Send a push notification',
            icon: {
              label: 'Icon',
              link: 'Link',
              placeholder: 'Enter an icon URL',
              selectIcon: 'Select an icon',
            },
            message: {
              label: 'Message',
              placeholder: 'Your message body',
            },
            link: {
              label: 'Link (optional)',
              placeholder: 'https://yourapp.com/',
            },
            ctaText: {
              label: 'Link Text',
              placeholder: 'Click here',
            },
            users: {
              label: 'Users',
              placeholder: 'Choose users',
            },
          },
          [SLACK_USER_NOTIFICATION]: {
            label: 'Send a Slack message to a user',
          },
          [SLACK_CHANNEL_NOTIFICATION]: {
            label: 'Send a Slack message to a channel',
            channels: {
              label: 'Channels',
              placeholder: 'Choose a channel',
              refresh: 'Refresh',
            },
          },
          [FILTER]: {
            label: 'Only continue if...',
            and: {
              label: 'And',
              title: 'Only continue if...',
            },
            or: {
              label: 'Or',
              title: 'Or continue if...',
            },
            conditions: {
              field: 'Choose a field',
              operator: 'Choose a condition',
              value: 'Enter a value',
            },
          },
          [FOR_EACH]: {
            label: 'For each item in a list... do...',
            disabled: 'You can not nest For each actions',
            dataList: {
              label: 'List',
              placeholder: 'Choose a list',
            },
            children: {
              add: 'Add for each step',
            },
          },
          [WEBHOOK]: {
            label: 'Trigger a webhook (eg. Zapier, Make)',
            description:
              "Webhooks are used to trigger an automation in another system when something happens. *{dataTypeText}* will be included as the hook's message—or payload",
            url: {
              label: 'Webhook url',
              placeholder: 'https://hooks.zapier.com/.../abcdefg/',
            },
          },
          MUTATION: {
            dataType: {
              label: 'Collection',
              placeholder: 'Choose a collection',
            },
            fields: {
              label: 'Fields',
              remove: 'Remove field',
              add: 'Add field',
            },
            workflowWarning: {
              title:
                'Note: other workflows will not run as a result of this action',
              subtitle:
                'When this workflow action is run, it will not trigger any other workflows to run.',
            },
          },
          [CREATE_RECORD]: {
            label: 'Create a record',
          },
          [UPDATE_RECORD]: {
            label: 'Update a record',
            recordId: {
              label: 'Record ID',
              help: 'ID of the record to update.',
              placeholder: 'Choose a record to update by ID',
            },
          },
          [DELETE_RECORD]: {
            label: 'Delete a record',
            recordId: {
              label: 'Record ID',
              help: 'ID of the record to delete.',
              placeholder: 'Choose a record to delete by ID',
            },
          },
          [DEACTIVATE_USER]: {
            label: 'Deactivate a user',
            userId: {
              label: 'User ID',
              help: 'ID of the user to deactivate.',
              placeholder: 'Choose a user to deactivate',
            },
          },
          [SEND_INVITATION_EMAIL]: {
            label: 'Send an invitation email',
            userId: {
              label: 'User ID',
              help: 'ID of the user to send an invitation to.',
              placeholder: 'Choose a user to send an invitation to',
            },
          },
          [CREATE_COMMENT]: {
            label: 'Add a comment to a record',
            recordId: {
              label: 'Record ID',
              help: 'ID of the record to comment on.',
              placeholder: 'Choose a record to comment on',
            },
            authorId: {
              label: 'Comment author (optional)',
              placeholder: 'The author of the comment',
            },
            text: {
              label: 'Comment text',
              placeholder: 'Your comment text',
            },
            markdown: 'This field supports markdown.',
          },
          [CREATE_NOTE]: {
            label: 'Add a note to a record',
            recordId: {
              label: 'Record ID',
              help: 'ID of the record to write the note on.',
              placeholder: 'Choose a record to write the note on',
            },
            authorId: {
              label: 'Note author (optional)',
              placeholder: 'The author of the note',
            },
            text: {
              label: 'Note text',
              placeholder: 'Your note text',
            },
            markdown: 'This field supports markdown.',
          },
          [DOCS_AUTOMATOR_CREATE]: {
            label: 'Generate a document with DocsAutomator',
            connect: {
              label: 'Enter your DocsAutomator API key',
              button: 'Connect',
            },
            sortLineItems: 'Sort line items by',
            placeholderPrompt: 'Now fill in your placeholder values',
            placeholderLineItems: 'Line Items',
            automationSelect: 'Select Automation',
            automationError: 'Error in fetching your document automations',
            placeholderError: 'Error in fetching your placeholder fields',
            placeholderValueFiller: 'Placeholder value for {value}',
            placeholderFileName: 'File name',
            fileName: 'Enter the file name you want to save the document as',
            docsAutomatorFile: {
              label: 'DocsAutomator Generated File',
            },
            imageHelp:
              'To use an image placeholder you need to pass forward the URL of the file. If it is a Noloco file field, click into the field and choose the URL sub-field.',
            refreshAutomations: 'Refresh',
            refreshPlaceholders: 'Refresh',
            orphaned: {
              button: 'Fix all',
              warning:
                'Some of your configured placeholders no longer exist in your DocsAutomator automation.',
            },
            multiTable: {
              disabled: 'Unable to configure items for additional tables',
              warning:
                'Your DocsAutomator automation is configured to use data from multiple tables. This is not currently supported in Noloco.',
            },
          },
        },
      },
      error: {
        field: "Oops! Something went wrong saving your field '{fieldName}'",
      },
    },
    permissions: {
      label: 'Permissions',
      title: '{dataType} Permissions',
      description: 'Description',
      guide: 'Learn more about permissions',
      enabled: "Access to '{dataType}' is currently restricted by permissions.",
      enable: {
        title: 'Enable permissions to restrict access to {dataType} records',
        subtitle:
          'If you want to control what data all or some users can access, enable permissions and create permission rules.',
        button: 'Enable permissions',
      },
      namePlaceholder: '{dataType} Permission',
      new: {
        name: '{dataType} Permission {count}',
        button: 'New',
      },
      dataAdmins:
        '{roles} users have access to read and update all collections',
      rolesWithoutPermissions:
        '{roles} users **do not** have access to any of these records',
      read: {
        label: 'Read',
      },
      create: {
        label: 'Create',
        tooltip: 'Users can create records',
      },
      update: {
        label: 'Update',
        tooltip: 'Users can update existing records',
      },
      roles: {
        tooltip: 'This rule applies to these roles',
        label: 'Apply these permissions to users with the following roles...',
        placeholder: 'Choose one or more roles',
      },
      filter: {
        tooltip: 'Record filter',
        label: 'Should these users have access to all records?',
        some: 'Only some records are shown',
        help: {
          all: "Records won't be filtered. These users will have access to all records",
          filter: 'Users can only access records if...',
        },
        filterField: {
          placeholder: 'Choose a field',
          record: 'The record',
        },
        userField: {
          placeholder: 'matches a User field...',
          record: 'the user',
        },
        matches: 'matches',
        isOneOf: 'is one of',
        includes: 'includes',
        all: 'All records',
      },
      search: 'Search...',
      fields: {
        field: 'Field',
      },
      fieldPermissionsLocked:
        'Field permissions are only available on the {plan} plan.',
      delete: 'Delete',
      deleteDisabled:
        'There must be at least one rule. Consider disabling permissions instead.',
      deleteError: 'Oops! There was a problem deleting your permission rule',
      clone: 'Clone',
      cloneError: 'Oops! There was a problem cloning your permission rule',
      save: 'Save',
      error: 'Oops! There was a problem saving your permission rule',
      invalid:
        'The permission rule is invalid. Make sure all fields are complete',
      missingFieldsWarning: {
        title: 'There are incomplete permission rules',
        description:
          'Some of your collections have permissions enabled but have fields without any permission rules which can lead to confusing behavior.',
        fix: 'Fix',
        rule: {
          title: 'There are new fields without rules',
          description:
            'There are fields in this collection that have no field permission rules which can lead to confusing behavior. To fix this, review and save your permission rules',
          field: "This field's rule has not yet been saved",
        },
        truncated: 'and {notShown} more',
      },
    },
    typeInput: {
      basic: 'Basic types',
      relations: 'Link to another record',
      label: 'Choose a field type',
      placeholder: 'Select a type',
      aiGeneration: {
        label: 'AI Powered',
        help: 'Generate a value with AI based on other fields in the same record',
        outputType: 'Output type',
      },
      formula: {
        label: 'Formula',
        help: 'Calculate a value for each record from fields in the same record',
        outputType: 'Formula output type',
      },
      lookup: {
        label: 'Lookup',
        help: 'A lookup field displays data from related records',
      },
      rollup: {
        label: 'Rollup',
        help: 'A rollup field summarizes data from related records',
      },
      fileLabel: 'File/Upload',
    },
    changeDisplayInput: {
      label: 'Name',
      error: 'There was a problem changing the field name',
    },
    changeTypeInput: {
      label: 'Type',
      error: 'There was a problem changing the field type',
    },
    relationshipInput: {
      label:
        "How does '{fieldName}' relate a {dataTypeNameA} to a {dataTypeNameB}?",
      placeholder: 'Select a relationship',
      isMultiFile: 'Allow multiple files?',
      isMulti: 'Allow linking to multiple records?',
      isReverseMulti:
        'Allow {dataTypeNameB} records to link to multiple {dataTypeNameA} records?',
    },
    options: {
      label: 'Options',
      button: 'Edit options',
      new: 'New option',
      invalid:
        'Options can only contain letters, numbers, spaces, dashes and underscores',
      edit: {
        done: 'Done',
        cancel: 'Cancel',
        title: "Edit '{fieldName}' options",
        disclaimer:
          'If you remove an option, any record with that value will be blank. Renaming an option will update all existing options to have that new value',
        add: 'Add new',
        placeholder: 'Add a new option',
        error: 'There was an error saving your option',
        bulkError: 'There was an error saving your options changes',
      },
    },
    allowNegative: {
      label: 'Allow negative numbers?',
      tooltip: 'Will some values of this field be negative?',
    },
    uniqueInput: {
      label: 'Is this field unique?',
      tooltip:
        'Will every value of this field be unique, for example, a username or email address?',
    },
    aiGeneration: {
      guide: 'Learn more about AI powered fields',
      operation: {
        label: 'Operation',
        tooltip:
          'The operation to perform on the inputted field data to generate a value',
        CLASSIFY: 'Classify',
        SUMMARIZE: 'Summarize',
        SENTIMENT: 'Sentiment',
        CHAT_PROMPT: 'Chat prompt',
        CORRECT_GRAMMAR: 'Correct grammar',
        KEYWORD_EXTRACTION: 'Keyword extraction',
      },
      lazyEvaluationDisclaimer:
        'AI fields are only calculated after the referenced fields are updated',
      prompt: {
        CLASSIFY: {
          label: 'Data to classify',
          placeholder:
            'Provide the information relevant to classifying this record using record fields or text',
          tooltip:
            'The data that will be used to generate a classification. The model will be instructed to generate a classification & assign one based the fields options',
        },
        SUMMARIZE: {
          label: 'Data to summarize',
          placeholder:
            'Provide the information to summarize using record fields or text',
          tooltip:
            'The data that will summarized. The model will be instructed to generate a summary of the provided text',
        },
        SENTIMENT: {
          label: 'Data to analyze',
          placeholder:
            'Provide the information to analyze using record fields or text',
          tooltip:
            'The data that will be analysed. The model will be instructed to perform sentiment analysis and assign a sentiment value',
        },
        CHAT_PROMPT: {
          label: 'Prompt',
          placeholder:
            'Enter a prompt using record fields or text. The AI model will already be aware of the configured field type',
          tooltip:
            'The instructions provided to the AI model to generate a response. The model will be instructed to generate a response based on the prompt and field configuration',
        },
        CORRECT_GRAMMAR: {
          label: 'Text to correct',
          placeholder:
            'Provide the text to correct using record fields or text',
          tooltip:
            'The text that requires grammar correction. The model will be instructed to correct the grammar of the provided text',
        },
        KEYWORD_EXTRACTION: {
          label: 'Text to extract keywords from',
          placeholder:
            'Provide the text to extract a list of keywords from using record fields or text',
          tooltip:
            'The text to extract keywords from. The model will be instructed to generate a list of keywords',
        },
      },
      providedType: {
        label: 'Output type',
        tooltip:
          'The type of data that the AI model will generate for this field',
      },
      sentiment: {
        negative: 'Negative',
        neutral: 'Neutral',
        positive: 'Positive',
      },
    },
    autoRelationship: {
      label: 'Automatically link these records?',
      tooltip:
        'Automatically link records between these two collections based on matching values in each collection. E.g. link a user by email address',
      sourceField: {
        label: 'When',
        placeholder: 'Choose a field',
      },
      lookupField: {
        label: 'Matches',
        placeholder: 'Choose a field',
      },
    },
    lookups: {
      guide: 'Learn more about lookups',
      sourceField: {
        label: 'Select lookup source field',
        tooltip:
          'The field on this table that links the records you want to lookup data from',
        placeholder: 'Choose a source field',
      },
      valueField: {
        label: '{sourceTypeName} field you want to look up',
        tooltip:
          'The field on the source table that has the data you want to lookup',
        placeholder: 'Choose a value field',
      },
    },
    rollups: {
      relatedField: {
        label: 'Related field',
        tooltip:
          'The field on this table that links the records you want to summarize',
        placeholder: 'Choose a related field',
      },
      field: {
        label: '{dataType} field that you would like to rollup',
        tooltip:
          'This is the field on the {dataType} collection that you will summarize (sum, average, etc...)',
        placeholder: 'Choose a {dataType} field',
      },
      aggregation: {
        label: 'Aggregation type',
        tooltip: 'This decides how you summarize your fields',
        placeholder: 'Choose an aggregation',
        options: {
          [aggregationTypes.SUM]: {
            label: 'SUM',
            help: 'The sum of all non-empty numeric values',
          },
          [aggregationTypes.COUNT]: {
            label: 'COUNT',
            help: 'The number of all non-empty values',
          },
          [aggregationTypes.MAX]: {
            label: 'MAX',
            help: 'The largest of all the non-empty numeric values',
          },
          [aggregationTypes.MIN]: {
            label: 'MIN',
            help: 'The smallest of all the non-empty numeric values',
          },
          [aggregationTypes.AVERAGE]: {
            label: 'AVERAGE',
            help: 'The average of all the non-empty numeric values',
          },
          [aggregationTypes.CONCATENATE]: {
            label: 'CONCATENATE',
            help: 'Join all the text values into a single text value',
          },
          [aggregationTypes.OR]: {
            label: 'OR',
            help: 'True if any of the values are true',
          },
          [aggregationTypes.AND]: {
            label: 'AND',
            help: 'True if all of the values are true',
          },
        },
      },
    },
    relationships: {
      [relationships.ONE_TO_ONE]: {
        label: 'One to one',
        itemA: '*{itemA}* records can have only one *{itemB}* value each',
        itemB:
          '*{itemB}* records can be associated with only one *{itemA}* value each',
      },
      [relationships.ONE_TO_MANY]: {
        label: 'One to many',
        itemA: '*{itemA}* records can have multiple *{itemB}* values',
        itemB:
          '*{itemB}* records can be associated with only one *{itemA}* value each',
      },
      [relationships.MANY_TO_ONE]: {
        label: 'Many to one',
        itemA: '*{itemA}* records can have only one *{itemB}* value each',
        itemB: '*{itemB}* records can be associated with many *{itemA}* values',
      },
      [relationships.MANY_TO_MANY]: {
        label: 'Many to many',
        itemA: '*{itemA}* records can have multiple *{itemB}* values each',
        itemB: '*{itemB}* records can be associated with many *{itemA}* values',
      },
      error: 'There was a problem updating your linked field',
    },
    page: {
      label: 'Page',
    },
    types: {
      [dataTypes.TEXT]: {
        label: 'Text',
      },
      [dataTypes.DATE]: {
        label: 'Date',
      },
      [dataTypes.INTEGER]: {
        label: 'Number (Integer)',
      },
      [dataTypes.DECIMAL]: {
        label: 'Number (Decimal)',
      },
      [dataTypes.DURATION]: {
        label: 'Duration',
      },
      [dataTypes.FILE_TYPE]: {
        label: 'File type',
      },
      [dataTypes.BOOLEAN]: {
        label: 'Boolean (Yes/No)',
      },
      [dataTypes.SINGLE_OPTION]: {
        label: 'Single option select',
      },
      [dataTypes.MULTIPLE_OPTION]: {
        label: 'Multiple option select',
      },
      [FILE]: {
        label: 'File',
      },
      [dataTypes.OBJECT]: {
        [ADDRESS]: {
          label: 'Street address',
          street: {
            label: 'Street',
          },
          suiteAptBldg: {
            label: 'Suite / Apt. / Building',
          },
          city: {
            label: 'City',
          },
          stateRegion: {
            label: 'State / Region',
          },
          postalCode: {
            label: 'Postal Code',
          },
          country: {
            label: 'Country',
          },
          latitude: {
            label: 'Latitude',
          },
          longitude: {
            label: 'Longitude',
          },
        },
        [COORDINATES]: {
          label: 'Map coordinates (Latitude/Longitude)',
          latitude: {
            label: 'Latitude',
          },
          longitude: {
            label: 'Longitude',
          },
        },
        [DUE_DATE]: {
          label: 'Due date',
          from: {
            label: 'Start (optional)',
          },
          to: {
            label: 'Due',
          },
          overdue: {
            label: 'Overdue',
          },
          complete: {
            label: 'Complete',
          },
          updatedAt: {
            label: 'Updated at',
          },
          status: {
            complete: 'Completed on time',
            completeOverdue: 'Complete but was overdue',
            inProgress: 'In progress',
            overdue: 'Is overdue',
          },
        },
        [DATE_RANGE]: {
          label: 'Date range',
          from: {
            label: 'From',
          },
          to: {
            label: 'To',
          },
        },
        [FULL_NAME]: {
          label: 'Full name',
          title: {
            label: 'Title',
          },
          first: {
            label: 'First Name',
          },
          middle: {
            label: 'Middle Name',
          },
          last: {
            label: 'Last Name',
          },
        },
        [PHONE_NUMBER]: {
          label: 'Phone number',
          country: {
            label: 'Country Code',
          },
          number: {
            label: 'Number',
          },
          extension: {
            label: 'Extension',
          },
        },
      },
    },
    typeOptions: {
      format: {
        label: 'Format',
        placeholder: 'Choose a format',
        default: 'Default',
        [TEXT]: {
          [EMAIL_FORMAT]: 'Email address',
          [IP_ADDRESS_FORMAT]: 'IP address',
          [MULTILINE_TEXT]: 'Long text',
          [SINGLE_LINE_TEXT]: 'Single line',
          [URL_FORMAT]: 'URL',
        },
        [DECIMAL]: {
          [CURRENCY]: 'Currency',
          [PERCENTAGE]: 'Percentage',
          [UNFORMATTED_NUMBER]: 'Unformatted',
        },
        [INTEGER]: {
          [CURRENCY]: 'Currency',
          [RATING]: 'Rating',
          [UNFORMATTED_NUMBER]: 'Unformatted',
          [SLIDER]: 'Slider',
        },
        [DATE]: {
          [DATE_FORMAT]: 'Date',
          [DATE_TIME]: 'Date & time',
        },
        [DURATION]: {
          [TIME]: 'Time',
          [HOURS_MINUTES]: 'Hours and minutes',
          [MINUTES_SECONDS]: 'Minutes and seconds',
        },
      },
      precision: {
        default: 'Default',
        label: 'Precision',
        placeholder: 'Choose a precision',
      },
      symbol: {
        label: 'Symbol',
        placeholder: 'Currency symbol',
      },
      formula: {
        label: 'Formula',
        placeholder: 'Enter a formula',
        processing: 'Processing formula changes',
        guideText: 'Learn more about formulas',
        AI: {
          generateButton: 'Generate',
          beta: {
            badge: 'Beta',
            tooltip: 'This feature is in beta and may not be fully accurate',
          },
          placeholder: 'Describe your formula...',
          placeholderChange: 'Describe any other changes...',
          feedback: {
            label: 'How did the AI assistant do?',
            success: 'Thank you for your feedback!',
            placeholder: 'Tell us how the AI assistant did...',
          },
        },
      },
      maxCharacters: {
        label: 'Limit character entry',
        placeholder: 'Maximum characters in field',
      },
      maxRating: {
        label: 'Max value',
      },
      time: {
        label: 'Include time with date values',
      },
      timeZone: {
        label: 'Use a consistent time zone (GMT)',
      },
      slider: {
        min: 'Min value',
        max: 'Max value',
        step: 'Step',
      },
      prefix: {
        label: 'Prefix',
        placeholder: 'Prefix value',
      },
      suffix: {
        label: 'Suffix',
        placeholder: 'Suffix value',
      },
      [FULL_NAME]: {
        middle: 'Include middle name',
        title: 'Include title',
      },
      multiple: {
        label: 'Allow multiple values',
        addTooltip: 'Add another value',
        removeTooltip: 'Remove this value',
      },
    },
    emptyState: {
      title: "There's nothing here",
      subtitle: 'Get started by creating your first record in this collection',
      button: 'Create your first record',
    },
    errorState: {
      title: 'Oops! Something went wrong',
    },
    filter: {
      guide: 'Learn more about filters',
      cancel: 'Cancel',
      label: 'Filter',
      apply: 'Apply',
    },
    sort: {
      label: 'Sort',
      apply: 'Apply',
    },
    notFound: "We couldn't find this record",
    invalid: 'Invalid item',
    unlinkRelatedRecord: {
      button: 'Unlink record',
    },
    import: {
      button: 'Import',
      title: 'Import from CSV',
      subtitle:
        'Upload your CSV here, you can map your columns to the fields in the next step. Or ',
      placeholder: 'Drag and drop a CSV or click to upload',
      summary: {
        file: 'File',
        rows: 'Rows',
        columns: 'Columns',
      },
      update:
        "You have mapped a column to the 'id' field. Any rows with a value in this column will **update** the existing record with that ID if it exists. Any row without an ID value will **create** a new record.",
      help: 'Please map the columns in your file to the fields in your collection. Any unmapped columns will be ignored. A sample of your data is shown below to help you.',
      mappingPlaceholder: 'Map to a field',
      confirm: 'Import',
      done: 'Done',
      sendInvitations: 'Would you like to send email invitations to new users?',
      error: 'There was an problem with your import',
      completeSummary: {
        title: ' Your import has started!',
        subtitle:
          'Your records will be imported in the background. You will receive an email when it has finished.',
      },
      templateDownload: 'download a template',
      template: 'template',
      unmapped:
        'Some of the fields in your CSV are not mapped to fields in this collection. Please review the following fields and either provide a mapping or proceed without them being imported.',
    },
    export: {
      button: 'Export',
    },
  },
  apis: {
    saveDisabled: {
      playground: 'Saving new APIs is not possible in the playground',
    },
    form: {
      basics: 'Basics',
      headers: {
        title: 'Request Headers',
        key: 'Key',
        value: 'Value',
      },
      authentication: {
        label: 'Authentication',
      },
      endpoints: {
        title: 'Endpoints',
        empty: "You haven't added any endpoints to this API yet",
        disabled: 'You can add endpoints after saving your API connection',
        oath2Disabled:
          'You have to authorize your Oauth2.0 account before you can add any endpoints',
      },
    },
    endpoints: {
      new: 'New Endpoint | {apiName}',
      edit: 'Edit {name} | {apiName}',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Endpoint name',
          error:
            'Endpoints names must be unique per API and must not contain any special characters or spaces',
        },
        method: {
          label: 'Method',
          placeholder: 'Select a method',
        },
        type: {
          label: 'Request type',
          placeholder: 'Select a type',
          help: 'Endpoints that mutate data can be used in actions but not in data wrapper elements',
          [QUERY]: 'Fetch - retrieve data',
        },
        path: {
          label: 'Path & Parameters',
          placeholder: 'documents/{documentId}',
          help: "``Appended to the API's base URL. To create a variable for data, use the {{variable}} format.``",
        },
        headers: {
          title: 'Headers & Authentication',
          subtitle:
            '``The list below includes base API headers. Override these values by specifying a new value with the same name. To create a variable for data, use the {{variable}} format.``',
          key: 'Key',
          value: 'Value',
        },
        body: {
          title: 'Body',
          subtitle:
            '``Specify the structure of your request with valid JSON. To use a variable for data, use {{variable}} format right in the JSON as before.``',
          placeholder: 'Enter valid JSON with variables',
        },
        parameters: {
          title: 'Parameters & Variables',
          name: 'Name',
          subtitle: 'Test values and data type are required for all variables',
          dataType: {
            label: 'Data type',
            placeholder: 'Select a type',
          },
          testValue: {
            label: 'Test value',
            placeholder: 'test value',
          },
        },
        transformer: {
          title: 'Transformer',
          subtitle:
            'When enabled, you can transform the result of the API request into a better format with JavaScript',
        },
        test: {
          title: 'Test',
          endpoint: 'Endpoint',
          errors:
            'This endpoint is not yet properly configured. Make sure you filled in all the variable values',
          testButton: 'Test',
          status: 'Status',
        },
      },
    },
    new: 'New API',
    edit: 'Edit {name}',
    save: 'Save',
  },
  operators: {
    [EQUAL]: {
      label: {
        default: 'Is equal to (=)',
        named: '{name} should be equal to {value}{units}',
      },
    },
    [NOT_EQUAL]: {
      label: {
        default: 'Is not equal to (!=)',
        named: '{name} should not be equal to {value}{units}',
      },
    },
    [CONTAINS]: {
      label: {
        default: 'Contains',
        named: '{name} should contain {value}{units}',
      },
    },
    [DOES_NOT_CONTAIN]: {
      label: {
        default: 'Does not contain',
        named: '{name} should not contain {value}{units}',
      },
    },
    [IN]: {
      label: {
        default: 'Is one of',
        named: '{name} should be one of {value}{units}',
      },
    },
    [NOT_IN]: {
      label: {
        default: 'Is not one of',
        named: '{name} should not be one of {value}{units}',
      },
    },
    [AFTER]: {
      label: {
        default: 'Is after',
        named: '{name} should be after {value}{units}',
      },
    },
    [BEFORE]: {
      label: {
        default: 'Is before',
        named: '{name} should be before {value}{units}',
      },
    },
    [AFTER_OR_EQUAL]: {
      label: {
        default: 'Is after or equal to',
        named: '{name} should be after or equal to {value}{units}',
      },
    },
    [BEFORE_OR_EQUAL]: {
      label: {
        default: 'Is before or equal to',
        named: '{name} should be before or equal to {value}{units}',
      },
    },
    [GREATER]: {
      label: {
        default: 'Is greater than (>)',
        named: '{name} should be greater than {value}{units}',
      },
    },
    [GREATER_OR_EQUAL]: {
      label: {
        default: 'Is greater than or equal to (>=)',
        named: '{name} should be greater than or equal to {value}{units}',
      },
    },
    [LESS]: {
      label: {
        default: 'Is less than (<)',
        named: '{name} should be less than {value}{units}',
      },
    },
    [LESS_OR_EQUAL]: {
      label: {
        default: 'Is less than or equal to (<=)',
        named: '{name} should be less than or equal to {value}{units}',
      },
    },
    [EMPTY]: {
      label: {
        default: 'Is empty',
        named: '{name} should be empty',
      },
    },
    [NOT_EMPTY]: {
      label: {
        default: 'Is not empty',
        named: '{name} should not be empty',
      },
    },
    [TRUE]: {
      label: {
        default: 'Is true',
        named: '{name} should be true',
      },
    },
    [FALSE]: {
      label: {
        default: 'Is false',
        named: '{name} should be false',
      },
    },
    [EMAIL_OPERATOR]: {
      label: {
        default: 'Is a valid email address',
        named: 'Should be a valid email address',
      },
    },
    [IP_ADDRESS_OPERATOR]: {
      label: {
        default: 'Is a valid IP address',
        named: 'Should be a valid IP address',
      },
    },
    [REGEX]: {
      label: {
        default: 'Matches the pattern',
        named: 'The value does not match the expected pattern',
      },
    },
    [URL_OPERATOR]: {
      label: {
        default: 'Is a valid URL',
        named: 'Should be a valid URL',
      },
    },
    [SOME]: {
      label: {
        default: 'Has at least one match',
        named: '{name} should have at least one item matching',
      },
    },
    [EVERY]: {
      label: {
        default: 'All match',
        named: '{name} should have every item matching',
      },
    },
    [NONE]: {
      label: {
        default: 'None match',
        named: '{name} should not have any item matching',
      },
    },
  },
  actions: {
    add: 'Add',
    type: {
      label: 'What should happen?',
      placeholder: 'Select a type',
    },
    options: {
      record: 'This record',
    },
    groups: {
      data: 'Data',
      page: 'Navigation',
      account: 'Account',
      payments: 'Payments',
    },
    manyRelationship: 'Add or remove from {fieldName}:',
    step: 'Step {step}',
    done: 'Done',
    remove: 'Remove',
    previousActions: 'Previous actions',
    payments: {
      notConnected:
        'This action will not work because you have not configured your payment settings',
    },
    disableReverseField:
      'This field is automatically configured when using an action button.',
    event: 'Event',
    types: {
      [VIEW]: {
        label: 'View a record',
        title: 'View',
        field: 'View which record?',
        item: 'Which {item} record?',
      },
      [UPDATE]: {
        label: 'Update a record',
        title: 'Update',
        field: 'Update what record?',
        item: 'Which {item} record?',
      },
      [CREATE]: {
        label: 'Create a new record',
        title: 'Create',
        field: 'Create what record?',
        redirect: {
          label: 'After creating it?',
          switch: 'Open the new record',
          title: 'Open the new record after creating it?',
        },
      },
      [DELETE]: {
        label: 'Delete a record',
        title: 'Delete',
        field: 'Delete what record?',
        item: 'Which {item} record?',
      },
      [ADD_COMMENT]: {
        label: 'Add a comment or note',
        title: 'Add Comment',
        field: 'Comment on which record?',
        type: 'Type',
        comment: 'Comment',
        note: 'Note',
        body: 'Comment body',
        send: 'Send',
      },
      [ON_DEMAND]: {
        label: 'Run an on demand workflow',
        title: 'Workflow',
        field: 'Which record?',
        workflow: 'Which workflow?',
        placeholder: 'Choose a workflow to run',
      },
      [IFRAME]: {
        title: 'Iframe',
        label: 'Show an Iframe',
      },
      [COPY]: {
        title: 'Copy',
        label: 'Copy to clipboard',
      },
      [SCAN_BARCODE]: {
        addValue: {
          placeholder: 'Select a field',
          title: 'Barcode field',
          helpText:
            'A text or number field that will store the scanned barcode value',
        },
        title: 'Scan a barcode',
        label: 'Scan a barcode',
        field: {
          [ScanActionType.CREATE]: 'Create what record?',
          [ScanActionType.UPDATE]: 'Update what record?',
        },
        scanActionType: {
          [ScanActionType.CREATE]: 'Create',
          [ScanActionType.UPDATE]: 'Update',
          [ScanActionType.NAVIGATE]: 'Navigate',
          title: 'Action type',
        },
        multiScan: {
          title: 'Continuous scanning mode',
          helpText:
            'When enabled, the scanner will keep scanning until you click done or next',
        },
      },
      [FOR_EACH]: {
        label: 'For each item... do...',
      },
      [NAVIGATE]: {
        title: 'Navigate',
        label: 'Navigate to',
        page: 'Page or URL',
      },
      [OPEN_AI_CHAT_COMPLETION]: {
        label: 'ChatGPT response',
      },
      [OPEN_AI_CORRECT_GRAMMAR]: {
        label: 'Grammatically correct text',
      },
      [OPEN_AI_KEYWORD_EXTRACTION]: {
        label: 'Extracted keywords',
      },
      [OPEN_AI_SENTIMENT_ANALYSIS]: {
        label: 'Sentiment',
      },
      [OPEN_AI_SUMMARIZATION]: {
        label: 'Summary',
      },
      [OPEN_AI_TEXT_COMPLETION]: {
        label: 'Finished text',
      },
      [SEND_EMAIL]: {
        label: 'Send an email',
      },
      [WEBHOOK]: {
        label: 'Trigger a webhook (eg. Zapier, Make)',
      },
      [API_REQUEST]: {
        label: 'Api request',
        endpoint: {
          placeholder: 'Select an endpoint',
        },
      },
      [CREATE_RECORD]: {
        label: 'The created record',
      },
      [UPDATE_RECORD]: {
        label: 'The updated record',
      },
      [DELETE_RECORD]: {
        label: 'The deleted record',
      },
      [CREATE_COMMENT]: {
        label: 'Write a record comment',
      },
      [CREATE_NOTE]: {
        label: 'Write a record note',
      },
      [DOCS_AUTOMATOR_CREATE]: {
        label: 'Create a document',
      },
    },
  },
  fields: {
    permissionsWarning: {
      read: 'The current user does not have permission to read this field',
      update: 'The current user does not have permission to update this field',
      create:
        'The current user does not have permission to create values for this field',
      viewPermissions: 'View permissions',
      fieldReadWarning:
        'The current user does not have permission to read the field: "{field}"',
    },
  },
  toolbar: {
    addElement: 'Element',
    help: {
      academy: 'Noloco Academy',
      community: 'Ask our community',
      webinars: 'Upcoming webinars',
      support: 'Support & guides',
      liveChat: 'Ask a question',
      youtube: 'Videos & tutorials',
      tour: 'Show me around',
      billing: 'Account & billing',
      expert: 'Hire a Certified Noloco Expert',
      keyboardShortcutsModal: {
        title: 'Keyboard shortcuts',
        close: 'Close',
        shortcuts: {
          basic: {
            buildModeTrigger: 'Build mode trigger',
            helpMenu: 'Help menu',
            keyboardShortcutsList: 'Keyboard shortcuts list',
            openPublishModal: 'Open publish modal',
            searchCommandPalette: 'Search / Command palette',
            spaceSelector: 'Space selector',
            spaceSwitcher: 'Space switcher',
            title: 'Basic',
            toggleDarkMode: 'Toggle dark mode',
            toggleSidebar: 'Toggle sidebar',
            viewAsAnotherUser: 'View as another user',
          },
          editor: {
            addNewSection: 'Add new section',
            cloneSection: 'Clone section',
            closeRightEditor: 'Close right editor',
            deleteSection: 'Delete section',
            moveSectionDown: 'Move section down',
            moveSectionUp: 'Move section up',
            nextEditorTab: 'Next editor tab',
            nextItem: 'Next item',
            prevEditorTab: 'Prev editor tab',
            prevItem: 'Prev item',
            redo: 'Redo',
            saveUpdatedPageName: 'Save updated page name',
            title: 'Editor',
            undo: 'Undo',
          },
          calendar: {
            datePicker: 'Date picker',
            dayView: 'Day view',
            goToToday: 'Go to today',
            monthView: 'Month view',
            nextPeriod: 'Next period',
            previousPeriod: 'Previous period',
            title: 'Calendar/Timeline/Gantt',
            weekView: 'Week view',
            workWeekView: 'Work week view',
          },
          comments: {
            title: 'Comments',
            toggleComments: 'Toggle comments',
          },
          bulkActions: {
            title: 'Bulk actions',
            toggleUpdateAllRecords: 'Toggle update all records',
          },
        },
      },
    },
    preview: {
      button: 'Preview',
      playground:
        "You are in the playground, which unfortunately means you can't preview any changes you have made. However, you can see what the app we built looks like",
    },
    trial: {
      onboarding: {
        [GILL]: {
          jobTitle: 'Solution Engineer',
        },
      },
      summary: {
        1: 'There is 1 day left in your *Business* trial',
        _: 'There are {context} days left in your *Business* Trial',
      },
      explain:
        'During your trial you can enjoy all of the *Business* features for free.',
      compare: 'Compare plans',
      viewPlans: 'View our plans',
    },
    publish: {
      button: 'Publish',
      explain:
        "Until you publish your app, any changes you have made to your app's pages or layout will not be visible to your users.",
      explainSuccinct: "Users can't see your changes until you publish.",
      domains: 'Domains',
      notPublished: 'Not published',
      published: 'Currently live',
      unpublishedChanges: 'Your app has unpublished changes',
      customDomain: 'Custom domain',
      connectDomain: 'Connect your domain',
      viewDomains: 'View your other domains',
      congrats: 'Congrats!',
      ok: 'Ok',
      success: 'Your app updates are now published',
      publish: 'Publish',
      error: 'Oops! There was a problem publishing your app',

      publishMessage: {
        label: 'Version Description',
        invite: 'Invite someone to your app',
        sendInvite: 'Send Invite',
        inviteSent: 'Invite sent',
        placeholders: {
          email: 'The email to invite...',
          role: 'Choose a role...',
        },
        explain:
          'Add a short message that describes the changes you are about to publish. These messages can help you understand your changes in future, especially when reverting to an earlier version of your app.',
        placeholder: 'Added new filters to leads page',
      },
      roleSummary: {
        dataAdmin: {
          true: 'Team Admins can view and manage all data',
          false: 'Users are restricted by data permissions',
        },
        builder: {
          true: 'Team Admins can modify your app',
          false: 'Users cannot modify your app',
        },
      },
      versionHistory: {
        linkText: 'Version history',
        guideLinkText: 'Learn more about version history',
        listTitle: 'App Version History',
        defaultPublishMessage: 'Version {versionNumber}',
        publishThisVersion: 'Publish this version',
        publishedVersionAlert: 'Published version {versionNumber}',
        modalConfirmButtonText: 'Done',
        isCurrentVersionBadge: 'Published',
        emptyState:
          'Previously published versions of your app will appear here',
        revertEditorToThisVersion: 'Revert editor to this version',
        revertedEditorToVersionAlert:
          'The editor has been reverted to version {versionNumber}',
      },
      newVersion: {
        title: 'New version available',
        subtitle: 'Your app has been updated. Click to load the latest changes',
      },
    },
    waitList: {
      button: 'Join waitlist',
    },
    playground: {
      title: 'You are in the playground',
      body: 'Any changes you make will be saved to your browser',
      startTour: 'Start the tour again',
    },
  },
  dataTable: {
    error:
      'Something went wrong, please try again. If it happens again let us know',
    footer: {
      count: {
        1: '{context} record',
        _: '{context} records',
      },
    },
    new: 'Create a {dataType}',
    notFound: {
      heading: "Sorry, something's gone wrong!",
      description: "The collection '{dataType}' doesn't seem to exist",
    },
    actionNotifications: {
      UPDATE: 'Updating...',
      DELETE: 'Deleting...',
      CREATE: 'Saving...',
    },
    confirmAction: {
      title: 'Are you sure you want to do this?',
      confirm: 'Confirm',
      description: 'If you confirm, this action will...',
      UPDATE: {
        1: 'Update {count} row',
        _: 'Update {count} rows',
      },
      DELETE: {
        1: 'Permanently delete {count} row',
        _: 'Permanently delete {count} rows',
      },
      CREATE: {
        1: 'Create {count} new row',
        _: 'Create {count} new rows',
      },
    },
    fieldsOrder: {
      search: 'Find a field',
    },
  },
  introVideo: {
    title: 'Hey {firstName}, get started with a quick walk-through of Noloco!',
    description:
      'Before you get started, we suggest watching this video. It will explain the basics of Noloco and how to get building as soon as possible',
    button: "I'm ready, let's create an app",
  },
  collectionsVideo: {
    title:
      'Hey {firstName}, get started with a quick walk-through of Noloco tables!',
    description:
      'Before you get started, we suggest watching this video. It will explain the basics of Noloco tables and how to get building as soon as possible',
    button: "Let's create my app",
  },
  playgroundTour: {
    navigation: {
      back: 'Back',
      close: 'Close',
      next: 'Next',
      finish: 'Finish',
    },
    help: {
      title: 'Do you want a quick tour?',
      description:
        'Let us quickly show you around the builder. You can skip any step, or click this help icon at any time to start the tour again.',
    },
    canvas: {
      title: 'Your canvas 🎨',
      description: 'You can see how your app looks in this main section',
    },
    pages: {
      title: 'Pages',
      description:
        'This dropdown shows you all the different pages in your app and allows you to easily switch between them. You can add a new page any time you like',
    },
    'element-tree': {
      title: 'Element tree',
      description:
        'Here, you can see how your elements (such as text, images, groups etc.) are ordered and laid out. You can drag and drop the elements to change their order',
    },
    'element-item-selected': {
      title: 'Select an individual element',
      description:
        'You can click on an element in the tree to select it on the canvas and change its style and properties',
    },
    'add-element': {
      title: 'Add a new element',
      description:
        'Choose from our library of ready-made elements and blocks to speed up your designs',
    },
    'style-editor': {
      title: 'Style elements',
      description:
        'Here, you can change the layout, spacing, color and background of an element (and a whole lot more)',
    },
    'element-properties-link': {
      title: 'Element properties',
      description:
        'This is where you change element properties such as text, image source and pull in dynamic data.',
    },
    'element-text-editor': {
      title: 'Edit text',
      description:
        'Here, you can type plain text or pull in dynamic data such as a user’s first and last names.',
    },
    'data-link': {
      title: 'Access your data',
      description: 'This is how you get to your data tab',
    },
    'data-list': {
      title: "What's this data you speak of? 🤔",
      description:
        'On the data tab, you can view all the collections you have created for your app such as users, posts, products or any other collection you need',
    },
    'data-properties': {
      title: 'Data properties',
      description:
        'You can see the fields belonging to each collection and add custom fields. For example, users have first names, last names, bios and emails',
    },
    'settings-link': {
      title: 'App settings',
      description:
        "This is how you get to your app's settings, such as setting the SEO title, inviting collaborators, and configuring custom domains",
    },
    preview: {
      title: 'See it live!',
      description:
        "See what your masterpiece looks like in one click whenever you’re ready. Don't worry, this is only a preview",
    },
    publish: {
      title: 'Ready to launch? 🚀',
      description:
        "When you're ready to go, so are we. Simply click this button to update your production app. You can even connect a custom domain",
    },
    'help-wrapper': {
      title: "That's all folks",
      description:
        "You've now had a whirlwind tour of the Noloco builder, if you're still confused, just click this icon to access tonnes of resources, or even start the tour again",
    },
  },
  waitListModal: {
    title: 'Join waitlist',
    body: 'Want to be the first to build your own web-app using Noloco? Sign up to our waitlist.',
    subscribe: 'Get early access',
    done: 'Done',
    cancel: 'Not now',
    success:
      "You're on the list! We've sent an email to {email} to confirm your place. In the meantime, see what you can build in the playground",
    name: {
      label: 'Name',
      placeholder: 'John Smith',
    },
    email: {
      label: 'Email',
      placeholder: 'hello@noloco.io',
    },
  },
  notFoundPage: {
    title: 'Sorry, you must be lost',
    subtitle: 'Try again, or go back to where you came from',
  },
  onboardingChecklist: {
    title: 'Your quick start guide',
    subtitle: 'Complete these steps to make the most of your Noloco app',
    helpTitle: 'Click to open your guide',
    tooltip: 'Quick start guide',
    progress: 'Progress',
    category: {
      remainingTasks: {
        1: '{context} task left',
        _: '{context} tasks left',
      },
      complete: 'Complete',
    },
    infoDot: 'Complete this section to make the most of your app',
    infoDotCategory: 'You have tasks to complete to make the most of your app',
    categories: {
      data: {
        title: 'Step 1: Data & user setup',
        subtitle:
          "Import your business data into your app and configure your app's design to match your company's brand. If you connect an external data source, Noloco will sync your data as it changes so you and your team can stay in sync.",
        tasks: {
          'connect-data-source': {
            title: 'Connect your data source',
            description:
              'Noloco works better when you import or connect data sources you already use. Connect multiple data sources and join them together to truly empower your app.',
            help: 'How do I connect a data source?',
            cta: 'Connect your data',
          },
          'create-collection': {
            title: 'Feed Noloco with some data',
            description:
              "Noloco works better when you set up your collections and import your data. Before we can configure the app's interface, we need to add a new collection, setup your fields and create your first record.",
            help: 'What is a collection?',
            cta: 'Add a collection',
          },
          'invite-builder': {
            title: 'Invite a collaborator',
            description:
              "Invite some of your teammates to collaborate with you on your Noloco app. Share the responsibilities of setting it up, and you can all benefit from the tools you've built. You can add collaborators at any time.",
            help: 'How do I invite a collaborator?',
            cta: 'Add collaborator',
          },
          'external-users': {
            title:
              'Do you have a list of users stored inside a table in your external data source?',
          },
          'user-list': {
            title: 'Import & sync your users with a User List',
            description:
              "If you already have a list of your app's users in your data source, like Airtable or Google Sheets, you can sync it to the Noloco Users table in your app to allow those users to log in. This step is very important if you plan to create roles, permissions, and filters.",
            help: 'How do I add a user list?',
            cta: 'Add a user list',
          },
          'no-user-list': {
            title: 'Add your User(s) directly to the Noloco User Collection',
            description:
              'Add the user(s) you wish to include in your Noloco app. You can do this by adding new users individually to the Noloco User collection or by importing a list of users via CSV using the import function.',
            help: 'How do I add a user list?',
            cta: 'Add a user',
          },
        },
      },
      users: {
        title: 'Step 2: Configure your app pages',
        subtitle:
          "It's important to setup roles and permissions to control who has access to what data in your app. When that is ready you can invite the rest of your team so they can benefit from the app too.",
        tasks: {
          'configure-collection': {
            title: 'Customize your first collection view',
            description:
              'You can add as many collection views to your app as you need. Collections views display multiple records at a time, and can be presented in different layouts such as tables, cards, a kanban board and more. Collection views can also be filtered and sorted to your liking.',
            help: 'How do I setup my collection view?',
            cta: 'Customize a view',
          },
          'configure-record-view': {
            title: 'Add custom sections to a record view',
            description:
              'Each record in a collection view links to a record view that can show a lot more details of that record. You can add and customize sections on a record view to display the data and fields of your records, including related collections, highlights, charts and fields which allow you and your team to edit your records.',
            help: 'How do I customize a record view?',
            cta: 'Add a section',
          },
          'configure-another-collection': {
            title: 'Add another collection view',
            description:
              'During onboarding, you added and customised your first collection view. For this step, let’s add another view to your app.',
            help: 'How do I setup my collection view?',
            cta: 'Customize another view',
          },
        },
      },
      configure: {
        title: 'Step 3: Secure, automate and publish',
        subtitle:
          'Customize the different layouts and forms in your app, learn how to publish those changes before finally flipping the switch and allowing your team or customers to access your app',
        tasks: {
          'roles-and-permissions': {
            title: 'Setup roles and permissions',
            description:
              "One of the main benefits of using Noloco over a spreadsheet or database is that you can restrict which records and fields users can see, create, and edit. Define different roles for your app's users and setup permissions for each role.",
            help: 'How do I setup user permissions?',
            cta: 'Add permissions',
          },
          workflows: {
            title: 'Automate actions in your app with a workflow',
            description:
              'Workflows allow you to automate complex actions based on a trigger event, such as when a record is created, updated, or deleted, or by pressing an action button. For example: Create a test email workflow that is triggered by an on-demand action button.',
            help: 'What can I do with workflows?',
            cta: 'Create a workflow',
          },
          actionButton: {
            title:
              'Create a custom action button in your app to trigger a workflow',
            description:
              'Action buttons can be created on records and collection views. They are most commonly used to run create, update, and delete commands on records or to trigger automated workflows built in Noloco. For example, create a test action button that runs the Noloco workflow you created in the last step.',
            help: 'How do I create an action button?',
            cta: 'Create an action button',
          },
          publish: {
            title: 'Publish the changes you have made to your app',
            description:
              "When you edit your app's configuration, all changes are instantly saved, even if you refresh the page. However, your users will not be able to see those changes until your publish them. You can publish your changes as frequently as you wish using the publish button in the admin nav bar.",
            help: 'What happens when I publish my app?',
            cta: 'Publish my app',
          },
          'go-live': {
            title: 'Take the final leap, switch your app to live-mode',
            description:
              'When live-mode is disabled, your app is in test-mode, which means only collaborators can access the app. Switching to live-mode and inviting the rest of your users and team is the last thing you need to do to setup your app.',
            help: 'What does live-mode mean?',
            cta: 'Switch to live-mode',
          },
        },
      },
    },
    hide: {
      title: "Congrats! You've completed all the recommended steps",
      subtitle: "You can now hide this section if you're finished",
      label: "I'm finished",
    },
  },
  appAuth: {
    onboarding: {
      title: "👋 Let's get you set up.",
      subtitle:
        "We’re excited to have you here! There's just a two more questions, you can skip these if you prefer.",
      intro: {
        title: {
          [GILL]: "Hi, I'm Gill!",
        },
        description:
          "I'll be your Onboarding Manager during your free Noloco trial",
      },
      referrer: {
        label: 'How did you hear about Noloco?',
        placeholder: 'E.g. Twitter',
      },
      description: {
        label: 'Mind telling me what you’re looking to build?',
        help: 'So that I can best help you - be as detailed as you can',
        placeholder:
          'E.g. A tool to share and manage our Google Sheets data with strategic partners',
      },
      learningPreference: {
        label: 'What way do you prefer to learn?',
        placeholder: 'E.g. video tutorials, guides, community...',
      },
      cloneTemplate: {
        title: 'Pick a name for your app',
        loading: 'Creating your app...',
        confirm: 'Next',
      },
      confirm: 'Get started',
      continue: 'Next',
      skip: 'Skip',
      back: 'Back',
      questions: {
        team: {
          title: 'Let us know which team you are on.',
          subtitle:
            'This will help us to personalise your experience in Noloco.',
          options: {
            founder: 'Founder / CXO',
            engineering: 'IT / Engineering',
            productManagement: 'Product / Project Management',
            finance: 'Finance / Accounting',
            customerSupport: 'Customer Support',
            operations: 'Operations',
            humanResources: 'Human Resources',
            salesAndMarketing: 'Sales & Marketing',
            other: 'Other',
          },
        },
        companySize: {
          title: 'How many people will you be working with?',
          options: {
            lessThan10: 'Less than 10',
            lessThan25: '10 - 25',
            lessThan50: '25 - 50',
            lessThan100: '50 - 100',
            moreThan100: 'More than 100',
          },
        },
        industry: {
          title: 'What industry do you work in?',
          options: {
            agriculture: 'Agriculture',
            automotive: 'Automotive',
            banking: 'Banking',
            construction: 'Construction',
            education: 'Education',
            energy: 'Energy',
            entertainment: 'Entertainment',
            foodAndBeverage: 'Food & Beverage',
            healthcare: 'Healthcare',
            hospitality: 'Hospitality',
            insurance: 'Insurance',
            manufacturing: 'Manufacturing',
            media: 'Media',
            nonProfit: 'Nonprofit',
            pharmaceutical: 'Pharmaceutical',
            realEstate: 'Real Estate',
            retail: 'Retail',
            softwareAndTechnology: 'Software & Technology',
            telecommunications: 'Telecommunications',
            transportation: 'Transportation',
            travelAndTourism: 'Travel & Tourism',
            utilities: 'Utilities',
            wholesaleTrade: 'Wholesale Trade',
            government: 'Government',
            other: 'Other industry...',
          },
        },
        goal: {
          title: 'What are you looking to build?',
          subtitle:
            'Choose the most appropriate area and we will help you get started',
          options: {
            salesCrm: 'Sales CRM',
            inventoryManagement: 'Inventory Management',
            clientPortal: 'Client portal',
            projectManagement: 'Project Management',
            applicantTrackingSystem: 'Applicant Tracking System',
            realEstateManagement: 'Real Estate Management',
            other: {
              label: 'Or tell us what you want to build',
              subtitle:
                'We will use your description to build your app and plan future templates',
              placeholder: 'I want to build...',
              next: 'Next',
            },
          },
        },
        connectWithExpert: {
          title: 'Looking for some help?',
          subtitle:
            'Would you like to collaborate with one of our Certified Noloco Experts to help you reach your goal faster?',
          expertsLeanMore: 'Learn more about Certified Noloco Experts',
          options: {
            true: 'Yes, connect me with an Expert',
            false: "No thanks, I'm okay for now",
          },
        },
        dataSource: {
          title: 'What data will you use?',
          subtitle:
            'Choose the option that best describes the type of data you will use in your app',
          options: {
            csv: {
              title: 'Upload a table',
              subtitle:
                'I want to import some data I already have in a CSV file',
            },
            external: {
              title: 'Sync my data',
              subtitle:
                'I want to connect my app to live data from an existing spreadsheet, database or software I use',
            },
            noloco: {
              title: 'Start from scratch',
              subtitle:
                'I want to build out my database from scratch using Noloco tables',
            },
          },
        },
      },
      call: {
        title: 'Book in your free onboarding call',
        subtitle:
          'Before you get started with Noloco, you can book a free onboarding call with {onboardingManager}, your in-house expert, who will help you succeed with Noloco.',
        booked: {
          title: 'Thanks {firstName}!',
          subtitle:
            "Your call with {onboardingManager} is now booked. Why not explore some of Noloco's resources while you wait.",
        },
        actions: {
          title: 'When are you ready to get started with Noloco?',
          now: {
            title: "I'm ready now",
            description: 'Get started with Noloco on your own',
          },
          later: {
            title: 'I would like to schedule a call',
            description:
              'Schedule an onboarding call for a time that suits you',
          },
        },
        resources: {
          call: {
            label: 'Your call details',
            description: 'Your onboarding call is on {dateTime}',
          },
          guides: {
            label: 'Guides',
            description: 'Learn how to build internal tools for your team',
          },
          youtube: {
            label: 'Video tutorials',
            description: 'Watch tutorials on building internal tools',
          },
          community: {
            label: 'Community',
            description:
              'Join our community to keep up to date with product updates',
          },
          getStarted: {
            label: 'Explore Noloco on your own',
            description: 'Get started with Noloco before your onboarding call',
          },
        },
      },
    },
    forgotPassword: {
      title: 'Recover your password',
      help: "Don't worry, it happens to the best of us",
      email: {
        label: 'Your email',
        placeholder: 'lisa@example.com',
      },
      confirm: 'Email me a recovery link',
      submitted:
        'If we find your account we will send you an email with more instructions. Just click the link if you get it',
    },
    resetPassword: {
      title: 'Reset your password',
      help: "Don't worry, it happens to the best of us",
      email: {
        label: 'Confirm your email',
      },
      password: {
        label: 'Your new password',
      },
      confirmPassword: {
        label: 'Confirm your password',
      },
      confirm: 'Reset my password',
    },
  },
  publicForms: {
    new: 'Create a {dataType}',
    successfulSubmitPageTitle: 'Success!',
    failedToCreateErrorMessage:
      'Something went wrong creating your public form',
    failedToDeleteErrorMessage:
      'Something went wrong deleting your public form',
  },
  userTable: {
    description: 'Manage access to the users in your app',
    fields: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      isActive: 'Active',
      lastActive: 'Last active on {dateTime}',
      pendingInvite: {
        badge: 'Pending',
        tooltip:
          'They have not accepted their invitation to collaborate on this app',
      },
    },
    add: 'Add a user',
    manageRoles: 'Manage roles',
    search: 'Search app users...',
    deactivated: {
      badge: 'Deactivated',
      tooltip:
        'This user was deactivated at {deactivatedAt} and will not be able to log into any new sessions.',
    },
    builderWarning: {
      label: 'This user needs to be invited to collaborate on this app',
      cta: 'Send Invite',
    },
    actions: {
      edit: 'Edit details',
      invite: 'Send invitation',
      copyInvite: 'Copy invitation link',
      invitationUrlCopied: 'Invitation URL copied.',
      passwordReset: 'Send password reset',
      passwordResetSent: 'A password reset email has been sent to {email}',
      deactivate: {
        description:
          'Deactivating this user will prevent them from logging into any new sessions in your app. You will not be charged for them after this current billing cycle, however this will not delete their record in your user table or any other of their data.',
        label: 'Deactivate this user',
        save: 'Deactivate',
      },
      reactivate: {
        description:
          'Reactivating this user will allow them to log into your app and resume using it as before.',
        label: 'Reactivate this user',
        save: 'Reactivate',
      },
      delete: {
        label: 'Remove user',
        description:
          'Removing this user will delete their record and all of their data. This action cannot be undone.',
        save: 'Remove user',
        error: "There was a problem removing '{email}'",
        success: "'{email}' was removed from your app",
      },
    },
    form: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      role: 'Role',
      save: 'Save',
      error: 'Oops! Something went wrong',
      title: {
        [UPDATE]: 'Update user',
        [CREATE]: 'Add a new user',
      },
      roleSummary: {
        title: 'Summary',
        placeholder: 'The user',
        internal: {
          true: '{firstName} is an internal team member',
          false: '{firstName} is not an internal team member',
        },
        dataAdmin: {
          true: '{firstName} can view and manage all data',
          false: '{firstName} is restricted by data permissions',
        },
        builder: {
          true: '{firstName} can modify your app',
          false: '{firstName} cannot modify your app',
        },
      },
      invite: 'Send an invitation email with instructions to log in',
      adminInvite:
        'App collaborators will be immediately invited to collaborate on this app',
    },
  },
  validateProject: {
    explainer:
      "We found {count} potential configuration issues with your app. These common issues can often cause the error you're seeing now.",
    apply: 'Fix potential issues',
  },
  commandPalette: {
    button: 'Jump to...',
    placeholder: 'Jump to page or space',
    emptyState: "Sorry, we couldn't find anything...",
    actionHelp: 'Open Page',
  },
  dynamicValueInput: {
    source: 'Use data from...',
    values: 'Choose data',
    empty: 'No more options...',
    dynamicSwitch: {
      label: 'Dynamic value',
      tooltip: 'To use a constant value, disable this switch',
    },
  },
  colors: {
    gray: 'Gray',
    cyan: 'Cyan',
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    indigo: 'Indigo',
    green: 'Green',
    blue: 'Blue',
    teal: 'Teal',
    purple: 'Purple',
    pink: 'Pink',
    slate: 'Slate',
  },
  notifications: {
    consent: {
      enable: 'Enable',
      prompt: 'Do you want to enable browser notifications?',
      snooze: 'Remind me later',
    },
    empty: "There's nothing here.",
    error: "We're having some trouble loading your notifications right now.",
    invite: 'Success! {email} has been sent an invite',
    label: 'Notifications',
    markAsRead: 'Mark as read',
    markAsUnread: 'Mark as unread',
    read: 'Read',
    showMore: 'Show more',
    unread: 'Unread',
  },
};

export default enLang;
