import React, { useEffect, useMemo } from 'react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { SelectInput, getColorShade } from '@noloco/components';
import { baseColors } from '@noloco/core/src/constants/tailwindStyles';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import ConditionsEditor from './ConditionsEditor';

interface ColorConditionsEditorProps {
  className?: string;
  contained?: boolean;
  inline?: boolean;
  dataType: DataType;
  fieldOptions: any;
  updateConditions: (path: ElementPath, value: any) => void;
  updateColor: (color: string) => void;
  removeColorCondition: () => void;
  project: Project;
  elementPath: ElementPath;
  rules: any;
  colorValue: string;
}

const ColorConditionsEditor = ({
  className,
  contained,
  inline,
  dataType,
  fieldOptions,
  project,
  elementPath,
  updateConditions,
  updateColor,
  removeColorCondition,
  rules,
  colorValue,
}: ColorConditionsEditorProps) => {
  useEffect(() => {
    if (Array.isArray(rules) && rules.length === 0) {
      removeColorCondition();
    }
  }, [rules, removeColorCondition]);

  const COLOR_OPTIONS = useMemo(
    () =>
      baseColors
        .filter((color: string) => color !== 'gray' && color !== 'slate')
        .map((color: string) => ({
          label: getText('colors', color),
          value: color,
          icon: (
            <div
              className={`mr-2 h-5 w-5 flex-shrink-0 rounded-full bg-${getColorShade(
                color,
                400,
              )}`}
            />
          ),
        })),
    [],
  );

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="border-brand-light relative mx-2 mt-2 rounded-lg border border-dotted p-2">
        <button
          onClick={removeColorCondition}
          className="bg-brand-light absolute right-0 top-0 -mr-2 -mt-2 flex items-center justify-center rounded-full p-1 text-white hover:bg-cyan-500"
        >
          <IconX size={12} />
        </button>
        <SelectInput
          className="mb-4"
          value={colorValue}
          contained={contained}
          options={COLOR_OPTIONS}
          onChange={updateColor}
          placeholder={getText('colorConditionEditor.color.placeholder')}
        />
        <ConditionsEditor
          inline={inline}
          dataType={dataType}
          fieldOptions={fieldOptions}
          updateConditions={updateConditions}
          rules={rules}
          project={project}
          elementPath={elementPath}
        />
      </div>
    </div>
  );
};

export default ColorConditionsEditor;
