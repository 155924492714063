import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader } from '@noloco/components/src';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import {
  DANGER,
  INFO,
  PRIMARY,
  Variant,
  WARNING,
} from '@noloco/components/src/constants/variants';
import IconComponent from '@noloco/core/src/elements/Icon';
import { NotificationIconType } from '@noloco/core/src/models/Notification';

const variantIcon = (type: any) =>
  ({
    [DANGER]: IconAlertOctagon,
    [WARNING]: IconAlertTriangle,
    [INFO]: IconInfoCircle,
    [PRIMARY]: IconCircleCheck,
  })[type] || IconCircleCheck;

const NotificationIcon = ({
  icon,
  iconType,
  type,
}: {
  icon: string;
  iconType?: NotificationIconType;
  type: Variant | 'LOADING';
}) => {
  if (type === 'LOADING') {
    return <Loader size={XS} />;
  }

  if (!icon) {
    const Icon = variantIcon(type);

    return <Icon size={24} />;
  }

  if (iconType === NotificationIconType.ICON) {
    return (
      <IconComponent icon={{ name: icon }} className="h-6 w-6" size={24} />
    );
  }

  return (
    <img alt="Notification icon" className="h-6 w-6 rounded-full" src={icon} />
  );
};

const Notification = forwardRef(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
  ({ className, style, options, message, close, theme }, ref) => {
    const color = useMemo(
      () =>
        ({
          [DANGER]: theme.brandColors[DANGER],
          [WARNING]: theme.brandColors[WARNING],
          [INFO]: theme.brandColors.primary,
          [PRIMARY]: theme.brandColors.success,
        })[options.type] || theme.brandColors.primary,
      [options.type, theme.brandColors],
    );

    const onClick = useCallback(() => {
      if (!options.onClick) {
        return;
      }

      options.onClick();
    }, [options]);

    const onClose = useCallback(
      (e) => {
        e.stopPropagation();

        if (options.onDismiss) {
          options.onDismiss();
        }

        close();
      },
      [close, options],
    );

    return (
      // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      <div ref={ref} style={style}>
        <div
          className={classNames(
            className,
            'flex w-full max-w-sm rounded-lg border bg-white p-3 shadow-lg',
            options.text ? 'items-start' : 'items-center',
            {
              'cursor-pointer': options.onClick,
            },
          )}
          onClick={onClick}
        >
          <Box className="flex flex-shrink-0" text={color}>
            <NotificationIcon
              icon={options.icon}
              type={options.type}
              iconType={options.iconType}
            />
          </Box>
          <div className="mt-.5 mx-3 flex flex-grow flex-col">
            <span className="text-sm font-medium">{message}</span>
            {options.text && (
              <span className="whitespace-pre-wrap text-xs font-light text-gray-600">
                {options.text}
              </span>
            )}
          </div>
          {close && options.clearable !== false && (
            <button
              className="flex flex-shrink-0 text-gray-500"
              onClick={onClose}
            >
              <IconX size={16} />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default withTheme(Notification);
