import React, { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { HelpTooltip, Label, SelectInput, Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import usePrevious from '@noloco/core/src/utils/hooks/usePrevious';
import { getText } from '@noloco/core/src/utils/lang';
import Guide from '../../Guide';
import DataField from './DataField';

const LANG_KEY = 'data.lookups';

interface Props {
  dataType: DataType;
  dataTypes: DataTypes;
  setSourceFieldId: (sourceFieldId: number) => void;
  setValueFieldId: (valueFieldId: number | null) => void;
  sourceFieldId: number | null;
  valueFieldId: number | null;
  onValidChange?: (isValid: boolean) => void;
  readOnly?: boolean;
  surface: Surface;
}

const LookupEditor = ({
  dataType,
  dataTypes,
  setSourceFieldId,
  setValueFieldId,
  sourceFieldId,
  valueFieldId,
  onValidChange,
  readOnly,
  surface,
}: Props) => {
  const sourceFieldOptions = useMemo(
    () =>
      dataType.fields
        .filter(
          (field) =>
            !field.hidden &&
            !field.internal &&
            field.type !== FILE &&
            (field.relationship || field.relatedField),
        )
        .map((field) => ({
          label: (
            <DataField dataTypes={dataTypes} field={field} key={field.name} />
          ),
          value: field.id,
        })),

    [dataType.fields, dataTypes],
  );

  const handleSourceFieldChange = useCallback(
    (sourceFieldId: number) => {
      setValueFieldId(null);
      setSourceFieldId(sourceFieldId);
    },
    [setSourceFieldId, setValueFieldId],
  );

  const lookupDataType = useMemo(() => {
    if (!sourceFieldId) {
      return null;
    }

    const field = dataType.fields.getById(sourceFieldId);

    return field && dataTypes.getByName(field.type);
  }, [dataType, dataTypes, sourceFieldId]);

  const valueFieldOptions = useMemo(
    () =>
      lookupDataType
        ? lookupDataType.fields
            .filter(
              (field) =>
                !field.hidden && !field.internal && field.type !== FILE,
            )
            .map((field) => ({
              label: (
                <DataField
                  dataTypes={dataTypes}
                  field={field}
                  key={field.name}
                />
              ),
              value: field.id,
            }))
        : [],

    [dataTypes, lookupDataType],
  );

  const isValid = useMemo(
    () =>
      !!lookupDataType &&
      sourceFieldOptions.some(
        (lookupOption) => lookupOption.value === sourceFieldId,
      ) &&
      valueFieldOptions.some(
        (lookupOption) => lookupOption.value === valueFieldId,
      ),
    [
      lookupDataType,
      sourceFieldId,
      sourceFieldOptions,
      valueFieldId,
      valueFieldOptions,
    ],
  );

  const previousIsValid = usePrevious(isValid);

  useEffect(() => {
    if (isValid !== previousIsValid && onValidChange) {
      onValidChange(isValid);
    }
  }, [isValid, onValidChange, previousIsValid]);

  return (
    <>
      <Guide
        className="mt-2 text-sm"
        href="https://guides.noloco.io/data/collections/lookups"
      >
        {getText(LANG_KEY, 'guide')}
      </Guide>
      <div
        className="mb-2 mt-4 flex items-center"
        data-testid="field-lookup-editor"
      >
        <Label surface={surface} m={0}>
          {getText(LANG_KEY, 'sourceField.label')}
        </Label>
        <HelpTooltip
          className={classNames('ml-2 hover:text-gray-500', {
            'text-gray-800': surface === LIGHT,
            'text-gray-200': surface === DARK,
          })}
          placement="right"
        >
          <p>{getText(LANG_KEY, 'sourceField.tooltip')}</p>
        </HelpTooltip>
      </div>
      <SelectInput
        className="mb-2"
        contained={true}
        data-testid="field-lookup-source-field"
        options={sourceFieldOptions}
        disabled={readOnly}
        placeholder={getText(LANG_KEY, 'sourceField.placeholder')}
        onChange={handleSourceFieldChange}
        value={sourceFieldId}
        surface={surface}
      />
      {sourceFieldId && lookupDataType && (
        <>
          <div className="mb-2 mt-4 flex items-center">
            <Label surface={surface} m={0}>
              {getText(
                { sourceTypeName: lookupDataType.display },
                LANG_KEY,
                'valueField.label',
              )}
            </Label>
            <HelpTooltip
              className={classNames('ml-2 hover:text-gray-500', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
              placement="right"
            >
              <p>
                {getText(
                  { dataType: lookupDataType.display },
                  LANG_KEY,
                  'valueField.tooltip',
                )}
              </p>
            </HelpTooltip>
          </div>
          <SelectInput
            className="mb-2"
            contained={true}
            data-testid="field-lookup-value-field"
            options={valueFieldOptions}
            placeholder={getText(
              { dataType: lookupDataType.display },
              LANG_KEY,
              'valueField.placeholder',
            )}
            onChange={setValueFieldId}
            disabled={readOnly}
            surface={surface}
            value={valueFieldId}
          />
        </>
      )}
    </>
  );
};

export default LookupEditor;
