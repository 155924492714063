import React, { memo } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import flagsmith from 'flagsmith';
import T from 'i18n-react';
import { Provider as AlertProvider } from 'react-alert';
import 'react-datepicker/dist/react-datepicker.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'simplebar/dist/simplebar.min.css';
import '@noloco/components/src/index.css';
import { LocaleProvider } from '@noloco/components/src/utils/hooks/useLocale';
import { CLIENT_AUTH_TOKEN } from '@noloco/core/src/constants/auth';
import coreTexts from '@noloco/core/src/lang/en';
import { Project as ProjectModel } from '@noloco/core/src/models/Project';
import { ProvideProjectAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { CurrentSpaceProvider } from '@noloco/core/src/utils/hooks/useCurrentSpace';
import { DarkModeProvider } from '@noloco/core/src/utils/hooks/useDarkMode';
import { addSegmentNonAdminDestinationsMiddleware } from '@noloco/core/src/utils/hooks/useSegmentSourceMiddlewares';
import { ProvideServerEvents } from '@noloco/core/src/utils/hooks/useServerEvents';
import { ProvidePushNotifications } from '@noloco/core/src/utils/hooks/useServiceWorkerNotifications';
import AlertNotification from '@noloco/ui/src/components/Notification';
import Project from '@noloco/ui/src/components/Project';
import ProjectErrorMessage from '@noloco/ui/src/components/ProjectErrorMessage';
import { FLAGSMITH_ENV_ID } from '@noloco/ui/src/constants/env';
import uiTexts from '@noloco/ui/src/lang/en';
import { ProvideProjectUpdates } from '@noloco/ui/src/utils/hooks/projectHooks';
import Head from './Head';

flagsmith.init({
  environmentID: FLAGSMITH_ENV_ID,
});

T.setTexts(
  {
    ...coreTexts,
    ...uiTexts,
  },
  { MDFlavor: 1 },
);

addSegmentNonAdminDestinationsMiddleware();

const App = memo(
  ({
    client,
    project,
  }: {
    client: ApolloClient<any>;
    isCustomDomain: boolean;
    project: ProjectModel;
  }) => (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <LocaleProvider>
          <DndProvider backend={HTML5Backend}>
            <AlertProvider template={AlertNotification} offset="6px 18px">
              {/* @ts-expect-error https://github.com/bvaughn/react-error-boundary/issues/113 react 18 types wanted */}
              <ErrorBoundary fallback={ProjectErrorMessage}>
                <ProvideProjectAuth
                  project={project}
                  authTokenKey={CLIENT_AUTH_TOKEN}
                >
                  <ProvideServerEvents projectName={project.name}>
                    <ProvideProjectUpdates projectName={project.name}>
                      <ProvidePushNotifications projectName={project.name}>
                        <DarkModeProvider settings={project.settings}>
                          <CurrentSpaceProvider>
                            <div className="noloco-project">
                              <Head
                                settings={project.settings}
                                media={project.media}
                              />
                              <Project />
                            </div>
                          </CurrentSpaceProvider>
                        </DarkModeProvider>
                      </ProvidePushNotifications>
                    </ProvideProjectUpdates>
                  </ProvideServerEvents>
                </ProvideProjectAuth>
              </ErrorBoundary>
            </AlertProvider>
          </DndProvider>
        </LocaleProvider>
      </ApolloProvider>
    </React.StrictMode>
  ),
);

// @ts-expect-error TODO need to fix typing for props
App.defaultProps = {
  ssrMode: false,
};

export default App;
