import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { DARK } from '@noloco/components/src/constants/surface';
import { FileType, IMAGE } from '@noloco/core/src/constants/fileTypes';
import {
  IMAGE_MIMETYPES,
  VIDEO_MIMETYPES,
} from '@noloco/core/src/constants/mimetypes';
import FilePreview from '@noloco/core/src/elements/sections/forms/FilePreview';
import { addMedia as dispatchAddMedia } from '@noloco/core/src/reducers/project';
import { UPLOAD_PROJECT_MEDIA } from '../../../queries/project';

interface Props {
  addMedia: (...args: any[]) => any;
  className?: string;
  fileType?: FileType;
  mediaId?: number;
  maxH?: number;
  updateMediaId: (...args: any[]) => any;
  project: any;
}

interface ImageUpload {
  file: File;
  arrayBuffer: ArrayBuffer;
  preview: string;
}

const DropzoneMediaUpload = ({
  addMedia,
  className,
  updateMediaId,
  fileType = IMAGE,
  mediaId,
  project,
  maxH = 20,
}: Props) => {
  const [uploadMedia] = useMutation(UPLOAD_PROJECT_MEDIA);
  const [uploadingImgData, setUploadingImageData] =
    useState<null | ImageUpload>(null);

  const removeImage = useCallback(() => {
    updateMediaId(undefined);
    setUploadingImageData(null);
  }, [updateMediaId]);

  const onHandleFileSelect = useCallback(
    (fileObject: any) => {
      if (!fileObject) {
        return removeImage();
      }
      const [file, arrayBuffer, previewUrl] = fileObject;
      uploadMedia({
        variables: { projectName: project.name, file },
      })
        .then((resultData) => {
          const uploadMedia = resultData.data.uploadMedia;
          updateMediaId(uploadMedia.id);
          addMedia(uploadMedia);
        })
        .catch((error) => {
          console.log('Error', error);
        });
      const imageData = {
        file,
        arrayBuffer,
        preview: previewUrl,
      } as ImageUpload;

      setUploadingImageData(imageData);
    },
    [addMedia, project.name, removeImage, updateMediaId, uploadMedia],
  );

  const selectedMedia = useMemo(
    () => mediaId && project.media.find(({ id }: any) => id === mediaId),
    [mediaId, project.media],
  );

  return (
    <div className="dark flex">
      <FilePreview
        className={className}
        acceptedMimetypes={
          fileType === IMAGE ? IMAGE_MIMETYPES : VIDEO_MIMETYPES
        }
        maxH={maxH}
        isMultiple={false}
        files={[selectedMedia ?? uploadingImgData ?? null]}
        maxFiles={1}
        readOnly={false}
        onRemove={removeImage}
        surface={DARK}
        onChange={onHandleFileSelect}
      />
    </div>
  );
};

const mapDispatch = {
  addMedia: dispatchAddMedia,
};

export default connect(null, mapDispatch)(DropzoneMediaUpload);
