import React, { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import ErrorText from '@noloco/components/src/components/form/ErrorText';
import { projectNameSelector } from '../selectors/projectSelectors';
import { YMD_TIME_FORMAT } from '../utils/dates';
import { downloadCsvStringAsFile } from '../utils/files';
import { useAuth } from '../utils/hooks/useAuth';
import useIsWindows from '../utils/hooks/useIsWindows';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import { AuthUrl } from './sections/twoFactorAuthentication/AuthUrl';
import { GenerateOTPBackupCodes } from './sections/twoFactorAuthentication/GenerateBackupCodes';
import { OTPInput } from './sections/twoFactorAuthentication/OTPInput';

enum AuthSteps {
  GENERATE = 'generate',
  VERIFY = 'verify',
  GENERATE_BACKUP_CODES = 'generateBackupCodes',
}

interface TwoFactorAuthLoginProps {
  secondFactorAuthToken?: string;
  redirectPath?: string;
  updateUserCache: (user: any) => void;
}

export const TwoFactorAuthSetup = ({
  secondFactorAuthToken,
  redirectPath,
  updateUserCache,
}: TwoFactorAuthLoginProps) => {
  const { generateOTP, verifyOTP, generateOTPBackupCodes } = useAuth();
  const [step, setStep] = useState<AuthSteps>(AuthSteps.GENERATE);
  const [otpAuthUrl, setOtpAuthUrl] = useState<string | undefined>();
  const [base32, setBase32] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const { push } = useRouter();
  const projectName = useSelector(projectNameSelector);
  const isWindows = useIsWindows();

  const handleGenerate = useCallback(() => {
    setLoading(true);
    generateOTP!(secondFactorAuthToken)
      .then((data) => {
        setOtpAuthUrl(data.otpAuthUrl);
        setBase32(data.base32);
      })
      .catch(() => setErrors([getText('auth.twoFactorAuth.error.generateOTP')]))
      .finally(() => setLoading(false));
  }, [generateOTP, secondFactorAuthToken]);

  const handleVerify = useCallback(
    (token: string) => {
      setLoading(true);
      verifyOTP!(token, secondFactorAuthToken)
        .then((user) => {
          if (user) {
            setStep(AuthSteps.GENERATE_BACKUP_CODES);
            updateUserCache(user);
          }
        })
        .catch(() =>
          setErrors([getText('auth.twoFactorAuth.error.otpInvalid')]),
        )
        .finally(() => setLoading(false));
    },
    [verifyOTP, updateUserCache, secondFactorAuthToken],
  );

  const handleGenerateBackupCodes = useCallback(() => {
    setLoading(true);
    generateOTPBackupCodes!()
      .then((data) => {
        if (data.codes) {
          downloadCsvStringAsFile(
            data.codes.join('\n'),
            `backupcodes-${projectName}-${DateTime.now().toFormat(YMD_TIME_FORMAT)}`,
            isWindows,
            'txt',
          );
        }
      })
      .catch(() =>
        setErrors([getText('auth.twoFactorAuth.error.generateBackupCodes')]),
      )
      .finally(() =>
        push(redirectPath ? decodeURIComponent(redirectPath) : '/'),
      );
  }, [generateOTPBackupCodes, push, redirectPath, projectName, isWindows]);

  useEffect(() => {
    if (!otpAuthUrl && step === AuthSteps.GENERATE) {
      handleGenerate();
    }
  }, [step, handleGenerate, otpAuthUrl]);

  return (
    <>
      {errors.length > 0 && (
        <ErrorText>
          {errors.map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </ErrorText>
      )}
      {step === AuthSteps.GENERATE && otpAuthUrl && (
        <AuthUrl
          otpAuthUrl={otpAuthUrl}
          base32={base32}
          onNext={() => setStep(AuthSteps.VERIFY)}
        />
      )}
      {step === AuthSteps.VERIFY && (
        <OTPInput handleVerify={handleVerify} loading={loading} />
      )}
      {step === AuthSteps.GENERATE_BACKUP_CODES && (
        <GenerateOTPBackupCodes
          generateBackupCodes={handleGenerateBackupCodes}
          buttonText={getText('auth.twoFactorAuth.backupCodes.generate')}
        />
      )}
    </>
  );
};
