import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronUp,
  IconEdit,
  IconFolder,
  IconGripVertical,
  IconSettings,
  IconShieldLock,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SelectInput, Switch, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import BuildModeIcon from '@noloco/core/src/components/buildMode/BuildModeIcon';
import { WithDraggable } from '@noloco/core/src/components/withDnD';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import { RECORD_FIELD } from '@noloco/core/src/constants/draggableItemTypes';
import { FIELD_LEVEL_PERMISSIONS } from '@noloco/core/src/constants/features';
import { FieldFormat } from '@noloco/core/src/constants/fieldFormats';
import { OBJECT_FORMATS_WITH_DISPLAY } from '@noloco/core/src/constants/objects';
import {
  CMD,
  COMMA,
  PERIOD,
  SHIFT,
} from '@noloco/core/src/constants/shortcuts';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes, { DataType } from '@noloco/core/src/models/DataTypes';
import { User } from '@noloco/core/src/models/User';
import {
  FormFieldConfig,
  FormSectionConfig,
} from '@noloco/core/src/models/View';
import { setIsFieldListEditorOpen } from '@noloco/core/src/reducers/elements';
import { isFieldListEditorOpenSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import useAuthWrapper from '@noloco/core/src/utils/hooks/useAuthWrapper';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';
import { getSubFieldsAsDataFields } from '@noloco/core/src/utils/objects';
import {
  PermissionType,
  nestedFieldPermissions,
} from '@noloco/core/src/utils/permissions';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import DataFieldIcon from '../../DataFieldIcon';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';
import DataFieldForm from '../../leftSidebar/data/DataFieldForm';

const LONG_FIELD_NAME_LENGTH = 30;

interface FieldListItemProps {
  activeField?: number | null;
  allowChildFields?: boolean;
  children?: any;
  className?: string;
  dataType: DataType;
  dataTypes: DataTypes;
  disabledText?: any;
  draggable: boolean;
  enabled: boolean;
  field: DataField;
  fieldsList?: {
    config: FormFieldConfig;
    field: DataField;
  }[];
  hasSections: boolean;
  index: number;
  isOver?: boolean;
  disabled?: boolean;
  disableSwitch?: boolean;
  onChangeEnabled: (field: DataField, parentField?: DataField) => any;
  onDeleteSection?: (arg: any) => any;
  parentField?: DataField;
  parentFieldType?: DataType;
  permissionType: PermissionType;
  popoutOpen?: boolean;
  refetchData: () => Promise<any>;
  section?: FormSectionConfig;
  selectedChildFields: string[];
  setActiveField?: (activeField: number | null) => void;
  setPopoutOpen?: (popoutOpen: boolean) => void;
}

const FieldListItem = forwardRef(
  (
    {
      activeField = null,
      allowChildFields,
      children,
      className,
      dataType,
      dataTypes,
      disabledText,
      draggable,
      enabled,
      field,
      fieldsList = [],
      hasSections,
      index,
      isOver,
      onChangeEnabled,
      onDeleteSection,
      parentField,
      parentFieldType,
      permissionType,
      popoutOpen = false,
      refetchData,
      section,
      selectedChildFields,
      setActiveField,
      setPopoutOpen,
      disabled = false,
      disableSwitch = false,
    }: FieldListItemProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { user } = useAuthWrapper();
    const dispatch = useDispatch();
    const isFieldListEditorOpen = useSelector(isFieldListEditorOpenSelector);
    const project = useSelector(projectDataSelector);
    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const fieldPermissions = useMemo(
      () =>
        nestedFieldPermissions(
          field,
          dataType,
          parentField,
          parentFieldType,
          user as User,
          fieldPermissionsEnabled,
        ),
      [
        dataType,
        field,
        fieldPermissionsEnabled,
        parentField,
        parentFieldType,
        user,
      ],
    );

    const hasValidPermissions = useMemo(
      () =>
        !!section ||
        (fieldPermissions.read &&
          (!field || field.readOnly || fieldPermissions[permissionType])),
      [field, fieldPermissions, permissionType, section],
    );

    const [showEditModal, setShowEditModal] = useState(false);
    const [isShowingChildFields, setIsShowingChildFields] = useState(false);

    const childFieldDataType = useMemo(() => {
      if (!allowChildFields || !field) {
        return null;
      }

      if (field.type === OBJECT) {
        return dataType;
      }

      return dataTypes.getByName(field.type);
    }, [allowChildFields, dataType, dataTypes, field]);

    const childFields = useMemo(() => {
      if (!allowChildFields) {
        return [];
      }

      if (field.type === OBJECT) {
        return getSubFieldsAsDataFields(field, { includeRoot: false });
      }

      return childFieldDataType?.fields ?? [];
    }, [allowChildFields, childFieldDataType, field]);

    const totalFields = useMemo(() => fieldsList.length, [fieldsList]);

    const fieldOptions = useMemo(
      () =>
        fieldsList.map(({ field, config }, index) => {
          if (config.isSection) {
            const plainLabel =
              config.label === ''
                ? getText('elements.FORMS.sections.placeholder')
                : config.label;

            return {
              icon: <IconFolder size={16} />,
              label: plainLabel,
              value: index,
            };
          }

          return {
            icon: <DataFieldIcon field={field} size={16} />,
            label: field.display,
            value: index,
          };
        }),
      [fieldsList],
    );

    const showFieldsNav = useMemo(
      () =>
        !!(
          (activeField || activeField === 0) &&
          (totalFields || totalFields === 0)
        ),
      [activeField, totalFields],
    );

    const { nextField = null, prevField = null } = useMemo(
      () => ({
        nextField:
          showFieldsNav &&
          (activeField === totalFields! - 1 ? 0 : activeField! + 1),
        prevField:
          showFieldsNav &&
          (activeField === 0 ? totalFields! - 1 : activeField! - 1),
      }),
      [activeField, totalFields, showFieldsNav],
    );

    const setActiveFieldState = useCallback(
      (field) => {
        setActiveField!(field);

        if (!isFieldListEditorOpen) {
          return dispatch(setIsFieldListEditorOpen(true));
        }

        if (field === null) {
          setPopoutOpen!(false);
          dispatch(setIsFieldListEditorOpen(false));
        }
      },
      [setActiveField, dispatch, isFieldListEditorOpen, setPopoutOpen],
    );

    const handleNextField = useCallback(() => {
      setPopoutOpen!(true);
      (nextField || nextField === 0) && setActiveFieldState(nextField);
    }, [setPopoutOpen, nextField, setActiveFieldState]);

    const handlePrevField = useCallback(() => {
      setPopoutOpen!(true);
      (prevField || prevField === 0) && setActiveFieldState(prevField);
    }, [setPopoutOpen, prevField, setActiveFieldState]);

    useOnKeyPress(PERIOD, handleNextField, {
      ctrlKey: true,
      shiftKey: true,
      enabled:
        !!(popoutOpen || activeField || activeField === 0) &&
        nextField !== null,
    });

    useOnKeyPress(COMMA, handlePrevField, {
      ctrlKey: true,
      shiftKey: true,
      enabled:
        !!(popoutOpen || activeField || activeField === 0) &&
        prevField !== null,
    });

    const header = useMemo(
      () => (
        <div className="flex w-full select-none items-center space-x-2">
          <SelectInput
            className="w-full max-w-60"
            onChange={(index: number) => {
              setPopoutOpen!(true);
              setActiveFieldState(index);
            }}
            options={fieldOptions}
            placeholder={field?.display ?? section?.label}
            searchable={true}
            shiftRight={true}
            surface={DARK}
            value={index}
          />
          <div className="flex items-center">
            <KeyboardShortcutTooltip
              keys={[CMD, SHIFT, COMMA]}
              label={getText('elements.VIEW.fields.prevField')}
              offset={[0, 8]}
              placement="bottom"
            >
              <BuildModeIcon
                disabled={prevField === null}
                Icon={IconChevronUp}
                onClick={handlePrevField}
              />
            </KeyboardShortcutTooltip>
            <KeyboardShortcutTooltip
              keys={[CMD, SHIFT, PERIOD]}
              label={getText('elements.VIEW.fields.nextField')}
              offset={[0, 8]}
              placement="bottom"
            >
              <BuildModeIcon
                disabled={nextField === null}
                Icon={IconChevronDown}
                onClick={handleNextField}
              />
            </KeyboardShortcutTooltip>
          </div>
        </div>
      ),
      [
        field,
        fieldOptions,
        handleNextField,
        handlePrevField,
        index,
        nextField,
        prevField,
        section,
        setActiveFieldState,
        setPopoutOpen,
      ],
    );

    if (showEditModal && !disabled) {
      return (
        <DataFieldForm
          canDelete={false}
          dataType={dataType}
          dataTypes={dataTypes}
          value={field}
          onClose={() => setShowEditModal(false)}
          project={project}
        />
      );
    }

    return (
      <>
        <div
          className={classNames(className, 'flex w-full flex-col')}
          ref={ref}
        >
          {draggable && isOver && (
            <div className="mb-2 h-2 w-full rounded-full bg-slate-900" />
          )}
          <div className="flex w-full items-center">
            <div
              className={classNames(
                'group flex w-full items-center overflow-hidden rounded-lg bg-slate-700 px-2 py-3 text-slate-200',
                {
                  'ml-4': field && hasSections,
                  'border-brand-light': hasValidPermissions,
                  'border border-yellow-300 opacity-75': !hasValidPermissions,
                },
              )}
            >
              {enabled && !disabledText && (
                <IconGripVertical
                  size={16}
                  className={classNames(
                    'mr-2 flex-shrink-0 cursor-move',
                    draggable ? 'opacity-75' : 'opacity-25',
                    { 'hidden group-hover:flex': enabled },
                  )}
                />
              )}
              {field && (
                <DataFieldIcon
                  size={16}
                  className={classNames('mr-2 flex-shrink-0 opacity-75', {
                    'group-hover:hidden': enabled,
                  })}
                  field={field}
                />
              )}
              {!hasValidPermissions && (
                <Tooltip
                  content={
                    <div className="flex flex-col">
                      <p>
                        {getText('fields.permissionsWarning', permissionType)}
                      </p>
                      <Link
                        to={`/_/data/internal/${dataType.name}/permissions`}
                        className="mt-1 font-medium text-teal-500 hover:text-teal-600 hover:underline"
                      >
                        {getText('fields.permissionsWarning.viewPermissions')}
                      </Link>
                    </div>
                  }
                  placement="right"
                  bg="white"
                >
                  <span className="mr-2 flex items-center">
                    <IconShieldLock
                      size={16}
                      className="flex-shrink-0 text-yellow-300"
                    />
                  </span>
                </Tooltip>
              )}
              {section && (
                <IconFolder className="mr-2 group-hover:hidden" size={16} />
              )}
              <Tooltip
                placement="top"
                content={
                  <div className="flex max-w-64 flex-col">
                    <span>{get(field, ['display'])}</span>
                    {disabledText && (
                      <div className="mt-2 font-medium">{disabledText}</div>
                    )}
                  </div>
                }
                disabled={!field || !disabledText}
                bg="white"
              >
                <div
                  className={classNames('mr-2 flex flex-col truncate', {
                    'opacity-50': disabledText,
                  })}
                >
                  {enabled && parentField && (
                    <span className="mb-px text-xs text-gray-300">
                      {parentField.display}
                    </span>
                  )}
                  <Tooltip
                    disabled={
                      !field || field.display.length < LONG_FIELD_NAME_LENGTH
                    }
                    content={field && field.display}
                  >
                    <span className="truncate whitespace-nowrap text-xs">
                      {field && field.display}
                      {section &&
                        (section.label ||
                          getText('elements.FORMS.sections.placeholder'))}
                    </span>
                  </Tooltip>
                </div>
              </Tooltip>
              <div
                className={classNames(
                  'ml-auto flex items-center',
                  { 'opacity-50': disabled },
                  className,
                )}
              >
                {enabled && !disabledText && (
                  <>
                    <button
                      className={classNames({
                        hidden: disabled,
                        'hidden group-hover:flex': !disabled,
                        'mr-2 px-2': field,
                        'mr-6': section,
                      })}
                      disabled={disabled}
                      onClick={() => setActiveFieldState(index)}
                    >
                      <IconEdit size={16} className="opacity-75" />
                    </button>
                    {activeField === index && (
                      <RightPopoutMenu
                        disableTransition={popoutOpen}
                        header={header}
                        onClose={() => setActiveFieldState(null)}
                        rootSelector=".noloco-project"
                        source="buildMode"
                        title={field && field.display}
                        usePortal={true}
                      >
                        <div className="flex flex-col text-sm text-gray-200">
                          <div className="flex flex-col">
                            <div className="flex flex-col p-2 pb-12">
                              {children}
                            </div>
                            {field && (
                              <div
                                className="absolute bottom-0 flex h-10 w-full cursor-pointer items-center justify-between bg-slate-900 px-2 text-slate-400 hover:bg-slate-700 hover:text-slate-200"
                                onClick={() => setShowEditModal(true)}
                              >
                                <div className="flex items-center space-x-2">
                                  <DataFieldIcon
                                    field={field}
                                    size={16}
                                    disabled={disabled}
                                  />
                                  <span>{field.display}</span>
                                </div>
                                <IconSettings size={16} />
                              </div>
                            )}
                          </div>
                        </div>
                      </RightPopoutMenu>
                    )}
                  </>
                )}
                {!enabled &&
                  !disabledText &&
                  allowChildFields &&
                  !parentField &&
                  (field.relationship ||
                    field.relatedField ||
                    field.type === OBJECT) &&
                  !isMultiField(field) && (
                    <button
                      onClick={() =>
                        setIsShowingChildFields(!isShowingChildFields)
                      }
                    >
                      {isShowingChildFields ? (
                        <IconChevronDown size={16} className="mx-2" />
                      ) : (
                        <IconChevronLeft size={16} className="mx-2" />
                      )}
                    </button>
                  )}
                {field &&
                  !disabledText &&
                  (field.type !== OBJECT ||
                    OBJECT_FORMATS_WITH_DISPLAY.includes(
                      field.typeOptions?.format as FieldFormat,
                    )) && (
                    <Switch
                      size="sm"
                      value={enabled}
                      onChange={() => onChangeEnabled(field)}
                      disabled={disabled || disableSwitch}
                    />
                  )}
                {section && !disabledText && (
                  <button
                    className="mr-2 hidden group-hover:flex"
                    onClick={onDeleteSection}
                  >
                    <IconTrash size={16} className="opacity-75" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {!enabled &&
          !parentField &&
          isShowingChildFields &&
          childFields
            .filter((subField) => !selectedChildFields.includes(subField.name))
            .map(
              (subField, index) =>
                childFieldDataType && (
                  <FieldListItem
                    className="pl-4"
                    dataType={childFieldDataType as DataType}
                    dataTypes={dataTypes}
                    draggable={false}
                    enabled={false}
                    field={subField}
                    hasSections={false}
                    index={index}
                    key={`${subField.name}-${field.name}`}
                    onChangeEnabled={() => onChangeEnabled(subField, field)}
                    parentField={field}
                    permissionType={permissionType}
                    refetchData={refetchData}
                    selectedChildFields={[]}
                  />
                ),
            )}
      </>
    );
  },
);

FieldListItem.displayName = 'FieldListItem';

FieldListItem.defaultProps = {
  selectedChildFields: [],
};

export default WithDraggable(FieldListItem, RECORD_FIELD);
