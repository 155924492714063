import React, { useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { Popover } from '@noloco/components';

const EditorSectionPopover = ({
  className,
  dataTestId,
  Icon,
  label,
  children,
  hasHint,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      placement="right-start"
      closeOnOutsideClick={true}
      bg="brand-darkest"
      border={[true, 'brand-light']}
      onOpenChange={setIsOpen}
      showArrow={false}
      content={
        <div
          className={classNames(
            className,
            'flex flex-col p-2 text-sm text-gray-100',
          )}
        >
          {children}
        </div>
      }
    >
      <div
        className={classNames(
          'mb-1 mr-auto flex w-full items-center rounded-lg p-2',
          { 'bg-brand-darkest': isOpen, 'hover:bg-brand-dark': !isOpen },
        )}
        // @ts-expect-error TS(2322): Type '{ children: Element[]; className: string; da... Remove this comment to see the full error message
        dataTestId={dataTestId ? `editor-section-btn-${dataTestId}` : undefined}
      >
        <Icon size={20} className="mr-2" />
        <label className="text-xs font-medium uppercase tracking-wider text-gray-300">
          {label}
        </label>
        {hasHint && <IconInfoCircle className="ml-auto" size={16} />}
      </div>
    </Popover>
  );
};
export default EditorSectionPopover;
